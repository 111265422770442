import React, { useEffect, useState, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { CardElement, useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { Route, Switch, useHistory, Link } from "react-router-dom";
import { ListContext, MyContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
const Auth = new AuthHelperMethods();

export const AccountPaymentMethod = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const [clientSecret, setClientSecret] = useState(null);
  const [setupId, setSetupId] = useState("");
  const [publicKey, setPublicKey] = useState("");

  const getPublicStripeKey = async () => {
    const result = await Auth.api("payments/getPublicStripeKey", "get");
    console.log("gettingi public stripe key");
    return result.data.publicKey;
  };

  const getFarmAccountId = async () => {
    const result = await Auth.api("payments/getFarmAccountId", "get");
    //console.log(result.data);
    return result.data.stripe_account_id;
  };

  const stripePromise = getPublicStripeKey().then((key) =>
    getFarmAccountId().then((farm) =>
      loadStripe(key, {
        stripeAccount: farm,
      })
    )
  );

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

const CheckoutForm = () => {
  const { listState, dispatch2 } = useContext(ListContext);

  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState(null);
  const [setupId, setSetupId] = useState("");

  useEffect(
    () => {
      const fetchData = async () => {
        "useEffect createSetupIntent";
        let params = { person_id: listState.accountHolder.id };
        try {
          const result = await Auth.api("payments/createSetupIntent", "post", params);
          if (result) setSetupId(result.data.setupIntent.id);
          setClientSecret(result.data.setupIntent.client_secret);
          // console.log(result.data.setupIntent);
        } catch (e) {
          console.log(e);
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    var payload;
    payload = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });
    console.log(payload);
    console.log(payload.setupIntent.payment_method);

    let params = { person_id: listState.accountHolder.id, payment_method: payload.setupIntent.payment_method };
    try {
      const result = await Auth.api("payments/updatePaymentMethod", "post", params);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CardElement />
        <button type="submit">now</button>
      </form>
    </>
  );
};
