import styled from "styled-components";

const COLORS = {
  orange: "#ff7900",
  lightorange: "#ff9541",
  blue: "#0099BC",
  lightblue: "#66c2d7",
  grey: "#e6e6e6",
  success: "#A8C046",
  error: "#B90646",
  info: "#99ccff",
  warning: "#FFFFCC",
  lightgrey: "#f9f9f9",
  medgrey: "#DCD0D0",
  tablegrey: "#4d4d4d",
  mediumTitle: "#4D4D4D",
  textMuted: "#768192",
  textBody: "#4f5d73",
  textDark: "#636f83",
  bgLight: "#ebedef",
  textgrey: "#777777",
};

export const DropdownStyle = {
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? "grey" : "red",
    fontWeight: "bold",
    minHeight: 20,
    background: "white",
  }),
  menu: (styles) => ({
    ...styles,
    top: "",
  }),
};

export const customColors = {
  option: (styles, { data }) => ({
    ...styles,
    ...dot(data.color),
    fontWeight: "bold",
    minHeight: 20,
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...dot(data.color),

    width: "100%",
    fontWeight: "bold",
    minHeight: 40,
    height: "100%",
  }),
  multiValue: (styles, { data }) => ({
    ...styles,

    backgroundColor: data.color,

    fontWeight: "bold",
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    padding: 0,
    margin: 0,
  }),
  menuList: (styles) => ({
    ...styles,
    minHeight: "70vh",
  }),
  menu: (styles) => ({
    ...styles,
    top: "",
    zIndex: 9999,
  }),
};

const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 20,
    width: 20,
  },
});

export const selectStyle = {
  option: (styles, { data }) => ({
    ...styles,
    ...dot(data.color),
    fontWeight: "bold",
    minHeight: 20,
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...dot(data.color),

    width: "100%",
    fontWeight: "bold",
    minHeight: 40,
    height: "100%",
  }),
  multiValue: (styles, { data }) => ({
    ...styles,

    backgroundColor: data.color,

    fontWeight: "bold",
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    padding: 0,
    margin: 0,
  }),
  menuList: (styles) => ({
    ...styles,
    minHeight: "70vh",
  }),
  menu: (styles) => ({
    ...styles,
    top: "",
  }),
};

export const square = (isSelected) => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: isSelected ? COLORS.orange : "white",
    borderRadius: 2,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

export const reportStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "solid 1pt lightgrey",
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      ...dot(isSelected),
      background: "white",
      color: isSelected ? COLORS.orange : "darkgrey",

      padding: 2,
      fontSize: 12,
      minWidth: 150,
      maxWidth: 150,
      minHeight: 20,
    };
  },
  input: (styles) => ({ ...styles, justifyContent: "flex-end" }),
  singleValue: (styles) => ({ ...styles }),
  multiValue: (styles) => ({ ...styles, ...dot() }),

  placeholder: (styles) => ({ ...styles }),
  menuList: (styles) => ({
    ...styles,
    display: "flex",
    flexWrap: "wrap",
  }),
  menu: (styles) => ({
    ...styles,
    top: "",
    width: 300,
    display: "flex",
    flexWrap: "wrap",
  }),
  container: (provided, state) => ({
    ...provided,
    border: "none",
    minWidth: 200,
    maxWidth: 150,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    color: "white",
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    justifyContent: "flex-end",
  }),
};

export const listItemStyle = {
  borderTop: "solid ,lightgrey",
  borderBottom: "solid ,lightgrey",
  borderLeft: "none",
  borderRight: "none",
};

export const boxStyle = {
  minHeight: "20px",
  minWidth: "20px",
  border: "solid 1pt grey",
};

export const DetailContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  font-family: Helvetica Neue;
  font-size: 14pt;
  flex: 1 1 100%;
`;

export const DetailLabel = styled.div`
  text-align: right;
  flex: 0 0 20%;
  color: #777;
  margin-right: 10px;
  font-size: 14pt;
`;

export const DetailInfo = styled.div`
  color: #333;
  flex: 1 0 auto;
  font-size: 14pt;
`;

export const Separator = styled.div`
  min-height: 10px;
  background: ${COLORS.grey};
  margin-bottom: 10px;
  padding: 5px;
  font-weight: "bold";
`;

export const SeparatorThin = styled.div`
  min-height: 10;
  background: ${COLORS.grey};
  margin-bottom: 10;
  padding: 5;
  font-weight: "bold";
`;

export const RowBottomBorder = styled.div`
  border-bottom: solid 1pt ${COLORS.grey};
  min-height: 50px;
  padding-bottom: 10px;
  padding-top: 10px;
`;

export const formContainerStyle = {
  minHeight: 400,
  border: "solid 3pt #0099BC",
  padding: 20,
  marginTop: 50,
};

export const helpStyle = {
  minHeight: 40,
  xminWidth: 300,
  border: `solid 1pt ${COLORS.orange}`,
  color: COLORS.orange,
  alignItems: "center",
  flexWrap: "nowrap",
  flexDirection: "row",
  display: "flex",
  marginLeft: 10,
  borderRadius: 10,
  padding: 5,
};
export const filterVerticalOpenStyle = {
  transition: "max-width .5s",

  opacity: 1,
};

export const filterVerticalCloseStyle = {
  transition: "max-width .5s",

  opacity: 0,
};

export const noteOpenStyle = {
  maxHeight: 200,
  transition: "max-height .5s",
  display: "flex",
  flexDirection: "column",
  opacity: 1,
};
export const noteClosedStyle = {
  maxHeight: 0,
  transition: "max-height .5s",
  display: "flex",
  flexDirection: "column",

  opacity: 0,
};

export const nametagStyle = {
  border: "solid 2pt lightgrey",
  borderRadius: 10,
  padding: 5,

  xmaxHeight: 60,
  xfontSize: 20,
  fontStyle: "bold",
  display: "flex",
  flexWrap: "nowrap",
  flexDirection: "row",
  flex: "1 1 auto",
  alignItems: "center",
  marginBottom: 5,
  background: "white",
};

export const buttonStyle = {
  fontSize: 12,
  background: "white",
  border: "solid  1px",
  borderColor: "darkgrey",
  borderRadius: 5,
  margin: 3,
  padding: 5,
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
};

export const buttonOrangeStyle = {
  backgroundColor: COLORS.orange,
  padding: "5px 10px",
  textAlign: "middle",
  color: "white",
  minHeight: "40px",
  maxHeight: 40,
  minWidth: "100px",
  margin: 1,
  borderRadius: 5,
  border: "none",
};

export const buttonBlueStyle = {
  backgroundColor: COLORS.blue,
  padding: "5px 10px",
  textAlign: "middle",
  color: "white",
  minHeight: "40px",
  maxHeight: 40,
  minWidth: "100px",
  margin: 1,
  borderRadius: 5,
  border: "none",
};

export const modalSelectStyle = {
  multiValue: (styles) => ({
    ...styles,
    color: "white",
    backgroundColor: COLORS.orange,
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "white",
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    padding: 0,
    margin: 0,
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: 450,
    marginBottom: 30,
  }),
  menu: (styles) => ({
    ...styles,
  }),
};

export default COLORS;
