import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { ListContext } from "./App";
import { MyContext } from "./App";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import { Row, Col, Modal, Form } from "react-bootstrap";
import {
  BlueButtonSm,
  OrangeButtonSm,
  SaveButton,
  CancelButton,
  BoxWithLabelNoM,
  ContainerH,
  ContainerV,
  HelpText,
  BlockLabel,
  colourStyles,
  selectSkinny,
} from "./styleCustom";
import { ModalTitleRow } from "./formComponents";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  currentMonthStart,
  currentMonthEnd,
  nextMonthStart,
  nextMonthEnd,
  previousMonthStart,
  previousMonthEnd,
  dateObjToDBDate,
} from "./momentUtils";
import { DATEPICKER_DATE_DISPLAY } from "./UserSettings";
import { customColors } from "./styleConstants";
import { options_attendance } from "./Constants2";
import COLORS from "./styleConstants";
import styles from "react-loading-overlay/lib/styles";
import { CircleSolid, Circle } from "./icons";

export const SelectTemplateFilter = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  const box = {
    display: "flex",
    flexDirection: "column",
    padding: 5,
    fontSize: 12,
    backgroundColor: COLORS.blue,
    color: "white",
    marginTop: 5,
    marginBottom: 0,
    textAlign: "left",
  };

  const Box = (props) => {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column", maxWidth: props.maxWidth, minWidth: props.maxWidth }}>
          <label style={box}>{props.label}</label>
          {props.children}
        </div>
      </>
    );
  };
  const BoxWithLabelNoMX = (props) => {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column", minWidth: 150 }}>
          <BlockLabel style={{ marginTop: 5 }}>{props.label}</BlockLabel>
          {props.children}
        </div>
      </>
    );
  };

  if (!props.options) return null;
  return (
    props.options && (
      <div
        className={props.className || "select-template"}
        style={{ marginBottom: 5, marginRight: 15, maxWidth: props.maxWidth, minWidth: props.maxWidth }}
      >
        <Select
          isSearchable
          isClearable
          isMulti={props.isMulti}
          onChange={(selectedOption) =>
            props.change ||
            dispatch2(["SET_SEARCH_FILTER_REPORTS", selectedOption || [], props.label, props.extraLabel])
          }
          options={props.options}
          styles={props.styles || selectSkinny}
          closeMenuOnSelect={!props.isMulti}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          defaultValue={props.defaultValue}
          value={props.options.filter((option) => props.value?.includes(option.value))}
          placeholder={props.extraLabel || props.label}
          blurInputOnSelect={false}
        />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",

            color: COLORS.orange,
          }}
        >
          {" "}
          {props.list && props.list.length > 0 ? props.list.join() : ""}
        </div>
      </div>
    )
  );
};

export const SelectCalendarFilter = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  if (!props.options) return null;
  return (
    props.options && (
      <div style={{ marginBottom: 5, marginRight: 5 }}>
        <BoxWithLabelNoM label={props.extraLabel || props.label} maxWidth={props.maxWidth}>
          <Select
            isSearchable
            isClearable
            isMulti={props.isMulti}
            onChange={(selectedOption) =>
              props.change || dispatch2(["SET_SEARCH_FILTER_CALENDAR", selectedOption, props.label, props.extraLabel])
            }
            options={props.options}
            styles={props.styles || colourStyles}
            closeMenuOnSelect={!props.isMulti}
            hideSelectedOptions={false}
            controlShouldRenderValue={false}
            defaultValue={props.defaultValue}
          />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",

              color: COLORS.orange,
            }}
          >
            {" "}
            {props.list && props.list.length > 0 ? props.list.join() : ""}
          </div>
        </BoxWithLabelNoM>
      </div>
    )
  );
};
export const ButtonTemplateFilter = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  if (!props.options) return null;
  return (
    props.options && (
      <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
        {props.options.map((option) => (
          <button
            onClick={() => dispatch2(["SET_SEARCH_FILTER_BUTTON", option.value, props.label])}
            key={option.value}
            style={{
              fontSize: 12,
              background: "white",
              border: "solid  1px",
              borderColor: "darkgrey",
              borderRadius: 5,
              margin: 3,
              padding: 5,
              color:
                listState.filter[props.label] && listState.filter[props.label].includes(option.value)
                  ? COLORS.orange
                  : COLORS.textDark,
              display: "flex",
            }}
          >
            {option.label}
          </button>
        ))}
      </div>
    )
  );
};

export const SelectNoLabel = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  if (!props.options) return null;
  return (
    props.options && (
      <>
        <Select
          isSearchable
          isClearable
          isMulti={props.isMulti}
          onChange={(selectedOption) =>
            props.change || dispatch2(["SET_SEARCH_FILTER_REPORTS", selectedOption, props.label, props.extraLabel])
          }
          options={props.options}
          styles={props.styles || colourStyles}
          closeMenuOnSelect={!props.isMulti}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          placeholder={props.placeholder || "Select "}
        />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",

            color: COLORS.orange,
          }}
        >
          {" "}
          {props.list && props.list.length > 0 ? props.list.join() : ""}
        </div>
      </>
    )
  );
};

export const Active = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  let [selected, setSelected] = useState(props.default);

  useEffect(() => {
    dispatch2(["SET_SEARCH_FILTER_RADIO", props.default, props.field]);
  }, []);

  const chooseSelection = (id) => {
    setSelected(id);

    let selectedOption = [{ value: props.id, label: props.label }];

    dispatch2(["SET_SEARCH_FILTER_RADIO", id, props.field]);
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: props.direction || "column", marginBottom: 5, marginRight: 15 }}>
        <div>
          {props.biglabel && (
            <BoxWithLabelNoM label={props.extraLabel || props.label} maxWidth={props.maxWidth} minWidth={120} />
          )}
          {!props.biglabel && (
            <span style={{ marginRight: 10, color: COLORS.blue, fontWeight: "bold" }}>{props.label}</span>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: props.direction || "row" }}>
          {props.options.map((option) => (
            <CancelRow
              key={option.value}
              id={option.value}
              label={option.label}
              font={12}
              chooseSelection={chooseSelection}
              selected={selected}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export const CancelRow = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);

  return (
    <div
      className="rowCancel"
      style={{
        minHeight: 10,
        minWidth: 75,
        display: "flex",
        marginBottom: 10,
        marginRight: 15,
      }}
      onClick={() => props.chooseSelection(props.id)}
    >
      <div
        className="circle"
        style={{
          display: "flex",
          alignItems: "flex-start",
          paddingRight: 5,
          paddingTop: 4,
        }}
      >
        {props.selected == props.id ? <CircleSolid color={COLORS.orange} /> : <Circle />}
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", flexDirection: props.direction || "column" }}>
        <div
          style={{
            fontSize: props.font || 16,
            color: listState.available === props.id ? COLORS.orange : COLORS.textDark,
            display: "flex",
          }}
        >
          {props.label}
        </div>
      </div>
    </div>
  );
};

export const ActiveButtons = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  let [selected, setSelected] = useState(props.default);

  useEffect(() => {
    dispatch2(["SET_SEARCH_FILTER_RADIO", props.default, props.field]);
  }, []);

  const chooseSelection = (id) => {
    setSelected(id);
    console.log(id);

    console.log(listState.available);
    console.log(listState.available === props.id);
    dispatch2(["SET_SEARCH_FILTER_REPORTS", selectedOption, props.label, props.extraLabel]);

    // setFilter(id);

    let selectedOption = [{ value: props.id, label: props.label }];

    //  dispatch2(["SET_SEARCH_FILTER_RADIO", id, props.field]);
  };

  return (
    <div style={{ display: "flex", flexDirection: props.direction || "row" }}>
      <span style={{ marginRight: 10, color: COLORS.blue, xminWidth: 75 }}>{props.label}</span>
      {props.options.map((option) => (
        <FilterButtons
          key={option.value}
          id={option.value}
          label={option.label}
          font={12}
          chooseSelection={chooseSelection}
          selected={selected}
        />
      ))}
    </div>
  );
};

export const FilterButtons = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);

  return (
    <div
      className="rowCancel"
      style={{
        minHeight: 10,
        minWidth: 75,
        display: "flex",
        marginBottom: 10,
        marginRight: 15,
      }}
      onClick={() => props.chooseSelection(props.id)}
    >
      <div style={{ display: "flex", flexWrap: "wrap", flexDirection: props.direction || "column" }}>
        <button
          style={{
            fontSize: props.font || 16,
            background: "white",
            border: "solid  1px",
            borderColor: "darkgrey",
            borderRadius: 5,
            color: listState.available === props.id ? COLORS.orange : COLORS.textDark,
            display: "flex",
          }}
        >
          {props.label}
        </button>
      </div>
    </div>
  );
};
export const ModalFilter = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const [showAdvancedFilterModal, setShowAdvancedFilterModal] = useState(false);

  return (
    <>
      <Modal show={showAdvancedFilterModal} size="md">
        <Modal.Header />
        <ModalTitleRow title={"Advanced Filter"} clickevent={() => setShowAdvancedFilterModal(false)} />
        <Modal.Body>{props.children}</Modal.Body>
        <Modal.Footer>
          <CancelButton>CLEAR FILTERS</CancelButton>
          <SaveButton onClick={() => dispatch2(["MERGE_FILTERS"])}> APPLY FILTERS </SaveButton>
        </Modal.Footer>
      </Modal>
      <OrangeButtonSm onClick={() => setShowAdvancedFilterModal(true)}>{"advanced filter Modal"}</OrangeButtonSm>
    </>
  );
};

export const BoxWithLabel = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginRight: 5,
        maxWidth: props.maxWidth || 150,
        minWidth: 150,
      }}
    >
      <BlockLabel style={{ paddingLeft: 30 }}>{props.label}</BlockLabel>
      {props.children}
    </div>
  );
};

const label = (isSearchable, options, isDisabled, isFocused) => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    content: isSearchable ? ' " "' : '"select/clear"',

    display: "block",
    marginRight: 5,
    color: isDisabled ? "blue" : "red",
  },
});

const IndicatorsContainer = (props) => {
  return (
    <div style={{ position: "relative", border: "solid yellow", top: -40 }}>
      <span>{props.className} </span>
      <components.IndicatorsContainer {...props} />
    </div>
  );
};

const updateSelection = (selectedOption, filterType, props) => {
  console.log(selectedOption);

  let selectedValue = [];
  if (selectedOption == null) return null;
  if (selectedOption.length > 0) {
    selectedValue = selectedOption.map((single) => single.value);
    console.log("array");
  } else {
    console.log("obj");
    selectedValue[0] = selectedOption.value;
  }

  return selectedValue;
};

export const InstructorFilter = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  let toAdd = [{ value: 0, label: "unassigned", color: "white" }];
  console.log("InstructorFilter");
  let options = mystate.options_instructor;
  options = mystate.options_instructor && mystate.options_instructor.concat(toAdd);
  console.log(mystate.options_instructor);
  if (!options) return null;
  return (
    options && (
      <BoxWithLabel label="staff">
        <Select
          className="instructor-color"
          classNamePrefix="instructor"
          isSearchable
          components={{ IndicatorsContainer }}
          isMulti={props.isMulti}
          closeMenuOnSelect={!props.isMulti}
          onChange={(selectedOption, props) => dispatch2(["SET_SEARCH_FILTER_REPORTS", selectedOption, "instructor"])}
          options={options}
          styles={props.styles || colourStyles}
          placeholder={"staff"}
          hideSelectedOptions={false}
        />
      </BoxWithLabel>
    )
  );
};

const MultiValueContainer = (props) => {
  return <div></div>;
};
export const LocationFilter = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  if (!mystate.options_location) return null;
  let options = mystate.options_location;
  return (
    options && (
      <Select
        components={{ MultiValueContainer }}
        isSearchable
        isMulti={props.isMulti}
        onChange={(selectedOption) => dispatch2(["SET_SEARCH_FILTER", updateSelection(selectedOption), "location"])}
        options={options}
        styles={colourStyles}
        closeMenuOnSelect={!props.isMulti}
        placeholder={"location"}
        hideSelectedOptions={false}
        controlShouldRenderValue={true}
      />
    )
  );
};

export const SectionFilter = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  if (!mystate.options_section) return null;
  let options = mystate.options_section;
  return (
    options && (
      <>
        {" "}
        <BoxWithLabel label="section">
          <Select
            isSearchable
            isMulti={props.isMulti}
            onChange={(selectedOption) => dispatch2(["SET_SEARCH_FILTER", selectedOption, "section"])}
            options={options}
            styles={props.styles || colourStyles}
            closeMenuOnSelect={!props.isMulti}
            placeholder={"section"}
            hideSelectedOptions={false}
            controlShouldRenderValue={false}
          />
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {" "}
            {listState.filter_labels.section && listState.filter_labels.section.length > 0
              ? listState.filter_labels.section.join()
              : ""}
          </div>
        </BoxWithLabel>
      </>
    )
  );
};

export const SelectTemplateFilterNoLabel = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  if (!props.options) return null;
  return (
    props.options && (
      <>
        <Select
          isSearchable
          isClearable
          isMulti={props.isMulti}
          onChange={(selectedOption) => dispatch2(["SET_SEARCH_FILTER_REPORTS", selectedOption, props.label])}
          options={props.options}
          styles={props.styles || colourStyles}
          closeMenuOnSelect={!props.isMulti}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
        />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",

            color: COLORS.orange,
          }}
        >
          {" "}
          {props.list && props.list.length > 0 ? props.list.join() : ""}
        </div>
      </>
    )
  );
};

export const AttendanceFilter = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  if (!options_attendance) return null;
  let options = options_attendance;
  return (
    options && (
      <Select
        isSearchable
        isMulti={props.isMulti}
        onChange={(selectedOption) => dispatch2(["SET_SEARCH_FILTER", updateSelection(selectedOption), "attendance"])}
        options={options}
        styles={colourStyles}
        closeMenuOnSelect={!props.isMulti}
        placeholder={"attendance"}
        hideSelectedOptions={false}
      />
    )
  );
};

export const ProgramFilter = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const searchinputRef = React.createRef();
  const [menuOpen, setMenuOpen] = useState(false);

  const onInputChange = (inputValue, { action }) => {
    console.log(inputValue, action);
    switch (action) {
      case "menu-close":
        setMenuOpen(!menuOpen);
        return;
      case "set-value":
        return;
      default:
        return;
    }
  };

  const colourStyless = {
    ...colourStyles,
    valueContainer: (styles, state) => ({
      ...styles,
      border: "none",
      paddingTop: menuOpen ? 0 : 30,
      overflow: "visible",
      order: 2,
    }),
  };

  if (!mystate.options_program) return null;
  let options = mystate.options_program;
  return (
    <>
      {options && (
        <>
          <BoxWithLabel label={"programs"}>
            <Select
              isSearchable
              isMulti={props.isMulti}
              isClearable
              onChange={(selectedOption) => dispatch2(["SET_SEARCH_FILTER", selectedOption, "program"])}
              options={options}
              styles={colourStyless}
              placeholder={""}
              hideSelectedOptions={false}
              controlShouldRenderValue={false}
              closeMenuOnSelect={false}
              onMenuOpen={() => setMenuOpen(true)}
              onMenuClose={() => setMenuOpen(false)}
            />
            <div>
              {mystate.filter.program && mystate.filter.program.map((item) => <span>options.filteritem.label</span>)}
            </div>
          </BoxWithLabel>
        </>
      )}
    </>
  );
};

export const ProgramTypeFilter = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  if (!mystate.options_program_type) return null;
  let options = mystate.options_program_type;

  return (
    options && (
      <ContainerH>
        <HelpText>{props.help}</HelpText>
        <BoxWithLabel label={"program types"}>
          <Select
            isSearchable
            isMulti={props.isMulti}
            isClearable
            onChange={(selectedOption) =>
              dispatch2(["SET_SEARCH_FILTER", updateSelection(selectedOption), "program_type"])
            }
            options={options}
            styles={colourStyles}
            closeMenuOnSelect={!props.isMulti}
            hideSelectedOptions={false}
          />
        </BoxWithLabel>
      </ContainerH>
    )
  );
};

const SearchFilter = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          border: "solid 1 lightgrey",
        }}
      ></div>
    </>
  );
};

export default SearchFilter;
