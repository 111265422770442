import React, { useState, useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ListContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import { BlueInvLink, Row2, BlueNavLinkSm, PageTitle } from "./styleCustom";
import Sections from "./sections";
import COLORS from "./styleConstants";
import Products from "./products";
import AssignPackage from "./assignPackage";
import { ClientNotesReview, HorseNotesReview } from "./notesAdmin";
import Programs from "./programs";
import { FarmUpdatePrefs } from "./farmUpdatePrefs";
import PrivateRoute from "./privateRoute";
import Locations from "./locations";
import Roles from "./roles";
import Registration from "./registration";
import { MyContext } from "./App";
import { SelectTemplateFilter } from "./searchFilterReports";
import Tack from "./tack";
import Task from "./task";
import AppointmentCalendar from "./features/appointments/filter/index.jsx";

const Auth = new AuthHelperMethods();

const Setup = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleItem = async () => {
    const result = await Auth.api("payments/recurringItems", "post");
  };
  const handlePayment = async () => {
    const result = await Auth.api("payments/recurringpayments", "post");
  };

  return (
    <>
      <PageTitle>{"Setup Config"}</PageTitle>

      <div
        className="admin"
        style={{
          background: COLORS.blue,

          fontSize: "10px",
          marginBottom: 30,
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "wrap",

          flex: "1 1 100%",
        }}
      >
        <>
          <BlueNavLinkSm to={`${props.match.url}/sections`}>{" Sections"}</BlueNavLinkSm>
          <BlueNavLinkSm to={`${props.match.url}/packages/list`}>{"Packages "}</BlueNavLinkSm>
          <BlueNavLinkSm to={`${props.match.url}/programs`}> Programs</BlueNavLinkSm>

          <BlueNavLinkSm to={`${props.match.url}/locations`}>Locations</BlueNavLinkSm>
          {1 == 1 && <BlueNavLinkSm to={`${props.match.url}/tack`}>Tack</BlueNavLinkSm>}
          {1 == 1 && <BlueNavLinkSm to={`${props.match.url}/task`}>Tasks</BlueNavLinkSm>}

          {mystate.farmDefaults.clients_can_register && (
            <BlueNavLinkSm to={`${props.match.url}/registration`}>Registration</BlueNavLinkSm>
          )}

          <BlueNavLinkSm to={`${props.match.url}/roles`}>Roles</BlueNavLinkSm>
          <BlueNavLinkSm to={`${props.match.url}/updateprefs`}>Settings</BlueNavLinkSm>
        </>
      </div>
      {1 == 2 && (
        <>
          {listState.dev && <button onClick={() => handleItem()}> item</button>}

          {listState.dev && <button onClick={() => handlePayment()}> payment</button>}
        </>
      )}
      <Row2>
        <Switch>
          <Route path={`${props.match.url}/sections`} component={Sections} />
          <Route path={`${props.match.url}/packages`} component={Products} />
          <Route path={`${props.match.url}/programs`} component={Programs} />
          <Route path={`${props.match.url}/locations`} component={Locations} />
          <Route path={`${props.match.url}/tack`} component={Tack} />
          <Route path={`${props.match.url}/task`} component={Task} />
          <Route path={`${props.match.url}/registration`} component={Registration} />

          <Route path={`${props.match.url}/roles`} component={Roles} />

          <Route path={`${props.match.url}/updateprefs`} component={FarmUpdatePrefs} />
        </Switch>
      </Row2>
    </>
  );
};

export default Setup;
