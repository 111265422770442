import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import lodash from "lodash";
import {
  BrowserRouter,
  Route,
  Switch,
  Link,
  useRouteMatch,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import {
  NavButton,
  BlockLabel,
  NameStringText,
  MediumPageHeader,
  BlueButton,
  SaveButtonSm,
  CancelButtonSm,
  HelpText,
  LinkText,
  SaveButton,
} from "./styleCustom";
import { MyTextInput, MyCheckbox, OrangeButton, TableHeader2, BlueButtonFinish, ButtonOrange } from "./formComponents";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col, Button, Accordion } from "react-bootstrap";
import SelectList from "./selectList";
import DatePicker from "react-datepicker";
import { DATEPICKER_DATE_DISPLAY } from "./UserSettings";
import { CheckSquare, EditIcon } from "./icons";
import { dateObjToDBDate } from "./momentUtils";
import { ListContext } from "./App";
import COLORS from "./styleConstants";
import Script from "react-load-script";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import "react-google-places-autocomplete/dist/assets/index.css";
import "react-phone-number-input/style.css";
import PhoneInput, { formatPhoneNumber } from "react-phone-number-input/input";
import User from "./UserSettings";
import { MyContext } from "./App";
import { DropdownArrow } from "./icons";
import { map_options_instructor } from "./Constants2";
import { useAlert } from "react-alert";

const Auth = new AuthHelperMethods();

//var sessionToken = new google.maps.places.AutocompleteSessionToken();
//console.log(sessionToken);
/*global google*/
//const autocomplete = new google.maps.places.Autocomplete();

//require("@google/maps").util.placesAutoCompleteSessionToken();
/*
const Save = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);

  return (
    <Row style={{ display: "flex", justifyContent: "center", marginTop: 30 }}>
      {props.showSaveButtons ? (
        <>
          <SaveButton type="submit">{`Save first ${props.newwhat}`}</SaveButton>

          <OrangeButton type="submit">{`Save second ${props.newwhat}`}</OrangeButton>
        </>
      ) : (
        <>
          <MediumPageHeader>{`${props.newwhat}  ${props.for ? "for" : ""} ${
            listState.newClientIds.person_name &&
            listState.newClientIds.person_name
          } ${props.havehas} been created.`}</MediumPageHeader>
          <OrangeButton type="submit">{"Edit"}</OrangeButton>
        </>
      )}
    </Row>
  );
};
/**/

/*const Header = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);

  return (
    <>
      <MediumPageHeader style={{ textAlign: "center" }}>
        {`${props.what}
         ${
           listState.newClientIds.person_name &&
           listState.newClientIds.person_name
         }`}
      </MediumPageHeader>
      <MediumPageHeader style={{ textAlign: "center" }}>
        {"(optional) "}
      </MediumPageHeader>
    </>
  );
};
/*
/*function LoadingButton() {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      simulateNetworkRequest().then(() => {
        setLoading(false);
      });
    }
  }, [isLoading]);

  const handleClick = () => setLoading(true);

  return (
    <Button
      variant="primary"
      disabled={isLoading}
      onClick={!isLoading ? handleClick : null}
    >
      {isLoading ? "Loading…" : "Click to load"}
    </Button>
  );
}*/

export const AddStaffForm = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const history = useHistory();
  const alert = useAlert();

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
        }}
        enableReinitialize
        validationSchema={Yup.object({
          firstName: Yup.string().required("First Name Required"),
          lastName: Yup.string(),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (values) {
            let params = {
              firstName: values.firstName.trim(),
              lastName: values.lastName.trim(),
              isStaff: true,
            };
            const result = await Auth.api("people/createBillingPerson", "post", params);
            if (result) {
              params = { ...listState.filter };
              try {
                const result2 = await Auth.api("instructors/stafflist", "get", params);
                console.log(result2);
                dispatch2(["STAFF_GET", result2.data]);
                let options = result2.data.options.map((option) => ({ ...option, color: option.color_code }));

                dispatch({
                  type: "GET_INSTRUCTOR_OPTIONS",
                  options: options,
                });
                //    alert.show(`New staff ${values.firstName} ${values.lastName} saved`);
              } catch (error) {
                console.log("error");
                console.log(error);
              }
            }
          }
          props.onSave(false);
        }}
      >
        <Form>
          <Row>
            <MediumPageHeader> {`Enter Name`}</MediumPageHeader>
          </Row>

          <Row>
            <Col>
              <MyTextInput name="firstName" type="text" placeholder="First Name" label="First Name" />
            </Col>
            <Col>
              <MyTextInput label="Last Name" name="lastName" type="text" placeholder="Last Name" />
            </Col>
          </Row>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 40 }}>
            <ButtonOrange type="submit" label="Save Staff Member" />
          </div>
        </Form>
      </Formik>
    </>
  );
};

export const AccountHolderForm = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const history = useHistory();
  console.log(history);
  const [showSaveButtons, setShowSaveButtons] = useState(true);

  useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        const result = await Auth.api("options/index", "get", params);
        dispatch({
          type: "UPDATE_OPTIONS",
          options: result.data,
        });
      };
      fetchData();
    },
    //eslint-disable-next-line
    [listState.clients]
  );
  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          isStudent: false,
          isStaff: false,
        }}
        enableReinitialize
        validationSchema={Yup.object({
          firstName: Yup.string().required("First Name Required"),
          lastName: Yup.string(),
          isStudent: Yup.boolean(),
          isStaff: Yup.boolean(),

          //      .required("Email Required"),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log("onSubmit");
          //  console.log(dependantValues);
          if (values) {
            console.log(values);

            //    setBillingPersonValues(`${values.firstName} ${values.lastName}`);
            let params = {};
            /*  try {
              dispatch({
                type: "FETCH_INIT"
              });*/
            params.firstName = values.firstName.trim();
            params.lastName = values.lastName.trim();
            params.isStudent = values.isStudent;
            params.isStaff = values.isStaff;

            //params = { name: values.firstName };
            const result = await Auth.api("people/createBillingPerson", "post", params);

            //  resetForm();
            //  setShowModal(false);
            // dispatch2(["SET_NEW_CLIENT_IDS", result.data]); //?don't know what this is
            dispatch2(["RETURN_NEW_CLIENT", result.data.newClient]);

            /* if (values.isStaff) { //todo put back
              try {
                const result = await Auth.api("instructors/index", "get", params);
                dispatch({
                  type: "GET_INSTRUCTORS",
                  payload: result.data.instructors,
                  options: map_options_instructor(result.data.instructors),
                });
              } catch (error) {
                console.log("error");
              }
            }*/
            //    dispatch2(["SET_CURRENT_STEP", 1]);
            //  setShowSaveButtons(false);
            //    <Redirect to="/clienthome/5143/contact" />;
            //history.push(`/clienthome/${result.data.newClient.id}/account`);
            props.onSave(false);
            // history.push("/admin/clients");
            /*  dispatch({
                type: "FETCH_SUCCESS"
              });
            } catch (err) {
              dispatch({
                type: "FETCH_ERROR",
                error: err
              });
              console.error(err);
            }*/
          }
        }}
      >
        <Form>
          <Row>
            <MediumPageHeader> {`Account Holder`}</MediumPageHeader>
          </Row>
          <Row>
            <HelpText>
              {
                "To create a new client, enter their name here if they will have their own account, or enter the name of the guardian/account holder.  Check the box if the Account Holder is also a student. Once saved, you will be prompted for the remaining information."
              }
            </HelpText>
          </Row>
          <Row>
            <Col>
              <MyTextInput name="firstName" type="text" placeholder="First Name" label="First Name" />
            </Col>
            <Col>
              <MyTextInput label="Last Name" name="lastName" type="text" placeholder="Last Name" />
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <div style={{ flex: "0 0 40%" }}>
              <MyCheckbox name={"isStudent"}>{"Is Account Holder also a Student?"}</MyCheckbox>
            </div>

            {1 == 2 && (
              <div style={{ flex: "0 0 40%" }}>
                <MyCheckbox name={"isStaff"}>{"Is Account Holder also a Staff Member?"}</MyCheckbox>
              </div>
            )}
          </Row>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 40 }}>
            <ButtonOrange type="submit" label={"Save Account Holder"} />
          </div>
        </Form>
      </Formik>
    </>
  );
};
/*
export const AddressForm = (props) => {
  //  const { values, submitForm } = useFormikContext();
  const { listState, dispatch2 } = useContext(ListContext);
  const [showSaveButtons, setShowSaveButtons] = useState(true);
  const [addressValues, setAddressValues] = useState("");
  const [currentPosition, setCurrentPosition] = useState({});
  const lat = 45;
  const long = -80;
  const params = {};

  useEffect(
    () => {
      navigator.geolocation.getCurrentPosition(function (position) {
        setCurrentPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    },
    //eslint-disable-next-line
    []
  );
  if (lodash.isEmpty(listState.newClientIds)) {
    return <div>you have to add an account holder first</div>;
  }
  return (
    <>
      <Formik
        initialValues={{
          address: props.address || "",
        }}
        enableReinitialize
        validationSchema={Yup.object({
          address: Yup.string(), //.required("Last Name Required"),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log(values);

          console.log(props);

          console.log(addressValues);

          //  dispatch2(["SET_CLIENT_CONTACT", values]);
          params.address = addressValues;
          params.person_id = listState.newClientIds.person_id;

          let result = await Auth.api(
            "people/createNewAddress",
            "post",
            params
          );

          dispatch2(["UPDATE_CLIENT_INFO", result.data]);
          setShowSaveButtons(false);
          resetForm();
        }}
      >
        <Form id="addressForm">
          <Header what={"Create Address Details for "} />

          <Row>
            <Col>
              <BlockLabel>Mailing Address</BlockLabel>
              <GooglePlacesAutocomplete
                onSelect={(address) => setAddressValues(address.description)}
                autocompletionRequest={{
                  location: currentPosition,
                  radius: 10000,
                }}
              />
            </Col>
          </Row>
          <Save
            showSaveButtons={showSaveButtons}
            newwhat={"Address Details"}
            havehas={"have"}
            for
          />
        </Form>
      </Formik>
    </>
  );
};
*/
export const StudentDetailsForm = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  return (
    <Formik
      initialValues={{
        date_of_birth: "",
        riding_level: "",
      }}
      enableReinitialize
      validationSchema={Yup.object({
        date_of_birth: Yup.date(), //.required("Last Name Required"),
        riding_level: Yup.string(),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        console.log(values);

        console.log(props);

        /*  if (values.phone1) params.phone1 = values.phone1;
          if (values.phone2) params.phone2 = values.phone2;
          dispatch2(["SET_CLIENT_CONTACT", values]);
          params.address = addressValues;*/
        //  Auth.api("people/create", "post", params);
      }}
    >
      <Form id="studentDetailsForm">
        <Row style={{ justifyContent: "center" }}> </Row>
        <Row>
          <Col>
            <datepicker label="Primary phone number" name="date_of_birth" type="date" />
          </Col>
          <Col>
            <MyTextInput label="Riding Level" name="riding_level" type="text" />
          </Col>
        </Row>
      </Form>
    </Formik>
  );
};

/*const ClientForm = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const steps = [];
  const [prevValues, setPrevValues] = useState();
  useEffect(
    () => {
      return () => {
        dispatch2(["SET_NEW_CLIENT_IDS", {}]);
      };
    },
    //eslint-disable-next-line
    []
  );
  return (
    <>
      <h1>client form </h1>
    </>
  );
};
export default ClientForm;*/
