import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Link, useParams, useHistory, withRouter, useLocation } from "react-router-dom";
import { Row, Col, Modal, Dropdown, Tabs, Tab } from "react-bootstrap";
import { ListContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import {
  BlueNavLink12,
  PrimaryText,
  PrimaryText18,
  PrimaryTextBlue,
  SaveButtonSm,
  CancelButtonSm,
  PageTitle,
  BlueText,
  HelpText,
  BlockLabelGrow,
  OrangeButton,
} from "./styleCustom";

import {
  currentDayToDB,
  timeISOtoString,
  dateISOtoStringMed,
  is_expired,
  calculate_expiry_date,
  in_future,
  dateObjToDBDate,
  dateISOtoString,
} from "./momentUtils";
import { PinIcon, Private, Public, Expand } from "./icons";

import OnlinePurchase from "./onlinePurchase";
import COLORS from "./styleConstants";
import { ModalHeaderBlue, UserCan, BackTo, Limit } from "./formComponents";
import { MyContext } from "./App";
import Booking from "./booking";

import { cancel_student_options } from "./Constants2";
import Select from "react-select";

import AccountPage from "./accountPage";
import { useAlert } from "react-alert";
import PrivateRoute from "./privateRoute";
import Logout from "./logout";

import AddDependantForm, { EditDependantName } from "./addDependantForm";

import ScheduledLessons, { History } from "./scheduledLessons";
import { LessonTable } from "./lessonTable";
import StudentForm from "./studentForm";
import SearchFilterDates from "./searchFilterDates";
import moment from "moment";
import { MemberLinks } from "./clientHomePage";

const Auth = new AuthHelperMethods();

const Purchases = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  let location = useLocation();
  const profileOnly = location.pathname.startsWith("/admin/calendarGroup/edit");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);

  const [currentPackage, setCurrentPackage] = useState();
  const [packageLessons, setPackageLessons] = useState([]);
  const [lessonsBefore, setLessonsBefore] = useState([]);
  const [lessonsAfter, setLessonsAfter] = useState([]);
  const [lessonsInPackage, setLessonsInPackage] = useState([]);

  const [lastLessonInPackage, setLastLessonInPackage] = useState();
  const [studentPurchases, setStudentPurchases] = useState();
  const [offset, setOffset] = useState(10);
  const [total, setTotal] = useState();
  const [scheduled_date, setScheduledDate] = useState();
  const limit = 10;
  let currentclientid = useParams();
  //  useFetch("students/studentPurchases", "CLIENT_DATA", "dispatchlist", params);
  const styleExpiry = useEffect(
    () => {
      const fetchData = async () => {
        dispatch({ type: "FETCH_INIT" });

        console.log("studentPurchases useeffect");
        let params = {
          student_id: listState.currentClient.student_id,
          limit: profileOnly ? 10 : offset,
          sortBy: "purchase_date",
          direction: "desc",
          ...(scheduled_date && { scheduled_date: scheduled_date }),
        };
        try {
          const result = await Auth.api("items/studentPurchases", "get", params);
          if (result) {
            setStudentPurchases(result.data.data);
            setTotal(result.data.size);
            // setCurrentPackage(result.data.data[0]);
            if (result.data.data.length > 0) getLessonsInPackage(result.data.data[0]);
          }
          dispatch({ type: "FETCH_SUCCESS" });
        } catch (error) {
          console.log("error");
          dispatch({ type: "FETCH_ERROR" });
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    [listState.currentClient.id, offset, scheduled_date]
  );

  const getLessonsInPackage = async (item) => {
    //setPackageLessons();
    /*  dispatch({
      type: "FETCH_INIT",
    });*/
    console.log(item);
    setCurrentPackage(item);
    let params = {
      item_id: item.id,
      student_id: listState.currentClient.student_id,
    };

    try {
      const result = await Auth.api("students/getStudentLessonsInPackage", "get", params);
      console.log(result.data.studentLessonsInPackage);
      setPackageLessons(result.data.studentLessonsInPackage);
      setLessonsBefore(result.data.before);
      setLessonsAfter(result.data.after);
    } catch (error) {
      console.log(error);
      // dispatch({ type: "FETCH_ERROR" });
      //  alert.show(error.message, { timeout: 3000, type: "error" });
    }
  };

  const redistrubutePackages = () => {
    let params = {};
    // params.scheduled_date = listState.filter.dbday || dateObjToDBDate(moment(Date()));
    params.scheduled_date = "2020-01-01";

    params.student_id = listState.currentClient.student_id || "";

    const result = Auth.api("items/everything", "get", params);
  };
  if (!studentPurchases) return <div>This student has no current packages</div>;

  const PackageMessage2 = (props) => {
    let packageLessons = props.packageLessons;
    console.log(packageLessons);
    console.log(packageLessons);

    useEffect(() => {
      let lastlen = packageLessons.length;
      if (lastlen > 0) setLastLessonInPackage(dateISOtoStringMed(packageLessons[lastlen - 1].scheduled_date));
    }, []);

    //  let msg = "";
    let expired = moment() > moment(currentPackage.package_expiry_date);
    let remain = currentPackage.remain;
    let lastLesson = lastLessonInPackage;
    let isLastLessonInPast = moment() > dateISOtoString(lastLessonInPackage);

    console.log(expired);
    console.log(remain);
    console.log(lastLesson);
    console.log(isLastLessonInPast);

    const msg = () => {
      if (expired) return "Package is expired";
      if (packageLessons.length == 0) return "Package has not yet been used";
      if (!expired && remain > 0)
        return `You will have ${remain} lesson(s) remaining after the last lesson booked on the ${lastLesson}`;
      if (!expired && remain == 0 && isLastLessonInPast)
        return `All the lessons in the package have been used.  The last lesson was on ${lastLesson}`;
      if (!expired && remain == 0 && !isLastLessonInPast)
        return `Package will expire with the last lesson on ${lastLesson}. You have used ${lessonsBefore.length} up until today.`;
    };

    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: "1 1 100%",
            marginBottom: 20,
            color: "red",
          }}
        >
          {" "}
          <PrimaryText> {msg}</PrimaryText>{" "}
        </div>
      </>
    );
  };

  const PackageMessage = () => {
    const Before = (props) => {
      return <PrimaryText style={{ marginLeft: 4, marginRight: 4 }}> {props.number}</PrimaryText>;
    };
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: "1 1 100%",
            flexWrap: "nowrap",
          }}
        >
          <div>This package has been applied to</div>
          <Before number={lessonsBefore.length} />
          <div>lessons completed up to today and</div>
          <Before number={lessonsAfter.length} />
          <div>lessons in the future. </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: "1 1 100%",
            marginBottom: 20,
            color: "red",
          }}
        >
          {" "}
          <PrimaryText>
            {" "}
            {lessonsAfter.length > 0 && `Package will expire with the last lesson booked on  ${lastLessonInPackage}`}
          </PrimaryText>{" "}
        </div>
      </>
    );
  };

  return (
    <>
      <MemberLinks verb={"View"} page={"Packages"} link={"purchases"} />
      {!studentPurchases.length > 0 && <div>no packages recorded</div>}
      {(Auth.getUserType() == "ADM" || mystate.farmDefaults.clients_can_book_lessons) && (
        <div>{"***  New! To book a makeup, click the orange 'Book Makeup' button ***** "}</div>
      )}
      {1 == 2 && <button onClick={() => setScheduledDate("2020-01-01")}>recalculate</button>}
      {1 == 2 && <button onClick={() => redistrubutePackages("2020-01-01")}>redistribure</button>}
      <div
        className="purchases"
        style={{
          display: "flex",
          flexWrap: "wrap",
          flex: "1 1 100%",
          border: "solid 1pt lightgrey",
        }}
      >
        <div>
          {studentPurchases.length > 0 &&
            studentPurchases.map((item) => (
              <>
                {" "}
                <div
                  key={item.id}
                  style={{
                    width: "100%",
                    background: "white",
                    border: "none",
                    borderBottom: "solid 1pt lightgrey",
                  }}
                  className={"packageRow"}
                >
                  <div
                    className={"after AT"}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      textAlign: "left",
                      padding: 10,
                      paddingLeft: 20,
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      className={"row1"}
                      style={{
                        display: "flex",
                        flex: "1 1  100%",
                      }}
                    >
                      <div
                        style={{
                          color: COLORS.blue,
                          cursor: "pointer",
                          padding: 5,
                          paddingLeft: 0,
                          minWidth: 20,
                          display: "flex",
                          alignItems: "center",
                        }}
                        disabled={mystate.isFetching}
                        onClick={() => getLessonsInPackage(item)}
                      >
                        <Expand onClick />
                      </div>
                      <div
                        style={{
                          color: COLORS.blue,
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      >
                        {item.product_name}
                      </div>
                    </div>
                    <div
                      className={"row1.5"}
                      style={{
                        display: "flex",
                        flex: "1 1 ",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="issued"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          minWidth: 100,
                        }}
                      >
                        <BlueText>{"issued"}</BlueText> <div>{item.purchase_date}</div>
                      </div>
                      <div
                        className="start"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          minWidth: 100,
                        }}
                      >
                        <BlueText>{"starts"} </BlueText>
                        <div>{item.package_start_date}</div>
                      </div>
                      <div
                        className="expiry"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          minWidth: 100,
                        }}
                      >
                        <BlueText> {is_expired(item.package_expiry_date) ? "expired " : "expires "} </BlueText>
                        <div
                          style={{
                            color: is_expired(item.package_expiry_date) ? "red" : null,
                          }}
                        >
                          {" "}
                          {item.package_expiry_date}
                        </div>
                      </div>
                    </div>
                    <div
                      className={"row1.75"}
                      style={{
                        display: "flex",
                        flex: "1 1 ",
                        justifyContent: "flex-start",
                      }}
                    >
                      {profileOnly && <div style={{ minWidth: 50 }} />}
                      <div
                        className="startCount"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          minWidth: 70,
                          alignItems: "center",
                        }}
                      >
                        <BlueText>{"start"}</BlueText>
                        <div style={{ alignItems: "center" }}>{item.start_count}</div>
                      </div>

                      <div
                        className="used"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          minWidth: 70,
                          alignItems: "center",
                        }}
                      >
                        <BlueText>{"used"}</BlueText>
                        <div style={{ alignItems: "center" }}>{item.usedtilltoday}</div>
                      </div>

                      <div
                        className="booked"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          minWidth: 70,
                          alignItems: "center",
                        }}
                      >
                        <BlueText>{"scheduled"}</BlueText>
                        <div style={{ alignItems: "center" }}>{item.usedaftertoday}</div>
                      </div>

                      <div
                        className="remainCount"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          minWidth: 70,
                          alignItems: "center",
                        }}
                      >
                        <BlueText>{"remaining"}</BlueText>
                        <div style={{ color: item.remain === 0 ? "red" : null }}>{item.remain}</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          minWidth: 100,
                          color: "red",
                          fontStyle: "italic",
                          fortWeight: "bold",
                        }}
                      >
                        {is_expired(item.package_expiry_date) && "expired"}
                      </div>
                    </div>

                    <div
                      className={"row2"}
                      style={{
                        display: "flex",
                        flex: "1 1 100%",
                        fontStyle: "italic",
                      }}
                    >
                      {item.item_notes}
                    </div>
                  </div>
                </div>
                {currentPackage && currentPackage.id == item.id && (
                  <div
                    className={"expanation"}
                    style={{
                      margin: 5,
                      border: `solid 10pt ${COLORS.lightgrey}`,
                      padding: 5,
                      display: "flex",
                      flex: "1 1 100%",
                      flexWrap: "wrap",
                      flexDirection: "column",
                    }}
                  >
                    {" "}
                    {packageLessons && (
                      <>
                        <TodayText item={item} />

                        <LessonTable lessons={lessonsBefore} className={"expandlessons"} hideText={true} {...props} />

                        <UpcomingText item={item} />

                        <LessonTable lessons={lessonsAfter} className={"expandlessons"} hideText={true} {...props} />

                        <AfterText item={item} />
                      </>
                    )}
                  </div>
                )}
              </>
            ))}
          {!profileOnly && (
            <Limit total={total} setOffset={setOffset} offset={offset} data={studentPurchases} limit={limit} />
          )}
        </div>
      </div>
    </>
  );
};

const todayStyle = { color: COLORS.orange, padding: 2 };

const UpcomingText = (props) => {
  const { item } = props;
  if (is_expired(item.package_expiry_date)) return null;

  if (item.usedaftertoday < 1) return null;

  return <div style={todayStyle}>{`Your package will cover these upcoming ${item.usedaftertoday} lessons:`}</div>;
};

const AfterText = (props) => {
  const { item } = props;
  if (item.usedaftertoday < 1) return null;
  if (is_expired(item.package_expiry_date)) return null;
  if (item.remain == 0) return null;
  return <div style={todayStyle}>{`You will have ${item.remain} left after this.`}</div>;
};

const TodayText = (props) => {
  const { item } = props;

  if (is_expired(item.package_expiry_date)) return null;
  return (
    <>
      <div style={todayStyle}>
        {`You have ${item.start_count - item.usedtilltoday} lessons left in your package as of today.`}
      </div>
      {item.usedtilltoday > 0 && (
        <div style={todayStyle}>
          {`You've used up ${item.usedtilltoday} lessons in your package as of today on these lessons:`}
        </div>
      )}
    </>
  );
};

export default Purchases;
