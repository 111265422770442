import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, NavLink } from "react-router-dom";
import { useParams, useHistory } from "react-router";
import { Formik, Form, useField, useFormikContext, Field } from "formik";
import * as Yup from "yup";
import { MediumPageHeader, BoxWithLabelW, BlueNavLink12, SaveButtonSm } from "./styleCustom";
import {
  MyTextInput,
  MyCheckbox,
  AddEditForm,
  OrangeButton,
  BlueLinkButton,
  ButtonOrange,
  ButtonBlue,
} from "./formComponents";
import Select from "react-select";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col } from "react-bootstrap";
import { ListContext, MyContext } from "./App";
import ToggleSwitch from "./ToggleSwitch";
import { truncate } from "lodash";
import PrivateRoute from "./privateRoute";
import { available_options, active_options, cancel_student_options, cancel_by_barn_options } from "./Constants2";
import { SelectTemplateFilter, SelectTemplateFilterNoLabel, Active } from "./searchFilterReports";
import Logout from "./logout";
import COLORS from "./styleConstants";
import { CheckSquare, Square, CheckYes, CheckNo } from "./icons";
import { v4 as uuidv4 } from "uuid";
import { timeISOtoStringN, dateObjToDBTime } from "./momentUtils";
import DatePicker from "react-datepicker";
import { DISPLAY_TIME } from "./UserSettings";
import moment from "moment";

const Auth = new AuthHelperMethods();

const SuperUser = () => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const [farmOptions, setFarmOptions] = useState([]);
  const [currentFarm, setCurrentFarm] = useState();
  const [farmList, setFarmList] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState();
  const [currentField, setCurrentField] = useState();
  const [finishedEdit, setFinishedEdit] = useState(false);
  const [showAddFarm, setShowAddFarm] = useState(false);
  const [optionsProduct, setOptionsProduct] = useState([]);
  const [optionsSection, setOptionsSection] = useState([]);
  const [optionsPlan, setOptionsPlan] = useState([]);
  const [farmName, setFarmName] = useState([]);

  //'farms/updateFarmPreferences'
  useEffect(
    () => {
      const fetchData = async () => {
        try {
          dispatch({ type: "FETCH_INIT" });

          let params = { farm_id: currentFarm.id, value: currentValue, field: currentField };
          const result = await Auth.api("farms/updateFarmPreferences", "post", params);

          dispatch({ type: "FETCH_SUCCESS" });
          setFinishedEdit(false);
        } catch (error) {
          dispatch({ type: "FETCH_ERROR" });
          setFinishedEdit(false);

          console.log("error");
        }
      };

      if (finishedEdit) fetchData();
    },
    //eslint-disable-next-line
    [finishedEdit]
  );

  useEffect(
    () => {
      const fetchData = async () => {
        console.log("***************** FARM FETCH *****************");
        try {
          let params = { ...listState.filter };
          const result = await Auth.api("farms/farmList", "get", params);
          let confList = result.data.farms;
          confList = confList.map((detail) => ({
            ...detail,
            min_time: moment(detail.min_time, "hh:mm ").toDate(),
            max_time: moment(detail.max_time, "hh:mm ").toDate(),
          }));
          setFarmList(confList);
          console.log(result.data.farms);
          console.log(confList);

          let test = result.data.farms[0];
          console.log(test);

          dispatch({ type: "FETCH_SUCCESS" });
        } catch (error) {
          console.log("error");
        }
      };

      fetchData();
    },
    //eslint-disable-next-line
    [listState.filter]
  );

  const getFarmWaivers = async () => {
    // const result = await Auth.api("farms/farmList", "get", params);
  };

  const updateIsActive = async (id) => {
    let params = { ...listState.filter };
    params.id = id;
    const result = await Auth.api("farms/updateActivationStatus", "post", params);

    console.log(result.data);
  };

  const editFarm = async (farm) => {
    setShowEdit(true);
    setCurrentFarm(farm);
    let params = { farm_id: farm.id };
    const result = await Auth.api("super/index", "get", params);
    setOptionsProduct(result.data.options_product);
    setOptionsSection(result.data.options_section);
    setOptionsPlan(result.data.options_plan);
    console.log(farm);
  };

  const clickYesNo = (value, field) => {
    console.log(value);
    console.log(field);
    setCurrentField(field);
    setCurrentValue(value);
    setCurrentFarm({ ...currentFarm, [field]: value });

    console.log("FINISHED");
    setFinishedEdit(true);
  };

  const changeTime = (value, field) => {
    console.log(value);
    console.log(field);
    setCurrentField(field);
    setCurrentValue(dateObjToDBTime(value));
    setCurrentFarm({ ...currentFarm, [field]: value });

    console.log("FINISHED");
    setFinishedEdit(true);
  };

  const YesNo = (props) => {
    return (
      <div style={{ display: "flex", direction: "row", alignItems: "center" }}>
        {props.value == 0 && (
          <>
            <div onClick={() => clickYesNo("1", props.field)}>
              <CheckNo fontSize={40} margin={20} yn={"Yes"} />{" "}
            </div>

            <div onClick={() => clickYesNo("0", props.field)}>
              <CheckYes fontSize={40} margin={20} yn={"No"} />
            </div>
          </>
        )}
        {props.value == 1 && (
          <>
            <div onClick={() => clickYesNo("1", props.field)}>
              <CheckYes fontSize={40} margin={20} yn={"Yes"} />{" "}
            </div>

            <div onClick={() => clickYesNo("0", props.field)}>
              <CheckNo fontSize={40} margin={20} yn={"No"} />{" "}
            </div>
          </>
        )}
      </div>
    );
  };
  const handleChangeInput = (e, field) => {
    console.log(field);
    console.log(e.target.value);
    setCurrentField(field);
    setCurrentValue(e.target.value);
  };
  const handleFinishInput = () => {
    console.log("FINISHED");
    console.log(currentField);
    console.log(currentValue);
    setFinishedEdit(true);
  };

  const addPublicId = async () => {
    let x = uuidv4();

    try {
      dispatch({ type: "FETCH_INIT" });

      let params = { farm_id: currentFarm.id, value: x, field: "public_id" };
      const result = await Auth.api("farms/updateFarmPreferences", "post", params);

      dispatch({ type: "FETCH_SUCCESS" });
      setFinishedEdit(false);
    } catch (error) {
      dispatch({ type: "FETCH_ERROR" });
      setFinishedEdit(false);

      console.log("error");
    }
  };
  const handleChangeSelect = (selected, field) => {
    console.log("handleChangeSelect");
    console.log(selected);

    setCurrentField(field);
    setCurrentValue(selected.value);
    setFinishedEdit(true);
    setCurrentFarm({ ...currentFarm, [field]: selected.value });
  };

  const createStripeCustomer = async () => {
    const params = { farm_id: currentFarm.id };
    try {
      const result = await Auth.api("farms/createEqoStripeCustomer", "post", params);
      alert("Created");
    } catch (e) {
      alert(e);
    }
  };

  if (Auth.getUserType() != "SPR") return null;
  if (!farmList) return null;
  if (!farmList[0]) return null;

  return (
    <div style={{ display: "flex", flexWrap: "wrap", margin: 50, flex: "1 1 100%" }}>
      <Logout />
      {showEdit && (
        <div className="farmEdit" style={{ display: "flex", flexWrap: "noWrap", flex: "1 1 100%" }}>
          <div
            className="settings"
            style={{ display: "flex", flexDirection: "column", flex: "1 1 25%", flexWrap: "wrap" }}
          >
            <h4>{currentFarm.farm_name}</h4>
            <ButtonOrange label={"Add Public Farm Id"} onClick={() => addPublicId()} />
            <ButtonOrange onClick={() => createStripeCustomer()} label={"Create Customer in Stripe"} />

            <div style={{ marginTop: 20, fontSize: 16, background: COLORS.blue, color: "white", padding: 5 }}>
              {"Adjustment Product "}
            </div>

            <Select
              onChange={(selectedOption) => handleChangeSelect(selectedOption, "adjustment_product_id")}
              className="form-control"
              options={optionsProduct}
              clearable={true}
              searchable={false}
              placeholder={"Select Package..."}
              value={optionsProduct.filter((product) => product.value == currentFarm.adjustment_product_id)}
            />
            <div style={{ fontSize: 16, background: COLORS.blue, color: "white", padding: 5 }}>{"Makeup Product"}</div>
            <Select
              onChange={(selectedOption) => handleChangeSelect(selectedOption, "makeup_product_id")}
              className="form-control"
              options={optionsProduct}
              clearable={true}
              searchable={false}
              placeholder={"Select Package..."}
              value={optionsProduct.filter((product) => product.value == currentFarm.makeup_product_id)}
            />
            <div style={{ fontSize: 16, background: COLORS.blue, color: "white", padding: 5 }}>{"Credit Product"}</div>
            <Select
              onChange={(selectedOption) => handleChangeSelect(selectedOption, "credit_product_id")}
              className="form-control"
              options={optionsProduct}
              clearable={true}
              searchable={false}
              placeholder={"Select Package..."}
              value={optionsProduct.filter((product) => product.value == currentFarm.credit_product_id)}
            />
            <div style={{ fontSize: 16, background: COLORS.blue, color: "white", padding: 5 }}>{"Default Section"}</div>
            <Select
              onChange={(selectedOption) => handleChangeSelect(selectedOption, "default_section_id")}
              className="form-control"
              options={optionsSection}
              clearable={true}
              searchable={false}
              placeholder={"Select Section..."}
              value={optionsSection.filter((section) => section.value == currentFarm.default_section_id)}
            />
            <div style={{ fontSize: 16, background: COLORS.blue, color: "white", padding: 5 }}>
              {"Default Student Cancel Lesson  Action"}
            </div>
            <Select
              onChange={(selectedOption) => handleChangeSelect(selectedOption, "client_cancel_default_action")}
              className="form-control"
              options={cancel_student_options}
              clearable={true}
              searchable={false}
              placeholder={"Select Action..."}
              value={cancel_student_options.filter(
                (option) => option.value == currentFarm.client_cancel_default_action
              )}
            />

            <div style={{ fontSize: 16, background: COLORS.blue, color: "white", padding: 5 }}>
              {"Default Barn Cancel Lesson  Action"}
            </div>
            <Select
              onChange={(selectedOption) => handleChangeSelect(selectedOption, "lesson_cancel_default_action")}
              className="form-control"
              options={cancel_by_barn_options}
              clearable={true}
              searchable={false}
              placeholder={"Select Action..."}
              value={cancel_by_barn_options.filter(
                (option) => option.value == currentFarm.lesson_cancel_default_action
              )}
            />
            <div style={{ fontSize: 16, background: COLORS.blue, color: "white", padding: 5 }}>{"Stripe Plan"}</div>
            <Select
              onChange={(selectedOption) => handleChangeSelect(selectedOption, "eqo_stripe_plan_id")}
              className="form-control"
              options={optionsPlan}
              clearable={true}
              searchable={false}
              placeholder={"Select Action..."}
              value={optionsPlan.filter((option) => option.value == currentFarm.eqo_stripe_plan_id)}
            />

            <div style={{ display: "flex", flexDirection: "column", marginBottom: 20 }}>
              <div style={{ fontSize: 16, width: 300, background: COLORS.blue, color: "white", padding: 5 }}>
                {"calendar start time"}
              </div>
              <div style={{ width: 300 }}>
                <DatePicker
                  showTimeSelect
                  dateFormat={DISPLAY_TIME}
                  onChange={(date) => changeTime(date, "min_time")}
                  showTimeSelectOnly
                  selected={currentFarm.min_time}
                />
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginBottom: 20 }}>
              <div
                style={{
                  fontSize: 16,
                  width: 300,
                  background: COLORS.blue,
                  color: "white",
                  padding: 5,
                  border: `solid 1pt ${COLORS.blue}`,
                }}
              >
                {"calendar end time"}
              </div>
              <div style={{ width: 300 }}>
                <DatePicker
                  showTimeSelect
                  dateFormat={DISPLAY_TIME}
                  onChange={(date) => changeTime(date, "max_time")}
                  showTimeSelectOnly
                  name="default_start_time"
                  selected={currentFarm.max_time}
                />
              </div>
            </div>

            {Object.keys(currentFarm).map((key, i) => (
              <div style={{ display: "flex", flexDirection: "column", marginBottom: 20 }}>
                <div style={{ fontSize: 16, width: 300, background: COLORS.blue, color: "white", padding: 5 }}>
                  {key.replaceAll("_", " ")}
                </div>
                <div style={{ width: 300 }}>
                  {currentFarm[key] == 1 || currentFarm[key] == 0 ? (
                    <YesNo value={currentFarm[key]} field={key} />
                  ) : (
                    <input
                      style={{ width: 300, padding: 10, fontSize: 14 }}
                      defaultValue={currentFarm[key]}
                      onChange={(e) => handleChangeInput(e, key)}
                      onBlur={() => handleFinishInput()}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          <div
            className={"other"}
            style={{
              display: "flex",
              flexDirection: "row",
              flex: "1 1 auto",
              flexWrap: "noWrap",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                className="programType"
                style={{
                  disiplay: "flex",
                  flexDirection: "column",
                  width: 300,
                  margin: 10,
                  flex: "1 1 100%",
                  padding: 20,
                  border: "solid 1pt lightgrey",
                  alignContents: "flex-start",
                }}
              >
                <MediumPageHeader>{`Create Program Types for ${currentFarm.farm_name}`}</MediumPageHeader>

                <Formik
                  initialValues={{ farm_id: "", name: "", desc: "" }}
                  enableReinitialize
                  onSubmit={async (values, { resetForm }) => {
                    //   e.stopPropagation();
                    let params = { ...values, farm_id: currentFarm.id };
                    try {
                      const result = await Auth.api("setup/program_types", "post", params);
                      alert("Saved");
                    } catch (e) {
                      alert(e);
                    }
                    resetForm();
                  }}
                >
                  <Form>
                    <MyTextInput label="Name" name="name" type="string" />
                    <MyTextInput label="desc" name="desc" type="string" />
                    <div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
                      <ButtonOrange type="submit" label={"save"} />
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
            <div
              className="addAdminUser"
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: 300,
                flex: "1 1 100%",
                margin: 10,
                padding: 20,
                border: "solid 1pt lightgrey",
              }}
            >
              <MediumPageHeader>{`Create Admin User for ${currentFarm.farm_name}`}</MediumPageHeader>

              <Formik
                initialValues={{ farmId: "", personName: "", userEmail: "" }}
                enableReinitialize
                validationSchema={Yup.object({
                  name: Yup.string(),
                  contactName: Yup.string(),
                  email: Yup.string(),
                })}
                onSubmit={async (values, { resetForm }) => {
                  let params = { ...values, farm_id: currentFarm.id };
                  try {
                    const result = await Auth.api("vusers/createAdminAccount", "post", params);
                    alert("Saved");
                    resetForm();
                  } catch (e) {
                    alert(e);
                  }
                }}
              >
                <Form id="createAdminUser" display={{ border: "solid red" }} key={"user"}>
                  <MyTextInput label="Name" name="name" type="string" />
                  <MyTextInput label="email" name="email" type="string" />
                  <div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
                    <ButtonOrange type="submit" form="createAdminUser" label={"save"} />
                  </div>
                </Form>
              </Formik>
            </div>
            <div
              className="waiver"
              style={{
                disiplay: "flex",
                flexDirection: "column",
                width: 300,
                margin: 10,
                flex: "1 1 100%",
                padding: 20,
                border: "solid 1pt lightgrey",
                alignContents: "flex-start",
              }}
            >
              <MediumPageHeader>{`Create Waiver Templates for ${currentFarm.farm_name}`}</MediumPageHeader>

              <Formik
                initialValues={{ farm_id: "", tempate_id: "", title: "", is_public: 0, create_user_account: 1 }}
                enableReinitialize
                onSubmit={async (values, { resetForm }) => {
                  //   e.stopPropagation();
                  let params = { ...values, farm_id: currentFarm.id };
                  try {
                    const result = await Auth.api("waiver_template/create", "post", params);

                    alert("Saved");
                  } catch (e) {
                    alert(e);
                  }
                  resetForm();
                }}
              >
                <Form>
                  <MyTextInput label="tempate_id" name="template_id" type="string" />
                  <MyTextInput label="title" name="title" type="string" />
                  <MyTextInput label="isPublic" name="is_public" type="int" />
                  <MyTextInput label="create user account" name="create_user_account" type="int" />

                  <div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
                    <ButtonOrange type="submit" label={"save"} />
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      )}
      {!showEdit && (
        <div>
          <ButtonOrange label={"Add New Farm"} onClick={() => setShowAddFarm(true)} />
          {showAddFarm && (
            <div style={{ display: "flex", flex: "1 1 100%" }}>
              <AddEditForm>
                <Formik
                  initialValues={{ farmId: "", personName: "", userEmail: "" }}
                  enableReinitialize
                  validationSchema={Yup.object({
                    name: Yup.string(),
                    contactName: Yup.string(),
                    email: Yup.string(),
                  })}
                  onSubmit={async (values, { resetForm }) => {
                    try {
                      const result = await Auth.api("farms/create", "post", values);
                      alert("New Farm Saved");
                    } catch (e) {
                      alert(e);
                    }

                    resetForm();
                  }}
                >
                  <Form id="addFarm">
                    <Row style={{ display: "flex", justifyContent: "center" }}>
                      <MediumPageHeader>{"Add New Farm"}</MediumPageHeader>
                    </Row>
                    <div style={{ marginBottom: 10 }}>
                      <Row
                        noGutters
                        style={{ display: "flex", justifyContent: "space-evenly", flexDirection: "column" }}
                      >
                        <MyTextInput label="Farm Name" name="name" type="string" />

                        <MyTextInput label="Contact Name" name="contactName" type="string" />
                        <MyTextInput label="email" name="email" type="string" />
                        <MyTextInput label="country" name="country_code" type="string" />
                      </Row>
                    </div>
                    <Row style={{ display: "flex", justifyContent: "center" }}>
                      <ButtonBlue onClick={() => setShowAddFarm(false)} label={"Cancel"} />

                      <ButtonOrange type="submit" label={"Save"} />
                    </Row>
                  </Form>
                </Formik>
              </AddEditForm>
            </div>
          )}
          <div>
            <MediumPageHeader>Farm List</MediumPageHeader>
          </div>
          <Active field={"is_active"} default={"1"} options={active_options} />
          <div style={{ display: "flex", flexDirection: "column", flex: "1 1 100%", flexWrap: "wrap" }}>
            <table>
              <thead>
                <tr>
                  {Object.keys(farmList[0]).map((key) => (
                    <th>{key.replaceAll("_", " ")}</th>
                  ))}
                </tr>
              </thead>
              {farmList.map((farm) => (
                <tr>
                  <td onClick={() => editFarm(farm)}>{farm.farm_name}</td>
                  <td>{farm.is_active ? "active" : "inactive"}</td>
                  <td onClick={() => updateIsActive(farm.id)} style={{ color: COLORS.orange, cursor: "pointer" }}>{`${
                    farm.is_active ? "inactivate" : "activate"
                  }`}</td>
                  {Object.keys(farm).map((key, i) => (
                    <td>{`${farm[key]}  `}</td>
                  ))}
                </tr>
              ))}
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuperUser;
