import React, { useState, useContext, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import getRepeatFirstLast from "./getLastDate";
import SelectSectionDefaults from "./selectSectionDefaults";
import SelectInstructor from "./selectInstructor";
import SelectStudent from "./selectStudent";
import SelectHorse from "./selectHorse";
import SelectLocation from "./selectLocation";
import TextArea from "./textInputgeneric";
import TextInputTitle from "./textInputTitle";
import TextInputDesc from "./textInputDesc";
import SelectProduct from "./selectProduct";
import TimeAndDate from "./timeanddate";
import RepeatingDates from "./repeatingDates";
import { Button, Row, Col, Container, Modal } from "react-bootstrap";
import { MyContext, ListContext } from "./App";
import axios from "axios";
import {
  formatDateTimeForDB,
  dateObjToDisplayDate,
  dateObjToDisplayTime,
  dateObjToDBDate,
  getNewDefaultDateforMonth,
  calculateEndTime,
  repeatOptions,
  dateISOtoString,
  dateISOtoStringMed,
  dateISOtoStringShort,
  timeISOtoStringN,
  currentDayToDB,
} from "./momentUtils";
import AuthHelperMethods from "./AuthHelperMethods";
import "./style.css";
import ReactTooltip from "react-tooltip";
import { useAlert } from "react-alert";
import "./styleAdd.css";
import { TitleRow } from "./blueCheckFuture";
import { ClientAvatar, HorseAvatar, StaffAvatar, LocationAvatar } from "./images";
import { NoteIcon, LocationIcon, RepeatIcon, ClockIcon, TitleIcon } from "./icons";
import moment from "moment";
import COLORS from "./styleConstants";
import { SelectTemplateFilter } from "./searchFilterReports";
import Payment from "./payment";
import PrivateRoute from "./privateRoute";
import { ButtonOrange, ButtonBlue } from "./formComponents";
import { ModalWarning } from "./formComponents";
import { CartList, CartTotal } from "./onlinePurchase";
import ManualPurchase from "./manualPurchaseForm";
import { TableHeaderNoAdd, OrangeLinkButton } from "./formComponents";

const Auth = new AuthHelperMethods();

const orangelabel = {};

//render(<AlertDismissibleExample />);

export const RegistrationClient = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [data, setData] = useState([]);
  const [showConf, setShowConf] = useState(false);
  const [currentReg, setCurrentReg] = useState();

  useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        try {
          // const result = await Auth.api("public/public", "get", params);
          const result = await Auth.api(
            `http://localhost:3001/lesson_date_time_series/getRegistrationList`,
            "get",
            params
          );
          setData(result.data.counts);
          dispatch2(["CLEAR_CART"]);
        } catch {
          return "error";
        }
      };
      fetchData();
    },
    //esnt-disable-next-line
    []
  );

  const handleClickRegistration = async (reg) => {
    if (reg.max_enrollment - reg.cnt < 1) {
      alert("Session is already full");
      return;
    }
    console.log(reg);
    let params2 = { series_id: reg.id, student_id: listState.currentClient.student_id };

    const result2 = await Auth.api(
      `http://localhost:3001/lesson_date_time_series/addStudentToRegistration`,
      "post",
      params2
    );

    if (result2.data.success == 3) {
      console.log(result2.data.success);
      alert("student is already registered in this session");
      return;
    }
    setCurrentReg(reg);
    setShowConf(true);

    let params = { id: reg.product_id };
    try {
      const result = await Auth.api(`http://localhost:3001/products/getProduct`, "get", params);
      let product = result.data.product;
      product.counter = 1;

      product.product_id = product.id;
      product.start_count = product.product_count;
      product.package_start_date = dateISOtoString(reg.starttime);
      product.purchase_date = currentDayToDB();
      product.package_expiry_date = dateISOtoString(reg.endtime);
      product.student_id = listState.currentClient.student_id || null;
      product.student_name = listState.currentClient.first_name || listState.currentClient.person_name;
      product.product_name = product.product_name;
      product.fee = mystate.farmDefaults.stripe_convenience_fee;
      product.registration_id = reg.id;
      product.registration_title = reg.title;
      console.log(product.package_start_date);
      console.log(result.data.product);
      dispatch2(["SET_CURRENT_PRODUCT", product]);

      dispatch2(["ADD_TO_CART", result.data.product]);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteRegistration = async (reg) => {
    console.log(reg);
    let params = { series_id: reg.registration_id, student_id: reg.student_id };
    const result = await Auth.api(
      `http://localhost:3001/lesson_date_time_series/deleteStudentRegistration`,
      "post",
      params
    );
  };

  const removeProduct = (productin) => {
    console.log(productin);
    let stripe_convenience_fee = mystate.farmDefaults.stripe_convenience_fee;
    dispatch2(["REMOVE_FROM_CART", productin, stripe_convenience_fee]);
    handleDeleteRegistration(productin);
  };

  const checkout = () => {
    //** also has link  *//
    if (listState.cartTotal < parseFloat(0.01)) alert("cart total is empty");
  };

  const cancelCheckout = () => {
    dispatch2(["CLEAR_CART"]);
  };

  return (
    <div>
      <CartList removeProduct={removeProduct} />
      <CartTotal />
      {listState.cartProducts.length > 0 && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flex: "1 1 100%",
            }}
          >
            {mystate.farmDefaults.stripe_account_id && (
              <Link to={`${props.match.url}/payments`}>
                <ButtonOrange
                  onClick={() => checkout()}
                  label={Auth.getUserType() == "ADM" ? "Online Card Payment" : "Pay Now"}
                />
              </Link>
            )}
            {Auth.getUserType() == "ADM" && (
              <Link to={`${props.match.url}/manual`}>
                <ButtonOrange label={"Manual Payment "} />
              </Link>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flex: "1 1 100%",
            }}
          >
            <ButtonBlue onClick={() => cancelCheckout()} label={"Cancel"} />
          </div>
        </>
      )}

      <PrivateRoute exact path={`${props.match.url}/payments`} component={Payment} />
      <PrivateRoute exact path={`${props.match.url}/manual`} component={ManualPurchase} />
      {showConf && <div>{`You are registering for ${currentReg.title}`}</div>}

      {data &&
        data.map((reg) => (
          <div
            onClick={() => handleClickRegistration(reg)}
            style={{ border: "solid .5pt", padding: 15, minWidth: 600, marginBottom: 5, color: COLORS.textgrey }}
          >
            <div className="title" style={{ marginBottom: 5, color: COLORS.textDark, fontWeight: "bold" }}>
              {reg.title}
            </div>
            <div>
              {`
              ${dateISOtoString(reg.starttime)} to   ${dateISOtoString(reg.endtime)}
             `}
            </div>
            <div className="more">{reg.description}</div>
            <div className="available">
              {reg.max_enrollment - reg.cnt < 1 ? "FULL" : `${reg.max_enrollment - reg.cnt} spots available`}
            </div>
            <div onClick={() => handleDeleteRegistration(reg)}>remove</div>
          </div>
        ))}
    </div>
  );
};
