import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, NavLink } from "react-router-dom";

import { ListContext, MyContext } from "./App";
import { PrimaryText, Td, StyledTr, TableText1, TableText2, Th, HelpText, StyledLink } from "./styleCustom";
import decode from "jwt-decode";
import axios from "axios";
import AuthHelperMethods from "./AuthHelperMethods";
import COLORS from "./styleConstants";
import { CheckSquare, Check } from "./icons";
import { OrangeLinkButton, TableHeaderNoAdd } from "./formComponents";
import ProductForm from "./productForm";
import ProductAssignProgram from "./productAssignProgram";
import PrivateRoute from "./privateRoute";
import { Active } from "./searchFilterReports";
import { active_options } from "./Constants2";
const Auth = new AuthHelperMethods();

const ProductTable = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  const isAdmin = true;
  const baseurl = "/admin/setup/packages";

  useEffect(
    () => {
      let params = { ...listState.filter };

      const fetchData = async () => {
        const result = await Auth.api("products/index", "get", params);
        let products = result.data;

        dispatch2(["PRODUCTS_GET", result.data]);
      };
      fetchData();
    },
    //eslint-disable-next-line
    [listState.filter]
  );

  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("product_programs/index", "get");
        dispatch2(["PRODUCT_PROGRAMS_GET", result.data]);
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  const handleClickEditProduct = (product) => {
    console.log();
    console.log(product);
    dispatch2(["SET_CURRENT_LIST_DETAIL", product]);
    let formInitialValues = {
      id: product.id,
      name: product.product_name || "",
      desc: product.product_desc || "",
      price: product.product_price || "",
      count: product.product_count || "",
      purchase_online: product.purchase_online || false,
      expires_in: product.expires_in || "",
      is_tracked: product.is_tracked,
      renew_day_of_month: product.is_auto ? product.renew_day_of_month : "no",
    };
    dispatch2(["SET_FORM_INITIAL_VALUES", formInitialValues]);
  };

  const getSingleProductPrograms = (productId) => {
    console.log("getSingleProductPrograms");
    return (
      listState.productPrograms
        //.filter(item => item.product_id === productId)
        //.map(item2 => item2.name)
        .reduce((acc, { product_id, name }) => {
          if (product_id === productId) {
            acc.push(name);
          }
          return acc;
        }, [])
        .join(", ")
    );
  };

  const updateIsActive = async (id) => {
    let params = { ...listState.filter };
    params.id = id;
    const result = await Auth.api("products/updateActivationStatus", "post", params);
    // dispatch2(["GET_SECTION_LIST", result.data]);
    //dispatch2(["SECTION_EDIT", result.data]);
    //dispatch2(["GET_SECTION_LIST", result.data]);
    dispatch2(["PRODUCTS_GET", result.data]);

    console.log(result.data);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flex: "1 1 100%",
          justifyContent: "center",
        }}
      >
        <PrivateRoute path={`${props.match.url}/packageform/:addoredit`} component={ProductForm} />
        <PrivateRoute path={`${props.match.url}/packageprogram/:id`} component={ProductAssignProgram} />
      </div>
      <TableHeaderNoAdd title="Packages">
        <Link to={`${props.match.url}/packageform/add`}>
          <OrangeLinkButton>{"Add New Package"} </OrangeLinkButton>{" "}
        </Link>
      </TableHeaderNoAdd>
      <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
        <span style={{ marginRight: 10 }}>{"Showing:"}</span>
        <Active field={"is_active"} default={"1"} options={active_options} />
      </div>
      {listState.products && (
        <table style={{ width: "100%" }}>
          <thead>
            <tr style={{ color: `${COLORS.blue}` }}>
              <Th>
                {" "}
                <div>{"Name"}</div>
                <HelpText>{"Click on Product Name to edit"}</HelpText>
              </Th>
              <Th>Price</Th>
              <Th>Tracked</Th>
              <Th>Count</Th>

              <Th>Valid For (days)</Th>
              <Th>Online</Th>
              {1 == 2 && <Th>Auto Renew</Th>}

              <Th>
                <div> {"Valid Programs"}</div>
                <HelpText>{"Click on Valid Programs to Add/Remove associated programs"}</HelpText>
              </Th>
            </tr>
          </thead>
          <tbody>
            {listState.products.length > 0 &&
              listState.products.map((product) => (
                <StyledTr key={product.id} recent={product.recent}>
                  <Td>
                    <StyledLink
                      to={`${props.match.url}/packageform/edit`}
                      onClick={() => handleClickEditProduct(product)}
                    >
                      <TableText2>{product.product_name} </TableText2>
                    </StyledLink>
                  </Td>
                  <Td>
                    <TableText2>
                      {`${mystate.farmDefaults.currency_symbol}${product.product_price}
                    `}
                    </TableText2>
                  </Td>
                  <Td>
                    <TableText2>{product.is_tracked ? <Check /> : ""}</TableText2>
                  </Td>

                  <Td>
                    <TableText2>{product.product_count}</TableText2>
                  </Td>

                  <Td>
                    <TableText2>{product.expires_in}</TableText2>
                  </Td>
                  <Td>
                    <TableText2>{product.purchase_online ? <Check /> : ""}</TableText2>
                  </Td>
                  {1 == 2 && (
                    <Td>
                      <TableText2>{product.is_auto ? <Check /> : ""}</TableText2>
                    </Td>
                  )}
                  <Td>
                    <Link to={`${props.match.url}/packageprogram/${product.id}`}>
                      <TableText2>{getSingleProductPrograms(product.id) || "no assigned programs"}</TableText2>
                    </Link>
                  </Td>
                  <Td onClick={() => updateIsActive(product.id)} style={{ color: "red", cursor: "pointer" }}>{`${
                    product.is_active ? "inactivate" : "activate"
                  }`}</Td>
                </StyledTr>
              ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default ProductTable;
