import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { MyContext, ListContext } from "./App";
import COLORS, { buttonStyle } from "./styleConstants";
import { PrimaryText, PrimaryTextBlue, BlueNavLink, OrangeButton, BlueButton } from "./styleCustom";
import { CheckSquare, Square, CheckYes, CheckNo } from "./icons";
import { Active, ButtonTemplateFilter } from "./searchFilterReports";
import AuthHelperMethods from "./AuthHelperMethods";
import Select from "react-select";
import { DISPLAY_TIME } from "./UserSettings";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateObjToDBTime } from "./momentUtils";
import { ButtonOrange } from "./formComponents";

const Auth = new AuthHelperMethods();

export const FarmUpdatePrefs = () => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const [currentValue, setCurrentValue] = useState("");
  const [currentField, setCurrentField] = useState("");
  const [finishedEdit, setFinishedEdit] = useState(false);
  const [prefs, setPrefs] = useState();
  const [boolprefs, setBoolPrefs] = useState();
  const [boolprivs, setBoolPrivs] = useState();
  const [calendarPrefs, setCalendarPrefs] = useState();
  const [farmDefaults, setFarmDefaults] = useState();
  let cv = "";
  let cf = ";";

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          dispatch({ type: "FETCH_INIT" });

          const result = await Auth.api("farms/getFarmPrefs", "get");

          setFarmDefaults(result.data.farmDefaults);

          dispatch({ type: "FETCH_SUCCESS" });
        } catch (error) {
          dispatch({ type: "FETCH_ERROR" });

          console.log("error");
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          dispatch({ type: "FETCH_INIT" });

          let params = { value: currentValue, field: currentField };
          //   let params = { value: currentValue, field: cf };

          const result = await Auth.api("farms/updateFarmPreferencesClient", "post", params);

          dispatch({
            type: "UPDATE_FARM_DEFAULTS",
            data: result.data.farmDefaults,
          });
          dispatch({ type: "FETCH_SUCCESS" });
          setFinishedEdit(false);
          setCurrentValue(false);
          setCurrentField(false);
          setFarmDefaults(result.data.farmDefaults);
        } catch (error) {
          console.log(error);
          dispatch({ type: "FETCH_ERROR" });
          setFinishedEdit(false);

          console.log("error");
        }
      };

      if (finishedEdit) fetchData();
    },
    //eslint-disable-next-line
    [finishedEdit]
  );

  const clickPortal = async () => {
    const result = await Auth.api("payments/stripePortal", "post", {});
    if (result) window.location.href = result.data.portal;
  };

  const clickYesNo = (value, field) => {
    console.log(value);
    console.log(field);
    setCurrentField(field);
    setCurrentValue(value);
    console.log("FINISHED");
    setFinishedEdit(true);
  };

  const YesNo = (props) => {
    return (
      <div style={{ display: "flex", direction: "row", alignItems: "center", marginTop: 10 }}>
        {props.value == 0 && (
          <>
            <div onClick={() => clickYesNo("1", props.field)}>
              <CheckNo fontSize={40} margin={20} yn={"Yes"} />{" "}
            </div>

            <div onClick={() => clickYesNo("0", props.field)}>
              <CheckYes fontSize={40} margin={20} yn={"No"} />
            </div>
          </>
        )}
        {props.value == 1 && (
          <>
            <div onClick={() => clickYesNo("1", props.field)}>
              <CheckYes fontSize={40} margin={20} yn={"Yes"} />{" "}
            </div>

            <div onClick={() => clickYesNo("0", props.field)}>
              <CheckNo fontSize={40} margin={20} yn={"No"} />{" "}
            </div>
          </>
        )}
      </div>
    );
  };
  const handleBlur = (e, field) => {
    console.log(e.target.value);
    console.log(field);
    setCurrentField(field);
    setCurrentValue(e.target.value);
    setFarmDefaults({ ...farmDefaults, [field]: e.target.value });
    setFinishedEdit(true);
  };
  const handleChangeInput = (e, field) => {
    console.log(e);
    console.log(field);
    console.log(e.target.value);
    // cv = e.target.value;
    // cf = field;
    // setFarmDefaults({ ...farmDefaults, [field]: e.target.value });

    // setCurrentField(field);
    // setCurrentValue(e.target.value);
  };

  const description = {
    clients_can_book_lessons:
      "Clients can add themselves to existing lessons. They will only be able to add themselves to lessons that have a section with a maximum enrollment filled in, and where there is an availability based on that number",
    restrict_client_sections:
      "When clients can book lessons, restricting client sections will restrict them to seeing only those events of the sections that they have been assigned to. When this is 'Yes', go to client's page under 'Student Details' and assign sections for client. If no sections are assigned, they will see all sections. **Only valid when 'Clients can book lessons' is checked.",
    clients_can_book_makeups:
      "Clients can book their own makeups when they have been granted.  To book they will click on the orange 'Book Makeup Lesson' button on their page. They do not need the 'clients can book lessons' privilege to do this ",
    clients_can_cancel:
      "Clients can cancel their own lessons from their Schedule page. Your setup will determine if they have given enough notice and if so, will assign them the appropriate makeup action chosen in your Equine Office setup meeting. ",
    clients_can_view_horses:
      "If clients have horses assigned to their profile, they will be able to see the pages of those horses.",
    clients_can_view_calendar:
      "Clients will be able to view your calendar without restrictions. They will not be able to see any client or horse names unless specified by the settings below.",
    clients_can_view_other_students_on_calendar:
      "Clients  can see the names of other students enrolled when booking a lesson.",
    clients_can_view_other_horses_on_calendar:
      "Clients with the view calendar permission can see the names of  horses on the calendar.",
    clients_can_add_lesson:
      "Clients will be able to create a lesson for themselves. They will not be able to assign any other clients to it. They need to have the 'Clients can view calendar' privilege as they will create the lesson by clicking on the calendar.",
    restrict_client_add_sections:
      "When clients can create their own lessons, you can restrict them to the lesson sections you choose. When this is 'Yes' you can assign the sections on the client's page under Student Details.",
    farm_name: "The name that appears as 'from' on your emails",
    email:
      "The from and reply-to address of your emails.  Also the email address that recieves copies of emails sent to clients.",
    show_general_lesson_notes_on_daysheet:
      "The area where you add the general lesson notes when adding or editing a lesson on the calendar can be shown on the day sheet as well.",
    large_font_on_daysheet: "Need your glasses to read the Daysheet?  This is for you.",
    booking_hours_buffer:
      "When clients book lesson, they cannot book within this number of hours before the start of the lesson.",
    cancel_notice_hours:
      "The number of hours notice that the client needs to give in order not to have to pay for that lesson.",
    makeup_expiry_days: "The number of days after the cancelled lesson that the makeup lesson expires.",
    makeup_start_before_days: "The number of days before the cancelled lesson that the student can schedule a makeup.",
    send_reminders:
      "Reminders will be sent to client by email one day before the scheduled event. Note that they will be sent for all events, and to all clients that are included in the event (if they have an email address).",
    send_notifications:
      "These are the propmts asking you if you would like to notify clients and staff when a change is made to an event.  You would normally want this on as you will still have the option not to check Yes to the prompts. It is useful to have off when you are starting with the system and aren't ready to go live with clients yet.",
    send_cancellation_notifications:
      "Apart from the general notifications, you can prevent the system from sending out notifiations when a student is cancelled",
    show_general_lesson_notes_on_client_page:
      "The general lesson notes (for the lesson as a whole - not the student lesson notes) will be shown on the client's schedule and history on their page.",
    show_paid_not_paid:
      "In the calendar you can see paid or not paid status beside the student name. You can turn this off if you are not recording payment in the system. Without it, you can tell if a lesson has been paid if there link to the package.",
    package_start_before_days:
      "When a package is purchased, the package start date defaults to the purchase date.  Use this setting if you would like the package start date to default to a specific number of days before the purchase date.",
    min_time: "The starting time for the day and week calendars",
    max_time: "The starting time for the day and week calendars",
    monday_start: "Have the calender week start on the Monday instead of Sunday",
    default_calendar_view: "The view when you first go to the calendar page",
    default_day_view: "When clicking on the Day view, choose which type of calendar you wish to see",
    show_lesson_by_client_name:
      "When chosen, this will default to showing the event by the client name instead of the title.  You can change it back on the calendar.",
    timepicker_interval: "When entering a time for your event, this is the increment in minutes for the dropdown.",
    calendar_interval:
      "This will be the selectable time increments in minutes on the Day and Week calenday views.  The default is 15 minutes.",
    calendar_timeslot:
      "This is how the time on the first column of the calendar increments on the Day and Week view of the calendar. It is determined in conjuction with the calendar interval. For example if the calendar interval is 15 and the timeslot is 2, the times on the left will be in 15 mulitplied by 2 increments thus 7:00, 7:30, 8:00 etc.  The default is 2.",
    address: "The steet address as you would like it to appear on invoices",
    address2: "The city, state/province, zip/postal code as you would like it to appear on invoices",
    invoice_notes1: "Custom field for notes you would like to appear on invoice pdf",
    invoice_notes2: "Second custom field for notes you would like to appear on invoice pdf",
    invoice_email_notes1: "Custom notes you would like to appear in the email body when sending an invoice",
    invoice_email_notes2: "Second custom notes you would like to appear in the email body when sending an invoice",
    receipt_email_notes1: "Custom notes you would like to appear in the email body when sending a receipt",
    receipt_email_notes2: "Second custom notes you would like to appear in the email body when sending a receipt",
  };

  const descriptionCalendarPrefs = {
    min_time: "The starting time for the day and week calendars",
    max_time: "The end time for the day and week calendars",
    default_calendar_view: "The view when you first go to the calendar",
    default_day_view: "When clicking on the Day view, choose which type of calendar you wish to see",
  };

  const viewOptions = ["day", "week", "month", "agenda"];
  const viewOptions2 = [
    { label: "day", value: "day" },
    { label: "week", value: "week" },
    { label: "month", value: "month" },
    { label: "agenda", value: "agenda" },
  ];

  const dayOptions = [
    { label: "staff", value: "instructor" },
    { label: "location", value: "location" },
    { label: "section", value: "section" },
    { label: "none", value: "default" },
  ];

  /*if (!prefs) return null;
  if (!boolprefs) return null;
  if (!boolprivs) return null;
  if (!calendarPrefs) return null;*/
  if (!farmDefaults) return null;

  const Status = () => {
    return (
      <div style={{ marginTop: 5 }}>
        {"Status: "} {mystate.farmDefaults.eqo_subscription_id ? "Live" : "Free Trial"}
      </div>
    );
  };

  const SubscriptionLink = () => {
    if (!mystate.farmDefaults.eqo_subscription_id && mystate.farmDefaults.eqo_stripe_customer_id) {
      return <Link to={"/admin/setup/preferences/paymentSubscriptions"}> Click here to Subscribe</Link>;
    }
    return null;
  };

  const Success = () => {
    return <div>success!</div>;
  };

  const BoolObject = ({ keyarray }) => {
    const filtered = Object.keys(farmDefaults)
      .filter((key) => keyarray.includes(key))
      .reduce((obj, key) => {
        obj[key] = farmDefaults[key];
        return obj;
      }, {});
    return Object.keys(filtered).map((key, i) => (
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: "1 1 100%" }}>
        <div style={{ display: "flex", flexDirection: "column", marginBottom: 20 }}>
          <div style={{ fontSize: 16, width: 300, background: COLORS.blue, color: "white", padding: 5 }}>
            {key.replaceAll("_", " ")}
          </div>

          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: "1 1 100%" }}>
            <div style={{ width: 300 }}>
              <YesNo value={farmDefaults[key]} field={key} />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", padding: 5, marginLeft: 20 }}>{description[key]}</div>
      </div>
    ));
  };

  const InputObject = ({ keyarray, header }) => {
    const filtered = Object.keys(farmDefaults)
      .filter((key) => keyarray.includes(key))
      .reduce((obj, key) => {
        obj[key] = farmDefaults[key];
        return obj;
      }, {});

    return (
      <>
        {Object.keys(filtered).map((key, i) => (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: "1 1 100%" }}>
            <div style={{ display: "flex", flexDirection: "column", marginBottom: 20 }}>
              <div style={{ fontSize: 16, width: 300, background: COLORS.blue, color: "white", padding: 5 }}>
                {key.replaceAll("_", " ")}
              </div>
              <div style={{ width: 300, display: "flex", flex: "0 0 auto" }}>
                <input
                  key={key}
                  style={{ width: 300, padding: 10, fontSize: 14, borderTop: "none" }}
                  onChangex={(e) => handleChangeInput(e, key)}
                  onFocuss={(e) => (e.target.value = "")}
                  onBlur={(e) => handleBlur(e, key)}
                  defaultValue={farmDefaults[key]}
                />
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", padding: 5, marginLeft: 20 }}>{description[key]}</div>
          </div>
        ))}
      </>
    );
  };

  const TextboxObject = ({ keyarray, header }) => {
    const filtered = Object.keys(farmDefaults)
      .filter((key) => keyarray.includes(key))
      .reduce((obj, key) => {
        obj[key] = farmDefaults[key];
        return obj;
      }, {});

    return (
      <>
        {Object.keys(filtered).map((key, i) => (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: "1 1 100%" }}>
            <div style={{ display: "flex", flexDirection: "column", marginBottom: 20 }}>
              <div style={{ fontSize: 16, width: 600, background: COLORS.blue, color: "white", padding: 5 }}>
                {key.replaceAll("_", " ")}
              </div>
              <div style={{ width: 600, display: "flex", flex: "0 0 auto" }}>
                <textarea
                  key={key}
                  style={{ width: 600, padding: 10, fontSize: 14, borderTop: "none" }}
                  onChangex={(e) => handleChangeInput(e, key)}
                  onFocuss={(e) => (e.target.value = "")}
                  onBlur={(e) => handleBlur(e, key)}
                  defaultValue={farmDefaults[key]}
                />
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", padding: 5, marginLeft: 20 }}>{description[key]}</div>
          </div>
        ))}
      </>
    );
  };
  const sectionStyle = { border: "solid 2pt darkgrey", padding: 10, marginBottom: 10 };
  const sectionTitleStyle = { color: "darkgrey" };
  return (
    <div style={{ display: "flex", flexDirection: "column", flex: "1 1 100%", flexWrap: "wrap" }}>
      <PrimaryText></PrimaryText>
      <div style={{ marginBottom: 10 }}>
        <div style={{ fontSize: 16, width: 300, background: COLORS.blue, color: "white", padding: 5 }}>
          {"Equine Office Subscription"}
        </div>

        <Status />
        <SubscriptionLink />
      </div>
      <div style={{ marginBottom: 5 }}>
        <ButtonOrange onClick={() => clickPortal()} label={"Manage Payment Method"} />
      </div>
      <div style={sectionStyle}>
        <h3 style={sectionTitleStyle}>{"Business Information"}</h3>
        <InputObject keyarray={["farm_name", "email", "address", "address2"]} />
      </div>

      <div style={sectionStyle}>
        <h3 style={sectionTitleStyle}>{"Daysheet Preferences"}</h3>
        <BoolObject keyarray={["show_general_lesson_notes_on_daysheet", "large_font_on_daysheet"]} />
      </div>

      <div style={sectionStyle}>
        <h3 style={sectionTitleStyle}>{"Booking Preferences"}</h3>
        <InputObject
          keyarray={["booking_hours_buffer", "makeup_expiry_days", "makeup_start_before_days", "cancel_notice_hours"]}
        />
        <BoolObject keyarray={[""]} />
      </div>
      <div style={sectionStyle}>
        <h3 style={sectionTitleStyle}>{"Package Preferences"}</h3>
        <InputObject keyarray={["package_start_before_days"]} />
        <BoolObject keyarray={[""]} />
      </div>
      <div style={sectionStyle}>
        <h3 style={sectionTitleStyle}>{"Clients"}</h3>
        <BoolObject
          keyarray={[
            "restrict_client_sections",
            "clients_can_book_lessons",
            "clients_can_book_makeups",
            "clients_can_cancel",
            "clients_can_view_horses",
            "clients_can_view_calendar",
            "clients_can_view_other_students_on_calendar",
            "clients_can_view_other_horses_on_calendar",
            "clients_can_add_lesson",
          ]}
        />
      </div>
      <div style={sectionStyle}>
        <h3 style={sectionTitleStyle}>{"Misc"}</h3>
        <BoolObject
          keyarray={[
            "send_reminders",
            "send_notifications",
            "send_cancellation_notifications",
            "show_general_lesson_notes_on_client_page",
            "show_paid_not_paid",
          ]}
        />
      </div>

      <div style={sectionStyle}>
        <h3 style={sectionTitleStyle}>{"Calendar Preferences"}</h3>

        {Object.keys(descriptionCalendarPrefs).map((key, i) => (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: "1 1 100%" }}>
            <div style={{ display: "flex", flexDirection: "column", marginBottom: 20 }}>
              <div style={{ fontSize: 16, width: 300, background: COLORS.blue, color: "white", padding: 5 }}>
                {key.replaceAll("_", " ")}
              </div>

              <div style={{ width: 300, display: "flex", flex: "0 0 auto" }}>
                {key == "min_time" && (
                  <DatePicker
                    showTimeSelect
                    dateFormat={DISPLAY_TIME}
                    onChange={(date) => clickYesNo(dateObjToDBTime(date), "min_time")}
                    showTimeSelectOnly
                    name="default_start_time"
                    selected={mystate.farmDefaults.min_time}
                  />
                )}
                {key == "max_time" && (
                  <DatePicker
                    showTimeSelect
                    dateFormat={DISPLAY_TIME}
                    onChange={(date) => clickYesNo(dateObjToDBTime(date), "max_time")}
                    showTimeSelectOnly
                    name="default_end_time"
                    selected={mystate.farmDefaults.max_time}
                  />
                )}

                {key == "default_calendar_view" && (
                  <div style={{ width: 300 }}>
                    <Select
                      onChange={(selected) => clickYesNo(selected.value, "default_calendar_view")}
                      options={viewOptions2}
                      defaultValue={viewOptions2.filter(
                        (option) => option.value == mystate.farmDefaults.default_calendar_view || null
                      )}
                    />
                  </div>
                )}
                {key == "default_day_view" && (
                  <div style={{ width: 300 }}>
                    <Select
                      onChange={(selected) => clickYesNo(selected.value, "default_day_view")}
                      options={dayOptions}
                      defaultValue={dayOptions.filter(
                        (option) => option.value == mystate.farmDefaults.default_day_view || null
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
        <InputObject keyarray={["timepicker_interval", "calendar_interval", "calendar_timeslot"]} />
        <BoolObject keyarray={["monday_start", "show_lesson_by_client_name"]} />
      </div>
      <div style={sectionStyle}>
        <h3 style={sectionTitleStyle}>{"Receipt and Email Preferences"}</h3>
        <TextboxObject
          keyarray={[
            "invoice_notes1",
            "invoice_notes2",
            "invoice_email_notes1",
            "invoice_email_notes2",
            "receipt_email_notes1",
            "receipt_email_notes2",
          ]}
        />
      </div>

      <div style={sectionStyle}>
        <h3 style={sectionTitleStyle}>{"Stripe Account"}</h3>
        <div style={{ fontSize: 16, width: 300, background: COLORS.blue, color: "white", padding: 5 }}>
          {"Stripe Account Connection"}
        </div>
        <div style={{ fontSize: 12 }}>
          {
            "Create or Connect your own Stripe account to be able to accept online credit and debit card payments in Equine Office "
          }
        </div>
        <div>
          {"Status: "}
          {mystate.farmDefaults.stripe_account_id ? "Connected" : "Not Connected"}
        </div>
        {!mystate.farmDefaults.stripe_account_id && (
          <a href="https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_GRa8ZKtBAxKvB2br6SSAZXr9JD7uqVyD&scope=read_write">
            Click to connect or create Stripe Account
          </a>
        )}
      </div>
    </div>
  );
};
