import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Route, Link, useRouteMatch, useParams, useHistory, useLocation } from "react-router";
import AuthHelperMethods from "./AuthHelperMethods";
import ReactTooltip from "react-tooltip";
import BlueCheckFuture, { BlueCheckBoxFutureOne } from "./blueCheckFuture";
import { useAlert } from "react-alert";
import COLORS from "./styleConstants";
import { Redirect } from "react-router-dom";
import { Row, Col, Container, Modal, ListGroup, Button } from "react-bootstrap";

import "./styleEdit.css";
import { lessonStatusMap, DISPLAY_DATE_MED } from "./UserSettings";
import SelectHorse from "./selectHorse";
import SelectStudent from "./selectStudent";
import getData from "./getData";
import { MyContext } from "./App";
import ModalDateTime from "./modalDateTime";
import ModalGeneral from "./modalGeneral";
import MsgAlert from "./msgAlert";
import {
  EditIcon,
  AddIcon,
  InfoIcon,
  QuestionIcon,
  DropdownArrow,
  TimesIcon,
  EditIconForEditEvent,
  ArrowDown,
} from "./icons";
import { dateObjToDisplayDate, dateObjToDisplayTime, stringToDateObj } from "./momentUtils";
import moment from "moment";
import InfoModal2 from "./infoModal2.js";
import {
  horseAvatar,
  clientAvatar,
  staffAvatar,
  locationAvatar,
  deleteImg,
  privateNoteImg,
  publicNoteImg,
} from "./images";
import StudentHorseDetail from "./studentHorseDetail";
import { TitleRow } from "./blueCheckFuture";
import HorseHomePage from "./horseHomePage";
import ClientHomePage from "./clientHomePage";
import { TableHeader2, UserCan, ButtonOrange, ButtonBlue, TextOrange } from "./formComponents";
import ToggleSwitch from "./ToggleSwitch";
import { BoxWithLabelW, BoxWithLabelNoM, BoxWithLabel100, HelpText } from "./styleCustom";
import PrivateRoute from "./privateRoute";
import Select from "react-select";
import useSWR from "swr";

const Auth = new AuthHelperMethods();

const EditEvent = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const thisEdit = useParams();
  const thisEditId = thisEdit.hid;
  console.log(thisEditId);
  const showalert = useAlert();

  //const [editparams,  setEditparams] = useState({});
  //  const [commonParams,  setCommonParams = useState({});

  //  const id = parseInt(props.match.params.id);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);
  const [repeat, setRepeat] = useState(false);

  const [toHome, setToHome] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showButtonDropdown, setShowButtonDropdown] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [actions, setActions] = useState(true);
  const [taskStaff, setTaskStaff] = useState([]);
  const [showTask, setShowTask] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [currentStaff, setCurrentStaff] = useState(null);
  const editMe = {
    color: "#818182",
    backgroundColor: "#fdfdfe",
    padding: 0,
    paddingTop: 10,
    border: " 1px solid rgba(0,0,0,.125)",
    textAlign: "center",
    minHeight: 100,
    flex: "1 1 100%",
    position: "relative",
    whiteSpace: "pre-line",
  };

  useEffect(
    () => {
      const fetchData = async () => {
        let params = { id: thisEditId };
        const result = await Auth.api("lessons/index", "get", params);
        console.log("result");
        console.log(result);

        dispatch({
          type: "SET_LESSON",
          data: result.data,
        });

        dispatch({
          type: "GET_STUDENT_LESSON_NOTES_FOR_CURRENT_LESSON",
          studentNotes: result.data.studentNotes,
        });

        dispatch({
          type: "SET_APPLY_TO_FUTURE",
          applyToFuture: false,
        });
      };

      if (thisEditId) fetchData();
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("notes_clients/index", "get", editparams);
        console.log("result");
        console.log(result);

        dispatch({
          type: "GET_STUDENT_LESSON_NOTES_FOR_CURRENT_LESSON",
          studentNotes: result.data.studentNotes,
        });
      };

      if (editparams.lesson_date_time_id) fetchData();
    },
    //eslint-disable-next-line
    [mystate.currentLesson.id]
  );

  useEffect(
    () => {
      console.log("CLEAR_CURRENT_LESSON");

      return async () => {
        dispatch({ type: "CLEAR_CURRENT_LESSON" }); //?? can but this after finish edit but needs to wait until finish edit is done
        dispatch({
          type: "SET_APPLY_TO_FUTURE",
          applyToFuture: false,
        });
      };
    },
    //eslint-disable-next-line
    []
  );

  let editparams = {};
  let commonParams = {};

  if (mystate.currentLesson) {
    editparams.lesson_date_time_id = mystate.currentLesson.id;

    commonParams = {
      lesson_date_time_id: mystate.currentLesson.id,
      lesson_date_time_series_id: mystate.currentLesson.lesson_date_time_series_id,
      applyToFuture: mystate.applyToFuture ? 1 : 0,
      scheduled_date: mystate.currentLesson.scheduled_date,
    };
  }

  /*useEffect(
    () => {
      if (mystate.currentLesson.is_recuring) {
        alert.show(
          `All changes are to this event only. To apply to all future events, use the Yes/No buttons below. `,
          {
            timeout: 5000,
            type: "info",
          }
        );
      }

      window.scrollTo(0, 0);
    },
    //eslint-disable-next-line
    [mystate.currentLesson.id]
  );*/
  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("notes_clients/index", "get", editparams);
        console.log("result");
        console.log(result);

        dispatch({
          type: "GET_STUDENT_LESSON_NOTES_FOR_CURRENT_LESSON",
          studentNotes: result.data.studentNotes,
        });
      };

      if (editparams.lesson_date_time_id) fetchData();
    },
    //eslint-disable-next-line
    [mystate.currentLesson.id]
  );

  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("notes_horses/index", "get", editparams);
        console.log("result");
        console.log(result);

        dispatch({
          type: "GET_HORSE_LESSON_NOTES_FOR_CURRENT_LESSON",
          horseNotes: result.data.horseNotes,
        });
      };
      if (editparams.lesson_date_time_id) fetchData();
    },
    //eslint-disable-next-line
    [mystate.currentLesson.id]
  );

  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("lesson_staffs/getLessonStaff", "get", editparams);
        console.log("result");
        console.log(result);
        /* let x = result.data.lessonStaff.map((el) => ({
          task: { value: el.task_id, label: el.task_name },
          staff: { value: el.instructor_id, label: el.instructor_name },
        }));*/
        // console.log(x);
        setTaskStaff(result.data.lessonStaff);

        /*  dispatch({
          type: "GET_HORSE_LESSON_NOTES_FOR_CURRENT_LESSON",
          horseNotes: result.data.horseNotes,
        });*/
      };

      if (editparams.lesson_date_time_id) fetchData();
    },
    //eslint-disable-next-line
    [mystate.currentLesson.id]
  );

  const rescheduleEvent = (e) => {
    if (mystate.currentLesson.lesson_is_cancelled) {
      showalert.show(`Lesson has already been cancelled `, {
        timeout: 5000,
        type: "success",
      });
    } else {
      dispatch({
        type: "SHOW_DEFAULT_MODAL",
        title: "Reschedule Lesson",
        editAction: "reschedule",
        editMessage: `Choose a new date and/or time.`,
        showNotify: true,
        showRecurringChoice: false,
      });
    }
  };

  const duplicateEvent = (e) => {
    dispatch({
      type: "SHOW_DEFAULT_MODAL",
      title: "Duplicate Lesson",
      editAction: "duplicate",
      showNotify: false,
      editMessage: `Choose a new date and/or time for the event you wish to duplicate.`,
      showRecurringChoice: false,
    });
  };

  const rebookEvent = (e) => {
    dispatch({
      type: "SHOW_DEFAULT_MODAL",
      title: "Rebook Cancelled Lesson",
      editAction: "duplicate",
      showNotify: false,
      editMessage: `Choose a new date and/or time for the event you wish to duplicate.`,
      showRecurringChoice: false,
    });
  };

  const editDateandTime = (e) => {
    dispatch({
      type: "SHOW_DEFAULT_MODAL",
      title: "Edit Date and Time",
      editAction: "editDateandtime",
      showNotify: true,
    });
  };

  //event details
  const editTitle = (e) => {
    dispatch({
      type: "SHOW_DEFAULT_MODAL",
      title: "Change Title",
      editAction: "editTitle",
      showRecurringChoice: true,
    });
  };

  const editInstructor = () => {
    dispatch({
      type: "SHOW_DEFAULT_MODAL",
      title: "Change Staff",
      editAction: "editInstructor",
      showNotify: true,
      showRecurringChoice: true,
    });
  };

  const editLocation = () => {
    dispatch({
      type: "SHOW_DEFAULT_MODAL",
      title: "Change Location",
      editAction: "editLocation",
      showNotify: true,
      showRecurringChoice: true,
    });
  };

  const editNotes = () => {
    dispatch({
      type: "SHOW_DEFAULT_MODAL",
      title: "Lesson Notes",
      editAction: "editNotes",
      showNotify: true,
      showRecurringChoice: true,
    });
  };

  const editSection = () => {
    dispatch({
      type: "SHOW_DEFAULT_MODAL",
      title: "Change Section",
      editAction: "editSection",
      showNotify: true,
    });
  };

  const deleteEvent = (e) => {
    dispatch({
      type: "SHOW_DEFAULT_MODAL",
      title: "Delete",
      editAction: "delete",
      showRecurringChoice: true,
    });
  };

  const cancelEvent = (e) => {
    if (mystate.currentLesson.lesson_is_cancelled) {
      showalert.show(`Lesson has already been cancelled `, {
        timeout: 5000,
        type: "success",
      });
    } else {
      dispatch({
        type: "SHOW_DEFAULT_MODAL",
        title: "Cancel Lesson",
        editAction: "cancel",
        showNotify: true,
      });
    }
  };

  const changeExistingToRecurring = (e) => {
    dispatch({
      type: "SHOW_DEFAULT_MODAL",
      title: "Change this to a recurring event",
      editAction: "changeExistingToRecurring",
      showRecurringChoice: false,
      showNotify: true,
    });
  };

  const extendRecurring = (e) => {
    setShowButtonDropdown(false);
    dispatch({
      type: "SHOW_DEFAULT_MODAL",
      title: "Extend Recurring Event",
      editAction: "extendRecurring",
      showRecurringChoice: false,
      showNotify: true,
      editMessage: `${mystate.currentLesson.repeatDesc} Enter the new finish date for the recurring event.`,
      //tbd add validation so date is later than current date
    });
    // console.log(stringToDateObj(mystate.currentLesson.repeat_end, DISPLAY_DATE_MED));
    console.log(mystate.currentLesson.repeat_end);

    //console.log(mystate.currentLesson);
    dispatch({
      type: "SET_DATE_COMPONENT",
      dateOnly: stringToDateObj(mystate.currentLesson.repeat_end, DISPLAY_DATE_MED),
    });
  };

  //edit horse and student

  const markLessonCompleted = async () => {
    setShowButtonDropdown(false);
    editparams = {
      ...commonParams,
      lesson_date_time_id: mystate.currentLesson.id,
    };
    const result = await getData("lesson_people/markLessonCompleted", "post", editparams);
    dispatch({
      type: "MARK_LESSON_COMPLETED",
      data: result,
      code: "COM",
    });
  };
  const markAllPaid = (truefalse) => {
    setShowButtonDropdown(false);
    editparams = {
      ...commonParams,
      paid: 1,
      lesson_date_time_id: mystate.currentLesson.id,
    };
    const studentresult = Auth.api("lesson_people/markAllPaid", "post", editparams);
    dispatch({ type: "MARK_ALL_STUDENTS_PAID", paid: true });
  };

  if (toHome) {
    return <Redirect to="/" />;
  }

  const finishEdit = async () => {
    /* let params = {
      lesson_date_time_id: mystate.currentLesson.id,
      //applyToFuture: mystate.applyToFuture ? 1 : 0,
      lesson_date_time_series_id: mystate.currentLesson.lesson_date_time_series_id,
      scheduled_date: mystate.currentLesson.scheduled_date,
      calendarDateRangeStart: mystate.calendarDateRangeStart,
      calendarDateRangeEnd: mystate.calendarDateRangeEnd,
    };
    const result = await Auth.api("lessons/getEditedLessons", "get", params);
    console.log("FINISH EDIT");
    dispatch({
      type: "DELETE_AND_REFRESH",
      payload: result.data,
    });
*/
    // does this need to go after result instead of before since it sets currentLesson={}
    history.push("/admin/calendarGroup/calendar");
  };

  //const [lessonmsg, setLessonmsg] = useState("");

  const showStudentList = () => {
    console.log("this showStudentList EditEvent");
    dispatch({ type: "SHOW_STUDENT_LIST", showStudentList: true });
    //  dispatch({ type: "FILTER_STUDENT_LIST_BY_SEARCHTERM" });
  };

  const showHorseList = () => {
    console.log("ssl");
    dispatch({ type: "SHOW_HORSE_LIST", showHorseList: true });
  };

  const handleCloseStudentList = () => {
    console.log("handleCloseStudentList");
    if (mystate.currentMessage && mystate.currentMessage.length > 0) {
      showalert.show(`Successfully added to event: ${mystate.currentMessage}  `, {
        timeout: 5000,
        type: "success",
      });
    }

    dispatch({ type: "SET_ALERT_MESSAGE", name: "" });
    dispatch({ type: "SHOW_STUDENT_LIST", showStudentList: false });
    dispatch({ type: "SHOW_HORSE_LIST", showHorseList: false });
  };

  function onToggleChange(event) {
    dispatch({
      type: "SET_APPLY_TO_FUTURE",
      applyToFuture: event.target.checked,
    });
  }

  const modalLong = { minHeight: "80vh" };

  const DateText = () => {
    return (
      <div style={{ color: COLORS.orange, fontSize: 16, marginRight: 10 }}>
        {dateObjToDisplayDate(mystate.currentLesson.start)}
      </div>
    );
  };
  const TimeText = () => {
    return (
      <div style={{ color: COLORS.orange, fontSize: 16, marginRight: 2 }}>
        {mystate.currentLesson.is_allday && (
          <div style={{ color: COLORS.orange, fontSize: 16, marginRight: 2 }}>{"all day"}</div>
        )}
        {mystate.currentLesson.is_allday === false && (
          <div style={{ color: COLORS.orange, fontSize: 16, marginRight: 2 }}>
            {`  ${dateObjToDisplayTime(mystate.currentLesson.start)} to  ${dateObjToDisplayTime(
              mystate.currentLesson.end
            )}`}
          </div>
        )}
      </div>
    );
  };

  const DateTimeText = () => {
    return (
      <div>
        <div>{dateObjToDisplayDate(mystate.currentLesson.start)}</div>

        {mystate.currentLesson.is_allday && <div>{"all day"}</div>}
        {mystate.currentLesson.is_allday === false && (
          <div>
            {`${dateObjToDisplayTime(mystate.currentLesson.start)}
   to
      ${dateObjToDisplayTime(mystate.currentLesson.end)}`}
          </div>
        )}
      </div>
    );
  };

  const handleSelectTask = (selected) => {
    console.log(selected);
    setCurrentTask(selected);
  };

  const handleSelectStaff = (selected) => {
    if (selected.value == 0) selected.value = null;
    setCurrentStaff(selected);
  };
  //if (!mystate.CurrentLesson) history.goBack();
  const handleClickDone = async () => {
    if (!currentTask) alert("Task can not be empty");
    else if (!currentStaff) alert("Staff can not be empty");
    else {
      let params = {
        task_id: currentTask.value,
        instructor_id: currentStaff.value,
        lesson_date_time_id: mystate.currentLesson.id,
        lesson_date_time_series_id: mystate.currentLesson.lesson_date_time_series_id,
        applyToFuture: mystate.applyToFuture,
        scheduled_date: mystate.currentLesson.scheduled_date,
      };

      const result = await Auth.api("lesson_staffs/create", "post", params);
      setTaskStaff(result.data.lessonStaff);
      setCurrentTask();
      setCurrentStaff();
    }
  };
  const deleteTask = async (id) => {
    console.log(id);
    let params = { id: id, lesson_date_time_id: mystate.currentLesson.id };
    const result = await Auth.api("lesson_staffs/delete", "post", params);
    setTaskStaff(result.data.lessonStaff);
  };

  const reportStyles = {
    container: (styles) => ({
      ...styles,
      flex: 1,
      flex: 1,
      minWidth: 150,
      margin: 5,
    }),
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flex: "1 1 100%",
          flexWrap: "wrap",
          marginBottom: 100,
        }}
      ></div>

      {mystate.currentLesson && (
        <>
          <div
            style={{
              display: "flex",
              flex: "1 1 100%",
              flexWrap: "wrap",
              border: isMobile ? null : "solid #e6e6e6 15px",
              background: mystate.currentLesson.lesson_is_cancelled && "lightgrey",
            }}
          >
            <ReactTooltip
              effect="solid"
              delayShow={20}
              delayHide={200}
              place={"top"}
              className={"customTip"}
              type="dark"
            />
            <Modal
              show={mystate.showStudentList || mystate.showHorseList}
              onHide={handleCloseStudentList}
              autoFocus={false}
              enforceFocus={false}
              size={"md"}
              dialogClassName="modalLong"
            >
              <Modal.Header
                closeButton
                style={{
                  backgroundColor: `${mystate.applyToFuture ? COLORS.orange : "#66c2d7"}`,
                  color: "#FFFFFF",
                  borderRadius: "2px",
                }}
              >
                <Modal.Title>
                  {mystate.showStudentList
                    ? `${
                        mystate.applyToFuture
                          ? "Add Clients to this and Future Events"
                          : "Add Clients to This Event Only"
                      }`
                    : `${
                        mystate.applyToFuture ? "Add Horses to this and Future Events" : "Add Horses to This Event Only"
                      }`}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ minHeight: "80vh" }}>
                {mystate.showStudentList && mystate.currentLesson.lessonStudents && (
                  <SelectStudent menuIsOpen addOrEdit={"edit"} />
                )}
                {!mystate.showStudentList && mystate.currentLesson.lessonHorses && <SelectHorse menuIsOpen edit />}
              </Modal.Body>
            </Modal>
            <div
              style={{
                display: "flex",
                flex: "1 1 100%",
                flexWrap: "noWrap",
                justifyContent: "flex-start",
                alignItems: "stretch",
              }}
            >
              <TableHeader2 title={"Edit Event"} />
              <div style={{ background: COLORS.blue, padding: 5 }} onClick={() => finishEdit()}>
                {" "}
                <TimesIcon color={"white"} />
              </div>
            </div>
            {actions && (
              <div style={{ display: "flex", flex: "1 1 100%", flexWrap: "wrap" }}>
                {!mystate.currentLesson.lesson_is_cancelled && (
                  <>
                    <ButtonOrange onClick={cancelEvent} label={"Cancel Event"} />

                    <ButtonOrange onClick={rescheduleEvent} label={"Reschedule"} />
                  </>
                )}
                <ButtonOrange onClick={duplicateEvent} label={"Duplicate"} />

                {!mystate.currentLesson.is_recuring && (
                  <ButtonOrange onClick={changeExistingToRecurring} label={"Make Recurring"} />
                )}
                {mystate.currentLesson.is_recuring && (
                  <ButtonOrange onClick={extendRecurring} label={"Extend Recurring "} />
                )}
                <ButtonOrange onClick={markLessonCompleted} label={"Mark Completed"} />
                <ButtonOrange onClick={deleteEvent} label={"Delete Event"} />
                {mystate.currentLesson.lesson_is_cancelled && 1 == 2 && (
                  <ButtonOrange onClick={duplicateEvent} label={"Rebook"} />
                )}
              </div>
            )}
            <div
              className="datetimeHeader"
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: 26,
                flex: "1 1 100%",
                flexWrap: "nowrap",
                marginTop: 5,
              }}
            >
              <DateText />
              <TimeText />
            </div>
            <div
              style={{
                display: "flex",
                flex: "1 1 100%",
                justifyContent: "center",
                paddingTop: "10px",
                fontStyle: "italic",
              }}
            >
              <span style={{ marginRight: 10 }}>{"Event Status: "}</span>
              <TextOrange text={`${lessonStatusMap[mystate.currentLesson.lesson_status_code] || "scheduled"}`} />
            </div>
            {mystate.currentLesson.repeatDesc && (
              <div
                style={{
                  display: "flex",
                  flex: "1 1 100%",
                  justifyContent: "center",
                  paddingTop: "2px",
                  fontStyle: "italic",
                }}
              >
                {mystate.currentLesson.repeatDesc}
              </div>
            )}{" "}
            {1 == 1 && mystate.currentLesson.repeatDesc && (
              <div
                className="future"
                style={{ display: "flex", flexDirection: "column", flex: "1 1 100%", marginTop: 10 }}
              >
                <div
                  style={{ display: "flex", padding: 6, flex: "1 1 100%", background: COLORS.orange, color: "white" }}
                >
                  {"Apply Changes To Future Events?"}
                </div>
                <div style={{ display: "flex", alignItems: "center", margin: 10 }}>
                  <ToggleSwitch
                    id="id"
                    defaultChecked={false}
                    disabled={false}
                    Text={["Yes", "No"]}
                    onToggleChange={onToggleChange}
                    name={"applyToFuture"}
                  />
                  {mystate.applyToFuture && <TextOrange text={"Changes will apply to future events"} />}
                </div>
              </div>
            )}
            <div className={"editLessonParts"} style={{ display: "flex", flex: "1 1 100%", flexWrap: "wrap" }}>
              <BoxWithLabel100 label={"DATE/TIME"}>
                <div className="container " style={editMe}>
                  <DateTimeText />

                  <div onClick={() => editDateandTime()}>
                    {" "}
                    <EditIcon />
                  </div>
                </div>
              </BoxWithLabel100>
              <BoxWithLabel100 label={"SECTION"}>
                <div className="container " style={editMe}>
                  {mystate.currentLesson.section_name}
                  <div onClick={() => editSection()}>
                    <EditIcon />
                  </div>
                </div>
              </BoxWithLabel100>

              <BoxWithLabel100 label={"TITLE"}>
                <div className="container " style={editMe}>
                  {mystate.currentLesson.title}
                  <div onClick={() => editTitle()}>
                    {" "}
                    <EditIcon />
                  </div>
                </div>
              </BoxWithLabel100>

              <BoxWithLabel100 label={"MAIN STAFF"}>
                <div className="container " style={editMe}>
                  <img src={staffAvatar} alt={"staff"} />
                  {mystate.currentLesson.instructor_name}
                  <div onClick={() => editInstructor()}>
                    {" "}
                    <EditIcon />
                  </div>
                </div>
              </BoxWithLabel100>
              <BoxWithLabel100 label={"EXTRA STAFF"}>
                <div className="container " style={editMe}>
                  {taskStaff &&
                    taskStaff.map((el, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          fontSize: "16px",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <div>{el.task_name} </div>
                          <div>{el.instructor_name}</div>
                        </div>
                      </div>
                    ))}
                  <div onClick={() => setShowTask(true)}>
                    {" "}
                    <EditIcon />
                  </div>
                </div>
              </BoxWithLabel100>

              {showTask && (
                <>
                  <Modal show={showTask} onHide={() => setShowTask(false)}>
                    <Modal.Header style={{ background: COLORS.blue, color: "white" }}>
                      <Modal.Title>{"Add Additional Staff Or Volunteers"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {taskStaff &&
                        taskStaff.map((el) => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              fontSize: "16px",
                              maxWidth: 350,
                            }}
                          >
                            <div
                              style={{ display: "flex", flexDirection: "row", fontSize: "16px", color: COLORS.blue }}
                              key={el.id || null}
                            >{`${el.task_name}: ${el.instructor_name}`}</div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                fontSize: 12,
                                color: COLORS.orange,
                                cursor: "pointer",
                                marginLeft: 30,
                                alignItems: "center",
                              }}
                              onClick={() => deleteTask(el.id)}
                            >
                              <TimesIcon fontsize={16} />
                            </div>
                          </div>
                        ))}
                      {mystate.options_task && mystate.options_task.length == 0 && (
                        <>
                          {" "}
                          <div>You do not have any tasks set up yet</div>
                          <div>To add tasks, go to Setup => Tasks => Add New Task </div>
                        </>
                      )}
                      {mystate.options_task && mystate.options_task.length > 0 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderx: `1px solid ${COLORS.blue}`,
                            padding: 10,
                            maxWidth: 600,
                            alignItems: "center",
                            justifyContent: "space-around",
                            marginTop: 20,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flex: "1 1 100%",
                              margin: 5,
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <HelpText>{"Select a task"}</HelpText>
                            <ArrowDown />
                            <Select
                              styles={reportStyles}
                              options={mystate.options_task}
                              onChange={(e) => handleSelectTask(e)}
                              clearable={true}
                              searchable={true}
                              placeholder={"Select Task..."}
                              value={currentTask}
                            />
                          </div>
                          <div
                            style={{ display: "flex", flex: "1 1 100%", flexDirection: "column", alignItems: "center" }}
                          >
                            <HelpText>{"Then select the person"}</HelpText>
                            <ArrowDown />
                            <Select
                              styles={reportStyles}
                              onChange={(selected) => handleSelectStaff(selected)}
                              options={mystate.options_instructor}
                              clearable={true}
                              searchable={true}
                              placeholder={"Select Staff..."}
                              value={currentStaff}
                            />
                          </div>
                          <div
                            style={{ display: "flex", flex: "1 1 100%", flexDirection: "column", alignItems: "center" }}
                          >
                            <HelpText>{"Then click Add to save"}</HelpText>
                            <ArrowDown />
                            <ButtonOrange label={"Add"} onClick={() => handleClickDone()} />
                          </div>{" "}
                        </div>
                      )}
                    </Modal.Body>

                    <Modal.Footer>
                      <div>
                        <ButtonBlue label={"Close"} onClick={() => setShowTask(false)} />
                      </div>
                    </Modal.Footer>
                  </Modal>
                </>
              )}

              <BoxWithLabel100 label={"LOCATION"}>
                <div className="container " style={editMe} o>
                  {mystate.currentLesson.location_name}
                  <div onClick={() => editLocation()}>
                    {" "}
                    <EditIconForEditEvent />
                  </div>
                </div>
              </BoxWithLabel100>

              <BoxWithLabel100 label={"CLIENTS"}>
                <div className="container " style={editMe} onClick={() => showStudentList()}>
                  {mystate.currentLesson.lessonStudents &&
                    mystate.currentLesson.lessonStudents.length > 0 &&
                    mystate.currentLesson.lessonStudents.map((student) => (
                      <p style={{ marginBottom: "0", fontStyle: "italic" }} key={student.student_id}>
                        {student.name}
                      </p>
                    ))}
                </div>
              </BoxWithLabel100>

              <BoxWithLabel100 label={"HORSES"}>
                <div style={editMe} className="container " onClick={() => showHorseList()}>
                  {mystate.currentLesson.lessonHorses &&
                    mystate.currentLesson.lessonHorses.map((horse) => (
                      <p key={horse.horse_id} style={{ marginBottom: "0", fontStyle: "italic" }}>
                        {" "}
                        {horse.name}{" "}
                      </p>
                    ))}
                </div>
              </BoxWithLabel100>
            </div>
            <div
              className="lessonParts2"
              style={{
                display: "flex",
                flex: " 1 1 100%",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  paddingBottom: "20px",
                  display: "flex",
                  justifyContent: "center",
                  maxWidth: "70%",
                  flex: "1 1 ",
                  flexWrap: "wrap",
                }}
              >
                <BoxWithLabel100 label={"LESSON NOTES"}>
                  <div style={{ ...editMe, textAlign: "left", paddingLeft: 10, paddingRight: 10 }}>
                    {mystate.currentLesson.lesson_notes}

                    <div onClick={() => editNotes()}>
                      {" "}
                      {mystate.currentLesson.lesson_notes ? <EditIcon /> : <AddIcon />}
                    </div>
                  </div>
                </BoxWithLabel100>
              </div>
            </div>
            <UserCan doThis={"STAFF_CAN_VIEW_CLIENT_HORSE_DETAIL"}>
              <div className="blocklabel" style={{ display: "flex", flex: "1 1 100%", marginTop: 0 }}>
                CLIENT AND HORSE DETAIL
              </div>
              <StudentHorseDetail {...props} />
            </UserCan>
            <div
              className="blocklabel"
              style={{
                display: "flex",
                flex: "1 1 100%",
                marginTop: 50,
                minHeight: 20,
              }}
            />
            <ModalDateTime />
            {mystate.showDefaultModal && <ModalGeneral currentId={thisEditId} />}
          </div>
        </>
      )}
    </>
  );
};

export default EditEvent;
