import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link, useParams, useHistory, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import AuthHelperMethods from "./AuthHelperMethods";
import { ListContext, MyContext } from "./App";
import COLORS from "./styleConstants";

export const Updates = () => {
  const isMobile = window.innerWidth < 500;
  console.log(isMobile);
  return (
    <div className="updateContainer" style={{ padding: isMobile ? 0 : 100 }}>
      <div>
        <h1 style={{ color: COLORS.orange }}>{"Updates"}</h1>
      </div>
      <div
        style={{
          display: "flex",
          flex: "1 1 100%",
          flexDirection: "column",
          marginTop: 10,
          fontSize: 14,
        }}
      >
        <div style={{ marginBottom: 20, fontSize: 20 }}>
          <b>{"June 13, 2022"}</b>
        </div>
        <div>
          <div>
            {" "}
            <h5 style={{ color: COLORS.blue }}>Reports</h5>
            <p>
              <b>Extra Staff Report </b>
              From the Reports page, there is now a detail report for the Extra Staff assigned to a lesson. This
              included the person's name and their assigned task. The report is downloadable to a CSV file for further
              manipulation.
            </p>
            <p>
              <b>Horse Detail Report Includes Client </b>
              From the Reports page, the horse detail report now shows the rider if one was assigned. If there was no
              rider assigned, it will still include the horse in the report.
            </p>
            <h5 style={{ color: COLORS.blue }}>Calendar</h5>
            <b>Student Cancellation Status </b>
            <p>
              On the Client Detail section of the lesson (when chosen from the calendar), if a student is cancelled, it
              will indicate whether it is a Student Cancel or a Barn Cancel.
            </p>
            <b>Duplicating a Cancelled Lesson</b>
            <p>
              When you duplicate a lesson that has been cancelled, the cancelled students from that lesson will now be
              added to the new lesson. This is also useful if you need to "uncancel" a lesson - just duplicate the
              lesson without changing the date or time, and all the students are copied to a new lesson. You can now
              delete the original lesson if cancelled in error. The duplication button is among the orange buttons at
              the top of the lesson when clicked from the calendar.
            </p>
            <h5 style={{ color: COLORS.blue }}>Miscellaneous Fixes and Improvments</h5>
            <b>Package Details Notes </b>
            <p>
              When assigning packages or editing details when purchasing packages, the package notes are now saved and
              displayed with the packages.
            </p>
            <b>Package Details Next/Current/Previous Month Dropdown </b>
            <p>
              When assigning/editing package details, you can use the dropdown to quickly enter dates for the
              next/current/or previous month.{" "}
            </p>
            <b>Mobile View of Calendar from Client Page </b>
            <p>
              You can now see the whole week of the calendar on mobile devices from the client page calendar. It was
              previously cutting off the first day of the calendar.
            </p>
            <b>Client Page Schedule/History on Wide Screens </b>
            <p>
              The reports will display on just one page at a time. Previously it was showing two pages of the reports on
              extra wide screens.
            </p>
            <b>Client Booking Confirmation Email When Clients Add Their Own Lessons </b>
            <p>
              The email received when a client adds a lessson when they change the time or all day event status will now
              refelct the updated time.
            </p>
          </div>
        </div>
        <div style={{ marginBottom: 20, fontSize: 20 }}>
          <b>{"April 17, 2022"}</b>
        </div>
        <div>
          <div>
            {" "}
            <h5 style={{ color: COLORS.blue }}>Settings </h5>
            <p>
              <b>Package Start Before Days </b>
              When a package is purchased, the package start date ordinarily defaults to the purchase date. Use this
              setting if you would like the package start date to default to a specific number of days before the
              purchase date.
            </p>
            <p>
              <b>Clients Can View Other Students </b>
              Clients can see the names of other students enrolled when booking a lesson.
            </p>
            <p>
              <b>Clients Can View Other Horses </b>
              Clients can see the names of other horses when booking a lesson.
            </p>
            <h5 style={{ color: COLORS.blue }}>New Staff Permissions for Roles</h5>
            <p>
              <ul>
                <li>Staff Can View Daily Horse and Client Notes</li>
                <li>Staff Can View Daily Staff Notes</li>
                <li>Staff Can View General Reports Page</li>
                <li>Staff Can Access Account Holders Page</li>
                <li>Staff Can Access Horses Page</li>
                <li>Staff Can Access Students Page</li>
              </ul>
            </p>
            <h5 style={{ color: COLORS.blue }}>Makeups</h5>
            <p>
              <b>Calendar Shows if Student is a makeup </b>
              If a student is having a makeup lesson, it will show in the student detail area of the lesson in the
              calendar.
            </p>
            <p>
              <b>Makeup List on Student Page </b>
              The Book Makeup button is now View Makeups. It will list outstanding makeup lessons including the date of
              the missed lesson.
            </p>
          </div>
        </div>
        <div style={{ marginBottom: 10, fontSize: 20 }}>
          <b>{"December 16, 2021"}</b>
        </div>
        <p>
          <b> File Uploads: </b>
          {"File uploads for horse documents now functional."}
        </p>
        <div style={{ marginBottom: 10, fontSize: 20 }}>
          <b>{"December 10, 2021"}</b>
        </div>
        <p>
          <b> Calendar Refresh: </b>
          {
            "The calendar will now automatically refresh on focus.  This means that if you switch windows or browser tabs, or you wakeup your computer from sleep, or grab your phone from your pocket, you will automatically see the latest calendar data.  No need to refresh your browser."
          }
        </p>
        <p>
          <b> Calendar Filters: </b>
          There are additional filters on the main calendar. You can now filter by student or horse, allowing you to see
          either's schedule without going to their page. You can also now see available lessons (as determined by the
          max enrollment field in the section setup) again without having to go to the student's page.
        </p>
        <p>
          <b>Settings Page:</b> The settings page on Admin => Settings now has detailed descriptions of the settings.
        </p>

        <p>
          <b>Restricting Students Booking:</b> If you allow your students to book lessons online, you can now restrict
          the sections that they are allowed to book. Choose Yes on the settings page for 'restrict client sections' and
          then on the Student's detail page you will be able to specify which ones. Admins will still be able to put
          students in any lesson from the main calendar.
        </p>
        <p>
          <b>Lesson booking from student page:</b> When the Book Lesson button on the student page is clicked, it will
          now show a list of available lessons for that student. It excludes lessons the student is already enrolled in,
          plus any sections that they are not eligible for. It also now shows mulitple days at once.
        </p>
        <p>
          <b>Booking Makeups:</b> There is a new Book Makeup button on the student page. When clicked it will list any
          eligible makeup lessons the student may have. When the appropriate makeup is clicked, only lessons that are
          within the makeups start and expiry dates will be shown. It excludes any lesson the student is already
          enrolled in. This replaces booking the makeup lesson from the Student's package list. It also shows multiple
          days at once.
        </p>
        <p>
          <b>Staff Color:</b> "You can now assign or change a staff person's calendar color from their main page."
        </p>
        <p>
          <b>Mobile: </b> General improvements for mobile screens{" "}
        </p>
      </div>
    </div>
  );
};
