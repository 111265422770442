import React, { useState, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";

import { Route, Switch } from "react-router-dom";
import { ListContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import { BlueInvLink, Row2, BlueNavLinkSm, PageTitle } from "./styleCustom";
import COLORS from "./styleConstants";
import { FarmUpdatePrefs } from "./farmUpdatePrefs";
import PrivateRoute from "./privateRoute";
import Locations from "./locations";
import Roles from "./roles";
import { MyContext } from "./App";
import { ButtonOrange } from "./formComponents";
import { UserCan, Dialog } from "./formComponents";
import SupportMessage from "./support";
const Auth = new AuthHelperMethods();

import "./faq.css";

const Faq = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const [support, setSupport] = useState({ subject: "", message: "" });
  const [showModal, setShowModal] = useState(false);

  const [source, setSource] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const linkStyle = {
    display: "flex",
    fontSize: 16,
    marginBottom: 15,
    color: COLORS.blue,
    cursor: "pointer",
    flexWrap: "wrap",
  };

  return (
    <>
      <div style={{ display: "flex", minWidth: "100%" }}>
        {" "}
        <UserCan isThis={"ADM"}>
          <SupportMessage />
        </UserCan>
      </div>
      <h2>FAQ</h2>
      <div style={{ display: "flex-col" }}>
        <h5>Why is the package not being applied to the lessons?</h5>
        <p>
          First look at the start and expiration date of the package. Make sure the lesson date is between those dates.
          Make sure the package has a valad program (Setup=> Programs and look under valid programs) for the section
          type of the lesson (you can get this information from the Calendar by clicking on the lesson and lookin at its
          section). You can see what Program the Section is under by going to Setup=> Sections and it will show you the
          program for each section. Once you have made changes you can have the system recalculate the package by going
          to the student's page and viewing packages.
        </p>

        <h5>Can multiple emails be setup for one account holder?</h5>
        <p>At this time, only one email per account holder but we are working to add this capability. </p>

        <h5>How can I change the package dates after it has been created?</h5>
        <p>
          {" "}
          Admin=> Package reports => Packages with accounts as of today=> find the package you are looking for and click
          on student's name=> you can adjust any of the package information there{" "}
        </p>

        <h5>Is there a way to edit invoices?</h5>
        <p>
          Not at this time. If an error has been made on an invoice the best option is to delete and start over. We will
          be adding a more robust invoicing module in the future.{" "}
        </p>

        <h5>Can I add multiple packages to one transaction/ invoice?</h5>
        <p>Click on all the packages you would like added before creating the invoice/ processing payment. </p>

        <h5>Can I put multiple family members on the same payment transaction/ invoice? </h5>
        <p>
          From one student's account page, add the packages for the first student. Then, click on the next student in
          the same family on the bottom left to go to their page. Add the packages for the second student. When you
          create the invoice each student will be a seperate item on the invoice.{" "}
        </p>

        <h5> How do I purchase a subscription during/ after my trial?</h5>
        <p>Click the blue link at the top of any page where it says "To update to a paid subscription click here". </p>

        <h5> How do I change my credit card on file?</h5>
        <p>Setup=> Settings=> Manage Payment Method</p>

        <h5> How do I sign up for stripe?</h5>
        <p>Setup=> Settings=> scroll all the way to the bottom and Click to connect or create Stripe Account </p>

        <h5>I have added a program but it is not showing up when I try to add a lesson. </h5>

        <p>
          Remember once you create a program, you next have to create a section (Setup=> Sections) with that program.
          The sections are what you see when you add a lesson.
        </p>
        <h5>Can I split packages between siblings?</h5>

        <p> Not at this time. </p>
        <h5> Can I uncancel a lesson after it has been cancelled?</h5>
        <p>
          Duplicate the canceled lesson (Orange buttons at the top of the lesson page on the calendar) Then delete the
          canceled lesson.
        </p>
        <h5>How can I uncancel a student from a lesson? </h5>
        <p>Delete the student from the lesson, then add the student back. </p>
        <h5>Can I delete a payment? </h5>
        <p>
          You can delete manual payments, ie not online (Stripe) payments. By going to Admin=> Payment report=> delete
          Note- deleting a payment will delete also delete all associated packages.{" "}
        </p>
        <h5>I added something new in the setup and it is not showing up. </h5>
        <p>Try refreshing your browser to update the latest settings. </p>
        <h5>Why aren't my clients getting notifications?</h5>
        <p>Setup=> Settings=> Misc =>Send notifications=> Yes</p>
        <h5>How can I prevent cancellation notices from being sent? </h5>
        <p>Setup=> Settings=> Misc =>Send cancellation notices=> No</p>
        <h5>How can I prevent my clients from booking a lesson at the last minute. </h5>
        <p>
          Setup=> Settings=> Booking Preferences => Booking hours buffer=> add the number of hours you would like before
          a lesson starts.
        </p>
      </div>
    </>
  );
};

export default Faq;
