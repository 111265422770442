import React, { useState, useContext, useEffect, useRef } from "react";
import AuthHelperMethods from "./AuthHelperMethods";
import axios from "axios";
import { ListContext, MyContext } from "./App";
import { saveAs } from "file-saver";
import COLORS from "./styleConstants";
import { dateTimeISOtoStringMed } from "./momentUtils";
import APIURL from "./Constants";
const Auth = new AuthHelperMethods();
//const APIURL = process.env.REACT_APP_APIURL;
//const APIURL = process.env.NODE_ENV == "development" ? "http://localhost:3001/" : process.env.REACT_APP_APIURL;

export const FileUpload = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  const [image, setImage] = useState({});
  const [fileList, setFileList] = useState([]);
  const [currentLink, setCurrentLink] = useState();
  const [currentFileName, setCurrentFileName] = useState();

  const [lastUpload, setLastUpload] = useState();
  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile] = useState({});
  const [currentDownload, selectCurrentDownload] = useState();
  const minSize = 0;
  const maxSize = 5242880;
  const fileUpload = useRef(null);

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          //   dispatch({ type: "FETCH_INIT" });
          let params = { horse_id: listState.currentHorse.id };
          const result = await Auth.api("file_storages/list", "get", params);
          setFileList(result.data.horsefiles);
          //dispatch({ type: "FETCH_SUCCESS" });
        } catch (error) {
          // dispatch({ type: "FETCH_ERROR" });

          console.log("error");
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    [lastUpload]
  );

  const FileList = () => {
    const getUrl = (fileh) => {
      // setCurrentLink();
      setCurrentFileName(fileh.file_name);
      dispatch({
        type: "FETCH_INIT",
      });
      let filename = `${fileh.file_code}`;

      try {
        fetch(`${APIURL}file_storages/getUrl?q=${filename}`, {
          method: "GET",
          headers: { Authorization: `Bearer  ${Auth.getToken()}` },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data.url);
            setCurrentLink(data.url);
          });
        dispatch({
          type: "FETCH_SUCCESS",
        });
      } catch (e) {
        dispatch({
          type: "FETCH_ERROR",
        });
      }
      // .then(() => fileDownloadRef.current && fileDownloadRef.current.click());
    };

    if (fileList.length == 0) return <div>{"There are no documents on record for this horse"}</div>;

    return (
      <>
        <div style={{ marginTop: 30 }}>
          {currentLink && (
            <div style={{ margin: 30, background: COLORS.orange, color: "white", padding: 10, fontSize: 16 }}>
              <a style={{ color: "white" }} href={`${currentLink}`} target="_blank">
                {`Click To View ${currentFileName}`}
              </a>
            </div>
          )}

          {fileList.map((fileh) => (
            <>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "flexStart", fontSize: 14 }}>
                <div
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    color: COLORS.blue,
                    flex: "1 1 auto",
                    width: "350px",
                    flexWrap: "wrap",
                    fontSize: 16,
                  }}
                  onClick={() => {
                    getUrl(fileh);
                  }}
                >
                  {`${fileh.file_name}`}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-start", fontSize: 16 }}>
                  {dateTimeISOtoStringMed(fileh.created_at)}
                </div>
              </div>
            </>
          ))}
        </div>
      </>
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log("submitting");
    // console.log(image.["name";
    //const form = new FormData();
    console.log(e);
    console.log(name);
    console.log(selectedFile);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", selectedFile);

    formData.append("horse_id", listState.currentHorse.id);

    let x = name.split(".").pop();
    formData.append("extension", x);
    console.log(x);

    /* form.append("image", image);
    form.append("name", image.name);
    form.append("horse_id", listState.currentHorse.id);
    form.append("extension", x);
*/
    //form.append("video", this.state.video);
    fetch(`${APIURL}file_storages/upload`, {
      method: "POST",
      body: formData,
      headers: { Authorization: `Bearer  ${Auth.getToken()}` },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.id);
        setLastUpload(data.id);
        setName("");
        setSelectedFile();
      });
  };

  const FileUploader = ({ onFileSelectSuccess }) => {
    const fileInput = useRef(null);

    const handleFileInput = (e) => {
      // e.preventDefault();
      console.log(e);
      console.log(e.target.files[0]);
      console.log("handleFileInput");

      const file = e.target.files[0];
      // handle validations
      onFileSelectSuccess(e.target.files[0]);
    };

    return (
      <div className="file-uploader">
        <input type="file" onChange={handleFileInput} ref={fileInput} style={{ display: "none" }} />
        <button
          type="button"
          onClick={(e) => fileInput.current && fileInput.current.click(e)}
          className="btn btn-primary"
        >
          {"Browse Files To Upload"}
        </button>
      </div>
    );
  };

  const onSuccess = (file) => {
    if (file.size > maxSize) {
      alert("File is too large - must be under 5MB");
    } else {
      console.log(file);
      setSelectedFile(file);
      setName(file.name);
      console.log(file.size);
    }
  };

  if (!listState.currentHorse.id) return null;

  return (
    <div className="form">
      <form>
        <FileUploader onFileSelectSuccess={(file) => onSuccess(file)} />
        {name && (
          <button className="btn btn-primary" style={{ marginTop: 5 }} onClick={onSubmit}>{`Upload ${name}`}</button>
        )}
      </form>
      <FileList />
    </div>
  );
};
