import React, { useReducer, Fragment, useState, useEffect, useContext } from "react";
import axios from "axios";
import Select from "react-select";
import { MyContext } from "./App";
import { valueFromId } from "./momentUtils";
import { colourStyles } from "./styleCustom";

const SelectLocation = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  //console.log(props);
  //console.log("SelectLocation props");

  const handleLocationChangeAdd = (event) => {
    dispatch({
      type: "SET_NEW_EVENT",
      data: { location_id: event.value },
    });
  };

  const handleLocationChangeEdit = (event) => {
    let d = valueFromId(mystate.options_location, event.value);
    dispatch({
      type: "EDIT_CURRENT_LOCATION",
      data: d,
    });
  };

  return (
    <div>
      {mystate.options_location && (
        <Select
          onChange={props.addOrEdit == "edit" ? handleLocationChangeEdit : handleLocationChangeAdd}
          className="form-control"
          value={mystate.options_location.filter(
            (option) =>
              option.value ==
              (props.addOrEdit == "edit" ? mystate.currentLesson.location_id : mystate.addevent.location_id)
          )}
          options={mystate.options_location}
          clearable={true}
          searchable={false}
          placeholder={"Select Location..."}
          styles={props.styles || colourStyles}
        />
      )}
    </div>
  );
};

export default SelectLocation;
