import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, NavLink, useParams } from "react-router-dom";

import { ListContext } from "./App";
import { MyContext } from "./App";
import {
  PrimaryTextBlue20,
  PrimaryText,
  Td,
  StyledTr,
  TableText1,
  TableText2,
  Th,
  HelpText,
  StyledLink,
  PageTitle,
} from "./styleCustom";
import axios from "axios";
import AuthHelperMethods from "./AuthHelperMethods";
import COLORS from "./styleConstants";
import { CheckSquare, Check } from "./icons";
import SelectTemplateFilter from "./searchFilterReports";
import Select from "react-select";
import PrivateRoute from "./privateRoute";
//import WaiverRetrieve, { WaiverRetrieveList, TemplateListRetrieve, DynamicTemplate } from "./waiverRetrieve";
import { nametagStyle } from "./styleConstants";
import styled from "styled-components";
import { dateISOtoStringMed, dateISOtoStringShortWithYear, calculateAge } from "./momentUtils";
const Auth = new AuthHelperMethods();

const Waiver = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [templatesList, setTemplateList] = useState([]);
  const [myWaivers, setMyWaivers] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState();
  const [currentWaiverList, setCurrentWaiverList] = useState([]);
  const [currentWaiverDetails, setCurrentWaiverDetails] = useState([]);

  let params = { person_id: listState.accountHolder.id };
  const GreyHeaderText = styled.span`
    color: ${COLORS.textgrey};
  `;
  useEffect(
    () => {
      const fetchData = async () => {
        try {
          dispatch({ type: "FETCH_INIT" });

          const result = await Auth.api("waiver/getAccountWaiverList", "get", params);
          console.log(result.data.waivers);
          setMyWaivers(result.data.waivers);
          dispatch({
            type: "FETCH_SUCCESS",
          });
        } catch (e) {
          dispatch({ type: "FETCH_ERROR" });

          console.log(e);
        }
        // setSections(result.data.studentSections);
        // dispatch2(["HORSE_DATA", result.data]);
      };
      if (listState.accountHolder.id) fetchData();
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          dispatch({ type: "FETCH_INIT" });

          const result = await Auth.api("waiver/getTemplateListDataBase", "get");
          //   console.log(result.data.waivers);
          setTemplateList(result.data.templates);
          dispatch({
            type: "FETCH_SUCCESS",
          });
        } catch (e) {
          dispatch({ type: "FETCH_ERROR" });

          console.log(e);
        }
        // setSections(result.data.studentSections);
        // dispatch2(["HORSE_DATA", result.data]);
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );
  const getAndSetCurrentWaiverDetails = async (waiverId) => {
    console.log(waiverId);
    try {
      let params = { id: waiverId };
      const result = await Auth.api("waiver/waiverRetrieve", "get", params);
      setCurrentWaiverDetails(result.data.waiver.waiver);
    } catch (e) {
      console.log(e);
    }
  };
  const getWaiverList = async (templateId) => {
    setCurrentTemplate(templateId);
    setCurrentWaiverDetails();

    try {
      let params = { template_id: templateId, id: templateId };
      // const result = await Auth.api("waiver/getTemplateWaiverListDataBase", "get", params);
      const result = await Auth.api("waiver/waiverList", "get", params);

      //  setCurrentWaiverList(result.data.waivers);
      setCurrentWaiverList(result.data.waiver.waivers);

      // console.log(result.data.waivers);
    } catch (e) {
      console.log(e);
    }
  };

  const WaiverRetrieve = () => {
    // const [waiver, setWaiver] = useState();
    let waiver = currentWaiverDetails;
    const [image, setImage] = useState();
    const params = useParams();
    //const [guardian, setGuardian] = useState();
    const [custom, setCustom] = useState();
    const [customWaiverValues, setCustomWaiverValues] = useState();
    let [riders, setRiders] = useState([]);
    // console.log(props.waiver);
    // console.log(props.waiver.participants);

    //var participants;
    // console.log(params);

    //  const result = await Auth.api("waiver/waiverRetrieve", "get", params);

    // setWaiver(props.waiver);
    //   setWaiver(currentWaiverDetails);

    //  setImage(props.waiver.pdf);
    //  console.log(result.data.waiver.waiver.customWaiverFields);
    console.log(currentWaiverDetails);
    const { participants, guardian, customWaiverFields } = currentWaiverDetails;

    const GetPeopleNames = () => {
      return (
        <div style={{ display: "flex", minWidth: 400, flexDirection: "column" }}>
          <GreyHeaderText>{"This waiver includes the following people:"}</GreyHeaderText>
          {participants.map((item) => (
            <div style={{ display: "flex", maxWidth: 400, flexGrow: 1 }}>
              <div style={{ display: "flex", flex: "1 1 40%" }}>{`${item.firstName} ${item.lastName}`}</div>
              <div style={{ display: "flex", flex: "1 1 15%" }}>{item.isMinor && "minor"}</div>
              {item.dob != "1800-01-01" && (
                <div style={{ display: "flex", flex: "1 1 35%" }}>
                  <div style={{ display: "flex", flex: "1 1 35%" }}>{`${dateISOtoStringShortWithYear(item.dob)}`}</div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>{`age ${calculateAge(item.dob)} `}</div>
                </div>
              )}{" "}
            </div>
          ))}
          {guardian && (
            <div>
              <GreyHeaderText>{`Guardian Specified On Waiver:`}</GreyHeaderText>
              <div>{`${guardian["firstName"]} ${guardian["lastName"]}`}</div>
              <div>{`${guardian["relationship"]} `}</div>
              <div>{`${guardian["phone"]} `}</div>
            </div>
          )}
          <GreyHeaderText>{`Main Email:`}</GreyHeaderText>
          <div>{currentWaiverDetails.email}</div>
          <GreyHeaderText>{`Address:`}</GreyHeaderText>
          <div>{`${currentWaiverDetails.addressLineOne} ${currentWaiverDetails.addressLineTwo}`}</div>
          <div>{`${currentWaiverDetails.addressCity} ${currentWaiverDetails.addressState} ${currentWaiverDetails.addressZip}`}</div>
          <div>{`${currentWaiverDetails.addressCountry} `}</div>
        </div>
      );
    };

    const OtherMainFields = () => {
      const GreyHeaderText = styled.span`
        color: ${COLORS.textgrey};
      `;

      const {
        driversLicenseNumber,
        driversLicenseState,
        emergencyContactName,
        emergencyContactPhone,
        expirationDate,
        insuranceCarrier,
        insurancePolicyNumber,
        verified,
      } = currentWaiverDetails;

      const Row = (props) => {
        if (!props.value) return null;
        return (
          <>
            <GreyHeaderText>{`${props.desc}:`}</GreyHeaderText>
            <div>{props.value}</div>
          </>
        );
      };

      return (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Row desc={`Drivers License Number`} value={driversLicenseNumber} />

            <Row desc={`Drivers License State`} value={driversLicenseState} />
            <Row desc={`Emergency Contact Name`} value={emergencyContactName} />
            <Row desc={`Emergency Contact Phone`} value={emergencyContactPhone} />
            <Row desc={`Insurance Carrier`} value={insuranceCarrier} />
            <Row desc={`Insurance Policy Number`} value={insurancePolicyNumber} />
            <Row desc={`Waiver Expiration Date`} value={expirationDate} />
            <Row desc={`Waiver Verified`} value={verified ? "yes" : "no"} />
          </div>
        </>
      );
    };

    const Participants = () => {
      //  console.log(Object.keys(riders).map((key) => <div> {key}</div>));
      //if (!guardian) return null;
      return (
        <div>
          {participants.map(
            (participant) =>
              participant["customParticipantFields"] && (
                <div>
                  <PrimaryText>{`Additional Participant Information For ${participant.firstName}`}</PrimaryText>

                  {Object.values(participant["customParticipantFields"]).map((item) => (
                    <>
                      <PrimaryText> {item.displayText} </PrimaryText>
                      <div>{item.value}</div>
                    </>
                  ))}
                </div>
              )
          )}
        </div>
      );
    };
    const CustomWaiverFields = () => {
      if (!customWaiverValues) return null;
      if (!Object.entries(customWaiverValues).length > 0) return null;
      return (
        <>
          <PrimaryText> {"Other"} </PrimaryText>
          {customWaiverValues.map((item) => (
            <>
              <PrimaryText>{`${item.displayText}: `}</PrimaryText>
              <div>{`${item.value}`}</div>
            </>
          ))}
        </>
      );
    };
    //<a href={`data:application/pdf;base64,${waiver.PDF}`}>dunno</a>
    const getWaiver = async (waiverId) => {
      let params = { id: waiverId };
      const result = await Auth.api("waiver/waiverRetrievePdf", "get", params);
      if (result.data.waiver) downloadPDF(result.data.waiver);
    };

    function downloadPDF(pdf) {
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = waiver ? `${waiver.title}_${waiver.firstName}${waiver.lastName}.pdf` : "waiver.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      setImage(downloadLink);
      downloadLink.click();
    }
    if (!waiver) return null;
    if (!currentWaiverDetails) return null;
    if (!participants && !participants.length > 0) return <div>"no riders"</div>;
    return (
      <div
        style={{
          display: "flex",
          flex: "1 1 100%",
          flexWrap: "wrap",
          flexDirection: "column",
          marginBottom: 10,
        }}
      >
        <PrimaryText style={{ marginTop: 10 }}>{`${waiver.title}`} </PrimaryText>
        <div style={{ cursor: "pointer" }} onClick={() => getWaiver(waiver.waiverId)}>
          <span style={{ cursor: "pointer", color: COLORS.orange }}>{"download pdf"}</span>
        </div>
        <div>{`Waiver created on ${dateISOtoStringMed(waiver.createdOn)}`}</div>

        <GetPeopleNames />
        <OtherMainFields />
        <Participants />
        <CustomWaiverFields />
      </div>
    );
  };

  const ListWaiversFromDb = (props) => {
    let currentWaiverList = props.currentWaiverList;
    if (!currentWaiverList) return null;
    if (currentWaiverList.length == 0) return <div>{"No waivers found for this template"}</div>;

    return currentWaiverList.map((waiver) => (
      <div key={waiver.waiver_id} style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flex: "1 1 auto",
            color: COLORS.textgrey,
            fontSize: 16,
            border: "solid 1px lightgrey",
            margin: 2,
          }}
          onClick={() => setCurrentWaiverDetails(waiver.waiver_json)}
        >{`${waiver.waiver_json.firstName}   ${waiver.waiver_json.lastName}`}</div>
        {currentWaiverDetails && currentWaiverDetails.waiverId == waiver.waiver_id && currentWaiverList.length > 0 && (
          <WaiverRetrieve />
        )}
      </div>
    ));
  };

  const ListWaiversFromApi = (props) => {
    console.log(props);
    let currentWaiverList = props.currentWaiverList;
    console.log(currentWaiverList);
    console.log(currentWaiverList.length == 0);

    if (!currentWaiverList) return null;
    if (currentWaiverList.length == 0) return <div>{"No waivers found for this template"}</div>;

    return (
      <>
        {currentWaiverList.map((waiver) => (
          <div key={waiver.waiverId} style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flex: "1 1 auto",
                color: COLORS.textgrey,
                fontSize: 16,
                border: "solid 1px lightgrey",
                margin: 2,
                cursor: "pointer",
              }}
              onClick={() => getAndSetCurrentWaiverDetails(waiver.waiverId)}
            >
              {`${waiver.firstName}   ${waiver.lastName}`}
            </div>
            {currentWaiverDetails &&
              currentWaiverDetails.waiverId == waiver.waiverId &&
              currentWaiverList.length > 0 && <WaiverRetrieve />}
          </div>
        ))}
      </>
    );
  };

  const TemplateListDB = () => {
    if (!templatesList) return <div>{"There are no active waiver templates"}</div>;

    return (
      <>
        <PrimaryTextBlue20>{"Available Waivers"}</PrimaryTextBlue20>
        <HelpText>{"Click on template to see signed waivers"}</HelpText>
        {templatesList.map((template) => (
          <>
            <div key={template.id} style={nametagStyle} onClick={() => getWaiverList(template.template_id)}>
              {template.title}
            </div>
            {currentTemplate && currentTemplate == template.template_id && (
              <div
                style={{
                  display: "flex",
                  flex: "1 1 100%",
                  flexWrap: "wrap",
                  marginBottom: 30,
                }}
              >
                <div style={{ display: "flex", minWidth: 400, flexDirection: "column" }}>
                  <>
                    {" "}
                    <PrimaryTextBlue20>
                      {" "}
                      {`Accounts with signed waivers for template ${template.title}`}
                    </PrimaryTextBlue20>
                    <HelpText>{"Click on waiver name to see waiver details"}</HelpText>
                  </>

                  <ListWaiversFromApi currentWaiverList={currentWaiverList} />
                </div>
              </div>
            )}
          </>
        ))}
      </>
    );
  };

  /* useEffect(
    () => {
      const fetchData = async () => {
        try {
          const result = await Auth.api("waiver/templateList", "get");
          setTemplateList(result.data.templates);
          dispatch2(["SET_TEMPLATE_LIST", result.data.templates]);
        } catch (e) {
          console.log(e);
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );*/

  //if (!templatesList) return null;
  return (
    <>
      <PageTitle>{"Waivers"}</PageTitle>
      <div style={{ display: "flex", flex: "1 1 100%" }} className="fullwaiverdiv">
        <div style={{ flex: "1 1 50%", marginRight: 30 }} className="templateList">
          {" "}
          <TemplateListDB />
        </div>
        <div style={{ flex: "1 1 50%" }} className="waiverList">
          {" "}
        </div>
      </div>
    </>
  );
};

export default Waiver;
