import React, { useState, useContext, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import getRepeatFirstLast from "./getLastDate";
import SelectSectionDefaults from "./selectSectionDefaults";
import SelectInstructor from "./selectInstructor";
import SelectStudent from "./selectStudent";
import SelectHorse from "./selectHorse";
import SelectLocation from "./selectLocation";
import TextArea from "./textInputgeneric";
import TextInputTitle from "./textInputTitle";
import TextInputDesc from "./textInputDesc";
import SelectProduct from "./selectProduct";
import TimeAndDate from "./timeanddate";
import RepeatingDates from "./repeatingDates";
import { Button, Row, Col, Container, Modal } from "react-bootstrap";
import { MyContext, ListContext } from "./App";
import axios from "axios";
import {
  formatDateTimeForDB,
  dateObjToDisplayDate,
  dateObjToDisplayTime,
  dateObjToDBDate,
  getNewDefaultDateforMonth,
  calculateEndTime,
  repeatOptions,
  dateISOtoString,
  dateISOtoStringMed,
  dateISOtoStringShort,
  timeISOtoStringN,
  currentDayToDB,
} from "./momentUtils";
import AuthHelperMethods from "./AuthHelperMethods";
import "./style.css";
import ReactTooltip from "react-tooltip";
import { useAlert } from "react-alert";
import "./styleAdd.css";
import { TitleRow } from "./blueCheckFuture";
import { ClientAvatar, HorseAvatar, StaffAvatar, LocationAvatar } from "./images";
import { NoteIcon, LocationIcon, RepeatIcon, ClockIcon, TitleIcon } from "./icons";
import moment from "moment";
import COLORS from "./styleConstants";
import { SelectTemplateFilter } from "./searchFilterReports";
import Payment from "./payment";
import PrivateRoute from "./privateRoute";
import { ButtonOrange, ButtonBlue } from "./formComponents";
import { ModalWarning } from "./formComponents";
import { CartList, CartTotal } from "./onlinePurchase";
import ManualPurchase from "./manualPurchaseForm";
import { TableHeaderNoAdd, OrangeLinkButton } from "./formComponents";

const Auth = new AuthHelperMethods();

const orangelabel = {};

const Registration = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const [data, SetData] = useState([]);
  const [studentData, SetStudentData] = useState([]);
  const [showClients, setShowClients] = useState(false);
  useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        const result = await Auth.api("lesson_date_time_series/getRegistrationList", "get", params);
        SetData(result.data.counts);
        SetStudentData(result.data.stu);
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  return (
    <>
      <PrivateRoute path={`${props.match.url}/registrationAdd`} component={RegistrationAdd} />

      <TableHeaderNoAdd title={"Registration"}>
        <Link to={`${props.match.url}/registrationAdd`}>
          <OrangeLinkButton>{"Add New Program"} </OrangeLinkButton>{" "}
        </Link>
      </TableHeaderNoAdd>
      <ButtonOrange label={"Show Clients"} onClick={() => setShowClients(!showClients)} />

      <div style={{ display: "flex", flexDirection: "column" }}>
        {data &&
          data.map((reg) => (
            <div style={{ border: "solid .5pt", padding: 15, minWidth: 600, marginBottom: 5, color: COLORS.textgrey }}>
              <div className="title" style={{ marginBottom: 5, color: COLORS.textDark, fontWeight: "bold" }}>
                {reg.title}
              </div>
              <div>
                {`
              ${dateISOtoString(reg.starttime)} to   ${dateISOtoString(reg.endtime)}
             `}
              </div>
              <div>
                {`
              ${timeISOtoStringN(reg.start_time_day)} to   ${timeISOtoStringN(reg.end_time_day)}
             `}
              </div>
              <div className="more">{reg.description}</div>
              <div className="more">{reg.section_name}</div>
              <div className="more">{reg.product_name}</div>
              <div className="more">{reg.max_enrollment}</div>

              {showClients &&
                studentData
                  .filter((student) => student.lesson_date_time_series_id == reg.id)
                  .map((person) => <div style={{ color: COLORS.blue }}> {person.person_name}</div>)}
            </div>
          ))}
      </div>
    </>
  );
};

const RegistrationAdd = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const [data, SetData] = useState([]);
  const [studentData, SetStudentData] = useState([]);
  const [showAddReg, setShowAddReg] = useState(false);

  const alert = useAlert();
  let history = useHistory();
  let x = useParams();
  console.log(x);
  console.log(x.add);

  useEffect(
    () => {
      //let start = moment();

      let isAllDay = false;
      const wholedate = moment().toDate();
      const endtime = calculateEndTime(moment(wholedate).toDate(), mystate.sectionDefaults.duration);

      let defulatsectionid = mystate.sectionDefaults.section_id;
      console.log();

      dispatch({
        type: "INITIALIZE_NEW_EVENT",

        data: {
          start: wholedate,
          section_id: Auth.getUserType() == "STU" ? null : defulatsectionid,
          instructor_id: mystate.sectionDefaults.instructor_id,
          //event.resourceId ? event.resourceId : mystate.sectionDefaults.instructor_id,
          location_id: mystate.sectionDefaults.location_id,

          duration: mystate.sectionDefaults.duration,

          title: mystate.sectionDefaults.title,
          is_recuring: true,
          repeat_end_type: "",
          uniqid: null,
          end: endtime,
          is_registration: true,
        },
      });

      dispatch({
        type: "SET_REPEAT_VALUES",
        data: {
          selectRepeatValue: repeatOptions[0],
        },
      });

      dispatch({
        type: "SET_DATE_TIME_COMPONENT",
        start: moment().toDate(),
        end: endtime,
        is_allday: isAllDay,
      });
    },
    //eslint-disable-next-line
    []
  );

  //  const alertb = useAlert();
  //  const alerta = alert.show("Some message");
  //const alert = alert.info("You are adding a lesson!", { timeout: 0 });
  const successmsg = () => {
    return `${mystate.addevent.is_recuring ? "Recurring " : ""}  Event ${
      mystate.addevent.title
    }  on ${dateObjToDisplayDate(mystate.dateTimeComponent.start)}
       at  ${dateObjToDisplayTime(mystate.dateTimeComponent.start)} has been saved successfully.`;
  };

  const handleCancel = () => {
    history.goBack();
    console.log("click handleCancel");
    //dispatch({ type: "HIDE_ADD_MODAL" });
  };

  //const handleShow = () =>

  //  ReactModal.setAppElement("body");

  const saveNewEvent = async () => {
    dispatch({
      type: "FETCH_INIT",
    });
    if (mystate.addevent.section_id == undefined) {
      alert.show("section is required", { timeout: 15000, type: "warning" });
      dispatch({
        type: "FETCH_SUCCESS",
      });
      return null;
    }
    if (mystate.addevent.product_id == undefined) {
      alert.show("package is required", { timeout: 15000, type: "warning" });
      dispatch({
        type: "FETCH_SUCCESS",
      });
      return null;
    }
    const { horses, students, start, end } = mystate.addevent;
    let studentsArray = [],
      horsesArray = [];

    if (students) {
      studentsArray = students.map((student) => {
        return student.value;
      });
      console.log(studentsArray);
    }
    if (horses) {
      horsesArray = horses.map((horses) => {
        return horses.value;
      });
      console.log(horsesArray);
    }

    const repeatObj = getRepeatFirstLast(mystate, {});
    const datetimeInfo = formatDateTimeForDB(mystate.dateTimeComponent);
    const dbParams = {
      ...mystate.addevent,
      ...repeatObj,
      ...datetimeInfo,
      students: studentsArray,
      horses: horsesArray,
    };
    console.log(dbParams.date1);

    let route = mystate.addevent.is_recuring ? "lessons/createRecurringLesson" : "lessons/createSingleLesson";

    try {
      const result = await Auth.api(route, "post", dbParams);
      //const data= await result;
      console.log(mystate.addevent.is_recuring);

      dispatch({ type: "ADD_NEW_LESSON", payload: result.data });

      dispatch({
        type: "FETCH_SUCCESS",
      });

      //dispatch({ type: "HIDE_ADD_MODAL" });
      history.goBack();

      alert.show(successmsg(), { timeout: 15000, type: "success" });

      Auth.getConfirm();
      return result;
    } catch (error) {
      dispatch({ type: "FETCH_ERROR" });
      alert.show(error.message, { timeout: 5000, type: "error" });
    }
  };

  let location_id = Number(mystate.addevent.location_id);

  const updateMaxEnrollment = (e) => {
    dispatch({
      type: "SET_NEW_EVENT",
      data: { max_enrollment: e.target.value },
    });
  };

  return (
    <>
      <Container className="add" style={{ margin: 0 }}>
        <TitleRow clickevent={() => handleCancel()} title={"Add Registration"} />
        {1 == 1 && (
          <>
            <Row>
              <Col xs="12" md="6" lg="4">
                <Col xs="12">
                  <div className="blocklabel">{"SECTION "}</div>

                  <SelectSectionDefaults />
                </Col>

                <Col xs="12">
                  <div className="blocklabel" style={orangelabel}>
                    {"PACKAGE"}
                  </div>
                  <SelectProduct addOrEdit={"add"} />
                </Col>
                <Col xs="12">
                  <div className="blocklabel" style={orangelabel}>
                    <TitleIcon /> {"TITLE"}
                  </div>

                  <TextInputTitle addOrEdit={"add"} />
                </Col>
                <Col xs="12" className="description">
                  <div className="blocklabel" style={orangelabel}>
                    {"DESCRIPTION"}
                  </div>

                  <TextInputDesc />
                </Col>
                <Col xs={12}>
                  <div className="blocklabel" style={orangelabel}>
                    {"MAXIMUM ENROLLMENT"}
                  </div>

                  <input
                    className="max"
                    style={{ width: "100%" }}
                    type="number"
                    label="maximum enrollment"
                    name="max_enrollment"
                    onChange={(e) => updateMaxEnrollment(e)}
                  />
                </Col>
              </Col>

              <Col xs="12" md="6" lg="4">
                <div className="blocklabel" style={orangelabel}>
                  <ClockIcon />
                  {"DATE OF FIRST LESSON "}
                </div>
                <TimeAndDate />
              </Col>
              <Col xs="12" md="6" lg="4">
                <div className="blocklabel" style={orangelabel}>
                  <RepeatIcon /> {"RECURRING "}
                </div>
                <RepeatingDates />
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="6" lg="4">
                <div className="blocklabel" style={orangelabel}>
                  <ClientAvatar />
                  {"CLIENTS"}{" "}
                </div>

                <SelectStudent addOrEdit={"add"} />
              </Col>

              <Col xs="12" md="6" lg="4">
                <div className="blocklabel" style={orangelabel}>
                  <HorseAvatar /> {"HORSES"}
                </div>
                {mystate.options_horse && <SelectHorse add horse />}
              </Col>

              <Col xs="12" md="6" lg="4">
                <div className="blocklabel" style={orangelabel}>
                  <StaffAvatar /> {"STAFF"}
                </div>
                <SelectInstructor addOrEdit={"add"} />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6" lg="4">
                <div className="blocklabel" style={orangelabel}>
                  <LocationIcon /> {"LOCATION "}
                </div>
                <SelectLocation />
              </Col>

              <Col xs="12" md="6" lg="4">
                <div className="blocklabel" style={orangelabel}>
                  <NoteIcon /> {"NOTES"}
                </div>

                <TextArea style={{ border: "solid blue" }} addOrEdit={"add"} />
              </Col>
            </Row>

            <Row style={{ marginLeft: 50, marginRight: 50 }} />

            <Row style={{ marginTop: "50px", marginBottom: "50px" }}>
              <div style={{ margin: "auto" }}>
                {" "}
                <Button
                  className="btn-close"
                  size="md"
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  Cancel
                </Button>
                <Button className="btn-save" size="md" onClick={() => saveNewEvent()}>
                  Save Event
                </Button>
              </div>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default Registration;
