import React, { useState, useEffect, useContext } from "react";
import COLORS from "./styleConstants";
export const horseAvatar = require("./horse_avatar_01.svg").default;
export const clientAvatar = require("./client_avatar_02.svg").default;
export const locationAvatar = require("./staff_avatar_04.svg").default;
export const deleteImg = require("./closeIcn-dark2.svg").default;
export const privateNoteImg = require("./private_note_icon.jpg").default;
export const publicNoteImg = require("./public_note_icon.jpg").default;
export const staffAvatar = require("./staff_avatar_03.svg").default;
export const blueCheck = require("./blueTickSml.svg").default;
export const downtArw = require("./downtArw.svg").default;
export const logo = require("./Equine_Office_logo.svg").default;
export const menuicon = require("./menuicon.svg").default;
export const logoRed = require("./horseonlypng191.png").default;
export const horseGreenBig = require("./horseGreenBig.svg").default;
export const horseSleep = require("./noHorses.svg").default;

export const Logo = () => {
  return <img style={{ width: 200, height: 100 }} src={logoRed} />;
};

export const MenuIcon = (props) => {
  console.log(props);
  return (
    <img
      src={menuicon}
      alt={"menu"}
      style={{
        background: COLORS.orange,
        width: 45,
        height: 35,
        zIndex: 100,
        cursor: "pointer",
        position: "absolute",
        left: props.showLeftNav ? -50 : 0,
        marginTop: 20,
        transform: props.showLeftNav ? "none" : "rotate(-180deg)",
        paddingLeft: props.showLeftNav ? 0 : 10,
      }}
    />
  );
};

export const HorseAvatar = (props) => {
  return <img style={{ width: props.width, height: props.height, minHeight: 10 }} src={horseAvatar} alt="horse" />;
};

export const ClientAvatar = (props) => {
  return <img style={{ width: props.width, height: props.height, minHeight: 10 }} src={clientAvatar} alt="client" />;
};

export const ClientAvatarLg = (props) => {
  return <img style={{ width: 70, height: 70 }} src={clientAvatar} alt="client" />;
};

export const StaffAvatar = (props) => {
  return <img style={{ width: props.width, height: props.height, color: props.color }} src={staffAvatar} alt="staff" />;
};

export const LocationAvatar = () => {
  return <img src={locationAvatar} alt="location" />;
};

export const HorseGreen = () => {
  return <img src={horseGreenBig} alt="greenhorse" />;
};

export const HorseSleep = () => {
  return <img src={horseSleep} alt="sleepinghorse" />;
};
