import React, { useContext, useReducer, useState, useEffect, useRef } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { InfoIcon, QuestionIcon } from "./icons";
import { Tooltip } from "./tooltips";
import ReactTooltip from "react-tooltip";
import COLORS from "./styleConstants";

import { MyContext } from "./App";

import { blueCheck } from "./images";
import { TimesIcon } from "./icons";

export const CheckImg = () => {
  return <img src={blueCheck} style={{ padding: "5px" }} />;
};

export const boxStyle = {
  border: "solid 1px lightgrey",
};

export const boxStyleYN = {
  minHeight: "30px",
  maxHeight: "30px",
  maxWidth: "75px",
  minWidth: "75px",
};
const boxStyleOn = {
  ...boxStyleYN,
  background: COLORS.orange,
  color: "white",
};

const boxStyleOff = {
  ...boxStyleYN,
  background: "lightgrey",
  color: "black",
};

let labelStyle = {
  marginTop: 0,
  textAlign: "left",
  border: "solid 1px ",
  borderLeft: "none",
  minHeight: "30px",
  maxHeight: "30px",

  borderRadius: "3px",
};

const labelStyleYes = {
  ...labelStyle,
  background: COLORS.orange,
  borderColor: COLORS.orange,
  color: "white",
};

const labelStyleNo = {
  ...labelStyle,
  borderColor: "#e6e6e6",
  whiteSpace: "noWrap",
};
const titleRow = {
  backgroundColor: COLORS.blue,
  color: "white",
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  padding: 5,
};

const x = {
  backgroundColor: COLORS.blue,
  margin: 5,
  border: "none",
};

export const TitleRow = (props) => {
  return (
    <div style={titleRow}>
      <div>
        <div className={"h4"}>{props.title}</div>
      </div>
      <div>
        <button style={x} onClick={props.clickevent}>
          <TimesIcon color={"white"} />
        </button>
      </div>
    </div>
  );
};

export const TitleRow2 = (props) => {
  return (
    <div
      style={{
        backgroundColor: COLORS.blue,
        color: "white",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        padding: 5,
      }}
    >
      <div>
        <div className={"h4"}>{props.title}</div>
      </div>
      <div>
        <button style={x} onClick={props.clickevent}>
          <TimesIcon color={"white"} />
        </button>
      </div>
    </div>
  );
};

export const CloseX = (props) => {
  return (
    <button className={"x"} onClick={props.clickevent}>
      <TimesIcon />
    </button>
  );
};

export const BlueCheckNoLabel = (props) => {
  return (
    <Col xs={3} style={boxStyle}>
      {props.checked ? <CheckImg /> : " "}
    </Col>
  );
};

export const BlueCheckBox = (props) => {
  const [checked, setChecked] = useState(props.default || false);
  const propfn = (kin) => {
    setChecked(!checked);
  };
  return (
    <Row
      noGutters
      className="justify-content-md-center"
      style={{ marginBottom: "5px", marginTop: "10px", textAlign: "center" }}
    >
      <Col xs={2}>
        <div onClick={() => propfn()} style={boxStyle}>
          {checked ? <CheckImg /> : " "}
        </div>
      </Col>
      <Col>
        {" "}
        <div style={checked ? labelStyleYes : labelStyleNo}>{props.label}</div>
      </Col>
    </Row>
  );
};

export const YesNoFuture = () => {
  const { mystate, dispatch } = useContext(MyContext);
  const propfn = (kin) => {
    dispatch({
      type: "SET_APPLY_TO_FUTURE",
      applyToFuture: !mystate.applyToFuture,
    });
  };
  return (
    <>
      <div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
        <label style={{ fontWeight: "bold", color: COLORS.orange }}>{"Apply changes to future events?"}</label>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button style={mystate.applyToFuture ? boxStyleOn : boxStyleOff} onClick={() => propfn(true)}>
          {"Yes"}
        </button>

        <button style={mystate.applyToFuture ? boxStyleOff : boxStyleOn} onClick={() => propfn(false)}>
          {"No"}
        </button>
      </div>
    </>
  );
};

export const BlueCheckBoxFutureOne = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const checked = mystate.applyToFuture;
  //const [applyToFuture, setApplyToFuture] = useState(false);
  //  const future = mystate.applyToFuture;
  const borderColor = checked ? "#ff7800" : "#e6e6e6";

  //  const [checked, setChecked] = useState(props.default || false);
  const propfn = (kin) => {
    dispatch({
      type: "SET_APPLY_TO_FUTURE",
      applyToFuture: !mystate.applyToFuture,
    });
  };

  return (
    <Row
      noGutters
      style={{
        marginBottom: "5px",
        marginTop: "10px",
        textAlign: "center",
        border: `solid 1pt ${COLORS.orange}`,
      }}
    >
      <Col xs={1} onClick={() => propfn()} style={boxStyle}>
        {checked ? <CheckImg /> : " "}
      </Col>
      <Col xs={3}>
        {" "}
        <div>
          <label style={labelStyleNo}>
            {" "}
            {mystate.applyToFuture
              ? "Changes will be applied to this and all future events."
              : "Changes will be applied to this event only."}
          </label>
        </div>
      </Col>
    </Row>
  );
};

export const BlueCheckFuture = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const [checked, setChecked] = useState(false);
  const [applyToFuture, setApplyToFuture] = useState(false);
  const future = mystate.applyToFuture;
  const borderColor = checked ? COLORS.orange : COLORS.grey;

  useEffect(
    () => {
      dispatch({ type: "SET_APPLY_TO_FUTURE", applyToFuture: false });
    },
    //eslint-disable-next-line
    []
  );

  const propfn = (yn) => {
    setChecked(yn);
    dispatch({ type: "SET_APPLY_TO_FUTURE", applyToFuture: yn });

    //props.testp(!checked);
  };

  return (
    <>
      <Tooltip />
      <Row noGutters>
        <Col xs={3} onClick={() => propfn(false)} style={boxStyle}>
          {!checked ? <CheckImg /> : " "}
        </Col>
        <Col>
          {" "}
          <div className="blocklabel" style={!checked ? labelStyleYes : labelStyleNo}>
            {props.label2}
          </div>
        </Col>
        <Col xs={1}>
          <InfoIcon
            datatip={
              "1.All changes made on this page will be applied immediately and to this event only. If you would like to apply your changes to all future events as well, change the indicator before making the changes"
            }
          />
        </Col>

        <Col xs={3} onClick={() => propfn(true)} style={boxStyle}>
          {checked ? <CheckImg /> : " "}
        </Col>
        <Col>
          {" "}
          <div className="blocklabel" style={checked ? labelStyleYes : labelStyleNo}>
            {props.label1}
          </div>
        </Col>
        <Col xs={1}>
          <InfoIcon
            datatip={
              "2.All changes made on this page will be applied immediately and to this event only. If you would like to apply your changes to all future events as well, change the indicator before making the changes"
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default BlueCheckFuture;
