import React, { useEffect, useState, useContext } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Route, Switch, useHistory, Link } from "react-router-dom";

import AuthHelperMethods from "./AuthHelperMethods";
import { ListContext, MyContext } from "./App";

import { PrimaryTextBlue } from "./styleCustom";
import { Modal } from "react-bootstrap";
import { todayDisplay } from "./momentUtils";
import COLORS from "./styleConstants";
import "./elementsStyle.css";
import { BlueButtonReg } from "./formComponents";
import OnlinePurchase from "./onlinePurchase";
import { useAlert } from "react-alert";
const Auth = new AuthHelperMethods();

export const AddPaymentMethod = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const alert = useAlert();

  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [show, setShow] = useState(true);
  const [errormsg, setErrormsg] = useState("");
  const [planDesc, setPlanDesc] = useState("");

  const elements = useElements();
  let history = useHistory();

  const stripe = useStripe();

  const handleSubmit = async (ev) => {
    console.log("handle.submint");
    ev.preventDefault();
    dispatch({ type: "FETCH_INIT" });

    if (!stripe || !elements) {
      console.log("not stripe nor elelments");
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      console.log(`[PaymentMethod ]`, paymentMethod);
      console.log(paymentMethod.id);
      let paymentMethodId = paymentMethod.id;
      let customerId = mystate.farmDefaults.eqo_stripe_customer_id;
      let priceId = mystate.farmDefaults.eqo_stripe_plan_id;
      // let priceId = "plan_GgvBNro05hWfLx"; // hardcoded to US 50 monthly
      // let priceId = process.env.NODE_ENV == "production" ? "plan_GgvBNro05hWfLx" : "price_1HdGMaGkn7ns6WE9g3I8UCnw";
      console.log(priceId);

      createSubcription({ customerId, paymentMethodId, priceId });
    } catch {
      console.log("[createPaymentMethod error]");

      console.log("[createPaymentMethod error]", error);
      dispatch({ type: "FETCH_ERROR" });
      alert.show(error, { timeout: 5000, type: "error" });
    }
  };

  const finishPay = () => {
    // dispatch2(["CLEAR_CART"]);
    history.push(`/admin/setup/preferences`);
  };

  const renderForm = () => {
    const options = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    };

    return (
      <>
        <Modal show={show} centered backdrop="static">
          {!succeeded && (
            <form
              onSubmit={handleSubmit}
              className="payment"
              style={{
                maxWidth: 500,

                display: "flex",
                flex: "1 1 100%",
                justifyContent: "center",
                flexDirection: "column",
                margin: 50,
              }}
            >
              <div className="sr-combo-inputs" style={{ border: "solid 1 grey", width: "100%" }}>
                <div>{``}</div>
                <div>{errormsg}</div>
                <div className="sr-combo-inputs-row">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    autoComplete="cardholder"
                    className="payment"
                  />
                </div>

                <div className="sr-combo-inputs-row">
                  <CardElement className="payment sr-input sr-card-element" options={options} />
                </div>
              </div>

              {error && <div className="message sr-field-error">{error}</div>}

              <button style={{ width: "100%", background: COLORS.orange }} className="payment">
                {processing ? "Processing…" : `Add`}
              </button>
              <button
                style={{ width: "100%", background: COLORS.blue }}
                className="payment"
                onClick={() => history.goBack()}
              >
                cancel
              </button>
            </form>
          )}
          {succeeded && (
            <div
              className="sr-field-success message"
              style={{
                margin: 50,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                maxWidth: 400,
              }}
            >
              <PrimaryTextBlue>Your payment method update was successful. </PrimaryTextBlue>

              <BlueButtonReg
                style={{
                  marginTop: 40,
                }}
                onClick={() => finishPay()}
              >
                close
              </BlueButtonReg>
            </div>
          )}
        </Modal>
      </>
    );
  };

  return (
    <>
      <div className="checkout-form">
        <div className="sr-payment-form">
          <div className="sr-form-row" />
          {renderForm()}
        </div>
      </div>
    </>
  );
};

export default AddPaymentMethod;
