import React, { useReducer, Fragment, useState, useEffect, useContext } from "react";
import Select from "react-select";
import { MyContext, ListContext } from "./App";
import ReactTooltip from "react-tooltip";
import { ActiveCheck } from "./formComponents";
import { assignStyles, addModaStyles } from "./styleCustom";
import AuthHelperMethods from "./AuthHelperMethods";

const Auth = new AuthHelperMethods();

const SelectStudent = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);

  const [active, setActive] = useState(true);
  const [options, setOptions] = useState(mystate.sections_student);
  const [selectvalue, setselectvalue] = useState([]);
  const [optionArray, setOptionArray] = useState([]);
  const [currentValue, setCurrentValue] = useState([]);
  const isStudent = Auth.getUserType() == "STU";

  let commonParams = {
    lesson_date_time_id: mystate.currentLesson.id,
    lesson_date_time_series_id: mystate.currentLesson.lesson_date_time_series_id,
    applyToFuture: mystate.applyToFuture,
    scheduled_date: mystate.currentLesson.scheduled_date,
  };

  useEffect(
    () => {
      console.log(Auth.getUserType());
      console.log(mystate.members);
      if (Auth.getUserType() == "STU") {
        console.log("STU");
        //  let stuOptions = mystate.members.map((member) => ({ value: member.student_id, label: member.person_name }));
        // setOptions(stuOptions);
        //setCurrentValue(options_student[0]);
        /* dispatch({
          type: "SET_NEW_EVENT",
          data: { students: { value: listState.currentClient.student_id, label: listState.currentClient.person_name } },
        });*/
      }
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        params.section_id = mystate.currentLesson.section_id || mystate.addevent.section_id;

        params.is_active = 1;

        const result = await Auth.api("section_students/index", "get", params);
        if (mystate.options_student)
          dispatch({
            type: "GET_STUDENTS_BY_SECTION",
            section_students_array: result.data.sectionStudentsArray,
            section_students: result.data.sectionStudents,
          });
      };
      console.log("selectStudent useEffect ");
      if (mystate.currentLesson.section_id || mystate.addevent.section_id) fetchData();
    },
    //eslint-disable-next-line
    [mystate.currentLesson.section_id, mystate.addevent.section_id]
  );

  const handlestudentChangeAdd = (selected) => {
    console.log("handle student change add");
    if (selected) {
      setCurrentValue(selected);
      //  console.log(selected);
      //console.log(selectvalue);

      // setselectvalue(selected.concat(selectvalue));
      setselectvalue(selected);
      // console.log(selectvalue);

      dispatch({
        type: "SET_NEW_EVENT",
        data: { students: selected },
      });
    } else console.log("Seleted is null");
  };

  const handlestudentChangeEdit = async (selected1, { action, removedValue }) => {
    if (action == "remove-value") {
      let studentObj = { ...removedValue, student_id: removedValue.value };

      dispatch({
        type: "REMOVE_STUDENT_FROM_LESSON",
        studentObj: studentObj,
      });

      commonParams = {
        ...commonParams,
        student_id: removedValue.value,
        //lesson_person_id: studentObjIn.id,
      };
      const result = await Auth.api("lesson_people/delete", "post", commonParams);
      dispatch({
        type: "REMOVE_STUDENT_FROM_LESSON_PEOPLE",
        lessonPersonIds: result.data.lessonPersonIds,
      });
      console.log(removedValue);
    } else {
      let selected = selected1.pop();
      console.log(selected);

      setselectvalue(selectvalue.concat(selected1));
      let params = {
        lesson_date_time_id: mystate.currentLesson.id,
        lesson_date_time_series_id: mystate.currentLesson.lesson_date_time_series_id || "",
        applyToFuture: mystate.applyToFuture,
        scheduled_date: mystate.currentLesson.scheduled_date,
        student_id: selected.value,
        name: selected.label,
        assigned_to: "",
        horse_id: 0,
        enrollment_status_code: "SCH",
        enrollment_status_name: "scheduled",
      };
      let matchedup = { name: selected.label, student_id: selected.value };

      dispatch({
        type: "ASSIGN_STUDENT_TO_LESSON",
        data: matchedup,
      });

      const result = await Auth.api("lesson_people/create", "post", params);
      dispatch({
        type: "ASSIGN_STUDENT_TO_LESSON_WITH_ID",
        data: result.data.lessonpeopletoreturn,
        lessonIds: result.data.lessonIds,
      });

      dispatch({
        type: "SET_ALERT_MESSAGE",
        name: params.name,
      });
    }
  };

  function onClickActive() {
    setActive(!active);
  }

  return (
    <>
      <div className="selectStudent">
        {mystate.options_student && !isStudent && (
          <Select
            isMulti={true}
            closeMenuOnSelect={false}
            options={mystate.sections_student}
            value={
              props.addOrEdit == "edit"
                ? mystate.currentLesson.lessonStudents.map((item) => ({
                    value: item.student_id,
                    label: item.name,
                    id: item.id,
                  }))
                : selectvalue
            }
            onChange={props.addOrEdit == "edit" ? handlestudentChangeEdit : handlestudentChangeAdd}
            onBlur={props.onBlur}
            menuIsOpen={props.menuIsOpen}
            controlShouldRenderValue={true}
            isClearable={false}
            placeholder={"Select Clients"}
            className={"studentContainer"}
            classNamePrefix={"selectStudent"}
          />
        )}

        {options && isStudent && (
          <>
            <Select
              autoFocus
              menuIsOpen
              isSearchable
              isClearable={false}
              options={options}
              isMulti
              label={"student"}
              placeholder={"search"}
              styles={addModaStyles}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              controlShouldRenderValue={false}
              onChange={handlestudentChangeAdd}
              value={currentValue}
            />
          </>
        )}

        <ReactTooltip place="right" type="dark" effect="solid" />
      </div>
    </>
  );
  //  }
};

export default SelectStudent;
