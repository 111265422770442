import React, { useReducer, Fragment, useState, useEffect } from "react";
import moment from "moment";
//import { momentObj } from "react-moment-proptypes";
import merge from "lodash.merge";
import { getdateinfo, formatFromDB } from "./momentUtils";
import Settings from "./UserSettings";
import {
  calculateEndTime,
  combineDateAndTimeStrToDateObj,
  datetimeISOtoDateObj,
  createNameString,
  createListString,
  createNameArray,
  createNameStringHorseName,
  createAssignedHorseNameArray,
  createAssignedHorseObject,
  createUNNAssignedHorseObject,
  createAssignedHorseObjectWhole,
  dateObjToDisplayDate,
  dateObjToDisplayDatetime,
  combineDateAndTimeStrToMomentObj,
  timeISOtoString,
  dateISOtoStringUtc,
  datetimeISOtoStringOnAt,
} from "./momentUtils";
import { difference } from "lodash/difference";
import initialState from "./initialState";
import { getRepeatDesc, formatEventsFromDB } from "./calendarFunctions";
const horseobj = {};
const horseojb = {};
const lessonsFiltered = {};
const arrayOfLessonStudentsStudentId = [];
let uniqid = "";

function Intials(cString) {
  var i;
  var aInitials = new Array();
  var wordArray = cString.split(" ");
  for (i = 0; i < wordArray.length; i++) {
    aInitials[i] = wordArray[i].substring(0, 1).toUpperCase();
  } // end loop name words;
  return aInitials; // return initials;
} // end Initials function;

/*********START REDUCER HERE ***************/
const calendarReducer = (state, action) => {
  let currentLessonStudents = [];
  let currentLessonStudentsUpdated = [];
  let currentStudentUpdated = {};

  switch (action.type) {
    case "GET_ALL_EVENTS":
      console.log("GET_ALL_EVENTS");
      let studentStringobn;
      var resultparsed = formatFromDB(action.payload);
      console.log(resultparsed);
      console.log(resultparsed);

      const hevents = [];
      console.log();

      action.payload.LessonHorses.forEach((obj) =>
        hevents.push({
          ...obj,
          start: combineDateAndTimeStrToDateObj(obj.scheduled_date, obj.scheduled_starttime),
          end: combineDateAndTimeStrToDateObj(obj.scheduled_end_date, obj.scheduled_endtime),
          color: obj.color_code,
        })
      );
      console.log(hevents);

      return {
        ...state,
        lessons: resultparsed,
        lessonStudents: action.payload.LessonStudents,
        lessonHorses: hevents,
        isFetching: false,
        // filteredEvents: resultparsed,
      };
    case "DO_NOTHING":
      console.log(action);
      console.log("do nothing");
      return { ...state, donothing: "dpmptjomg" };

    case "SETA":
      return { ...state, b: action.x || [] };

    case "GET_CALENDAR_CLIENT_EVENTS":
      console.log(action);

      let events = action.payload.lessons.map((obj) => ({
        ...obj,
        start: combineDateAndTimeStrToDateObj(obj.scheduled_date, obj.scheduled_starttime),
        end: combineDateAndTimeStrToDateObj(obj.scheduled_end_date, obj.scheduled_endtime),
        time: timeISOtoString(obj.scheduled_starttime),
        endtime: timeISOtoString(obj.scheduled_endtime),
        is_allday: obj.is_allday,
        allDay: obj.is_allday,
      }));

      return {
        ...state,
        lessons: events,
      };

    case "FILTER_EVENTS":
      let filtered = action.lessons;
      console.log(action);

      console.log(filtered);

      filtered =
        action.data.instructor && action.data.instructor.length > 0
          ? action.lessons.filter((lesson) => action.data.instructor.includes(lesson.instructor_id))
          : action.lessons;
      // console.log(filteredins);

      filtered =
        action.data.program && action.data.program.length > 0
          ? filtered.filter((lesson) => action.data.program.includes(lesson.program_id))
          : filtered;

      filtered =
        action.data.program_type && action.data.program_type.length > 0
          ? filtered.filter((lesson) => action.data.program_type.includes(lesson.program_type_id))
          : filtered;

      filtered =
        action.data.section && action.data.section.length > 0
          ? filtered.filter((lesson) => action.data.section.includes(lesson.section_id))
          : filtered;

      filtered =
        action.data.location && action.data.location.length > 0
          ? filtered.filter((lesson) => action.data.location.includes(lesson.location_id))
          : filtered;

      console.log(filtered);

      /* filtered = filtered.map((lesson) => ({
        ...lesson,
        title: lesson.title_with_time,
      }));
      */

      return {
        ...state,
        filteredEvents: filtered,
      };

    case "GET_DAYSHEET_EVENTS":
      console.log("GET_ALL_EVENTS");
      console.log(action);

      let formattedLessons = action.payload.lessons.map((obj) => ({
        ...obj,
        color: obj.color_code,
        title: obj.title || obj.name,
        assignedHorses:
          action.payload.LessonStudents.filter((students) => students.lesson_date_time_id == obj.id)
            .filter((student) => student.horse_id !== null)
            .map((horse) => horse.horse_id) || [],
      }));

      return {
        ...state,
        daysheet: {
          lessons: formattedLessons,
          lessonStudents: action.payload.LessonStudents,
          lessonHorses: action.payload.LessonHorses,
          tasks: action.payload.tasks,
        },

        isFetching: false,
      };

    case "REFRESH_WHITEBOARD_LESSON":
      var resultparsed = formatFromDB(action.payload);

      return state;

    case "GET_WHITEBOARD_HORSECOUNT":
      console.log(action);
      console.log(action.payload);

      return {
        ...state,
        whiteboard_horsecount: action.payload,
      };
    case "SET_SECTION_HORSE_WHITEBOARD":
      console.log(action);
      return {
        ...state,
        whiteboard_section_horses_array: action.payload,
      };

    case "GET_FILTERED_WHITEBOARD_HORSE_COUNT":
      return {
        ...state,
        whiteboard_horses_filtered: state.whiteboard.horsecount.filter((horse) =>
          action.filterarray.includes(horse.id)
        ),
      };

    case "REMOVE_WHITEBOARD_HORSE":
      console.log(action.payload);
      /* state.daysheet.lessonHorses.forEach((e) =>
        e.forEach((f) => console.log(typeof f))
      );*/
      var newonehere = state.daysheet.lessonHorses.findIndex(
        (lessonhorse) =>
          lessonhorse.lesson_date_time_id === action.payload.lesson_date_time_id &&
          lessonhorse.horse_id === action.payload.horse_id
      );

      if (newonehere > -1) {
        state.daysheet.lessonHorses.splice(newonehere, 1);
      }

      // index = a.findIndex(x => x.prop2 ==="yutu");

      console.log(newonehere);

      let horsewithnewcount = state.whiteboard_horsecount.map((el) =>
        el.id === action.payload.horse_id ? { ...el, horsecount: el.horsecount - 1 } : el
      );

      return {
        ...state,
        daysheet: {
          ...state.daysheet,
          lessonHorses: state.daysheet.lessonHorses,
        },
        whiteboard_horsecount: horsewithnewcount,
      };

    case "ADD_WHITEBOARD_HORSE":
      console.log(action.payload);
      // console.log(state.daysheet.lessonHorses);
      let horsewithnewcountClick = state.whiteboard_horsecount.map((el) =>
        el.id === action.payload.horse_id ? { ...el, horsecount: el.horsecount + 1 } : el
      );
      //  console.log(horsewithnewcount);

      //  console.log(state.daysheet.lessonHorses.concat(action.payload.params));
      // console.log([...state.daysheet.lessonHorses, action.payload]);
      return {
        ...state,
        daysheet: {
          ...state.daysheet,
          lessonHorses: [...state.daysheet.lessonHorses, action.payload],
        },
        whiteboard_horsecount: horsewithnewcountClick,
      };

    case "UNASSIGN_WHITEBOARD_HORSE":
      console.log(action);
      /* let obj = state.daysheet.lessons.find((el) => el.id === action.lesson_id);
      console.log(obj);
      let array1 = obj.assignedHorses;
      console.log(array1);

      let filteredarray = array1.filter((horse) => horse !== action.horse_id);
      console.log(filteredarray);
      //  let inx = obj.assignedHorses.indexOf((horse) => horse == action.horse_id);
      //   console.log(inx);
*/
      return {
        ...state,
        daysheet: {
          ...state.daysheet,
          lessonStudents: state.daysheet.lessonStudents.map((el) =>
            el.id === action.lessonPersonId
              ? {
                  ...el,
                  horse_id: "",
                  assigned_to: "",
                }
              : el
          ),
          lessons: state.daysheet.lessons.map((el) =>
            el.id === action.lesson_id
              ? {
                  ...el,
                  assignedHorses: el.assignedHorses.filter((horse) => horse != parseInt(action.horse_id)),
                }
              : el
          ),
        },
      };

    case "ASSIGN_WHITEBOARD_HORSE":
      console.log(action);
      return {
        ...state,
        daysheet: {
          ...state.daysheet,
          lessonStudents: state.daysheet.lessonStudents.map((el) =>
            el.id == action.lessonPersonId
              ? {
                  ...el,
                  horse_id: action.horse_id,
                  assigned_to: action.horse_name,
                }
              : el
          ),
          lessons: state.daysheet.lessons.map((el) =>
            el.id === action.lesson_id
              ? {
                  ...el,
                  assignedHorses: el.assignedHorses.concat(parseInt(action.horse_id)),
                }
              : el
          ),
        },
      };

    case "GET_DAYSHEET_HORSE_NOTES":
      console.log(action.payload.horseNotes);
      return {
        ...state,
        horseNotes: action.payload.horseNotes,
      };

    case "GET_HORSE_TIMETABLE":
      let horsetime = action.payload.lessonHorsesWithTime.map((item) => ({
        id: item.id,
        group: item.horse_id,
        title: `${Intials(item.instructor_name)} ${item.name}`,
        color: item.color,
        itemProps: {
          style: { backgroundColor: item.color },
        },
        start_time: combineDateAndTimeStrToDateObj(item.scheduled_date, item.scheduled_starttime),
        end_time: combineDateAndTimeStrToDateObj(item.scheduled_end_date, item.scheduled_endtime),
      }));

      let lessonHorsesWithTime = action.payload.lessonHorsesWithTime.map((item) => ({
        title: `${Intials(item.instructor_name)} ${item.name}`,
        color: item.color,
        start: combineDateAndTimeStrToDateObj(item.scheduled_date, item.scheduled_starttime),
        end: combineDateAndTimeStrToDateObj(item.scheduled_end_date, item.scheduled_endtime),
        horse_id: item.horse_id,
        allDay: false,
      }));

      return {
        ...state,
        group:
          state.horses &&
          state.horses.map((item) => ({
            id: item.id,
            title: item.horse_name,
          })),
        item: horsetime,
        lessonHorsesWithTime: lessonHorsesWithTime,
      };

    case "SET_CURRENTLESSON_TO_NULL":
      return {
        ...state,
        currentLesson: {},
        addevent: {},
        currentLessonOld: {},
      };

    case "CLEAR_CALENDAR":
      return {
        ...state,
        lessons: [],
        lessonStudents: [],
        lessonHorses: [],
        filteredLessons: [],
      };

    case "ADD_NEW_LESSON":
      var resultparsed = formatFromDB(action.payload);
      console.log(action.payload);
      console.log(action.payload.LessonStudents);
      //console.log(action.payload.lessonStudents.length);
      var newarray = [];
      console.log(action.payload);
      console.log(newarray);
      console.log(newarray.length);

      return {
        ...state,
        lessons: [...resultparsed, ...state.lessons],
        lessonStudents: [...action.payload.LessonStudents, ...state.lessonStudents],
        lessonHorses: [...action.payload.LessonHorses, ...state.lessonHorses],
      };

    case "DELETE_AND_REFRESH":
      var filteredLessons, filteredHorses, filteredStudents, lessonIds;
      //remove from state first to replace with updated

      // var resultparsed = formatFromDB(action.payload);
      lessonIds = action.payload.lessonIds;
      console.log(action.payload.lessonIds);

      if (lessonIds && lessonIds.length > 0) {
        filteredLessons = state.lessons.filter((item) => !lessonIds.includes(item.id));
        console.log(filteredLessons);

        filteredHorses = state.lessonHorses.filter((item) => !lessonIds.includes(item.lesson_date_time_id));
        filteredStudents = state.lessonStudents.filter((item) => !lessonIds.includes(item.lesson_date_time_id));

        console.log(filteredLessons.concat(resultparsed));

        //then update with refreshed events
        var resultparsed = formatFromDB(action.payload);
        return {
          ...state,
          lessons: filteredLessons.concat(resultparsed),
          lessonStudents: filteredStudents.concat(action.payload.LessonStudents),
          lessonHorses: filteredHorses.concat(action.payload.LessonHorses),
        };
      } else return state;

    case "SET_LESSON":
      console.log(action.data);
      let currentLesson = action.data.lessons[0];
      if (currentLesson && currentLesson.is_recuring) {
        let desc = getRepeatDesc(currentLesson);
        console.log(desc);
        currentLesson = { ...currentLesson, repeatDesc: desc };
      }

      let cl = currentLesson;
      cl.lessonStudents = action.data.LessonStudents;
      cl.lessonHorses = action.data.LessonHorses;
      cl.start = combineDateAndTimeStrToDateObj(currentLesson.scheduled_date, currentLesson.scheduled_starttime);
      cl.end = combineDateAndTimeStrToDateObj(currentLesson.scheduled_end_date, currentLesson.scheduled_endtime);
      cl.repeat_end = dateISOtoStringUtc(currentLesson.repeatEnd);
      cl.lessonString = ` ${currentLesson.title} ${datetimeISOtoStringOnAt(
        currentLesson.scheduled_date,
        currentLesson.scheduled_starttime
      )}`;
      return {
        ...state,
        currentLesson: cl,
        currentLessonOld: cl,
        dateTimeComponent: {
          start: cl.start,
          end: cl.end,
          is_allday: cl.is_allday,
        },
        sendNotification: false,
        updated_lesson_message: "",
      };

    case "GET_EVENT_DETAIL":
      console.log("GET_EVENT_DETAIL");

      console.log(action.data);
      // for edit and view single lesson

      currentLesson = state.lessons.find((item) => item.id == action.data);
      console.log(currentLesson);
      console.log(state.lessons);
      //creating horse and student strings
      if (currentLesson) {
        if (currentLesson && currentLesson.is_recuring) {
          let desc = getRepeatDesc(currentLesson);

          console.log(desc);
          //  let descShort=`repeats ${currentLesson.period=="weeks"? "weekly" : "daily"}.`
          currentLesson = { ...currentLesson, repeatDesc: desc };
        }

        currentLesson.lessonStudents = state.lessonStudents.filter(
          (student) => student.lesson_date_time_id == action.data
        );

        currentLesson.studentString = createNameString(currentLesson.lessonStudents);

        currentLesson.lessonHorses = state.lessonHorses.filter((horse) => horse.lesson_date_time_id == action.data);
        currentLesson.horseString = createNameString(currentLesson.lessonHorses);
        currentLesson.showNotify = false;
        currentLesson.notifyMessage = "";
        currentLesson.lessonString = ` ${currentLesson.title} ${datetimeISOtoStringOnAt(
          currentLesson.scheduled_date,
          currentLesson.scheduled_starttime
        )}`;

        return {
          ...state,
          currentLesson: currentLesson,
          currentLessonOld: currentLesson,
          dateTimeComponent: {
            start: currentLesson.start,
            end: currentLesson.end,
            is_allday: currentLesson.is_allday,
          },
          editLessonDone: false,
          applyToFuture: false,
          repeat: {},
          sendNotification: false,
          updated_lesson_message: "",
        };
      } else {
        return state;
      }

    case "CLEAR_CURRENT_LESSON": {
      return { ...state, currentLesson: {}, currentLessonOld: {} };
    }

    //////////*** ADD MAIN *************///
    case "SET_ADD_DATE_TIME_COMPONENT":
      state.addevent = {
        ...state.addevent,
        start: state.dateTimeComponent.start,
        end: state.dateTimeComponent.end,
      };

      return {
        ...state,
        addevent: state.addevent,
      };
    case "ADD_AND_REMOVE_LESSON_STUDENTS":
      console.log(action);
      let diff = action.data.student;
      let listitem = { student_id: diff[0].value, name: diff[0].label };

      currentLessonStudents = {};
      if (action.data.addRemove == "add") {
        currentLessonStudents = state.currentLesson.lessonStudents.concat(listitem);
      } else {
        currentLessonStudents = state.currentLesson.lessonStudents.filter(
          (student) => student.student_id != listitem.student_id1
        );
      }
      let curlesson = {
        ...state.currentLesson,
        lessonStudents: currentLessonStudents,
      };

      return {
        ...state,
        currentLesson: curlesson,
      };
    case "SET_NEW_EVENT":
      console.log("SET_NEW_EVENT");
      console.log(action);

      Object.keys(action.data).forEach(function (key) {
        state = {
          ...state,
          addevent: { ...state.addevent, [key]: action.data[key] },
        };
      });

      return state;

    case "SET_REPEAT_VALUES":
      Object.keys(action.data).forEach(function (key) {
        state = {
          ...state,
          repeat: { ...state.repeat, [key]: action.data[key] },
        };
      });
      console.log(state.repeat);

      return state;

    // END OF ADD
    //*************  EDIT COMPONENTS ***********//
    // </LessonNotes>
    case "UPDATE_LESSON_MESSAGE":
      return {
        ...state,
        updated_lesson_message: action.message,
      };
    case "HANDLE_LESSON_NOTES_INPUT":
      console.log("HANDLE_LESSON_NOTES_INPUT");
      console.log(action.message);
      // <LessonNotes/>
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          lesson_notes: action.message,
        },
      };
    case "SAVE_LESSON_MESSAGE_EDIT":
      //   <ModalGeneral/>
      console.log("SAVE_LESSON_MESSAGE_EDIT");
      let updatedcurmsg =
        state.editAction == "editNotes" ? state.currentLesson.lesson_notes : state.updated_lesson_message;
      let prevNotes = "";
      if (state.editAction !== "editNotes") {
        prevNotes =
          state.currentLessonOld.lesson_notes && `      \n previous note: ${state.currentLessonOld.lesson_notes}`;
      }

      updatedcurmsg = prevNotes ? `${updatedcurmsg} ${prevNotes}` : updatedcurmsg;

      return {
        ...state,
        currentLesson: { ...state.currentLesson, lesson_notes: updatedcurmsg },
        currentLessonOld: {
          ...state.currentLessonOld,
          lesson_notes: updatedcurmsg,
        },
        editLessonDone: true,
      };

    // End    </LessonNotes>
    case "EDIT_LESSON_TITLE":
      //<TextInputTitle/>
      return {
        ...state,
        currentLesson: { ...state.currentLesson, title: action.title },
      };

    case "EDIT_CURRENT_INSTRUCTOR":
      //<SelectInstructor/>
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          instructor_id: action.data.value,
          instructor_name: action.data.label,
          color: action.data.color,
        },
      };

    case "EDIT_CURRENT_SECTION":
      // <SelectLocation/>
      console.log(action.data.label);
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          section_id: action.data.value,
          section_name: action.data.label,
        },
      };

    case "EDIT_CURRENT_LOCATION":
      // <SelectLocation/>
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          location_id: action.data.value,
          location_name: action.data.label,
        },
      };

    case "UPDATE_NOTIFICATION_MESSAGE":
      console.log("UPDATE_MESSAGE");
      console.log(action);

      return {
        ...state,
        updated_notification_message: action.message,
      };

    case "SEND_NOTIFICATION":
      console.log("SEND_NOTIFICATION");
      return {
        ...state,
        sendNotification: action.notify,
      };

    case "SET_DATE_TIME_COMPONENT":
      // <TimeAndDate/>
      console.log(action.is_allday);
      console.log(action.start);
      return {
        ...state,
        dateTimeComponent: {
          start: action.start || state.dateTimeComponent.start,
          end: action.end || state.dateTimeComponent.end,
          is_allday: action.is_allday,
        },
        addevent: {
          ...state.addevent,
          start: action.start || state.dateTimeComponent.start,
          end: action.end || state.dateTimeComponent.end,
          is_allday: action.is_allday,
        },
      };

    /*case "SET_DATE_TIME_COMPONENT_NEW":
      // <TimeAndDate/>
      console.log(action);
      console.log(action);
      return {
        ...state,
        dateTimeComponent: {
          startdate: action.startdate || state.dateTimeComponent.startdate,
          starttime: action.starttime || state.dateTimeComponent.starttime,
          endtime: action.endtime || state.dateTimeComponent.endtime,
          is_allday: action.is_allday,
        },
      };
*/
    case "SET_DATE_COMPONENT":
      return { ...state, dateOnly: action.dateOnly };
    //*************** BULK ACTIONS*****************/
    case "SET_BULK_IDS":
      if (state.bulkarray.includes(action.id))
        return { ...state, bulkarray: state.bulkarray.filter((id) => id != action.id) };
      else return { ...state, bulkarray: [...state.bulkarray, action.id] };

    case "SET_BULK_FLAG":
      return { ...state, isBulk: action.isBulk };

    case "SET_ALL_BULK_IDS":
      return { ...state, bulkarray: action.id };

    case "SET_EDIT_ACTION":
      return { ...state, editAction: action.edit_action };

    case "SET_BULK_CANCEL_ACTION":
      return {
        ...state,
        bulk_cancel_action: action.bulk_cancel_action,
        //  editAction: action.bulk_cancel_action,
      };

    case "SET_BULK_CANCEL_REASON":
      return {
        ...state,
        bulk_cancel_reason: action.bulk_cancel_reason,
      };

    case "SET_BULK_CANCEL_EXPIRY":
      return {
        ...state,
        bulk_cancel_expiry: action.bulk_cancel_expiry,
      };

    case "SET_BULK_NOTIFICATION_MESSAGE":
      return {
        ...state,
        bulk_notification_message: action.bulk_notification_message,
      };

    //******************* EDIT  EVENT  *****************//

    case "SET_CANCEL_MAKEUP_ACTION":
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          barn_cancel_makeup_action: action.makeup_action,
        },
      };

    case "SET_CANCEL_REASON":
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          barn_cancel_reason: action.cancel_reason,
        },
      };

    case "UPDATE_AFTER_SAVING_STUDENT_NOTE": //from edit student lesson
      // could have called GET_STUDENT_LESSON_NOTES_FOR_CURRENT_LESSON and then EDIT_STUDENT but can't be sure 1st will be completed before 2nd?
      //replace all studentLessonNotes for current Lesson
      console.log(action);
      let newlessonstudentnotes = action.studentNotes;
      let currentstudentnotes = newlessonstudentnotes.filter(
        (note) => note.student_id === state.currentStudent.student_id
      );
      console.log(currentstudentnotes);
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          studentLessonNotes: newlessonstudentnotes,
        },
        currentStudent: {
          ...state.currentStudent,
          studentLessonNotes: currentstudentnotes,
        },
      };

    case "UPDATE_AFTER_SAVING_HORSE_NOTE": //from edit horse lesson
      // could have called GET_STUDENT_LESSON_NOTES_FOR_CURRENT_LESSON and then EDIT_STUDENT but can't be sure 1st will be completed before 2nd?
      //replace all studentLessonNotes for current Lesson
      let newlessonhorsenotes = action.horseNotes;
      let currenthorsenotes = newlessonhorsenotes.filter((note) => note.horse_id === state.currentHorse.horse_id);
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          horseLessonNotes: newlessonhorsenotes,
        },
        currentHorse: {
          ...state.currentHorse,
          horseLessonNotes: currenthorsenotes,
        },
      };
    case "GET_STUDENT_LESSON_NOTES_FOR_CURRENT_LESSON":
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          studentLessonNotes: action.studentNotes,
        },
      };

    case "GET_HORSE_LESSON_NOTES_FOR_CURRENT_LESSON":
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          horseLessonNotes: action.horseNotes,
        },
      };

    case "MARK_LESSON_COMPLETED":
      console.log(action);
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          lesson_status_code: action.code,
          notifyMessage: "test",
          lessonStudents: state.currentLesson.lessonStudents.map((el) =>
            el.enrollment_status_code == "SCH" || !el.enrollment_status_code
              ? {
                  ...el,
                  enrollment_status_code: "COM",
                  enrollment_status_name: "completed",
                }
              : el
          ),
        },
      };
    case "MARK_ALL_STUDENTS_PAID":
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          lessonStudents: state.currentLesson.lessonStudents.map((el) => (el = { ...el, paid: action.paid })),
        },
      };
    case "ASSIGN_HORSE_TO_LESSON":
      console.log(action.data);

      //   </SelectHorseList>

      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          lessonHorses: state.currentLesson.lessonHorses.concat(action.data),
        },
        lessonHorses: state.lessonHorses.concat(action.data),
      };

    case "UPDATE_ASSIGN_HORSE_TO_LESSON":
      console.log(action);

      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          lessonHorses: state.currentLesson.lessonHorses.map((lessonHorse) =>
            lessonHorse.tempid == action.tempid ? { ...lessonHorse, id: action.data } : lessonHorse
          ),
        },
      };

    case "ASSIGN_STUDENT_TO_LESSON":
      //****    <SelectStudentList/>    ***//
      console.log("SelectStudentList");
      console.log(action.data);
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          lessonStudents: state.currentLesson.lessonStudents.concat(action.data),
        },
        /*
          lessonStudents: state.lessonStudents.concat(action.studentObj)*/
      };

    case "ASSIGN_STUDENT_TO_LESSON_WITH_ID":
      //**** from   <SelectStudent/>    ***//
      console.log("SelectStudentList2");
      console.log(action.data[0]);
      let data = action.data[0];
      //******* data[0] to take earliest lesson which is current lesson when multiple are returned */

      let findStudentInCurrentLessonWithoutLPid = state.currentLesson.lessonStudents.map((item) =>
        item.student_id == data.student_id ? data : item
      );

      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          lessonStudents: findStudentInCurrentLessonWithoutLPid,
        },

        lessonStudents: state.lessonStudents.concat(data),
      };

    case "UPDATE_ASSIGN_STUDENT_WITH_ID":
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          lessonStudents: state.currentLesson.lessonStudents.map((student) =>
            student.student_id == action.data.student_id &&
            student.lesson_date_time_id == action.data.lesson_date_time_id
              ? action.data
              : student
          ),
        },
      };

    case "REMOVE_STUDENT_FROM_LESSON":
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          lessonStudents: state.currentLesson.lessonStudents.filter(
            (item) => item.student_id !== action.studentObj.student_id
          ),
        },
        /*  lessonStudents: state.lessonStudents.filter(
          item => item.student_id !== action.studentObj.student_id
        )*/
      };

    case "REMOVE_STUDENT_FROM_LESSON_PEOPLE":
      console.log(action);
      return {
        ...state,
        lessonStudents: state.lessonStudents.filter(
          (lessonPerson) => !action.lessonPersonIds.includes(lessonPerson.id)
        ),
      };

    case "REMOVE_HORSE_FROM_LESSON":
      //and unassign from student if that horse is assigned
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          lessonHorses: state.currentLesson.lessonHorses.filter((item) => item.horse_id !== action.horseObj.horse_id),
          lessonStudents: state.currentLesson.lessonStudents.map((el) =>
            el.horse_id === action.horseObj.horse_id ? { ...el, horse_id: 0, assigned_to: "" } : el
          ),
        },
      };

    //****************End EDIT  EVENT************************//
    //****************Edit lesson People and Horses***********************//
    case "EDIT_STUDENT":
      console.log(action.data);

      let curstudentnotes = [];
      if (state.currentLesson.studentLessonNotes) {
        console.log(state.currentLesson.studentLessonNotes);
        curstudentnotes = state.currentLesson.studentLessonNotes.filter(
          (note) => note.student_id === action.data.student_id
        );
      }
      console.log(curstudentnotes);
      let currentStudentobj = {
        ...action.data,
        studentLessonNotes: curstudentnotes || "",
      };
      //  return state;
      return {
        ...state,
        currentStudent: currentStudentobj,
        currentStudentOld: currentStudentobj,
        currentEditIsStudentNotHorse: true,
      };

    case "EDIT_HORSE":
      console.log(action.data);
      //    console.log(action.index);

      let curnotes = "";
      if (state.currentLesson.horseLessonNotes) {
        curnotes = state.currentLesson.horseLessonNotes.filter((note) => note.horse_id === action.data.horse_id);
      }
      let currentHorseobj = {
        ...action.data,
        horseLessonNotes: curnotes,
      };
      console.log(currentHorseobj);
      return {
        ...state,
        currentHorse: currentHorseobj,

        currentEditIsStudentNotHorse: false,
        currentStudent: {},
      };
    //duplicate?
    case "MARK_STUDENT_PAID":
      let paid = action.paid;
      return {
        ...state,
        currentStudent: { ...state.currentStudent, paid: paid },
        currentLesson: {
          ...state.currentLesson,
          lessonStudents: state.currentLesson.lessonStudents.map((el) =>
            el.student_id == state.currentStudent.student_id ? { ...el, paid: paid } : el
          ),
        },
      };

    case "UPDATE_QUICKPAY":
      console.log("UPDATE_QUICKPAY");

      console.log(action);

      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          lessonStudents: state.currentLesson.lessonStudents.map((el) =>
            el.student_id == action.student_id ? { ...el, item_id: action.item_id } : el
          ),
        },
      };

    case "ASSIGN_HORSE_TO_STUDENT":
      let updatedcurstudent = {
        ...state.currentStudent,
        horse_id: action.horseId,
        assigned_to: action.horseName,
      };
      return {
        ...state,
        currentStudent: updatedcurstudent,
        currentLesson: {
          ...state.currentLesson,
          lessonStudents: state.currentLesson.lessonStudents.map((el) =>
            el.student_id == action.studentId ? updatedcurstudent : el
          ),
        },
      };

    case "ASSIGN_TACK_TO_HORSE":
      let updatedlessonhorse = {
        ...state.currentHorse,
        tack_id: action.tackId,
        tack_name: action.tackName,
      };
      console.log(state.currentLesson.lessonHorses);
      return {
        ...state,
        currentHorse: updatedlessonhorse,
        currentLesson: {
          ...state.currentLesson,
          lessonHorses: state.currentLesson.lessonHorses.map((el) =>
            el.id == action.lessondatetimehorseid ? updatedlessonhorse : el
          ),
        },
      };

    case "SET_STUDENT_ATTENDANCE":
      console.log(action);
      let curstudentid = state.currentStudent.student_id;
      console.log(curstudentid);

      let updatedcurstudentA = {
        ...state.currentStudent,
        enrollment_status_code: action.data.value,
        enrollment_status_name: action.data.label,
        student_is_cancelled: true,
      };

      return {
        ...state,
        currentStudent: updatedcurstudentA,
        currentLesson: {
          ...state.currentLesson,
          lessonStudents: state.currentLesson.lessonStudents.map((el) =>
            el.student_id == curstudentid ? updatedcurstudentA : el
          ),
        },
      };

    /*  case "HANDLE_NOTE_INPUT":
      return {
        ...state,
        currentStudent: {
          ...state.currentStudent,
          client_notes: action.message
        },
        currentHorse: {
          ...state.currentHorse,
          horse_notes: action.message
        }
      };*/

    /*  case "ADD_STUDENT_NOTE_CURRENT_LESSON_STATE":
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          studentLessonNotes: state.currentLesson.studentLessonNotes.concat(
            action.studentNotes
          )
        },
        currentStudent: {
          ...state.currentStudent,
          studentLessonNotes: state.currentStudent.studentLessonNotes.concat(
            action.studentNotes
          ),
          client_notes: ""
        }
      };
*/
    case "ADD_HORSE_NOTE_CURRENT_LESSON_STATE":
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          horseLessonNotes: state.currentLesson.horseLessonNotes.concat(action.horseNotes),
        },
        currentHorse: {
          ...state.currentHorse,
          horseLessonNotes: state.currentHorse.horseLessonNotes.concat(action.horseNotes),
          horse_notes: "",
        },
      };

    case "DELETE_HORSE_LESSON_NOTE":
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          horseLessonNotes: state.currentLesson.horseLessonNotes.filter((item) => item.id != action.id),
        },
        currentHorse: {
          ...state.currentHorse,
          horseLessonNotes: state.currentHorse.horseLessonNotes.filter((item) => item.id != action.id),
        },
      };

    case "DELETE_STUDENT_LESSON_NOTE":
      return {
        ...state,
        currentLesson: {
          ...state.currentLesson,
          studentLessonNotes: state.currentLesson.studentLessonNotes.filter((item) => item.id != action.id),
        },
        currentStudent: {
          ...state.currentStudent,
          studentLessonNotes: state.currentStudent.studentLessonNotes.filter((item) => item.id != action.id),
        },
      };

    case "SHOW_STUDENT_LIST":
      return { ...state, showStudentList: action.showStudentList };

    case "SHOW_HORSE_LIST":
      return { ...state, showHorseList: action.showHorseList };

    //****************End Edit lesson People and Horses***********************//

    case "FILTER_RESOURCE_DATA":
      console.log(action);
      return {
        ...state,
        resource_map: action.data,
      };

    case "SET_RESOURCE_MAP":
      console.log(action);

      //  let rm=mystate.lessons.filter(lesson => lesson.)
      return {
        ...state,
        resource_map: action.data,
      };

    case "SET_RESOURCE_ACCESSOR":
      console.log(action);

      return {
        ...state,

        resourceAccessor: action.resourceAccessor || "",
      };

    case "SET_RESOURCE_ID":
      return {
        ...state,
        resourceIdAccessor: action.id,
      };

    case "SET_RESOURCE":
      return {
        ...state,
        resource: action.data,
      };

    case "GET_SECTION_RESOURCES":
      var sobj = action.payload;
      const secarray = [];
      sobj.map((thisobj) => {
        const tmp = {
          section_id: thisobj.id,
          section_name: thisobj.section_name,
        };
        secarray.push(tmp);
      });
      return {
        ...state,
        resourceMapSections: secarray,
      };

    //***************** DROPDOWN OPTIONS *****************///
    case "GET_SECTIONS":
      return {
        ...state,
        sections: action.payload,
        options_section: action.options,
      };

    case "GET_INSTRUCTORS":
      console.log("GET_INSTRUCTORS");
      return {
        ...state,
        instructors: action.payload, //?dont think this is used
        options_instructor: action.options,
      };

    case "ADD_NEW_CLIENT_TO_OPTIONS_STUDENT":
      return {
        ...state,
        options_student: [action.data, ...state.options_student],
      };

    case "GET_INSTRUCTOR_OPTIONS":
      console.log("GET_INSTRUCTOR_OPTIONS");
      return {
        ...state,
        options_instructor: action.options,
      };

    case "GET_STUDENTS":
      return {
        ...state,
        students: action.payload, //?not uesed
        options_student: action.options,
      };

    case "GET_HORSES":
      return {
        ...state,
        horses: action.payload,
        options_horse: action.options,
      };

    case "GET_STUDENTS_BY_SECTION":
      console.log(action);
      //console.log(state.options_horse);

      let first2 = action.section_students.map((item) => ({
        value: item.student_id,
        label: item.person_name,
      }));

      let second2 = state.options_student.filter((student) => !action.section_students_array.includes(student.value));

      let groupedOptions2 = [
        {
          label: "Section Students",
          options: first2,
        },
        {
          label: "Other Students",
          options: second2,
        },
      ];
      //  console.log(groupedOptions[0].options);
      return {
        ...state,

        sections_student: groupedOptions2,
      };

    case "GET_HORSES_BY_SECTION":
      console.log(action);
      console.log(state.options_horse);

      let first = action.section_horses.map((item) => ({
        value: item.horse_id,
        label: item.horse_name,
      }));

      let second = state.options_horse.filter((horse) => !action.section_horses_array.includes(horse.value));

      let groupedOptions = [
        {
          label: "Section Horses",
          options: first,
        },
        {
          label: "Other Horses",
          options: second,
        },
      ];
      //  console.log(groupedOptions[0].options);
      return {
        ...state,

        sections_horse: groupedOptions,
      };

    case "CLEAR_SECTIONS_HORSE":
      return {
        ...state,
        sections_horse: [],
      };

    case "SET_LOCATIONS":
      return {
        ...state,
        locations: action.payload,
        options_location: action.options,
      };

    case "GET_PROGRAM_TYPES":
      return {
        ...state,
        programTypes: action.payload,
        options_program_type: action.options,
      };

    case "GET_PROGRAMS":
      return {
        ...state,
        programs: action.payload,
        options_program: action.options,
      };

    case "GET_PRODUCTS":
      return {
        ...state,
        products: action.payload,
        options_product: action.options,
      };

    case "GET_ENROLLMENT":
      return {
        ...state,
        enrollment: action.payload,
        options_enrollment: action.options,
      };

    case "SET_INITAL_SECTION_DEFAULTS":
      let sectionDefaultobj = {
        section_id: action.data.id,
        instructor_id: action.data.instructor_id,
        location_id: action.data.location_id,
        duration: action.data.duration,
        title: action.data.section_name,
      };

      return {
        ...state,
        sectionDefaults: sectionDefaultobj,
      };

    case "SET_SECTION_DEFAULTS":
      console.log("SET_SECTION_DEFAULTS");
      console.log(action);
      //  const section_id = state.addevent.section_id;

      const section_id = action.id;
      const starttime = state.dateTimeComponent.start;
      // const newSection = state.sections.find((section) => section.id === section_id);
      const newSection = action.section;
      console.log("newSection");

      console.log(newSection);

      const duration = newSection.duration || 60;
      let endtime = calculateEndTime(starttime, duration);

      return {
        ...state,
        addevent: {
          ...state.addevent,
          section_id: section_id,
          instructor_id:
            state.resourceIdAccessor && state.resourceAccessor == "instructor_id"
              ? state.resourceIdAccessor
              : newSection.instructor_id,
          // location: newSection.location_id, //May 26 -error- should have been location_id
          location_id:
            state.resourceIdAccessor && state.resourceAccessor == "location_id"
              ? state.resourceIdAccessor
              : newSection.location_id,
          //newSection.location_id,

          duration: newSection.duration,
          title: newSection.section_name,
          end: endtime,
        },
        dateTimeComponent: {
          ...state.dateTimeComponent,
          end: endtime,
        },
      };

    //***************** END DROPDOWN OPTIONS *****************///

    case "CLEAR_FILTER":
      return { ...state, filter: initialState.filter };

    case "SET_FILTER":
      console.log("SET_FILTER");
      console.log(action);
      //  if (action.data) {
      Object.keys(action.data).forEach(function (key) {
        console.log(key + ": " + action.data[key]);
        console.log("key + :  + action.data[key]");

        state = {
          ...state,
          filter: { ...state.filter, [key]: action.data[key] },
        };
      });
      console.log(state.filter);
      console.log("state.filter");

      return state;

    case "SET_EVENT":
      return { ...state, event: action.event };

    case "INITIALIZE_NEW_EVENT":
      console.log("INITIALIZE_NEW_EVENT");
      ////debugger;
      state = {
        ...state,
        addevent: {},
      };

      //??? is this guaranteed to be set before Object.keys function?
      ////debugger;
      Object.keys(action.data).forEach(function (key) {
        state = {
          ...state,
          addevent: { ...state.addevent, [key]: action.data[key] },
        };
      });
      //    //debugger;
      return state;

    case "CLEAR":
      console.log("clear");
      return { ...state, addevent: {} };

    case "CLEAR_STATE":
      return { state: {} };

    case "RESET_STATE":
      return initialState;

    /*case "SET_DEFAULTS_LOADED":
      return { ...state, defaultsLoaded: action.data };*/

    case "SET_VIEW":
      console.log("DISPATCH SET_VIEW");
      console.log(action);
      return { ...state, view: action.data };

    case "SET_CALENDAR_DATE":
      return { ...state, calendarDate: action.data };

    case "SET_CALENDAR_DATE_RANGE":
      console.log("SET_CALENDAR_DATE_RANGE");
      console.log(action);

      return {
        ...state,
        calendarDateRangeStart: action.start,
        calendarDateRangeEnd: action.end,
      };

    case "SET_LOGGED_IN":
      console.log("SET_LOGGED_IN");
      return { ...state, loggedIn: action.data };

    // ************* PRODUCT  ********************//
    case "SET_PRODUCTS":
      return {
        ...state,
        products: action.payload,
      };

    case "CREATE_PRODUCTS":
      return {
        state,
        products: state.products.concat(action.payload.products),
      };

    // ************ OPTIONS ***************//

    case "UPDATE_OPTIONS":
      console.log("UPDATE_OPTIONS");

      return {
        ...state,
        options_horse: action.options.options_horse,
        options_location: action.options.options_location,
        options_program: action.options.options_program,
        options_program_type: action.options.options_program_type,
        options_student: action.options.options_student,
        options_section: action.options.options_section,
        options_tack: action.options.options_tack,
        options_instructor: action.options.options_instructor,
        options_product: action.options.options_product,
        options_task: action.options.options_task,
        options_role: action.options.options_role,
      };

    case "UPDATE_HORSE_OPTIONS":
      return {
        ...state,
        options_horse: action.options,
      };

    case "UPDATE_LOCATION_OPTIONS":
      return {
        ...state,
        options_location: action.options,
      };

    case "UPDATE_PROGRAM_OPTIONS":
      return {
        ...state,
        options_program: action.options,
      };

    case "UPDATE_SECTION_OPTIONS":
      return {
        ...state,
        options_section: action.options,
        sections: action.payload,
      };

    case "UPDATE_PRODUCT_OPTIONS":
      return {
        ...state,
        options_product: action.options,
      };

    case "UPDATE_STUDENT_OPTIONS":
      return {
        ...state,
        options_student: action.options,
      };

    case "UPDATE_TASK_OPTIONS":
      return {
        ...state,
        options_task: action.options,
      };

    //***************  SHOW/HIDE  ****************/

    case "SET_SHOW_BY_STUDENT_NAME":
      console.log(state.farmDefaults.show_lesson_by_client_name);
      console.log(action.data);
      return { ...state, showByStudentName: action.data };

    case "SHOW_DATE_TIME_MODAL":
      return {
        ...state,
        showDateTimeModal: true,
        modalTitle: action.title,
        editAction: action.editAction,
      };
    case "HIDE_DATE_TIME_MODAL":
      return { ...state, showDefaultModal: false, editMessage: "" };
    case "SHOW_DEFAULT_MODAL":
      //    console.log(action.showRecurringChoice);
      return {
        ...state,
        showDefaultModal: true,
        modalTitle: action.title,
        singleEventEdit: true,
        editAction: action.editAction,
        showEditMessage: false,
        editMessage: action.editMessage,
        showNotify: action.showNotify,
        showRecurringChoice: action.showRecurringChoice,
        refresh: false,
        sendNotification: false,
      };
    case "SET_SHOW_NOTIFY":
      return {
        ...state,
        showNotify: action.showNotify,
      };
    case "SET_APPLY_TO_FUTURE":
      return { ...state, applyToFuture: action.applyToFuture };

    case "HIDE_DEFAULT_MODAL":
      return {
        ...state,
        showDefaultModal: false,
        modalTitle: "",
        editMessage: "",
      };
    /*  case "SHOW_INFO_MODAL":
      return { ...state, showInfoModal: true };

    case "HIDE_INFO_MODAL":
      return { ...state, showInfoModal: false };*/
    case "SHOW_INFO_MODAL2":
      console.log("SHOW_INFO_MODAL2 dispatched");
      return { ...state, showInfoModal2: true, infoModal2what: action.what };

    case "HIDE_INFO_MODAL2":
      return {
        ...state,
        showInfoModal2: false,
        currentHorse: {},
        currentStudent: {},
        currentStudentOld: {},
      };

    case "SHOW_TIMELINE":
      return {
        ...state,
        showTimeLine: true,
        showCalendar: false,
      };

    case "SHOW_CALENDAR":
      console.log(action);
      return {
        ...state,
        showCalendar: true,
      };

    case "CLIENT_GET_ACCOUNT":
      return {
        ...state,
        members: action.data.members,
        accountHolder: action.data.accountHolder,
      };
    case "FETCH_INIT":
      console.log("FETCH_INIT dispatch");
      return { ...state, isFetching: true, isError: false, data: {} };
    case "FETCH_SUCCESS":
      console.log("FETCH_SUCCESS dispatch");
      return {
        ...state,
        isFetching: false,
        isError: false,
        //  data: action.payload,
      };
    case "FETCH_ERROR":
      console.log("FETCH_ERROR dispatch");
      return {
        ...state,
        isFetching: false,
        isError: true,
        errormsg: action.error,
      };

    case "SET_ALERT_MESSAGE":
      let currentMessage = action.name ? `${state.currentMessage || ""} ${action.name} ` : "";
      return { ...state, currentMessage: currentMessage };

    case "SHOW_ALERT_MESSAGE":
      console.log("SHOW_ALERT_MESSAGE");
      console.log(action);
      return {
        ...state,
        showAlert: action.showAlert,
        alertVariant: action.alertVariant,
        alertMessage: action.alertMessage,
        timeOut: action.alertTimeOut,
      };

    case "SET_FARM_DEFAULTS":
      console.log("setting farm defaults");
      /* let mintime =
        moment(action.data.default_starttime, "hh:mm ").toDate() ||
        moment("07:00", "hh:mm ").toDate();

      let maxtime =
        moment(action.data.default_endtime, "hh:mm ").toDate() ||
        moment("22:00", "hh:mm ").toDate();

      action.data.default_starttime = mintime;
      action.data.default_endtime = maxtime;
*/
      var defaultSection;
      if (state.options_section) {
        defaultSection = state.options_section.find((section) => section.value == action.data.default_section_id);
        //  defaultSection = defaultSection.label;

        // console.log(defaultSection);
      }
      console.log(action);

      const MINTIME = moment(action.data.min_time, "hh:mm ").toDate();
      const MAXTIME = moment(action.data.max_time, "hh:mm ").toDate();

      console.log(MINTIME);
      console.log(action.data.default_starttime);

      action.data.min_time = MINTIME;
      action.data.max_time = MAXTIME;
      action.data.default_section = defaultSection;
      action.data.lastCheck = moment().format("hh:mm:ss");
      action.data.lastCheckM = moment();

      /* moment(mystate.farmDefaults.default_starttid)
        .utc()
        .format("hh:mm A")*/
      return {
        ...state,
        farmDefaults: action.data,
        farmPermissions: action.farmPermissions,
        currentUser: action.currentUser,
      };

    case "UPDATE_FARM_DEFAULTS":
      console.log(action.data);
      action.data.min_time = moment(action.data.min_time, "hh:mm ").toDate();
      action.data.max_time = moment(action.data.max_time, "hh:mm ").toDate();
      return {
        ...state,
        farmDefaults: action.data,
      };

    case "UPDATE_FARM_DEFAULT_MIN_TIME":
      console.log(action);
      console.log(moment(action.data, "hh:mm ").toDate());
      return {
        ...state,
        farmDefaults: {
          ...state.farmDefaults,
          min_time: action.data,
        },
      };

    case "UPDATE_FARM_DEFAULT_MAX_TIME":
      console.log(action);

      return {
        ...state,
        farmDefaults: {
          ...state.farmDefaults,
          max_time: action.data,
        },
      };

    case "SET_DEFAULTS_LOADED":
      return { ...state, defaultsLoaded: true };
    case "CLEAR_DEFAULTS_LOADED":
      return { ...state, defaultsLoaded: false };

    case "SET_UPDATE_VERSION":
      return { ...state, versionUpdate: action.data };

    case "SHOW_LEFT_NAV":
      return { ...state, showLeftNav: action.data };

    default:
      throw new Error(`Unexpected action-could not find dispatch type ${action.type}`);
  }
};

export default calendarReducer;
