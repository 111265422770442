import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, Link, useParams, Switch } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import AuthHelperMethods from "./AuthHelperMethods";
import { ListContext } from "./App";
import { MyContext } from "./App";
import { PrimaryTextBlue, PageTitle, PrimaryText, PrimaryText18, BoxWithLabel } from "./styleCustom";
//import ReportTable, { WorkloadGraphic } from "./reportTable";
import { WorkloadGraphic } from "./reportTable";

import { SelectTemplateFilter, SelectNoLabel, CancelRow, Active } from "./searchFilterReports";
import { options_attendance, available_options } from "./Constants2";
import { Desc, Asc } from "./icons";
import COLORS from "./styleConstants";
import ToggleSwitch from "./ToggleSwitch";

import SearchFilterDates from "./searchFilterDates";
import moment from "moment";

import { currentMonthStart, currentMonthEnd } from "./momentUtils";
import { OpenClose, ReportChoice } from "./formComponents";
import useSWR, { mutate, trigger } from "swr";
import ReportTable from "./reportTableTestSWR";

const Auth = new AuthHelperMethods();

const cancel_by_barn_options = [
  // { value: "CBBM", label: "cancelled with makeup" },
  { value: "BCAP", label: "cancelled and add back to package" },
  { value: "BCWR", label: "cancelled and issue refund" },
  { value: "BCNA", label: "cancelled no action" },
  { value: "BCWA", label: "cancelled and issue single lesson package" },
];
const Reports = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [showFilter, setShowFilter] = useState(false);

  // const [textFilter, setTextFilter] = useState("");
  let params = useParams;

  /* useEffect(() => {
    for (const [key, value] of Object.entries(listState.filter_labels)) {
      setTextFilter(`${key}: ${value}`);
      console.log(`${key}: ${value}`);
    }
    console.log(textFilter);
    // setSortBy();
    // console.log(sortBy);
  }, [listState.filter_labels]);
*/

  useEffect(() => {
    dispatch2(["RESET_SEARCH_FILTER"]);
    //dispatch2(["SET_SEARCH_FILTER_DATES", currentMonthStart, "date1"]);
    //dispatch2(["SET_SEARCH_FILTER_DATES", currentMonthEnd, "date2"]);
  }, []);

  useEffect(
    () => {
      return () => {
        dispatch2(["RESET_SEARCH_FILTER"]);
      };
    },
    //eslint-disable-next-line
    []
  );

  return (
    <>
      <PageTitle>{"Reports"}</PageTitle>
      {1 == 2 && (
        <div
          className={"reportSelection"}
          style={{ display: "flex", flex: "1 1 100%", flexDirection: "row", flexWrap: "wrap" }}
        >
          <ReportChoice
            title={"Enrollment Reports"}
            detail={"View Students that have signed up or attended any lessons"}
            hint={"You can also edit the package details from this report"}
            url="purchased"
          />
          <ReportChoice
            title={"Horse Workload Reports"}
            detail={"View Horses that have completed or are scheduled in any lessons."}
            hint={"These students need to purchase a package before their next lesson."}
            url="next"
          />
          <ReportChoice
            title={"Staff Reports"}
            detail={"View completed and scheduled staff activity."}
            hint={"These students have used all the lessons in their package by this lesson date."}
            url="last"
          />
          <ReportChoice
            title={"Lesson Reports"}
            detail={"View scheduled and completed events."}
            hint={"These students have used all the lessons in their package by this lesson date."}
            url="last"
          />
          <ReportChoice title={"Email Reports"} detail={"View sent email."} hint={""} url="last" />
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flex: "1 1 100%",
          wrap: "nowrap",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "flexStart",
          }}
        >
          <PrimaryText18>Student</PrimaryText18>

          <Link to={`${props.match.url}/enrollment/program/`}>
            <PrimaryTextBlue>Program</PrimaryTextBlue>
          </Link>
          <Link to={`${props.match.url}/enrollment/section/`}>
            <PrimaryTextBlue>Section</PrimaryTextBlue>
          </Link>
          <Link to={`${props.match.url}/enrollment/programsection/`}>
            <PrimaryTextBlue>Program and Section</PrimaryTextBlue>
          </Link>
          <Link to={`${props.match.url}/enrollment/total/`}>
            <PrimaryTextBlue>Student Totals</PrimaryTextBlue>
          </Link>
          <Link to={`${props.match.url}/enrollment/detail/`}>
            <PrimaryTextBlue>Detail</PrimaryTextBlue>
          </Link>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "flexStart",
          }}
        >
          <PrimaryText18>Horse</PrimaryText18>

          <Link to={`${props.match.url}/workload/program/`}>
            <PrimaryTextBlue>Program</PrimaryTextBlue>
          </Link>
          <Link to={`${props.match.url}/workload/section/`}>
            <PrimaryTextBlue>Section</PrimaryTextBlue>
          </Link>
          <Link to={`${props.match.url}/workload/programsection/`}>
            <PrimaryTextBlue>Program and Section</PrimaryTextBlue>
          </Link>
          <Link to={`${props.match.url}/workload/detail/`}>
            <PrimaryTextBlue>Detail</PrimaryTextBlue>
          </Link>
          <Link to={`${props.match.url}/workload/total/`}>
            <PrimaryTextBlue>Horse Totals</PrimaryTextBlue>
          </Link>
          <Link to={`${props.match.url}/workloadgraphic/graphic`}>
            <PrimaryTextBlue>Weekly Graphic</PrimaryTextBlue>
          </Link>
          <Link to={`${props.match.url}/workloadgraphic/numbers`}>
            <PrimaryTextBlue>Weekly Numbers</PrimaryTextBlue>
          </Link>
        </div>

        {1 == 1 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "flexStart",
            }}
          >
            <PrimaryText18>Staff</PrimaryText18>
            <div style={{ color: COLORS.orange }}>by lesson</div>

            <Link to={`${props.match.url}/lessonStaff/program/`}>
              <PrimaryTextBlue>Program</PrimaryTextBlue>
            </Link>
            <Link to={`${props.match.url}/lessonStaff/section/`}>
              <PrimaryTextBlue>Section</PrimaryTextBlue>
            </Link>
            <Link to={`${props.match.url}/lessonStaff/programsection/`}>
              <PrimaryTextBlue>Program and Section</PrimaryTextBlue>
            </Link>
            <Link to={`${props.match.url}/lessonStaff/total`}>
              <PrimaryTextBlue>Total</PrimaryTextBlue>
            </Link>
            <Link to={`${props.match.url}/lessonStaff/detail`}>
              <PrimaryTextBlue>Detail</PrimaryTextBlue>
            </Link>
          </div>
        )}

        {1 == 1 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "flexStart",
            }}
          >
            <PrimaryText18>Staff</PrimaryText18>
            <div style={{ color: COLORS.orange }}>by student</div>

            <Link to={`${props.match.url}/studentStaff/program/`}>
              <PrimaryTextBlue>Program</PrimaryTextBlue>
            </Link>
            <Link to={`${props.match.url}/studentStaff/section/`}>
              <PrimaryTextBlue>Section</PrimaryTextBlue>
            </Link>
            <Link to={`${props.match.url}/studentStaff/programsection/`}>
              <PrimaryTextBlue>Program and Section</PrimaryTextBlue>
            </Link>
            <Link to={`${props.match.url}/studentStaff/total`}>
              <PrimaryTextBlue>Total</PrimaryTextBlue>
            </Link>
            <Link to={`${props.match.url}/studentStaff/detail`}>
              <PrimaryTextBlue>Detail</PrimaryTextBlue>
            </Link>
          </div>
        )}

        {1 == 1 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "flexStart",
            }}
          >
            <PrimaryText18>Extra Staff</PrimaryText18>
            <div style={{ color: COLORS.orange }}></div>

            <Link to={`${props.match.url}/extraStaff/detail`}>
              <PrimaryTextBlue>Detail</PrimaryTextBlue>
            </Link>
          </div>
        )}
        {1 == 2 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "flexStart",
            }}
          >
            <PrimaryText18>Email Logs </PrimaryText18>

            <Link to={`${props.match.url}/email/logs`}>
              <PrimaryTextBlue>All</PrimaryTextBlue>
            </Link>
            <Link to={`${props.match.url}/email/error`}>
              <PrimaryTextBlue>Errors</PrimaryTextBlue>
            </Link>
          </div>
        )}
        {1 == 2 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "flexStart",
            }}
          >
            <PrimaryText18>Lessons</PrimaryText18>

            <Link to={`${props.match.url}/lessons/cancel/`}>
              <PrimaryTextBlue>Cancellation Report</PrimaryTextBlue>
              <PrimaryText>Under Construction!</PrimaryText>
            </Link>
          </div>
        )}

        {1 == 2 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "flexStart",
            }}
          >
            <PrimaryText18>Owing</PrimaryText18>

            <Link to={`${props.match.url}/owing/detail/`}>
              <PrimaryTextBlue>Owing</PrimaryTextBlue>
              <PrimaryText>Under Construction!</PrimaryText>
            </Link>
          </div>
        )}
      </div>

      <div className="reportWrapper" style={{ display: "flex", flex: "1 1 100%", flexWrap: "nowrap", marginTop: 50 }}>
        <div style={{ display: "flex", flex: "1 1 70%", flexDirection: "column", alignContent: "flex-start" }}>
          <PrivateRoute path={`${props.match.url}/enrollment/:report/`} component={ReportTable} />
          <PrivateRoute path={`${props.match.url}/workloadgraphic/:report`} component={WorkloadGraphic} />

          <PrivateRoute path={`${props.match.url}/workload/:report/`} component={ReportTable} />

          <PrivateRoute path={`${props.match.url}/lessons/:report/`} component={ReportTable} />
          <PrivateRoute path={`${props.match.url}/owing/:report/`} component={ReportTable} />
          <PrivateRoute path={`${props.match.url}/lessonStaff/:report/`} component={ReportTable} />

          <PrivateRoute path={`${props.match.url}/studentStaff/:report/`} component={ReportTable} />
          <PrivateRoute path={`${props.match.url}/extraStaff/:report/`} component={ReportTable} />

          <PrivateRoute path={`${props.match.url}/email/:report/`} component={ReportTable} />
        </div>
      </div>
    </>
  );
};

export default Reports;
