import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { ListContext, MyContext } from "./App";
import {
  PrimaryText,
  Td,
  StyledTr,
  TableText1,
  TableText2,
  Th,
  HelpText,
  StyledLink,
  PrimaryTextBlue,
  OrangeButton,
  Row100,
  OrangeText,
  BlueText,
  BlueButton,
  LinkTextBlue,
  BoxWithLabelNoM,
  BlockLabelGrow,
  BlockLabel,
} from "./styleCustom";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { FormRight, ModalWarning } from "./formComponents";
import ProductTable from "./productTable";
import Payment from "./payment";
import {
  currentDayToDB,
  dateObjToDBDate,
  dateObjToDisplayDate,
  calculate_expiry_date,
  todayDisplay,
  stringToDateObj,
} from "./momentUtils";
import PrivateRoute from "./privateRoute";
import { divide, remove } from "lodash";
import COLORS, { nametagStyle, buttonOrangeStyle, buttonBlueStyle } from "./styleConstants";
import { MemberLinks } from "./clientHomePage";
import SearchFilterDates from "./searchFilterDates";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DATEPICKER_DATE_DISPLAY } from "./UserSettings";
import { Formik, Form, useField, Field } from "formik";
import * as Yup from "yup";
import {
  TableHeader2,
  MyTextInput,
  MyTextAreaInput,
  ModalHeaderBlue,
  ButtonOrange,
  ButtonBlue,
} from "./formComponents";
import { Circle, CircleSolid, Trash, EditIconPlain } from "./icons";
import { useAlert } from "react-alert";
import { CreatePaymentAndItems } from "./createPaymentAndItems";

const Auth = new AuthHelperMethods();

const ManualPurchase = (props) => {
  const [showSavedCardOption, setShowSavedCardOption] = useState(false);
  const [pDate, setPdate] = useState();

  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  let location = useLocation();
  const calendar = location.pathname.startsWith("/admin/calendarGroup/edit");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const alert = useAlert();
  const [showModal, setShowModal] = useState(true);
  const [paymentOrInvoice, setPaymentOrInvoice] = useState(props.thisisinvoice ? "Invoice" : "Payment");
  // const accountInfo, setAccountInfo=useState()
  //const [noShow, setNoShow] = useState(false);
  //const [n, setN] = useState();
  // const [showDunno, setShowDunno] = useState(false);

  let history = useHistory();

  useEffect(
    () => {
      let data = {};
      data.fee = 0;
      dispatch2(["REMOVE_CART_FEE"]);
    },
    //eslint-disable-next-line
    []
  );

  async function checkIfClientHasPaymentMethod(student_id) {
    dispatch({
      type: "FETCH_INIT",
    });
    if (!mystate.currentStudent.student_id) return false;
    const params = { student_id: mystate.currentStudent.student_id };
    const result = await Auth.api("payments/checkIfClientHasAPaymentMethodOnFile", "get", params);
    console.dir(result.data);
    dispatch({
      type: "FETCH_SUCCESS",
    });
    return result.data.stripe_payment_method_id;
  }

  useEffect(() => {
    if (calendar)
      checkIfClientHasPaymentMethod(listState.quickpaystudentid).then((result) => setShowSavedCardOption(result));
  }, []);

  const finishPay = (data) => {
    console.log(data);
    console.log("I'm back");
    //dispatch2(["SET_QUICKPAY_REFRESH", true]);

    if (listState.quickpaystudentid)
      dispatch({ type: "UPDATE_QUICKPAY", student_id: listState.quickpaystudentid, item_id: data.returnArray[0] });
    history.goBack();
  };

  function cancelPayment() {
    dispatch2(["SET_QUICKPAY_STUDENT_ID", ""]);
    history.goBack();
  }

  return (
    <>
      <CreatePaymentAndItems onFinish={finishPay} />
      <Modal show={true}>
        <ModalHeaderBlue>
          <Modal.Title>{`${paymentOrInvoice} Note`}</Modal.Title>
        </ModalHeaderBlue>
        <Modal.Body>
          <Formik
            initialValues={{
              method: "cash",
              reference: "",
              notes: "",
              amount: listState.cartTotal,
            }}
            validationSchema={Yup.object({})}
            onSubmit={(values, { setSubmitting }) => {
              dispatch({ type: "FETCH_INIT" });

              setButtonDisabled(true);
              console.log("submit");
              let clientpayment = {
                transaction_type: props.thisisinvoice ? "invoice" : values.method,
                payment_notes: values.notes,
                reference: values.reference,
                purchase_date: pDate,
              };
              dispatch2(["SET_CLIENT_PAYMENT", clientpayment]);
            }}
          >
            <Form id="manualPurchaseForm">
              <Row
                style={{ fontSize: 20, color: COLORS.blue, justifyContent: "center", marginBottom: 10 }}
              >{`${paymentOrInvoice} ${mystate.farmDefaults.currency_code} ${listState.cartSubtotalWithTax}`}</Row>

              <Row style={{ justifyContent: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                  <BlockLabel>{"Purchase Date"}</BlockLabel>
                  <DatePicker
                    name="purchasedate"
                    dateFormat={DATEPICKER_DATE_DISPLAY}
                    selected={stringToDateObj(listState.currentProduct.purchase_date)}
                    onChange={(date) => {
                      if (!date) console.log("yuck");
                      else {
                        setPdate(dateObjToDBDate(date));

                        dispatch2([
                          "SET_CURRENT_PRODUCT",
                          { ...listState.currentProduct, purchase_date: dateObjToDBDate(date) },
                        ]);
                      }
                    }}
                  />{" "}
                </div>
              </Row>
              {!props.thisisinvoice && (
                <Row>
                  <BlockLabelGrow>Method</BlockLabelGrow>
                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flex: "1 1 100%",
                      padding: 10,
                      fontSize: 12,
                    }}
                  >
                    <label>
                      <Field style={{ marginRight: 5 }} type="radio" name="method" value="cash" />
                      Cash
                    </label>
                    <label>
                      <Field style={{ marginRight: 5 }} type="radio" name="method" value="cheque" />
                      Check
                    </label>
                    <label>
                      <Field style={{ marginRight: 5 }} type="radio" name="method" value="venmo" />
                      Venmo
                    </label>
                    <label>
                      <Field style={{ marginRight: 5 }} type="radio" name="method" value="transfer" />
                      Bank Transfer
                    </label>
                    <label>
                      <Field style={{ marginRight: 5 }} type="radio" name="method" value="other" />
                      Other
                    </label>
                    {showSavedCardOption && (
                      <label>
                        <Field style={{ marginRight: 5 }} type="radio" name="method" value="saved" />
                        Saved Card
                      </label>
                    )}
                  </div>
                </Row>
              )}
              <Row style={{ justifyContent: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                  <BlockLabel>{"Reference"}</BlockLabel>
                  <Field as="input" type="string" name="reference" placeholder={`${paymentOrInvoice} reference`} />
                </div>
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                  <BlockLabel>{"Notes"}</BlockLabel>
                  <Field as="textarea" name="notes" rows={4} />
                </div>
              </Row>
            </Form>
          </Formik>
        </Modal.Body>

        <Modal.Footer>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flex: "1 1 100%",
            }}
          >
            <button style={buttonBlueStyle} onClick={() => cancelPayment()}>
              {"Cancel"}
            </button>

            <div>
              <button
                disabled={buttonDisabled}
                style={{ ...buttonOrangeStyle, background: buttonDisabled ? "grey" : COLORS.orange }}
                form="manualPurchaseForm"
                type="submit"
              >
                {`Save ${paymentOrInvoice}`}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <div>Some Other things</div>
    </>
  );
};

export default ManualPurchase;
