import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, NavLink, useParams, useHistory } from "react-router-dom";

import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
//import "./styleFormik.css";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col, Modal } from "react-bootstrap";
import SelectList from "./selectList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  BoxWithLabel,
  OrangeButtonSm,
  OrangeButton,
  BlueButton,
  BoxWithLabelNoM,
  StyledListGroup,
  BlockLabel,
  StyledList,
  Td,
  Th,
  HelpText,
  PrimaryTextBlue,
  TableText2,
  PrimaryText,
  colourStyles,
} from "./styleCustom";
import { assignStyles, addModaStyles } from "./styleCustom";
import COLORS, { customColors, selectStyle, modalSelectStyle, nametagStyle } from "./styleConstants";

import { DATEPICKER_DATE_DISPLAY } from "./UserSettings";
import {
  TableHeader2,
  MyTextInput,
  MyTextAreaInput,
  ModalHeaderBlue,
  ButtonOrange,
  ButtonBlue,
} from "./formComponents";
import { DISPLAY_DATE } from "./UserSettings";

import moment from "moment";
import {
  dateObjToDBDate,
  dateISOtoStringShort,
  currentMonthStart,
  currentMonthEnd,
  dateObjToDisplayDate,
  calculate_expiry_date,
} from "./momentUtils";
import SearchFilter, { ModalFilter } from "./searchFilter";
import SearchFilterDates from "./searchFilterDates";
import { ListContext } from "./App";
import { MyContext } from "./App";

import { RemoveIcon, EditIcon, EditIconPlain } from "./icons";
import { useAlert } from "react-alert";
import { errormsg } from "./Constants2";

import SelectStudent from "./selectStudent";
import PrivateRoute from "./privateRoute";
import PackageList from "./packageList";
import PackageDetailsEdit from "./packageDetailsEdit";
import { SelectTemplateFilter } from "./searchFilterReports";
import Select from "react-select";

const Auth = new AuthHelperMethods();

const AssignPackage = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  const [productList, setProductList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [studentsPurchaseList, setStudentsPurchaseList] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});
  const [validFrom, setValidFrom] = useState(moment().toDate());
  const [purchaseDate, setPurchaseDate] = useState(moment().toDate());
  const [expiry, setExpiry] = useState();
  const [amount, setAmount] = useState();
  const [method, setMethod] = useState();
  const [student, setStudent] = useState();
  const [studentName, setStudentName] = useState("");

  const [reference, setReference] = useState();
  const [showModal, setShowModal] = useState(false);
  const [currentNoteInput, setCurrentNoteInput] = useState("");
  const [productCount, setProductCount] = useState();
  const [price, setPrice] = useState();
  const [warning, setWarning] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showStudentModal, setShowStudentModal] = useState(false);
  const [students, setStudents] = useState([]);

  let history = useHistory();

  const alertShow = useAlert();

  useEffect(
    () => {
      return () => {
        dispatch2(["SET_CURRENT_PRODUCT", {}]);
      };
    },
    //eslint-disable-next-line
    []
  );

  /*useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("product_programs/index", "get");
        dispatch2(["PRODUCT_PROGRAMS_GET", result.data]);
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );
*/

  const handleClosePackageEditModal = () => {
    setShowEditModal(false);
    setShowStudentModal(true);
    //history.push(`/admin/admin/assignPackages/students`);
  };

  const handleClickPackage = () => {
    setShowEditModal(true);

    //  dispatch2(["SET_CURRENT_PRODUCT", {}]);
  };

  const handleCloseStudentModal = async () => {
    console.log(students);

    let itemarray = students.map((student) => ({
      ...listState.currentProduct,
      student_id: student.value,
      product_id: listState.currentProduct.id,
    }));
    console.log(itemarray);
    let params = {};
    params.item_details = JSON.stringify(itemarray);
    let result = await Auth.api("items/createMultiple", "post", params);
    console.log(result);

    setShowStudentModal(false);
  };

  /*const getSingleProductPrograms = (productId) => {
    console.log("getSingleProductPrograms");
    return (
      listState.productPrograms
        //.filter(item => item.product_id === productId)
        //.map(item2 => item2.name)
        .reduce((acc, { product_id, name }) => {
          if (product_id === productId) {
            acc.push(name);
          }
          return acc;
        }, [])
        .join(", ")
    );
  };
  */
  const CurrentPackage = () => {
    let product = listState.currentProduct;
    if (!listState.currentProduct.id) return null;
    return (
      <div
        className="eachPurchase"
        style={{
          ...nametagStyle,
          display: "flex",
          flex: "1 1 100%",

          justifyContent: "space-between",

          flexWrap: "wrap",
        }}
      >
        <div
          className="topLine"
          style={{ display: "flex", flexWrap: "nowrap", justifyContent: "space-between", flex: "1 1 100%" }}
        >
          <div style={{ fontWeight: "bold", display: "flex", flex: "1 1 100%" }}>{product.product_name}</div>
        </div>

        <div
          className="productDetails"
          style={{
            display: "flex",
            flex: "1 1 100%",
            justifyContent: "space-between",
            color: COLORS.textgrey,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            {" "}
            <div>{`purchased`}</div>
            <div> {product.purchase_date}</div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {" "}
            <div>{`starts`}</div>
            <div> {product.package_start_date}</div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {" "}
            <div>{`expires`}</div>
            <div> {product.package_expiry_date}</div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {" "}
            <div>{`count`}</div>
            <div> {product.start_count}</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flex: "1 1 100%",
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          {" "}
          {`$${Number(product.product_price).toFixed(2)}`}
        </div>
      </div>
    );
  };

  return (
    <>
      {showStudentModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "darkgrey",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "70%",
              height: "70vh",
              background: "white",
            }}
          >
            <div style={{ color: COLORS.blue, fontSize: 24, padding: 20, textAlign: "center" }}>
              {" "}
              {"Choose Students"}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ButtonBlue onClick={() => setShowStudentModal(false)} label={"Cancel"} />
              <ButtonOrange onClick={() => handleCloseStudentModal()} label={"Save"} />
            </div>

            {mystate.options_student && (
              <Select
                isMulti
                closeMenuOnSelect={false}
                options={mystate.options_student}
                menuIsOpen
                controlShouldRenderValue
                isClearable
                styles={modalSelectStyle}
                scrollable
                onChange={(selectedOption) => setStudents(selectedOption)}
              />
            )}
          </div>
        </div>
      )}
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ flex: "1 1 100%" }}>
          <TableHeader2 title="Assign Packages To Students" />

          <HelpText>
            {
              "Use this if you don't need to record payments, but want to enter packages bought for tracking purposes. You may enter mulitple students at once for each package. "
            }
          </HelpText>
        </div>
      </div>

      <div>
        <PackageList handleClick={() => handleClickPackage()} />
      </div>
      <div></div>

      <div>
        <PackageDetailsEdit
          onCloseModal={handleClosePackageEditModal}
          showModal={showEditModal}
          setShowModal={setShowEditModal}
        />
      </div>
    </>
  );
};

export default AssignPackage;
