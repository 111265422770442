import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, useHistory, useLocation, useParams, withRouter } from "react-router-dom";
import { ListContext, MyContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import { Formik, Form, useField, Field } from "formik";
import * as Yup from "yup";
import {
  NavButton,
  BlockLabel,
  NameStringText,
  MediumPageHeader,
  BlueButton,
  SaveButtonSm,
  CancelButtonSm,
  HelpText,
  LinkText,
  SaveButton,
  BoxWithLabelW,
} from "./styleCustom";
import {
  MyTextInput,
  TextInput,
  MyCheckbox,
  OrangeButton,
  TableHeader2,
  BlueButtonFinish,
  ModalHeaderBlue,
} from "./formComponents";
import { Row, Col, Button, ListGroup, Modal } from "react-bootstrap";

const Auth = new AuthHelperMethods();

export const EditDependantName = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  let history = useHistory();
  let people_id = useParams().student_person_id;
  console.log(useParams().student_person_id);

  let member = {};
  if (listState.accountHolder.id == people_id) {
    member = listState.accountHolder;
  } else {
    if (listState.members && listState.members.length > 0)
      member = listState.members.find((member) => member.id == people_id);
  }
  let memberName = member.person_name;
  let firstName = member.first_name;
  let lastName = member.last_name;

  if (!firstName) {
    firstName = memberName.split(" ").slice(0, -1).join(" ");
    lastName = memberName.split(" ").slice(-1).join(" ");
  }
  useEffect(
    () => {
      console.log("useEffect");
      window.searchinputRef = searchinputRef;
      if (searchinputRef.current) searchinputRef.current.focus();
    },
    //eslint-disable-next-line
    []
  );
  const searchinputRef = React.createRef();

  return (
    <Modal show={true} onHide={() => history.goBack()} autoFocus>
      <ModalHeaderBlue closeButton>
        <Modal.Title> {"Edit Name"}</Modal.Title>
      </ModalHeaderBlue>
      <Modal.Body>
        <Formik
          initialValues={{
            firstName: firstName,
            lastName: lastName,
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required("First Name Required"),
            lastName: Yup.string(),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            values.person_id = people_id;

            let result = await Auth.api("people/editName", "post", values);

            console.log(result.data);
            dispatch2(["UPDATE_CLIENT_INFO", result.data.person]);
            dispatch2(["CLIENT_UPDATE_SINGLE_MEMBER", result.data.person]);

            history.goBack();
            resetForm();
          }}
        >
          <Form id="editDependentForm">
            <Row>
              <Col>
                <MyTextInput name="firstName" type="text" placeholder="First Name" label="First Name" />
              </Col>{" "}
              <Col>
                <MyTextInput label="Last Name" name="lastName" type="text" placeholder="Last Name" />
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 30,
              }}
            >
              <OrangeButton type="submit">{"Save"}</OrangeButton>
            </Row>
          </Form>
        </Formik>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export const AddDependantForm = (props) => {
  //  const { values, submitForm } = useFormikContext();
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  const [params, setParams] = useState({});
  const [first, setFirst] = useState({});
  const [second, setSecond] = useState({});
  let history = useHistory();

  useEffect(
    () => {
      console.log("useEffect");
      window.searchinputRef = searchinputRef;
      if (searchinputRef.current) searchinputRef.current.focus();
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        const result = await Auth.api("options/index", "get", params);
        dispatch({
          type: "UPDATE_OPTIONS",
          options: result.data,
        });
      };
      fetchData();
    },
    //eslint-disable-next-line
    [listState.clients]
  );

  if (!listState.accountHolder) {
    return <div>you have to add an account holder first</div>;
  }
  const searchinputRef = React.createRef();
  return (
    <>
      {" "}
      <Modal show={true} onHide={() => history.goBack()} autoFocus>
        <ModalHeaderBlue closeButton>
          <Modal.Title>{"Add Member"}</Modal.Title>
        </ModalHeaderBlue>
        <Modal.Body>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
            }}
            enableReinitialize
            validationSchema={Yup.object({
              firstName: Yup.string().required("First Name Required"),
              lastName: Yup.string(),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              //    let params = {};
              // if (first) params.kid = first.concat(` ${second}`);

              //console.log(params);

              console.log(`x${values.firstName}`);

              values.firstName = values.firstName.trim();
              values.lastName = values.lastName.trim();
              console.log(`x${values.firstName}`);

              values.accountHolderId = listState.accountHolder.id;
              values.billing_person_id = listState.accountHolder.billing_person_id;

              let result = await Auth.api("people/addNewDependant", "post", values);

              dispatch2(["ADD_NEW_DEPENDANT", result.data.newMember]);

              history.goBack();
              resetForm();
            }}
          >
            <Form id="dependentForm">
              <MediumPageHeader style={{ textAlign: "center" }}>
                {`Create Additional Account Member for `}
              </MediumPageHeader>
              <MediumPageHeader style={{ textAlign: "center" }}>
                {` ${listState.accountHolder.person_name}`}
              </MediumPageHeader>

              <Row>
                <Col>
                  <MyTextInput name="firstName" type="text" placeholder="First Name" label="First Name" />
                </Col>{" "}
                <Col>
                  <MyTextInput label="Last Name" name="lastName" type="text" placeholder="Last Name" />
                </Col>
              </Row>

              <>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 30,
                  }}
                >
                  <OrangeButton type="submit">{"Save"}</OrangeButton>
                </Row>
              </>
            </Form>
          </Formik>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </>
  );
};

export default AddDependantForm;
