import decode from "jwt-decode";
import axios from "axios";
import APIURL from "./Constants";
import { history } from "./index";
let isLoading = true;
let hasError = false;
let data = {};

//const APIURL = process.env.REACT_APP_APIURL;

export default class AuthHelperMethods {
  getUserType = () => {
    let usercode = decode(this.getToken()).user_type_code;
    return usercode;
  };

  getUserPersonId = () => {
    let person_id = decode(this.getToken()).user_person_id;
    return person_id;
  };

  getUserRoleIds = () => {
    let role_id = decode(this.getToken()).role_id;
    return role_id;
  };

  loggedIn = () => {
    const token = this.getToken(); // Getting token from localstorage
    return !!token && !this.isTokenExpired(token); // handwaiving here*/
  };

  isTokenExpired = (token) => {
    try {
      const decoded = decode(token);

      if (decoded.exp < Date.now() / 1000) {
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  };

  setToken = (idToken) => {
    localStorage.setItem("auth_token", idToken);
  };

  getToken = () => {
    return localStorage.getItem("auth_token");
  };

  getConfirm = () => {
    let answer = decode(this.getToken());
    return answer;
  };

  getHeaders() {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer  ${this.getToken()}`,
    };
    return headers;
  }

  api = async (url, method, params) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (this.getToken()) {
      headers["Authorization"] = "Bearer " + this.getToken();
    } else {
      console.log("NOTLOGGEDIN");
      this.setToken("");
      return {};
    }
    try {
      return axios(url, {
        params: params,
        method: method || "get",
        headers,
      });
    } catch (e) {
      console.log("log auth error");
      console.log(e.message);
      localStorage.setItem("auth_token", "");
    }
  };

  checkTimer = async () => {
    if (localStorage.getItem("timer")) {
      let timer = localStorage.getItem("timer");
      if ((Date.now() - timer) / 1000 / 60 > 60 || !localStorage.getItem("systemVersion")) {
        const result = await this.api("vusers/checkUserVersion", "get");
        if (result && result.data) localStorage.setItem("systemVersion", result.data.version);
        localStorage.setItem("timer", Date.now());
      }
    } else localStorage.setItem("timer", Date.now());
  };
}
