import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link, useParams, useHistory, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import AuthHelperMethods from "./AuthHelperMethods";
import { ListContext, MyContext } from "./App";
import moment from "moment";
import COLORS from "./styleConstants";
import { BlueText } from "./styleCustom";
import useSWR from "swr";
import { Calendar, momentLocalizer } from "react-big-calendar";
import AddModal from "./addModal.js";
import { SelectTemplateFilter } from "./searchFilterReports";

import { Filter, FilterLabels, SelectFilter, FilterActive } from "./calendarFilter";
import { resourceAccessor, eventStyleGetter, dayPropGetter } from "./calendarConstants";
import {
  CustomEvent,
  BlueResourceButtons,
  BulkButtons,
  ToggleStudentEventName,
  ToggleShowClientShowAll,
  ToggleShowFilter,
} from "./calendarComponents";
import {
  formatEventsFromDB,
  calculateDateRange,
  calulateInitialCalendarDateRange,
  calulateInitialAgendaDates,
} from "./calendarFunctions";
import { useAlert } from "react-alert";
import BookingCalendar from "./bookingMakeupCalendar";
const localizer = momentLocalizer(moment);
import { nowToDB } from "./momentUtils";
const Auth = new AuthHelperMethods();

export const CalendarHomePageMakeupBooking = ({ match, makeupItemId }) => {
  //console.log(props);
  //const [data, setData] = useState([]);
  const alert = useAlert();
  const usertype = Auth.getUserType();

  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);
  const [showFilter, setShowFilter] = useState(isMobile ? false : false);
  const [showBookLesson, setShowBookLesson] = useState(false);
  const [makeups, setMakeups] = useState([]);
  const [title, setTitle] = useState("");
  const [currentMakeup, setCurrentMakeup] = useState();
  const [now, setNow] = useState(false);
  const [formattedData, setFormattedData] = useState([]);
  const [calendarLength, setCalendarLength] = useState([30]);

  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    dispatch({
      type: "FETCH_INIT",
    });
    getValidMakeups();

    // if (listState.filter.page != "calendar") dispatch2(["SET_SEARCH_FILTER", "calendar", "page"]);
  }, [listState.currentClient, currentMakeup]);

  useEffect(
    () => {
      return () => {
        setCurrentMakeup();
      };
    },
    //eslint-disable-next-line
    []
  );

  useEffect(() => {
    console.log("INITAL USEEFFECT");
    dispatch({
      type: "SET_CALENDAR_DATE",
      data: moment().toDate(),
    });
    let [date1, date2] = calulateInitialAgendaDates("month");
    console.log(date1, date2);

    let initialfilter = {
      currentStudentId: listState.currentClient.student_id,

      page: "calendar",
      view: "agenda",
      available: "available",
      date1: date1,
      date2: date2,
    };
    console.log(initialfilter);

    dispatch2(["SET_SEARCH_FILTER_OBJECT", initialfilter]);
  }, []);

  const getValidMakeups = async () => {
    try {
      let params = {
        student_id: listState.currentClient.student_id,
        scheduled_date: nowToDB(),
      };
      const result = await Auth.api("lesson_people/getStudentMakeupsForBooking", "get", params);
      setMakeups(result.data.makeups);
      setTitle(result.data.makeups.length > 0 ? "available makeup(s) to book" : "Client has no makeups");

      dispatch({
        type: "FETCH_SUCCESS",
      });
    } catch (error) {
      dispatch({
        type: "FETCH_ERROR",
      });
      console.error(error.message);
    }
  };

  const { data, error, isLagging, resetLaggy, isValidating } = useSWR(
    listState.filter.date1 &&
      listState.filter.date2 &&
      listState.filter.page == "calendar" &&
      listState.currentClient.student_id &&
      currentMakeup && [`lessons/index`, listState.filter]
  );
  useEffect(() => {
    if (isValidating) dispatch({ type: "FETCH_INIT" });
    else {
      console.log("IS VALIDATING");
      dispatch({ type: "FETCH_SUCCESS" });
      const formattedData = formatEventsFromDB(data);
      const filterBufferHours = formattedData.filter((lesson) => lesson.start > moment().add(data.buffer, "hours"));
      setFormattedData(usertype == "STU" ? filterBufferHours : formattedData);
      //  setFormattedData(formattedData); //! changed  feb 05 2023 - was overriding data for students from line above
    }
  }, [isValidating]);

  function handleRangeChange(e) {
    console.log("handleRangeChange");
    var [currentStart, currentEnd, view] = calculateDateRange(e);
    // dispatch2(["SET_SEARCH_FILTER", view, "view"]);
    dispatch2(["SET_BOTH_SEARCH_FILTER_DATES", currentStart, currentEnd, "calendar"]);
    dispatch({
      type: "SET_CALENDAR_DATE_RANGE",
      start: currentStart,
      end: currentEnd,
    });
    console.log("set view from range");
  }

  function handleNavigate(date) {
    console.log("handleNavigate");
    dispatch({
      type: "SET_CALENDAR_DATE",
      data: date,
    });
  }

  const AgendaEvent = (event) => {
    const { mystate, dispatch } = useContext(MyContext);
    const { listState, dispatch2 } = useContext(ListContext);
    event = event.event;

    let onClickAgenda = () => {
      let url = `${match.url}/bookingMakeupCalendar`;
      dispatch({
        type: "SET_EVENT",
        event: event,
      });
      dispatch2(["SET_CURRENT_BOOKING_LESSON", event]);

      history.push(url);
    };

    let eventtime = event.is_allday ? "" : event.time;
    let firststring = `${eventtime}  ${listState.showByStudentName ? event.studentString : event.title}`;
    let firststringnotime = `${listState.showByStudentName ? event.studentString : event.title}`;
    let bottomstring = listState.showByStudentName ? event.title : event.studentString;

    return (
      <div
        onClick={(e) => onClickAgenda(e)}
        style={{
          minHeight: 15,
          cursor: "pointer",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <span>{`${firststringnotime}  ${event.studentString}`}</span>
        <span>
          {event.available > 1 && `${event.available} available spots `}
          {event.available == 1 && `${event.available} available spot `}
        </span>
      </div>
    );
  };

  /*  const handleExistingEvent = (e) => {
    let url = `/admin/clienthome/${listState.currentClient.id}/calendar/bookingCalendar`;
    dispatch({
      type: "SET_EVENT",
      event: e,
    });
    dispatch2(["SET_CURRENT_BOOKING_LESSON", e]);
    history.push(url);
  };
  */

  let formats = {
    agendaHeaderFormat: ({ start, end }) => {
      return moment(start).format("dddd MMMM Do") + " - " + moment(end).format("dddd MMMM Do");
    },
  };

  const onClickMakeup = async (item) => {
    let params = { product_id: item.product_id, is_active: 1 };
    const result = await Auth.api("product_programs/getProgramsforProduct", "get", params);
    console.log(moment(item.package_start_date));
    setCalendarLength(
      moment(item.package_expiry_date).diff(moment(item.package_start_date), "days") // 1
    );
    console.log(moment(item.package_expiry_date).diff(moment(item.package_start_date), "days"));
    if (result.data?.productPrograms) dispatch2(["SET_SEARCH_FILTER", result.data.productPrograms, "program"]);
    let newfilter = {
      ...listState.filter,
      program: result.data.productPrograms,
      date1: item.package_start_date,
      date2: item.package_expiry_date,
      currentStudentId: listState.currentClient.student_id,
    };
    dispatch2(["SET_SEARCH_FILTER_OBJECT", newfilter]);
    dispatch({
      type: "SET_CALENDAR_DATE_RANGE",
      start: item.package_start_date,
      end: item.package_expiry_date,
    });
    dispatch({
      type: "SET_CALENDAR_DATE",
      data: item.package_start_date,
    });
    setCurrentMakeup(item.id);
  };

  return (
    <>
      <div
        className="reportTable"
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          flex: "1 1 ",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3>{title}</h3>
          {makeups &&
            makeups.map((makeup) => (
              <>
                {" "}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    className="issued"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: 100,
                    }}
                  >
                    <BlueText>{"issued"}</BlueText> <div>{makeup.purchase_date}</div>
                  </div>
                  <div
                    className="start"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: 100,
                    }}
                  >
                    <BlueText>{"starts"} </BlueText>
                    <div>{makeup.package_start_date}</div>
                  </div>
                  <div
                    className="expiry"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: 100,
                    }}
                  >
                    <BlueText> {"expires "} </BlueText>
                    <div>{makeup.package_expiry_date}</div>
                  </div>

                  <div
                    className="lesson"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: 100,
                    }}
                  >
                    <BlueText> {"missed lesson "} </BlueText>
                    <div>{`${makeup.scheduled_date} `}</div>
                    <div>{makeup.name}</div>
                  </div>

                  <div
                    className="book"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: 100,
                      alignContent: "center",
                    }}
                  >
                    {" "}
                    <div>{""}</div>
                    <div
                      onClick={() => onClickMakeup(makeup)}
                      style={{ cursor: "pointer", color: COLORS.orange, marginLeft: 4 }}
                    >
                      {"Book this makeup lesson"}
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>
        {currentMakeup && (
          <div
            className={"table"}
            style={{
              marginTop: 20,
              display: "flex",
              flex: "1 1 100%",
              flexDirection: "column",
            }}
          >
            <div className="filtertop">
              <div style={{ marginBottom: 10 }}>
                <ToggleStudentEventName />
              </div>

              <div style={{ xmaxWidth: 700, marginBottom: 20 }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {Auth.getUserType() != "STU" && <SelectFilter staff program_type program location section />}
                  {Auth.getUserType() == "STU" && <SelectFilter staff />}
                </div>
                <FilterLabels />
              </div>
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", fontSize: 18, color: COLORS.orange }}
            >{`Showing lessons available for makeup for ${listState.currentClient.person_name}. To book makeup, click on the event.`}</div>

            <div style={{ minHeight: 700 }}>
              <Calendar
                events={formattedData}
                localizer={localizer}
                defaultView={"agenda"}
                selectable
                min={mystate.farmDefaults.min_time}
                max={mystate.farmDefaults.max_time}
                onNavigate={handleNavigate}
                onRangeChange={handleRangeChange}
                date={mystate.calendarDate}
                eventPropGetter={eventStyleGetter}
                dayPropGetter={dayPropGetter}
                components={{ event: CustomEvent, agenda: { event: AgendaEvent } }}
                step={mystate.farmDefaults.calendar_interval || 60}
                popup
                views={["agenda"]}
                formats={formats}
                length={calendarLength}
                toolbar={false}
              />
            </div>
            <PrivateRoute
              path={`${match.url}/bookingMakeupCalendar`}
              component={BookingCalendar}
              makeupItemId={currentMakeup}
              setCurrentMakeup={setCurrentMakeup}
            />

            <PrivateRoute path={`/admin/clienthome/${listState.currentClient.id}/add/:add`} component={AddModal} />
          </div>
        )}
      </div>
    </>
  );
};
