import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Route, Link, useRouteMatch, useParams, useHistory, useLocation } from "react-router-dom";
import { StudentTextSpan } from "./styleCustom";
import AuthHelperMethods from "./AuthHelperMethods";
import { timeISOtoString, currentDayToDB } from "./momentUtils";
import { MyContext } from "./App";
import { Asc, Desc, Circle, CircleSolid, Check, ArrowLeft, BackPage } from "./icons";
import moment from "moment";
import { dateObjToDBDate, DATEPICKER_DATE_DISPLAY, dateObjToDisplayDate } from "./momentUtils";
import { ListContext } from "./App";
import { Row, Col } from "react-bootstrap";
import useSWR from "swr";
import { formatEventsFromDB, calculateDateRange, calulateInitialCalendarDateRange } from "./calendarFunctions";
import { Filter, FilterLabels, FilterActive, SelectFilter } from "./calendarFilter";
import { ToggleShowFilter } from "./calendarComponents";
import {
  ContainerV,
  Row1,
  Ul,
  PageTitle,
  OrangeButtonSm,
  colourStyles,
  colourStyles2,
  BoxWithLabelNoM,
  BoxWithLabel200,
  BoxWithLabel,
  Th,
  Td,
} from "./styleCustom";
import COLORS, { filterVerticalOpenStyle, filterVerticalCloseStyle } from "./styleConstants";
import SearchFilterDate from "./searchFilterDates";

import { SelectTemplateFilter, Active } from "./searchFilterReports";
import {
  TopName,
  ButtonOrange,
  OrangeButtonReg,
  ButtonOrangeMed,
  ButtonOrangeInverse,
  UserCan,
  OpenClose,
} from "./formComponents";
import { DISPLAY_DATE } from "./UserSettings";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Font } from "@react-pdf/renderer";
import { available_options, show_cancel_options } from "./Constants2";
import { Bulk } from "./bulk2";
import { useAlert } from "react-alert";

const Auth = new AuthHelperMethods();

const studentCancelStyle = {
  textDecoration: "line-through",
  color: "red",
  fontStyle: "italic",
};

const LessonReportLink = ({ heading, largeFont, data, events }) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  //const [largeFont, setLargeFont] = useState(false);
  heading = moment(listState.filter.date1).format(DISPLAY_DATE);
  console.log(heading);
  if (!events) return null;
  return (
    <div>
      <PDFDownloadLink
        document={
          <PdfDisplay
            heading={heading}
            lessons={events}
            lessonHorses={data?.LessonHorses}
            lessonStudents={data?.LessonStudents}
            largeFont={mystate.farmDefaults.large_font_on_daysheet}
            tasks={data?.tasks}
          />
        }
        fileName="newdaysheet.pdf"
      >
        {({ blob, url, loading, error }) => (loading ? "Loading document..." : "PDF ready - click to download")}
      </PDFDownloadLink>
    </div>
  );
};

const PdfDisplay = ({ lessons, lessonHorses, lessonStudents, heading, largeFont, tasks }) => {
  const styles = StyleSheet.create({
    headingStyle: {
      display: "flex",
      flexWrap: "wrap",
      flex: " 1 1 100%",
      marginTop: "30",
      marginLeft: "20",
      fontSize: "14",
      marginBottom: "10",
    },
    textStyle: { paddingBottom: 1 },
    boldStyle: { paddingBottom: 1, color: "black" },
  });

  return (
    <Document>
      <Page
        style={{
          marginTop: 10,
          fontSize: largeFont ? "10pt" : "6pt",
          display: "flex",
          marginLeft: 20,
          marginRight: 20,
          color: "#4D4D4D",
          fontFamily: "Helvetica",
        }}
        wrap={true}
      >
        <>
          <View margin="20" fixed>
            <Text style={styles.headingStyle}>{heading}</Text>
          </View>

          {lessons &&
            lessons.length > 0 &&
            lessons.map((lesson) => (
              <>
                <View
                  className="wrapper"
                  wrap={false}
                  key={lesson.id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flex: "1 1 95%",
                    border: "1",
                    borderColor: "grey",
                    marginRight: 30,
                    marginBottom: 0,
                    flexWrap: "wrap",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      marginRight: 10,
                      backgroundColor: lesson.color || "lightgrey",
                      maxWidth: 30,
                      minWidth: 30,
                    }}
                  >
                    <Text style={{ color: lesson.color || "lightgrey" }}> A</Text>
                  </View>
                  <View
                    className="afterColor"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",

                      justifyContent: "flex-end",
                      flexBasis: "90%",
                      paddingTop: 3,
                      paddingBottom: 3,
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        flexBasis: "100%",
                        paddingBottom: 2,
                        justifyContent: "space-between",
                      }}
                    >
                      <View className="title" style={{ display: "flex", flexBasis: "25%" }}>
                        <Text style={styles.boldStyle}>{lesson.title}</Text>
                        <Text style={styles.boldStyle}>
                          {!lesson.is_allday &&
                            `${timeISOtoString(lesson.scheduled_starttime)} -  ${timeISOtoString(
                              lesson.scheduled_endtime
                            )}`}
                        </Text>
                        <Text style={styles.boldStyle}>{lesson.location_name || "location unassigned"} </Text>
                      </View>
                      <View className="staff" style={{ display: "flex", flexBasis: "25%" }}>
                        <Text style={styles.boldStyle}>{lesson.instructor_name || "instructor unassigned"}</Text>
                        {tasks &&
                          tasks
                            .filter((staff) => staff.lesson_date_time_id == lesson.id)
                            .map((staff) => (
                              <Text style={styles.textStyle}>{` ${staff.task_name}: ${staff.instructor_name}`}</Text>
                            ))}
                      </View>

                      <View style={{ display: "flex", flexBasis: "45%" }}>
                        {lessonStudents
                          .filter((students) => students.lesson_date_time_id == lesson.id)
                          .map((student) => (
                            <>
                              <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                  borderBottom: 1,
                                  borderStyle: "dotted",
                                  borderColor: "black",
                                  paddingBottom: 0.5,
                                  marginTop: 4,
                                }}
                                key={student.id}
                              >
                                <View
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    flexBasis: "40%",
                                  }}
                                >
                                  <Text style={styles.textStyle}>
                                    <Text style={student.student_is_cancelled ? studentCancelStyle : {}}>
                                      {student.name}{" "}
                                    </Text>
                                    {`   ${student.student_is_cancelled ? "cancelled" : ""} `}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    display: "flex",
                                    flexBasis: "60%",

                                    flexWrap: "wrap",
                                    flexDirection: "row",
                                  }}
                                >
                                  {student.assigned_to &&
                                    lessonHorses
                                      .filter(
                                        (horse) =>
                                          horse.lesson_date_time_id == lesson.id && student.horse_id == horse.horse_id
                                      )
                                      .map((horse) => (
                                        <>
                                          <Text style={styles.textStyle}>{horse.horse_name}</Text>
                                          <Text style={styles.textStyle}>
                                            {horse.tack_name ? `  - ${horse.tack_name}` : null}
                                          </Text>
                                        </>
                                      ))}
                                </View>
                              </View>
                            </>
                          ))}
                      </View>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flex: "1 1 100%",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flex: "1 1 100%",
                          marginBottom: 5,
                          marginTop: 3,
                        }}
                      >
                        <Text style={{ marginRight: 5 }}>{"Horses:  "}</Text>
                        {lessonHorses &&
                          lessonHorses
                            .filter((horse) => horse.lesson_date_time_id === lesson.id)
                            .map((horse) => (
                              <Text style={{ marginRight: 10 }} key={horse.id}>
                                {horse.name}
                              </Text>
                            ))}
                      </View>
                    </View>
                  </View>
                </View>
              </>
            ))}
        </>
      </Page>
    </Document>
  );
};

export const DaySheetView = () => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  let history = useHistory();
  const [ready, setReady] = useState(false);

  const [heading, setHeading] = useState();
  const [showFilter, setShowFilter] = useState(true);
  const [available, setAvailable] = useState("");
  const [initComplete, setInitComplete] = useState(false);
  const [largeFont, setLargeFont] = useState(mystate.farmDefaults.large_font_on_daysheet);

  // const [selectAll, setSelectAll] = useState(false);
  const widecolourStyles = {
    ...colourStyles,
    option: { ...colourStyles.option, maxWidth: 300 },
  };
  let location = useLocation();
  //location.pathname.endsWith("/account") ||
  let bulk = location.pathname.endsWith("/bulk") || false;
  console.log(bulk);
  console.log(moment("2021-11-30").add(1, "months").endOf("month"));

  useEffect(() => {
    console.log("INITAL USEEFFECT");
    dispatch2(["SET_BOTH_SEARCH_FILTER_DATES", currentDayToDB(), currentDayToDB(), "daysheet"]);
    // dispatch2(["SET_SEARCH_FILTER", true, "not_cancelled"]);
    // dispatch2(["SET_SEARCH_FILTER", 0, "lesson_is_cancelled"]);
  }, []);

  const { data, error, isLagging, resetLaggy, isValidating } = useSWR(
    listState.filter.date1 && listState.filter.date2 ? [`lessons/index`, listState.filter] : null
  );
  useEffect(() => {
    if (isValidating) dispatch({ type: "FETCH_INIT" });
    else dispatch({ type: "FETCH_SUCCESS" });
  }, [isValidating]);
  let events = formatEventsFromDB(data) || [];
  //let js = JSON.stringify(events);
  //console.log(js);

  const blockStyle = {
    padding: 10,
    fontWeight: "bold",
    paddingLeft: 0,
    minWidth: 150,
  };

  const me = {
    border: "solid 5pt blue",
    minWidth: 150,
    maxWidthx: 150,
    maxHeight: 40,
    overflow: "visible",
  };
  let colourstylesMe = { ...colourStyles };

  const colourStylesShe = {
    ...colourStyles,
    control: (styles, state) => ({
      ...colourStyles.control,
      border: "solid 5px red",
      minWidth: 300,
    }),
  };

  return (
    <>
      <PageTitle>{bulk ? "Bulk Lesson Actions" : "Day Sheet"}</PageTitle>

      <div style={{ display: "flex", justifyContent: "flex-start", flex: "1 1 100%" }}>
        <ToggleShowFilter />
      </div>
      {listState.showfilter && (
        <>
          <SelectFilter staff program_type program location section student horse />
          {!bulk && <FilterActive lessonCancel available studentCancel />}
        </>
      )}

      <FilterLabels />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          flex: "1 1 100%",
          marginBottom: 20,
          justifyContent: "space-between",
        }}
      >
        <SearchFilterDate singleboth initial={moment().toDate()} hidelabel label={"Select Day"} />
        <div style={{ fontSize: 20, color: COLORS.blue, paddingRight: 30 }}>
          {dateObjToDisplayDate(listState.filter.date1)}{" "}
        </div>
        {!bulk && (
          <>
            <div onClick={() => setReady(true)} style={{ color: COLORS.orange, cursor: "pointer" }}>
              {"Prepare pdf download"}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {ready && (
                <div onClick={() => setReady(false)}>
                  <LessonReportLink
                    onClick={() => setReady(false)}
                    heading={dateObjToDisplayDate(listState.filter.date1)}
                    largeFont={largeFont}
                    data={data}
                    events={events}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <div style={{ display: "flex", flexDirection: "row", flex: "0 1 100%" }}>
        <div style={{ display: "flex", flexDirection: "column", alignContent: "flex-start", flex: "1 1" }}>
          {events?.length === 0 && !isValidating && <div>There is nothing scheduled for today</div>}
          {bulk ? <Bulk events={events} lessonStudents={data?.LessonStudents} /> : <Day events={events} data={data} />}
        </div>
      </div>
    </>
  );
};

const Day = ({ events, data }) => {
  const { mystate, dispatch } = useContext(MyContext);
  const [ready, setReady] = useState(false);
  const [heading, setHeading] = useState();
  let history = useHistory();
  const blockStyle = {
    padding: 10,
    fontWeight: "bold",
    paddingLeft: 0,
    flex: "1 1 ",
    minWidth: 100,
  };
  if (!events) return null;
  return (
    <>
      {events.map((lesson) => (
        <div
          className="wrapper"
          noGutters
          key={lesson.id}
          style={{
            display: "flex",
            flexDirection: "row",
            border: "solid 1pt grey",
            padding: 0,

            marginBottom: 5,
            background: lesson.lesson_is_cancelled ? "lightgrey" : "white",
            minHeight: 75,
            fontStyle: "bold",
            color: COLORS.tablegrey,
          }}
        >
          <div style={{ display: "flex", minWidth: 40, background: lesson.color || "white" }} />
          <div
            className="restWrapper"
            style={{
              display: "flex",
              flexDirection: "row",
              flex: "1 1 100%",
              flexWrap: "wrap",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div
              className={"row1"}
              style={{
                display: "flex",
                flexDirection: "row",
                flex: "1 1 100%",
                justifyContent: "space-between",
                marginBottom: 10,
                alignItems: "flex-start",
              }}
            >
              <div
                style={{ display: "flex", flex: "1 1 20%", flexDirection: "column", padding: 5, flexWrap: "nowrap" }}
              >
                {lesson.lesson_status_code == "COM" && (
                  <div style={{ fontWeight: "bold", color: "red" }}>{"Completed"}</div>
                )}

                <div style={{ fontWeight: "bold" }}>{lesson.title}</div>
                <div style={{ fontWeight: "bold" }}>
                  {!lesson.is_allday &&
                    `${timeISOtoString(lesson.scheduled_starttime)} -  ${timeISOtoString(lesson.scheduled_endtime)}`}
                </div>
                <div style={{ fontWeight: "bold" }}>{lesson.location_name}</div>
              </div>
              <div style={{ display: "flex", flex: "1 1 20%", flexDirection: "column", padding: 5 }}>
                <div style={{ fontWeight: "bold" }}>{lesson.instructor_name || "Staff unassigned"}</div>

                <div>
                  {data &&
                    data.tasks &&
                    data?.tasks
                      .filter((staff) => staff.lesson_date_time_id == lesson.id)
                      .map((staff) => <div>{` ${staff.task_name}: ${staff.instructor_name}`}</div>)}
                </div>
              </div>

              <div style={{ display: "flex", flex: "1 1 60%", flexWrap: "wrap" }}>
                {data?.LessonStudents.filter((students) => students.lesson_date_time_id == lesson.id).map((student) => (
                  <div style={{ display: "flex", flex: "1 1 100%", border: "none", borderBottom: "1pt dotted black" }}>
                    <div
                      style={{
                        display: "flex",
                        flex: "1 1 40%",
                      }}
                    >
                      <span style={student.student_is_cancelled ? studentCancelStyle : {}}>{student.name} </span>
                      <span style={{ marginLeft: 5, fontStyle: "italic" }}>
                        {" "}
                        {student.student_is_cancelled ? `  cancelled ` : ``}
                      </span>
                    </div>

                    <div style={{ display: "flex", flex: "1 1 50%" }}>
                      {student.assigned_to &&
                        data?.LessonHorses.filter(
                          (horse) => horse.lesson_date_time_id == lesson.id && student.horse_id == horse.horse_id
                        ).map((horse) => (
                          <>
                            <span style={{ marginRight: 10 }} key={horse.id}>
                              {horse.name}
                            </span>
                            <span style={{ fontStyle: "italic" }}>{horse.tack_name || null}</span>
                          </>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="horses"
              style={{ display: "flex", flexWrap: "wrap", flex: "1 1 100%", paddingLeft: 20, fontStyle: "italic" }}
            >
              <div>
                <span style={{ marginRight: 10, fontWeight: "bold" }}>{"Horses:  "}</span>
                {data?.LessonHorses.filter((horse) => horse.lesson_date_time_id === lesson.id).map((horse) => (
                  <>
                    {" "}
                    <span style={{ marginRight: 10 }} key={horse.id}>
                      {horse.name}
                    </span>
                  </>
                ))}
              </div>
            </div>
            {lesson.lesson_notes && mystate.farmDefaults.show_general_lesson_notes_on_daysheet && (
              <div
                className="notes"
                style={{ display: "flex", flexWrap: "wrap", flex: "1 1 100%", paddingLeft: 20, fontStyle: "italic" }}
              >
                {lesson.lesson_notes}
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

/*SELECT lesson_date_times.name,  groups.group_name, people.person_name FROM `groups`, staff_groups, lesson_groups, instructors, lesson_date_times, people
WHERE groups.id=staff_groups.group_id
AND staff_groups.id = lesson_groups.staff_group_id
AND staff_groups.instructor_id = instructors.id
AND lesson_groups.lesson_date_time_id=lesson_date_times.id
AND lesson_groups.lesson_date_time_id=157642
AND instructors.person_id=people.id
*/
