import React, {
  Fragment,
  useContext,
  useReducer,
  useState,
  useEffect
} from "react";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { MyContext } from "./App";
import UserSettings from "./UserSettings";
import { Button, Alert } from "react-bootstrap";
//ROLES
//PERMISSIONS
const req = [
  { compName: "resourceButton", needs: "SHOW_RESOURCE_BUTTON" },
  { compName: "addEventsButton", needs: "ADD_EVENTS" },
  { compName: "editEventsButton", needs: "EDIT_EVENTS" },
  { compName: "calendarState", needs: ["CALENDAR_STATE"] }
];

let requiresPermissions = req.find(data => data.compName === "calendarState")
  .needs;
//console.log("find perm");

//console.log("find perm");

const ADM = [
  "ADD_EVENTS",
  "EDIT_EVENTS",
  "SHOW_RESOURCE_BUTTON",
  "CALENDAR_STATE",
  "CAN_ADD_STUDENT",
  "CAN_ADD_HORSE"
];
const INS = ["ADD_EVENTS"];
const STU = ["VIEW_EVENTS", "SCHEDULE_MAKEUP", "REQUEST_LESSON"];

const userType = UserSettings.userTypeCode;
const assignedPermissions = ADM;
//console.log("requiresPermissions");

//console.log(requiresPermissions);
//console.log("assignedPermissions");
//console.log(assignedPermissions);

//const myusersetting.ShowResourceButton = true;

export const CalendarStateButton = () => {
  const { mystate, dispatch } = useContext(MyContext);

  const showState = () => {
    console.log("mystate");
    console.log(mystate);
  };
  return (
    <>
      {process.env.NODE_ENV !== "production" && (
        <PermissibleRender
          userPermissions={assignedPermissions}
          requiredPermissions={requiresPermissions}
          oneperm
        >
          <Button variant="outline-info" size={"sm"} onClick={showState}>
            Calendar State
          </Button>{" "}
        </PermissibleRender>
      )}
    </>
  );
};

export const ShowResourceButton = props => {
  return (
    <PermissibleRender
      userPermissions={assignedPermissions}
      requiredPermissions={requiresPermissions}
      oneperm
    >
      <Button variant="outline-info" size={"sm"} onClick={props.showResource}>
        Show By Resource
      </Button>
    </PermissibleRender>
  );
};

export function AlertMsg() {
  //  const [show, setShow] = useState(true);
  const { mystate, dispatch } = useContext(MyContext);

  //  const show = mystate.showAlert;
  /*  useEffect(
    () => {
      console.log("start");
      //  console.log(moment().toDate());
      setTimeout(() => {
        console.log("end");

        //  console.log(moment().toDate());
        dispatch({ type: "SHOW_ALERT_MESSAGE", showAlert: false });
      }, 3000);
    },
    //eslint-disable-next-line
    [mystate.alertTimeOut]
  );*/

  if (mystate.showAlert) {
    return (
      <Alert
        variant={mystate.alertVariant}
        onClose={() => dispatch({ type: "SHOW_ALERT_MESSAGE", show: false })}
        style={{ position: "absolute", top: "0" }}
      >
        <Alert.Heading>{mystate.alertMessage}</Alert.Heading>
        <p>{mystate.alertBody}</p>
      </Alert>
    );
  } else return null;
  //  return <Button onClick={() => setShow(true)}>Show Alert</Button>;
}
