import UserSettings from "./UserSettings";
import moment from "moment";
import { dateObjToDBDate } from "./momentUtils";
import AuthHelperMethods from "./AuthHelperMethods";
const Auth = new AuthHelperMethods();

const initialStateList = {
  showForm: false,
  showClients: false,

  showAssignToLists: false,
  currentProgramsForProduct: [],
  currentListDetail: {},
  formInitialValues: {},
  pageTitle: "",
  listTitle: "",
  formRightHeaderAdd: "",
  formRightHeaderEdit: "",
  showClientHomePage: false,
  searchByText: "",
  selectedListPage: "",
  currentStep: 0,
  filter: { is_active: 1 },
  filter_labels: [],
  filtercal: {},
  filter_labelscal: [],

  advanced_filter: {},

  selectedMainPage: "purchases",
  productPrograms: [],
  showAddEditForm: false,
  currentClient: "",
  currentStaff: {},

  currentCalendarClient: {},
  clientProfile: {},
  newClientIds: {},
  newClientAddress: {},
  newClientContact: {},
  currentHorse: {},
  horsedata: [],
  clientdata: [],
  quicklook: {},
  products: [],
  userClients: [],
  sectionHorses: [],
  sectionStudents: [],
  whiteboard_section_horses_array: [],
  whiteboard_horsecount: [],
  showContactModal: false,
  clients: [],
  locations: [],
  userPermissions: [],
  permissionList: [],
  currentStudentDetail: {},
  roles: [],
  rolePermissions: [],
  templates: [],
  members: [],
  accountHolder: {},
  currentStaff: {},
  selectedColor: "",
  horseList: [],
  daysheetClients: [],
  programList: [],
  clientPurchase: [],
  cartProducts: [],
  cartSubtotal: 0,
  cartFee: 0,
  cartTotal: 0,
  dialogShow: false,
  horses: [],
  confirmShow: false,
  currentProduct: {},
  clientPayment: {},
  quickPayRefresh: false,
  loggedInStaff: "",
  showClientOrAll: true,
  useMakeup: false,
  is_makeup: false,
  use_makeup: false,
  dev: process.env.NODE_ENV == "production" ? false : true,
  showfilter: true,
};

export default initialStateList;
