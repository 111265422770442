import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter, Route, Switch, Link, NavLink } from "react-router-dom";
import ReactDOM from "react-dom";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
//import "./styleFormik.css";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col, Modal } from "react-bootstrap";
import SelectList from "./selectList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PageTitle, Td, Th, HelpText, PrimaryTextList, PrimaryTextBlue, Container } from "./styleCustom";
import { DATEPICKER_DATE_DISPLAY } from "./UserSettings";
import {
  TableHeader,
  TableHeader2,
  TableHeaderNoAdd,
  AddEditForm,
  ActiveCheck,
  BackTo,
  ModalHeaderBlue,
  ButtonOrange,
  ButtonOrangeInverse,
  ButtonColor,
} from "./formComponents";
import moment from "moment";

import SearchFilter, { ModalFilter } from "./searchFilter";
import SearchFilterDate from "./searchFilterDates";
import { ListContext, MyContext } from "./App";
import { RemoveIcon, EditIconPlain, ClockIcon, Check } from "./icons";
import StaffForm from "./staffForm";

import { AddStaffForm } from "./clientForm";

import COLORS from "./styleConstants";
import HorseHomePage from "./horseHomePage";
import { StaffAvatar } from "./images";
import { active_options } from "./Constants2";
import { Active } from "./searchFilterReports";
const Auth = new AuthHelperMethods();

const Staff = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  const [active, setActive] = useState(true);
  const [showAddStaff, setShowAddStaff] = useState(false);
  const [showbyList, setShowByList] = useState(false);
  const [initComplete, setInitComplete] = useState(false);

  useEffect(
    () => {
      const fetchData = async () => {
        // let params = { is_active: active ? 1 : 0 };
        let params = { is_active: 1 };
        console.log("INITIAL");
        try {
          const result = await Auth.api("instructors/stafflist", "get", params);
          dispatch2(["STAFF_GET", result.data]);
          setInitComplete(true);
        } catch (error) {
          console.log("error");
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const fetchData = async () => {
        // let params = { is_active: active ? 1 : 0 };
        let params = { ...listState.filter };

        console.log("USEEFFECT STAFF");
        try {
          const result = await Auth.api("instructors/stafflist", "get", params);
          dispatch2(["STAFF_GET", result.data]);
          /* let accountHolder = listState.clients.find(
            (client) => client.id == result.data.person_id
          );*/
        } catch (error) {
          console.log("error");
        }
      };
      if (initComplete) fetchData();
    },
    //eslint-disable-next-line
    [listState.filter]
  );

  // useFetch("instructors/stafflist", "STAFF_GET", "dispatchlist", params);

  const StaffBox = (props) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",

          height: 300,
          width: 250,
          background: "white",
          borderRadius: 10,
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: 30,
          fontSize: 20,
          margin: 20,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flex: "1 1 100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              background: props.color || "lightgrey",
              minWidth: 250,
              minHeight: 60,
              marginBottom: 10,
            }}
          ></div>

          <div> {props.name}</div>
          <div>{"staff role"}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: 10 }}>
          <div>{props.email}</div>
          <div>{props.phone}</div>
        </div>
        <div>
          {" "}
          <Link to={`/admin/staffhome/${props.person_id}/details`}>
            <ButtonColor label={"view profile"} color={props.color || "lightgrey"} />
          </Link>
        </div>
      </div>
    );
  };

  const updateIsActive = async (staff) => {
    console.log(staff);
    let params = { ...listState.filter };
    // params.
    params.staffId = staff.instructor_id;
    params.accountHolderId = staff.person_id;
    params.whattodo = staff.is_active ? "inactivate" : "activate";
    try {
      dispatch({ type: "FETCH_INIT" });
      const result = await Auth.api("people/updateIsActiveAccount", "post", params);

      let params2 = { ...listState.filter };

      const result2 = await Auth.api("instructors/stafflist", "get", params2);
      dispatch2(["STAFF_GET", result2.data]);
      dispatch({ type: "FETCH_SUCCESS" });

      dispatch({
        type: "GET_INSTRUCTOR_OPTIONS",
        options: result.data.options,
      });
    } catch (e) {
      dispatch({ type: "FETCH_INIT" });
    }
  };

  return (
    <>
      <Modal show={showAddStaff} size="lg" onHide={() => setShowAddStaff(false)}>
        <ModalHeaderBlue closeButton>
          <Modal.Title>{"Add New Staff Account"}</Modal.Title>
        </ModalHeaderBlue>
        <Modal.Body>
          <AddStaffForm onSave={() => setShowAddStaff(false)} />
        </Modal.Body>
      </Modal>
      <Container style={{ flexWrap: "wrap", flex: "1 1 100%" }}>
        <TableHeaderNoAdd title={"Staff"}>
          {" "}
          <ButtonOrange onClick={() => setShowAddStaff(true)} label={"Add New"} />
        </TableHeaderNoAdd>

        {listState.staff &&
          1 == 2 &&
          listState.staff.map((staff) => (
            <StaffBox
              key={staff.instructor_id}
              name={staff.name}
              email={staff.contact_email}
              phone={staff.contact_mobile}
              color={staff.color_code}
              person_id={staff.person_id}
            />
          ))}
        <Active field={"is_active"} default={"1"} options={active_options} />

        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <Th />
              <Th>{"name"}</Th>

              <Th>{"email"}</Th>
              <Th>{"phone"}</Th>

              <Th>{"availability"}</Th>
              <Th>{"general notes"}</Th>
              <Th>{"has login account"}</Th>
              <Th>{"actions"}</Th>
            </tr>
          </thead>

          <tbody>
            {listState.staff &&
              listState.staff.map((staff) => (
                <tr key={staff.instructor_id}>
                  <Td style={{ background: staff.color_code }}></Td>

                  <Td>
                    {" "}
                    <Link to={`/admin/staffhome/${staff.person_id}/details`}>
                      <PrimaryTextBlue> {staff.name}</PrimaryTextBlue>
                    </Link>
                  </Td>
                  <Td>{staff.contact_email}</Td>
                  <Td>{staff.contact_mobile}</Td>

                  <Td>{staff.availability}</Td>
                  <Td>{staff.general_notes}</Td>
                  <Td>{staff.user_id && <Check />}</Td>

                  <Td onClick={() => updateIsActive(staff)} style={{ color: "red", cursor: "pointer" }}>{`${
                    staff.is_active ? "inactivate" : "activate"
                  }`}</Td>
                </tr>
              ))}
          </tbody>
        </table>
      </Container>
    </>
  );
};

export default Staff;
