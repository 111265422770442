import React, { useState, useContext, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import getRepeatFirstLast from "./getLastDate";
import SelectSectionDefaults from "./selectSectionDefaults";
import SelectInstructor from "./selectInstructor";
import SelectStudent from "./selectStudent";
import SelectHorse from "./selectHorse";
import SelectLocation from "./selectLocation";
import TextArea from "./textInputgeneric";
import TextInputTitle from "./textInputTitle";
import TimeAndDate from "./timeanddate";
import RepeatingDates from "./repeatingDates";
import { Button, Row, Col, Container, Modal } from "react-bootstrap";
import { MyContext, ListContext } from "./App";
import axios from "axios";
import {
  formatDateTimeForDB,
  dateObjToDisplayDate,
  dateObjToDisplayTime,
  dateObjToDBDate,
  getNewDefaultDateforMonth,
  calculateEndTime,
  repeatOptions,
  dateISOtoStringMed,
  timeISOtoStringN,
} from "./momentUtils";
import AuthHelperMethods from "./AuthHelperMethods";
import "./style.css";
import ReactTooltip from "react-tooltip";
import { useAlert } from "react-alert";
import "./styleAdd.css";
import { TitleRow } from "./blueCheckFuture";
import { ClientAvatar, HorseAvatar, StaffAvatar, LocationAvatar } from "./images";
import { NoteIcon, LocationIcon, RepeatIcon, ClockIcon, TitleIcon, ArrowDown, TimesIcon } from "./icons";
import moment from "moment";
import Select from "react-select";
import COLORS from "./styleConstants";
import { HelpText } from "./styleCustom";
import {
  TopName,
  ButtonOrange,
  ButtonBlue,
  OrangeButtonReg,
  ButtonOrangeMed,
  ButtonOrangeInverse,
  UserCan,
  OpenClose,
} from "./formComponents";

const Auth = new AuthHelperMethods();

const orangelabel = {};

//render(<AlertDismissibleExample />);

function AddModal(props) {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const [studentBooking, setStudentBooking] = useState(props.studentBooking);

  const showalert = useAlert();
  let history = useHistory();
  const [groupOptions, setGroupOptions] = useState([]);
  const [currentGroup, setCurrentGroup] = useState();
  const [staffInGroup, setStaffInGroup] = useState([]);
  const [currentTask, setCurrentTask] = useState(null);
  const [currentStaff, setCurrentStaff] = useState(null);
  const [showTask, setShowTask] = useState(false);
  const [staffSelectIsDisabled, setStaffSelectIsDisabled] = useState(true);

  const [taskStaff, setTaskStaff] = useState([]);
  const [taskStaffKey, setTaskStaffKey] = useState(2);
  const [inLesson, setInLesson] = useState([]);
  const isAStudent = Auth.getUserType() == "STU";
  let location = useLocation();
  let front = location.pathname.substring(0, location.pathname.lastIndexOf("/"));

  /// const [all, setAll] = useState([]);
  let all = [];
  let x = useParams();

  let params = {};

  useEffect(
    () => {
      dispatch({
        type: "FETCH_SUCCESS",
      });
      return () => {
        dispatch({ type: "SET_CURRENTLESSON_TO_NULL" });
      };
    },
    //eslint-disable-next-line
    []
  );

  useEffect(() => {
    //  let front = location.pathname.substring(0, location.pathname.lastIndexOf("/"));

    if (front.startsWith("/admin/clienthome/")) setStudentBooking(true);
    /*  dispatch({
      type: "SET_NEW_EVENT",
      data: { students: [{ value: listState.currentClient.student_id, label: listState.currentStudent.person_name }] },
    });*/
  });

  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("/groups/options", "get", {});
        setGroupOptions(result.data.options_group);
      };
      fetchData();
    },

    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (Auth.getUserType() == "STU") console.log("HEY GUYS IT'S A STUDENT");
    },

    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("/staff_groups/index", "get", { group_id: currentGroup.value });
        console.log(result.data.staff);
        setStaffInGroup(result.data.staff);
        params = { ...params, [currentGroup.label]: "" };
      };
      if (currentGroup) fetchData();
    },

    //eslint-disable-next-line
    [currentGroup]
  );

  useEffect(
    () => {
      let event = mystate.event;
      dispatch({
        type: "SET_RESOURCE_ID",
        id: event.resourceId,
      });

      let { start, end } = event;
      let resource = "";

      if (mystate.resource_map?.length > 0) resource = mystate.resourceAccessor;
      let isAllDay = mystate.view !== "month" ? start == end : false;
      const wholedate = mystate.view === "month" ? getNewDefaultDateforMonth(start) : start;
      const endtime = calculateEndTime(moment(wholedate).toDate(), mystate.sectionDefaults.duration || 60);

      let defulatsectionid =
        event.resourceId && mystate.resourceAccessor == "section_id"
          ? event.resourceId
          : mystate.sectionDefaults.section_id;

      dispatch({
        type: "INITIALIZE_NEW_EVENT",

        data: {
          start: moment(wholedate).toDate(),
          section_id: Auth.getUserType() == "STU" ? null : defulatsectionid,
          instructor_id:
            event.resourceId && mystate.resourceAccessor == "instructor_id"
              ? event.resourceId
              : mystate.sectionDefaults.instructor_id,
          //event.resourceId ? event.resourceId : mystate.sectionDefaults.instructor_id,
          location_id:
            event.resourceId && mystate.resourceAccessor == "location_id"
              ? event.resourceId
              : mystate.sectionDefaults.location_id,

          duration: mystate.sectionDefaults.duration,

          title: mystate.sectionDefaults.title,
          is_recuring: false,
          repeat_end_type: "",
          uniqid: null,
          end: endtime,
          students:
            listState.currentClient.student_id && listState.currentClient.person_name
              ? [{ value: listState.currentClient.student_id, label: listState.currentClient.person_name }]
              : [],
        },
      });

      dispatch({
        type: "SET_REPEAT_VALUES",
        data: {
          selectRepeatValue: repeatOptions[0],
        },
      });

      dispatch({
        type: "SET_DATE_TIME_COMPONENT",
        start: moment(wholedate).toDate(),
        end: endtime,
        is_allday: isAllDay,
      });
      dispatch({
        type: "FETCH_SUCCESS",
      });
    },
    //eslint-disable-next-line
    []
  );

  //  const alertb = useAlert();
  //  const alerta = alert.show("Some message");
  //const alert = alert.info("You are adding a lesson!", { timeout: 0 });
  const successmsg = () => {
    return `${mystate.addevent.is_recuring ? "Recurring " : ""}  Event ${
      mystate.addevent.title
    }  on ${dateObjToDisplayDate(mystate.dateTimeComponent.start)}
       at  ${dateObjToDisplayTime(mystate.dateTimeComponent.start)} has been saved successfully.`;
  };

  const handleCancel = () => {
    history.goBack();
    console.log("click handleCancel");
    //dispatch({ type: "HIDE_ADD_MODAL" });
  };

  //const handleShow = () =>

  //  ReactModal.setAppElement("body");

  const saveNewEvent = async () => {
    dispatch({
      type: "FETCH_INIT",
    });
    if (mystate.addevent.section_id == undefined) {
      showalert.show("section is required", { timeout: 15000, type: "warning" });
      dispatch({
        type: "FETCH_SUCCESS",
      });
      return null;
    }
    const { horses, students, otherStaff, start, end } = mystate.addevent;
    let studentsArray = [],
      horsesArray = [],
      otherStaffArray = [],
      taskStaffValues = [];

    if (students) {
      studentsArray = students.map((student) => {
        return student.value;
      });
    }
    if (horses) {
      horsesArray = horses.map((horses) => {
        return horses.value;
      });
    }
    // horsesArray = JSON.stringify(horses);
    // params.item_details = JSON.stringify(itemarray);

    if (otherStaff) {
      otherStaffArray = otherStaff.map((staff) => {
        return staff.value;
      });
    }

    if (taskStaff) {
      //taskStaffValues = taskStaff.map((el) => ({ task: el.task.value, staff: el.staff.value }));
      taskStaffValues = taskStaff.map((el) => [el.staff.value, el.task.value]); //! order is staff, task

      taskStaffValues = JSON.stringify(taskStaffValues);
    }

    const repeatObj = getRepeatFirstLast(mystate, {});
    const datetimeInfo = formatDateTimeForDB(mystate.dateTimeComponent);

    const dbParams = {
      ...mystate.addevent,
      ...repeatObj,
      ...datetimeInfo,
      students: studentsArray,
      horses: horsesArray,
      otherStaff: otherStaffArray,
      calendarDateRangeStart: dateObjToDBDate(mystate.calendarDateRangeStart),
      calendarDateRangeEnd: dateObjToDBDate(mystate.calendarDateRangeEnd),
      taskStaff: taskStaffValues,
      isStudentBooking: studentBooking,
    };
    // console.log(dbParams);

    if (studentBooking) {
      const msg = `${listState.currentClient.person_name} has been created an event for  ${
        mystate.addevent.title
      } on ${dateISOtoStringMed(mystate.addevent.start)} ${
        !mystate.addevent.is_allday ? "at " + timeISOtoStringN(mystate.addevent.start) : ""
      }  `;
      dbParams.msg = msg;
      dbParams.student_email = listState.accountHolder.contact_email;
      dbParams.account_name = listState.accountHolder.first_name;

      console.log(msg);
    }

    let route = mystate.addevent.is_recuring ? "lessons/createRecurringLesson" : "lessons/createSingleLesson";

    try {
      ///  dbParams = { ...dbParams, ...taskStaff };
      //let params = taskStaff;
      const result = await Auth.api(route, "post", dbParams);
      //const data= await result;
      console.log(mystate.addevent.is_recuring);

      //   dispatch({ type: "ADD_NEW_LESSON", payload: result.data });

      dispatch({
        type: "FETCH_SUCCESS",
      });

      //dispatch({ type: "HIDE_ADD_MODAL" });
      history.goBack();

      // showalert.show(successmsg(), { timeout: 5000, type: "success" }); //!!!disabled for testing - put back!

      Auth.getConfirm();
      return result;
    } catch (error) {
      dispatch({ type: "FETCH_ERROR" });
      showalert.show(error.message, { timeout: 5000, type: "error" });
    }
  };

  let location_id = Number(mystate.addevent.location_id);
  const reportStyles = {
    container: (styles) => ({
      ...styles,
      flex: 1,
      minWidth: 150,
      margin: 5,
    }),
  };

  const handleSelectTask = (selected) => {
    console.log(selected);
    setCurrentTask(selected);
  };

  const handleSelectStaff = (selected) => {
    console.log(selected);
    console.log(taskStaffKey);
    console.log(taskStaffKey + 1);
    if (selected.value == 0) selected.value = null;
    setCurrentStaff(selected);
  };

  const handleClickDone = () => {
    if (!currentTask) alert("Task can not be empty");
    else if (!currentStaff) alert("Staff can not be empty");
    else {
      setTaskStaff([...taskStaff, { id: taskStaffKey, task: currentTask, staff: currentStaff }]);
      //  setShowTask(false);
      setTaskStaffKey(taskStaffKey + 1);
      setCurrentTask(null);
      setCurrentStaff(null);
    }
  };

  const addNewTaskStaff = () => {
    setShowTask(true);
  };

  const deleteTask = (id) => {
    console.log(id);
    setTaskStaff(taskStaff.filter((el) => el.id != id));
  };
  const [value1, setValue1] = useState(null);
  const [value2, setValue2] = useState(null);
  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    margin: 5,
    minWidth: 300,
    maxWidth: 300,
  };
  return (
    <>
      {1 === 1 && (
        <div className="add" style={{ border: "solid #e6e6e6 15px", paddingBottom: 100 }}>
          <TitleRow clickevent={() => handleCancel()} title={"Add Event"} />
          {studentBooking && (
            <>
              <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                <div style={{ fontSize: 20, color: COLORS.orange }}>{`You are adding an event for `}</div>
                <div style={{ fontSize: 30, color: COLORS.orange, fontWeight: "bold" }}>
                  {listState.currentClient.person_name}
                </div>
              </div>
            </>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              flex: "1 1 100%",
              justifyContent: "space-around",
            }}
          >
            <div style={boxStyle}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="blocklabel">{"SECTION "}</div>

                <SelectSectionDefaults />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="blocklabel" style={orangelabel}>
                  <TitleIcon /> {"TITLE"}
                </div>

                <TextInputTitle addOrEdit={"add"} />
              </div>
            </div>

            <div style={boxStyle}>
              <div className="blocklabel" style={orangelabel}>
                <ClockIcon />
                {"DATE AND TIME "}
              </div>
              <TimeAndDate />
            </div>
            <div style={boxStyle}>
              <div className="blocklabel" style={orangelabel}>
                <RepeatIcon /> {"RECURRING "}
              </div>
              <RepeatingDates />
            </div>

            {!studentBooking && (
              <div style={boxStyle}>
                <div className="blocklabel" style={orangelabel}>
                  <ClientAvatar />
                  {"CLIENTS"}{" "}
                </div>

                <SelectStudent addOrEdit={"add"} />
              </div>
            )}
            <div style={boxStyle}>
              <div className="blocklabel" style={orangelabel}>
                <HorseAvatar /> {"HORSES"}
              </div>
              {mystate.options_horse && <SelectHorse add horse />}
            </div>

            <div style={boxStyle}>
              <div className="blocklabel" style={orangelabel}>
                <StaffAvatar /> {"STAFF"}
              </div>
              <SelectInstructor addOrEdit={"add"} />
            </div>

            <div style={boxStyle}>
              <div className="blocklabel" style={orangelabel}>
                <LocationIcon /> {"LOCATION "}
              </div>
              <SelectLocation />
            </div>

            <div style={boxStyle}>
              <div className="blocklabel" style={orangelabel}>
                <NoteIcon /> {"NOTES"}
              </div>

              <TextArea style={{ border: "solid blue" }} addOrEdit={"add"} />
            </div>

            <div style={boxStyle}>
              <div className="blocklabel" style={orangelabel}>
                {"Additional Staff/Volunteers"}
              </div>

              {taskStaff &&
                taskStaff.map((el, index) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontSize: "16px",
                    }}
                  >
                    <div
                      style={{ display: "flex", flexDirection: "row", fontSize: "16px", color: COLORS.blue }}
                      key={el.id}
                    >{`${el.task.label}: ${el.staff.label}`}</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        fontSize: 12,
                        color: COLORS.orange,
                        cursor: "pointer",
                        marginLeft: 30,
                        alignItems: "center",
                      }}
                      onClick={() => deleteTask(el.id)}
                    >
                      <TimesIcon fontsize={16} />
                    </div>
                  </div>
                ))}
              <ButtonOrange label={"Add additional staff or volunteers"} onClick={() => setShowTask(true)} />
            </div>

            {showTask && (
              <>
                <Modal show={showTask} onHide={() => setShowTask(false)}>
                  <Modal.Header closeButton style={{ background: COLORS.blue, color: "white" }}>
                    <Modal.Title>{"Add Additional Staff or Volunteers"}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {mystate.options_task && mystate.options_task.length == 0 && (
                      <>
                        {" "}
                        <div>You do not have any tasks set up yet. </div>
                        <div>To add tasks, go to Setup => Tasks => Add New Task </div>
                      </>
                    )}
                    {taskStaff &&
                      taskStaff.length > 0 &&
                      taskStaff.map((el, index) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            fontSize: "16px",
                            maxWidth: 350,
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "row", fontSize: "16px", color: COLORS.blue }}
                            key={el.id}
                          >{`${el.task.label}: ${el.staff.label}`}</div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              fontSize: 12,
                              color: COLORS.orange,
                              cursor: "pointer",
                              marginLeft: 30,
                              alignItems: "center",
                            }}
                            onClick={() => deleteTask(el.id)}
                          >
                            <TimesIcon fontsize={16} />
                          </div>
                        </div>
                      ))}
                    {mystate.options_task && mystate.options_task.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          borderx: `1px solid ${COLORS.blue}`,
                          padding: 10,
                          maxWidth: 600,
                          alignItems: "center",
                          justifyContent: "space-around",
                          marginTop: 20,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flex: "1 1 100%",
                            margin: 5,
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <HelpText>{"Select a task"}</HelpText>
                          <ArrowDown />
                          <Select
                            styles={reportStyles}
                            options={mystate.options_task}
                            onChange={(e) => handleSelectTask(e)}
                            clearable={true}
                            searchable={true}
                            placeholder={"Select Task..."}
                            value={currentTask}
                          />
                        </div>
                        <div
                          style={{ display: "flex", flex: "1 1 100%", flexDirection: "column", alignItems: "center" }}
                        >
                          <HelpText>{"Then select the person"}</HelpText>
                          <ArrowDown />
                          <Select
                            styles={reportStyles}
                            onChange={(selected) => handleSelectStaff(selected)}
                            options={mystate.options_instructor}
                            clearable={true}
                            searchable={true}
                            placeholder={"Select Staff..."}
                            value={currentStaff}
                          />
                        </div>
                        <div
                          style={{ display: "flex", flex: "1 1 100%", flexDirection: "column", alignItems: "center" }}
                        >
                          <HelpText>{"Then click Add to save"}</HelpText>
                          <ArrowDown />
                          <ButtonOrange label={"Add"} onClick={() => handleClickDone()} />
                        </div>{" "}
                      </div>
                    )}
                  </Modal.Body>

                  <Modal.Footer>
                    <div>
                      <ButtonBlue label={"Close"} onClick={() => setShowTask(false)} />

                      {1 == 2 && <ButtonOrange label={"Done"} onClick={() => setShowTask(false)} />}
                    </div>
                  </Modal.Footer>
                </Modal>
              </>
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "50px", marginBottom: "50px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {" "}
              <Button
                className="btn-close"
                size="md"
                onClick={() => {
                  handleCancel();
                }}
              >
                Cancel
              </Button>
              <div style={{ padding: 5 }}></div>
              <Button className="btn-save" size="md" onClick={() => saveNewEvent()}>
                Save Event
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default AddModal;
