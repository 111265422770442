import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ListContext, MyContext } from "../App";
import AuthHelperMethods from "../AuthHelperMethods";
import { ButtonOrange } from "../formComponents.js";
import { dateISOtoStringShort, todayDisplay } from "../momentUtils";
import COLORS from "../styleConstants";
import Payment from "../payment";
import CheckoutSavedPaymentForm from "../paymentSavedForm";
import ManualPurchase from "../manualPurchaseForm";
import { MemberLinks } from "../clientHomePage";
import PrivateRoute from "../privateRoute";
import SearchFilterDatesLocal from "../searchFilterDatesLocal";
import { Inv } from "./inv";
import { Modal } from "react-bootstrap";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Font, PDFViewer, pdf } from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import APIURL from "../Constants";

//const APIURL = "http://localhost:3001/";
const Auth = new AuthHelperMethods();
//const APIURL = process.env.REACT_APP_APIURL;
//const APIURL = process.env.NODE_ENV == "development" ? "http://localhost:3001/" : process.env.REACT_APP_APIURL;

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const LessonReportLink = ({ heading = "", item = {}, details = [], farm = {}, account = {} }) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  //const [largeFont, setLargeFont] = useState(false);
  if (!item) return null;

  return (
    <div>
      <PDFDownloadLink
        document={<PdfDisplay heading={heading} item={item} details={details} farm={farm} account={account} />}
        fileName="invoice.pdf"
      >
        {({ blob, url, loading, error }) => (loading ? "Loading document..." : "PDF ready - click to download")}
      </PDFDownloadLink>
    </div>
  );
};

const PdfView = ({ heading = "", item = {}, details = [], farm = {}, account = {}, show, onHide }) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  //const [largeFont, setLargeFont] = useState(false);
  if (!item) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        border: 2,
        height: "100vh",
        width: "80vw",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: COLORS.blue,
          padding: 20,
          alignItems: "center",
        }}
      >
        <div style={{ minHeight: 20, background: COLORS.blue, flex: "1 1 100%" }}>
          {" "}
          <ButtonOrange onClick={onHide} label={"Close"} />
        </div>
        <PDFViewer style={{ height: "100vh", width: "70vw" }}>
          <PdfDisplay heading={"INVOICE"} item={item} details={details} farm={farm} account={account} />
        </PDFViewer>
      </div>
    </div>
  );
};

const PdfDisplay = ({ heading, item, details = [], farm = {}, account = {} }) => {
  const [rowdetails, setRowdetails] = useState(details);

  useEffect(() => {
    setRowdetails(rowdetails.filter((detail) => detail.payment_id == item.id));
    console.log(rowdetails);
  }, []);
  console.log(rowdetails);

  const styles = StyleSheet.create({
    headingStyle: {
      display: "flex",
      flexWrap: "wrap",
      flex: " 1 1 100%",

      fontSize: "14",
      background: "lightgrey",
    },
    textStyle: { paddingBottom: 1 },
    boldStyle: { paddingBottom: 1, color: "black" },
    rowheader: { padding: 2 },
  });

  return (
    <Document>
      <Page
        style={{
          size: "A4",
          fontSize: "10pt",

          color: "#4D4D4D",
          fontFamily: "Helvetica",
          borderx: 1,
          borderColorx: "green",
          padding: 50,
        }}
        wrap={true}
      >
        <>
          <View style={{ borderx: 2, borderColorx: "pink" }}>
            <View>
              <Text style={styles.headingStyle}>{"INVOICE"}</Text>
              <Text style={styles.boldStyle}>{todayDisplay()}</Text>
            </View>
            <View style={{ marginTop: 20 }}>
              <Text style={styles.boldStyle}>{farm.farm_name}</Text>
              <Text style={styles.boldStyle}>{farm.address}</Text>
              <Text style={styles.boldStyle}>{farm.address2}</Text>
            </View>
            <View style={{ marginTop: 20 }}>
              <Text style={styles.boldStyle}>{account.person_name}</Text>
              <Text style={styles.boldStyle}>{account.contact_email}</Text>
            </View>
            <View
              className="wrapper"
              wrap={false}
              style={{
                display: "flex",
                flexDirection: "row",
                flex: "1 1 100%",
                flexWrap: "wrap",
                flexBasis: "100%",
                marginTop: 10,
                borderx: 2,
                borderColorx: "red",
              }}
            >
              <View
                className="rowheader"
                style={{
                  display: "flex",
                  flex: "1 1 100%",
                  flexBasis: "100%",
                  flexDirection: "row",
                  backgroundColor: "lightgrey",
                  padding: "5 5 5 5",
                  marginBottom: 5,
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    flexBasis: "20%",
                  }}
                >
                  <Text>student</Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    flexBasis: "40%",
                  }}
                >
                  <Text>item</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    flexBasis: "20%",
                  }}
                >
                  <Text>start date</Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    flexBasis: "20%",
                    justifyContent: "flex-end",
                  }}
                >
                  <Text>price</Text>
                </View>
              </View>
              {rowdetails.map((detail) => (
                <>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: 5,
                      paddingBottom: 2,
                      borderBottom: 0.5,
                      borderColor: "#ccc",
                    }}
                  >
                    <View
                      className="rowdetail1"
                      style={{
                        display: "flex",
                        flex: "1 0 100%",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          flexBasis: "20%",
                        }}
                      >
                        <Text>{detail.person_name}</Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          flexBasis: "40%",
                        }}
                      >
                        <Text>{detail.product_name}</Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          flexBasis: "20%",
                        }}
                      >
                        <Text>{dateISOtoStringShort(detail.package_start_date)}</Text>
                      </View>

                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          flexBasis: "20%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Text>{Number(detail.price).toFixed(2)}</Text>
                      </View>
                    </View>
                    <View
                      className="rowdetail2"
                      style={{
                        display: "flex",
                        flex: "1 1 100%",
                        flexDirection: "row",
                        color: "darkgrey",
                        whiteSpace: "pre-wrap",
                        paddingTop: 2,
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ fontStyle: "italic" }}>{detail.item_notes}</Text>
                    </View>
                  </View>
                </>
              ))}
              <View
                className="totals"
                style={{
                  display: "flex",
                  flex: "1 1 100%",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  borderx: 2,
                  borderColorx: "yellow",
                  flexBasis: "100%",
                  marginTop: 10,
                }}
              >
                <View style={{ width: 200, paddingx: 20, display: "flex", flex: "1 1 100%" }}>
                  <>
                    <View
                      className="rowheader"
                      style={{
                        backgroundColor: "lightgrey",
                        padding: "5 5 5 5",
                        flex: "1 1 100%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                        borderx: 1,
                        borderColorx: "purple",
                      }}
                    >
                      <Text>Invoice Summary</Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        flex: "1 1 100%",
                      }}
                    >
                      <Text>Total</Text>
                      <Text>{item.amount}</Text>
                    </View>
                  </>
                </View>
              </View>
              <View>
                <Text style={{ marginTop: "10" }}>{item.notes}</Text>
                <Text style={{ marginTop: "20" }}>{farm.invoice_notes1}</Text>
                <Text style={{ marginTop: "20" }}>{farm.invoice_notes2}</Text>
              </View>
            </View>
          </View>
        </>
      </Page>
    </Document>
  );
};

export const StudentPayments = ({ searchDates = undefined, setShowDateFilter, match, currentPage }) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);

  const [payments, setPayments] = useState([]);
  const [currentPayment, setCurrentPayment] = useState(undefined);
  const [showAllDetails, setShowAllDetails] = useState(false);
  const [allPackageDetails, setAllPackageDetails] = useState([]);
  const [purchaseList, setPurchaseList] = useState([]);

  const history = useHistory();
  const location = useLocation();
  const [showViewer, setShowViewer] = useState(false);
  const [currentDetails, setCurrentDetails] = useState(false);

  const onPaymentsPage = currentPage == "payments";
  const onInvoicePage = currentPage == "invoices";

  const [totals, setTotals] = useState(undefined);
  const [outstanding, setOutstanding] = useState(undefined);
  const [showModal, setShowModal] = useState(true);
  const [ready, setReady] = useState(false);
  const [currentPdf, setCurrentPdf] = useState();
  const [currentItem, setCurrentItem] = useState();
  const [x, setX] = useState();

  useEffect(() => {
    const fetchData = async () => {
      let params = {};
      params.student = listState.currentClient.student_id;
      const result = await Auth.api(`items/credits`, "get", params);
    };
    fetchData();
  }, [location]);

  useEffect(() => {
    //  if (currentPage == "payments") setShowDateFilter(false);

    console.log("searchdates have changed");
    const fetchData = async () => {
      setShowDateFilter(true);
      let params = {};
      params.billing_person_id = listState.currentClient.billing_person_id;
      params.report = "payment";
      params.clientView = true;
      params.limit = 20;
      if (onInvoicePage) params.include_invoices = true;
      if (onPaymentsPage) params.payments_only = true;
      if (searchDates) params = { ...params, ...searchDates };
      console.log(params);
      try {
        dispatch({ type: "FETCH_INIT" });

        const result = await Auth.api(`reports/financial`, "get", params);
        if (result) {
          dispatch({ type: "FETCH_SUCCESS" });
          setPayments(result.data.reports);
          result && setPurchaseList(result.data.reports);
          result && setTotals(result.data.totals.total);
          result && result.data.outstandingTotals && setOutstanding(result.data.outstandingTotals.outstanding);
        }
      } catch (error) {
        console.log(error.message);
        if (error) dispatch({ type: "FETCH_ERROR" });
      }
    };
    fetchData();
    console.log(location);
  }, [searchDates, location]);

  useEffect(
    () => {
      const fetchData = async () => {
        console.log("items/getAllItemsForPayment");

        dispatch({ type: "FETCH_INIT" });
        let params = { billing_person_id: listState.currentClient.billing_person_id };
        try {
          const result = await Auth.api("items/getAllItemsForPayment", "get", params);
          result && setAllPackageDetails(result.data.items);

          dispatch({ type: "FETCH_SUCCESS" });
        } catch {
          dispatch({ type: "FETCH_ERROR" });
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    [searchDates, location]
  );

  const generatePdfDocument = async ({
    heading = "inv",
    item = {},
    details = [],
    fileName = "inv.pdf",
    farm = {},
    account = {},
    id = "",
  }) => {
    const blob = await pdf(
      <PdfDisplay heading={heading} item={item} details={details} farm={farm} account={account} />
    ).toBlob();

    var reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onloadend = async function () {
      var base64String = reader.result;
      var x = base64String.split(",")[1].trim();

      const formData = new FormData();
      formData.append("name", id);
      formData.append("file", x);
      formData.append("id", id);
      formData.append("email", listState.accountHolder.contact_email);

      try {
        fetch(`${APIURL}payments/sendInvoice`, {
          method: "POST",
          body: formData,
          headers: { Authorization: `Bearer  ${Auth.getToken()}` },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data.id);
            dispatch({
              type: "FETCH_SUCCESS",
            });
            alert("invoice emailed");
          });
      } catch (error) {
        alert("error with emailing invoice");
      }
    };
  };

  async function handleClickManualPayment(payment) {
    var total = parseFloat(payment.amount);
    dispatch2(["ADD_CART_FEE_TO_INVOICE_PAYMENT", total, 0, total]);
    dispatch2(["SET_INVOICE_PAYMENT", { is_invoice: "invoice", payment_id: payment.id }]);

    try {
      dispatch({
        type: "FETCH_INIT",
      });

      history.push(`${match.url}/manualPurchase`);
    } catch (e) {
      console.log(e.message);
      // console.log("[error]", result.error);
      alert("Sorry - there has been an error with your transaction.");
      dispatch({
        type: "FETCH_ERROR",
      });
    }
  }

  async function handleClickPayNow(payment) {
    var feeRate = parseFloat(mystate.farmDefaults.stripe_convenience_fee);
    var totalBeforeFee = parseFloat(payment.amount);
    var fee = parseFloat(payment.amount) * parseFloat(mystate.farmDefaults.stripe_convenience_fee);
    var totalWithFee = parseFloat(totalBeforeFee + fee);

    dispatch2(["ADD_CART_FEE_TO_INVOICE_PAYMENT", totalBeforeFee, fee, totalWithFee]);
    setCurrentPayment({ ...payment, is_invoice_payment: true });
    dispatch2(["SET_INVOICE_PAYMENT", { is_invoice: "invoice", payment_id: payment.id }]);

    try {
      dispatch({
        type: "FETCH_INIT",
      });
      let params = {
        total: parseInt(totalWithFee * 100),
        account_holder: listState.accountHolder.people_bill_person_name,
        student: listState.currentClient.person_name,
        account_id: listState.accountHolder.id,
        person_id: listState.accountHolder.id,
      };
      const result = await Auth.api("payments/createPaymentIntentAndCustomer/", "post", params);
      dispatch2(["SET_PAYMENT_INTENT", result.data.payment_intent]);
      dispatch2(["SET_PAYMENT_METHODS", result.data.payment_methods]);
      //setShowP(true);
      console.log("This is an online invoice payment");

      if (result.data.payment_methods.length == 0) history.push(`${match.url}/payments`);
      if (result.data.payment_methods.length > 0) history.push(`${match.url}/paymentsSaved`);
    } catch (e) {
      console.log(e.message);
      // console.log("[error]", result.error);
      alert("Sorry - there has been an error with your transaction.");
      dispatch({
        type: "FETCH_ERROR",
      });
    }
  }

  function handleView(item) {
    // alert(item.id);

    console.log(allPackageDetails[0]);
    console.log(allPackageDetails.filter((p) => p.payment == item.id));
    setCurrentPdf(item);
    setCurrentDetails(allPackageDetails.filter((p) => p.payment_id == item.id));
    setShowViewer(true);
  }

  function handleDownload(item) {
    setCurrentPdf(item);
    setReady(true);
    setCurrentDetails(allPackageDetails.filter((p) => p.payment_id == item.id));
  }

  function handleEmail(item) {
    dispatch({
      type: "FETCH_INIT",
    });
    let details = allPackageDetails.filter((p) => p.payment_id == item.id);

    generatePdfDocument({
      heading: "inv",
      item: item,
      details: details,
      fileName: "inv.pdf",
      farm: mystate.farmDefaults,
      account: listState.accountHolder,
      id: item.invoice_number,
    });
  }
  const modalLong = { maxWidth: "100vw", minHeight: "100vh", minWidth: "100vw" };

  return (
    <>
      {1 == 2 && (
        <Modal show={true} className="modal-dialog mw-100 w-75 ">
          <div style={{ minHeight: "100vh", minWidth: "100vw", background: "white" }}>INVOICE</div>
        </Modal>
      )}
      <div className="noprint">
        <MemberLinks verb={"View"} page={capitalizeFirstLetter(currentPage)} link={currentPage} />
        <ButtonOrange
          label={showAllDetails ? "hide details" : "show detail"}
          onClick={() => setShowAllDetails(!showAllDetails)}
        />
        {outstanding && onInvoicePage && (
          <div className="purchaseListOutstanding" style={{ color: "red", marginBottom: 20, marginTop: 20 }}>
            <span>TOTAL INVOICES OUTSTANDING:</span>
            <span style={{ marginLeft: 10 }}>{outstanding}</span>
          </div>
        )}
        <div style={{ display: "flex", flexDirection: "column", width: "100%", flexWrap: "wrap" }}>
          <div className="purchaseListBody">
            {purchaseList &&
              purchaseList.map((item) => (
                <>
                  <div
                    key={item.id}
                    className={"rowMain"}
                    style={{ marginBottom: 20, borderBottom: "solid 1pt lightgrey" }}
                  >
                    <div
                      className="row1"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ width: 100 }}>{`$${item.amount}`}</div>
                      <div style={{ width: 100 }}>{dateISOtoStringShort(item.received)}</div>
                      <div style={{ width: 75 }}>{item.bank}</div>
                      <div style={{ width: 200, display: "flex", flexWrap: "wrap" }}>{item.reference}</div>

                      {onInvoicePage && (
                        <div style={{ color: COLORS.orange, display: "flex", flexDirecton: "row" }}>
                          <div style={{ cursor: "pointer", width: 75 }} onClick={() => handleView(item)}>
                            view
                          </div>
                          <div style={{ cursor: "pointer", width: 75 }} onClick={() => handleEmail(item)}>
                            email
                          </div>

                          <div onClick={() => handleDownload(item)} style={{ width: 75, cursor: "pointer" }}>
                            {"download"}
                          </div>
                        </div>
                      )}
                      {ready && item.id == currentPdf.id && (
                        <div onClick={() => setReady(false)}>
                          <LessonReportLink
                            heading={"INVOICE"}
                            item={item}
                            details={currentDetails}
                            farm={mystate.farmDefaults}
                            account={listState.accountHolder}
                          />
                        </div>
                      )}
                      {showViewer && item.id == currentPdf.id && (
                        <PdfView
                          onClick={() => setReady(false)}
                          heading={"INVOICE"}
                          item={item}
                          details={currentDetails}
                          onHide={() => setShowViewer(false)}
                          farm={mystate.farmDefaults}
                          account={listState.accountHolder}
                        />
                      )}
                    </div>

                    <div
                      className="uppaid actions before notes"
                      style={{ display: "flex", flexDirection: "row", color: COLORS.orange }}
                    >
                      {onInvoicePage && item.paid == true && <div> paid</div>}
                      {onInvoicePage && !item.paid == true && (
                        <div style={{ cursor: "pointer", width: 100 }} onClick={() => handleClickPayNow(item)}>
                          pay by card
                        </div>
                      )}
                      {onInvoicePage && !item.paid == true && Auth.getUserType() != "STU" && (
                        <div style={{ cursor: "pointer", width: 100 }} onClick={() => handleClickManualPayment(item)}>
                          pay manually
                        </div>
                      )}
                    </div>

                    <div style={{ display: "flex", flex: "1 1 100%", flexWrap: "wrap" }}>
                      {item.invoice == true && (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            flex: "1 1 100%",
                            fontStyle: "italic",
                            color: "#737373",
                            whiteSpace: "pre-wrap",
                          }}
                        >{`payment of invoice ${item.invoice_number || ""} `}</div>
                      )}

                      {item.notes && (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            flex: "1 1 100%",
                            fontStyle: "italic",
                            color: "#737373",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {item.notes}
                        </div>
                      )}
                    </div>
                  </div>

                  {showAllDetails &&
                    allPackageDetails &&
                    allPackageDetails.filter((piece) => piece.payment_id == item.id).length > 0 && (
                      <div
                        className="packageDiv"
                        style={{
                          margin: 5,
                          borderx: `solid 2pt ${COLORS.lightgrey}`,
                          padding: 5,
                          display: "flex",
                          flex: "1 1 100%",
                          flexWrap: "wrap",
                          marginBottom: 30,
                          background: `${COLORS.lightgrey}`,
                        }}
                      >
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              flexDirection: "row",
                              flex: "1 1 100%",
                              fontColor: "black",
                              fontWeight: "bold",
                            }}
                          >
                            <div style={{ width: 200 }}>{"student"}</div>
                            <div style={{ width: 200 }}>{"package"}</div>
                            <div style={{ width: 100 }}>{"amount"}</div>
                            <div style={{ width: 100 }}>{"starts"}</div>
                            <div style={{ width: 100 }}>{"expires"}</div>
                          </div>
                          {allPackageDetails
                            .filter((piece) => piece.payment_id == item.id)
                            .map((row) => (
                              <View style={{ borderBottom: 1 }}>
                                <div
                                  className="packageRow"
                                  style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", flex: "1 1 100%" }}
                                >
                                  <div style={{ width: 200 }}>{row.person_name}</div>
                                  <div style={{ width: 200 }}>{row.product_name}</div>
                                  <div style={{ width: 100 }}>{Number(row.price).toFixed(2)}</div>
                                  <div style={{ width: 100 }}>{dateISOtoStringShort(row.package_start_date)}</div>
                                  <div style={{ width: 100 }}>{dateISOtoStringShort(row.package_expiry_date)}</div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    flexDirection: "row",
                                    flex: "1 1 100%",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  <div> {row.item_notes}</div>
                                </div>
                              </View>
                            ))}
                          <div>{item.fee > 0 && `fee: ${item.fee}`}</div>{" "}
                          <div>{item.tax > 0 && `tax: ${item.tax}`}</div>
                        </>
                      </div>
                    )}
                </>
              ))}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flex: "1 1 100%",
                minHeight: 500,
              }}
            >
              <PrivateRoute exact path={`${match.url}/payments`} component={Payment} currentPage={currentPage} />
              <PrivateRoute
                exact
                path={`${match.url}/manualPurchase`}
                component={ManualPurchase}
                currentPage={currentPage}
              />

              <PrivateRoute
                exact
                path={`${match.url}/paymentsSaved`}
                component={CheckoutSavedPaymentForm}
                currentPage={currentPage}
              />
              {1 == 1 && <PrivateRoute path={`${match.url}/inv`} component={Inv} data={"data"} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
