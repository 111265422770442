import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link, useParams, useHistory, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import AuthHelperMethods from "./AuthHelperMethods";
import { ListContext, MyContext } from "./App";
import moment from "moment";
import COLORS from "./styleConstants";
import { BlueText } from "./styleCustom";
import useSWR from "swr";
import { Calendar, momentLocalizer } from "react-big-calendar";
import AddModal from "./addModal.js";
import {
  nowToDB,
  combineDateAndTimeStrToDateObj,
  combineDateAndTimeStrToMomentObj,
  combineDateAndTimeStrToMomentObjNoUtc,
} from "./momentUtils";

import { SelectTemplateFilter } from "./searchFilterReports";

import { Filter, FilterLabels, SelectFilter, FilterActive } from "./calendarFilter";
import { resourceAccessor, eventStyleGetter, dayPropGetter } from "./calendarConstants";
import {
  CustomEvent,
  BlueResourceButtons,
  BulkButtons,
  ToggleStudentEventName,
  ToggleShowClientShowAll,
  ToggleShowFilter,
} from "./calendarComponents";
import {
  formatEventsFromDB,
  calculateDateRange,
  calulateInitialCalendarDateRange,
  calulateInitialAgendaDates,
} from "./calendarFunctions";
import { useAlert } from "react-alert";
import BookingCalendar from "./bookingCalendar";
const localizer = momentLocalizer(moment);
const Auth = new AuthHelperMethods();

export const CalendarHomePageBooking = ({
  match,
  handleexisting,
  studentBooking,
  currentStudentId,
  currentHorseId,
  url,
  canadd,
}) => {
  //console.log(props);
  //const [data, setData] = useState([]);
  const alert = useAlert();
  const usertype = Auth.getUserType();

  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);
  const [showFilter, setShowFilter] = useState(isMobile ? false : false);
  const [showBookLesson, setShowBookLesson] = useState(false);
  const [makeups, setMakeups] = useState([]);
  const [formattedData, setFormattedData] = useState([]);

  let incoming = useParams();
  console.log(incoming);

  const isBookingMakeup = incoming.is_makeup ? true : false;

  console.log(isBookingMakeup);

  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    console.log("INITAL USEEFFECT");
    dispatch({
      type: "SET_CALENDAR_DATE",
      data: moment().toDate(),
    });
    let [date1, date2] = calulateInitialAgendaDates("month");
    console.log(date1, date2);

    let initialfilter = {
      currentStudentId: listState.currentClient.student_id,
      date1: date1,
      date2: date2,
      page: "calendar",
      view: "agenda",
      available: "available",
    };
    console.log(initialfilter);

    dispatch2(["SET_SEARCH_FILTER_OBJECT", initialfilter]);
    // dispatch2(["SET_SHOW_CLIENT_OR_ALL", true]);

    // if (listState.filter.page != "calendar") dispatch2(["SET_SEARCH_FILTER", "calendar", "page"]);
  }, []);

  const { data, error, isLagging, resetLaggy, isValidating } = useSWR(
    listState.filter.date1 &&
      listState.filter.date2 &&
      listState.filter.page == "calendar" &&
      listState.currentClient.student_id && [`lessons/index`, listState.filter]
  );
  useEffect(() => {
    if (isValidating) dispatch({ type: "FETCH_INIT" });
    else {
      dispatch({ type: "FETCH_SUCCESS" });
      const formattedData = formatEventsFromDB(data);
      const filterBufferHours = formattedData.filter((lesson) => lesson.start > moment().add(data.buffer, "hours"));
      setFormattedData(usertype == "STU" ? filterBufferHours : formattedData);
    }
  }, [isValidating]);

  function handleRangeChange(e) {
    var [currentStart, currentEnd, view] = calculateDateRange(e);
    // dispatch2(["SET_SEARCH_FILTER", view, "view"]);
    dispatch2(["SET_BOTH_SEARCH_FILTER_DATES", currentStart, currentEnd, "calendar"]);
    dispatch({
      type: "SET_CALENDAR_DATE_RANGE",
      start: currentStart,
      end: currentEnd,
    });
    console.log("set view from range");
    /* dispatch({
      type: "SET_VIEW",
      data: view,
    });
    */
  }

  function handleNavigate(date) {
    dispatch({
      type: "SET_CALENDAR_DATE",
      data: date,
    });
  }
  function handleViewChange(e) {
    console.log(e);
    dispatch({
      type: "SET_VIEW",
      data: e,
    });
    dispatch2(["SET_SEARCH_FILTER", e, "view"]);
  }

  const AgendaEvent = (event) => {
    const { mystate, dispatch } = useContext(MyContext);
    const { listState, dispatch2 } = useContext(ListContext);
    // console.log(event);
    event = event.event;
    /*let currentevent = event.event;
    let popoverClickRootClose = (
      <Popover id="popover-trigger-hover-root-close" style={{ zIndex: 10000, padding: 10 }}>
        <Info currentevent={event} />
      </Popover>
    );  
*/
    let onClickAgenda = () => {
      let url = `${match.url}/bookingCalendar`;
      dispatch({
        type: "SET_EVENT",
        event: event,
      });
      dispatch2(["SET_CURRENT_BOOKING_LESSON", event]);
      console.log("onClickAgenda");
      console.log(url);

      history.push(url);
      //handleExistingEvent(event);
      /*dispatch({
        type: "SET_EVENT",
        event: event,
      });
      history.push(`${match.url}/addStudentToEventFromCalendar`);*/
      //handleExistingEvent();
    };

    let eventtime = event.is_allday ? "" : event.time;
    let firststring = `${eventtime}  ${listState.showByStudentName ? event.studentString : event.title}`;
    let firststringnotime = `${listState.showByStudentName ? event.studentString : event.title}`;
    let bottomstring = listState.showByStudentName ? event.title : event.studentString;

    return (
      <div
        onClick={(e) => onClickAgenda(e)}
        style={{
          minHeight: 15,
          cursor: "pointer",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <span>{`${firststringnotime}  ${event.studentString}`}</span>
        <span>
          {event.available > 1 && `${event.available} available spots `}
          {event.available == 1 && `${event.available} available spot `}
        </span>
      </div>
    );
  };

  let formats = {
    agendaHeaderFormat: ({ start, end }) => {
      return moment(start).format("dddd MMMM Do") + " - " + moment(end).format("dddd MMMM Do");
    },
  };

  return (
    <>
      <div
        className="reportTable"
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          flex: "1 1 ",
        }}
      >
        <div
          className={"table"}
          style={{
            marginTop: 20,
            display: "flex",
            flex: "1 1 100%",
            flexDirection: "column",
          }}
        >
          <div className="filtertop">
            <div style={{ marginBottom: 10 }}>
              <ToggleStudentEventName />
            </div>
            <div style={{ xmaxWidth: 700, marginBottom: 20 }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {Auth.getUserType() != "STU" && <SelectFilter staff program_type program location section />}
                {Auth.getUserType() == "STU" && <SelectFilter staff />}
              </div>
              <FilterLabels hideClearAllButton={true} />
              <div
                onClick={() => dispatch2(["RESET_SEARCH_FILTER_FOR_CLIENT_BOOKING_KEEP_AVAILABLE"])}
                style={{
                  color: COLORS.orange,
                  cursor: "pointer",
                  marginLeft: 20,
                }}
              >
                clear all filters
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", fontSize: 18, color: COLORS.orange }}
          >{`Showing available lessons for ${listState.currentClient.person_name}. To book lesson, click on the event.`}</div>
          <div style={{ fontStyle: "italic", justifyContent: "center", display: "flex", marginBottom: 5 }}>
            ** lessons that are full are not displayed **
          </div>
          <div style={{ minHeight: 700 }}>
            <Calendar
              events={formattedData}
              localizer={localizer}
              defaultView={"agenda"}
              selectable
              min={mystate.farmDefaults.min_time}
              max={mystate.farmDefaults.max_time}
              date={mystate.calendarDate}
              eventPropGetter={eventStyleGetter}
              dayPropGetter={dayPropGetter}
              components={{ event: CustomEvent, agenda: { event: AgendaEvent } }}
              step={mystate.farmDefaults.calendar_interval || 60}
              popup
              views={["agenda"]}
              formats={formats}
              length={30}
              onRangeChange={handleRangeChange}
              onView={handleViewChange}
              onNavigate={handleNavigate}
            />
          </div>
          <PrivateRoute path={`${match.url}/bookingCalendar`} component={BookingCalendar} />

          <PrivateRoute path={`/admin/clienthome/${listState.currentClient.id}/add/:add`} component={AddModal} />
        </div>
      </div>
    </>
  );
};
