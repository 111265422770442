import React, { useContext, useEffect, useState } from "react";

export function Inv(props) {
  //alert(data);
  const state = props.location.state;
  return (
    <>
      <div>This will be the invoice header</div>
      <div>{0}</div>
      <style>
        {`@media print {
       .noprint{display:none
      }
    }
    `}
      </style>
    </>
  );
}
