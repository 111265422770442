import React, { useState, useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ListContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import { BlueInvLink, Row2, BlueNavLinkSm, PageTitle } from "./styleCustom";
import Sections from "./sections";
import COLORS from "./styleConstants";
import Products from "./products";
import AssignPackage from "./assignPackage";
import { ClientNotesReview, HorseNotesReview } from "./notesAdmin";
import Programs from "./programs";
import { FarmUpdatePrefs } from "./farmUpdatePrefs";
import PrivateRoute from "./privateRoute";
import Locations from "./locations";
import Roles from "./roles";
import Registration from "./registration";
import { MyContext } from "./App";
import { ButtonOrange, UserCan } from "./formComponents";
import SupportMessage from "./support";
import "./help.css";

const Help = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const [showB, setShowB] = useState(false);
  const [showA, setShowA] = useState(false);
  const [source, setSource] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeVideo = (src) => {
    setSource(src);
    // setShowA(true);
  };

  const linkStyle = {
    display: "flex",
    fontSize: 16,
    marginBottom: 15,
    color: COLORS.blue,
    cursor: "pointer",
    flexWrap: "wrap",
  };

  return (
    <>
      <div style={{ display: "flex", minWidth: "100%" }}>
        {" "}
        <UserCan isThis={"ADM"}>
          <SupportMessage />
        </UserCan>
      </div>
      {1 == 2 && <ButtonOrange onClick={() => setShowA(false)} label={"Close"} />}
      <div style={{ display: "flex", justifyContent: "space-around", alignItems: "flex-start" }}>
        <div style={{ display: "flex", flexDirection: "column", marginRight: 50, flex: 1 }}>
          <PageTitle>{"Calendar"}</PageTitle>
          <div style={linkStyle} onClick={() => changeVideo("3b8118d34d48f1ddb0bc6e1ad461d79f")}>
            {"Add a new event to the calendar"}
          </div>
          <div style={linkStyle} onClick={() => changeVideo("45ea43465b3260bc6bdb58b12a5929d6")}>
            {"Add an new event to the calendar - Part 2"}
          </div>
          <div style={linkStyle} onClick={() => changeVideo("29d0e42e6328613c42290bbbab33275a")}>
            {"Add a student to an existing event on the calendar"}
          </div>
          <div style={linkStyle} onClick={() => changeVideo("71a2b1d5698dbdb7adc9a224e51a93aa")}>
            {"Add a student to a recurring event on the calendar"}
          </div>
          <div style={linkStyle} onClick={() => changeVideo("985422a920869a41e79e3d80e532ae92")}>
            {"Delete a student from an event"}
          </div>
          <div style={linkStyle} onClick={() => changeVideo("a0bdc3543d5b5ad29a08fbaa9e2d5b2d")}>
            {"Add horses to and event"}
          </div>
          <div style={linkStyle} onClick={() => changeVideo("c72668b24613ae72ebe2b235f6055694")}>
            {"Assign a horse to a student"}
          </div>
          <div style={linkStyle} onClick={() => changeVideo("04cecdaf606e652031b0f3c7f91ff79e")}>
            {"Cancel a single lesson"}
          </div>
          <div style={linkStyle} onClick={() => changeVideo("30cfd1e67c4fd184af4104a06cd5bf58")}>
            {"Cancel all lessons in a day"}
          </div>

          <div style={linkStyle} onClick={() => changeVideo("160f88cd9225cdb239fa25cb7688009c")}>
            {"Add a student to an event from the student's page"}
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column", marginRight: 50, flex: 1 }}>
          <PageTitle>{"Setup"}</PageTitle>

          <div style={linkStyle} onClick={() => changeVideo("9198b85b1caa1283401b3d992c58a509")}>
            {"Adding Students and Account Holders to the system"}
          </div>
          <div style={linkStyle} onClick={() => changeVideo("7767d3e42c2bd3757f8561a8c5db0c8b")}>
            {"Adding Horses to the system"}
          </div>
          <div style={linkStyle} onClick={() => changeVideo("debdf83733851ce97a250784ef2d0287")}>
            {"Adding Staff to the system"}
          </div>
          <div style={linkStyle} onClick={() => changeVideo("9c0785c1a9ca5a05363112a4766c662a")}>
            {"Programs and Sections expained"}
          </div>
          <div style={linkStyle} onClick={() => changeVideo("1e1065affb6975027e0c493176f45009")}>
            {"Adding a horse to a Section"}
          </div>
          <div style={linkStyle} onClick={() => changeVideo("7b4e831a1153a2a3219c8409065c177e")}>
            {"Adding a student to a Section"}
          </div>

          <div style={linkStyle} onClick={() => changeVideo("aeea9c20a25534f6080e84e66b3b62fd")}>
            {"Adding Lesson Packages to the system"}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", marginRight: 50, flex: 1 }}>
          <PageTitle>{"Packages"}</PageTitle>
          <div style={linkStyle} onClick={() => changeVideo("c7830f16c20b052b08c14febf6762900")}>
            {"Adding a Lesson Package and Payment for a Student"}
          </div>

          <div style={linkStyle} onClick={() => changeVideo("099c77c133cab1cb531ac5f7105292b2")}>
            {"Adding Lesson Packages for a Student without tracking payment"}
          </div>

          <div style={linkStyle} onClick={() => changeVideo("499b4ff79ee4f7ed5ea1961e30e9249f")}>
            {"Tracking Lessons in a Package"}
          </div>
        </div>
      </div>

      <div>
        {source && (
          <iframe
            src={`https://watch.videodelivery.net/${source}`}
            style={{ border: "none" }}
            height="720"
            width="1280"
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowfullscreen="true"
          ></iframe>
        )}
      </div>
    </>
  );
};

export default Help;
