import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, NavLink } from "react-router-dom";
import { useParams, useHistory } from "react-router";
import { Formik, Form, useField, useFormikContext, Field } from "formik";
import * as Yup from "yup";
import {
  MediumPageHeader,
  BoxWithLabelW,
  BoxWithLabel200,
  BlockLabelGrow,
  BlockLabelHorizontal,
  HelpText,
  BlockLabel,
} from "./styleCustom";
import {
  MyTextInput,
  CheckBox,
  AddEditForm,
  OrangeButton,
  OrangeBlueButton,
  BlueLinkButton,
  BlueButton,
  MyCheckbox,
} from "./formComponents";
import COLORS from "./styleConstants";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col } from "react-bootstrap";
import { ListContext, MyContext } from "./App";
import { AddIconNP } from "./icons";
import ToggleSwitch from "./ToggleSwitch";
import { useAlert } from "react-alert";
import "./cssForm.css";
import Select from "react-select";

// AlertTemplate from "react-alert-template-basic";
//import AlertTemplate from "./alert_template";

const Auth = new AuthHelperMethods();

const ProductForm = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  const [online, SetOnline] = useState(listState.formInitialValues.purchase_online || false);
  const [isTrackedx, setIsTracked] = useState(listState.formInitialValues.is_tracked || false);
  const [isRenewable, setIsRenewable] = useState({ label: "not recurring", value: "no" });

  const alert = useAlert();
  const options = [
    { label: "package does not auto renew", value: "no" },
    { label: "on same day of month as initial purchase date", value: 0 },
    { label: "on the last day of the month", value: 31 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
    { label: "13", value: 13 },
    { label: "14", value: 14 },
    { label: "15", value: 15 },
    { label: "16", value: 16 },
    { label: "17", value: 17 },
    { label: "18", value: 18 },
    { label: "19", value: 19 },
    { label: "20", value: 20 },
    { label: "21", value: 21 },
    { label: "22", value: 22 },
    { label: "23", value: 23 },
    { label: "24", value: 24 },
    { label: "25", value: 25 },
    { label: "26", value: 26 },
    { label: "27", value: 27 },
    { label: "28", value: 28 },
    { label: "29", value: 29 },
    { label: "30", value: 30 },
    { label: "31", value: 31 },
  ];
  useEffect(
    () => {
      window.scrollTo(0, 0);
      //   return () => {
      //     dispatch2(["SET_FORM_INITIAL_VALUES", {}]);
      //    };

      //  }
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        const result = await Auth.api("options/index", "get", params);
        dispatch({
          type: "UPDATE_OPTIONS",
          options: result.data,
        });
      };
      fetchData();
    },
    //eslint-disable-next-line
    [listState.products]
  );

  console.log("rerender? ");
  console.log(listState.formInitialValues.purchase_online);
  console.log(online);
  let { addoredit } = useParams();
  console.log(addoredit);
  const iv = {
    name: "",
    desc: "",
    price: "",
    count: "1",
    expires_in: "180",
    purchase_online: false,
    is_tracked: true,
    renew_day_of_month: "no",
  };
  const clickToggle = (event) => {
    console.log(event.target.checked);
    SetOnline(event.target.checked);
    console.log("clickToggle");
  };
  let initialValues = addoredit === "add" ? iv : listState.formInitialValues;
  let history = useHistory();

  function goBackToList() {
    console.log("click");
    history.goBack();
  }

  const SelectField: React.SFC<ReactSelectProps & FieldProps> = ({ options, field, form }) => (
    <Select
      options={options}
      name={field.name}
      value={options ? options.find((option) => option.value === field.value) : ""}
      onChange={(option: Option) => form.setFieldValue(field.name, option.value)}
      placeholder={"Select"}
      onBlur={field.onBlur}
    />
  );

  return (
    <>
      <AddEditForm>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("A package name is required"),
            desc: Yup.string(),
            price: Yup.number().required("Price is required"),
            is_tracked: Yup.boolean(),
            count: Yup.number()
              .integer("Must not have a decimal")
              .min(0, "Can not be a negative number")
              .required("A count is required"),
            purchase_online: Yup.boolean(),
            expires_in: Yup.number().integer("Must not have a decimal").required(),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            console.log(values.renew_day_of_month);
            if (values.renew_day_of_month == "no") values.renew_day_of_month = null;
            console.log(values.renew_day_of_month);

            values = {
              ...values,
            };
            if (addoredit === "add") {
              try {
                const result = await Auth.api("products/create", "post", values);
                alert.show(`${result.data.products.product_name} saved`, {
                  timeout: 5000,
                  type: "success",
                });

                dispatch2(["PRODUCT_CREATE", result.data]);
                /* dispatch({
                  type: "UPDATE_PRODUCT_OPTIONS",
                  options: result.data.options,
                });*/

                setSubmitting(false);
                goBackToList();
              } catch (e) {
                console.log(e);
              }
            }

            if (addoredit === "edit") {
              console.log(addoredit);

              try {
                const result = await Auth.api("products/update", "post", values);
                alert.show(`changes to ${result.data.product.product_name} saved`, {
                  timeout: 5000,
                  type: "success",
                });
                //  console.log(result.data.product);
                dispatch2(["PRODUCT_EDIT", result.data]);
                dispatch({
                  type: "UPDATE_PRODUCT_OPTIONS",
                  options: result.data.options,
                });
                goBackToList();
              } catch (e) {
                console.log(e);
              }
            }
          }}
        >
          <Form id="productForm" style={{ maxWidth: 500 }} className="productForm">
            <Row style={{ display: "flex", justifyContent: "center" }} className={"header"}>
              <MediumPageHeader>
                {addoredit === "add" ? "Create a New Package" : "Edit Current Package"}
              </MediumPageHeader>
            </Row>
            <div style={{ marginBottom: 50 }}>
              <Row noGutters style={{ flexDirection: "column" }}>
                <MyTextInput name="name" type="text" placeholder="name" label="Package Name" />
                <HelpText>required</HelpText>

                <MyTextInput label="Package Description" name="desc" type="text" placeholder="Description" />
              </Row>
              <Row noGutters style={{ display: "flex", justifyContent: "space-evenly" }}>
                <Col xs={12} md={3}>
                  {" "}
                  <MyTextInput label="Price" name="price" type="number" placeholder="$1.00" step="0.01" />
                  <HelpText>required</HelpText>
                </Col>
                <Col xs={12} md={3}>
                  <MyTextInput label="Count" name="count" type="number" placeholder="1" />
                  <HelpText>required</HelpText>
                </Col>
                <Col xs={12} md={3}>
                  <MyTextInput label="Valid For (days)" name="expires_in" type="number" placeholder="180" />
                  <HelpText>required</HelpText>
                </Col>
              </Row>
              <CheckBox name={"is_tracked"} label={"Track Package?"} />{" "}
              <CheckBox name={"purchase_online"} label={"Allow Package to be Purchased Online?"} />
              {1 == 2 && (
                <div>
                  {" "}
                  <div style={{ color: COLORS.blue, fontWeight: "bold", padding: 5 }}>
                    {"If this is a recurring package, select the day of the month you would like it to renew:"}{" "}
                  </div>
                  <div style={{ color: COLORS.blue, fontStyle: "italic", padding: 5 }}>
                    {
                      "If the day of the month on the original date is greater than the number of days in the final month, the day of the month will change to the last day in the final month."
                    }{" "}
                  </div>
                  <Field name={"renew_day_of_month"} component={SelectField} options={options} />
                </div>
              )}
            </div>
            <Row style={{ display: "flex", justifyContent: "spread-evenly" }}>
              <Col>
                <OrangeBlueButton onClick={() => goBackToList()}>{"Cancel"}</OrangeBlueButton>
              </Col>
              <Col>
                {" "}
                <OrangeButton type="submit">{"Save"}</OrangeButton>
              </Col>
            </Row>
          </Form>
        </Formik>
      </AddEditForm>
    </>
  );
};
export default ProductForm;
