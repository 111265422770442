import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { ListContext, MyContext } from "./App";
import {
  PrimaryText,
  Td,
  StyledTr,
  TableText1,
  TableText2,
  Th,
  HelpText,
  StyledLink,
  PrimaryTextBlue,
  OrangeButton,
  Row100,
  OrangeText,
  BlueText,
  BlueButton,
  LinkTextBlue,
  BoxWithLabelNoM,
  BlockLabelGrow,
  BlockLabel,
} from "./styleCustom";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { FormRight, ModalWarning } from "./formComponents";
import ProductTable from "./productTable";
import Payment from "./payment";
import {
  currentDayToDB,
  dateObjToDBDate,
  dateObjToDisplayDate,
  calculate_expiry_date,
  todayDisplay,
  stringToDateObj,
} from "./momentUtils";
import PrivateRoute from "./privateRoute";
import { divide, remove } from "lodash";
import COLORS, { nametagStyle, buttonOrangeStyle, buttonBlueStyle } from "./styleConstants";
import { MemberLinks } from "./clientHomePage";
import SearchFilterDates, { SelectMonth } from "./searchFilterDates";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DATEPICKER_DATE_DISPLAY } from "./UserSettings";
import { Formik, Form, useField, Field } from "formik";
import * as Yup from "yup";
import {
  TableHeader2,
  MyTextInput,
  MyTextAreaInput,
  ModalHeaderBlue,
  ButtonOrange,
  ButtonBlue,
} from "./formComponents";
import { Circle, CircleSolid, Trash, EditIconPlain } from "./icons";
import { useAlert } from "react-alert";

const Auth = new AuthHelperMethods();

const PackageDetailsEdit = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const [warning, setWarning] = useState("");
  const [itemNotes, setItemNotes] = useState(listState.currentProduct.itemNotes);
  const [first, setFirst] = useState();
  const [second, setSecond] = useState();

  useEffect(
    () => {
      console.log(dateObjToDBDate(listState.filter.date1));
      console.log(dateObjToDBDate(listState.filter.date2));
      console.log("FIRST");
      if (first && second)
        dispatch2([
          "SET_CURRENT_PRODUCT",
          {
            ...listState.currentProduct,
            package_start_date: dateObjToDBDate(first),
            package_expiry_date: dateObjToDBDate(second),
            //    package_renew_date: dateObjToDBDate(moment(first).add(25, "days")),
          },
        ]);
    },
    //eslint-disable-next-line
    [first, second]
  );

  const onClose = () => {
    if (!listState.currentProduct.purchase_date) setWarning("Purchase Date is not valid");
    else if (!listState.currentProduct.package_start_date) setWarning("Valid From  Date is not valid");
    else if (!listState.currentProduct.package_expiry_date) setWarning("Expiry Date is not valid");
    //test   else if (!listState.currentProduct.product_count != 10) setWarning("c Date is not valid");
    else {
      console.log("close");

      setWarning("");
      setFirst(undefined);
      setSecond(undefined);
      //  dispatch2(["SET_CURRENT_PRODUCT", { ...listState.currentProduct, item_notes: itemNotes }]);

      props.onCloseModal();
    }
  };

  if (!listState.currentProduct) return null;
  return (
    <Modal show={props.showModal} size={"lg"}>
      <ModalHeaderBlue>
        <Modal.Title></Modal.Title>
      </ModalHeaderBlue>
      <Modal.Body>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <HelpText style={{ fontSize: 16 }}> {"Adjust Package Details:"}</HelpText>{" "}
        </div>

        <Row style={{ flexWrap: "wrap" }}>
          <Col xs={6}>
            <BoxWithLabelNoM label={"Valid From"}>
              <div className={"start"}>
                <DatePicker
                  dateFormat={DATEPICKER_DATE_DISPLAY}
                  selected={stringToDateObj(listState.currentProduct.package_start_date)}
                  onChange={(date) => {
                    console.log("start date cjamgom");
                    if (!date) console.log("blah");
                    if (moment(date).isAfter(moment(listState.currentProduct.package_expiry_date)))
                      alert("package start date must be before expiry date");
                    else
                      dispatch2([
                        "SET_CURRENT_PRODUCT",
                        {
                          ...listState.currentProduct,
                          package_start_date: dateObjToDBDate(date),
                          //     package_renew_date: dateObjToDBDate(moment(date).add(25, "days")),
                        },
                      ]);
                  }}

                  // props.setCurrentProduct({ ...props.currentProduct, package_start_date: dateObjToDBDate(date) })
                />
              </div>
            </BoxWithLabelNoM>
          </Col>
          <Col xs={6}>
            <BoxWithLabelNoM label={"Expires"}>
              <div className={"expiry"}>
                <DatePicker
                  dateFormat={DATEPICKER_DATE_DISPLAY}
                  selected={stringToDateObj(listState.currentProduct.package_expiry_date)}
                  onChange={(date) => {
                    if (!date) console.log("thatsit");
                    console.log("onchange");
                    if (moment(date).isBefore(moment(listState.currentProduct.package_start_date)))
                      alert("expiry date must be after start date");
                    else
                      dispatch2([
                        "SET_CURRENT_PRODUCT",
                        { ...listState.currentProduct, package_expiry_date: dateObjToDBDate(date) },
                      ]);
                  }}
                />
              </div>
            </BoxWithLabelNoM>
          </Col>
        </Row>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 10,
            fontWeight: "bold",
          }}
        >
          {"OR"}
        </div>

        <div className="packageEditSelectMonth" style={{ display: "flex", justifyContent: "center" }}>
          <BoxWithLabelNoM label={"Select Month"}>
            <SelectMonth first={setFirst} second={setSecond} />
          </BoxWithLabelNoM>{" "}
        </div>
        <div style={{ display: "flex", flexDirection: "column", marginTop: 30 }}>
          <div
            style={{
              display: "flex",
              flex: "1 1 100%",
              justifyContent: "space-around",
              flexWrap: "wrap",
            }}
          >
            <div style={{ minWidth: 100 }}>
              <BoxWithLabelNoM label={"Purchase Date"}>
                <div className={"purchase"}>
                  <DatePicker
                    dateFormat={DATEPICKER_DATE_DISPLAY}
                    selected={stringToDateObj(listState.currentProduct.purchase_date)}
                    onChange={(date) => {
                      if (!date) console.log("yuck");
                      else
                        dispatch2([
                          "SET_CURRENT_PRODUCT",
                          { ...listState.currentProduct, purchase_date: dateObjToDBDate(date) },
                        ]);
                    }}
                  />
                </div>
              </BoxWithLabelNoM>
            </div>
            <BoxWithLabelNoM label={"Adjust Package Count"}>
              <input
                dateFormat={DATEPICKER_DATE_DISPLAY}
                type="number"
                className="productCount"
                defaultValue={listState.currentProduct.product_count}
                onChange={(e) =>
                  dispatch2([
                    "SET_CURRENT_PRODUCT",
                    {
                      ...listState.currentProduct,
                      product_count: e.target.value ? e.target.value : 0,
                      start_count: e.target.value ? e.target.value : 0,
                    },
                  ])
                }
              />
            </BoxWithLabelNoM>
            {!props.hidePrice && (
              <BoxWithLabelNoM label={"Adjust Price"}>
                <input
                  className="productPrice"
                  type="number"
                  step="10.00"
                  defaultValue={listState.currentProduct.product_price}
                  onChange={(e) =>
                    dispatch2([
                      "SET_CURRENT_PRODUCT",
                      { ...listState.currentProduct, product_price: e.target.value ? e.target.value : 0 },
                    ])
                  }
                />
              </BoxWithLabelNoM>
            )}

            {1 == 2 && (
              <BoxWithLabelNoM label={"Renew On"}>
                <div className={"renew"}>
                  {listState.currentProduct.package_renew_date && (
                    <DatePicker
                      dateFormat={DATEPICKER_DATE_DISPLAY}
                      selected={stringToDateObj(listState.currentProduct.package_renew_date)}
                      onChange={(date) => {
                        dispatch2([
                          "SET_CURRENT_PRODUCT",
                          {
                            ...listState.currentProduct,
                            package_renew_date: dateObjToDBDate(date),
                            //     package_renew_date: dateObjToDBDate(moment(date).add(25, "days")),
                          },
                        ]);
                      }}
                    />
                  )}
                </div>
              </BoxWithLabelNoM>
            )}
          </div>

          <Row style={{ marginTop: 30 }}>
            <Col xs={12}>
              <BoxWithLabelNoM label={"Notes (optional)"}>
                <textarea
                  className={"productNotes"}
                  rows={8}
                  onChange={(e) => {
                    dispatch2(["SET_CURRENT_PRODUCT", { ...listState.currentProduct, item_notes: e.target.value }]);
                    // setItemNotes(e.target.value);
                  }}
                  defaultValue={listState.currentProduct.item_notes}
                />
              </BoxWithLabelNoM>
            </Col>
          </Row>
          <Row style={{ color: COLORS.orange, fontSize: 20, fontWeight: "bold", margin: 10 }}>{`${warning}`}</Row>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flex: "1 1 100%",
          }}
        >
          <ButtonBlue onClick={() => props.setShowModal(false)} label={"Cancel"} />
          <ButtonOrange onClick={() => onClose()} label={"Continue"} />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PackageDetailsEdit;
