import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { MyContext } from "./App";
import moment from "moment";
import { DISPLAY_TIME, DATEPICKER_DISPLAY } from "./UserSettings";
import {
  formatDateTimeForDbEdit,
  calculateEndTime,
  dateObjToDisplayDatetime,
} from "./momentUtils";
import AuthHelperMethods from "./AuthHelperMethods";
const Auth = new AuthHelperMethods();

const ModalDateTime = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const lessons = mystate.currentLesson;
  const [show, setShow] = useState(true);
  const editAction = props.editAction;

  const handleModalClose = () => dispatch({ type: "HIDE_DEFAULT_MODAL" });
  const handleShow = () => setShow(true);

  const [start, setStart] = useState(lessons.start);
  const [end, setEnd] = useState(lessons.end);
  const [allDay, setAllDay] = useState(lessons.allDay);

  const getCurrentDuration = (start, end, unit) => {
    var start = moment(start);
    var end = moment(end);

    return Number(moment.duration(end.diff(start, unit)));
  };

  const currentDuration = getCurrentDuration(
    lessons.start,
    lessons.end,
    "minutes"
  );

  const handleTimeValueChange = (date) => {
    //console.log(currentDuration);
    setEnd(calculateEndTime(date, currentDuration));
    //console.log(end);

    setStart(date);

    //console.log(start);

    /*  dispatch({
      type: "SET_NEW_EVENT",
      data: {
        start: date,
        end: calculateEndTime(date, currentDur)
      }
    });*/
  };

  const saveChanges = () => {
    let editParams = {};
    editParams.start = start;
    editParams.end = end;
    editParams = formatDateTimeForDbEdit(editParams);
    editParams.id = lessons.id;
    editParams.editAction = mystate.editAction;

    if (mystate.editAction == "reschedule") {
      let note =
        "Your lesson has been rescheduled from " +
        dateObjToDisplayDatetime(lessons.start) +
        " to " +
        dateObjToDisplayDatetime(start);
      let notesfrom =
        "Rescheduled from  " + dateObjToDisplayDatetime(lessons.start);
      let notesto = "Rescheduled to " + dateObjToDisplayDatetime(start);
      editParams.notesfrom = notesfrom;
      editParams.notesto = notesto;
    }

    const result = Auth.api("lessons/reschedule", "post", editParams);
  };

  return (
    <Modal
      show={mystate.showDateTimeModal}
      onHide={handleModalClose}
      size="md"
      centered
    >
      <Modal.Header
        closeButton
        style={{
          backgroundColor: "#66c2d7",
          color: "#FFFFFF",
          borderRadius: "2px",
        }}
      >
        <Modal.Title>{mystate.modalTitle} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="datepicker">
          {lessons && (
            <>
              <label>
                <DatePicker
                  showTimeSelect
                  selected={start}
                  dateFormat={
                    lessons.is_allday ? "MMMM d, yyyy" : DATEPICKER_DISPLAY
                  }
                  onChange={handleTimeValueChange}
                />
                {"start"}
              </label>

              {!mystate.addevent.is_allday && (
                <label>
                  <DatePicker
                    showTimeSelect={!lessons.is_allday}
                    selected={end}
                    dateFormat={DISPLAY_TIME}
                    onChange={(date) => setEnd(date)}
                    showTimeSelectOnly
                  />
                  {"end"}
                </label>
              )}

              <div>
                {" "}
                <label>
                  <input
                    type="checkbox"
                    checked={allDay ? true : false}
                    onChange={(event) => setAllDay(!allDay)}
                  />
                  {"all day"}
                </label>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Close
        </Button>
        <Button
          onClick={saveChanges}
          style={{ background: "#FF7800", color: "white", border: "none" }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDateTime;
