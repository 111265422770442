import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { ListContext, MyContext } from "./App";
import {
  PrimaryText,
  Td,
  StyledTr,
  TableText1,
  TableText2,
  Th,
  HelpText,
  StyledLink,
  PrimaryTextBlue,
  OrangeButton,
  Row100,
  OrangeText,
  BlueText,
  BlueButton,
  LinkTextBlue,
  BoxWithLabelNoM,
  BlockLabelGrow,
  BlockLabel,
} from "./styleCustom";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { FormRight, ModalWarning } from "./formComponents";
import ProductTable from "./productTable";
import Payment from "./payment";
import {
  currentDayToDB,
  dateObjToDBDate,
  dateObjToDisplayDate,
  calculate_expiry_date,
  todayDisplay,
  stringToDateObj,
} from "./momentUtils";
import PrivateRoute from "./privateRoute";
import { divide, remove } from "lodash";
import COLORS, { nametagStyle, buttonOrangeStyle, buttonBlueStyle } from "./styleConstants";
import { MemberLinks } from "./clientHomePage";
import SearchFilterDates from "./searchFilterDates";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DATEPICKER_DATE_DISPLAY } from "./UserSettings";
import { Formik, Form, useField, Field } from "formik";
import * as Yup from "yup";
import {
  TableHeader2,
  MyTextInput,
  MyTextAreaInput,
  ModalHeaderBlue,
  ButtonOrange,
  ButtonBlue,
} from "./formComponents";
import { Circle, CircleSolid, Trash, EditIconPlain } from "./icons";
import { useAlert } from "react-alert";
import { v4 as uuidv4 } from "uuid";

const Auth = new AuthHelperMethods();

//****************END ONLINE PURCHASE START PRODUCT LIST *///////
const PackageList = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        if (Auth.getUserType() == "STU") params.purchase_online = 1;
        params.is_active = 1;

        const result = await Auth.api("products/index", "get", params);

        // const response = await Auth.api("xrefs/getStudentProducts", "get", params);

        dispatch2(["PRODUCTS_GET", result.data]);
      };

      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  /*const getRenewDate()={
    //renewdate=moment().year().month()

  };*/

  const handleClickProduct = (productIn) => {
    console.log(productIn);
    console.log("handleClickProduct package list");
    if (productIn.is_auto) {
    }
    // console.log(mystate.farmDefaults.package_start_before_days);
    //console.log(dateObjToDBDate(moment().subtract(mystate.farmDefaults.package_start_before_days, "days")));

    const product = {
      ...productIn,
      package_start_date: dateObjToDBDate(moment().subtract(mystate.farmDefaults.package_start_before_days, "days")),
      purchase_date: currentDayToDB(),
      package_expiry_date: productIn.expires_in ? dateObjToDBDate(moment().add(productIn.expires_in, "days")) : null,
      item_notes: "",
      // unique_id: uuidv4(),
    };
    console.log(product.package_start_date);

    console.log(product);
    dispatch2(["SET_CURRENT_PRODUCT", product]);

    AddProductToCart(product);

    if (props.handleClick) props.handleClick();
    // setCurrentProduct(productin);

    //setCurrentProduct(product);
    /* if (Auth.getUserType() == "ADM") {
      setShowModal(true);
    } else {
      onCloseModal(productin);
    }*/
  };

  const AddProductToCart = (product) => {
    // product.counter = counter + 1;
    // SetCounter(counter + 1);
    product.product_id = product.id;
    product.start_count = product.product_count;
    product.package_start_date = dateObjToDBDate(
      moment().subtract(mystate.farmDefaults.package_start_before_days, "days")
    );
    product.purchase_date = currentDayToDB();
    product.package_expiry_date = product.expires_in ? dateObjToDBDate(moment().add(product.expires_in, "days")) : null;
    product.student_id = listState.currentClient.student_id || null;
    product.student_name = listState.currentClient.first_name || listState.currentClient.person_name;
    product.product_name = product.product_name;
    product.fee = mystate.farmDefaults.stripe_convenience_fee;
    product.tax_percent = mystate.farmDefaults.tax_percent;
    console.log(product.package_start_date);
    if (product.is_auto) product.package_renew_date = dateObjToDBDate(moment().add(1, "month")) || null;

    dispatch2(["ADD_TO_CART", product]);
    console.log(listState.cartProducts);
  };

  return (
    <div
      className="productWrapper"
      style={{
        display: "flex",
        flexWrap: "wrap",
        minWidth: 100,
        maxWidth: 500,
        padding: 20,

        borderx: "solid 1pt purple",
        orderx: 2,
      }}
    >
      {listState.products.map((product) => (
        <div
          className="eachPackage"
          onClick={() => handleClickProduct(product)}
          style={{
            display: "flex",
            flexDirection: "column",
            border: "solid 1pt lightgrey",
            background: "white",
            flexWrap: "wrap",
            flex: "1 1 90%",
            borderRadius: 10,

            padding: 15,

            marginBottom: 10,
          }}
        >
          <div
            className="productName"
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <PrimaryTextBlue>{product.product_name || ""} </PrimaryTextBlue>
          </div>
          <div>
            <div className="productDetails" style={{ color: COLORS.textgrey }}>
              <TableText2>
                {product.product_price &&
                  `${mystate.farmDefaults.currency_symbol}${Number(product.product_price).toFixed(
                    2
                  )} - good for         ${product.product_count} uses - valid for ${product.expires_in || ""} days`}
              </TableText2>
            </div>
            {1 == 2 && product.is_auto && <div>{"This product auto renews"}</div>}
          </div>
        </div>
      ))}
    </div>
  );
};
//****************END PRODUCT LIST START MANUAL MODAL *///////

export default PackageList;
