import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter, Route, Switch, Link, NavLink } from "react-router-dom";

import ReactDOM from "react-dom";
import { useParams, useHistory } from "react-router";
import { Formik, Form, useField, useFormikContext, FieldProps, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  MediumPageHeader,
  BoxWithLabel,
  Row1,
  ContainerH,
  ContainerV,
  Row2,
  BoxWithLabelW,
  HelpText,
  StyledErrorMessage,
} from "./styleCustom";
import { MyTextInput, MyCheckbox, AddEditForm, OrangeButton, OrangeBlueButton, BlueLinkButton } from "./formComponents";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col } from "react-bootstrap";
import { ListContext } from "./App";
import SearchFilter, { InstructorFilter, LocationFilter, ProgramFilter } from "./searchFilter";
import { MyContext } from "./App";
import Select, { Option, ReactSelectProps } from "react-select";

const Auth = new AuthHelperMethods();

const SectionForm = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  useEffect(
    () => {
      window.scrollTo(0, 0);
      return () => {
        dispatch2(["SET_FORM_INITIAL_VALUES", {}]);
      };

      //  }
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        const result = await Auth.api("options/index", "get", params);
        dispatch({
          type: "UPDATE_OPTIONS",
          options: result.data,
        });
      };
      fetchData();
    },
    //eslint-disable-next-line
    [listState.sectionList]
  );

  let addoredit = useParams().id;

  let history = useHistory();

  // const SelectField = ({
  const SelectField: React.SFC<ReactSelectProps & FieldProps> = ({ options, field, form }) => (
    <Select
      options={options}
      name={field.name}
      value={options ? options.find((option) => option.value === field.value) : ""}
      onChange={(option: Option) => form.setFieldValue(field.name, option.value)}
      placeholder={"Select"}
      onBlur={field.onBlur}
    />
  );
  return (
    <>
      <AddEditForm>
        {listState.formInitialValues && (
          <Formik
            initialValues={listState.formInitialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string().required("section needs a name"),
              duration: Yup.number().required("event duration is required"),
              max_enrollment: Yup.number(),
              program_id: Yup.string().required("program required"),
              instructor_id: Yup.string().required("staff required"),
              location_id: Yup.string().required("location required"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              console.log("onSubmit");
              console.log(values);
              values = { ...values, ...listState.filter };
              if (addoredit === "add") {
                const result = await Auth.api("sections/create", "post", values);
                dispatch2(["SECTION_CREATE", result.data]);

                setSubmitting(false);
                history.goBack();
              }

              if (addoredit !== "add") {
                const result = await Auth.api("sections/update", "post", values);
                dispatch2(["SECTION_EDIT", result.data]);
                history.push("/admin/setup/sections");
              }

              //    }
            }}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form id="sectionForm" style={{ maxWidth: 500 }}>
                <Row style={{ display: "flex", justifyContent: "center" }}>
                  <MediumPageHeader>
                    {addoredit === "add" ? "Create a new Section" : "Edit Current Section"}
                  </MediumPageHeader>
                </Row>
                <HelpText>
                  {
                    "Sections are the event type that you choose when adding an event.  The values entered here are the default values for that event and will be prepopulated on the Add Event form.. You can then change them if you wish.  "
                  }
                </HelpText>
                <div style={{ marginBottom: 50 }}>
                  <Row noGutters>
                    <Col xs={12}>
                      {mystate.options_program && (
                        <BoxWithLabelW label="program">
                          <Field name={"program_id"} component={SelectField} options={mystate.options_program} />
                          <HelpText>required</HelpText>

                          <ErrorMessage name="program_id" component={StyledErrorMessage} />
                        </BoxWithLabelW>
                      )}
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col xs={12}>
                      <BoxWithLabelW label="name *">
                        <Field name="name" />
                        <ErrorMessage name="name" component={StyledErrorMessage} />
                        <HelpText>required</HelpText>
                      </BoxWithLabelW>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <BoxWithLabelW label="duration *">
                        <Field name="duration" type="number" placeholder="duration in minutes" />
                        <ErrorMessage name="duration" component={StyledErrorMessage} />
                        <HelpText>required</HelpText>
                      </BoxWithLabelW>
                    </Col>
                    <Col xs={6}>
                      <MyTextInput type="number" label="maximum enrollment" name="max_enrollment" />
                    </Col>
                  </Row>

                  <Row noGutters>
                    <Col xs={12}>
                      {mystate.options_instructor && (
                        <BoxWithLabelW label="default staff *">
                          <Field name={"instructor_id"} component={SelectField} options={mystate.options_instructor} />
                          <HelpText>required</HelpText>

                          <ErrorMessage name="instructor_id" component={StyledErrorMessage} />
                        </BoxWithLabelW>
                      )}
                    </Col>
                  </Row>

                  <Row noGutters>
                    <Col xs={12}>
                      {mystate.options_location && (
                        <BoxWithLabelW label="default location *">
                          <Field name={"location_id"} component={SelectField} options={mystate.options_location} />
                          <HelpText>required</HelpText>

                          <ErrorMessage name="location_id" component={StyledErrorMessage} />
                        </BoxWithLabelW>
                      )}
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col xs={12}>
                      {mystate.options_product && (
                        <BoxWithLabelW label="default single package ">
                          <Field name={"product_id"} component={SelectField} options={mystate.options_product} />

                          <ErrorMessage name="product_id" component={StyledErrorMessage} />
                        </BoxWithLabelW>
                      )}
                    </Col>
                  </Row>
                </div>

                <Row style={{ display: "flex", justifyContent: "spread-evenly" }}>
                  <Col>
                    <OrangeBlueButton onClick={() => history.push("/admin/setup/sections")}>
                      {"Cancel"}
                    </OrangeBlueButton>
                  </Col>
                  <Col>
                    {" "}
                    <OrangeButton type="submit">{"Save"}</OrangeButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        )}
      </AddEditForm>
    </>
  );
};
export default SectionForm;
