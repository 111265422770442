import React, { useReducer, Fragment, useState, useEffect, useContext } from "react";

import Select from "react-select";
import { MyContext, ListContext } from "./App";
import ReactTooltip from "react-tooltip";
import { Active } from "./formComponents";
import AuthHelperMethods from "./AuthHelperMethods";
import { DropdownStyle } from "./styleConstants";
const Auth = new AuthHelperMethods();

const SelectHorse = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);

  const [options, setOptions] = useState([]);
  const isStudent = Auth.getUserType() == "STU";

  useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        params.section_id = mystate.currentLesson.section_id || mystate.addevent.section_id;

        params.is_active = 1;

        const result = await Auth.api("section_horses/index", "get", params);
        if (mystate.options_horse)
          dispatch({
            type: "GET_HORSES_BY_SECTION",
            section_horses_array: result.data.sectionHorsesArray,
            section_horses: result.data.sectionHorses,
          });
      };
      console.log("selectHorse useEffect ");
      if (mystate.currentLesson.section_id || mystate.addevent.section_id) fetchData();
    },
    //eslint-disable-next-line
    [mystate.currentLesson.section_id, mystate.addevent.section_id]
  );

  /*
  //*using section optons instead
  useEffect(
    () => {
      const fetchData = async () => {
        console.log("STU");
        let params = { single_id: listState.currentClient.student_id };
        const result = await Auth.api("xrefs/getStudentHorses", "get", params);
        if (result) setOptions(result.data.currentValue);
        if (result) console.log(result.data);
      };
      if (isStudent) fetchData();
    },
    //eslint-disable-next-line
    []
  );
*/
  useEffect(
    () => {
      return () => {
        dispatch({ type: "CLEAR_SECTIONS_HORSE" });
      };
    },
    //eslint-disable-next-line
    []
  );

  let commonParams = {
    lesson_date_time_id: mystate.currentLesson.id,
    lesson_date_time_series_id: mystate.currentLesson.lesson_date_time_series_id,
    applyToFuture: mystate.applyToFuture,
    scheduled_date: mystate.currentLesson.scheduled_date,
  };

  const handleListItemClick = async (selected, { action, removedValue }) => {
    console.log(selected);
    if (action == "remove-value") {
      commonParams = { ...commonParams, horse_id: removedValue.value };
      console.log(removedValue);
      let horseObj = { ...removedValue, horse_id: removedValue.value };

      dispatch({
        type: "REMOVE_HORSE_FROM_LESSON",
        horseObj: horseObj,
      });

      const result = Auth.api("lesson_date_time_horses/delete", "post", commonParams);
    } else {
      console.log("handleListItemClick");

      console.log(selected);
      let objectIn = selected.pop();
      console.log(objectIn);
      //   dispatch({ type: "UPDATE_HORSES_BY_SECTION", data: objectIn.value });
      let tempid = `${objectIn.value}${mystate.currentLesson.id}`;
      console.log(tempid);

      if (objectIn) {
        let commonparams = {
          lesson_date_time_id: mystate.currentLesson.id,
          lesson_date_time_series_id: mystate.currentLesson.lesson_date_time_series_id || "",
          applyToFuture: mystate.applyToFuture,
          scheduled_date: mystate.currentLesson.scheduled_date,
        };
        let params = mystate.showStudentList
          ? {
              ...commonparams,
              student_id: objectIn.id,

              name: objectIn.person_name,
              assigned_to: "",
              horse_id: 0,
              enrollment_status_code: "SCH",
              enrollment_status_name: "scheduled",
            }
          : {
              ...commonparams,
              horse_id: objectIn.value,
              tempid: tempid,
              name: objectIn.label,
            };

        // cheat - dispatch before result -- actually - not using api result at all
        dispatch({
          type: "ASSIGN_HORSE_TO_LESSON",
          data: params,
        });

        //  const result = Auth.api("lesson_people/create", "post", studentOption);
        const result = await Auth.api("lesson_date_time_horses/create", "post", params);
        dispatch({
          type: "UPDATE_ASSIGN_HORSE_TO_LESSON",
          data: result.data.id,
          tempid: tempid,
        });

        dispatch({
          type: "SET_ALERT_MESSAGE",
          name: params.name,
        });
      }
    }
  };

  const handleHorseChange = (event) => {
    console.log("handleHorseChange");
    console.log(event);
    dispatch({
      type: "SET_NEW_EVENT",
      data: { horses: event },
    });
  };

  return (
    <div data-for="horse">
      <Select
        onChange={props.add ? (e) => handleHorseChange(e) : handleListItemClick}
        className="form-control"
        options={isStudent ? mystate.options_horse : mystate.sections_horse}
        isClearable={false}
        searchable={true}
        isMulti={true}
        closeMenuOnSelect={false}
        menuIsOpen={props.menuIsOpen}
        onBlur={props.onBlur}
        placeholder={"Select Horses..."}
        value={
          mystate.currentLesson.lessonHorses &&
          mystate.currentLesson.lessonHorses.map((horse) => {
            return { value: horse.horse_id, label: horse.name };
          })
        }
        blurInputOnSelect={false}
        hideSelectedOptions={true}
      />

      <ReactTooltip id="horse" place="left" type="info" effect="solid" />
    </div>
  );
  //  }
};

export default SelectHorse;
