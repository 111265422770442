import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter, Route, Switch, Link, NavLink } from "react-router-dom";

import ReactDOM from "react-dom";
import { useParams, useHistory } from "react-router";
import { Formik, Form, useField, useFormikContext, FieldProps, Field } from "formik";
import * as Yup from "yup";

import { MediumPageHeader, BoxWithLabel, Row1, ContainerH, ContainerV, Row2, BoxWithLabelW } from "./styleCustom";
import { MyTextInput, MyCheckbox, AddEditForm, OrangeButton, BlueLinkButton } from "./formComponents";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col } from "react-bootstrap";
import { ListContext } from "./App";
import SearchFilter, { InstructorFilter, LocationFilter, ProgramFilter } from "./searchFilter";
import { MyContext } from "./App";
import Select, { Option, ReactSelectProps } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Auth = new AuthHelperMethods();

const FilterForm = (props) => {
  const { instructor, day, program, location } = props;
  console.log(instructor);
  console.log(location);

  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  let initialValues = {
    program_id: "",
    instructor_id: "",
    location_id: "",
    day: "",
  };

  let addoredit = useParams().id;
  let history = useHistory();

  const DatePickerField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    return (
      <DatePicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
      />
    );
  };

  const SelectField: React.FC<ReactSelectProps & FieldProps> = ({ options, field, form }) => {
    return (
      <Select
        options={options}
        name={field.name}
        value={options ? options.find((option) => option.value === field.value) : ""}
        /*  onChange={(option: Option || ) =>
       form.setFieldValue(
            field.name,  option ? (option as Option[]).map((item: Option) => item.value) : []
          )
//  option ? (option as Option[]).map((item: Option) => item.value) : []

}*/
        onChange={(option: Option) => form.setFieldValue(field.name, option ? option.value : [])}
        onBlur={field.onBlur}
        isClearable
      />
    );
  };
  return (
    <>
      {initialValues && (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={async (values, { setSubmitting }) => {
            console.log("onSubmit");
            console.log(values);
            //    if (addoredit === "add") {

            const result = await Auth.api(props.url, "get", values);
            dispatch2([props.action, result.data]);

            setSubmitting(false);

            //  }

            //    }
          }}
        >
          <Form id="filterForm">
            <div style={{ marginBottom: 50, display: "flex" }}>
              {day && (
                <BoxWithLabel label="day ">
                  <DatePickerField name="day" />{" "}
                </BoxWithLabel>
              )}
              {mystate.options_program && program && (
                <BoxWithLabel label="program">
                  <Field name={"program_id"} component={SelectField} options={mystate.options_program} />
                </BoxWithLabel>
              )}

              {mystate.options_instructor && instructor && (
                <BoxWithLabel label="instructor">
                  <Field name={"instructor_id"} component={SelectField} options={mystate.options_instructor} />
                </BoxWithLabel>
              )}

              {mystate.options_location && location && (
                <BoxWithLabel label="location">
                  <Field name={"location_id"} component={SelectField} options={mystate.options_location} />
                </BoxWithLabel>
              )}

              <button type="submit">{"Search"}</button>
            </div>
          </Form>
        </Formik>
      )}
    </>
  );
};
export default FilterForm;
