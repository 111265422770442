import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MediumPageHeader } from "./styleCustom";
import { MyTextInput, FormRightSave } from "./formComponents";
import { errormsg } from "./Constants2";
//import "./styleForm.css";
//import "./styleFormik.css";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col } from "react-bootstrap";
import { ListContext } from "./App";
import { MyContext } from "./App";
import { useAlert } from "react-alert";

import { useHistory, useParams } from "react-router-dom";
const Auth = new AuthHelperMethods();

const Url = () => {
  const [success, SetSuccess] = useState(false);
  let x = useParams();
  let history = useHistory();
  console.log(x);
  var url_string = window.location.href;
  var url = new URL(url_string);
  var c = url.searchParams.get("scope");

  console.log(c);
  //  var qs = parse_query_string(query);
  const getParams = () => {
    console.log(x);
    return <div>{"there"}</div>;
  };

  useEffect(
    () => {
      var url_string = window.location.href;
      var url = new URL(url_string);
      var c = url.searchParams.get("code");
      let params = { code: c };
      console.log(c);
      try {
        const result = Auth.api("payments/addAccount", "post", params);
        if (result) SetSuccess(true);
      } catch (e) {
        console.log(e);
      }

      //  console.log("url page");
    },
    //eslint-disable-next-line
    []
  );
  if (!success) return null;
  return (
    <>
      <div>
        {success && (
          <>
            <h1>
              {
                "Your Stripe account has been successfully connected with Equine Office"
              }
            </h1>
            <button onClick={() => history.push("/admin/setup/preferences")}>
              click to return
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default Url;
