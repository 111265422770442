import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, NavLink } from "react-router-dom";
import { Row, Col, Button, ListGroup } from "react-bootstrap";
import { ListContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import {
  StyledListGroup,
  StyledList,
  AccountHolderText,
  StudentText,
  Td,
  Th,
  PrimaryText,
  PrimaryTextBlue,
  BlueNavLink,
  BlueInvLink,
  ContainerV,
  PageTitle,
} from "./styleCustom";
import SearchFilter from "./searchFilter";
import moment from "moment";
import SearchFilterDate from "./searchFilterDates";
import {
  filterToParams,
  currentDayToDB,
  dateISOtoString,
  timeISOtoString,
  dateISOtoStringMed,
  dateTimeISOtoStringMed,
} from "./momentUtils";
import { EditIcon } from "./icons";
import Sections from "./sections";
import COLORS from "./styleConstants";
import { TableHeader2, UserCan } from "./formComponents";
import Products from "./products";
import { Check } from "./icons";
import SelectStudent from "./selectStudent";
import { DISPLAY_DATE } from "./UserSettings";
import PrivateRoute from "./privateRoute";
const Auth = new AuthHelperMethods();
import { BlueNavLinkSm } from "./styleCustom";

export const NotesReview = (props) => {
  return (
    <div>
      <div
        className="admin"
        style={{
          background: COLORS.blue,

          fontSize: "10px",
          marginBottom: 30,
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "wrap",

          flex: "1 1 100%",
        }}
      >
        <BlueNavLinkSm to={`${props.match.url}/clientnotes`}> Client Notes</BlueNavLinkSm>
        <BlueNavLinkSm to={`${props.match.url}/horsenotes`}> Horse Notes</BlueNavLinkSm>
        <UserCan doThis={"STAFF_CAN_VIEW_STAFF_NOTES_PAGE"}>
          <BlueNavLinkSm to={`${props.match.url}/staffnotes`}> Staff Notes</BlueNavLinkSm>
        </UserCan>
      </div>
      <div className="switch" style={{ display: "flex", flex: "1", flexWrap: "wrap" }}>
        <Switch>
          <PrivateRoute path={`${props.match.url}/clientnotes`} component={ClientNotesReview} />
          <UserCan doThis={"STAFF_CAN_VIEW_STAFF_NOTES_PAGE"}>
            <PrivateRoute path={`${props.match.url}/staffnotes`} component={StaffNotesReview} />
          </UserCan>
          <PrivateRoute path={`${props.match.url}/horsenotes`} component={HorseNotesReview} />
          <UserCan doThis={"STAFF_CAN_VIEW_STAFF_NOTES_PAGE"}>
            <PrivateRoute path={`${props.match.url}/staffnotes`} component={StaffNotesReview} />
          </UserCan>
        </Switch>
      </div>
    </div>
  );
};

const NotesTable = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  if (props.notes && props.notes.length == 0)
    return <div style={{ fontSize: 20, color: COLORS.blue, paddingRight: 30 }}>{"No notes recorded today"}</div>;

  return (
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          <Th>name</Th>
          <Th>note </Th>
          <Th>pin </Th>
          <Th>public </Th>

          <Th>re lesson</Th>
          <Th>Created By</Th>
          <Th>Created On</Th>
        </tr>
      </thead>
      <tbody>
        {props.notes &&
          props.notes.length > 0 &&
          props.notes.map((note) => (
            <tr key={note.id}>
              <Td>
                {note.person_name && <Link to={`/admin/clientHome/${note.person_id}/details`}>{note.person_name}</Link>}
                {note.horse_name && note.horse_name}
              </Td>
              <Td>{note.notes} </Td>

              <Td>{note.pin && <Check />}</Td>
              <Td>{!note.is_public && "private"}</Td>
              <Td>
                {note.scheduled_date && (
                  <>
                    <div>{note.lesson_name} </div>
                    <div>{dateISOtoStringMed(note.scheduled_date)}</div>
                  </>
                )}
              </Td>
              <Td>{note.created_by_person}</Td>
              <Td>{dateTimeISOtoStringMed(note.created_at)}</Td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export const ClientNotesReview = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const [heading, setHeading] = useState();

  //  let params = { dbday: currentDayToDB() };

  useEffect(
    () => {
      console.log("filter change");
      console.log(listState.filter);

      let params = {};
      params.dbday = listState.filter.dbday || currentDayToDB();
      params.clients_only = 0;

      const fetchData = async () => {
        const result = await Auth.api("notes_clients/clientNotes", "get", params);
        result && dispatch2(["GET_ADMIN_CLIENT_NOTES", result.data]);
        setHeading(moment(listState.filter.dbday).format(DISPLAY_DATE));
      };
      fetchData();
      listState.filter.page === "clientnotes" && fetchData();
    },
    //eslint-disable-next-line
    [listState.filter]
  );
  useEffect(
    () => {
      dispatch2(["SET_SEARCH_FILTER", "clientnotes", "page"]);
    },
    //eslint-disable-next-line
    []
  );
  return (
    <>
      <PageTitle>{"Notes"}</PageTitle>
      <div style={{ display: "flex", flex: "1 1 100%" }} className="filter">
        <SearchFilterDate single initial={moment().toDate()} />
      </div>
      <div style={{ fontSize: 20, color: COLORS.blue, paddingRight: 30 }}>{heading} </div>
      <TableHeader2 title={"Client Notes"} />

      <NotesTable notes={listState.adminClientNotes} />
    </>
  );
};

export const StaffNotesReview = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const [heading, setHeading] = useState();

  //  let params = { dbday: currentDayToDB() };

  useEffect(
    () => {
      let params = {};
      params.dbday = listState.filter.dbday || currentDayToDB();
      params.staff_only = 1;
      const fetchData = async () => {
        const result = await Auth.api("notes_clients/clientNotes", "get", params);
        result && dispatch2(["GET_ADMIN_CLIENT_NOTES", result.data]);
        setHeading(moment(listState.filter.dbday).format(DISPLAY_DATE));
      };
      fetchData();
      listState.filter.page === "clientnotes" && fetchData();
    },
    //eslint-disable-next-line
    [listState.filter]
  );
  useEffect(
    () => {
      dispatch2(["SET_SEARCH_FILTER", "clientnotes", "page"]);
    },
    //eslint-disable-next-line
    []
  );
  return (
    <>
      <PageTitle>{"Notes"}</PageTitle>
      <div style={{ display: "flex", flex: "1 1 100%" }} className="filter">
        <SearchFilterDate single initial={moment().toDate()} />
      </div>
      <div style={{ fontSize: 20, color: COLORS.blue, paddingRight: 30 }}>{heading} </div>
      <TableHeader2 title={"Staff Notes"} />

      <NotesTable notes={listState.adminClientNotes} />
    </>
  );
};
export const HorseNotesReview = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const [heading, setHeading] = useState();

  useEffect(
    () => {
      let params = {};
      params.dbday = listState.filter.dbday || currentDayToDB();
      const fetchData = async () => {
        const result = await Auth.api("notes_horses/getHorsePageNotes", "get", params);
        setHeading(moment(listState.filter.dbday).format(DISPLAY_DATE));

        result && dispatch2(["GET_ADMIN_HORSE_NOTES", result.data]);
      };
      fetchData();
      listState.filter.page == "horsenotes" && fetchData();
    },
    //eslint-disable-next-line
    [listState.filter]
  );
  useEffect(
    () => {
      dispatch2(["SET_SEARCH_FILTER", "horsenotes", "page"]);
    },
    //eslint-disable-next-line
    []
  );

  return (
    <>
      <PageTitle style={{ flex: "1 0 100%" }}>{"Notes"}</PageTitle>
      <SearchFilterDate single initial={moment().toDate()} />
      <TableHeader2 title={"Horse Notes"} />
      <div style={{ fontSize: 20, color: COLORS.blue, paddingRight: 30 }}>{heading} </div>
      <NotesTable notes={listState.adminHorseNotes} />
    </>
  );
};
