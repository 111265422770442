import React, { useState, useContext, useEffect } from "react";

import { BrowserRouter, Route, Switch, Link, NavLink } from "react-router-dom";

import { useParams, useHistory } from "react-router";
import styled from "styled-components";
import COLORS from "./styleConstants";
import {
  SaveButton,
  CancelButton,
  PageTitle,
  AddButton,
  BlockLabel,
  BlockInputLg,
  BoxWithLabel,
  BlockLabelGrow,
  BoxWithLabelW,
  PrimaryTextBlue,
  PrimaryTextBlue5m,
  BlueText,
  StyledErrorMessage,
  HelpText,
  BlockLabelHorizontal,
} from "./styleCustom";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { ListContext } from "./App";
import DatePicker from "react-datepicker";
import { DATEPICKER_DATE_DISPLAY } from "./UserSettings";
import { dateObjToDBDate } from "./momentUtils";
import { Formik, Form, useField, useFormikContext, Field, ErrorMessage } from "formik";
import { TimesIcon, TimesIconBlue, Check, RemoveIconNoPosition, Asc, Desc, Circle, CircleSolid } from "./icons";
import User from "./UserSettings";
import { useAlert } from "react-alert";
import axios from "axios";
import AuthHelperMethods from "./AuthHelperMethods";
import { MyContext } from "./App";
import { Row2 } from "./styleCustom";
import { privateNoteImg, publicNoteImg } from "./images";
import { NonceProvider } from "react-select";
import { CheckSquare, Square } from "./icons";

const Auth = new AuthHelperMethods();

export const TopName = (props) => {
  return (
    <div
      className={"topname"}
      style={{
        display: "flex",
        flexWrap: "wrap",
        flex: " 1 1 100%",
        color: COLORS.blue,
        marginTop: 30,

        fontSize: 30,
        marginBottom: 10,
        marginRight: 10,
      }}
    >
      {props.heading}
    </div>
  );
};

export const ModalHeaderBlue = styled(Modal.Header)`
  background: ${COLORS.blue};
  color: white;
`;

export const ModalFooterBlue = styled(Modal.Footer)`
  background: ${COLORS.blue};
  color: white;
`;

export const ModalConfirm = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);

  return (
    <Modal show={listState.confirmShow} onHide={dispatch2(["SET_CONFIRM_SHOW", false])} size="md" centered>
      <Modal.Header style={{ background: COLORS.blue, color: "white" }} closeButton>
        <Modal.Title>{props.modalTitle} </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.handleModalClose()}>
          Close
        </Button>
        <Button onClick={() => props.saveEvent()} style={{ background: COLORS.blue, color: "white", border: "none" }}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ModalWarning = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  //const [showWarning, setShowWarning] = useState(false);
  const [showModal, setShowModal] = useState(true);
  return (
    <Modal show={true} size="md" centered>
      <Modal.Header style={{ background: props.color || "#dc3545", color: "white" }}>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: "40 20" }}>
          <div style={{ fontSize: 20 }}>{props.warningMessage} </div>
          <div style={{ fontSize: 14, fontStyle: "italic", marginTop: 15 }}>{props.warning2} </div>
          {props.children}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flex: "1 1 100%",
          }}
        >
          <Button variant="light" onClick={() => props.cancel()}>
            {props.cancellabel}
          </Button>
          <Button variant="danger" background={"orange"} onClick={() => props.proceed()}>
            {props.proceedlabel}{" "}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export const Dialog = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);

  const handleModalClose = () => {
    dispatch2(["SET_DIALOG_SHOW", false]);
  };
  return (
    <Modal show={listState.dialogShow} onHide={() => handleModalClose()} size="md" centered>
      <Modal.Header style={{ background: COLORS.blue, color: "white" }} closeButton>
        <Modal.Title>{props.modalTitle} </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleModalClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const DialogNoFooter = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);

  const handleModalClose = () => {
    dispatch2(["SET_DIALOG_SHOW", false]);
  };
  return (
    <Modal show={listState.dialogShow} onHide={() => handleModalClose()} size="md" centered>
      <Modal.Header style={{ background: COLORS.blue, color: "white" }} closeButton>
        <Modal.Title>{props.modalTitle} </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
};

export const ButtonNoShadow = (props) => {
  return <Button className="shadow-none"> {props.children}</Button>;
};

export const OrangeButton = (props) => {
  return (
    <Button
      className="shadow-none"
      style={{ color: "white", background: COLORS.orange, width: "100%" }}
      size={"md"}
      onClick={props.onClick}
      type={props.type}
    >
      {props.children}
    </Button>
  );
};

export const OrangeBlueButton = (props) => {
  return (
    <Button
      className="shadow-none"
      style={{
        color: "white",
        background: COLORS.blue,
        width: "100%",
        border: "none",
      }}
      size={"md"}
      onClick={props.onClick}
      type={props.type}
    >
      {props.children}
    </Button>
  );
};

export const OrangeButtonReg = (props) => {
  return (
    <Button
      className="shadow-none"
      style={{ color: "white", background: COLORS.orange, fontSize: 10 }}
      size={"sm"}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};
export const orangebuttonstyle = {
  color: "white",
  background: COLORS.orange,
  fontSize: 14,
  border: "none",
  borderRadius: 5,
  padding: 10,
  width: 150,
};
export const bluebuttonstyle = {
  ...orangebuttonstyle,
  background: COLORS.blue,
};
export const BlueButtonReg = (props) => {
  return (
    <Button
      className="shadow-none"
      style={{
        color: "white",
        background: COLORS.blue,
        fontSize: 10,
        border: "none",
      }}
      size={"sm"}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

export const OrangeLinkButton = (props) => {
  return (
    <Button className="shadow-none" style={{ color: "white", background: COLORS.orange, width: "100%" }} size={"md"}>
      {props.children}
    </Button>
  );
};

export const BlueLinkButton = (props) => {
  return (
    <Button
      className="shadow-none"
      style={{
        color: "white",
        background: COLORS.blue,
        width: "100%",
        border: "none",
      }}
      size={"md"}
    >
      {props.children}
    </Button>
  );
};

export const BlueButton = (props) => {
  let history = useHistory();
  function goBack() {
    history.goBack();
  }
  return (
    <Button
      className="shadow-none"
      style={{ color: "white", background: COLORS.blue }}
      size={"lg"}
      type="submit"
      onClick={() => goBack()}
    >
      {props.children}
    </Button>
  );
};

export const BlueButtonFinish = (props) => {
  return (
    <Button
      className="shadow-none"
      style={{
        color: "white",
        background: COLORS.blue,
        width: "40%",
        margin: 30,
        fontSize: 14,
        padding: 10,
      }}
      size={"lg"}
      type="submit"
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

export const FormFooter = styled.div`
  position: absolute;
  bottom: 0;
  min-height: 60px;
  widthx: 100%;
  padding: 10px;
  background-color: ${COLORS.blue};
`;

export const FormLeft = styled.div`
  background-color: ${COLORS.blue};
  min-height: 400px;
`;

export const FormHeader = styled.h1`
  color: white;
  padding-top: 30px;
  text-align: center;
`;

export const FormRightHeader = styled.h3`
  color: ${COLORS.blue};
  text-align: center;
`;
export const FormRight = styled.div`
  min-height: 400px;
  border: solid 3pt ${COLORS.blue};
  padding: 20px;
  margin-top: 50px;
`;

export const FormRightQuick = styled.div`
  min-height: 400px;
  border: solid 3pt ${COLORS.blue};
  min-width: 600px;
  max-width: 600px;
  max-height: 400px;
`;

export const FormAssign = (props) => {
  return (
    <Row noGutters>
      <Col xs={10}>
        <FormRightHeader>{props.title}</FormRightHeader>
      </Col>
      <Col
        xs={2}
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          zIndex: 9000,
        }}
        onClick={() => props.handleClose()}
      >
        <TimesIconBlue />
      </Col>
    </Row>
  );
};

export const FormCloseX = () => {
  return (
    <Row noGutters style={{ display: "flex", flexDirection: "row-reverse", zIndex: 9000 }}>
      <TimesIconBlue />
    </Row>
  );
};

export const ListPageTop = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);

  return (
    <>
      <PageTitle>{props.pageTitle}</PageTitle>
    </>
  );
};

export const AddFormButton = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  console.log("click");
  const handleClick = (e) => {
    dispatch2(["SHOW_ADD_EDIT_FORM", "add"]);
    let formInitialValues = {};
    dispatch2(["SET_FORM_INITIAL_VALUES", {}]);
    dispatch2(["SHOW_FORM_ASSIGN_TO_LISTS", false]);
  };
  return <OrangeButton onClick={(e) => handleClick(e)}>Add New</OrangeButton>;
};

export const TableHeaderNoAdd = (props) => {
  return (
    <Row2
      style={{
        fontSize: 20,
        background: COLORS.blue,
        color: "white",
        padding: 5,
      }}
    >
      <Col
        style={{
          fontSize: 20,
        }}
      >
        {props.title}
      </Col>
      <Col
        style={{
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        {props.children}
      </Col>
    </Row2>
  );
};

export const TableHeader = (props) => {
  return (
    <Row
      style={{
        fontSize: 20,
        background: COLORS.blue,
        color: "white",
        padding: 5,
      }}
    >
      <Col
        style={{
          fontSize: 20,
        }}
      >
        {props.title}
      </Col>
      <Col
        style={{
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        {" "}
        <AddFormButton />
      </Col>
    </Row>
  );
};
export const TableHeader2 = (props) => {
  return (
    <div
      style={{
        fontSize: 20,
        background: COLORS.blue,
        color: "white",
        padding: 5,
        flex: "1 1 100%",
      }}
    >
      <div
        style={{
          fontSize: 20,
        }}
      >
        {props.title}
      </div>
    </div>
  );
};

export const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      <BoxWithLabelW label={props.label}>
        <DatePicker
          {...field}
          {...props}
          selected={(field.value && new Date(field.value)) || null}
          onChange={(val) => {
            setFieldValue(field.name, val);
          }}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dateFormat={"yyyy-MM-dd"}
        />
      </BoxWithLabelW>
    </>
  );
};

export const FormLeftCancel = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);

  return <BlueButton onClick={() => dispatch2(["CLOSE_ADD_EDIT_FORM"])}>{"Cancel"}</BlueButton>;
};

export const FormRightSave = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);

  return <SaveButton form={props.formId}>Save</SaveButton>;
};

export const AddEditForm = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);

  return (
    <FormRight style={{ marginBottom: 150 }}>
      <Col style={{ borderx: "solid blue" }}>{props.children}</Col>
    </FormRight>
  );
};

export const AddEditFormWithLeftDiv = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);

  return (
    <Row
      style={{
        border: `solid 3pt ${COLORS.blue}`,
        width: "100%",
        marginLeft: 50,
      }}
    >
      <Col
        xs={4}
        style={{
          backgroundColor: `${COLORS.blue}`,
          minHeight: 400,
          display: "flex",
          border: `solid 1pt ${COLORS.blue}`,
          flexDirection: "column",
        }}
      >
        <Col style={{ padding: "5px" }}>
          {" "}
          <FormHeader>{listState.formAction}</FormHeader>
        </Col>
        <FormLeftCancel />
      </Col>
      <Col xs={8} style={{ borderx: "solid blue" }}>
        {props.children}
      </Col>
    </Row>
  );
};

export const ModalTitleRow = (props) => {
  const x = {
    backgroundColor: COLORS.blue,
    margin: "5px 0",
    border: "none",
  };
  return (
    <Row>
      <Col style={{ display: "flex", justifyContent: "flex-start" }}>
        <div className={"h4"}>{props.title}</div>
      </Col>
      <Col style={{ display: "flex", justifyContent: "flex-end" }}>
        <button style={x} onClick={props.clickevent}>
          <TimesIcon color={"white"} />
        </button>
      </Col>
    </Row>
  );
};

export const MyTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <BlockLabel htmlFor={props.id || props.name}>{label}</BlockLabel>
        <input
          style={{ width: "100%", borderTop: "none" }}
          className="text-input"
          {...field}
          {...props}
          max={props.max}
        />
        {meta.touched && meta.error ? <ErrorMessage name={props.name} component={StyledErrorMessage} /> : null}
      </div>
    </>
  );
};

export const MyTextAreaInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <BlockLabel htmlFor={props.id || props.name}>{label}</BlockLabel>
        <textarea
          rows={5}
          style={{ width: "100%", minHeight: 100, borderTop: "none", resize: "none" }}
          {...field}
          {...props}
        />
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </div>
    </>
  );
};

export const TextArea = (props) => {
  return (
    <>
      <BoxWithLabelW label={props.label}>
        <Field as="textarea" rows={props.rows || 5} name={props.name} style={{ resize: "none" }} />
      </BoxWithLabelW>
    </>
  );
};

export const TextDisplay = (props) => {
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <BlueText>{props.label}</BlueText>
        <div>{props.data}</div>
      </div>
    </>
  );
};

export const TextInput = (props) => {
  return (
    <>
      <BoxWithLabelW label={props.label}>
        <Field as="input" name={props.name} type={props.type || "text"} placeholder={props.placeholder} />
      </BoxWithLabelW>
    </>
  );
};

export const CheckBoxX = (props) => {
  return (
    <BoxWithLabelW label={props.label}>
      <Field as="input" name={props.name} type={"checkbox"} checked={props.defaultChecked} />
    </BoxWithLabelW>
  );
};
export const CheckBoxxx = (props) => {
  return (
    <BoxWithLabelW label={props.label}>
      <Field name={props.name} type={"checkbox"} />
    </BoxWithLabelW>
  );
};
export const CheckBox = ({ label, name, blue }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", marginTop: 15 }}>
      <div
        style={{
          padding: 5,
          paddingLeft: 10,
          paddingRight: 10,
          fontSize: 12,
          backgroundColor: "white",
          color: COLORS.blue,
          marginRight: 20,
          fontWeight: "bold",
        }}
      >
        {label}
      </div>
      <Field name={name} type="checkbox" className={"square"} />
    </div>
  );
};
export const PhoneInput = ({ label, ...props }) => {
  const [value, setValue] = useState();

  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <BlockLabel
          short={props.short}
          htmlFor={props.id || props.name}
          placeholder="Enter phone number"
          value={value}
          onChange={setValue}
          country={User.countryCode}
        >
          {label}
        </BlockLabel>
        <PhoneInput className="text-input" {...field} {...props} />
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </div>
    </>
  );
};

export const MyCheckbox = ({ children, ...props }) => {
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <>
      <BlockLabel className="checkbox" style={{ position: "relative" }}>
        <input
          type="checkbox"
          style={{ marginRight: 10 }}
          defaultChecked={props.defaultChecked}
          {...field}
          {...props}
        />{" "}
        {children}
      </BlockLabel>

      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </>
  );
};

export const DateWithLabel = (props) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", width: 200 }}>
      <BlockLabel>{props.label}</BlockLabel>

      <DatePicker style={{ width: "100%" }} />
    </div>
  );
};

export const Active = (props) => {
  return (
    <div style={{ display: "flex", flexWrap: "nowrap", alignItems: "flex-end" }}>
      <PrimaryTextBlue5m onClick={() => props.handleInactive(1)} style={{ cursor: "pointer" }}>
        {"active"}
      </PrimaryTextBlue5m>
      <PrimaryTextBlue5m onClick={() => props.handleInactive(0)} style={{ cursor: "pointer" }}>
        {"inactive"}
      </PrimaryTextBlue5m>
      <PrimaryTextBlue5m onClick={() => props.handleInactive("")} style={{ cursor: "pointer" }}>
        {"both"}
      </PrimaryTextBlue5m>
    </div>
  );
};

export const Note = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  const [addNoteType, setAddNoteType] = useState(true);
  const [currentNoteInput, setCurrentNoteInput] = useState("");

  const handleNoteInputChange = (e) => {
    setCurrentNoteInput(e.target.value);
  };

  const onClickCheck = () => {
    props.saveNotes(currentNoteInput, addNoteType);
    setCurrentNoteInput("");
  };

  return (
    <>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <PrimaryTextBlue>{`Adding ${addNoteType ? "Public" : "Private"} Note for ${props.name}`}</PrimaryTextBlue>
        <HelpText>click eye to change note from private to public</HelpText>
      </Row>
      <Row>
        <Col xs={1}>
          <img
            alt={addNoteType ? "public" : "private"}
            style={{ maxHeight: "20px", resize: "none" }}
            src={addNoteType ? publicNoteImg : privateNoteImg}
            onClick={() => setAddNoteType(!addNoteType)}
          />
        </Col>
        <Col>
          <textarea
            style={{ minWidth: "100%", marginBottom: 20 }}
            rows={6}
            onChange={(e) => handleNoteInputChange(e)}
            autoFocus
            value={currentNoteInput}
            onBlur={() => onClickCheck()}
          />
        </Col>
      </Row>
    </>
  );
};

export const ActiveCheck = (props) => {
  return (
    <div>
      <label
        style={{
          color: "rgb(102, 102, 102)",
          display: "inline - block",
          fontSize: 12,
          fontStyle: "italic",
          marginTop: 1,
        }}
      >
        <input
          type="checkbox"
          onClick={() => props.onClick()}
          style={{
            margin: 3,
          }}
        />
        show inactive
      </label>
    </div>
  );
};

export const UserCan = ({ doThis, isThis, ifFarm, what, children }) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  /*console.log(doThis);
  console.log(isThis);
  console.log(ifFarm);
  console.log(what);
*/
  if (ifFarm) {
    /*console.log(ifFarm);
    console.log(mystate.farmDefaults[ifFarm]);
    console.log(!mystate.farmDefaults[ifFarm]);
*/
    if (!mystate.farmDefaults[ifFarm]) {
      return null;
    } else {
    }
  }

  if (doThis) {
    /* console.log(doThis);
    console.log(listState.userPermissions);

    console.log(listState.userPermissions.includes(doThis));
    console.log(!listState.userPermissions.includes(doThis));
*/
    if (!listState.userPermissions) return null;
    if (!listState.userPermissions.includes(doThis)) {
      return null;
    } else {
    }
  }

  if (isThis) {
    /*  console.log(isThis);
    console.log("did we get this far?");
*/
    if (!isThis.includes(Auth.getUserType())) {
      // console.log("getUserType NOT - return null");
      return null;
    } else {
    }
  }

  return children;
  return null;
};

export const BackTo = (props) => {
  let history = useHistory();
  return (
    <div
      style={{
        color: COLORS.blue,
        cursor: "pointer",
        fontSize: "small",
      }}
      onClick={
        // () => history.goBack()
        () => history.push(`${props.url}`)
      }
    >
      {`<- back to ${props.pagename}`}
    </div>
  );
};

export const BackToClose = (props) => {
  let history = useHistory();
  return (
    <div
      style={{
        color: props.color || COLORS.blue,
        cursor: "pointer",
      }}
      onClick={
        // () => history.goBack()
        () => history.push(`${props.url}`)
      }
    >
      <RemoveIconNoPosition color={COLORS.blue} size={"2x"} />{" "}
    </div>
  );
};

export const HelpDiv = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div
        style={{
          fontSize: 20,
          color: COLORS.orange,
          display: "flex",
          justifyContent: "space-between",
        }}
        onClick={() => setOpen(!open)}
      >
        {props.title} {open ? <Asc color={COLORS.orange} /> : <Desc color={COLORS.orange} />}
      </div>
      {open && <div>{props.children}</div>}
    </>
  );
};

const buttonStyle = {
  backgroundColor: COLORS.orange,
  padding: "5px 10px",
  textAlign: "middle",
  color: "white",
  minHeight: "40px",
  maxHeight: 40,
  minWidth: "100px",
  margin: 5,
  borderRadius: 5,
  border: "none",
};

const buttonStyleSm = {
  ...buttonStyle,
  minHeight: 30,
};

const buttonStyleMed = {
  backgroundColor: COLORS.orange,
  padding: "10px 10px",
  textAlign: "middle",
  color: "white",
  fontSize: 16,
  minWidth: "100px",
  margin: 5,
  borderRadius: 5,
  border: "none",
};

export const ButtonOrange = (props) => {
  return (
    <button style={buttonStyle} onClick={props.onClick} type={props.type}>
      {props.label}
    </button>
  );
};

export const ButtonOrangeDisable = (props) => {
  const [disabled, setDisabled] = useState(false);

  function handleClick() {
    if (disabled) console.dir("disabled");
    if (disabled) return;
    setDisabled(true);
    props.onClick();
  }
  return (
    <button style={buttonStyle} disabled={disabled} onClick={() => handleClick()} type={props.type}>
      {disabled ? "sending" : props.label}
    </button>
  );
};

export const ButtonColor = (props) => {
  return (
    <button style={{ ...buttonStyle, background: props.color }} onClick={props.onClick}>
      {props.label}
    </button>
  );
};

export const ButtonBlue = (props) => {
  return (
    <button style={{ ...buttonStyle, backgroundColor: COLORS.blue }} onClick={props.onClick}>
      {props.label}
    </button>
  );
};

export const ButtonOrangeSm = (props) => {
  return (
    <button style={buttonStyleSm} onClick={props.onClick}>
      {props.label}
    </button>
  );
};

export const ButtonOrangeInverse = (props) => {
  return (
    <button
      style={{
        ...buttonStyle,
        backgroundColor: "white",
        border: `solid 1pt ${COLORS.orange}`,
        color: COLORS.orange,
        fontWeight: "bold",
      }}
      onClick={props.onClick}
    >
      {props.label}
    </button>
  );
};

export const ButtonOrangeMed = (props) => {
  return (
    <button style={buttonStyleMed} onClick={props.onClick}>
      {props.label}
    </button>
  );
};

export const ButtonBlueMed = (props) => {
  return (
    <button style={{ ...buttonStyleMed, backgroundColor: COLORS.blue }} onClick={props.onClick}>
      {props.label}
    </button>
  );
};

export const TextOrange = (props) => {
  return <span style={{ color: COLORS.orange }}>{props.text}</span>;
};

export const OpenClose = (props) => {
  return (
    <div
      onClick={props.onClick}
      style={{
        background: "white",
        color: COLORS.blue,
        padding: 10,
        minWidth: 100,
        minHeight: 40,
        marginTop: 10,
        border: "solid 1pt lightgrey",
      }}
    >
      {props.show ? (
        <div style={{ display: "flex", justifyContent: "flexStart", alignItems: "center" }}>
          <span style={{ marginRight: 10, fontSize: 14 }}> {"Close Filter "} </span>
          <Asc size={props.size || "2x"} />
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <span style={{ marginRight: 10, fontSize: 14, cursor: "pointer" }}>{"View Filter "}</span>
          <Desc size={props.size || "2x"} />
        </div>
      )}
    </div>
  );
};

export const ReportChoice = (props) => {
  return (
    <Link to={`${props.url}`}>
      <div
        className="reportChoice"
        style={{
          border: "solid lightgrey 1pt",
          borderRadius: 20,
          width: 300,
          height: 120,
          padding: 15,
          margin: 5,
          cursor: "pointer",
        }}
      >
        <div className="reportChoiceTitle" style={{ fontSize: 16, fontWeight: "bold", color: COLORS.textgrey }}>
          {props.title}
        </div>
        <div className="reportChoiceDesc" style={{ fontSize: 12, color: COLORS.textgrey }}>
          {props.detail}
        </div>
        <div className="reportChoiceDesc" style={{ fontSize: 10, color: COLORS.textgrey, fontStyle: "italic" }}>
          {props.hint}
        </div>
      </div>
    </Link>
  );
};

export const Limit = ({ data = [], offset = 100, limit = 100, total = 0, setOffset = () => {} }) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  let currentTotal = data.length;
  return (
    <div>
      {data && currentTotal > 0 && (
        <>
          {total - currentTotal > 0 && (
            <ButtonOrange onClick={() => setOffset(currentTotal + offset)} label={`Next ${limit}`} />
          )}
          <div>{`Showing  ${currentTotal} of ${total} records`}</div>
        </>
      )}
      {data && data.length == 0 && <div>{"No Records Returned"}</div>}
    </div>
  );
};

export const CheckBoxSquare = (props) => {
  return (
    <div
      style={{
        marginTop: "30px",
        marginBottom: "10px",
        display: "flex",
        justifyContent: "flex-start",
        flex: "1 1 100%",
      }}
    >
      <div onClick={() => props.onClick()}>
        {props.check ? <CheckSquare fontSize={34} /> : <Square fontSize={34} />}
      </div>
      <div style={{ marginLeft: 10, alignItems: "center", display: "flex", fontSize: 16 }}>{props.label}</div>
    </div>
  );
};
