import React, { useReducer, Fragment, useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import { MyContext } from "./App";
import { valueFromId } from "./momentUtils";
import { Row, Col, Container, Modal, ListGroup } from "react-bootstrap";
import AuthHelperMethods from "./AuthHelperMethods";
import BlueCheckFuture from "./blueCheckFuture";
import { useAlert } from "react-alert";
import styled from "styled-components";
import { StyledList, StyledListGroup, BlockLabel } from "./styleCustom";
import { CheckSquare, Square } from "./icons";
import { listItemStyle, boxStyle } from "./styleConstants";

import { blueCheck, downtArw } from "./images";
const CheckImg = () => {
  return <img src={blueCheck} style={{ padding: "2px", width: 20, height: 20 }} />;
};
const Auth = new AuthHelperMethods();

const SelectList = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const [searchTerm, setSearchTerm] = useState("");
  const takenStyle = { backgroundColor: "#e6e6e6" };
  useEffect(
    () => {
      console.log("useEffect");
      window.searchinputRef = searchinputRef;
      searchinputRef.current.focus();
    },
    //eslint-disable-next-line
    []
  );

  const fullDataList = props.fullDataList;
  const subDataList = props.subDataList;
  console.log(fullDataList);
  console.log(subDataList);

  //const [a, setA] = useState(
  const availableList = () => {
    console.log(fullDataList);
    console.log(subDataList);

    if (fullDataList && subDataList) {
      console.log("yes if");

      return fullDataList
        .filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
        .map((full) =>
          subDataList.map((sub) => sub.id).includes(full.id)
            ? { ...full, available: true, name: full.name }
            : { ...full, available: false, name: full.name }
        );
    } else return [];
  };

  const available = availableList();

  const handleListItemClick = async (objectIn) => {
    var isitinthere = subDataList.some((item) => item.id == objectIn.id);
    console.log("objectIn");
    console.log(isitinthere);
    console.log(objectIn);
    console.log(subDataList);
    //  let listToMap = subDataList.map(el => el.id);
    console.log(subDataList);

    if (objectIn && isitinthere) {
      alert("already selected");
    } else {
      if (objectIn) {
        //**** maybe use props.function instead here  *********//
        props.listClickFunction(objectIn);
        /*  let params {
              id: objectIn.id,
              name: objectIn.name
            };
        const result = await Auth.api( props.url,  "post",  params);*/
        //**** to here -also use dispatch/set state in original fn *********//
      }
    }
  };

  const keyPressed = (event) => {
    if (event.key === "Enter") {
      let v = availableList();
      handleListItemClick(v[0]);
      setSearchTerm("");
    }
  };

  const updateSearchTerm = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const searchinputRef = React.createRef();

  return (
    <>
      <Row
        style={{
          color: "white",
        }}
      >
        <Col xs={"12"}>
          <BlockLabel> {props.title || "available "}</BlockLabel>
          <input
            ref={searchinputRef}
            type="text"
            style={{ width: "100%", border: "solid lightgrey" }}
            onChange={(e) => updateSearchTerm(e)}
            value={searchTerm}
            autoFocus
            onKeyPress={keyPressed}
            placeholder={"start typing here to filter names"}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={"12"}>
          <StyledListGroup>
            {available &&
              available.map((item) => (
                <StyledList key={item.id} onClick={() => handleListItemClick(item)} style={listItemStyle}>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Col xs={"1"}>{item.available ? <CheckSquare /> : <Square />}</Col>
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        fontSize: 10,
                      }}
                    >
                      {item.name}
                    </Col>
                  </Row>
                </StyledList>
              ))}
          </StyledListGroup>
        </Col>
      </Row>
    </>

    //      )}
  );
  //  }
};

export default SelectList;
