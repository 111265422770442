import COLORS from "./styleConstants";
import { dateObjToDBDate } from "./momentUtils";

export const resourceAccessor = {
  instructor: "instructor_id",
  location: "location_id",
  section: "section_id",
  horse: "horse_id",
  tack: "tack_id",
  default: "",
};

export const resourceViews = ["instructor", "location", "section", "horse", "tack", "default"];

export const eventStyleGetter = (event, view) => {
  let newStyle = {
    color: "black",
    borderRadius: "0px",
    border: "none",
  };
  event.color = event.lesson_is_cancelled ? "lightgrey" : event.color;
  if (!event.id) {
    event.color = "yellow";
  }
  if (!event.instructor_id) {
    event.color = "white";
  }
  if (event.color) {
    newStyle.backgroundColor = event.color;
    newStyle.border = "solid 1px";
    newStyle.borderLeft = "solid 5px lightgrey";
  }
  if (view == "day") newStyle = { ...newStyle, maxWidth: "50%" };

  return {
    className: "",
    style: newStyle,
  };
};

export const dayPropGetter = () => {
  return {
    className: "rbc-today",
    style: { background: "white" },
  };
};
