import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Popover, OverlayTrigger, Modal } from "react-bootstrap";
import { MyContext, ListContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import { resourceViews } from "./calendarConstants";

import { useAlert } from "react-alert";
import { ButtonBlue, ButtonOrange, CheckBoxSquare, UserCan } from "./formComponents";
import COLORS from "./styleConstants";
import { dateISOtoStringMed, timeISOtoString } from "./momentUtils";
const Auth = new AuthHelperMethods();

const buttonStyle = {
  color: "white",
  background: process.env.NODE_ENV == "production" ? COLORS.orange : COLORS.blue,
  cursor: "pointer",
  padding: 5,
  paddingLeft: 10,
  paddingRight: 10,
  marginRight: 15,
  minHeight: 40,
  maxHeight: 40,
  minWidth: 150,
  maxWidth: 300,
  borderRadius: 5,
  border: "none",
};

export const Info = ({ currentevent }) => {
  const { mystate, dispatch } = useContext(MyContext);

  let event = currentevent;

  return (
    <div style={{ display: "flex", flex: "1 1 100%", flexDirection: "column" }}>
      <div>
        {" "}
        <strong>
          {event.title} {event.is_allday ? "all day " : event.time}
        </strong>
      </div>
      <div>
        {event.instructor_name} {event.location_name}
      </div>
      <div> {event.studentString}</div>
      <div> {event.horseString}</div>
    </div>
  );
};

export const xAgendaEvent = (event) => {
  return <div onClick={(event) => console.log(event)}>agenda</div>;
};

export const CustomEvent = (event, stayorgo) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);

  event = event.event;
  let currentevent = event.event;
  let popoverClickRootClose = (
    <Popover id="popover-trigger-hover-root-close" style={{ zIndex: 10000, padding: 10 }}>
      <Info currentevent={event} />
    </Popover>
  );

  let eventtime = event.is_allday ? "" : event.time;
  let firststring = `${eventtime}  ${listState.showByStudentName ? event.studentString : event.title}`;
  let firststringnotime = `${listState.showByStudentName ? event.studentString : event.title}`;
  let bottomstring = listState.showByStudentName ? event.title : event.studentString;

  return (
    <div className="customEventContainer" style={{ maxWidth: mystate.view == "month" ? 80 : 200 }}>
      <OverlayTrigger
        id="help"
        trigger={["hover", "focus"]}
        rootClose
        container={this}
        placement="bottom"
        overlay={popoverClickRootClose}
      >
        <div style={{ minHeight: 15 }} className="customevent">
          {mystate.view == "month" && (
            <div
              className="customMonth"
              style={{ minWidth: 100, maxWidth: 100, minHeight: 15 }}
            >{`${firststringnotime}`}</div>
          )}
          {mystate.view == "day" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: event.color,

                color: COLORS.tablegrey,
              }}
            >
              <strong> {firststringnotime}</strong>
              <div>
                {event.instructor_name} {event.location_name}
              </div>
              <div> {bottomstring}</div>
              <div> {event.horseString}</div>
            </div>
          )}
          {(mystate.view == "week" || mystate.view == "agenda") && firststringnotime}
        </div>
      </OverlayTrigger>
    </div>
  );
};

export const BlueResourceButtons = ({ onClickResource, hideResource }) => {
  const { mystate, dispatch } = useContext(MyContext);
  if (mystate.view != "day") return null;
  if (!resourceViews) return null;
  if (hideResource) return null;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        color: COLORS.blue,
        fontSize: 12,
        fontWeight: "bold",
        marginRight: 15,
        flexFlow: "row wrap",
      }}
    >
      <div>{"View day by: "}</div>

      {resourceViews.map((item) => (
        <div
          key={item}
          onClick={() => onClickResource(item)}
          key={item}
          style={{ color: COLORS.blue, padding: "2px 5px", cursor: "pointer" }}
        >
          {`${item}`}
        </div>
      ))}
    </div>
  );
};

export const BulkButtons = ({ hideBulkButtons }) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);

  if (Auth.getUserType() != "ADM") return null;
  if (hideBulkButtons) return null;
  return (
    <button style={buttonStyle}>
      <Link style={{ color: "white" }} to="/admin/bulk">
        {"go to bulk lesson actions"}
      </Link>
    </button>
  );
};

export const ToggleStudentEventName = ({ hide }) => {
  if (Auth.getUserType() == "STU") return null;
  const { listState, dispatch2 } = useContext(ListContext);

  function onToggleChange() {
    dispatch2(["TOGGLE_SHOW_STUDENT_EVENT_NAME"]);
  }

  if (hide) return null;
  return (
    <button onClick={() => onToggleChange()} style={buttonStyle}>
      {listState.showByStudentName ? "show by event name" : "show by client name"}
    </button>
  );
};

export const ToggleShowFilter = ({ hide }) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const [showClientOnly, setShowClientOnly] = useState(true);
  function onToggleChange() {
    dispatch2(["TOGGLE_SHOW_HIDE_FILTER"]);
  }

  if (hide) return null;
  return (
    <button onClick={() => onToggleChange()} style={buttonStyle}>
      {listState.showfilter ? "hide filter choices" : "show filter choices"}
    </button>
  );
};

export const ToggleShowClientShowAll = () => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  // const [showClientOnly, setShowClientOnly] = useState(true);

  useEffect(
    () => {
      if (listState.showClientOrAll) dispatch2(["SET_SEARCH_FILTER", [listState.currentClient.student_id], "student"]);
      else dispatch2(["SET_SEARCH_FILTER", [], "student"]);
    },
    //eslint-disable-next-line
    [listState.showClientOrAll]
  );

  useEffect(
    () => {
      if (listState.filter.available == "available") dispatch2(["SET_SHOW_CLIENT_OR_ALL", false]);
    },
    //eslint-disable-next-line
    [listState.filter.available]
  );

  /*const showAll = () => {
    dispatch2(["SET_SEARCH_FILTER", [], "student"]);
    setShowClientOnly(false);
  };

  const showCurrent = () => {
    dispatch2(["SET_SEARCH_FILTER", [listState.currentClient.student_id], "student"]);
    setShowClientOnly(true);
  };
*/

  function onToggleChange() {
    dispatch2(["TOGGLE_SHOW_CLIENT_OR_ALL"]);
  }

  return (
    <>
      <button style={{ ...buttonStyle, width: 300 }} onClick={() => onToggleChange()}>
        {listState.showClientOrAll
          ? "click to show events for all clients"
          : "click to show current client's events only"}
      </button>
    </>
  );
};

export const lessonInWords = (lesson) => {
  let msg = `${listState.currentClient.person_name} has been booked for ${lesson.title} on ${dateISOtoStringMed(
    lesson.scheduled_date
  )} ${!lesson.is_allday ? " at " + timeISOtoString(lesson.scheduled_starttime) : ""}  with ${lesson.instructor_name}.`;
  return msg;
};

export const setAddStudentToLessonParams = (client, account, lesson) => {
  let params = {
    student_id: client.student_id,
    student_email: account.contact_email,
    person_id: client.id,
    account_name: account.first_name,
    id: lesson.id,
    instructor_id: lesson.instructor_id,
    lesson_date_time_id: lesson.id,
    msg: `${client.person_name} has been booked for ${lesson.title_with_date_time} with ${lesson.instructor_name}`,
  };

  // dispatch2(["SET_CURRENT_ADD_LESSON", params]);
  return params;
};

export const checkIfMakeupsForLesson = async (student_id, scheduled_date) => {
  let params = {
    student_id: student_id,
    scheduled_date: scheduled_date,
  };
  try {
    const result = await Auth.api("lesson_people/getAvailableMakeupLessons", "get", params);
    return result.data.makeups;
  } catch (error) {
    return error.message;
  }
};
