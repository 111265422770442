import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, NavLink, useParams } from "react-router-dom";
import { TableHeaderNoAdd, OrangeLinkButton } from "./formComponents";
import { Row, Col } from "react-bootstrap";
import ProductForm from "./productForm";
import ProductTable from "./productTable";
import ProductAssignProgram from "./productAssignProgram";
import SectionForm from "./sectionForm";
import { ListContext } from "./App";
import {
  PrimaryText,
  Td,
  StyledTr,
  TableText1,
  TableText2,
  Th,
  HelpText,
  StyledLink,
  Container,
  Row1,
  Row2,
  ContainerH,
  ContainerV,
} from "./styleCustom";
import COLORS from "./styleConstants";
import AuthHelperMethods from "./AuthHelperMethods";
import { MyContext } from "./App";
import { AddIconNP } from "./icons";
import SectionAssignStudent from "./sectionAssignStudent";
import { Active, CancelRow } from "./searchFilterReports";
import { active_options } from "./Constants2";
import FilterForm from "./filterForm";
import PrivateRoute from "./privateRoute";

const Auth = new AuthHelperMethods();

const SectionTable = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  useEffect(
    () => {
      console.log("SECTION TABLE USEEFFECT");
      const fetchData = async () => {
        let params = { ...listState.filter };

        const result = await Auth.api("sections/sectionList", "get", params);
        dispatch2(["GET_SECTION_LIST", result.data]);
      };
      fetchData();

      //  }
    },
    //eslint-disable-next-line
    [listState.filter]
  );

  useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        const result = await Auth.api("options/index", "get", params);
        dispatch({
          type: "UPDATE_OPTIONS",
          options: result.data,
        });
      };
      fetchData();
    },
    //eslint-disable-next-line
    [listState.sectionList]
  );

  const getInitialValue = (options) => {
    if (options && options.length == 1) return options[0].value;
    else return "";
  };

  const addInitialValues = {
    name: "",
    duration: "",
    max_enrollment: "",
    program_id: getInitialValue(mystate.options_program),
    location_id: getInitialValue(mystate.options_location),
    instructor_id: getInitialValue(mystate.options_instructor),
  };

  const handleClickEditSection = (section) => {
    console.log(section);
    let formInitialValues = {
      id: section.id,
      name: section.section_name || "",
      duration: section.duration || "",
      max_enrollment: section.max_enrollment || "",
      program_id: section.program_id || "",
      location_id: section.location_id || "",
      instructor_id: section.instructor_id || "",
      default_section_id: section.default_section_id || "",
      product_id: section.product_id,
    };

    dispatch2(["SET_FORM_INITIAL_VALUES", formInitialValues]);
  };
  const updateIsActive = async (id) => {
    let params = { ...listState.filter };
    params.id = id;
    const result = await Auth.api("sections/updateActivationStatus", "post", params);
    // dispatch2(["GET_SECTION_LIST", result.data]);
    //dispatch2(["SECTION_EDIT", result.data]);
    dispatch2(["GET_SECTION_LIST", result.data]);

    console.log(result.data);
  };

  if (!listState.sectionList) return null;
  return (
    <>
      {listState.sectionList && (
        <>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
            <span style={{ marginRight: 10 }}>{"Showing:"}</span>
            <Active field={"is_active"} default={"1"} options={active_options} />
          </div>
          <table style={{ width: "100%" }}>
            <thead>
              <tr style={{ color: `${COLORS.blue}` }}>
                <Th>
                  {" "}
                  <div>{"Name"}</div>
                  <HelpText>{"Click on Section Name to edit"}</HelpText>
                </Th>
                <Th>{"duration"}</Th>
                <Th>{"max enrollment"}</Th>
                <Th>{"instructor"}</Th>
                <Th>{"location"}</Th>
                <Th>{"program"}</Th>
                <Th>{"default package"}</Th>
                <Th style={{ textAlign: "center" }}>{"horses"}</Th>

                <Th style={{ textAlign: "center" }}>{"students"}</Th>
              </tr>
            </thead>
            <tbody>
              {listState.sectionList &&
                listState.sectionList.length > 0 &&
                listState.sectionList.map((section) => (
                  <StyledTr key={section.id} recent={section.recent}>
                    <Td>
                      <StyledLink
                        to={`${props.match.url}/sectionform/${section.id}`}
                        onClick={() => handleClickEditSection(section)}
                      >
                        <TableText2>{section.section_name} </TableText2>
                      </StyledLink>
                    </Td>

                    <Td>{section.duration || ""}</Td>
                    <Td>{section.max_enrollment || ""}</Td>
                    <Td>{section.instructor_name || ""}</Td>
                    <Td>{section.location_name || ""}</Td>
                    <Td>{section.program_name || ""}</Td>
                    <Td>{section.product_name || ""}</Td>

                    <Td style={{ textAlign: "center" }}>
                      <StyledLink to={`/admin/sectionHorses/${section.id}`}>
                        <AddIconNP />
                      </StyledLink>
                    </Td>

                    <Td style={{ textAlign: "center" }}>
                      <StyledLink to={`/admin/sectionStudents/${section.id}`}>
                        <AddIconNP />
                      </StyledLink>
                    </Td>
                    <Td onClick={() => updateIsActive(section.id)} style={{ color: "red", cursor: "pointer" }}>{`${
                      section.is_active ? "inactivate" : "activate"
                    }`}</Td>
                  </StyledTr>
                ))}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};
export default SectionTable;
