import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, Link, useParams, useHistory, Switch } from "react-router-dom";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
//import "./styleFormik.css";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col, Modal } from "react-bootstrap";
import SelectList from "./selectList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PageTitle, TableText1, TableText2 } from "./styleCustom";
import PrivateRoute from "./privateRoute";

import moment from "moment";
import {
  dateISOtoStringShort,
  currentDayToDB,
  dateTimeISOtoStringShort,
  dateISOtoStringShortWithYear,
} from "./momentUtils";
import SearchFilterDate from "./searchFilterDates";
import { ListContext, MyContext } from "./App";
import { AdminPackageReport } from "./adminPackageReport";
import COLORS from "./styleConstants";
import { useAlert } from "react-alert";
import { SelectTemplateFilter, SelectNoLabel, CancelRow, Active } from "./searchFilterReports";
import { FilterActive } from "./calendarFilter";
import { CSVLink, CSVDownload } from "react-csv";
import SelectAccountHolder from "./selectAccountHolder";
const Auth = new AuthHelperMethods();

//****************************************************///

const ssm = 50;
const smd = 100;
const slg = 200;

const ORANGEBUTTON = {
  backgroundColor: COLORS.orange,
  padding: 8,
  color: "white",
  fontSize: 12,
  minWidth: 100,
  margin: 5,
  borderRadius: 5,
  border: "medium none,",
  borderColor: COLORS.orange,
};

const Csv = ({ data, filename }) => {
  return (
    <button style={ORANGEBUTTON}>
      <CSVLink data={data} filename={`${filename}.csv`} style={{ color: "white" }}>
        Download CSV
      </CSVLink>
    </button>
  );
};

const Head = ({ label, lg, md, sort, changeSort = () => {} }) => {
  return (
    <div
      style={{ width: lg ? slg : md ? smd : ssm, background: "lightgrey", fontWeight: "bold", padding: 10 }}
      onClick={() => changeSort(sort)}
    >
      {label}
    </div>
  );
};
const Body = (props) => {
  return (
    <div style={{ width: props.lg ? slg : props.md ? smd : ssm, padding: 10, borderBottom: "solid lightgrey 1pt" }}>
      {" "}
      {props.label}
    </div>
  );
};

export const AllUnpaidLessons = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [sortBy, setSortBy] = useState("scheduled_date");
  const [direction, setDirection] = useState("asc");
  const alert = useAlert();
  let history = useHistory();
  const [unpaidLessons, setUnpaidLessons] = useState([]);
  const [reportData, setReportData] = useState([]);

  useEffect(
    () => {
      const fetchData = async () => {
        dispatch({ type: "FETCH_INIT" });
        // let params = { ...listState.filter };

        let params = { ...listState.filter, date1: listState.filter.date5, date2: listState.filter.date6 };
        console.log(params);
        params.sortBy = sortBy;
        params.direction = direction;
        params.report = "allUnpaid";

        try {
          const result = await Auth.api("lesson_people/lessonPaymentReports", "get", params);
          setUnpaidLessons(result.data.lessons);

          setReportData(
            result.data.lessons.map((lesson) => ({
              client: lesson.person_name,
              event: lesson.lesson_name,
              date: dateISOtoStringShortWithYear(lesson.lesson_date),
              status:
                lesson.lesson_is_cancelled == 1
                  ? "lesson cancelled"
                  : lesson.student_is_cancelled
                  ? `student cancel: ${lesson.enrollment_status_name}`
                  : null,
            }))
          );

          dispatch({ type: "FETCH_SUCCESS" });
        } catch {
          dispatch({ type: "FETCH_ERROR" });
        }
      };

      if (listState.filter.date5 && listState.filter.date6) fetchData();
    },
    //eslint-disable-next-line
    [listState.filter, sortBy, direction]
  );

  const changeSort = (sortIn) => {
    console.log(sortIn);
    setSortBy(sortIn);
    setDirection(direction == "asc" ? "desc" : "asc");
  };

  return (
    <div>
      <FilterActive lessonCancel />
      {1 == 2 && (
        <Active
          field={"include_makeups"}
          default={0}
          options={[
            { value: 1, label: "yes" },
            { value: 0, label: "no" },
          ]}
          label={"Include Makeups?"}
        />
      )}

      <SearchFilterDate default={"current"} label={"dates between"} datefield1={"date5"} datefield2={"date6"} />
      <Csv data={reportData} filename={"unpaid"} />

      <div style={{ marginTop: 20 }}>
        <h5 style={{ color: COLORS.blue }}>{"Unpaid Lessons"}</h5>

        <h6 style={{ color: COLORS.blue }}>{"The following lessons are unpaid"}</h6>
        <div>
          {`You are viewing unpaid lessons where the scheduled date  falls between ${dateISOtoStringShortWithYear(
            listState.filter.date5
          )} and ${dateISOtoStringShortWithYear(listState.filter.date6)}`}
        </div>
      </div>
      {unpaidLessons && unpaidLessons.length > 0 && (
        <div>
          <div style={{ display: "flex", flexDirection: "row", flex: "1 1 100%" }}>
            {" "}
            <Head lg label={"Client"} changeSort={changeSort} sort={"person_name"} />
            <Head lg label={"Lesson Date"} changeSort={changeSort} sort={"scheduled_date"} />
            <Head lg label={"Lesson Name"} changeSort={changeSort} sort={"lesson_name"} />
            <Head lg label={"Status"} />
          </div>
          {unpaidLessons.map((lesson) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                color: lesson.lesson_is_cancelled == 1 ? "red" : "black",
              }}
            >
              <Body lg label={lesson.person_name} />

              <Body lg label={dateISOtoStringShortWithYear(lesson.lesson_date)} />
              <Body lg label={lesson.lesson_name} />
              <Body
                lg
                label={
                  lesson.lesson_is_cancelled == 1
                    ? "lesson cancelled"
                    : lesson.student_is_cancelled
                    ? `student cancel: ${lesson.enrollment_status_name}`
                    : null
                }
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const LastLesson = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [sortBy, setSortBy] = useState("scheduled_date");
  const [direction, setDirection] = useState(true);
  const alert = useAlert();
  let history = useHistory();
  const [lastPaidLesson, setLastPaidLesson] = useState([]);
  const [reportData, setReportData] = useState([]);

  useEffect(
    () => {
      const fetchData = async () => {
        console.log("LastLesson");

        dispatch({ type: "FETCH_INIT" });
        let params = { ...listState.filter };
        params.sortBy = sortBy;
        params.direction = direction;
        params.report = "lastPaid";
        try {
          const result = await Auth.api("lesson_people/lessonPaymentReports", "get", params);
          setLastPaidLesson(result.data.lessons);
          setReportData(
            result.data.lessons.map((lesson) => ({
              date: dateISOtoStringShortWithYear(lesson.scheduled_date),

              client: lesson.person_name,
              package: lesson.product_name,
              purchased: dateISOtoStringShortWithYear(lesson.purchase_date),
            }))
          );

          dispatch({ type: "FETCH_SUCCESS" });
        } catch {
          dispatch({ type: "FETCH_ERROR" });
        }
      };
      if (listState.filter.date1 && listState.filter.date2) fetchData();
    },
    //eslint-disable-next-line
    [listState.filter, sortBy, direction]
  );

  const changeSort = (sortIn) => {
    console.log(sortIn);
    setSortBy(sortIn);
    setDirection(direction == "asc" ? "desc" : "asc");
  };

  const Description = () => {
    return (
      <div style={{ color: COLORS.blue }}>
        <h6>
          The following students are using their last lesson in their package on the specified date. These do not
          include booked makeup lessons.
        </h6>
        <h6>
          {
            "The default is not to include lessons that were cancelled by the barn, but you can change that with the selections above."
          }
        </h6>
      </div>
    );
  };
  const DateDescription = () => {
    return (
      <div>
        {`You are viewing lessons where the last paid lesson date falls between ${dateISOtoStringShort(
          listState.filter.date1
        )} and ${dateISOtoStringShort(listState.filter.date2)}`}
      </div>
    );
  };

  return (
    <div>
      <FilterActive lessonCancel />

      <SearchFilterDate default={"current"} label={"dates between"} datefield1={"date1"} datefield2={"date2"} />
      <Csv data={reportData} filename={"lastPaid"} />

      <div style={{ marginTop: 20 }}>
        <h5 style={{ color: COLORS.blue }}>{"Date of Last Paid Lesson"}</h5>

        <Description />
        <DateDescription />
        {lastPaidLesson.length > 0 && (
          <div>
            <div style={{ display: "flex", flexDircetion: "row" }}>
              {" "}
              <Head lg label={"Last Paid Lesson Date"} changeSort={changeSort} sort={"scheduled_date"} />
              <Head lg label={"Client"} changeSort={changeSort} sort={"person_name"} />
              <Head lg label={"Package"} changeSort={changeSort} sort={"product_name"} />
              <Head lg label={"Purchase Date of Package"} changeSort={changeSort} sort={"purchase_date"} />
            </div>
            {lastPaidLesson.map((student) => (
              <div style={{ display: "flex", flexDircetion: "row" }}>
                <Body lg label={dateISOtoStringShort(student.scheduled_date)} />
                <Body lg label={student.person_name} />
                <Body lg label={student.product_name} />
                <Body lg label={dateISOtoStringShort(student.purchase_date)} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export const NextUnpaidLesson = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [sortBy, setSortBy] = useState("scheduled_date");
  const [direction, setDirection] = useState();
  const alert = useAlert();
  let history = useHistory();
  const [nextUnpaidLesson, setNextUnpaidLesson] = useState([]);
  const [reportData, setReportData] = useState([]);
  const d1 = listState.filter.date1;
  const d2 = listState.filter.date2;

  useEffect(
    () => {
      const fetchData = async () => {
        console.log("NextUnpaidLesson");
        dispatch({ type: "FETCH_INIT" });
        // let params = { ...listState.filter };
        let params = { ...listState.filter, date1: listState.filter.date3, date2: listState.filter.date4 };
        //   let params = { ...listState.filter, date1: listState.filter.date5, date2: listState.filter.date6 };
        params.sortBy = sortBy;
        params.direction = direction;
        params.report = "firstUnpaid";

        try {
          const result = await Auth.api("lesson_people/lessonPaymentReports", "get", params);
          setNextUnpaidLesson(result.data.lessons);
          setReportData(
            result.data.lessons.map((lesson) => ({
              date: dateISOtoStringShortWithYear(lesson.scheduled_date),
              client: lesson.person_name,
              event: lesson.lesson_name,
            }))
          );
          console.log("FIRST");
          console.log(result.data.lessons);

          dispatch({ type: "FETCH_SUCCESS" });
        } catch {
          dispatch({ type: "FETCH_ERROR" });
        }
      };
      // if (d1 && d2) fetchData();
      // setTimeout(fetchData(), 5000);

      if (listState.filter.date3 && listState.filter.date4) fetchData();
    },
    //eslint-disable-next-line
    [listState.filter, sortBy, direction]
  );

  const changeSort = (sortIn) => {
    console.log(sortIn);
    setSortBy(sortIn);
    setDirection(direction == "asc" ? "desc" : "asc");
  };

  return (
    <div>
      <FilterActive lessonCancel />

      <SearchFilterDate default={"current"} label={"dates between"} datefield1={"date3"} datefield2={"date4"} />
      <Csv data={reportData} filename={"first_unpaid"} />

      <div style={{ marginTop: 20 }}>
        <h5 style={{ color: COLORS.blue }}>{"Date of First Unpaid Lesson"}</h5>

        <h6 style={{ color: COLORS.blue }}>
          {
            "The following students will be finished their package, and the next lesson below is the first without payment"
          }
        </h6>
        <div>{`You are viewing students whose first unpaid lesson  falls between ${dateISOtoStringShort(
          listState.filter.date3
        )} and ${dateISOtoStringShort(listState.filter.date4)}`}</div>
      </div>
      {nextUnpaidLesson && nextUnpaidLesson.length > 0 && (
        <div>
          <div style={{ display: "flex", flexDircetion: "row" }}>
            {" "}
            <Head lg label={"Lesson Date"} changeSort={changeSort} sort={"scheduled_date"} />
            <Head lg label={"Client"} changeSort={changeSort} sort={"person_name"} />
            <Head lg label={"Lesson Name"} changeSort={changeSort} sort={"lesson_name"} />
          </div>
          {nextUnpaidLesson.map((student) => (
            <div style={{ display: "flex", flexDircetion: "row" }}>
              <Body lg label={dateISOtoStringShort(student.scheduled_date)} />
              <Body lg label={student.person_name} />
              <Body lg label={student.lesson_name} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const RecurringPackages = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [packages, setPackages] = useState([]);
  /*
  useEffect(
    () => {
      const fetchData = async () => {
        dispatch({ type: "FETCH_INIT" });
      };
      try {
        const result = await Auth.api("items/getRecurring", "get", params);
        setNextUnpaidLesson(result.data.nextUnpaidLesson);

        dispatch({ type: "FETCH_SUCCESS" });
      } catch {
        dispatch({ type: "FETCH_ERROR" });
      }

      if (listState.filter.date3 && listState.filter.date4) fetchData();
    },
    //eslint-disable-next-line
    [listState.filter]
  );
*/
  return;
};

export const AdminPackages = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const alert = useAlert();
  let history = useHistory();

  useEffect(() => {
    console.log("INITAL USEEFFECT");
    dispatch2(["SET_SEARCH_FILTER", 0, "lesson_is_cancelled"]);
  }, []);

  const ReportChoice = (props) => {
    let today = props.today || "";
    return (
      <div
        className="reportChoice"
        style={{
          border: "solid lightgrey 1pt",
          borderRadius: 20,
          width: 300,
          height: 120,
          padding: 15,
          margin: 5,
          cursor: "pointer",
        }}
        onClick={() => history.push(`/admin/admin/packages/${props.url}/${today}`)}
      >
        <div className="reportChoiceTitle" style={{ fontSize: 16, fontWeight: "bold", color: COLORS.textgrey }}>
          {props.title}
        </div>
        <div className="reportChoiceDesc" style={{ fontSize: 12, color: COLORS.textgrey }}>
          {props.detail}
        </div>
        <div className="reportChoiceDesc" style={{ fontSize: 10, color: COLORS.textgrey, fontStyle: "italic" }}>
          {props.hint}
        </div>
      </div>
    );
  };

  return (
    <>
      <PageTitle>{"Package Reports"}</PageTitle>
      <div style={{ fontSize: 16, color: COLORS.orange }}>{"** new - View Packages Counts As Of Today's Date **"}</div>

      <div
        className={"reportSelection"}
        style={{ display: "flex", flex: "1 1 100%", flexDirection: "row", flexWrap: "wrap", marginBottom: 20 }}
      >
        <ReportChoice
          title={"Packages With Counts As Of Today"}
          detail={"View Packages Purchased during the selected period (defaults to current month). "}
          hint={"You can also edit the package details from this report"}
          url="purchased"
          today={"today"}
        />
        <ReportChoice
          title={"Packages With Counts Based on Future Schedule"}
          detail={"View Packages Purchased during the selected period (defaults to current month)."}
          hint={"You can also edit the package details from this report"}
          url="purchased"
          today={"scheduled"}
        />
        <ReportChoice
          title={"First Unpaid Lesson"}
          detail={"View Students with a lesson booked after their package ran out."}
          hint={"These students need to purchase a package before this lesson date."}
          url="next"
        />
        <ReportChoice
          title={"Last Paid Lesson"}
          detail={"View Students whose last lesson in their package occurs during this date range."}
          hint={"These students have used all the lessons in their package by this lesson date."}
          url="last"
        />
        <ReportChoice
          title={"Unpaid Lessons"}
          detail={"View all unpaid lessons in this date range."}
          hint={""}
          url="unpaid"
        />
        {1 == 2 && (
          <ReportChoice
            title={"Recurring Packages"}
            detail={"View all packages due to be renewed during this date range."}
            hint={""}
            url="unpaid"
          />
        )}
      </div>

      <div className={"reportArea"}>
        <div className={"selectFilters"} style={{ display: "flex", flexDirection: "row", flex: "1 1 100%" }}>
          <SelectTemplateFilter
            options={mystate.options_program}
            isMulti
            label={"program"}
            list={listState.filter_labels.program || null}
            value={listState.filter.program || null}
            maxWidth={200}
          />
          <SelectTemplateFilter
            options={mystate.options_section}
            isMulti
            label={"section"}
            list={listState.filter_labels.section || null}
            value={listState.filter.section || null}
            maxWidth={200}
          />
          <SelectTemplateFilter
            options={mystate.options_product}
            isMulti
            label={"product"}
            list={listState.filter_labels.product || null}
            value={listState.filter.product || null}
            maxWidth={200}
          />
          <SelectTemplateFilter
            options={mystate.options_student}
            isMulti
            label={"student"}
            list={listState.filter_labels.student || null}
            value={listState.filter.student || null}
            maxWidth={200}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <SelectAccountHolder />
        </div>
        <Switch>
          <PrivateRoute path={`/admin/admin/packages/purchased/:today`} component={AdminPackageReport} />

          <PrivateRoute path={`/admin/admin/packages/last`} component={LastLesson} />
          <PrivateRoute path={`/admin/admin/packages/next`} component={NextUnpaidLesson} />
          <PrivateRoute path={`/admin/admin/packages/unpaid`} component={AllUnpaidLessons} />
          <PrivateRoute path={`/admin/admin/packages/recurring`} component={RecurringPackages} />
        </Switch>
      </div>
    </>
  );
};
