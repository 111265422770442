import React, { Fragment, useState, useContext, useEffect } from "react";
import { MyContext } from "./App";
import ReactTooltip from "react-tooltip";

const TextArea = (props) => {
  const { mystate, dispatch } = useContext(MyContext);

  const handleInputChange = (e) => {
    props.addOrEdit === "add"
      ? dispatch({
          type: "SET_NEW_EVENT",
          data: {
            lesson_notes: e.target.value,
          },
        })
      : console.log("handleLessonNoteEdit");
  };
  return (
    <>
      <textarea
        onChange={(e) => handleInputChange(e)}
        value={mystate.addevent.lesson_notes || ""}
        style={{
          minWidth: "100px",
          width: "100%",
          minHeight: "100px",
        }}
      />
    </>
  );
};

export default TextArea;
