import React from "react";
import ReactTooltip from "react-tooltip";

export const Tooltip = () => {
  return (
    <ReactTooltip
      effect="solid"
      delayShow={20}
      delayHide={200}
      place={"top"}
      className={"customTip"}
      type="dark"
    />
  );
};
