import React, { useReducer, Fragment, useState, useEffect, useContext } from "react";
import ReactDom from "react-dom";
import moment from "moment";
import Settings from "./UserSettings";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import RepeatingDates from "./repeatingDates";

import { MyContext } from "./App";
import {
  getdateinfo,
  calculateEndTime,
  DBTIMEFORMAT,
  DBDATEFORMAT,
  DBDATETIMEFORMAT,
  dateObjToDisplayDate,
  dateObjToDisplayDatetime,
  valueFromId,
  repeatOptions,
} from "./momentUtils";
import {
  DISPLAY_DATETIME,
  DISPLAY_DATE,
  DISPLAY_TIME,
  DATEPICKER_DISPLAY,
  DATEPICKER_DATE_DISPLAY,
} from "./UserSettings";
import { Row, Col, Container, FormCheck, Form } from "react-bootstrap";
import Select from "react-select";
import Checkbox from "./Checkbox";
import { blueCheck } from "./images";

//import moment-recur from "moment-recur";
require("moment-recur");

const TimeAndDate = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const [check, setCheck] = useState(mystate.dateTimeComponent.is_allday || false);

  const start = mystate.dateTimeComponent.start;
  const end = mystate.dateTimeComponent.end;

  const getCurrentDuration = (start, end, unit) => {
    var start = moment(start);
    var end = moment(end);

    return Number(moment.duration(end.diff(start, unit)));
  };

  // for when start time changed by user (not by change of sectionid);
  const handleTimeValueChange = (date) => {
    console.log(date);
    // todo:  add validation that (calculaterd endtime is not less than starttime -impossible if duration is > 0)  and that it doens't go past midnight of the same day
    //? if it does got past midnight either set to 11:55 or give user warning?
    if (date) {
      const currentDur = getCurrentDuration(start, end, "minutes");

      dispatch({
        type: "SET_DATE_TIME_COMPONENT",
        start: date,
        end: calculateEndTime(date, currentDur),
        is_allday: mystate.dateTimeComponent.is_allday,
      });
    }
  };

  const handleEndTimeValueChange = (value) => {
    // todo:  add validation that endtime is not less than starttime and that it doens't go past midnight of the same day
    dispatch({
      type: "SET_DATE_TIME_COMPONENT",
      end: value,
      is_allday: mystate.dateTimeComponent.is_allday,
    });
  };

  const handleAllDayCheck = () => {
    console.log(mystate.dateTimeComponent.is_allday);
    console.log(!mystate.dateTimeComponent.is_allday);
    let m = !mystate.dateTimeComponent.is_allday;

    //let checkValue = !check;
    dispatch({
      type: "SET_DATE_TIME_COMPONENT",
      is_allday: m,
    });

    //  setCheck(checkValue);
  };

  return (
    <>
      {!props.timeOnly && (
        <Row noGutters>
          <DatePicker
            selected={mystate.dateTimeComponent.start}
            dateFormat={DATEPICKER_DATE_DISPLAY}
            onChange={handleTimeValueChange}
            onFocus={(e) => (e.target.readOnly = true)}
          />
        </Row>
      )}
      {!mystate.dateTimeComponent.is_allday && (
        <>
          <Row noGutters>
            <DatePicker
              showTimeSelect
              selected={mystate.dateTimeComponent.start}
              dateFormat={DISPLAY_TIME}
              onChange={handleTimeValueChange}
              showTimeSelectOnly
              timeIntervals={mystate.farmDefaults.timepicker_interval || 15}
              onFocus={(e) => (e.target.readOnly = true)}
            />
          </Row>
          <Row noGutters>
            <DatePicker
              showTimeSelect={!mystate.dateTimeComponent.is_allday}
              selected={end}
              dateFormat={DISPLAY_TIME}
              onChange={handleEndTimeValueChange}
              showTimeSelectOnly
              timeIntervals={mystate.farmDefaults.timepicker_interval || 15}
              onFocus={(e) => (e.target.readOnly = true)}
            />
          </Row>
        </>
      )}

      <Row noGutters style={{ marginTop: "10px" }}>
        <Col
          xs={3}
          onClick={() => handleAllDayCheck()}
          style={{
            border: "solid 1px grey",
            minHeight: "30px",
            minWidth: "30px",
            maxHeight: "30px",
            maxWidth: "30px",
          }}
        >
          {mystate.dateTimeComponent.is_allday ? (
            <img src={blueCheck} style={{ padding: "5px" }} alt={"alldaY"} />
          ) : (
            " "
          )}
        </Col>
        <Col>
          <div
            className="blocklabel"
            style={{
              marginTop: 0,
              textAlign: "left",
              background: "white",
              color: "black",
            }}
          >
            {"Is this an all day event? "}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TimeAndDate;
