import React, { useContext, useReducer, useState } from "react";
import AuthHelperMethods from "./AuthHelperMethods";
import { MyContext } from "./App";
import { useHistory, Redirect } from "react-router-dom";
import { ListContext } from "./App";
import COLORS from "./styleConstants";
import initialStateList from "./initialStateList";
import listPageReducer from "./listPageReducer";
import { ButtonOrange, ButtonOrangeInverse } from "./formComponents";
//import LogoutConfirm from "./logoutConfirm";
const Auth = new AuthHelperMethods();

const Logout = (e) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  let history = useHistory();
  let [go, setGo] = useState(false);
  const logOut = () => {
    Auth.setToken("");
    dispatch({ type: "RESET_STATE" });
    dispatch2(["RESET_STATE"]);
    history.push("/login");
  };

  return <ButtonOrangeInverse onClick={() => logOut()} label={"Logout"} />;
};

export default Logout;
