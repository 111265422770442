import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter, Route, Switch, Link, NavLink, useParams, useHistory, useLocation } from "react-router-dom";
import ReactDOM from "react-dom";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

//import "./styleFormik.css";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col, Modal, ListGroup, Dropdown } from "react-bootstrap";
import SelectList from "./selectList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  DateWithLabel,
  BoxWithLabel,
  MediumPageHeader,
  PageTitle,
  OrangeButtonSm,
  PrimaryText,
  StyledTr,
  TableText1,
  TableText2,
  BoxWithLabelNoM,
  StyledListGroup,
  BlockLabel,
  StyledList,
  Td,
  Th,
  HelpText,
  PrimaryTextList,
  SaveButton,
  PrimaryTextBlue,
  BlueNavLink,
  ContainerV,
  PrimaryText18,
  BlueText,
  ContainerH,
} from "./styleCustom";
import { DATEPICKER_DATE_DISPLAY } from "./UserSettings";
import { BackTo, ButtonOrange, Limit, ButtonOrangeSm, UserCan } from "./formComponents";
import moment from "moment";
import {
  dateObjToDBDate,
  dateISOtoStringShort,
  currentMonthStart,
  currentMonthEnd,
  dateObjToDisplayDate,
  currentDayToDB,
  timeISOtoString,
  dateTimeISOtoStringMed,
  dateISOtoStringMed,
} from "./momentUtils";
import SearchFilter, { ModalFilter } from "./searchFilter";
import SearchFilterDate from "./searchFilterDates";
import { ListContext } from "./App";
import { withRouter } from "react-router-dom";
import COLORS from "./styleConstants";
import AddNote from "./addNote1";
import HorseForm from "./horseForm";
import { EditIconPlain, Check } from "./icons";
import { MyContext } from "./App";
import PrivateRoute from "./privateRoute";
import NotesTable from "./notesTable";
import { HorseAvatar } from "./images";
import { SelectTemplateFilter, Active, ButtonTemplateFilter } from "./searchFilterReports";
import { FileUpload } from "./fileTransfer4.js";
import { CalendarHomePage } from "./CalendarHomePage";

const Auth = new AuthHelperMethods();

const Title = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);

  if (!listState.currentHorse.id) return null;
  return (
    <PageTitle
      style={{
        display: "flex",
        flexWrap: "wrap",
        flex: " 1 1 100%",
        fontSize: 20,
      }}
    >
      {`${props.page} for ${listState.currentHorse.horse_name}`}
    </PageTitle>
  );
};

const Documents = () => {
  return (
    <>
      <Title page="Documents" />

      <FileUpload />
    </>
  );
};

const Info = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  const location = useLocation();
  const profileOnly = location.pathname.startsWith("/admin/calendarGroup/edit");
  const [sections, setSections] = useState([]);
  let horse = listState.currentHorse;
  let params = { horse_id: listState.currentHorse.id };

  useEffect(
    () => {
      const fetchData = async () => {
        console.log(params);
        const result = await Auth.api("section_horses/getHorseSections", "get", params);
        setSections(result.data.horseSection);
      };
      if (listState.currentHorse.id) fetchData();
      return () => {
        dispatch2(["HORSE_DATA", {}]);
      };
    },
    //eslint-disable-next-line
    [listState.currentHorse.id]
  );

  const Sections = () => {
    if (!sections) return null;
    return (
      <>
        <div style={{ fontSize: 20, marginTop: 10 }}>{`Sections`}</div>

        {sections.map((section) => (
          <div key={section.section_id}>{section.section_name}</div>
        ))}
      </>
    );
  };
  if (!horse) return null;
  return (
    <>
      <Title page="Details" />
      {1 == 2 && <FileUpload />}
      <div style={{ display: "flex", flex: "1 1 100%", justifyContent: "center" }}>
        <ContainerH
          className="account"
          style={{
            flex: "1 1 100%",
            maxWidth: 600,
            border: "solid 5px lightgrey",
            padding: 10,
            minHeight: 400,
            marginTop: 50,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              {" "}
              <HorseAvatar style={{ height: 100 }} />{" "}
              <BlueText style={{ fontSize: "14pt", cursor: "pointer" }}>{horse.horse_name}</BlueText>
            </div>
            <Link to={`/admin/horsehome/${listState.currentHorse.id}/edit/edit`}>
              <EditIconPlain fontSize={"20px"} />
            </Link>
          </div>
          <div style={{ fontSize: 20, marginTop: 10 }}>{`Description`}</div>
          <div>
            {`${horse.height == 0 ? "" : horse.height || ""} 
          ${horse.color || ""} 
          ${horse.gender || ""} ${horse.age == 0 ? "" : horse.age || ""} ${horse.desc || ""}`}
          </div>
          <div style={{ fontSize: 20, marginTop: 10 }}>{`Tack`}</div>
          <div>{horse.tack || ""}</div>
          <div style={{ fontSize: 20, marginTop: 10 }}>{`General Notes`}</div>
          <div style={{ marginBottom: 5 }}>{horse.general_notes}</div>
          <div style={{ marginBottom: 5 }}>{horse.general_notes2}</div>
          <div style={{ marginBottom: 5 }}>{horse.general_notes3}</div>
          <Sections />
        </ContainerH>
      </div>
    </>
  );
};
const Schedule = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [offset, setOffset] = useState(100);
  const [total, setTotal] = useState();
  const location = useLocation();

  const profileOnly = location.pathname.startsWith("/admin/calendarGroup/edit");

  /* useEffect(() => {
    return () => {
      return null;
    };
  });
*/
  let params = { ...listState.filter, today: currentDayToDB(), id: listState.currentHorse.id, limit: offset };
  /* useFetch(
    "lesson_date_time_horses/getSchedule",
    "HORSE_DATA",
    "dispatchlist",
    params
  );*/
  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("lesson_date_time_horses/getSchedule", "get", params);
        dispatch2(["HORSE_DATA", result.data.schedule]);
        setTotal(result.data.size);
      };
      fetchData();
    },
    //eslint-disable-next-line
    [listState.currentHorse.id, listState.filter, offset]
  );
  //if (lessons.length == 0) return <div>{"No records"}</div>;

  return (
    <>
      <Title page="Schedule" />
      <div style={{ display: "flex", flex: "1 1 100%", alignItems: "center" }}>
        <BlueText>{"program type"}</BlueText>
        <ButtonTemplateFilter options={mystate.options_program_type} label={"program_type"} />
      </div>
      <SelectTemplateFilter
        options={mystate.options_program}
        isMulti
        label={"program"}
        list={listState.filter_labels.program || null}
        defaultValue={
          (mystate.options_program &&
            listState.filter.program &&
            mystate.options_program.filter((program) => listState.filter.program.includes(program.value))) ||
          null
        }
      />
      <SelectTemplateFilter
        options={mystate.options_instructor}
        isMulti
        label={"instructor"}
        list={listState.filter_labels.instructor || null}
        defaultValue={
          (mystate.options_instructor &&
            listState.filter.instructor &&
            mystate.options_instructor.filter((staff) => listState.filter.instructor.includes(staff.value))) ||
          null
        }
      />

      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <Th> title</Th>

            <Th> date</Th>
            <Th> time</Th>
            <Th> staff</Th>
          </tr>
        </thead>
        <tbody>
          {listState.horsedata &&
            listState.horsedata.length > 0 &&
            listState.horsedata.map((data) => (
              <>
                <tr key={data.id}>
                  <Td style={{ borderBottom: "none" }}>{data.name}</Td>
                  <Td style={{ borderBottom: "none" }}>{data.scheduled_date}</Td>
                  <Td style={{ borderBottom: "none" }}>{timeISOtoString(data.scheduled_starttime)}</Td>
                  <Td style={{ borderBottom: "none" }}>{data.instructor_name}</Td>
                </tr>
                <tr style={{ borderBottom: "solid lightgrey 1pt" }}>
                  {data.lesson_notes && (
                    <Td style={{ fontStyle: "italic", paddingTop: 0, whiteSpace: "pre-wrap" }}>{data.lesson_notes}</Td>
                  )}
                </tr>
              </>
            ))}
        </tbody>
      </table>
      {!profileOnly && <Limit total={total} setOffset={setOffset} data={listState.horsedata} offset={offset} />}
    </>
  );
};

const History = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [offset, setOffset] = useState(100);
  const [total, setTotal] = useState();
  const location = useLocation();

  const profileOnly = location.pathname.startsWith("/admin/calendarGroup/edit");

  let params = { ...listState.filter, today: currentDayToDB(), id: listState.currentHorse.id, limit: offset };

  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("lesson_date_time_horses/getHistory", "get", params);
        dispatch2(["HORSE_DATA", result.data.history]);
        setTotal(result.data.size);
      };
      fetchData();
    },
    //eslint-disable-next-line
    [listState.filter, offset]
  );

  /* useEffect(() => {
    return () => {
      return null;
    };
  });*/

  return (
    <>
      <Title page="History" />
      <div style={{ display: "flex", flex: "1 1 100%", alignItems: "center" }}>
        <BlueText>{"program type"}</BlueText>
        <ButtonTemplateFilter options={mystate.options_program_type} label={"program_type"} />
      </div>
      <SelectTemplateFilter
        options={mystate.options_program}
        isMulti
        label={"program"}
        list={listState.filter_labels.program || null}
        defaultValue={
          (mystate.options_program &&
            listState.filter.program &&
            mystate.options_program.filter((program) => listState.filter.program.includes(program.value))) ||
          null
        }
      />
      <SelectTemplateFilter
        options={mystate.options_instructor}
        isMulti
        label={"instructor"}
        list={listState.filter_labels.instructor || null}
        defaultValue={
          (mystate.options_instructor &&
            listState.filter.instructor &&
            mystate.options_instructor.filter((staff) => listState.filter.instructor.includes(staff.value))) ||
          null
        }
      />
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <Th> title</Th>

            <Th> date</Th>
            <Th> time</Th>
            <Th> staff</Th>
          </tr>
        </thead>
        <tbody>
          {listState.horsedata &&
            listState.horsedata.length > 0 &&
            listState.horsedata.map((data) => (
              <>
                <tr key={data.id}>
                  <Td style={{ borderBottom: "none" }}>{data.name}</Td>
                  <Td style={{ borderBottom: "none" }}>{data.scheduled_date}</Td>
                  <Td style={{ borderBottom: "none" }}>{timeISOtoString(data.scheduled_starttime)}</Td>
                  <Td style={{ borderBottom: "none" }}>{data.instructor_name}</Td>
                </tr>
                <tr style={{ borderBottom: "solid lightgrey 1pt" }}>
                  {data.lesson_notes && (
                    <Td style={{ fontStyle: "italic", paddingTop: 0, whiteSpace: "pre-wrap" }}>{data.lesson_notes}</Td>
                  )}
                </tr>
              </>
            ))}
        </tbody>
      </table>
      {!profileOnly && <Limit total={total} setOffset={setOffset} data={listState.horsedata} offset={offset} />}
    </>
  );
};
const Notes = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [offset, setOffset] = useState(50);
  const [total, setTotal] = useState();
  const usertype = Auth.getUserType();
  const location = useLocation();
  const profileOnly = location.pathname.startsWith("/admin/calendarGroup/edit");

  useEffect(() => {
    return () => {
      return null;
    };
  });

  let params = { horse_id: listState.currentHorse.id, limit: offset };
  /* useFetch(
    "notes_horses/getHorsePageNotes",
    "HORSE_DATA",
    "dispatchlist",
    params
  );*/
  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("notes_horses/getHorsePageNotes", "get", params);
        //  dispatch2(["HORSE_DATA", result.data.notes]);
        dispatch2(["CLIENT_DATA", result.data]);
        setTotal(result.data.size);
      };
      fetchData();
    },
    //eslint-disable-next-line
    [offset]
  );

  return (
    <>
      <Title page="Notes" />

      <NotesTable {...props} />
      {!profileOnly && <Limit total={total} setOffset={setOffset} data={listState.clientdata || []} offset={offset} />}
    </>
  );
};
const Workload = () => {
  return <div>workload</div>;
};

const Edit = (props) => {
  return <HorseForm history={props.history} edit title={props.title} />;
};

const Calendar = () => {
  return <CalendarHomePage />;
};

const Uploads = () => {};

const Actions = (props) => {
  return (
    <>
      <Link to={`${props.match.url}/addNote/1/horse`}>
        <ButtonOrangeSm label={"Add Public Note"} />
      </Link>

      <UserCan isThis={"ADM"}>
        <Link to={`${props.match.url}/addNote/0/horse`}>
          <ButtonOrangeSm label={"Add Private Note"} />
        </Link>
      </UserCan>
    </>
  );
};

const Summary = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  if (!listState.accountHolder) return null;

  return (
    <div className={"SummaryLeft"}>
      {Auth.getUserType() == "INS" && (
        <div style={{ color: COLORS.blue, fontSize: 16 }}>{`${mystate.farmDefaults.farm_name} `}</div>
      )}

      <div
        className="currentHorse"
        style={{ marginBottom: 30, display: "flex", flex: 1, justifyContent: "center", marginTop: 50 }}
      >
        <span style={{ fontSize: 30, fontWeight: "bold" }}>{listState.currentHorse.horse_name}</span>
      </div>
      <div style={{ display: "flex", flex: "1 1 100%", justifyContent: "center" }}>
        <HorseAvatar width={"70px"} height={"70px"} />
      </div>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
        <Actions {...props} />
      </div>
    </div>
  );
};

const BlueNav = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  return (
    <div
      className="BlueNav"
      style={{
        display: "flex",
        background: COLORS.blue,
        justifyContent: "space-evenly",
        flexWrap: "wrap",
        flex: " 1 1 100%",
      }}
    >
      <>
        {1 == 2 && <BlueNavLink to={`${props.match.url}/calendar`}> Calendar</BlueNavLink>}

        <BlueNavLink to={`${props.match.url}/schedule`}>Schedule</BlueNavLink>
        <BlueNavLink to={`${props.match.url}/history`}> History</BlueNavLink>
        <BlueNavLink to={`${props.match.url}/notes/horse`}> Notes</BlueNavLink>
        {Auth.getUserType() == "ADM" && <BlueNavLink to={`${props.match.url}/documents`}>Documents</BlueNavLink>}

        <BlueNavLink to={`${props.match.url}/details`}>Details</BlueNavLink>
      </>
    </div>
  );
};

const HorseHomePage = (props) => {
  const { mystate, dispatch } = useContext(MyContext);

  const { listState, dispatch2 } = useContext(ListContext);

  const [currentHorse, setCurrentHorse] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);
  const [isLaptop, setIsLaptop] = useState(window.innerWidth > 1000);
  let currentHorseId = useParams();
  const location = useLocation();
  const history = useHistory();
  let params = {
    id: currentHorseId.id || null,
  };
  const profileOnly = location.pathname.startsWith("/admin/calendarGroup/edit");
  console.log("profileOnly");
  console.log(profileOnly);
  console.log(location.pathname);

  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("horses/horse", "get", params);
        console.log(result.data.horse);

        dispatch2(["HORSE_GET", result.data.horse]);
        setCurrentHorse(result.data.horse);
        dispatch2(["SET_SEARCH_FILTER_HORSE", currentHorseId.id]);
      };
      fetchData();
    },
    //eslint-disable-next-line

    [currentHorseId.id]
  );

  useEffect(
    () => {
      return () => {
        dispatch2(["RESET_SEARCH_FILTER"]);
      };
    },
    //eslint-disable-next-line
    []
  );
  useEffect(
    () => {
      console.log("Clear ClientHome");

      return () => {
        dispatch2(["HORSE_DATA", []]);
        dispatch2(["CLIENT_DATA", []]);
        dispatch2(["HORSE_GET", {}]);
      };
    },
    //eslint-disable-next-line
    []
  );

  console.log("HorseHomePage");
  //if (!listState.currentHorse) return null;
  //console.log(listState.currentHorse);
  const leftPadding = () => {
    if (isMobile) return 0;
    return 20;
  };
  if (!listState.currentHorse) return null;
  return (
    <>
      {!profileOnly && Auth.getUserType() == "ADM" && (
        <div style={{ display: "flex", flex: "1 1 100%" }}>
          <BackTo url={`/admin/horses`} pagename={"horses"} />
        </div>
      )}

      <div
        style={{
          display: "flex",
          maxWidth: "30%",
          minWidth: 250,
          flexDirection: "column",
          flex: "1 1 auto",
          marginBottom: 60,
        }}
      >
        <Summary {...props} />
      </div>
      <div style={{ display: "flex", flex: "1 1 500px" }}>
        <div
          style={{
            display: "flex",
            flex: isMobile ? " 1 1 100% " : " 1 1 75%",
            flexWrap: "wrap",
            alignContent: "flex-start",
            paddingLeft: leftPadding(),
          }}
          className={"student"}
        >
          <BlueNav {...props} />

          <Switch>
            <PrivateRoute path={`${props.match.url}/addNote/:isPublic/:who`} component={AddNote} />
            <PrivateRoute path={`${props.match.url}/schedule`} component={Schedule} />

            <PrivateRoute path={`${props.match.url}/workload`} component={Workload} />
            <PrivateRoute path={`${props.match.url}/notes/:horse`} component={Notes} />

            <PrivateRoute path={`${props.match.url}/history`} component={History} />

            <PrivateRoute path={`${props.match.url}/details`} component={Info} />
            <PrivateRoute path={`${props.match.url}/documents`} component={Documents} />

            <PrivateRoute path={`${props.match.url}/edit/:addoredit`} component={HorseForm} />
            <PrivateRoute
              path={`${props.match.url}/calendar`}
              component={CalendarHomePage}
              studentBooking
              hideFilter
              currentHorseId={[listState.currentHorse.horse_id]}
            />
          </Switch>
        </div>
      </div>
      <div> {profileOnly && <HelpText>Showing top ten results. To see more, go to client page.</HelpText>}</div>
    </>
  );
};
export default HorseHomePage;
