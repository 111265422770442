import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, NavLink, useParams } from "react-router-dom";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";

import AuthHelperMethods from "./AuthHelperMethods";
import { MyContext } from "./App";
import { ListContext } from "./App";
import {
  TableHeaderNoAdd,
  OrangeLinkButton,
  OrangeButton,
  ModalHeaderBlue,
  MyTextInput,
  MyTextAreaInput,
  ButtonOrange,
} from "./formComponents";
import { BoxWithLabelW, SaveButton, StyledTr, Td, Th, HelpText } from "./styleCustom";
import { Modal } from "react-bootstrap";
import { useAlert } from "react-alert";
import { errormsg } from "./Constants2";
import COLORS from "./styleConstants";
import { active_options } from "./Constants2";
import { Active } from "./searchFilterReports";

const Auth = new AuthHelperMethods();

const Task = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [showModal, setShowModal] = useState(false);
  const [addoredit, setAddorEdit] = useState("add");
  const [initialValues, setInitialValues] = useState({
    task_name: "",
  });
  const [taskList, setTaskList] = useState([]);
  const alert = useAlert();
  const [active, setActive] = useState(true);

  const editTask = (task) => {
    console.log("editLocatiion");
    setAddorEdit("edit");
    setShowModal(true);
    setInitialValues({
      id: task.id,
      task_name: task.task_name,
      task_description: task.task_description,
    });
  };

  useEffect(
    () => {
      const fetchData = async () => {
        console.log("**************** TASK LIST*****************");
        let params = { ...listState.filter };
        try {
          const result = await Auth.api("tasks/index", "get", params);

          setTaskList(result.data.task);
        } catch (error) {
          console.log("error");
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    [listState.filter]
  );

  useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        const result = await Auth.api("options/index", "get", params);
        dispatch({
          type: "UPDATE_OPTIONS",
          options: result.data,
        });
      };
      fetchData();
    },
    //eslint-disable-next-line
    [listState.tasks]
  );

  const updateIsActive = async (task_id) => {
    try {
      let params = { id: task_id };
      const result = await Auth.api("tasks/updateActivationStatus", "post", params);
      setTaskList(taskList.map((task) => (task.id == result.data.task.id ? result.data.task : task)));
    } catch (error) {
      console.log("error");
    }
  };
  return (
    <>
      <Modal show={showModal} size="md" onHide={() => setShowModal(false)}>
        <ModalHeaderBlue closeButton>
          <Modal.Title>{`${addoredit == "edit" ? "Edit" : "Add New"}  Task`}</Modal.Title>
        </ModalHeaderBlue>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              task_name: Yup.string().required("required"),
              task_description: Yup.string().nullable(true),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              dispatch({ type: "FETCH_INIT" });
              console.log(values);
              let url = addoredit == "edit" ? "tasks/update" : "tasks/create";
              console.log(url);
              try {
                const result = await Auth.api(url, "post", values);
                if (addoredit == "edit") {
                  setTaskList(taskList.map((task) => (task.id == result.data.task.id ? result.data.task : task)));
                  //   dispatch2(["EDIT_LOCATION", result.data.task]);
                  alert.show(`Changes to task ${values.task_name} saved`, {
                    timeout: 5000,
                    type: "success",
                  });
                } else {
                  setTaskList([result.data.task, ...taskList]);
                  alert.show(`Task ${values.task_name} added`, {
                    timeout: 5000,
                    type: "success",
                  });
                }
                dispatch({
                  type: "UPDATE_TASK_OPTIONS",
                  options: result.data.options,
                });
                dispatch({ type: "FETCH_SUCCESS" });

                setShowModal(false);
                setAddorEdit("add");
                setInitialValues({
                  task__name: "",
                });
              } catch (e) {
                console.log(e);
                dispatch({ type: "FETCH_ERROR" });

                alert.show(errormsg, { timeout: 5000, type: "error" });
              }
            }}
          >
            <Form>
              <MyTextInput name="task_name" type="text" placeholder=" " label="Task Name" />
              <HelpText>required</HelpText>
              <MyTextAreaInput name="task_description" type="text" placeholder=" " label="Description" />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 40,
                }}
              >
                <ButtonOrange type="submit" label={"Save Task"} />
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
      <div style={{ display: "flex", flexWrap: "nowrap", flex: "1 0 100%" }}>
        <div style={{ flex: "1 0 100%" }}>
          <TableHeaderNoAdd title={"Tasks"}>
            <OrangeButton onClick={() => setShowModal(true)}>{"Add New Task"} </OrangeButton>
          </TableHeaderNoAdd>
          <Active field={"is_active"} default={"1"} options={active_options} />

          <table style={{ width: "100%" }}>
            <thead>
              <tr style={{ color: `${COLORS.blue}` }}>
                <Th>
                  {" "}
                  <div>{"Name"}</div>
                  <HelpText>{"Click on Name to edit"}</HelpText>
                </Th>
                <Th>{"description"}</Th>

                <Th>{"status"}</Th>
              </tr>
            </thead>
            <tbody>
              {taskList &&
                taskList.length > 0 &&
                taskList.map((task) => (
                  <StyledTr key={task.id}>
                    <Td>
                      <div style={{ cursor: "pointer" }} onClick={() => editTask(task)}>
                        {task.task_name}
                      </div>
                    </Td>
                    <Td>
                      <div>{task.task_description || ""}</div>
                    </Td>

                    <Td onClick={() => updateIsActive(task.id)} style={{ color: "red", cursor: "pointer" }}>
                      {`${task.is_active ? "inactivate" : "activate"}`}
                    </Td>
                  </StyledTr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Task;
