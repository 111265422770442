import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, NavLink } from "react-router-dom";

import { ListContext } from "./App";
import { MyContext } from "./App";
import {
  PrimaryText,
  PrimaryTextBlue20,
  StyledTr,
  TableText1,
  TableText2,
  Th,
  HelpText,
  StyledLink,
  PageTitle,
} from "./styleCustom";
import axios from "axios";
import AuthHelperMethods from "./AuthHelperMethods";
import COLORS from "./styleConstants";
import { CheckSquare, Check } from "./icons";
import SelectTemplateFilter from "./searchFilterReports";
import Select from "react-select";
import PrivateRoute from "./privateRoute";
import WaiverRetrieve, { WaiverRetrieveList, TemplateListRetrieve, DynamicTemplate } from "./waiverRetrieve";
import { nametagStyle } from "./styleConstants";
const Auth = new AuthHelperMethods();

const WaiverClient = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [templatesList, setTemplateList] = useState([]);
  const [myWaivers, setMyWaivers] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState();
  const [currentWaiverList, setCurrentWaiverList] = useState([]);
  const [currentWaiverDetails, setCurrentWaiverDetails] = useState([]);

  let params = { person_id: listState.accountHolder.id };

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          dispatch({ type: "FETCH_INIT" });

          // const result = await Auth.api("waiver/getAccountWaiverList", "get", params);
          const result = await Auth.api("waiver/getAccountWaiversDatabase", "get", params);

          console.log(result.data.waivers);
          setMyWaivers(result.data.waivers);
          dispatch({
            type: "FETCH_SUCCESS",
          });
        } catch (e) {
          dispatch({ type: "FETCH_ERROR" });

          console.log(e);
        }
        // setSections(result.data.studentSections);
        // dispatch2(["HORSE_DATA", result.data]);
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          dispatch({ type: "FETCH_INIT" });

          const result = await Auth.api("waiver/getTemplateListDataBase", "get");
          //   console.log(result.data.waivers);
          if (result.data.templates) {
            let notPublicTemplates = result.data.templates.filter((template) => !template.is_public);
            setTemplateList(notPublicTemplates);
          }
          dispatch({
            type: "FETCH_SUCCESS",
          });
        } catch (e) {
          dispatch({ type: "FETCH_ERROR" });

          console.log(e);
        }
        // setSections(result.data.studentSections);
        // dispatch2(["HORSE_DATA", result.data]);
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  const TemplateListDB = () => {
    if (!templatesList) return <div>{"There are no active waiver templates"}</div>;

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <PrimaryTextBlue20>{"Available Waivers"}</PrimaryTextBlue20>
        <HelpText>{"Click to sign waiver/form"}</HelpText>
        {templatesList.map((template) => (
          <>
            <div key={template.id} style={{ ...nametagStyle, cursor: "pointer" }}>
              <a
                href={`https://waiver.smartwaiver.com/auto/?auto_waiverid=${template.template_id}&auto_tag=${listState.accountHolder.id}`}
              >
                {template.title}
              </a>
            </div>
          </>
        ))}
      </div>
    );
  };

  return (
    <>
      <PageTitle>{"Waivers"}</PageTitle>
      <div style={{ display: "flex", flex: "1 1 100%", flexWrap: "wrap" }} className="templateList">
        <TemplateListDB />
      </div>

      <div style={{ display: "flex", flex: "1 1 100%", flexWrap: "wrap" }} className="waiverList">
        <div style={{ display: "flex", flex: "1 1 100%" }}>
          <PrimaryTextBlue20>{"Signed Waivers"}</PrimaryTextBlue20>
        </div>

        {myWaivers.length > 0 &&
          myWaivers.map((waiver) => (
            <div style={{ cursor: "pointer" }}>
              <WaiverRetrieve waiver={waiver.waiver_json} />
            </div>
          ))}
      </div>
    </>
  );
};

export default WaiverClient;
