import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Route,
  Switch,
  Link,
  useRouteMatch,
  useParams,
  useHistory,
  withRouter,
  useLocation,
} from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { ListContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import { BlueNavLink12, div, PrimaryText, PrimaryText18, Td, Th, PageTitle } from "./styleCustom";
import { currentDayToDB, timeISOtoString, dateISOtoStringMed } from "./momentUtils";
import { EditIcon, EditIconPlain } from "./icons";
import COLORS from "./styleConstants";
import AddNote from "./addNote1"; //from ***addNote1****
import { MyContext } from "./App";
import Select from "react-select";
import { useAlert } from "react-alert";
import PrivateRoute from "./privateRoute";
import Logout from "./logout";
import StaffForm from "./staffForm";
import StaffFormEdit, { DetailDisplay } from "./staffFormEdit";
import { AccountPage, Contact } from "./accountPage";
import { BackTo, ButtonOrange, UserCan, ButtonOrangeSm, Limit } from "./formComponents";
import NotesTable from "./notesTable";
import ContactForm from "./contactForm";
import { StaffAvatar } from "./images";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input";
import CalendarHome from "./Calendar3";
import AddModal from "./addModalStaff";
import EditEvent from "./EditEvent.js";
import { StaffColor } from "./staffFormEdit";
import { XrefSelect } from "./xrefs";

const Auth = new AuthHelperMethods();

const ScheduledLessons = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);

  useEffect(
    () => {
      let params = {
        staff_id: listState.currentStaff.instructor_id,
        today: currentDayToDB(),
      };
      console.log(params);
      const fetchData = async () => {
        const result = await Auth.api("instructors/getSchedule", "get", params);
        dispatch2(["HORSE_DATA", result.data.schedule]);
      };
      if (params.staff_id) fetchData();
    },
    //eslint-disable-next-line
    [listState.currentStaff.instructor_id]
  );

  useEffect(
    () => {
      return () => {
        dispatch2(["HORSE_DATA", {}]);
      };
    },
    //eslint-disable-next-line
    [listState.currentStaff.instructor_id]
  );

  //  useFetch("students/lessonSchedule", "CLIENT_DATA", "dispatchlist", params);
  //  if (!listState.clientdata) return null;
  return (
    <>
      <PrimaryText18>{"Schedule"}</PrimaryText18>

      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <Th> name</Th>

            <Th> date</Th>
            <Th> time</Th>
            <Th> end</Th>
          </tr>
        </thead>
        <tbody>
          {listState.horsedata &&
            listState.horsedata.length > 0 &&
            listState.horsedata.map((data) => (
              <tr key={data.id}>
                <Td>{data.name}</Td>
                <Td>{dateISOtoStringMed(data.scheduled_date)}</Td>
                <Td>{timeISOtoString(data.scheduled_starttime)}</Td>
                <Td>{timeISOtoString(data.scheduled_endtime)}</Td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

const History = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);

  let params = {
    staff_id: listState.currentStaff.instructor_id,
    today: currentDayToDB(),
  };
  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("instructors/getHistory", "get", params);
        dispatch2(["HORSE_DATA", result.data.history]);
      };
      if (params.staff_id) fetchData();
    },
    //eslint-disable-next-line
    [listState.currentStaff.instructor_id]
  );

  useEffect(
    () => {
      return () => {
        dispatch2(["HORSE_DATA", {}]);
      };
    },
    //eslint-disable-next-line
    [listState.currentStaff.instructor_id]
  );

  //  useFetch("students/lessonHistory", "CLIENT_DATA", "dispatchlist", params);
  return (
    <>
      <PrimaryText18>{"History"}</PrimaryText18>

      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <Th> name</Th>

            <Th> date</Th>
            <Th> time</Th>
            <Th> end</Th>
          </tr>
        </thead>
        <tbody>
          {listState.horsedata &&
            listState.horsedata.length > 0 &&
            listState.horsedata.map((data) => (
              <tr key={data.id}>
                <Td>{data.name}</Td>
                <Td>{dateISOtoStringMed(data.scheduled_date)}</Td>
                <Td>{timeISOtoString(data.scheduled_starttime)}</Td>
                <Td>{timeISOtoString(data.scheduled_endtime)}</Td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

const Notes = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const usertype = Auth.getUserType();
  const [offset, setOffset] = useState(50);
  const [total, setTotal] = useState();

  let location = useLocation();
  const profileOnly = location.pathname.startsWith("/admin/calendarGroup/edit");

  let params = {
    person_id: listState.currentStaff.person_id,
    limit: profileOnly ? 10 : offset,
  };
  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("notes_clients/staffNotes", "get", params);
        dispatch2(["CLIENT_DATA", result.data, "notes"]);
        setTotal(result.data.size);
      };
      if (listState.currentStaff.person_id) fetchData();
    },
    //eslint-disable-next-line
    []
  );
  useEffect(
    () => {
      return () => {
        console.log("Clear Notes");
        dispatch2(["CLIENT_DATA", []]);
      };
    },
    //eslint-disable-next-line
    []
  );

  //  useFetch("notes_clients/clientNotes", "CLIENT_DATA", "dispatchlist", params);
  //if (!listState.clientdata) return null;
  return (
    <>
      <NotesTable type={"staff"} {...props} />
      {!profileOnly && <Limit total={total} setOffset={setOffset} data={listState.clientdata || []} offset={offset} />}
    </>
  );
};

const Actions = (props) => {
  return (
    <>
      {1 == 2 && (
        <UserCan doThis={"STAFF_CAN_VIEW_CALENDAR"}>
          <div style={{ textAlign: "center" }}>
            <Link to={`${props.match.url}/calendarGroup/calendar`}>
              <ButtonOrangeSm label={"View Calendar"} />
            </Link>
          </div>
        </UserCan>
      )}
      <Link to={`${props.match.url}/addNote/1/staff`}>
        <ButtonOrangeSm label={"Add Public Note"} />
      </Link>

      <UserCan isThis={"ADM"}>
        <Link to={`${props.match.url}/addNote/0/staff`}>
          <ButtonOrangeSm label={"Add Private Note"} />
        </Link>
      </UserCan>
    </>
  );
};

const Summary = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [showColor, setShowColor] = useState(false);
  if (!listState.accountHolder) return null;

  return (
    <div className={"SummaryLeft"}>
      {Auth.getUserType() == "INS" && (
        <div style={{ color: COLORS.blue, fontSize: 16 }}>{`${mystate.farmDefaults.farm_name} `}</div>
      )}

      <div
        className="currentStaff"
        style={{ marginBottom: 30, display: "flex", flex: 1, justifyContent: "center", marginTop: 50 }}
      >
        <span style={{ fontSize: 30, fontWeight: "bold" }}>{listState.currentStaff.name}</span>
      </div>
      <div style={{ display: "flex", flex: "1 1 100%", justifyContent: "center" }}>
        <StaffAvatar width={"70px"} height={"70px"} />
      </div>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
        <Actions {...props} />
      </div>
      <div
        className="contactDisplay"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: 20,
          color: COLORS.textgrey,
          fontSize: 20,
        }}
      >
        <div style={{ fontSize: 20 }}>
          <div style={{ fontSize: 20, color: COLORS.textgrey, display: "flex", justifyContent: "center" }}>
            <a href={`tel: ${listState.accountHolder.contact_email}}`} style={{ fontSize: 20, color: COLORS.textgrey }}>
              {listState.accountHolder.contact_email}
            </a>
          </div>
          <div style={{ fontSize: 20, color: COLORS.textgrey }}>
            <a
              href={`tel: ${listState.accountHolder.contact_mobile}`}
              style={{ fontSize: 20, color: COLORS.textgrey, margin: "auto" }}
            >
              {listState.accountHolder.contact_mobile && formatPhoneNumber(listState.accountHolder.contact_mobile)}
            </a>
          </div>
          <div style={{ fontSize: 20, color: COLORS.textgrey }}>
            <a
              href={`tel: ${listState.accountHolder.contact_homephone}`}
              style={{ fontSize: 20, color: COLORS.textgrey }}
            >
              {listState.accountHolder.contact_homephone &&
                formatPhoneNumber(listState.accountHolder.contact_homephone)}
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          background: listState.currentStaff.color_code || COLORS.orange,
          minHeight: 40,
          marginBottom: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          cursor: "pointer",
        }}
        onClick={() => setShowColor(true)}
      >
        {listState.currentStaff.color_code ? "change color" : "choose color"}
      </div>
      {showColor && <StaffColor />}
    </div>
  );
};

const BlueNav = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  return (
    <div
      className="BlueNav"
      style={{
        display: "flex",
        background: COLORS.blue,
        justifyContent: "space-evenly",
        flexWrap: "wrap",
        flex: " 1 1 100%",
        alignItems: "center",
      }}
    >
      <>
        <BlueNavLink12 to={`${props.match.url}/schedule`}> Schedule</BlueNavLink12>
        <BlueNavLink12 to={`${props.match.url}/history`}> History</BlueNavLink12>
        <BlueNavLink12 to={`${props.match.url}/notes`}> Notes</BlueNavLink12>
        <BlueNavLink12 to={`${props.match.url}/details`}> Staff Details</BlueNavLink12>
        {mystate.farmDefaults.restrict_clients_to_staff && (
          <BlueNavLink12 to={`${props.match.url}/students`}> Students</BlueNavLink12>
        )}

        <>
          {Auth.getUserType() == "ADM" && (
            <BlueNavLink12 activeClassName="is-active" to={`${props.match.url}/account`}>
              Staff Account
            </BlueNavLink12>
          )}
        </>
      </>
    </div>
  );
};

const StaffOwnStudents = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  return (
    <XrefSelect
      options={mystate.options_student}
      get={"xrefs/getInstructorStudents"}
      post={"xrefs/updateInstructorStudent"}
      single={listState.currentStaff.instructor_id}
      label={`Students assigned to ${listState.currentStaff.name}`}
      nametag
      student
    />
  );
};

const StaffLogin = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("vusers/getUserPermissions", "get");
        dispatch2(["SET_STAFF_PERMISSIONS", result.data.permissions]);
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );
  return null;
};

const StaffHomePage = (props) => {
  console.log("StaffHomePage");
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);
  const [isLaptop, setIsLaptop] = useState(window.innerWidth > 1000);
  const [currentInstuctorId, setCurrentInstuctorId] = useState();
  const staffParams = useParams();
  let currentPersonId = staffParams.id;
  const history = useHistory();
  const location = useLocation();

  console.log(location.pathname.substring(0, location.pathname.lastIndexOf("/")));

  useEffect(
    () => {
      return () => {
        dispatch2(["HORSE_DATA", []]);
        dispatch2(["CLIENT_UPDATE_ACCOUNT_HOLDER", {}]);
        dispatch2(["CLIENT_GET", {}]);
        if (Auth.getUserType() == "ADM") dispatch2(["STAFF_GET_ONE"], {});

        dispatch2(["CLIENT_UPDATE_MEMBERS", []]);
      };
    },
    //eslint-disable-next-line
    []
  );
  useEffect(
    () => {
      const fetchData = async () => {
        let params = { person_id: currentPersonId };
        try {
          console.log("THIS IS RUNNING AGAIN");
          dispatch({ type: "FETCH_INIT" });
          const result = await Auth.api("instructors/staffPerson", "get", params);
          dispatch({ type: "FETCH_SUCCESS" });
          dispatch2(["STAFF_GET_ONE", result.data.staff]);
          dispatch2(["CLIENT_UPDATE_ACCOUNT_HOLDER", result.data.accountHolder]);
          history.push(`${props.match.url}/details`);
        } catch (error) {
          console.log(error);
          dispatch({ type: "FETCH_ERROR" });
          // alert.show(mystate.errormsg, { timeout: 5000, type: "error" });
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  /*useEffect(
    () => {
      const fetchData = async () => {
        let params = { person_id: currentPersonId };
        try {
          const result = await Auth.api("people/getStaffAccountHolder", "get", params);
          dispatch2(["CLIENT_UPDATE_ACCOUNT_HOLDER", result.data.accountHolder]);
          history.push(`${props.match.url}/details`);
        } catch (error) {
          console.log(error);

          dispatch({ type: "FETCH_ERROR" });
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );
  */

  const onClickOptions = async () => {
    let params = {};
    const result = await Auth.api("options/index", "get", params);
    dispatch({
      type: "UPDATE_OPTIONS",
      options: result.data,
    });
    console.log(result.data);
  };
  if (listState.currentStaff && Object.entries(listState.currentStaff).length === 0) return <div></div>;
  const leftPadding = () => {
    if (isMobile) return 0;
    return 20;
  };
  return (
    <>
      {listState.currentStaff && (
        <>
          {" "}
          {Auth.getUserType() == "ADM" && (
            <div style={{ display: "flex", flex: "1 1 100%" }}>
              <BackTo url={`/admin/staff`} pagename={"staff"} />
            </div>
          )}
          <div
            style={{
              display: "flex",
              maxWidth: "30%",
              minWidth: 250,
              flexDirection: "column",
              flex: "1 1 auto",
              marginBottom: 60,
            }}
          >
            <Summary {...props} />
          </div>
          <div style={{ display: "flex", flex: "1 1 500px" }}>
            <div
              style={{
                display: "flex",
                flex: isMobile ? " 1 1 100% " : " 1 1 75%",
                flexWrap: "wrap",
                alignContent: "flex-start",
                paddingLeft: leftPadding(),
              }}
              className={"student"}
            >
              <BlueNav {...props} />
              <Switch>
                <PrivateRoute path={`${props.match.url}/schedule`} component={ScheduledLessons} />
                <PrivateRoute path={`${props.match.url}/addNote/:isPublic/:who`} component={AddNote} />
                <PrivateRoute path={`${props.match.url}/history`} component={History} />
                <PrivateRoute path={`${props.match.url}/notes`} component={Notes} />

                <PrivateRoute path={`${props.match.url}/details`} component={DetailDisplay} />
                <PrivateRoute path={`${props.match.url}/edit`} component={StaffFormEdit} />
                <PrivateRoute path={`${props.match.url}/contact/`} component={ContactForm} />
                {1 == 2 && <PrivateRoute path={`${props.match.url}/calendarGroup/calendar`} component={CalendarHome} />}
                <PrivateRoute path={`${props.match.url}/calendarGroup/add/newevent`} component={AddModal} />
                <PrivateRoute path={`${props.match.url}/calendarGroup/edit/:hid`} component={EditEvent} />
                <PrivateRoute path={`${props.match.url}/students`} component={StaffOwnStudents} />

                {Auth.getUserType() == "ADM" && (
                  <PrivateRoute path={`${props.match.url}/account`} component={AccountPage} />
                )}

                <Link to={`${props.match.url}/contact`}>
                  <EditIconPlain />
                </Link>
              </Switch>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const CreateUserAccount = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const createUserAccount = async () => {
    let params = {
      person_name: listState.accountHolder.person_name,
      person_id: listState.accountHolder.id,
      email: listState.accountHolder.contact_email,
      type: "INS",
    };
    if (!listState.accountHolder.contact_email) {
      alert("Staff does not yet have a email address associated with this account.  Please create one then try again.");
    } else {
      try {
        const result = await Auth.api("vusers/addClientUserAccount", "post", params);
        params = { ...params, user_id: result.data.user_id };
        dispatch2(["CLIENT_NEW_ACCOUNT", params]);

        alert("User Succesfully Created");
      } catch (error) {
        console.log(error);
        //  dispatch({ type: "FETCH_ERROR" });
        alert(
          "Account creation failed. Check that this email address has not already been used to create another account"
        );
      }
    }
  };
  return (
    <UserCan isThis={"ADM"}>
      <div style={{ display: "flex" }}>
        {" "}
        <ButtonOrange onClick={() => createUserAccount(listState.accountHolder)} label={"create user login account"} />
      </div>
    </UserCan>
  );
};

export default StaffHomePage;
