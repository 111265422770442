export default {
  defaultSystemDuration: 60, //system setting if there is no default section duration
  minuteIncrement: 30, //system setting until users can set own config
  defaultSectionId: 90 || "", // set to 0 on login if there isn't one
  /*  defaultSectionInstructorId: 80 || "", // set to 0 on login if there isn't one
  defaultSectionDuration: 60 || 60, // set to defaultSystemDuration if there isn't a default section
  defaultSectionLocationId: 0 || 0, //// set to 0 on login if there isn't one
  defaultSectionTitle: "IDA" || "",*/
  defaultCalendarView: "week",
  defaultMinTime: "07:00:00",
  showResources: true,
  userTypeCode: "ADM",
  countryCode: "CA",
  defaultMakeUpDays: 30,
  ADM: ["ADD_EVENTS", "EDIT_EVENTS", "SHOW_RESOURCE_BUTTON", "CAN_ADD_STUDENT", "CAN_ADD_HORSE"],
  INS: ["ADD_EVENTS", "EDIT_EVENTS", "SHOW_RESOURCE_BUTTON", "CAN_ADD_STUDENT"],
  STU: ["VIEW_EVENTS", "SCHEDULE_MAKEUP", "REQUEST_LESSON, CAN_ADD_HORSE"],
};

export const lessonStatusMap = {
  COM: "Completed",
  SCH: "Scheduled",
  CAN: "Cancelled",
  RSC: "Rescheduled",
};

export const DISPLAY_DATETIME = "MMMM Do, YYYY h:mm a";
export const DISPLAY_DATETIME_SHORT = "MMMM Do h:mm a";

export const DISPLAY_DATE = "dddd MMMM Do, YYYY ";
export const DISPLAY_DATE_MED = "ddd MMM Do, YYYY ";
export const DISPLAY_DATE_NO_YEAR = "ddd MMM Do";
export const DISPLAY_DATE_SHORT = " MMM Do ";
export const DISPLAY_DATE_SHORT_WITH_YEAR = "MMM DD yy";

export const DISPLAY_TIME = "h:mma";
export const DISPLAY_TIME_NO_MINUTES = "ha";

export const DATEPICKER_DISPLAY = "MMMM d, yyyy h:mm aa";
export const DATEPICKER_DATE_DISPLAY = "MMMM d, yyyy ";
export const DBDATEFORMAT = "YYYY-MM-DD hh:mm";
