import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { MediumPageHeader, OrangeButton, PrimaryText18, BlueButton, HelpText } from "./styleCustom";
import {
  MyTextInput,
  MyTextAreaInput,
  FormRightSave,
  TextArea,
  TextInput,
  CheckBox,
  TextDisplay,
  DatePickerField,
  UserCan,
  AddEditForm,
  ModalHeaderBlue,
  ButtonBlue,
  ButtonOrange,
} from "./formComponents";
import { errormsg } from "./Constants2";
//import "./styleForm.css";
//import "./styleFormik.css";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col, Modal } from "react-bootstrap";
import { ListContext } from "./App";
import { MyContext } from "./App";
import { useAlert } from "react-alert";
import { useHistory, useParams } from "react-router-dom";
import { BrowserRouter, Route, Switch, Link, NavLink, useLocation } from "react-router-dom";
import { EditIconPlain } from "./icons";
import moment from "moment";
import { todayDisplay, dateObjToDBDate } from "./momentUtils";
import { MemberLinks } from "./clientHomePage";

const Auth = new AuthHelperMethods();

const StudentForm = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [isEditing, setIsEditing] = useState(false);
  const [showEditModal, setShowEditModal] = useState(true);
  let location = useLocation();

  const profileOnly = location.pathname.startsWith("/admin/calendarGroup/edit");

  const alert = useAlert();
  let history = useHistory();
  let params = useParams();

  let title = "Details";

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          let params = { person_id: listState.currentClient.id };
          const result = await Auth.api("students/student", "get", params);
          let client = result.data.student;
          console.log(client.date_of_birth);
          console.log(moment(client.date_of_birth, "YYYY-MM-DD"));

          let formInitialValues = {
            id: client.student_id,

            gender: client.level || "",
            tack: client.tack || "",

            notes: client.general_notes1 || "",
            notes2: client.general_notes2 || "",
            notes3: client.general_notes3 || "",
            date_of_birth: client.date_of_birth || "",

            month: moment(client.date_of_birth, "YYYY-MM-DD").month() + 1 || "",
            day: moment(client.date_of_birth, "YYYY-MM-DD").date() || "",
            year: moment(client.date_of_birth, "YYYY-MM-DD").year() || "",
          };

          dispatch2(["SET_FORM_INITIAL_VALUES", formInitialValues, "edit"]);
          dispatch2(["STUDENT_DETAIL_SET", result.data.student]);
        } catch (error) {
          console.error(error.message);
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );
  /* useEffect(() => {
    return () => {
      return null;
    };
  });*/

  const DetailDisplay = () => {
    if (isEditing) return null;
    return (
      <div
        style={{
          display: "flex",
          flex: "1 1 100%",
        }}
      >
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1 1 auto",
              marginRight: 50,
            }}
          >
            <TextDisplay label={"Date of Birth"} data={listState.currentStudentDetail.date_of_birth} />
            <TextDisplay label={"Level"} data={listState.currentStudentDetail.riding_level} />
            <TextDisplay label={"Tack"} data={listState.currentStudentDetail.tack} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "2 2 auto",
            }}
          >
            <TextDisplay label={"General Notes 1"} data={listState.currentStudentDetail.general_notes1} />
            <TextDisplay label={"General Notes 2"} data={listState.currentStudentDetail.general_notes2} />
            <TextDisplay label={"General Notes 3"} data={listState.currentStudentDetail.general_notes3} />
            <TextDisplay
              label={"Eligible Levels"}
              data={props.sections.map((section) => (
                <div key={section.section_id}>{section.section_name}</div>
              ))}
            />
          </div>
        </>
      </div>
    );
  };

  //if (!listState.currentStudentDetail) return null;
  return (
    <>
      <AddEditForm>
        <Modal show={true}>
          <Formik
            initialValues={listState.formInitialValues}
            enableReinitialize
            validationSchema={Yup.object({
              level: Yup.string(),
              tack: Yup.string(),
              notes: Yup.string(),
              notes2: Yup.string(),
              notes3: Yup.string(),
              month: Yup.number()
                .min(1, "Must be a number between 1 and 12")
                .max(12, "Must be a number between 1 and 12"),
              year: Yup.number().min(1900, "Must be a 4 digit  number ").max(2050, "Must be a 4 digit  number "),
              day: Yup.number()
                .min(1, "min Must be a number between 1 and 31")
                .max(31, "max  Must be a number between 1 and 31"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              dispatch({ type: "FETCH_INIT" });
              console.log(values);
              values.date_of_birth = moment([values.year, values.month - 1, values.day]).format("YYYY-MM-DD");
              //console.log(dob);
              /* if (values.date_of_birth) {
              values = {
                ...values,
                date_of_birth: dateObjToDBDate(values.date_of_birth),
              };
            }*/

              try {
                const result = await Auth.api("students/update", "post", values);
                let data = { data: result.data.student };

                //  dispatch2([action, result.data]);
                dispatch({ type: "FETCH_SUCCESS" });
                dispatch2(["SET_FORM_INITIAL_VALUES", {}]);

                dispatch2(["CLIENT_DATA", data]);
                history.goBack();
              } catch (error) {
                console.log(error);
                dispatch({ type: "FETCH_ERROR" });
                alert.show(errormsg, { timeout: 5000, type: "error" });
              }
              //  dispatch2(["CLOSE_ADD_EDIT_FORM"]);
            }}
          >
            <Form
              id="studentForm"
              style={{
                display: "flex",
                maxWidth: 500,
                flexWrap: "wrap",
              }}
            >
              <ModalHeaderBlue style={{ display: "flex", flex: "1", justifyContent: "center" }}>
                <Modal.Title>{"Edit Student Detail"}</Modal.Title>
              </ModalHeaderBlue>
              <Modal.Body>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                    }}
                  >
                    <TextInput name="level" label="Level" />
                    <TextInput name="tack" label="Tack" />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",

                        flexWrap: "wrap",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flex: "0 0 25%",
                          flexDirection: "column",
                        }}
                      >
                        {" "}
                        <MyTextInput name="year" label="Year" type="number" placeholder={"YYYY"} />
                        <HelpText>{"Enter a 4 digit year"}</HelpText>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: "0 0 25%",
                          flexDirection: "column",
                        }}
                      >
                        {" "}
                        <MyTextInput name="month" label="Month" type="number" placeholder={"01"} max={12} />
                        <HelpText>{"Enter a one or two  digit month"}</HelpText>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flex: "0 0 25%",
                          flexDirection: "column",
                        }}
                      >
                        {" "}
                        <MyTextInput name="day" label="Day" type="number" placeholder={"01"} max={31} />
                        <HelpText>{"Enter a one or two  digit day of month"}</HelpText>
                      </div>
                    </div>

                    <TextArea rows={3} label={"General Notes 1"} name="notes" />
                    <TextArea rows={3} label={"General Notes 2"} name="notes2" />
                    <TextArea rows={3} label={"General Notes 3"} name="notes3" />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer style={{ display: "flex", flex: 1 }}>
                <div style={{ display: "flex", justifyContent: "space-evenly", flex: "1 1 ", margin: 20 }}>
                  <ButtonBlue onClick={() => history.goBack()} label={"Cancel"} />
                  <ButtonOrange formId={"studentForm"} type="submit" label={"Save"} />
                </div>
              </Modal.Footer>
            </Form>
          </Formik>
        </Modal>
      </AddEditForm>
    </>
  );
};
export default StudentForm;
