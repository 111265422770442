import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { MyContext, ListContext } from "./App";
import COLORS from "./styleConstants";
import {
  dateObjToDisplayDate,
  dateISOtoString,
  dateObjToDBDate,
  DBDATEFORMAT,
  timeISOtoString,
  timeISOtoStringN,
  timeISOtoStringUtc,
  combineDateAndTimeStrToDateObj,
  formatFromDB,
} from "./momentUtils";
import { SelectTemplateFilter, Active } from "./searchFilterReports";
import { OpenClose, ButtonBlue, ButtonOrange } from "./formComponents";
import { options_attendance, available_options } from "./Constants2";
import AuthHelperMethods from "./AuthHelperMethods";

const Auth = new AuthHelperMethods();

export const SelectFilter = ({ staff, location, program_type, program, section, student, horse }) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const [showFilter, setShowFilter] = useState(true);

  return (
    <div
      className="filter"
      style={{
        display: "flex",
        flex: "1 1 100%",
        flexWrap: "wrap",
        padding: 10,
        alignContent: "center",
        flexDirection: "row",
        justifyContent: "flex-start",
      }}
    >
      {staff && (
        <SelectTemplateFilter
          options={mystate.options_instructor}
          isMulti
          label={"instructor"}
          value={listState.filter.instructor || null}
          maxWidth={130}
        />
      )}
      {location && (
        <SelectTemplateFilter
          options={mystate.options_location}
          isMulti
          label={"location"}
          value={listState.filter.location || null}
          maxWidth={130}
        />
      )}
      {program_type && (
        <SelectTemplateFilter
          options={mystate.options_program_type}
          isMulti
          label={"program_type"}
          extraLabel={"program type"}
          value={listState.filter.program_type || null}
          maxWidth={130}
        />
      )}
      {program && (
        <SelectTemplateFilter
          options={mystate.options_program}
          isMulti
          label={"program"}
          value={listState.filter.program || null}
          maxWidth={130}
        />
      )}
      {section && (
        <SelectTemplateFilter
          options={mystate.options_section}
          isMulti
          label={"section"}
          placeholder="section"
          value={listState.filter.section}
          maxWidth={130}
        />
      )}
      {student && (
        <SelectTemplateFilter
          options={mystate.options_horse}
          isMulti
          label={"horse"}
          value={listState.filter.horse || null}
          maxWidth={130}
        />
      )}
      {horse && (
        <SelectTemplateFilter
          options={mystate.options_student}
          isMulti
          label={"student"}
          value={listState.filter.student || null}
          maxWidth={130}
        />
      )}
    </div>
  );
};

export const Filter = ({ hideFilter, direction, filterIsOpen }) => {
  console.log(filterIsOpen);
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const [showFilter, setShowFilter] = useState(true);

  console.log(showFilter);
  if (hideFilter) return null;

  return (
    <div
      className="filterside"
      style={{ display: "flex", flex: "1 1 100%", flexDirection: "column", marginBottom: 10 }}
    >
      <OpenClose onClick={() => setShowFilter(!showFilter)} show={showFilter} />
      <div
        className="filter"
        style={{
          display: "flex",
          flex: "1 1 100%",
          flexWrap: "wrap",
          border: showFilter ? "solid 1pt lightgrey" : "none",
          padding: 10,
          paddingBottom: showFilter ? 20 : 0,
          alignContent: "center",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        {showFilter && (
          <>
            <SelectTemplateFilter
              options={mystate.options_instructor}
              isMulti
              label={"instructor"}
              value={listState.filter.instructor || null}
              maxWidth={130}
            />
            <SelectTemplateFilter
              options={mystate.options_location}
              isMulti
              label={"location"}
              value={listState.filter.location || null}
              maxWidth={130}
            />
            <SelectTemplateFilter
              options={mystate.options_program_type}
              isMulti
              label={"program_type"}
              extraLabel={"program type"}
              value={listState.filter.program_type || null}
              maxWidth={130}
            />
            <SelectTemplateFilter
              options={mystate.options_program}
              isMulti
              label={"program"}
              value={listState.filter.program || null}
              maxWidth={130}
            />

            <SelectTemplateFilter
              options={mystate.options_section}
              isMulti
              label={"section"}
              placeholder="section"
              value={listState.filter.section}
              maxWidth={130}
            />

            <SelectTemplateFilter
              options={mystate.options_horse}
              isMulti
              label={"horse"}
              value={listState.filter.horse || null}
              maxWidth={130}
            />
            <SelectTemplateFilter
              options={mystate.options_student}
              isMulti
              label={"student"}
              value={listState.filter.student || null}
              maxWidth={130}
            />
            <div style={{ display: "flex", justifyContent: "flex-start", flex: "1 1 100%", marginTop: 15 }}>
              <Active
                label={"Lesson cancelled by barn"}
                field={"lesson_is_cancelled"}
                default={listState.filter.lesson_is_cancelled || "2"}
                options={[
                  { value: 2, label: "show all" },
                  { value: 0, label: "not cancelled" },
                  { value: 1, label: "cancelled" },
                ]}
              />
              <Active
                field={"available"}
                default={listState.filter.available || "2"}
                options={available_options}
                label={"Availability"}
              />
            </div>
          </>
        )}
        <FilterLabels />
      </div>
    </div>
  );
};
export const FilterActive = ({ studentCancel, available, lessonCancel }) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);

  return (
    <div style={{ display: "flex", justifyContent: "flex-start", flex: "1 1 100%", flexWrap: "wrap", paddingLeft: 10 }}>
      {lessonCancel && (
        <Active
          label={"Lesson cancelled by barn"}
          field={"lesson_is_cancelled"}
          default={listState.filter.lesson_is_cancelled || 0}
          options={[
            { value: 2, label: "show all" },
            { value: 0, label: "not cancelled" },
            { value: 1, label: "cancelled" },
          ]}
        />
      )}
      {available && (
        <Active
          field={"available"}
          default={listState.filter.available || 2}
          options={available_options}
          label={"Availability"}
        />
      )}
      {studentCancel && (
        <Active
          field={"student_is_cancelled"}
          default={0}
          label={"Student Cancel"}
          options={[
            { value: 2, label: "show all" },
            { value: 0, label: "not cancelled" },
            { value: 1, label: "cancelled" },
          ]}
        />
      )}
    </div>
  );
};
export const FilterLabels = ({ hideClearAllButton = false }) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const isData = Object.entries(listState?.filter_labels)?.length > 0;
  //  if (Object.entries(listState.filter_labels)?.length == 0) return null;
  return (
    <div
      className={"filters"}
      style={{
        display: "flex",
        flex: "1 1 100%",
        marginBottom: 10,
        paddingLeft: 10,
        flexWrap: "wrap",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", flex: "1 1 100%" }}>
        <div style={{ color: COLORS.blue, marginRight: 20, fontWeight: "bold" }}>{isData ? "Filters:" : ""}</div>
      </div>

      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {Object.entries(listState.filter_labels).map(([key, value]) => {
          return (
            <div style={{ marginRight: 10 }}>
              <span style={{ color: COLORS.textDark, fontWeight: "bold" }}>{`${key}: `}</span>
              <span style={{ color: COLORS.textDark }}>{`${value.join(", ")}  `}</span>
            </div>
          );
        })}
      </div>
      {isData && !hideClearAllButton && (
        <div
          onClick={() => dispatch2(["RESET_SEARCH_FILTER_EXCEPT_DATES"])}
          style={{
            color: COLORS.orange,
            cursor: "pointer",
            marginLeft: 20,
          }}
        >
          clear all filters
        </div>
      )}
    </div>
  );
};
