import moment from "moment";
//import { momentObj } from "react-moment-proptypes";
import {
  DISPLAY_DATETIME,
  DISPLAY_DATE,
  DISPLAY_TIME,
  DISPLAY_DATE_SHORT,
  DISPLAY_DATE_MED,
  DISPLAY_DATE_NO_YEAR,
  DISPLAY_TIME_NO_MINUTES,
  DISPLAY_DATETIME_SHORT,
  DISPLAY_DATE_SHORT_WITH_YEAR,
} from "./UserSettings";
import Settings from "./UserSettings";

export const DBDATETIMEFORMAT = "YYYY-MM-DD HH:mm";
export const DBDATEFORMAT = "YYYY-MM-DD";
export const DBTIMEFORMAT = "HH:mm";
export const MINTIME = moment(Settings.defaultMinTime, "hh:mm ").utc().toDate();
export const DATETIMESEC = "HH:mm:ss";
export function filterToParams(filterIn) {
  let params = {};
  Object.keys(filterIn).forEach(function (key) {
    params[key] = filterIn[key];
  });
  return params;
}

export function textFilter(thingToFilter, searchByText, field) {
  console.log(field);
  /*  let x = thingToFilter.filter(item =>
    `item.${field}`.toLowerCase().includes(searchByText.toLowerCase())
  );*/
  let x = thingToFilter.filter((item) => item.person_name.toLowerCase().includes(searchByText.toLowerCase()));
  return x;
}

export const repeatOptions = [
  { value: { measure: "none", units: 0 }, label: "Does Not Repeat" },
  { value: { measure: "weeks", units: 1 }, label: "Repeats Weekly" },
  { value: { measure: "weeks", units: 2 }, label: "Repeats Every Two Weeks" },

  { value: { measure: "days", units: 1 }, label: "Repeats Daily" },
];

export function round(date, durationin, unit) {
  let duration = moment.duration(durationin, unit);
  return moment(Math["ceil"](+date / +duration) * +duration);
}

export function calculateAge(birthday) {
  const startDate = new Date();
  const endDate = new Date(birthday);
  return Math.abs(moment.duration(endDate - startDate).years());
}
//**************** FROM DB *********************//
/* date */

export function dateISOtoString(datein) {
  return moment(datein).format(DISPLAY_DATE);
}
export function dateISOtoStringUtc(datein) {
  return moment(datein).utc().format(DISPLAY_DATE_MED);
}
export function dateISOtoStringShort(datein) {
  return moment(datein).format(DISPLAY_DATE_SHORT);
}

export function dateISOtoStringShortWithYear(datein) {
  return moment(datein).format(DISPLAY_DATE_SHORT_WITH_YEAR);
}

export function dateISOtoStringMed(datein) {
  return moment(datein).format(DISPLAY_DATE_MED);
}
export function dateISOtoStringMedLocal(datein) {
  return moment(datein).local().format(DISPLAY_DATE_MED);
}
export function dateISOtoStringMedUTC(datein) {
  return moment(datein).utc().format(DISPLAY_DATE_MED);
}

export function dateISOtoDateObj(datein) {
  return moment(datein, "YYYY-MM-DD").utc().toDate();
}

/* datetime */
export function dateTimeISOtoStringMed(datein) {
  return moment(datein).format(DISPLAY_DATETIME);
}
export function dateTimeISOtoStringShort(datein) {
  return moment(datein).format(DISPLAY_DATETIME_SHORT);
}
export function datetimeISOtoStringOnAt(datein, timein, is_allday = undefined) {
  return `on ${dateISOtoString(datein)} ${!is_allday ? " at " + timeISOtoString(timein) : ""} `;
}

export function dateTimeISOtoStringMedLocal(datein) {
  return moment(datein).local().format(DISPLAY_DATETIME);
}
export function dateTimeISOtoStringMedUTC(datein) {
  return moment(datein).utc().format(DISPLAY_DATETIME);
}

export function datetimeISOtoDateObj(datein) {
  return moment(datein, "YYYY-MM-DD hh:mm A").utc().toDate();
}
export function datetimeISOtoStringLong(datein) {
  return moment(datein, "YYYY-MM-DD hh:mm A").utc().toDate();
}
/* time */

export function timeISOtoStringN(datein) {
  return moment(datein).format(DISPLAY_TIME);
}
export function timeISOtoStringLocal(datein) {
  return moment(datein).local().format(DISPLAY_TIME);
}
export function timeISOtoStringUtc(datein) {
  return moment(datein).utc().format(DISPLAY_TIME);
}

export function timeISOtoString(datein) {
  // console.log(moment(datein).minutes());
  if (moment(datein).minutes() == 0) {
    return moment(datein).utc().format(DISPLAY_TIME_NO_MINUTES);
  } else {
    return moment(datein).utc().format(DISPLAY_TIME);
  }
}

//**************** TO DB  *********************//

export function dateObjToDBDateTime(datein) {
  return moment(datein).format(DBDATETIMEFORMAT);
}

export function dateObjToDBDate(datein) {
  return moment(datein).format(DBDATEFORMAT);
}
export function dateObjToDBTime(datein) {
  return moment(datein).format(DBTIMEFORMAT);
}
export function currentDayDB() {
  return moment().format(DBDATEFORMAT);
}

export function currentDayToDB() {
  return moment().format(DBDATEFORMAT);
}

export function currentTimeToDB() {
  return moment().format(DBTIMEFORMAT);
}

export function currentTimeToDBlocal() {
  return moment().local().format(DBTIMEFORMAT);
}

export function nowToDB() {
  return moment().format(DBDATETIMEFORMAT);
}

//**************** DISPLAY *********************//
export function todayDisplay() {
  return moment().format(DISPLAY_DATE);
}

export function todayDisplayDateTime() {
  return moment().format(DISPLAY_DATETIME);
}
export function todayDisplayDateTimeSec() {
  return moment().format(DATETIMESEC);
}

export function dateObjToDisplayDatetime(datein) {
  return moment(datein).format(DISPLAY_DATETIME);
}
export function dateObjToDisplayDate(datein) {
  return moment(datein).format(DISPLAY_DATE);
}
export function dateObjToDisplayDateShort(datein) {
  return moment(datein).format(DISPLAY_DATE_SHORT_WITH_YEAR);
}

export function dateObjToDisplayTime(datein) {
  return moment(datein).format(DISPLAY_TIME);
}

//**************** DATE FUNCTIONS *********************//

export function stringToDateObj(datein, format) {
  return moment(datein, format).toDate();
}

export function getNewDefaultDateforMonth(datein) {
  let currentTime = round(moment(), Settings.minuteIncrement, "minutes").format("HH:mm");
  //console.log(currentTime);
  let startDate = moment(datein).format("YYYY-MM-DD");
  return moment(`${startDate} ${currentTime}`);
}
export const currentMonthLabel = moment().startOf("month").format("MMM");
export const nextMonthLabel = moment().add(1, "months").startOf("month").format("MMM");
export const previousMonthLabel = moment().subtract(1, "months").startOf("month").format("MMM");

export const currentMonthStart = moment().startOf("month").format(DBDATEFORMAT);
export const currentMonthEnd = moment().endOf("month").format(DBDATEFORMAT);

export const nextMonthStart = moment().add(1, "months").startOf("month").format(DBDATEFORMAT);
export const nextMonthEnd = moment().add(1, "months").endOf("month").format(DBDATEFORMAT);
export const previousMonthStart = moment().subtract(1, "months").startOf("month").format(DBDATEFORMAT);
export const previousMonthEnd = moment().subtract(1, "months").endOf("month").format(DBDATEFORMAT);

export function getdateinfo(datein, varname) {
  let whatitis = "";
  if (moment.isDate(datein)) {
    whatitis = "date object ";
  } else if (moment.isMoment(datein)) {
    whatitis = "moment object ";
  } else {
    whatitis = typeof datein;
  }
}

export function combineDateAndTimeStrToDateObj(scheduledDate, scheduledTime) {
  const format = "YYYY-MM-DD hh:mm A";
  let x = moment(`${scheduledDate} ${moment(scheduledTime).utc().format("hh:mm A")}`, format);
  return x.toDate();
}
export function combineDateAndTimeStrToMomentObj(scheduledDate, scheduledTime) {
  const format = "YYYY-MM-DD hh:mm A";
  let x = moment(`${scheduledDate} ${moment(scheduledTime).utc().format("hh:mm A")}`, format);
  return x;
}

export function combineDateAndTimeStrToMomentObjNoUtc(scheduledDate, scheduledTime) {
  const format = "YYYY-MM-DD hh:mm A";
  let x = moment(`${scheduledDate} ${moment(scheduledTime).format("hh:mm A")}`, format);
  return x;
}

////////////////// END DATE FUNCTIONS /////////////////////////////////

export function expiredColor(expirydate, color1, color2) {
  //console.log(expirydate);
  if (dateISOtoDateObj(expirydate) < moment().toDate()) return color1 || "red";
  else return color2 || null;
}

export function is_expired(expirydate) {
  if (dateISOtoDateObj(expirydate) < moment().toDate()) return true;
  else return false;
}

export function in_future(lessondate) {
  if (dateISOtoDateObj(lessondate) > moment().toDate()) return true;
  else return false;
}

export function calculate_expiry_date(start_date, days) {
  return moment(start_date).add(days, "days").toDate();
}

export function calculate_makeup_start_date(start_date, days) {
  return moment(start_date)
    .subtract(days || 14, "days")
    .toDate();
}

export const valueFromId = (opts, id) => {
  if (opts) {
    let x = opts.find((o) => o.value == id);

    return x;
    //console.log("valueFromId");
    //console.log(x);
  } else {
    //console.log("no options");
  }
};

export const calculateEndTime = (starttime, duration) => {
  console.log(starttime);
  console.log(duration);
  console.log(
    moment(starttime)
      .add(duration || 60, "minutes")
      .toDate()
  );
  return moment(starttime)
    .add(duration || 60, "minutes")
    .toDate();
};

export const createNameString = (obj) => {
  let returnstr = obj ? obj.map((item) => item.name) : [];
  return returnstr.join(", ") || null;
};

export const createAssignedHorseNameArray = (obj) => {
  let notNullAssignedTo = obj.filter((x) => x.assigned_to !== null);

  return notNullAssignedTo.map((item) => item.assigned_to);
};

export const createAssignedHorseObject = (obj) => {
  //obj is arrry of lessonStudent objects - filters the ones that are assigned and creates a new array of objects with just horse_id and assigned_to (fields from the lessonStudents obj)
  return obj
    .filter((x) => x.assigned_to !== null)
    .map((item) => (({ horse_id, assigned_to }) => ({ horse_id, assigned_to }))(item));
};

export const createUNNAssignedHorseObject = (studentObj, horseObj) => {
  let h = horseObj.filter(
    (x) =>
      !studentObj
        .filter((x) => x.assigned_to !== null) //filters (ie keeps) student objects where assigned to isn't empty ie assigned
        .map((item) => item.horse_id) // maps new item to include the horse_id from the filtered student object
        .includes(x.horse_id) // then filters the horse object where the new mapped student object (with just horse_id) so that it doesn't include the assigned horses
  );

  return h;
};

export const createAssignedHorseObjectWhole = (studentObj, horseObj) => {
  let h = horseObj.filter(
    (x) =>
      studentObj
        .filter((x) => x.assigned_to !== null) //filters (ie keeps) student objects where assigned to isn't empty ie assigned
        .map((item) => item.horse_id) // maps new item to include the horse_id from the filtered student object
        .includes(x.horse_id) // then filters the horse object where the new mapped student object (with just horse_id) so that it doesn't include the assigned horses
  );

  return h;
};

export const mdndm = (studentObj, horseObj) => {
  /*  let h = horseObj.filter(
    x =>
      studentObj
        .filter(x => x.assigned_to !== null) //filters (ie keeps) student objects where assigned to isn't empty ie assigned
        .map(item => item.horse_id) // maps new item to include the horse_id from the filtered student object
        .includes(x.horse_id) // then filters the horse object where the new mapped student object (with just horse_id) so that it doesn't include the assigned horses
  );
  //debugger;
  let y = horseObj
    .map(item => item.horse_id)
    .filter(x => h.includes(x.horse_id));
  //debugger;
  return y;*/
};

export const createHorseIdArray = (obj) => {
  let returnstr = obj ? obj.map((item) => item.horse_id) : [];

  return returnstr || null;
};

export const createNameArray = (obj) => {
  let returnstr = obj ? obj.map((item) => item.name) : [];

  return returnstr || null;
};

export const createListString = (obj) => {
  let returnstr = obj ? obj.map((item) => "<li>" + item.name + "</li>") : [];
  return returnstr.join(" ") || null;
};

export function formatDateTimeForDbEdit(obj) {
  //console.log(obj);
  let formatDateTimeobj = {
    scheduled_date: dateObjToDBDate(obj.start) || "",
    scheduled_end_date: dateObjToDBDate(obj.end) || "",
    scheduled_starttime: dateObjToDBTime(obj.start) || "",
    scheduled_endtime: dateObjToDBTime(obj.end) || "",
    start: dateObjToDBDateTime(obj.start),
    end: dateObjToDBDateTime(obj.end),
    time: timeISOtoString(obj.scheduled_starttime),
    is_allday: obj.is_allday,
  };
  return formatDateTimeobj;
}

export function formatDateTimeForDB(dateInfoObj) {
  //console.log(obj);
  const { start, end, is_allday } = dateInfoObj;

  let formatDateTimeobj = {
    scheduled_date: dateObjToDBDate(start) || "",
    scheduled_end_date: dateObjToDBDate(end) || "",
    scheduled_starttime: dateObjToDBTime(start) || "",
    scheduled_endtime: dateObjToDBTime(end) || "",
    start: dateObjToDBDateTime(start),
    end: dateObjToDBDateTime(end),
    is_allday: is_allday,
  };
  return formatDateTimeobj;
}

export function getOptionChangeValue(prev, next) {
  if (prev) {
    let arr1 = prev;
    let arr2 = next;
    let diff = arr1.filter((x) => !arr2.includes(x)).concat(arr2.filter((x) => !arr1.includes(x)));

    let add = prev.filter((x) => !next.includes(x)).length;
    let addOrRemove = add > 0 ? "remove" : "add";

    return { diff: diff, addRemove: addOrRemove };
  } else return { diff: next, addRemove: "add" };
}

export function formatForDb(obj) {
  //console.log(obj);
  let setObject = { ...obj };

  //console.log("obj");

  let students = [];
  let horses = [];

  if (obj.students) {
    students = obj.students.map((student) => {
      return student.value;
    });
  }
  if (obj.horses) {
    horses = obj.horses.map((horse) => {
      return horse.value;
    });
  }

  let createeventobj = {
    scheduled_date: dateObjToDBDate(obj.start) || "",
    scheduled_end_date: dateObjToDBDate(obj.end) || "",
    scheduled_starttime: dateObjToDBTime(obj.start) || "",
    scheduled_endtime: dateObjToDBTime(obj.end) || "",
    title: obj.title || "",
    instructor_id: obj.instructor_id || 0,
    location_id: obj.location_id || 0,
    section_id: obj.section_id || 0,
    start: dateObjToDBDateTime(obj.start),
    end: dateObjToDBDateTime(obj.end),
    time: timeISOtoString(obj.scheduled_starttime),
    duration: obj.duration,
    students: students,
    horses: horses,
    is_allday: obj.is_allday || false,
    is_recuring: obj.is_recuring || false,
    lesson_notes: obj.lesson_notes,
  };

  if (setObject.id) {
    createeventobj.id = setObject.id;
  }

  /*  if (obj.is_recuring) {
    console.log(obj.repeat);
    let repeat_text = "";
    let repeat_end_type = "";
    //createeventobj.repeat = obj.repeat;
    //let repeatObj = {
    createeventobj.units = obj.repeat.units || "";
    createeventobj.frequency = obj.repeat.frequency || "";
    createeventobj.measure = obj.repeat.measure || "";
    createeventobj.endtype = obj.repeat.endtype || "";
    createeventobj.firstDate = dateObjToDBDate(obj.firstDate);
    createeventobj.nextDate = dateObjToDBDate(obj.nextDate);
    createeventobj.lastDate = dateObjToDBDate(obj.lastDate);

    //debugger;
    createeventobj.repeat_text = repeat_text || "";
    //  };
    //createeventobj.repeatObj = repeatObj;
    //console.log("createeventobj");

    console.log(createeventobj);
  }*/
  return createeventobj;
}

export const formatFromDB = (response) => {
  const events = [];
  console.log(response);
  var time;
  if (response?.lessons) {
    response.lessons.forEach((obj) =>
      events.push({
        key: obj.id,
        start: combineDateAndTimeStrToDateObj(obj.scheduled_date, obj.scheduled_starttime),
        end: combineDateAndTimeStrToDateObj(obj.scheduled_end_date, obj.scheduled_endtime),
        itemstart: combineDateAndTimeStrToMomentObj(obj.scheduled_date, obj.scheduled_starttime),
        itemend: combineDateAndTimeStrToMomentObj(obj.scheduled_end_date, obj.scheduled_endtime),

        id: obj.id,
        scheduled_date: obj.scheduled_date,
        scheduled_starttime: obj.scheduled_starttime,
        scheduled_end_date: obj.scheduled_end_date,
        scheduled_endtime: obj.scheduled_endtime,
        time: timeISOtoString(obj.scheduled_starttime),
        endtime: timeISOtoString(obj.scheduled_endtime),
        title: obj.title || obj.name,
        title_with_time: `${timeISOtoString(obj.scheduled_starttime)} ${obj.title || obj.name}`,
        url: `http://localhost:3000/calendar/?id:${obj.id}`,
        section_id: obj.section_id,
        product_id: obj.product_id,
        section_name: obj.section_name,
        instructor_id: obj.instructor_id,
        location_id: obj.location_id,
        is_allday: obj.is_allday,
        allDay: obj.is_allday,
        instructor_name: obj.instructor_name || "",
        location_name: obj.location_name || "",
        color: obj.color_code,
        lesson_is_cancelled: obj.lesson_is_cancelled,
        lesson_status_code: obj.lesson_status_code,
        lesson_notes: obj.lesson_notes,
        is_recuring: obj.is_recuring || obj.lesson_date_time_series_id,
        lesson_date_time_series_id: obj.lesson_date_time_series_id,
        frequency: obj.frequency,
        period: obj.period,
        repeat_end: dateISOtoStringUtc(obj.repeatEnd),
        refresh: false,
        program_id: obj.program_id,
        program_type_id: obj.program_type_id,
        studentString:
          createNameString(
            response.LessonStudents.filter(
              (students) => students.lesson_date_time_id == obj.id && students.student_is_cancelled == 0
            )
          ) || "",
        horseString:
          createNameString(response.LessonHorses.filter((horses) => horses.lesson_date_time_id == obj.id)) || "",
        assignedHorses:
          response.LessonStudents.filter((students) => students.lesson_date_time_id == obj.id)
            .filter((student) => student.horse_id !== null)
            .map((horse) => horse.horse_id) || [],
        max: obj.max,
        cnt: obj.cnt,
        available: obj.available,
        horse_id: obj.horse_id,
        horse_name: obj.horse_name,
      })
    );
  }
  console.log(events);
  return events;
};

export function calculateCancellationNotice(lesson, cutoff) {
  console.log(lesson);
  console.log(cutoff);
  let timestring = timeISOtoString(lesson.scheduled_starttime);
  let dateandtimestring = lesson.scheduled_date.concat(` ${timestring}`);
  let scheduleddate = moment(dateandtimestring, "YYYY-MM-DD-hh:mm A");
  var diff = scheduleddate.diff(moment(), "hours");
  return parseInt(diff) < Number(cutoff) ? false : true;
}

export function hasPermssion(required, userPermissions) {
  console.log(userPermissions);
  console.log("T");
  if (userPermissions.includes(required)) {
    return true;
  } else {
    return true;
  }
}

export const arrayDiff = (arrayStart, arrayFinish) => {
  let added = arrayFinish.filter((item) => !arrayStart.includes(item)); //* added
  let removed = arrayStart.filter((section) => !arrayFinish.includes(section)); //* removed
  return { added: added, removed: removed };
};

export const onPage = (location) => {
  let last = location.lastIndexOf("/");
  return location.substring(last + 1);
};
