import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Link,
  useRouteMatch,
  useParams,
  withRouter,
  useLocation,
  useHistory,
} from "react-router-dom";
import AuthHelperMethods from "./AuthHelperMethods";
import ReactTooltip from "react-tooltip";
import BlueCheckFuture from "./blueCheckFuture";
import { useAlert } from "react-alert";
import { Row, Col, Container, Modal, ListGroup, ModalBody } from "react-bootstrap";
import { MyContext, ListContext } from "./App";
import InfoModal2 from "./infoModal2.js";
import { EditIcon, AddIcon, InfoIcon, QuestionIcon, ChevronDown, Expand, RemoveIconNoPosition } from "./icons";
import {
  horseAvatar,
  clientAvatar,
  staffAvatar,
  locationAvatar,
  deleteImg,
  privateNoteImg,
  publicNoteImg,
} from "./images";
import COLORS from "./styleConstants";
import ClientHomePage from "./clientHomePage";
import HorseHomePage from "./horseHomePage";
import PrivateRoute from "./privateRoute";
import CancelStudentModal from "./cancelStudentModal";
import { LinkTextBlue, GreyText } from "./styleCustom";
import { ItemModal } from "./lessonTable";
import { ButtonOrange, UserCan } from "./formComponents";
import ManualPurchase from "./manualPurchaseForm";
import moment from "moment";

import {
  currentDayToDB,
  dateObjToDBDate,
  dateObjToDisplayDate,
  calculate_expiry_date,
  todayDisplay,
  stringToDateObj,
} from "./momentUtils";
const Auth = new AuthHelperMethods();

export const StudentHorseDetail = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);

  const alert = useAlert();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState();
  const [currentProfileId, setCurrentProfileId] = useState();
  const [showManualPayment, setShowManualPayment] = useState(false);
  const myparams = useParams();
  console.log(myparams.id);
  let location = useLocation();
  let history = useHistory();
  useEffect(() => {
    console.log("DOES THIS RERENDER?");
    setUrl(`${props.match.url}`);

    console.log("60 ");
    console.log(location);
  }, []);

  useEffect(() => {
    return () => {
      dispatch2(["SET_QUICKPAY_STUDENT_ID", ""]);
    };
  }, []);

  const PackageInfoLink = (props) => {
    //  if (onPurchasesPage) return <div>purchase</div>;
    const { item } = props;
    return (
      <div
        stylex={{
          display: "flex",
          flex: "1 1 ",

          minWidth: 102,
        }}
      >
        <Link to={`${url}/package/${item}`}>{item && "package"}</Link>
      </div>
    );
  };

  const showStudentList = () => {
    console.log("this showStudentList StudentHorseDetail");
    dispatch({ type: "SHOW_STUDENT_LIST", showStudentList: true });
    //  dispatch({ type: "FILTER_STUDENT_LIST_BY_SEARCHTERM" }); //?
  };
  const showHorseList = () => {
    dispatch({ type: "SHOW_HORSE_LIST", showHorseList: true });
  };
  const editStudent = (student, what) => {
    console.log("editStudetn");
    console.log(student);
    dispatch({ type: "SHOW_INFO_MODAL2", what: what });
    dispatch({ type: "EDIT_STUDENT", data: student });
  };
  const editHorse = (horse, what) => {
    console.log(mystate.currentLesson.lessonHorses);

    dispatch({ type: "SHOW_INFO_MODAL2", what: what });
    dispatch({ type: "EDIT_HORSE", data: horse });
  };

  const removeStudent = async (e, studentObjIn) => {
    console.log(e);
    console.log(studentObjIn);

    e.stopPropagation();
    console.log("clicked");
    window._e = e;

    dispatch({
      type: "REMOVE_STUDENT_FROM_LESSON",
      studentObj: studentObjIn,
    });

    commonParams = {
      ...commonParams,
      student_id: studentObjIn.student_id,
      lesson_person_id: studentObjIn.id,
    };
    const result = await Auth.api("lesson_people/delete", "post", commonParams);

    dispatch({
      type: "REMOVE_STUDENT_FROM_LESSON_PEOPLE",
      lessonPersonIds: result.data.lessonPersonIds,
    });
  };

  const removeHorse = (e, horseObjIn) => {
    e.stopPropagation();
    commonParams = { ...commonParams, horse_id: horseObjIn.horse_id };
    dispatch({
      type: "REMOVE_HORSE_FROM_LESSON",
      horseObj: horseObjIn,
    });

    const handleCloseStudentList = () => {
      if (mystate.currentMessage && mystate.currentMessage.length > 0) {
        alert.show(`Successfully added to event: ${mystate.currentMessage}  `, {
          timeout: 5000,
          type: "success",
        });
      }

      dispatch({ type: "SET_ALERT_MESSAGE", name: "" });
      dispatch({ type: "SHOW_STUDENT_LIST", showStudentList: false });
      dispatch({ type: "SHOW_HORSE_LIST", showHorseList: false });
    };

    const result = Auth.api("lesson_date_time_horses/delete", "post", commonParams);
  };

  let commonParams = {
    lesson_date_time_id: mystate.currentLesson.id,
    lesson_date_time_series_id: mystate.currentLesson.lesson_date_time_series_id,
    applyToFuture: mystate.applyToFuture,
    scheduled_date: mystate.currentLesson.scheduled_date,
  };

  const liststyle = {
    minHeight: 50,
    zIndex: 10,
    backgroundColor: "white",
    border: "grey",
  };
  const liststyleCanceled = {
    ...liststyle,
    backgroundColor: "#f6fcf4",
  };

  const switchActiveStudent = () => {
    setCurrentProfileId();
  };

  const clickProfile = (e, id) => {
    e.stopPropagation();
    setCurrentProfileId(id);
    //dispatch2(["SET_QUICKPAY_STUDENT_ID", id]);

    // window.scrollTo(0, 0);
    // if (isMobile) setShowModal(true);
  };
  const finishPayFunction = () => {
    console.log("finishPayFunction");
  };

  const handleQuickPay = async (student) => {
    console.log(student);
    dispatch({
      type: "EDIT_STUDENT",
      data: student,
    });

    dispatch2(["SET_QUICKPAY_STUDENT_ID", student.student_id]);

    let params = { section_id: mystate.currentLesson.section_id };
    const result = await Auth.api("products/getSectionProduct", "get", params);
    let product = result.data.product;
    console.log(result.data.product);
    dispatch2(["SET_CURRENT_PRODUCT", result.data.product]);

    //let product = { ...productin };
    product.counter = 1;
    //SetCounter(counter + 1);
    product.product_id = product.id;
    product.start_count = product.product_count;
    //product.package_start_date = currentDayToDB();
    product.package_start_date = mystate.currentLesson.scheduled_date;
    product.package_expiry_date = mystate.currentLesson.scheduled_date;

    product.purchase_date = currentDayToDB();
    //product. = product.expires_in ? dateObjToDBDate(moment().add(product.expires_in, "days")) : null;
    product.student_id = student.student_id || null;
    product.person_id = student.person_id || null;
    product.student_name = student.name;
    product.product_name = product.product_name;
    product.tax_percent = mystate.farmDefaults.tax_percent;

    product.fee = 0; //! quickpay is always manual so there is never a fee  //!! nov 29 - not anymore re quickpay card payment

    //  setValidFrom(moment(productin.package_start_date.toDate());
    //setPurchaseDate(currentDayToDB());
    console.log(product.package_start_date);

    dispatch2(["ADD_TO_CART", product]);
    // transformProduct(product);
    // dispatch2(["SET_CURRENT_PRODUCT", productin]);
    // setShowManualPayment(true);
    console.log(product);
    return product;
  };

  function getCancelText(code) {
    if (code.startsWith("B")) return "Barn Cancel: ";
    return "Student Cancel:";
  }

  return (
    <>
      <Row style={{ marginTop: "20px", flex: "1 1 100%" }}>
        <Col xs={12} md={6} style={{ flex: "1 1 100%" }}>
          <UserCan doThis={"STAFF_CAN_ADD_STUDENTS"}>
            <Row style={{ display: "flex", justifyContent: "center" }} data-cy="addStudentDetail">
              <ButtonOrange onClick={showStudentList} label={"Add Clients"} />
            </Row>
          </UserCan>
          <div className="blocklabel" style={{ marginTop: 0 }}>
            CLIENT DETAIL
          </div>

          {mystate.currentLesson.lessonStudents &&
            mystate.currentLesson.lessonStudents.map((student) => {
              return (
                <>
                  <div
                    className="studentDetail"
                    id={student.student_id}
                    key={student.student_id}
                    style={{
                      padding: 5,
                      paddingTop: 10,
                      paddingBottom: 10,
                      minHeight: 50,
                      zIndex: 10,
                      backgroundColor: "white",
                      border: "solid 1pt grey",
                      backgroundColor: student.student_is_cancelled ? "#f6fcf4" : "white",
                    }}
                    onClick={() => switchActiveStudent()}
                  >
                    {" "}
                    <div
                      className={"detailRow1"}
                      style={{
                        display: "flex",
                        flex: "1 1 100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        value={student.id}
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        <img src={clientAvatar} alt={"client"} />

                        {student.name}
                      </div>
                      <UserCan doThis={"STAFF_CAN_ASSIGN_HORSES"}>
                        <div
                          data-cy="assignHorses"
                          style={{ cursor: "pointer" }}
                          onClick={() => editStudent(student, "assign")}
                        >
                          <img alt={"horse"} src={horseAvatar} />

                          <LinkTextBlue>{student.assigned_to ? student.assigned_to : "assign horse"}</LinkTextBlue>
                        </div>
                      </UserCan>
                      <UserCan doThis={"STAFF_CAN_REMOVE_STUDENT_FROM_LESSON"}>
                        <img
                          data-cy="removeStudent"
                          alt={"remove"}
                          onClick={(e) => removeStudent(e, student)}
                          style={{
                            zIndex: 1000,
                          }}
                          src={deleteImg}
                        />
                      </UserCan>
                    </div>
                    <div
                      className="detailRow2"
                      style={{
                        display: "flex",
                        flex: "1 1 100%",
                        justifyContent: "space-around",
                        fontStyle: "italic",
                        paddingBottom: 10,
                      }}
                    >
                      {mystate.farmDefaults.show_paid_not_paid && <div>{!student.item_id ? "not paid" : "paid"}</div>}

                      <div>
                        {student.enrollment_status_code && student.student_is_cancelled
                          ? `${getCancelText(student.enrollment_status_code)}  ${student.enrollment_status_name || ""} `
                          : ""}
                        {student.makeup_lesson_id && "Makeup Lesson"}
                      </div>
                    </div>
                    <div
                      className="detailRow3"
                      style={{
                        display: "flex",
                        flex: "1 1 100%",
                        justifyContent: "space-evenly",
                        flexWrap: "wrap",
                        paddingBottom: 10,
                      }}
                    >
                      <UserCan doThis={"STAFF_CAN_VIEW_CLIENT_PROFILE"}>
                        <div data-cy="studentProfile">
                          <Link
                            style={{ marginRight: 20 }}
                            to={`${props.match.url}/clientprofile/${student.person_id}/details`}
                            onClick={(e) => clickProfile(e, student.person_id)}
                          >
                            {"profile"}
                          </Link>
                        </div>
                      </UserCan>

                      <UserCan doThis={"STAFF_CAN_CANCEL_STUDENT_LESSON"}>
                        <div data-cy="cancelStudent">
                          {!student.student_is_cancelled && (
                            <div style={{ cursor: "pointer" }} className="adminCancel">
                              <Link
                                to={`${props.match.url}/modalCancel/${student.id}/cal`}
                                onClick={() =>
                                  dispatch({
                                    type: "EDIT_STUDENT",
                                    data: student,
                                  })
                                }
                              >
                                {" cancel student"}
                              </Link>
                            </div>
                          )}
                        </div>
                      </UserCan>
                      {showManualPayment && <ManualPurchase />}
                      <UserCan doThis={"STAFF_CAN_ADD_STUDENT_LESSON_NOTES"}>
                        <div data-cy="addStudentNotes">
                          {!student.student_is_cancelled && (
                            <div style={{ cursor: "pointer" }} onClick={() => editStudent(student, "notes")}>
                              <LinkTextBlue> {"add notes"}</LinkTextBlue>
                            </div>
                          )}
                        </div>
                      </UserCan>
                      {student.item_id && (
                        <div>
                          <Link to={`${props.match.url}/package/${student.item_id}`}>
                            {student.item_id && "package"}
                          </Link>
                        </div>
                      )}
                      {!student.item_id && mystate.currentLesson.product_id && (
                        <Link to={`${props.match.url}/manual`}>
                          <div style={{ cursor: "pointer" }} onClick={() => handleQuickPay(student)}>
                            {!student.item_id && "Quick Pay"}
                          </div>
                        </Link>
                      )}
                    </div>
                    <UserCan doThis={"STAFF_CAN_VIEW_STUDENT_LESSON_NOTES"}>
                      <div data-cy="viewStudentLessonNotes">
                        {mystate.currentLesson.studentLessonNotes &&
                          mystate.currentLesson.studentLessonNotes.map((note) =>
                            note.student_id === student.student_id ? (
                              <Row key={note.id} style={{ border: "none", marginTop: 10 }}>
                                <Col xs={1}>
                                  {" "}
                                  <img
                                    alt={note.is_public ? "public" : "private"}
                                    style={{ maxHeight: "15px" }}
                                    src={note.is_public ? publicNoteImg : privateNoteImg}
                                  />
                                </Col>
                                <Col style={{ whiteSpace: "pre-wrap", fontStyle: "italic" }}>{note.notes} </Col>
                              </Row>
                            ) : null
                          )}
                      </div>
                    </UserCan>
                  </div>
                  <div
                    className="mobileProfile"
                    style={{
                      display: "flex",
                      flex: "1 1 100%",
                      flexWrap: "wrap",
                    }}
                  >
                    {currentProfileId == student.person_id && isMobile && (
                      <>
                        {" "}
                        <div>
                          <PrivateRoute path={`${props.match.url}/clientprofile/:id`} component={ClientHomePage} />
                        </div>
                      </>
                    )}
                  </div>
                </>
              );
            })}

          <Row className="justify-content-md-center" style={{ display: "flex", justifyContent: "center" }}>
            <UserCan doThis={"STAFF_CAN_ADD_HORSES"}>
              <div data-cy="addHorseDetail">
                <ButtonOrange onClick={showHorseList} label={"Add Horses"} />
              </div>
            </UserCan>
          </Row>
          <div className="blocklabel" style={{ marginTop: 0 }}>
            HORSE DETAIL
          </div>

          <div>
            {mystate.currentLesson.lessonHorses &&
              mystate.currentLesson.lessonHorses.map((horse) => (
                <>
                  <div
                    key={horse.horse_id}
                    style={{
                      padding: 5,
                      paddingTop: 10,
                      paddingBottom: 10,
                      minHeight: 50,
                      zIndex: 10,
                      backgroundColor: "white",
                      border: "solid 1pt grey",
                      backgroundColor: "white",
                    }}
                    onClick={() => setCurrentProfileId()}
                  >
                    <div
                      className={"HorseRow1"}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBottom: 10,
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", fontWeight: "bold" }}>
                        <img src={horseAvatar} alt={"horse"} />
                        {horse.name || horse.horse_name}
                      </div>

                      <UserCan doThis={"STAFF_CAN_REMOVE_HORSE_FROM_LESSON"}>
                        <img
                          data-cy="removeHorse"
                          alt={"delete"}
                          style={{
                            zIndex: 1000,
                          }}
                          src={deleteImg}
                          onClick={(e) => removeHorse(e, horse)}
                        />
                      </UserCan>
                    </div>

                    <div
                      className={"HorseRowTwo"}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        paddingBottom: 10,
                      }}
                    >
                      <UserCan doThis={"STAFF_CAN_VIEW_HORSE_PROFILE"}>
                        <div data-cy={"horseProfile"}>
                          <Link
                            style={{ marginRight: 20 }}
                            to={`${props.match.url}/horsehome/${horse.horse_id}/details`}
                            onClick={(e) => clickProfile(e, horse.horse_id)}

                            // onClick={(e) => e.stopPropagation()}
                          >
                            {"profile"}
                          </Link>
                        </div>
                      </UserCan>

                      <UserCan doThis={"STAFF_CAN_ADD_HORSE_LESSON_NOTES"}>
                        <div
                          data-cy={"addHorseNotes"}
                          style={{ display: "flex", cursor: "pointer", alignItems: "flex-end" }}
                          onClick={() => editHorse(horse, "notes")}
                        >
                          <LinkTextBlue>{"add notes"}</LinkTextBlue>
                        </div>
                      </UserCan>

                      <UserCan doThis={"STAFF_CAN_ADD_TACK_TO_HORSE"}>
                        <div
                          data-cy="addTack"
                          style={{ display: "flex", cursor: "pointer", alignItems: "flex-end" }}
                          onClick={() => editHorse(horse, "tack")}
                        >
                          <LinkTextBlue>{horse.tack_id ? horse.tack_name : "assign tack"}</LinkTextBlue>
                        </div>
                      </UserCan>
                    </div>
                    <UserCan doThis={"STAFF_CAN_VIEW_HORSE_LESSON_NOTES"}>
                      <div data-cy="viewHorseNotes">
                        {mystate.currentLesson.horseLessonNotes &&
                          mystate.currentLesson.horseLessonNotes.map((note) =>
                            note.horse_id === horse.horse_id ? (
                              <Row key={note.id} style={{ border: "none" }}>
                                <Col xs={1}>
                                  {" "}
                                  <img
                                    alt={"public or private"}
                                    style={{ maxHeight: "15px" }}
                                    src={note.is_public ? publicNoteImg : privateNoteImg}
                                  />
                                </Col>
                                <Col>{note.notes} </Col>
                              </Row>
                            ) : null
                          )}
                      </div>
                    </UserCan>

                    <div
                      className="mobileProfile"
                      style={{
                        display: "flex",
                        flex: "1 1 100%",
                        flexWrap: "wrap",
                      }}
                    >
                      {currentProfileId == horse.horse_id && isMobile && (
                        <>
                          {" "}
                          <div>
                            <PrivateRoute path={`${props.match.url}/horsehome/:id`} component={HorseHomePage} />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ))}
          </div>
        </Col>
        {!isMobile && (
          <Col xs={12} md={6}>
            <>
              <div className="blocklabel" style={{ marginTop: 0 }}>
                CLIENT/HORSE PROFILE{" "}
              </div>
              {currentProfileId && <PrivateRoute path={`${props.match.url}/horsehome/:id`} component={HorseHomePage} />}
              {currentProfileId && (
                <PrivateRoute path={`${props.match.url}/clientprofile/:id`} component={ClientHomePage} />
              )}
            </>
          </Col>
        )}
      </Row>
      <Switch>
        <PrivateRoute path={`${props.match.path}/modalCancel/:lpid/:where`} component={CancelStudentModal} />
        <PrivateRoute path={`${props.match.path}/package/:id`} component={ItemModal} />
        <PrivateRoute exact path={`${props.match.url}/manual`} component={ManualPurchase} />
      </Switch>
      {mystate.showInfoModal2 && <InfoModal2 />}
    </>
  );
};

export default StudentHorseDetail;
