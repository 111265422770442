import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { BlockLabel, StyledList, StyledListGroup, BlueButton, BlueText, BlockLabelNoMargin } from "./styleCustom";
import { FormRightHeader, FormRight, BackTo } from "./formComponents";
import { TimesIconBlue, CheckSquare, Square } from "./icons";
import { Row, Col } from "react-bootstrap";
import SelectList from "./selectList";
import { ListContext } from "./App";
import { RemoveIcon, RemoveIconNoPosition } from "./icons";
import AuthHelperMethods from "./AuthHelperMethods";
import { active_options } from "./Constants2";
import { Active } from "./searchFilterReports";
const Auth = new AuthHelperMethods();

const SectionAssignHorse = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  let params = {};
  const [assignedArray, SetAssignedArray] = useState([]);
  const [search, setSearch] = useState("");
  const [sendEmail, setSendEmail] = useState(false);
  let history = useHistory();
  function goBackToList() {
    history.push("/admin/setup/sections");
  }
  let section_id = useParams().id;
  var section_name = "section";
  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  useEffect(
    () => {
      const fetchData = async () => {
        //   let params = { filter: listState.filter };
        params.section_id = section_id;
        const result = await Auth.api("section_horses/sectionHorsesArray", "get", params);
        SetAssignedArray(result.data.sectionHorsesArray);
      };
      if (section_id) fetchData();

      return () => {
        dispatch2(["CLEAR_SECTION_HORSES"]);
      };
    },
    //eslint-disable-next-line
    [section_id]
  );
  useEffect(
    () => {
      // let params = { is_active: 1 };
      let params = { ...listState.filter };

      const fetchData = async () => {
        const result = await Auth.api("horses/getHorsesAll", "get", params);
        dispatch2(["HORSES_GET", result.data]);
      };
      fetchData();
    },
    //eslint-disable-next-line
    [listState.filter]
  );

  /*useEffect(
    () => {
      console.log("useEffect");
      window.searchinputRef = searchinputRef;
      searchinputRef.current.focus();
    },
    //eslint-disable-next-line
    []
  );
*/
  // const searchinputRef = React.createRef();

  const unassign = async (objectIn) => {
    let params = { horse_id: objectIn.id, section_id: section_id };
    let result = await Auth.api("section_horses/delete", "post", params);
    SetAssignedArray(result.data.sectionHorsesArray);
  };

  const addHorseToSection = async (objectIn) => {
    let params = { horse_id: objectIn.id, section_id: section_id };

    try {
      let result = await Auth.api("section_horses/create", "post", params);
      SetAssignedArray(result.data.sectionHorsesArray);
    } catch (error) {
      console.log(error);
    }
  };

  if (!listState.sectionList) return null;
  if (!listState.horseList) return null;
  section_name = listState.sectionList.find((item) => item.id == section_id);
  section_name = section_name ? section_name.section_name : "section";

  return (
    <>
      <BackTo url={`/admin/setup/sections`} pagename={"section list"} />

      <div
        style={{
          display: "flex",
          flex: "1 1 100%",
          minWidth: 700,
          justifyContent: "center",
          marginBottom: 100,
        }}
      >
        <div className="Border">
          <div
            className="header"
            style={{
              display: "flex",
              flexWrap: "wrap",
              flex: "1 1 100%",
              justifyContent: "center",
            }}
          >
            <FormRightHeader>{`Assign Horses to ${section_name} `}</FormRightHeader>
          </div>
          <Active field={"is_active"} default={"1"} options={active_options} />

          <div style={{ display: "flex", flexWrap: "nowrap" }}>
            <div
              className="left"
              style={{
                display: "flex",
                flexWrap: "wrap",
                flex: "1 1 50%",
                alignContent: "flex-start",
                padding: 10,
                marginRight: 60,
              }}
            >
              {" "}
              <BlockLabelNoMargin> assigned section horses </BlockLabelNoMargin>
              {assignedArray < 1 && <p>No Horses Assigned</p>}
              {assignedArray.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flex: "1 1 100%",
                    flexDirection: "column",
                  }}
                >
                  {listState.horseList
                    .filter((horse) => assignedArray.includes(horse.id))
                    .map((item) => (
                      <div
                        key={item.id}
                        onClick={() => unassign(item)}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: 5,
                        }}
                      >
                        {item.name || item.horse_name} <RemoveIconNoPosition />
                      </div>
                    ))}
                </div>
              )}
            </div>

            <div
              className="right"
              style={{
                display: "flex",
                flex: "1 1 50%",
                flexDirection: "column",
              }}
            >
              <input
                style={{ marginTop: 10, marginBottom: 10 }}
                autoFocus
                type="text"
                value={search}
                onChange={(e) => handleSearchChange(e)}
                placeholder="start typing to search for a client"
              />
              {listState.horseList && listState.horseList.length > 0 && (
                <div>
                  {listState.horseList
                    .filter((horse) => horse.name.toLowerCase().includes(search.toLowerCase()))
                    .map((item) => (
                      <div
                        key={item.id}
                        onClick={() => addHorseToSection(item)}
                        style={{ display: "flex", marginBottom: 5 }}
                      >
                        {assignedArray && assignedArray.includes(item.id) ? <CheckSquare /> : <Square />}

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: 20,
                          }}
                        >
                          {" "}
                          {item.name}
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          <Row
            style={{
              justifyContent: "center",
              display: "flex",
              flex: "1 1 100%",
            }}
          >
            <BlueButton onClick={() => goBackToList()}>{"Close"} </BlueButton>
          </Row>
        </div>
      </div>
    </>
  );
};

export default SectionAssignHorse;
