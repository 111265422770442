import { useState } from "react";

import COLORS from "./styleConstants";
import { ButtonOrange } from "./formComponents";
import { Modal } from "react-bootstrap";
import AuthHelperMethods from "./AuthHelperMethods";
const Auth = new AuthHelperMethods();

export default function SupportMessage() {
  const [support, setSupport] = useState({ subject: "", message: "" });
  const [showModal, setShowModal] = useState(false);

  async function sendEmail() {
    let params = {
      msg: support.message,
      summary: support.subject,

      //noteRegardingLesson: noteRegardingLesson,
    };
    const result = await Auth.api("notes_clients/createSupportEmail", "post", params);
    if (result) {
      setShowModal(false);

      alert("Your message has been sent.");
      setSupport({});
    }

    //  console.dir(result.data);
  }

  return (
    <div>
      <Modal show={showModal} size="md" centered onHide={() => setShowModal(false)}>
        <Modal.Header style={{ background: COLORS.blue, color: "white" }} closeButton>
          <Modal.Title>{"Send Message To Support"} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex flex-col", color: COLORS.darkgrey }}>
            <div style={{ fontStyle: "italic", color: COLORS.orange }}>
              If you need to add an attachment to your question, please email <email>support@equineoffice.com</email>{" "}
              directly.
            </div>
            <div style={{ fontStyle: "italic", color: COLORS.orange, marginBottom: 10 }}>
              Please include your Farm Name in the email.
            </div>{" "}
            <div>Subject</div>
            <input
              style={{
                minWidth: "100%",
                border: `solid 2pt ${COLORS.blue}`,
                resize: "none",
                borderRadius: 3,
                padding: 5,
              }}
              type="text"
              value={support.subject}
              onChange={(e) => setSupport({ ...support, subject: e.target.value })}
            />
            <div style={{ marginTop: 8 }}>How can we help?</div>
            <textarea
              style={{
                minWidth: "100%",
                border: `solid 2pt ${COLORS.blue}`,
                resize: "none",
                borderRadius: 3,
                padding: 5,
                whiteSpace: "pre-wrap",
              }}
              rows={8}
              value={support.message}
              onChange={(e) => setSupport({ ...support, message: e.target.value })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {" "}
          <ButtonOrange label={"Send Message Now"} onClick={() => sendEmail()} />
        </Modal.Footer>
      </Modal>
      <ButtonOrange label={"Send Message to Support"} onClick={() => setShowModal(true)} />
    </div>
  );
}
