import React, { Fragment, useState, useContext, useEffect } from "react";
import { MyContext } from "./App";

const TextInputDesc = (props) => {
  const { mystate, dispatch } = useContext(MyContext);

  /* useEffect(
    () => {
    StudentAddSections();

      }
    },
    //eslint-disable-next-line
    [mystate.addEvent.title]
  );
*/

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_NEW_EVENT",
      data: {
        description: e.target.value,
      },
    });

    //console.log(e);
  };

  return (
    <textarea
      className="form-control"
      onChange={(e) => handleInputChange(e)}
      value={mystate.addevent.description}
      style={{ fontSize: "10pt" }}
    />
  );
};

export default TextInputDesc;
