import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Formik, Form, useField, useFormikContext, FieldProps, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  MediumPageHeader,
  BoxWithLabelW,
  BoxWithLabel200,
  BoxWithLabelNoM,
  HelpText,
  StyledErrorMessage,
  OrangeButton,
} from "./styleCustom";
import { formContainerStyle } from "./styleConstants";
import {
  MyTextInput,
  MyTextAreaInput,
  FormRightSave,
  TextArea,
  TextInput,
  CheckBox,
  OrangeBlueButton,
} from "./formComponents";
import { errormsg } from "./Constants2";
//import "./styleForm.css";
//import "./styleFormik.css";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col } from "react-bootstrap";
import { ListContext } from "./App";
import { MyContext } from "./App";
import { useAlert } from "react-alert";
import { useHistory, useParams } from "react-router-dom";
import { BrowserRouter, Route, Switch, Link, NavLink } from "react-router-dom";
const Auth = new AuthHelperMethods();

const HorseForm = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const alert = useAlert();
  let history = useHistory();
  let params = useParams();

  let addoredit = params.addoredit;

  let title = addoredit === "edit" ? "Edit Current Horse" : "Enter Name of New Horse";

  console.log(addoredit);
  useEffect(
    () => {
      /*    let horsearray = Object.entries(listState.currentHorse);

      let formInitialValues = horsearray.map(
        horse => `${horse[0]}: ${horse[1]}`
      );
      dispatch2(["SET_FORM_INITIAL_VALUES", listState.currentHorse]);*/
      let horse = listState.currentHorse;
      console.log(horse);

      let formInitialValues = {
        id: horse.id,
        name: horse.horse_name || "",
        height: horse.height || "",
        color: horse.color || "",
        age: horse.age || "",
        gender: horse.gender || "",
        desc: horse.desc || "",
        notes: horse.general_notes || "",
        notes2: horse.general_notes2 || "",
        notes3: horse.general_notes3 || "",
        tack: horse.tack || "",
      };

      dispatch2(["SET_FORM_INITIAL_VALUES", formInitialValues, "edit"]);
    },
    //eslint-disable-next-line
    [listState.currentHorse]
  );
  useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        const result = await Auth.api("options/index", "get", params);
        dispatch({
          type: "UPDATE_OPTIONS",
          options: result.data,
        });
      };
      fetchData();
    },
    //eslint-disable-next-line
    [listState.horses]
  );

  return (
    <>
      <div>
        <Formik
          initialValues={listState.formInitialValues}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("horse must have a name"),
            height: Yup.number(),
            color: Yup.string(),
            age: Yup.number().min(1950).max(2050),
            gender: Yup.string(),
            desc: Yup.string(),
            notes: Yup.string(),
            notes2: Yup.string(),
            notes3: Yup.string(),
            tack: Yup.string(),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            //  e.preventDefault();

            dispatch({ type: "FETCH_INIT" });

            try {
              const result = await Auth.api("horses/update", "post", values);
              dispatch2(["HORSE_EDIT", result.data]);

              dispatch({ type: "FETCH_SUCCESS" });

              /*  history.push(
                `/admin/horsehome/${listState.currentHorse.id}/details`
              );*/
              history.goBack();
            } catch (error) {
              console.log(error);
              dispatch({ type: "FETCH_ERROR" });
              alert.show(errormsg, { timeout: 5000, type: "error" });
            }
            //  dispatch2(["CLOSE_ADD_EDIT_FORM"]);
          }}
        >
          <Form
            id="horseForm"
            style={{
              display: "flex",
              flex: "1 1 100%",
              justifyContent: "space-evenly",
              alignItems: "flexStart",
              flexWrap: "wrap",
              margin: 30,
              minHeight: 400,
              border: "solid 3pt #0099BC",
              padding: 20,
              marginTop: 50,
            }}
          >
            <div
              style={{
                display: "flex",
                flex: "1 1 100%",
                justifyContent: "center",
              }}
            >
              <MediumPageHeader>{title}</MediumPageHeader>
            </div>

            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 1 auto",
                  marginRight: 50,
                }}
              >
                <TextInput name="name" label="Horse Name" />
                <HelpText>{"required"}</HelpText>
                <ErrorMessage name="name" component={StyledErrorMessage} />

                <TextInput name="height" type="number" label="Height" />
                <TextInput name="color" label="Color" />
                <TextInput name="age" label="Year Born" type="number" placeholder="enter 4 digit year" />
                <TextInput name="gender" label="Gender" />
                <TextInput name="desc" label="Markings" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "2 2 auto",
                }}
              >
                <TextArea label={"Tack"} name="tack" />
                <TextArea label={"General Notes 1"} name="notes" />
                <TextArea label={"General Notes 2"} name="notes2" />
                <TextArea label={"General Notes 3"} name="notes3" />
              </div>
            </>

            <div
              style={{
                display: "flex",
                flex: "1 1 100%",
                justifyContent: "center",
              }}
            >
              <OrangeButton type="submit" style={{ marginTop: 40 }}>
                {"Save "}
              </OrangeButton>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};
export default HorseForm;
