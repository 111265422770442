import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link, useParams, useHistory, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import AuthHelperMethods from "./AuthHelperMethods";
import { ListContext, MyContext } from "./App";
import moment from "moment";
import COLORS from "./styleConstants";
import useSWR from "swr";
import { Calendar, momentLocalizer } from "react-big-calendar";
import AddModal from "./addModal.js";
import { SelectTemplateFilter } from "./searchFilterReports";
import { dateObjToDisplayDate } from "./momentUtils";
import { Filter, FilterLabels, SelectFilter, FilterActive } from "./calendarFilter";
import { resourceAccessor, eventStyleGetter, dayPropGetter } from "./calendarConstants";
import {
  CustomEvent,
  BlueResourceButtons,
  BulkButtons,
  ToggleStudentEventName,
  ToggleShowFilter,
  AgendaEvent,
} from "./calendarComponents";
import { formatEventsFromDB, calculateDateRange } from "./calendarFunctions";
import AppointmentCalendar from "./features/appointments/calendar/index.jsx";

const localizer = momentLocalizer(moment);
const Auth = new AuthHelperMethods();

export const CalendarHome = ({ match, handleexisting, hideResource, hideFilter }) => {
  //console.log(props);
  //const [data, setData] = useState([]);
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);
  const [currentResourceOptions, SetCurrentResourceOptions] = useState([]);
  const [showFilter, setShowFilter] = useState(isMobile ? false : true);
  const isAdmin = Auth.getUserType() == "ADM" || Auth.getUserType() == "INS" ? true : false;
  const [stateData, setStateData] = useState([]);
  let history = useHistory();
  let location = useLocation();
  moment.updateLocale("en", {
    week: {
      dow: mystate.farmDefaults.monday_start ? 1 : 0, // First day of week is Monday
      doy: mystate.farmDefaults.monday_start ? 7 : 6, // First week of year must contain 1 January (7 + 1 - 1)
    },
  });
  const resourceMap = {
    instructor: mystate.options_instructor,
    location: mystate.options_location,
    section: mystate.options_section,
    horse: mystate.options_horse,
    tack: mystate.options_tack,
    default: [],
  };

  /* useEffect(() => {
    console.log("FILTER AVAILABLW");

    // if (listState.filter.page != "calendar") dispatch2(["SET_SEARCH_FILTER", "calendar", "page"]);
  }, [listState.filter.available]);
*/

  // let xevents = formatEventsFromDB(data);
  useEffect(
    () => {
      return () => {
        dispatch2(["SET_COPY_FILTER_TO_CALENDAR"]);
        // dispatch2(["RESET_SEARCH_FILTER"]);
      };
    },
    //eslint-disable-next-line
    []
  );

  useEffect(() => {
    console.log("INITAL USEEFFECT");

    /* if (
      Object.keys(listState.filtercal).length > 0 &&
      listState.filter.date1 &&
      listState.filter.date2 &&
      listState.filter.page == "calendar" &&
      listState.filter.view
    ) {
      console.log("SET_COPY_TO_SEARCH_FILTER");

      dispatch2(["SET_COPY_TO_SEARCH_FILTER", listState.filtercal, listState.filter_labelscal]);
    } else {
      */
    /* dispatch({
      type: "SET_CALENDAR_DATE",
      data: moment().toDate(),
    });*/
    // let [date1, date2] = calulateInitialCalendarDateRange("month");
    let date1 = mystate.calendarDateRangeStart;
    let date2 = mystate.calendarDateRangeEnd;
    console.log(date1 + date2);
    let initialfilter = {
      ...(mystate.resource && { resource: mystate.resource }),
      date1: date1,
      date2: date2,
      page: "calendar",
      view: mystate.view ? mystate.view : "day",
    };
    console.log(initialfilter);

    dispatch2(["SET_SEARCH_FILTER_OBJECT", initialfilter]);
    // }
    // if (listState.filter.page != "calendar") dispatch2(["SET_SEARCH_FILTER", "calendar", "page"]);
  }, []);

  const { data, error, isLagging, resetLaggy, isValidating } = useSWR(
    listState.filter.date1 && listState.filter.date2 && listState.filter.page == "calendar" && listState.filter.view
      ? [`lessons/index`, listState.filter]
      : null
  );
  // setStateData(data);
  console.log("we got data");

  console.log(data);
  const formattedData = formatEventsFromDB(data);

  console.log("formattedData");
  console.log(formattedData);

  function handleRangeChange(e) {
    console.log("****** OMG ******* RANGE CHANGE  ************");
    console.log(e);
    var [currentStart, currentEnd, view] = calculateDateRange(e);
    //dispatch2(["SET_SEARCH_FILTER", view, "view"]);
    dispatch2(["SET_BOTH_SEARCH_FILTER_DATES", currentStart, currentEnd, "calendar"]);
    dispatch({
      type: "SET_CALENDAR_DATE_RANGE",
      start: currentStart,
      end: currentEnd,
    });
    console.log("set view from range");
    /* dispatch({
      type: "SET_VIEW",
      data: view,
    });
    */
  }

  function handleViewChange(e) {
    console.log(e);
    console.log("handleViewChange");
    console.log("****** OMG ******* VIEW CHANGE  ************");

    dispatch({
      type: "SET_VIEW",
      data: e,
    });
    dispatch2(["SET_SEARCH_FILTER", e, "view"]);
  }

  function handleNavigate(date) {
    console.log("handleNavigate");
    console.log("****** OMG ******* NAVIGATE CHANGE  ************");

    console.log(date);

    dispatch({
      type: "SET_CALENDAR_DATE",
      data: moment(date.toDateString()).toDate(),
    });
  }

  const handleNewEvent = (event) => {
    console.log(event);
    if (Auth.getUserType() == "STU" && !mystate.farmDefaults.clients_can_add_lesson) return null;
    //props.newevent();
    dispatch({
      type: "SET_EVENT",
      event: event,
    });
    history.push(`/admin/add/:add`);
    // if (props.afteradd) props.afteradd();
  };

  const handleExistingEvent = (e) => {
    if (Auth.getUserType() == "STU") return null;
    console.log(e);
    dispatch({
      type: "SET_EVENT",
      event: e,
    });

    //if (props.handleexisting) props.handleexisting();

    if (handleexisting) history.push(`${match.url}/${handleexisting}`);
    else history.push(`/admin/calendarGroup/edit/${e.id}`);
  };

  useEffect(
    () => {
      setResourceMap(mystate.resource);
    },
    //eslint-disable-next-line
    [listState.filter]
  );

  function setResourceMap(item) {
    let data = [];
    if (resourceMap[item] && listState.filter[`${item}`]) {
      data = resourceMap[item].filter((r) => listState.filter[`${item}`].includes(r.value));
    } else {
      data = resourceMap[item];
    }

    dispatch({
      type: "SET_RESOURCE_MAP",
      data: data,
    });
  }

  const onClickResource = (item) => {
    dispatch2(["SET_SEARCH_FILTER", item, "resource"]);
    dispatch({
      type: "SET_RESOURCE_ACCESSOR",
      resourceAccessor: resourceAccessor[item],
    });
    dispatch({
      type: "SET_RESOURCE",
      data: item,
    });
    setResourceMap(item);
  };

  let formats = {
    agendaHeaderFormat: ({ start, end }) => {
      return moment(start).format("dddd MMMM Do") + " - " + moment(end).format("dddd MMMM Do");
    },
  };
  return (
    <div
      className="reportWrapper"
      style={{ display: "flex", flex: "1 1 100%", flexWrap: "nowrap", marginTop: 10, alignItems: "flex-start" }}
    >
      {" "}
      <div
        className="reportTable"
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          flex: "1 1 ",
        }}
      >
        {" "}
        <ToggleShowFilter />
        <ToggleStudentEventName /> <BulkButtons />
        {1 == 2 && <div>{data?.lessonStudentCancellations.map((lp) => lp.name)}</div>}
        <div style={{ display: "flex", flexDirection: "column" }}>
          {listState.showfilter && (
            <>
              {" "}
              <SelectFilter staff program_type program location section student horse />
              <FilterActive lessonCancel available studentCancel />
            </>
          )}
        </div>
        <FilterLabels />
        <div style={{ display: "flex", flex: "1 1 100%", justifyContent: "flex-end" }}>
          <BlueResourceButtons onClickResource={onClickResource} />
        </div>
        <div
          className={"table"}
          style={{
            display: "flex",
            flex: "1 1 90%",
            flexDirection: "column",
            minHeight: 700,
          }}
        >
          {1 == 2 && <AppointmentCalendar appointments={formattedData} />}
          <Calendar
            events={formatEventsFromDB(data)}
            localizer={localizer}
            defaultView={mystate.view || "day"}
            selectable
            min={mystate.farmDefaults.min_time}
            max={mystate.farmDefaults.max_time}
            date={mystate.calendarDate}
            eventPropGetter={eventStyleGetter}
            dayPropGetter={dayPropGetter}
            components={{ event: isAdmin ? CustomEvent : null }}
            step={mystate.farmDefaults.calendar_interval}
            timeslots={mystate.farmDefaults.calendar_timeslot || 2}
            resources={
              mystate.resource_map == undefined || mystate.resource_map.length === 0 || mystate.view == "week"
                ? null
                : mystate.resource_map
            }
            resourceAccessor={mystate.resourceAccessor}
            resourceIdAccessor={"value"}
            resourceTitleAccessor={"label"}
            popup
            formats={formats}
            onView={handleViewChange}
            onRangeChange={handleRangeChange}
            onNavigate={handleNavigate}
            onSelectSlot={(event) => handleNewEvent(event)}
            onSelectEvent={(event) => handleExistingEvent(event)}
            view={mystate.view}
          />
        </div>
      </div>
    </div>
  );
};
export default CalendarHome;
