import { useState, useEffect } from "react";
import axios from "axios";
import AuthHelperMethods from "./AuthHelperMethods";

const Auth = new AuthHelperMethods();

// custom hook for performing GET request
const getData = async (url, action, params) => {
  //  const [data, setData] = useState([]);
  //  const [loading, setLoading] = useState(true);
  var response;
  //  const fetchData = async function() {
  try {
    //  setLoading(true);

    const response = await Auth.api(url, action, params);
    /*  if (response.status === 200) {
        setData(response.data);
      }*/
    return response.data;
  } catch (error) {
    alert(error);
    return error;
  } finally {
    //  setLoading(false);
  }
  //  };
};

export default getData;
