import React, { useContext, useState, useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import TimeAndDate from "./timeanddate";
import DateOnly from "./dateonly";
import moment from "moment";
import { dateISOtoString, timeISOtoString, dateObjToDBDate } from "./momentUtils";
import { DISPLAY_DATE } from "./UserSettings";
import COLORS, { noteOpenStyle, noteClosedStyle } from "./styleConstants";
import { MyContext, ListContext } from "./App";

import AuthHelperMethods from "./AuthHelperMethods";

import { cancel_by_barn_options, lesson_cancel_resason_options } from "./Constants2";
import Select from "react-select";
import { HelpText } from "./styleCustom";
import messageMap from "./messageMap";
import DatePicker from "react-datepicker";

import { Circle, CircleSolid, EditIconPlain } from "./icons";
import { DATEPICKER_DATE_DISPLAY } from "./UserSettings";

const Auth = new AuthHelperMethods();

const SelectCancelMakeupAction = ({ setCancelReasonX = () => {}, setCancelActionX = () => {} }) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);

  const [cancelReason, setCancelReason] = useState("Lesson Cancelled");
  const [cancelCode, setCancelCode] = useState("OTH");
  const [cancelOption, setCancelOption] = useState(mystate.farmDefaults.lesson_cancel_default_action || "BCNA");
  const lessonInfoMessage = `${mystate.currentLesson.title || "lesson"} on ${dateISOtoString(
    mystate.currentLesson.scheduled_date
  )} at ${timeISOtoString(mystate.currentLesson.scheduled_starttime)}`;
  const bulkLessonInfoMessage = `You are cancelling lessons for ${moment(listState.filter.dbday).format(DISPLAY_DATE)}`;
  const [localExpiry, setLocalExpiry] = useState();
  useEffect(
    () => {
      dispatch({
        type: "SET_CANCEL_MAKEUP_ACTION",
        makeup_action: mystate.farmDefaults.lesson_cancel_default_action || "BCNA",
      });
      dispatch({ type: "SET_CANCEL_REASON", cancel_reason: "OTH" });
      dispatch({ type: "UPDATE_NOTIFICATION_MESSAGE", message: `${lessonInfoMessage} has been cancelled` });
    },
    //eslint-disable-next-line
    []
  );

  const handleClick = (code) => {
    setCancelOption(code);
    setCancelActionX(code);
    dispatch({
      type: "SET_CANCEL_MAKEUP_ACTION",
      makeup_action: code,
    });

    dispatch({
      type: "SET_BULK_CANCEL_ACTION",
      bulk_cancel_action: code,
    });
  };

  const handleClickReason = (code, reason) => {
    setCancelCode(code);

    dispatch({ type: "SET_SHOW_NOTIFY", showNotify: true });

    setCancelReason(`Lesson has been cancelled due to ${reason}.`);
    dispatch({ type: "SET_CANCEL_REASON", cancel_reason: code });
    dispatch({ type: "SET_BULK_CANCEL_REASON", bulk_cancel_reason: code });

    let notifymsg = mystate.isBulk
      ? `Lessons for ${dateISOtoString(listState.filter.dbday)} have been cancelled due to ${reason}.`
      : `${lessonInfoMessage} has been cancelled due to ${reason}.`;
    //{mystate.isBulk ? bulkLessonInfoMessage : `You are cancelling ${lessonInfoMessage}`}

    dispatch({ type: "UPDATE_NOTIFICATION_MESSAGE", message: notifymsg });
    dispatch({
      type: "UPDATE_LESSON_MESSAGE",
      message: `Lesson has been cancelled due to ${reason}`,
    });
    dispatch({
      type: "SET_BULK_NOTIFICATION_MESSAGE",
      message: `Lessons have been cancelled due to ${reason}`,
    });
    setCancelReasonX({ cancel_code: code, notification_message: notifymsg, cancel_text: reason });
  };

  const changeExpiryDate = (date) => {
    setLocalExpiry(date);
    dispatch({ type: "SET_BULK_CANCEL_EXPIRY", bulk_cancel_expiry: dateObjToDBDate(date) });
  };

  const CancelRow = (props) => {
    const { mystate, dispatch } = useContext(MyContext);

    return (
      <div
        className="rowCancel"
        style={{
          minHeight: 10,
          minWidth: 100,

          display: "flex",
          marginBottom: 5,
        }}
        onClick={() => props.handleClick(props.id, props.title)}
      >
        <div
          className="circle"
          style={{
            display: "flex",
            alignItems: "flex-start",
            paddingRight: 20,
            paddingTop: 4,
          }}
        >
          {props.selected == props.id ? <CircleSolid color={COLORS.orange} /> : <Circle />}
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
          <div
            style={{
              fontSize: 16,
              color: props.selected == props.id ? COLORS.orange : COLORS.textDark,
              display: "flex",
            }}
          >
            {props.title}
          </div>
          {props.showHelpText && <div style={{ fontSize: 10, color: COLORS.textMuted }}>{props.help || ""}</div>}
        </div>
      </div>
    );
  };
  const radioTitle = { fontSize: 20, marginTop: 20, marginBottom: 10 };
  return (
    <>
      <div style={{ marginBottom: 10, fontStyle: "italic", color: COLORS.orange }}>
        {mystate.isBulk ? bulkLessonInfoMessage : `You are cancelling ${lessonInfoMessage}`}
      </div>
      {mystate.isBulk && <div>{"Showing only non cancelled lessons"}</div>}
      <div>
        <div style={radioTitle}>Choose Cancel Reason:</div>

        {lesson_cancel_resason_options.map((option) => (
          <CancelRow
            key={option.value}
            id={option.value}
            title={option.label}
            handleClick={handleClickReason}
            selected={cancelCode}
          />
        ))}

        {1 == 2 && (
          <div style={{ color: COLORS.blue, margin: 7 }}>
            <span>{cancelReason}</span>
          </div>
        )}
      </div>

      <div className="part2" style={{ marginTop: 20 }}>
        <div style={radioTitle}>Choose Cancel Action For Prepaid Students:</div>

        {cancel_by_barn_options.map((option) => (
          <CancelRow
            key={option.value}
            id={option.value}
            title={option.label}
            help={option.help}
            handleClick={handleClick}
            selected={cancelOption}
          />
        ))}
        {1 == 2 && cancelOption == "BCWA" && (
          <div style={{ width: 200 }}>
            <DatePicker
              placeholderText={"Choose Expiration Date for Makeup/Complimentary Lesson"}
              onChange={changeExpiryDate}
              selected={localExpiry}
              dateFormat={DATEPICKER_DATE_DISPLAY}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SelectCancelMakeupAction;
