import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter, Route, Switch, Link, NavLink, useHistory } from "react-router-dom";
import ReactDOM from "react-dom";
import { Formik, Form, useField, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col, Modal } from "react-bootstrap";
import {
  PageTitle,
  Td,
  Th,
  HelpText,
  PrimaryTextList,
  Container,
  PrimaryTextBlue,
  StyledErrorMessage,
} from "./styleCustom";
import COLORS from "./styleConstants";
import {
  TableHeader,
  AddEditForm,
  TableHeaderNoAdd,
  OrangeButton,
  OrangeLinkButton,
  ModalHeaderBlue,
  ActiveCheck,
  TextInput,
} from "./formComponents";
import SearchFilter, { ModalFilter } from "./searchFilter";
import SearchFilterDate from "./searchFilterDates";
import { ListContext, MyContext } from "./App";
import { RemoveIcon, EditIconPlain } from "./icons";
import HorseForm from "./horseForm";
import Url from "./url";
import HorseHomePage from "./horseHomePage";
import { errormsg } from "./Constants2";
import { active_options } from "./Constants2";
import { Active } from "./searchFilterReports";

const Auth = new AuthHelperMethods();

const Horses = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [params, setParams] = useState({});
  const [active, setActive] = useState(true);
  const history = useHistory();
  const [initComplete, setInitComplete] = useState(false);

  let formInitialValues = {};
  useEffect(
    () => {
      console.log("Clear ClientHome");

      return () => {
        dispatch2(["HORSE_GET", {}]);
      };
    },
    //eslint-disable-next-line
    []
  );
  const handleSearchChange = (e) => {
    e.preventDefault();

    setSearch(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      let params = { is_active: 1 };
      const result = await Auth.api("horses/getHorsesAll", "get", params);
      dispatch2(["HORSES_GET", result.data]);
      setInitComplete(true);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let params = { ...listState.filter };
      const result = await Auth.api("horses/getHorsesAll", "get", params);
      dispatch2(["HORSES_GET", result.data]);
    };
    if (initComplete) fetchData();
  }, [listState.filter]);

  const updateIsActive = async (id) => {
    let params = { ...listState.filter };
    params.id = id;
    try {
      dispatch({ type: "FETCH_INIT" });

      const result = await Auth.api("horses/updateActivationStatus", "post", params);
      dispatch2(["HORSES_GET", result.data]);
      dispatch({ type: "FETCH_SUCCESS" });

      console.log(result.data);
    } catch (e) {
      dispatch({ type: "FETCH_ERROR" });
    }
  };

  useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        const result = await Auth.api("options/index", "get", params);
        dispatch({
          type: "UPDATE_OPTIONS",
          options: result.data,
        });
      };
      if (initComplete) fetchData();
    },
    //eslint-disable-next-line
    [listState.horses]
  );

  return (
    <>
      <Modal show={showModal} size="md" onHide={() => setShowModal(false)}>
        <ModalHeaderBlue closeButton>
          <Modal.Title>{"Create a New Horse"}</Modal.Title>
        </ModalHeaderBlue>
        <Modal.Body>
          <Formik
            initialValues={{ name: "" }}
            validationSchema={Yup.object({
              name: Yup.string().required("horse must have a name"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                dispatch({ type: "FETCH_INIT" });
                values.name = values.name.trim();

                const result = await Auth.api("horses/create", "post", values);

                dispatch2(["HORSE_CREATE", result.data]);
                // dispatch2(["HORSE_GET", result.data.horse]);

                dispatch({ type: "FETCH_SUCCESS" });
                setShowModal(false);
              } catch (error) {
                console.log(error);
                dispatch({ type: "FETCH_ERROR" });
                alert.show(errormsg, { timeout: 5000, type: "error" });
              }
            }}
          >
            <Form id="horseForm">
              <TextInput name="name" label="Horse Name" />
              <HelpText>{"required"}</HelpText>
              <ErrorMessage name="name" component={StyledErrorMessage} />
              <div style={{ marginTop: 100 }}>
                <OrangeButton type="submit">{"Save"}</OrangeButton>
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
      <Container style={{ flexWrap: "wrap", flex: "1 1 100%" }}>
        <input
          style={{ marginTop: 10 }}
          autoFocus
          type="text"
          value={search}
          onChange={(e) => handleSearchChange(e)}
          placeholder="start typing to search for a horse"
        />

        <TableHeaderNoAdd title={"Horses"}>
          <OrangeButton onClick={() => setShowModal(true)}>{"Add New Horse"}</OrangeButton>
        </TableHeaderNoAdd>
        <Active field={"is_active"} default={"1"} options={active_options} />

        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <Th>{"name"}</Th>
              <Th>{"height"}</Th>
              <Th>{"color"}</Th>
              <Th>{"year"}</Th>
              <Th>{"gender"}</Th>
              <Th>{"markings"}</Th>

              <Th>{"tack"}</Th>
              <Th />
            </tr>
          </thead>

          <tbody style={{ marginTop: 10 }}>
            {listState.horses &&
              listState.horses.map((horse) => (
                <tr key={horse.id}>
                  <Td>
                    {" "}
                    <Link to={`/admin/horsehome/${horse.id}/details`}>
                      {" "}
                      <PrimaryTextBlue>{horse.horse_name} </PrimaryTextBlue>
                    </Link>
                  </Td>
                  <Td>{horse.height || ""}</Td>
                  <Td>{horse.color || ""}</Td>
                  <Td>{horse.age || ""}</Td>
                  <Td>{horse.gender || ""}</Td>
                  <Td>{horse.desc || ""}</Td>

                  <Td>{horse.tack || ""}</Td>
                  <Td onClick={() => updateIsActive(horse.id)} style={{ color: COLORS.orange, cursor: "pointer" }}>{`${
                    horse.is_active ? "inactivate" : "activate"
                  }`}</Td>
                </tr>
              ))}
          </tbody>
        </table>
      </Container>
    </>
  );
};

export default Horses;
