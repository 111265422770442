import UserSettings from "./UserSettings";
import moment from "moment";
import { dateObjToDBDate } from "./momentUtils";
import AuthHelperMethods from "./AuthHelperMethods";
const Auth = new AuthHelperMethods();
const width = window.innerWidth;
const breakpoint = 620;

const initialState = {
  showCalendar: true,
  lessons: [],
  lessonStudents: [],
  lessonHorses: [],
  sections: [],
  instructors: [],
  addevent: {},

  calendarDate: moment().toDate(),
  showMainLinks: true,
  resource_map: [],
  loggedIn: Auth.loggedIn(),
  showAddModal: false,
  currentLesson: { lessonStudents: [] },
  currentLessonOld: {},
  currentEdit: {},
  singleEventEdit: true,
  filter: { dbday: dateObjToDBDate(moment().toDate()), changeFilter: false },
  whiteboardCurrentLesson: 0,
  lessonsSH: [],
  showDefaultModal: false,
  dateTimeComponent: { start: "", end: "", is_allday: false },
  isFetching: false,
  editMessage: "",
  notifyMessage: "",
  refresh: false,
  repeat: {},
  resources: {},
  filteredBySearchtermStudents: {},
  resourceAccessor: "",
  currentEditIsStudentNotHorse: false,
  showInfoModal2: false,
  sectionDefaults: {},
  showListPage: true,
  showCalendarPage: true,
  daysheet: [],
  isError: false,
  mounted: false,
  calendarDateRangeStart: dateObjToDBDate(moment().startOf("month").add(-2, "week").toDate()),

  //calendarDateRangeStart: dateObjToDBDate(moment().toDate()),
  calendarDateRangeEnd: dateObjToDBDate(moment().endOf("month").add(2, "week").toDate()),
  //calendarDateRangeEnd: dateObjToDBDate(moment().toDate()),
  whiteboard_section_horses_array: [],
  whiteboard_horsecount: [],
  whiteboard_horses_filtered: [],
  section_horses: [],
  showByStudentName: false,
  farmDefaults: {},
  defaultsLoaded: false,
  calendarRefresh: false,
  currentStudent: {},
  options_instructor: [],
  filteredEvents: [],
  options_section: [],
  options_student: [],
  versionUpdate: true,
  bulkarray: [],
  isBulk: false,
  currentStaff: {},
  event: {},
  options_tack: [],
};

export default initialState;
