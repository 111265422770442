import React, { useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { BlockLabel, StyledList, StyledListGroup, BlueButton } from "./styleCustom";
import { FormRightHeader, FormRight } from "./formComponents";
import { TimesIconBlue } from "./icons";
import { Row, Col } from "react-bootstrap";
import SelectList from "./selectList";
import { ListContext } from "./App";
import { RemoveIcon } from "./icons";
import AuthHelperMethods from "./AuthHelperMethods";
const Auth = new AuthHelperMethods();

const ProductAssignProgram = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);

  useEffect(
    () => {
      console.log("useeffet");
      const fetchData = async () => {
        let params = {};
        params.is_active = 1;
        const result = await Auth.api("programs/programList", "get", params);
        dispatch2(["GET_PROGRAM_LIST", result.data]);
      };
      fetchData();

      //  }
    },
    //eslint-disable-next-line
    []
  );
  // useFetch("programs/index", "GET_PROGRAM_LIST", "dispatchlist", params);
  let history = useHistory();
  function goBackToList() {
    history.goBack();
  }
  let product_id = useParams().id;
  var product_name = "product";
  if (listState.products && listState.products.length > 0) {
    product_name = listState.products.find((product) => product.id == product_id).product_name || "product";
  }

  let assignedList = [];

  if (listState.productPrograms) {
    assignedList = listState.productPrograms
      .filter((item) => item.product_id == product_id)
      .map((item2) => ({
        id: item2.program_id,
        name: item2.name,
        pk: item2.id,
      }));
  }

  const unassign = async (objectIn) => {
    console.log(objectIn);
    dispatch2(["PRODUCT_PROGRAM_DELETE", objectIn.pk]);
    let params = { id: objectIn.pk };
    let result = await Auth.api("product_programs/delete", "post", params);
  };

  const addProgramToProduct = async (programobj) => {
    let params = {};
    params.product_id = product_id;
    params.program_id = programobj.id;
    let result = await Auth.api("product_programs/create", "post", params);
    dispatch2(["PRODUCT_PROGRAM_CREATE", result.data]);
  };
  const handleClose = () => {
    goBackToList();
  };

  return (
    <FormRight style={{ width: 600 }}>
      <Row noGutters>
        <Col xs={10} style={{ display: "flex", justifyContent: "center" }}>
          <FormRightHeader>{`Assign Programs to ${product_name} `}</FormRightHeader>
        </Col>
        <Col
          xs={2}
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            zIndex: 9000,
          }}
          onClick={() => handleClose()}
        >
          <TimesIconBlue />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          {" "}
          <BlockLabel> assigned programs </BlockLabel>
          {assignedList.length < 1 && <p>No Programs Assigned</p>}
          {assignedList.length > 0 && (
            <StyledListGroup>
              {assignedList.map((item) => (
                <StyledList key={item.pk} onClick={() => unassign(item)}>
                  {item.name} <RemoveIcon />
                </StyledList>
              ))}
            </StyledListGroup>
          )}
        </Col>
        <Col xs={12} md={6}>
          {listState.programList && listState.programList.length > 0 ? (
            <SelectList
              fullDataList={listState.programList}
              subDataList={assignedList}
              listClickFunction={addProgramToProduct}
            />
          ) : (
            <div>no records to display</div>
          )}
        </Col>
      </Row>
      <Row style={{ justifyContent: "center", display: "flex" }}>
        <BlueButton onClick={() => goBackToList()}>{"Close"} </BlueButton>
      </Row>
    </FormRight>
  );
};

export default ProductAssignProgram;
