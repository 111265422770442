import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter, Route, Switch, Link, NavLink } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AuthHelperMethods from "./AuthHelperMethods";
import Url from "./url";
import AddPaymentMethod from "./addPaymentMethod";
import { ListContext } from "./App";
const Auth = new AuthHelperMethods();

//import api from "./api";

const PaymentMethodElements = (props) => {
  const getPublicStripeKey = async () => {
    const result = await Auth.api("payments/getPublicStripeKey", "get");
    return result.data.publicKey;
  };

  const stripePromise = getPublicStripeKey().then((key) => loadStripe(key));
  console.log(getPublicStripeKey());

  return (
    <>
      <Elements stripe={stripePromise}>
        <AddPaymentMethod {...props} />
      </Elements>
    </>
  );
};

export default PaymentMethodElements;
