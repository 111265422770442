import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, NavLink, useParams } from "react-router-dom";
import { TableHeaderNoAdd, OrangeLinkButton } from "./formComponents";
import { Row, Col } from "react-bootstrap";
import ProductForm from "./productForm";
import ProductTable from "./productTable";
import ProductAssignProgram from "./productAssignProgram";
import SectionForm from "./sectionForm";
import { ListContext } from "./App";
import {
  PrimaryText,
  Td,
  StyledTr,
  TableText1,
  TableText2,
  Th,
  HelpText,
  StyledLink,
  Container,
  Row1,
  Row2,
  ContainerH,
  ContainerV,
} from "./styleCustom";
import COLORS from "./styleConstants";
import AuthHelperMethods from "./AuthHelperMethods";
import { MyContext } from "./App";
import { AddIconNP } from "./icons";
import SectionAssignHorse from "./sectionAssignHorse";
import SectionAssignStudent from "./sectionAssignStudent";

import FilterForm from "./filterForm";
import PrivateRoute from "./privateRoute";
import SectionTable from "./sectionTable.js";
const Auth = new AuthHelperMethods();

const Sections = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  return (
    <>
      <Container style={{ flexWrap: "wrap", flex: "0 0 100%" }}>
        <div
          style={{
            display: "flex",
            flex: "1 1 100%",
            justifyContent: "center",
            width: 400,
          }}
        >
          <Switch>
            {" "}
            <PrivateRoute path={`${props.match.url}/sectionform/:id`} component={SectionForm} />
            <PrivateRoute path={`${props.match.url}/sectionform/:id`} component={SectionForm} />
          </Switch>
        </div>

        <div style={{ flex: "1 1 100%" }}>
          <TableHeaderNoAdd title="Sections">
            <Link to={`${props.match.url}/sectionform/add`}>
              <OrangeLinkButton>{"Add New Section"} </OrangeLinkButton>{" "}
            </Link>
          </TableHeaderNoAdd>

          <PrivateRoute path={`/admin/setup/sections/`} component={SectionTable} />
        </div>
      </Container>
    </>
  );
};

export default Sections;
