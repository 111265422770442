import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, Link, useParams, useLocation } from "react-router-dom";
import AuthHelperMethods from "./AuthHelperMethods";
import { TableHeader2, ModalWarning, ButtonOrange } from "./formComponents";
import SearchFilterDates from "./searchFilterDates";
import PrivateRoute from "./privateRoute";
import moment from "moment";
import { dateISOtoStringShort } from "./momentUtils";
import { ListContext, MyContext } from "./App";
import { Expand } from "./icons";
import COLORS from "./styleConstants";
import { SelectTemplateFilter, SelectNoLabel, CancelRow, Active } from "./searchFilterReports";
import Select from "react-select";
const Auth = new AuthHelperMethods();
import { CSVLink, CSVDownload } from "react-csv";
import { dateObjToDisplayDate } from "./momentUtils";
import SelectAccountHolder from "./selectAccountHolder";
import { HelpText } from "./styleCustom";

//****************************************************///
export const AdminPaymentReport = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [sortBy, setSortBy] = useState();
  const [direction, setDirection] = useState(true);
  const [purchaseList, setPurchaseList] = useState([]);
  const [packageDetails, setPackageDetails] = useState([]);
  const [currentPackage, setCurrentPackage] = useState();
  const [showWarning, setShowWarning] = useState(false);
  const [paymentToDelete, setPaymentToDelete] = useState();
  const [allPackageDetails, setAllPackageDetails] = useState([]);
  const [showAllDetails, setShowAllDetails] = useState(false);
  const [totals, setTotals] = useState(undefined);
  const [outstanding, setOutstanding] = useState(undefined);
  const location = useLocation();
  const [refreshPage, setRefreshPage] = useState(false);

  const onInvoicePage = location.pathname?.endsWith("invoices");
  const onPaymentsPage = location?.pathname?.endsWith("paymentReport");
  console.log(location.pathname);
  console.log(onInvoicePage);

  useEffect(
    () => {
      //  return () => {
      console.log("when is this running?");
      console.log("location change");

      console.log(location);
      dispatch2(["RESET_SEARCH_FILTER_EXCEPT_DATES"]);
      //  };
    },
    //eslint-disable-next-line
    [location]
  );

  useEffect(() => {
    if (listState.currentClient?.billing_person_id)
      dispatch2(["SET_SEARCH_FILTER", [listState.currentClient?.billing_person_id], "billing_person_id"]);
  }, [listState.currentClient?.billing_person_id]);

  const ORANGEBUTTON = {
    backgroundColor: COLORS.orange,
    padding: 8,
    color: "white",
    fontSize: 12,
    minWidth: 100,
    margin: 5,
    borderRadius: 5,
    border: "medium none,",
    borderColor: COLORS.orange,
  };
  const dateText = `${listState.filter.date1 && dateObjToDisplayDate(listState.filter.date1)} to ${
    listState.filter.date2 && dateObjToDisplayDate(listState.filter.date2)
  }
`;
  const Csv = ({ data, filename }) => {
    return (
      <button style={ORANGEBUTTON}>
        <CSVLink data={purchaseList} filename={`${filename}.csv`} style={{ color: "white" }}>
          Download CSV
        </CSVLink>
      </button>
    );
  };

  useEffect(
    () => {
      return () => {
        dispatch2(["RESET_SEARCH_FILTER_EXCEPT_DATES"]);
      };
    },
    //eslint-disable-next-line
    []
  );

  /* useEffect(
    () => {
      // console.log("USEEFFECT API");
      const fetchData = async () => {
        dispatch({ type: "FETCH_INIT" });

        let params = { is_active: 1 };

        try {
          const result = await Auth.api("people/index", "get", params);
          dispatch({
            type: "FETCH_SUCCESS",
          });

          dispatch2(["ACCOUNT_CLIENTS_GET", result.data]);
          //  setInitComplete(true);
        } catch (error) {
          dispatch({ type: "FETCH_ERROR" });
          //  alert.show(error.message, { timeout: 3000, type: "error" });
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
    //[active, refresh, filter]
  );

  useEffect(
    () => {
      // console.log("USEEFFECT API");
      const fetchData = async () => {
        dispatch({ type: "FETCH_INIT" });

        let params = { is_active: 1 };

        try {
          const result = await Auth.api("people/studentClient", "get", params);
          dispatch({
            type: "FETCH_SUCCESS",
          });

          dispatch2(["ACCOUNT_STUDENTS_GET", result.data]);
          // setInitComplete(true);
        } catch (error) {
          dispatch({ type: "FETCH_ERROR" });
          //  alert.show(error.message, { timeout: 3000, type: "error" });
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
    //[active, refresh, filter]
  );
*/
  useEffect(
    () => {
      let params = {
        ...listState.filter,
        report: "payment",
      };
      console.log(params);

      params.sortBy = sortBy;
      params.direction = direction ? "desc" : "asc";
      if (onInvoicePage) {
        params.include_invoices = true;
        params.sortBy = sortBy || "payments.paid";
        params.direction = direction ? "desc" : "asc";
        console.log(params);
      }
      if (onInvoicePage) console.log(onInvoicePage);

      if (onPaymentsPage) params.payments_only = true;
      if (onPaymentsPage) console.log(onPaymentsPage);

      console.log(params);

      const fetchData = async () => {
        console.log("reports/financial");
        dispatch({ type: "FETCH_INIT" });

        try {
          const result = await Auth.api("reports/financial", "get", params);

          result && setPurchaseList(result.data.reports);
          result && setTotals(result.data.totals.total);
          result && result.data.outstandingTotals && setOutstanding(result.data.outstandingTotals.outstanding);

          dispatch({ type: "FETCH_SUCCESS" });
        } catch {
          dispatch({ type: "FETCH_ERROR" });
        }
      };
      //    fetchData();
      if (listState.filter.date1 || listState.filter.date2) fetchData();
    },
    //eslint-disable-next-line
    [listState.filter, sortBy, direction, location, refreshPage]
  );

  useEffect(
    () => {
      const fetchData = async () => {
        console.log("items/getAllItemsForPayment");

        dispatch({ type: "FETCH_INIT" });
        let params = { ...listState.filter };
        try {
          const result = await Auth.api("items/getAllItemsForPayment", "get", params);
          result && setAllPackageDetails(result.data.items);

          dispatch({ type: "FETCH_SUCCESS" });
        } catch {
          dispatch({ type: "FETCH_ERROR" });
        }
      };
      if (showAllDetails) fetchData();
    },
    //eslint-disable-next-line
    [showAllDetails, refreshPage]
  );

  const handleGetDetail = async (paymentid) => {
    console.log(paymentid);
    setCurrentPackage(paymentid);
    let params = { payment_id: paymentid };
    const result = await Auth.api("items/getItemsForPayment", "get", params);
    setPackageDetails(result.data.items);
    console.log(result.data.items);
  };

  const prepareDeletePayment = (itemId) => {
    setShowWarning(true);
    setPaymentToDelete(itemId);
  };
  const deletePayment = async () => {
    let params = { payment_id: paymentToDelete };
    console.log(paymentToDelete);
    try {
      const result = await Auth.api("payments/deleteManualPayment", "post", params);
      setPurchaseList(purchaseList.filter((purchase) => purchase.id !== paymentToDelete));
      setShowWarning(false);
      setPaymentToDelete();
      setRefreshPage(!refreshPage);
    } catch {}
  };

  const changeSort = (sortIn) => {
    console.log(sortIn);
    setSortBy(sortIn);
    setDirection(!direction);
  };

  function filterInvoices() {
    setPurchaseList(purchaseList.filter((purchase) => purchase.is_invoice === true));
  }
  return (
    <>
      {showWarning && (
        <ModalWarning
          title={"Whoa there!"}
          proceedlabel={`Yes I want to delete this payment and its packages`}
          cancellabel={`No, I do not wish to delete this payment`}
          warningMessage={`Warning!  Any packages covered by this payment will also be deleted.`}
          warning2={"Package counts will be automatically recalculated when deleted."}
          cancel={() => setShowWarning(false)}
          proceed={deletePayment}
        ></ModalWarning>
      )}
      <SelectAccountHolder />

      <div className={"dteea"} style={{ display: "flex", flex: "1 1 100%", flexDirection: "column" }}>
        <SearchFilterDates />
      </div>

      {1 == 2 && <ButtonOrange label={"Invoice Payments Only"} onClick={() => filterInvoices()} />}
      <ButtonOrange
        label={showAllDetails ? "hide details" : "show detail"}
        onClick={() => setShowAllDetails(!showAllDetails)}
      />
      <Csv data={purchaseList} filename={`payments ${dateText}`} />
      <div
        className={"dteea"}
        style={{ display: "flex", flex: "1 1 100%", flexDirection: "column", color: COLORS.orange, fontSize: 14 }}
      >
        <div>
          {" "}
          Payments and Invoices are grouped by account as items for mulitiple students on one account may occur as one
          transaction.
        </div>
        <div> You can search for the account by the account holder name or by the student name.</div>
      </div>
      <TableHeader2 title={`${onInvoicePage ? "Invoices" : "Payments"}`} />
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
          className={"headerrow"}
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            color: COLORS.blue,
            fontWeight: "bold",
            padding: 7,
            textAlign: "left",
          }}
        >
          <div style={{ width: "10%" }} onClick={() => changeSort("amount")}>
            amount
          </div>
          <div style={{ width: "10%" }} onClick={() => changeSort("payments.created_at")}>
            date
          </div>
          <div style={{ width: "25%" }} onClick={() => changeSort("person_name")}>
            account
          </div>

          <div style={{ width: "10%" }}>method</div>

          <div style={{ width: "25%" }}>reference</div>
        </div>
        <div className="purchaseListBody">
          {purchaseList &&
            purchaseList.map((item) => (
              <>
                <div
                  key={item.id}
                  className={"rowMain"}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    padding: 7,
                    fontSize: 14,
                    borderBottom: "solid 1pt lightgrey",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: "1 1 100%",
                      flexWrap: "wrap",
                      color: item.paid ? "grey" : "red",
                    }}
                  >
                    <div style={{ width: "10%" }}>{item.amount}</div>
                    <div style={{ width: "10%" }}>{dateISOtoStringShort(item.received)}</div>
                    <div style={{ width: "25%" }}>{item.account}</div>
                    <div style={{ width: "10%" }}>{item.bank}</div>
                    <div style={{ width: "25%" }}>{item.reference}</div>
                    {onInvoicePage && <div style={{ width: "10%" }}>{item.paid ? "paid" : "not paid"}</div>}

                    <div
                      className="deletePayment"
                      onClick={() => prepareDeletePayment(item.id)}
                      style={{ color: COLORS.orange, cursor: "pointer", width: "5%" }}
                      style={{ color: COLORS.orange, cursor: "pointer", justifyContent: "end" }}
                    >
                      {"delete"}
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 1 100%", flexWrap: "wrap" }}>
                    {item.notes && (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          flex: "1 1 100%",
                          fontStyle: "italic",
                          color: "#737373",
                          paddingTop: 5,
                        }}
                      >
                        {item.notes}
                      </div>
                    )}
                  </div>
                </div>

                {showAllDetails &&
                  allPackageDetails &&
                  allPackageDetails.filter((piece) => piece.payment_id == item.id).length > 0 && (
                    <div
                      className="packageDiv"
                      style={{
                        margin: 5,
                        border: `solid 2pt ${COLORS.lightgrey}`,
                        padding: 5,
                        display: "flex",
                        flex: "1 1 100%",
                        flexWrap: "wrap",
                        marginLeft: 100,
                        marginBottom: 30,
                        background: `${COLORS.lightgrey}`,
                      }}
                    >
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "row",
                            flex: "1 1 100%",
                            color: COLORS.blue,
                            fontWeight: "bold",
                          }}
                        >
                          <div style={{ width: 200 }}>{"student"}</div>
                          <div style={{ width: 200 }}>{"package"}</div>
                          <div style={{ width: 100 }}>{"amount"}</div>
                          <div style={{ width: 100 }}>{"starts"}</div>
                          <div style={{ width: 100 }}>{"expires"}</div>
                        </div>
                        {allPackageDetails
                          .filter((piece) => piece.payment_id == item.id)
                          .map((row) => (
                            <div
                              className="packageRow"
                              style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", flex: "1 1 100%" }}
                            >
                              <div style={{ width: 200 }}>{row.person_name}</div>
                              <div style={{ width: 200 }}>{row.product_name}</div>
                              <div style={{ width: 100 }}>{Number(row.price).toFixed(2)}</div>
                              <div style={{ width: 100 }}>{dateISOtoStringShort(row.package_start_date)}</div>
                              <div style={{ width: 100 }}>{dateISOtoStringShort(row.package_expiry_date)}</div>
                            </div>
                          ))}
                      </>
                    </div>
                  )}
              </>
            ))}
        </div>
        {totals && (
          <div className="purchaseListTotals">
            <span>TOTAL:</span>
            <span style={{ marginLeft: 10 }}>{totals}</span>
          </div>
        )}
        {outstanding && (
          <div className="purchaseListOutstanding" style={{ color: "red" }}>
            <span>OUTSTANDING:</span>
            <span style={{ marginLeft: 10 }}>{outstanding}</span>
          </div>
        )}
      </div>
    </>
  );
};
