import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, NavLink } from "react-router-dom";
import { TableHeaderNoAdd, OrangeLinkButton } from "./formComponents";
import { Row, Col } from "react-bootstrap";
import ProductForm from "./productForm";
import ProductTable from "./productTable";
import ProductAssignProgram from "./productAssignProgram";
import { Container, Row1, Row2 } from "./styleCustom";
import PrivateRoute from "./privateRoute";

const Products = (props) => {
  return (
    <Container style={{ flexWrap: "wrap", flex: "1 1 100%" }}>
      <Route path={`${props.match.url}/list`} component={ProductTable} />
    </Container>
  );
};

export default Products;
