import React, { useReducer, Fragment, useState, useEffect, useContext } from "react";
import axios from "axios";
import Select from "react-select";
import { MyContext, ListContext } from "./App";
import { valueFromId } from "./momentUtils";
import AuthHelperMethods from "./AuthHelperMethods";

const Auth = new AuthHelperMethods();

const SelectColor = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  const [options_colors, set_options_colors] = useState();
  const [currentValue, setCurrentValue] = useState(props.value || null);
  const [colorId, setColorId] = useState(props.colorId);
  const customStyles = {
    option: (styles, { data }) => ({
      ...styles,
      background: `${data.color}`,
      fontWeight: "bold",
      minHeight: 20,
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      background: `${data.color}`,
      width: "100%",
      fontWeight: "bold",
      minHeight: 40,
      display: "flex",
      alignItems: "center",
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      background: `${data.color}`,
      width: "100%",
      fontWeight: "bold",
      minHeight: 40,
      display: "flex",
      alignItems: "center",
    }),
    valueContainer: (styles, { data }) => ({
      ...styles,
      padding: 0,
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      padding: 0,
      margin: 0,
    }),
  };
  const handleColorChange = (a) => {
    console.log(a);
    setColorId(a.value);
  };

  const updateSelection = (selected) => {
    console.log(selected);
    // setCurrentValue(selected.value);
    dispatch2(["SET_SELECTED_COLOR", selected]);
    // props.onupdate && props.onupdate(selected.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await Auth.api("colors/index", "get");
      console.log(result.data.colors);

      set_options_colors(
        result.data.colors.map((color) => ({
          label: color.color_name,
          value: color.id,
          other: color.id,
          color: color.color_code,
        }))
      );
    };
    fetchData();
  }, []);

  return (
    <div style={{ minWidth: 200 }}>
      <Select
        onChange={(a) => updateSelection(a)}
        className="form-control"
        options={options_colors}
        clearable={true}
        searchable={false}
        placeholder={"Select Color..."}
        styles={customStyles}
        value={listState.selectedColor}
      />
    </div>
  );
};

export default SelectColor;
