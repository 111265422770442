import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "./tooltips";
import COLORS from "./styleConstants";

import {
  faEdit,
  //  faCheckSquare,
  faLocationArrow,
  faChevronCircleRight,
  faPlus,
  faCaretSquareDown,
  faInfoCircle,
  faTimes,
  faSticky,
  faRedo,
  faClock,
  faCheck,
  faMapPin,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faBars,
  faCircle as fasCircle,
  faArrowLeft,
  faArrowDown,
  faLongArrowAltLeft,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import {
  faQuestionCircle,
  faStickyNote,
  faCheckSquare,
  faCheckCircle,
  faSquare,
  faEye,
  faEyeSlash,
  faDotCircle,
  faCircle,
} from "@fortawesome/free-regular-svg-icons";

export const DotCircle = (props) => {
  return (
    <FontAwesomeIcon
      icon={faDotCircle}
      style={{
        color: props.color || COLORS.orange,
        marginRight: 3,
        fontSize: 14,
      }}
    />
  );
};

export const Trash = (props) => {
  return (
    <FontAwesomeIcon
      icon={faTrashAlt}
      style={{
        color: props.color || COLORS.blue,
        marginRight: 3,
        fontSize: props.fontSize || 15,
      }}
    />
  );
};

export const BackPage = (props) => {
  return (
    <FontAwesomeIcon
      icon={faLongArrowAltLeft}
      style={{
        color: props.color || COLORS.blue,
        marginRight: 3,
        fontSize: 30,
      }}
    />
  );
};

export const ArrowLeft = (props) => {
  return (
    <FontAwesomeIcon
      icon={faArrowLeft}
      style={{
        color: props.color || COLORS.orange,
        marginRight: 3,
        fontSize: 14,
      }}
    />
  );
};

export const ArrowDown = (props) => {
  return (
    <FontAwesomeIcon
      icon={faArrowDown}
      style={{
        color: props.color || COLORS.orange,
        marginRight: 3,
        fontSize: 14,
      }}
    />
  );
};

export const Menu = (props) => {
  return (
    <FontAwesomeIcon
      size={"2x"}
      icon={faBars}
      style={{
        color: "white",
      }}
    />
  );
};

export const Circle = (props) => {
  return (
    <FontAwesomeIcon
      icon={faCircle}
      style={{
        color: props.color || "darkgrey",
        background: props.color,
        marginRight: 3,
        border: props.border,
        fontSize: 14,
      }}
    />
  );
};

export const CircleSolid = (props) => {
  return (
    <FontAwesomeIcon
      icon={fasCircle}
      style={{
        color: props.color || COLORS.orange,
        background: props.color,
        marginRight: 3,
        border: props.border,
        fontSize: 14,
      }}
    />
  );
};

export const MenuLeft = (props) => {
  return (
    <FontAwesomeIcon
      icon={faChevronLeft}
      size={"2x"}
      style={{
        color: props.color,
        marginRight: 3,
      }}
    />
  );
};
export const MenuRight = (props) => {
  return (
    <FontAwesomeIcon
      size={"2x"}
      icon={faChevronRight}
      style={{
        color: props.color || "white",
        marginLeft: 3,
      }}
    />
  );
};

export const Back = (props) => {
  return (
    <FontAwesomeIcon
      size={"1x"}
      icon={faChevronRight}
      style={{
        color: props.color || COLORS.blue,
      }}
    />
  );
};

export const Next = (props) => {
  return (
    <FontAwesomeIcon
      size={"1x"}
      icon={faChevronLeft}
      style={{
        color: props.color || COLORS.blue,
      }}
    />
  );
};

export const Asc = (props) => {
  return (
    <FontAwesomeIcon
      size={props.size || 12}
      icon={faChevronUp}
      style={{
        color: props.color || COLORS.blue,
      }}
    />
  );
};

export const Desc = (props) => {
  return (
    <FontAwesomeIcon
      fontSize={props.size || 12}
      icon={faChevronDown}
      style={{
        color: props.color || COLORS.blue,
      }}
    />
  );
};

export const Expand = (props) => {
  return (
    <FontAwesomeIcon
      size={"1x"}
      icon={faChevronDown}
      style={{
        color: props.color || COLORS.blue,
      }}
    />
  );
};

export const Private = (props) => {
  return (
    <FontAwesomeIcon
      icon={faEyeSlash}
      style={{
        fontSize: props.fontSize,
        color: props.color || COLORS.medgrey,
      }}
    />
  );
};

export const Public = (props) => {
  return (
    <FontAwesomeIcon
      icon={faEye}
      style={{
        fontSize: props.fontSize,
        color: props.color || COLORS.medgrey,
      }}
    />
  );
};

export const PinIcon = (props) => {
  return <FontAwesomeIcon icon={faMapPin} style={{ fontSize: props.fontSize, color: props.color || COLORS.orange }} />;
};

export const RemoveIcon = (props) => {
  return <FontAwesomeIcon icon={faTimes} style={{ marginRight: 10, position: "absolute", right: 15 }} />;
};

export const RemoveIconNoPosition = (props) => {
  return <FontAwesomeIcon icon={faTimes} size={props.size} />;
};
export const NoteIcon = (props) => {
  return <FontAwesomeIcon icon={faStickyNote} style={{ marginRight: 10 }} />;
};
export const LocationIcon = (props) => {
  return <FontAwesomeIcon icon={faLocationArrow} style={{ marginRight: 10 }} />;
};
export const RepeatIcon = (props) => {
  return <FontAwesomeIcon icon={faRedo} style={{ marginRight: 10 }} />;
};
export const ClockIcon = (props) => {
  return <FontAwesomeIcon icon={faClock} style={{ marginRight: 10 }} />;
};
export const TitleIcon = (props) => {
  return <FontAwesomeIcon icon={faEdit} style={{ marginRight: 10 }} />;
};
export const InfoIconCirc = (props) => {
  return <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 10, color: COLORS.orange, fontSize: 18 }} />;
};
export const SuccessIcon = (props) => {
  return (
    <FontAwesomeIcon
      icon={faCheckSquare}
      style={{
        marginRight: 10,
        color: COLORS.success,
        backgroundColor: "white",
      }}
    />
  );
};
export const WarningIcon = (props) => {
  return <FontAwesomeIcon icon={faCheckSquare} style={{ marginRight: 10 }} />;
};

export const TimesIcon = (props) => {
  return (
    <FontAwesomeIcon
      icon={faTimes}
      style={{
        color: props.color,
        fontSize: props.fontsize || 28,
      }}
    />
  );
};

export const TimesIconBlue = (props) => {
  return (
    <FontAwesomeIcon
      icon={faTimes}
      style={{
        color: COLORS.blue,
        fontSize: props.fontsize || 28,
      }}
    />
  );
};

export const TimesIconAb = (props) => {
  return (
    <FontAwesomeIcon
      icon={faTimes}
      style={{
        color: "#ff7900",
        position: "absolute",
        right: props.right || "5%",
        top: props.top || "40%",
        fontSize: 28,
      }}
      onClick={props.action}
    />
  );
};

export const EditIcon = (props) => {
  return (
    <FontAwesomeIcon
      icon={faEdit}
      style={{
        color: "#ff7900",
        position: "absolute",
        right: props.right || "5%",
        bottom: props.bottom || "5%",
      }}
      size={props.size}
    />
  );
};

export const EditIconPlain = (props) => {
  return (
    <FontAwesomeIcon
      icon={faEdit}
      style={{ color: props.color || COLORS.orange, fontSize: props.fontSize }}
      size={props.size}
    />
  );
};

export const DropdownArrow = (props) => {
  return (
    <FontAwesomeIcon
      icon={faCaretSquareDown}
      size={"3x"}
      style={{
        color: "#ff7900",
        position: "absolute",
        right: props.right || "0%",
      }}
    />
  );
};

export const EditIconForEditEvent = (props) => {
  return (
    <FontAwesomeIcon
      icon={faEdit}
      style={{
        color: "#ff7900",
        position: "absolute",
        right: "5%",
        bottom: "5%",
      }}
    />
  );
};

export const AddIcon = () => {
  return (
    <FontAwesomeIcon
      icon={faPlus}
      style={{
        color: "#ff7900",
        position: "absolute",
        right: "5%",
        bottom: "5%",
      }}
    />
  );
};
export const AddIconNP = () => {
  return (
    <FontAwesomeIcon
      className="addIconNP"
      icon={faPlus}
      size={"1x"}
      style={{
        color: "#ff7900",
      }}
    />
  );
};

export const InfoIcon = (props) => {
  return (
    <FontAwesomeIcon
      icon={faInfoCircle}
      style={{
        color: "#ff7900",
        position: "absolute",
        left: "5%",
        top: "50%",
        transform: "translateY(-50%)",
        fontSize: "16px",
      }}
      data-tip={props.datatip}
    />
  );
};

export const CheckSquare = (props) => {
  return (
    <FontAwesomeIcon
      icon={faCheckSquare}
      style={{
        color: props.color || COLORS.blue,
        background: "white",
        fontSize: props.fontSize || 24,
        margin: props.margin || 0,
      }}
    />
  );
};

export const Check = (props) => {
  return (
    <FontAwesomeIcon
      icon={faCheck}
      style={{
        color: props.color || COLORS.blue,
        background: "white",
        fontSize: props.fontSize || 24,
        margin: props.margin || 0,
      }}
    />
  );
};
export const CheckYes = (props) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", justifyContent: "center", color: COLORS.blue, fontSize: 14, fontWeight: "bold" }}>
        {props.yn}
      </div>
      <FontAwesomeIcon
        icon={faCheckSquare}
        style={{
          color: props.color || COLORS.blue,
          background: "white",
          fontSize: props.fontSize || 24,
          margin: props.margin || 0,
          marginTop: 0,
        }}
      />
    </div>
  );
};

export const CheckNo = (props) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", justifyContent: "center", color: COLORS.blue, fontSize: 14, fontWeight: "bold" }}>
        {props.yn}
      </div>
      <FontAwesomeIcon
        icon={faSquare}
        style={{
          color: props.color || COLORS.blue,
          background: "white",
          fontSize: props.fontSize || 24,
          margin: props.margin || 0,
          marginTop: 0,
        }}
      />
    </div>
  );
};

export const Square = (props) => {
  return (
    <FontAwesomeIcon
      icon={faSquare}
      style={{
        color: props.color || COLORS.blue,
        fontSize: props.fontSize || 24,
      }}
    />
  );
};

export const QuestionIconNp = (props) => {
  return (
    <FontAwesomeIcon
      icon={faQuestionCircle}
      style={{
        color: "#ff7900",
        fontSize: props.fontSize || "18px",
        cursor: "pointer",
      }}
      data-tip={props.datatip}
    />
  );
};

export const QuestionIcon = (props) => {
  return (
    <FontAwesomeIcon
      icon={faQuestionCircle}
      style={{
        color: "#ff7900",
        position: "absolute",
        left: "5%",
        top: "50%",
        transform: "translateY(-50%)",
        fontSize: "18px",
      }}
      data-tip={props.datatip}
    />
  );
};
