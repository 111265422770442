import React, { useState, useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import getRepeatFirstLast from "./getLastDate";
import SelectInstructor from "./selectInstructor";
import SelectStudent from "./selectStudent";
import SelectHorse from "./selectHorse";
import SelectLocation from "./selectLocation";
import TextArea from "./textInputgeneric";
import TextInputTitle from "./textInputTitle";
import TimeAndDate from "./timeanddate";
import RepeatingDates from "./repeatingDates";
import { Button, Row, Col, Container } from "react-bootstrap";
import { MyContext, ListContext } from "./App";
import axios from "axios";
import {
  formatDateTimeForDB,
  dateObjToDisplayDate,
  dateObjToDisplayTime,
  dateObjToDBDate,
  getNewDefaultDateforMonth,
  calculateEndTime,
  repeatOptions,
} from "./momentUtils";
import AuthHelperMethods from "./AuthHelperMethods";
import "./style.css";
import ReactTooltip from "react-tooltip";
import { useAlert } from "react-alert";
import "./styleAdd.css";
import { TitleRow2 } from "./blueCheckFuture";
import { ClientAvatar, HorseAvatar, StaffAvatar, LocationAvatar } from "./images";
import { NoteIcon, LocationIcon, RepeatIcon, ClockIcon, TitleIcon } from "./icons";
import Select from "react-select";
import COLORS from "./styleConstants";
import moment from "moment";
import { UserCan } from "./formComponents";

const Auth = new AuthHelperMethods();

const orangelabel = {};

//render(<AlertDismissibleExample />);

function AddModal(props) {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);

  const alert = useAlert();
  let history = useHistory();
  let x = useParams();

  useEffect(
    () => {
      return () => {
        dispatch({ type: "SET_CURRENTLESSON_TO_NULL" });
      };
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      let event = mystate.event;
      console.log(Auth.getUserType());

      let { start, end } = event;

      // const endtime = calculateEndTime(moment(start).toDate(), 60);

      const wholedate = mystate.view === "month" ? getNewDefaultDateforMonth(start) : start;
      const endtime = calculateEndTime(moment(wholedate).toDate(), mystate.sectionDefaults.duration || 60);

      dispatch({
        type: "INITIALIZE_NEW_EVENT",

        data: {
          instructor_id: listState.currentStaff.instructor_id,
          duration: 60,
          title: "",
          is_recuring: false,
          repeat_end_type: "",
          end: endtime,
          start: moment(wholedate).toDate(),
          section_id: mystate.sectionDefaults.section_id,
        },
      });

      dispatch({
        type: "SET_REPEAT_VALUES",
        data: {
          selectRepeatValue: repeatOptions[0],
        },
      });

      dispatch({
        type: "SET_DATE_TIME_COMPONENT",
        start: moment(wholedate).toDate(),
        end: endtime,
        is_allday: false,
      });
      dispatch({
        type: "FETCH_SUCCESS",
      });
    },
    //eslint-disable-next-line
    []
  );

  //  const alertb = useAlert();
  //  const alerta = alert.show("Some message");
  //const alert = alert.info("You are adding a lesson!", { timeout: 0 });
  const successmsg = () => {
    return `${mystate.addevent.is_recuring ? "Recurring " : ""}  Event ${
      mystate.addevent.title
    }  on ${dateObjToDisplayDate(mystate.dateTimeComponent.start)}
       at  ${dateObjToDisplayTime(mystate.dateTimeComponent.start)} has been saved successfully.`;
  };

  const handleCancel = () => {
    history.goBack();
    console.log("click handleCancel");
    //dispatch({ type: "HIDE_ADD_MODAL" });
  };

  //const handleShow = () =>

  //  ReactModal.setAppElement("body");

  const saveNewEvent = async () => {
    dispatch({
      type: "FETCH_INIT",
    });
    if (mystate.addevent.section_id == undefined) {
      alert.show("section is required", { timeout: 15000, type: "error" });

      dispatch({
        type: "FETCH_SUCCESS",
      });
      return null;
    }
    if (mystate.addevent.location_id == undefined) {
      alert.show("section is required", { timeout: 15000, type: "error" });

      dispatch({
        type: "FETCH_SUCCESS",
      });
      return null;
    }

    const { horses, students, start, end } = mystate.addevent;
    let studentsArray = [],
      horsesArray = [];

    if (students) {
      studentsArray = students.map((student) => {
        return student.value;
      });
      console.log(studentsArray);
    }
    if (horses) {
      horsesArray = horses.map((horses) => {
        return horses.value;
      });
      console.log(horsesArray);
    }

    const repeatObj = getRepeatFirstLast(mystate, {});
    const datetimeInfo = formatDateTimeForDB(mystate.dateTimeComponent);
    const dbParams = {
      ...mystate.addevent,
      ...repeatObj,
      ...datetimeInfo,
      students: studentsArray,
      horses: horsesArray,
      calendarDateRangeStart: dateObjToDBDate(mystate.calendarDateRangeStart),
      calendarDateRangeEnd: dateObjToDBDate(mystate.calendarDateRangeEnd),
    };
    console.log(dbParams.date1);

    let route = mystate.addevent.is_recuring ? "lessons/createRecurringLesson" : "lessons/createSingleLesson";

    try {
      const result = await Auth.api(route, "post", dbParams);
      //const data= await result;
      console.log(mystate.addevent.is_recuring);

      dispatch({ type: "ADD_NEW_LESSON", payload: result.data });

      dispatch({
        type: "FETCH_SUCCESS",
      });

      //dispatch({ type: "HIDE_ADD_MODAL" });
      history.goBack();

      alert.show(successmsg(), { timeout: 15000, type: "success" });

      Auth.getConfirm();
      return result;
    } catch (error) {
      dispatch({ type: "FETCH_ERROR" });
      alert.show(error.message, { timeout: 5000, type: "error" });
    }
  };

  let location_id = Number(mystate.addevent.location_id);

  const handleSelectChange = (e) => {
    dispatch({
      type: "SET_NEW_EVENT",
      data: { section_id: e.value },
    });
    dispatch({
      type: "SET_NEW_EVENT",
      data: { title: e.label },
    });
  };
  return (
    <>
      {1 === 1 && (
        <div className="add" style={{ margin: 50, maxWidth: 500, display: "flex", flexDirection: "column", flex: 1 }}>
          <TitleRow2 clickevent={() => handleCancel()} title={"Add Event "} />

          <div style={{ display: "flex", flexDirection: "column", padding: 20 }}>
            <div>
              <h4 style={{ color: COLORS.blue }}>{`staff name: ${listState.accountHolder.person_name}`}</h4>
            </div>
            <div>
              <div className="blocklabel">{"SECTION "}</div>

              <Select
                onChange={(e) => handleSelectChange(e)}
                className="form-control"
                value={mystate.options_section.filter((section) => section.value == mystate.addevent.section_id)}
                options={mystate.options_section}
                clearable={true}
                searchable={true}
              />
            </div>

            <div>
              <div className="blocklabel" style={orangelabel}>
                <TitleIcon /> {"TITLE"}
              </div>

              <TextInputTitle addOrEdit={"add"} />
            </div>
            <div>
              <div className="blocklabel" style={orangelabel}>
                <ClockIcon />
                {"DATE AND TIME "}
              </div>
              <TimeAndDate />
            </div>
            <div>
              <div className="blocklabel" style={orangelabel}>
                <RepeatIcon /> {"RECURRING "}
              </div>
              <RepeatingDates />
            </div>
            <UserCan isThis={"INS"} doThis={"STAFF_CAN_ADD_STUDENTS"}>
              <div>
                <div className="blocklabel" style={orangelabel}>
                  <ClientAvatar />
                  {"CLIENTS"}
                </div>

                <SelectStudent addOrEdit={"add"} />
              </div>
            </UserCan>
            <UserCan isThis={"INS"} doThis={"STAFF_CAN_ADD_HORSES"}>
              <div>
                <div className="blocklabel" style={orangelabel}>
                  <HorseAvatar /> {"HORSES"}
                </div>
                {mystate.options_horse && <SelectHorse add horse />}
              </div>
            </UserCan>
            {1 == 2 && (
              <div>
                <div className="blocklabel" style={orangelabel}>
                  <StaffAvatar /> {"STAFF"}
                </div>
                <SelectInstructor addOrEdit={"add"} />
              </div>
            )}

            <div>
              <div className="blocklabel" style={orangelabel}>
                <LocationIcon /> {"LOCATION "}
              </div>
              <SelectLocation />
            </div>

            <div>
              <div className="blocklabel" style={orangelabel}>
                <NoteIcon /> {"NOTES"}
              </div>

              <TextArea style={{ border: "solid blue" }} addOrEdit={"add"} />
            </div>

            <div style={{ marginTop: "50px", marginBottom: "50px" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <Button
                  className="btn-close"
                  size="md"
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  Cancel
                </Button>
                <Button className="btn-save" size="md" onClick={() => saveNewEvent()}>
                  Save Event
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default AddModal;
