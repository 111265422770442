import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Row, Col, Button, ListGroup, Modal } from "react-bootstrap";
import { ListContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import {
  StyledListGroup,
  StyledList,
  AccountHolderText,
  StudentText,
  Td,
  Th,
  PrimaryText,
  PrimaryTextBlue,
  SaveButton,
  ContainerV,
  OrangeButton,
} from "./styleCustom";
import SearchFilter from "./searchFilter";
import {
  filterToParams,
  currentDayDB,
  dateISOtoString,
  timeISOtoString,
  dateISOtoStringMed,
  dateTimeISOtoStringMed,
  currentTimeToDB,
  currentTimeToDBlocal,
  currentDayToDB,
  todayDisplayDateTime,
} from "./momentUtils";
import { EditIcon, Check, Square, CheckSquare, PinIcon } from "./icons";
import { AccountHolderForm, ContactForm, StudentsForm, AddressForm } from "./clientForm";
import COLORS from "./styleConstants";
import { TableHeader2, ModalHeaderBlue, ModalFooterBlue, ButtonOrange, ButtonOrangeDisable } from "./formComponents";
import { deleteImg, privateNoteImg, publicNoteImg } from "./images";

import moment from "moment";
import { useAlert } from "react-alert";

const Auth = new AuthHelperMethods();

// use this for non lesson notes
const AddNote = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const [noteInput, setNoteInput] = useState(props.defaultNote || "");
  const [pin, setPin] = useState(true);
  let urlparams = useParams();

  const [isPublic, setIsPublic] = useState(urlparams.isPublic == 1 ? true : false);

  //let isPublic = ;
  let clienthorsestaff = urlparams.who;
  if (isPublic == 1) console.log("Public");
  if (isPublic) console.log("Public");
  console.log(isPublic);
  console.log(clienthorsestaff);

  const [showAddNoteModal, setShowAddNoteModal] = useState(true);
  const [sendEmail, setSendEmail] = useState(false);
  const usertype = Auth.getUserType();
  let history = useHistory();
  console.log(history);
  let location = useLocation();
  console.log(location.pathname);
  //let pnoteublic = useParams();
  //console.log(pnoteublic);

  //console.log(x);

  useEffect(
    () => {
      console.log("useEffect");
      window.searchinputRef = searchinputRef;
      searchinputRef.current.focus();
    },
    //eslint-disable-next-line
    []
  );
  function handleNoteInputChange(e) {
    setNoteInput(e.target.value);
  }

  /*function handleClickAddNotes(ispublic) {
    setShowAddNoteModal(true);
    setIsPublic(ispublic);
  }*/

  var name = (function (clienthorsestaffIn) {
    switch (clienthorsestaffIn) {
      case "client":
        return listState.currentClient.person_name;
      case "staff":
        return listState.currentStaff.person_name;
      case "horse":
        return listState.currentHorse.horse_name;
      default:
        return "";
    }
  })(clienthorsestaff);

  var controller = (function (url) {
    switch (url) {
      case "client":
        return `notes_clients/createNoteFromClientPage`;
      case "staff":
        return `notes_clients/createNoteFromClientPage`;
      case "horse":
        return `notes_horses/create`;
      default:
        return "";
    }
  })(clienthorsestaff);

  var params = (function (clienthorsestaffIn) {
    switch (clienthorsestaffIn) {
      case "client":
        return { person_id: listState.currentClient.id };
      case "staff":
        return { person_id: listState.currentStaff.person_id };
      case "horse":
        return { horse_id: listState.currentHorse.id };
      default:
        return {};
    }
  })(clienthorsestaff);

  const notefor = () => {
    let who = "";
    if (usertype == "STU") {
      who = "Admin";
    }
    if (usertype !== "STU") {
      who = name;
    }
    return who;
  };

  const saveNotes = async () => {
    console.log(moment().utc().format("HH:mm"));
    console.log(moment().utc());
    console.log(moment().local().format("HH:mm"));

    params = {
      ...params,
      notes: noteInput,
      is_public: isPublic == 1 ? true : false,
      pin: pin,
      sendEmail: sendEmail,
      emailAddress: listState.accountHolder.contact_email,
      date_created_at: currentDayToDB(),
      time_created_at: currentTimeToDB(),
      created_at: todayDisplayDateTime(),
      created_by_name: Auth.getUserType() == "STU" ? listState.accountHolder.person_name : "staff",
    };
    console.log(clienthorsestaff);

    const result = await Auth.api(controller, "post", params);
    history.goBack();
  };

  const handleClose = () => {
    history.goBack();
  };

  const searchinputRef = React.createRef();
  console.log("Note");
  return (
    <>
      <Modal show={showAddNoteModal} onHide={() => handleClose()} size={"md"}>
        <ModalHeaderBlue closeButton>
          <Modal.Title>{`Add ${isPublic == 1 ? "Public" : "Private"} Note for ${
            notefor() || clienthorsestaff
          }`}</Modal.Title>
        </ModalHeaderBlue>
        <ContainerV style={{ padding: 20 }}>
          <div
            className="icons"
            style={{
              display: "flex",
              flex: "0 0 auto",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div onClick={Auth.getUserType() == "ADM" ? () => setIsPublic(!isPublic) : null}>
              <img
                alt={"isPublic?"}
                style={{ maxHeight: "20px" }}
                src={isPublic == 1 ? publicNoteImg : privateNoteImg}
              />
            </div>
            <div onClick={() => setPin(!pin)} style={{ marginTop: 5 }}>
              <PinIcon fontSize={"20px"} color={pin ? COLORS.orange : COLORS.medgrey} />
            </div>
          </div>
          <div className="ugh" style={{ flex: "1 1 100% " }}>
            <textarea
              ref={searchinputRef}
              style={{
                minWidth: "100%",
                border: "solid 1pt lightgrey",
                resize: "none",
              }}
              rows={8}
              onChange={(e) => handleNoteInputChange(e)}
              autoFocus
            />
          </div>
        </ContainerV>
        {clienthorsestaff !== "horse" && isPublic && (
          <div
            style={{
              margin: 20,
              padding: 20,

              fontSize: 18,
              display: "flex",
            }}
            className="emailNote"
          >
            <div onClick={() => setSendEmail(!sendEmail)} style={{ marginRight: 10 }}>
              {sendEmail ? <CheckSquare /> : <Square />}
            </div>
            <div style={{ fontSize: 20, color: COLORS.textDark }}>{`Would you like to email this note to  ${
              notefor() || clienthorsestaff
            }?`}</div>
          </div>
        )}{" "}
        <Modal.Footer style={{ justifyContent: "center" }}>
          <OrangeButton onClick={() => saveNotes()}>{"Save Note"}</OrangeButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const BulkEmail = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const [noteInput, setNoteInput] = useState("");
  let history = useHistory();
  const alert = useAlert();

  function handleNoteInputChange(e) {
    setNoteInput(e.target.value);
  }
  const saveNotes = async () => {
    const params = {
      notes: noteInput,
      date_created_at: currentDayToDB(),
      time_created_at: currentTimeToDB(),
      created_at: todayDisplayDateTime(),
    };

    const result = await Auth.api(`notes_clients/createBulkNote`, "post", params);
    if (result) alert.show(`${result.data.emails} emails sent`, { timeout: 5000, type: "success" });

    props.set(false); // history.goBack();
  };
  const handleClose = () => {
    // setShowEmail(false);
  };
  const searchinputRef = React.createRef();

  return (
    <>
      <Modal show={props.show} onHide={() => props.set(false)} size={"md"}>
        <ModalHeaderBlue closeButton>
          <Modal.Title>{`Send email to all active account holders`}</Modal.Title>
        </ModalHeaderBlue>

        <div className="ugh" style={{ flex: "1 1 100% ", margin: 20 }}>
          <textarea
            ref={searchinputRef}
            style={{
              minWidth: "100%",
              border: "solid 1pt lightgrey",
              resize: "none",
            }}
            rows={8}
            onChange={(e) => handleNoteInputChange(e)}
            autoFocus
          />
        </div>

        <Modal.Footer style={{ justifyContent: "center" }}>
          <ButtonOrangeDisable onClick={() => saveNotes()} label={"Send Email"} />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddNote;
