import React, { useContext, useState, useEffect, useRef, Children, cloneElement } from "react";
import { BrowserRouter, Route, Link, useRouteMatch, useParams, useHistory, useLocation } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.css";
import { MyContext } from "./App";
import AddModal from "./addModal.js";

import AddModalStudent from "./addModalStudent.js";
import EditEvent from "./EditEvent.js";
import { DATEPICKER_DATE_DISPLAY } from "./UserSettings";
import AuthHelperMethods from "./AuthHelperMethods";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, Button, OverlayTrigger, Popover, Modal } from "react-bootstrap";
import { useAlert } from "react-alert";
import ReactTooltip from "react-tooltip";

import Logout from "./logout";
import { ListContext } from "./App";
import { NavButtonBlue, Row2, PageTitle, CancelButtonSm } from "./styleCustom";
import { ButtonOrange, ButtonBlue } from "./formComponents";
import COLORS from "./styleConstants";
import PrivateRoute from "./privateRoute";
import { SelectTemplateFilter, SelectCalendarFilter, Active, SelectTemplateFilterNoLabel } from "./searchFilterReports";
import { DISPLAY_DATETIME, DISPLAY_DATE, DISPLAY_TIME, DISPLAY_DATE_SHORT, DISPLAY_DATE_MED } from "./UserSettings";
import {
  getNewDefaultDateforMonth,
  calculateEndTime,
  MINTIME,
  dateObjToDBDate,
  repeatOptions,
  dateObjToDisplayDate,
  timeISOtoString,
  DBTIMEFORMAT,
  hasPermssion,
} from "./momentUtils";

/*import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
} from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import TimeLineHorse from "./horseTimeTable";*/

const Auth = new AuthHelperMethods();
const localizer = momentLocalizer(moment);

export const Info = ({ currentevent }) => {
  let event = currentevent;
  return (
    <div style={{ display: "flex", flex: "1 1 100%", flexDirection: "column" }}>
      <strong>
        {event.title} {event.is_allday ? "all day " : event.time}
      </strong>
      <div>
        {event.instructor_name} {event.location_name}
      </div>
      <div> {event.studentString}</div>
      <div> {event.horseString}</div>
    </div>
  );
};
const CustomEvent = (event) => {
  const { mystate, dispatch } = useContext(MyContext);

  event = event.event;
  let currentevent = event.event;
  let popoverClickRootClose = (
    <Popover id="popover-trigger-hover-root-close" style={{ zIndex: 10000, padding: 10 }}>
      <Info currentevent={event} />
    </Popover>
  );

  return (
    <div>
      <OverlayTrigger
        id="help"
        trigger={["hover", "focus"]}
        rootClose
        container={this}
        placement="bottom"
        overlay={popoverClickRootClose}
      >
        <div style={{ xmaxWidth: 150, minHeight: 15 }}>
          {mystate.view == "day" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "white",
                padding: 5,
                color: COLORS.tablegrey,
              }}
            >
              <strong>
                {event.title} {event.is_allday ? "all day " : event.time}
              </strong>
              <div>
                {event.instructor_name} {event.location_name}
              </div>
              <div> {event.studentString}</div>
              <div> {event.horseString}</div>
            </div>
          )}
          {mystate.view !== "day" &&
            `${mystate.showByStudentName ? event.studentString || event.horseString : event.label || event.title} `}
        </div>
      </OverlayTrigger>
    </div>
  );
};

/*const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};*/
//const mydefaultview = "day";

export const CalendarHome = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const isAdmin = Auth.getUserType() == "ADM" || Auth.getUserType() == "INS" ? true : false;
  // let canAddEvents = true;
  // const width = window.innerWidth;
  const width = "100%";

  const breakpoint = 620;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);

  const alert = useAlert();
  const [isError, setIsError] = useState(false);

  let history = useHistory();
  let location = useLocation();
  let front = location.pathname.substring(0, location.pathname.lastIndexOf("/"));
  console.log(front);

  let newEvents = mystate.lessons;
  let [refresh, setRefresh] = useState(true);
  const resourceViews = ["instructor", "location", "section", "horse", "tack", "default"];
  const [resourceView, setResourceView] = useState(mystate.farmDefaults.default_day_view || "default");
  const mydefaultresource = mystate.farmDefaults.default_day_view;
  const horseTack = (mystate.resource == "tack" || mystate.resource == "horse") && mystate.view == "day";
  const [studentBooking, setStudentBooking] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  moment.updateLocale("en", {
    week: {
      dow: mystate.farmDefaults.monday_start ? 1 : 0, // First day of week is Monday
      doy: mystate.farmDefaults.monday_start ? 7 : 6, // First week of year must contain 1 January (7 + 1 - 1)
    },
  });

  const resourceMap = {
    instructor: mystate.options_instructor,
    location: mystate.options_location,
    section: mystate.options_section,
    horse: mystate.options_horse,
    tack: mystate.options_tack,
    default: [],
  };

  const resourceAccessor = {
    instructor: "instructor_id",
    location: "location_id",
    section: "section_id",
    horse: "horse_id",
    tack: "tack_id",
    default: "",
  };

  console.log(location.pathname);

  useEffect(
    () => {
      let data = [];
      if (resourceMap[mystate.resource] && listState.filtercal[`${mystate.resource}`]) {
        data = resourceMap[mystate.resource].filter((item) =>
          listState.filtercal[`${mystate.resource}`].includes(item.value)
        );
      } else {
        data = resourceMap[mystate.resource];
      }

      dispatch({
        type: "SET_RESOURCE_MAP",
        data: data,
      });

      dispatch({
        type: "SET_RESOURCE_ACCESSOR",
        resourceAccessor: resourceAccessor[mystate.resource],
      });
    },
    //eslint-disable-next-line
    [
      mystate.options_instructor,
      mystate.options_location,
      mystate.options_section,
      mystate.options_horse,
      mystate.options_tack,
      mystate.resource,
      listState.filtercal,
      mystate.lessons,
    ]
  );

  useEffect(
    () => {
      const fetchData = async () => {
        console.log("********** ORIGINAL LESSONS GET **************");
        let params = {};
        //let params = { ...listState.filter }; //*had this to test for using calendar in horse page **/
        //*coming from daysheet for example, had to add listState.filter to useEffect dependency since this get happened before daysheet reset state **/
        params.scheduled_date = mystate.calendarDateRangeStart;
        params.scheduled_end_date = mystate.calendarDateRangeEnd;

        //params.scheduled_date = dateObjToDBDate(moment().startOf("month").toDate());  //* changed to calendar date range sinc this runds every mothf8*//
        //params.scheduled_end_date = dateObjToDBDate(moment().endOf("month").toDate());

        // setResourceView(mystate.farmDefaults.default_day_view || "default");

        try {
          console.log("time to run");
          let result = {};

          result = await Auth.api("lessons/index", "get", params);
          if (result && result.data) {
            dispatch({ type: "GET_ALL_EVENTS", payload: result.data });
          }

          //    dispatch({ type: "FETCH_SUCCESS" });
          //  setRefresh(false);
        } catch (error) {
          alert.show("Sorry, there has been an error sending the data.  Please try again.", {
            timeout: 50000,
            type: "error",
          });

          dispatch({ type: "FETCH_ERROR" });
        }
      };

      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      console.log("FILTER_EVENTS");

      dispatch({
        type: "FILTER_EVENTS",
        data: listState.filtercal,
        lessons: mystate.lessons,
      });
    },

    //eslint-disable-next-line
    [listState.filtercal, mystate.lessons]
  );

  const onClickResource = (item) => {
    dispatch({
      type: "SET_RESOURCE",
      data: item,
    });

    dispatch({
      type: "SET_RESOURCE_MAP",
      data: resourceMap[item],
    });
    dispatch({
      type: "SET_RESOURCE_ACCESSOR",
      data: resourceAccessor[item],
    });

    // setResourceView(item);
  };

  const addStudentToLesson = async () => {
    //? for booking from client page only ?//
    //  setStudentBooking(true);
    let lessonToBook = currentEvent;
    console.log(lessonToBook);
    let lesson = {};
    lesson.id = lessonToBook.id;
    lesson.instructor_id = lessonToBook.instructor_id;
    lesson.lesson_date_time_id = lessonToBook.id;
    lesson.student_id = listState.currentClient.student_id;
    lesson.student_email = listState.accountHolder.contact_email;
    lesson.person_id = listState.currentClient.id;
    lesson.account_name = listState.accountHolder.first_name;
    lesson.makeup_lesson_id = listState.currentClient.makeup_lesson_id || null; //?this doesn't make sense -> doesn't exist

    console.log(lesson);
    // let msg = lessonInWords(lesson);
    // lesson.msg = msg;

    //let packageurl = isMakeup ? "items/everything" : "items/makeup";
    // let item_id = incoming.is_makeup || 0;

    // if (incoming.item_id) lesson = { ...lesson, item_id: incoming.item_id, is_makeup: true }; //rule - if booking from package ie has item_id then it is a makeup
    // console.log(incoming.is_makeup);
    console.log(lesson);

    try {
      const result = await Auth.api("lesson_people/addStudentToLesson", "post", lesson);

      if (result) {
        if (result.data.lessonPerson == "already in lesson") {
          // dispatch2(["SET_DIALOG_SHOW", true]);
          alert.show("Student is already booked for this lesson spot");
          setStudentBooking(false);
        } else if (result.data.lessonPerson == "none available") {
          setStudentBooking(false);

          alert.show("No spots available for this lesson");
        } else {
          setRefresh(!refresh);
          setStudentBooking(false);

          alert.show("added", {
            timeout: 5000,
            type: "success",
          });
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleExistingEvent = (e) => {
    //  let front = location.pathname.substring(0, location.pathname.lastIndexOf("/"));
    console.log(front);
    if (front.startsWith("/admin/clienthome/")) {
      if (Auth.getUserType() == "STU") return null;

      setStudentBooking(true);

      // addStudentToLesson(e);
      console.log("ADD STUDENT TO LESSON");
      setCurrentEvent(e);
      dispatch({
        type: "GET_EVENT_DETAIL",
        data: e.id,
      });
      dispatch2(["SET_CURRENT_BOOKING_LESSON", e]);
      history.push(`${front}/bookingCalendar`);
    } else {
      console.log(e);
      if (horseTack) return null;
      if (Auth.getUserType() == "STU") return null; //todo
      //if (Auth.getUserType() == "INS") return null; //todo //todo
      if (Auth.getUserType() == "INS" && listState.userPermissions.includes("STAFF_CAN_VIEW_CLIENT_HORSE_DETAIL"))
        history.push(`/admin/calendarGroup/edit/${e.id}`);

      // if (Auth.getUserType() == "INS" && !mystate.farmDefaults.staff_can_edit) return null; //todo
      /* if (
      Auth.getUserType() == "INS" &&
      mystate.farmDefaults.restrict_staff_edit_own &&
      e.instructor_id !== listState.currentStaff.instructor_id
    )
      return null;
*/ console.log("handleExistingEvent");
      console.log(e);
      console.log(e.instructor_id);
      // let url= isAdmin? `/admin/calendarGroup/edit/${e.id}` ||
      history.push(`${front}/edit/${e.id}`);

      //history.push(`/admin/calendarGroup/edit/${e.id}`);
      /* if (Auth.getUserType() == "ADM") history.push(`/admin/calendarGroup/edit/${e.id}`);
    if (Auth.getUserType() == "INS") {
      let front = location.pathname.substring(0, location.pathname.lastIndexOf("/"));

      //  console.log(`$(location.pathname.substring(0, location.pathname.lastIndexOf("/"))1);

      history.push(`${front}/edit/${e.id}`);
    }
    */

      //  history.push(`${props.match.url}/edit/${e.id}`);
    } //  history.push("/edit/edit");
  };

  const handleNewEvent = (event) => {
    console.log("this has been clicked now");
    if (horseTack) return null;
    if (Auth.getUserType() == "STU" && !mystate.farmDefaults.clients_can_add_lesson) return null; //* wait till release this feature - also should be || insted of && ?
    let front = location.pathname.substring(0, location.pathname.lastIndexOf("/"));
    console.log(front);
    console.log(location.pathname);
    console.log("****");
    console.log("mystate.farmDefaults.clients_can_add_lesson");

    console.log(mystate.farmDefaults.clients_can_add_lesson);
    console.log("!mystate.farmDefaults.clients_can_add_lesson");

    console.log(!mystate.farmDefaults.clients_can_add_lesson);
    console.log("****");

    console.log(Auth.getUserType() == "STU");
    console.log(Auth.getUserType() != "STU");
    console.log("****");

    //if (Auth.getUserType() == "STU" && !mystate.farmDefaults.client_can_add_lesson) return null;
    if (Auth.getUserType() == "STU" && mystate.farmDefaults.clients_can_add_lesson) console.log("GO AHEAD");
    else console.log("STOP!");
    if (Auth.getUserType() == "INS" && !listState.userPermissions.includes("STAFF_CAN_ADD_EVENTS")) return null; //todo
    dispatch({
      type: "FETCH_INIT",
    });
    console.log(front);
    dispatch({
      type: "SET_EVENT",
      event: event,
    });

    console.log(`${front}/add/newevent`);

    history.push(`${front}/add/newevent`);

    //  history.push(`/admin/calendarGroup/add/newevent`);
  };

  function handleNavigate(date) {
    console.log("handleNavigate");
    dispatch({
      type: "SET_CALENDAR_DATE",
      data: date,
    });

    console.log(date);
  }

  async function handleRangeChange(e) {
    console.log("handleRangeChange");

    console.log(e);
    dispatch({ type: "FETCH_INIT" });
    var currentStart, currentEnd;
    let params = {};

    if (e.length) {
      if (e.length == 1) {
        currentStart = e[0];
        currentEnd = e[0];
        params = {
          scheduled_date: dateObjToDBDate(currentStart),
          scheduled_end_date: dateObjToDBDate(currentEnd),
        };

        console.log(params);
      } else if (e.length == 7) {
        currentStart = e[0];
        currentEnd = e[6];
        params = {
          scheduled_date: dateObjToDBDate(currentStart),
          scheduled_end_date: dateObjToDBDate(currentEnd),
        };
        console.log(params);
      }
    } else {
      currentStart = e.start;
      currentEnd = e.end;
      params = {
        scheduled_date: dateObjToDBDate(currentStart),
        scheduled_end_date: dateObjToDBDate(currentEnd),
      };
      console.log(listState.filter);
      console.log(params);
    }
    //currentEnd = moment(date).endOf("month").add(1, "week").toDate();
    params = { ...params, ...listState.filter };
    console.log(params);

    try {
      let result = await Auth.api("lessons/index", "get", params);
      if (result.data) {
        dispatch({ type: "GET_ALL_EVENTS", payload: result.data });
        dispatch({
          type: "SET_CALENDAR_DATE_RANGE",
          start: params.scheduled_date,
          end: params.scheduled_end_date,
        });
      }
      dispatch({ type: "FETCH_SUCCESS" });

      console.log(result.data);
    } catch {
      dispatch({ type: "FETCH_ERROR" });
    }
  }

  function handleView(e) {
    console.log(resourceView);
    console.log("handleView");
    console.log(e);
    /*  if (e === "day") {
      dispatch({
        type: "SET_RESOURCE_MAP",
        data: resourceMap[resourceView] || [],
        resourceAccessor: resourceAccessor[resourceView],
      });
    }*/
    /*
    if (e === "week") {
      dispatch({
        type: "SET_RESOURCE_MAP",
        data: resourceMap["default"] || [],
        resourceAccessor: resourceAccessor["default"],
      });
    }
*/
    dispatch({
      type: "SET_VIEW",
      data: e,
    });
    //handleNavigate();
  }

  //if (!listState.selectedMainPage === "calendar") return null; //obsolets

  // if (!settingsLoaded) return null;

  function onToggleChange() {
    console.log(mystate.showByStudentName);
    console.log(!mystate.showByStudentName);

    dispatch({ type: "TOGGLE_SHOW_BY_STUDENT_NAME" });
    //console.log(event.target.checked);
    console.log(mystate.showByStudentName);
  }

  if (!mystate.lessons) return null;
  //if (!listState.selectedMainPage === "calendar") return null; ??obsolete
  const slotPropGetter1 = () => {
    return {
      className: "rbc-timeslot-group",
      style: { minHeight: 20 },
    };
  };

  const dayPropGetterbkg = () => {
    return {
      className: "rbc-today",
      style: { background: "white" },
    };
  };

  const eventStyleGetter2 = (event) => {
    let newStyle = {
      color: "black",
      borderRadius: "0px",
      border: "none",
    };
    event.color = event.lesson_is_cancelled ? "lightgrey" : event.color;
    if (!event.id) {
      event.color = "yellow";
    }
    if (!event.instructor_id) {
      event.color = "white";
    }
    if (event.color) {
      newStyle.backgroundColor = event.color;
      newStyle.border = "solid 1px";
      newStyle.borderLeft = "solid 5px lightgrey";
      //newStyle.borderColor = event.color;
    }

    return {
      className: "",
      style: newStyle,
    };
  };
  if (!mystate.view) return null;
  if (!mystate.resource) return null;
  if (!mystate.options_instructor) return null;
  console.log(listState.filtercal.instructor);
  let staffDefault = [];
  if (listState.filtercal.instructor)
    staffDefault = mystate.options_instructor.filter((staff) => listState.filtercal.instructor.includes(staff.value));
  console.log(staffDefault);

  return (
    <>
      <PageTitle>Calendar</PageTitle>
      {studentBooking && (
        <Modal show={studentBooking} size="md" centered>
          <Modal.Header style={{ background: COLORS.blue, color: "white" }} closeButton>
            <Modal.Title>{"Add Student To Lesson"} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{"add student to lesson"}</div>
            <Info currentevent={currentEvent} />
          </Modal.Body>
          <Modal.Footer>
            <CancelButtonSm onClick={() => addStudentToLesson()}>Add</CancelButtonSm>
            <CancelButtonSm onClick={() => setStudentBooking(false)}>Close</CancelButtonSm>
          </Modal.Footer>
        </Modal>
      )}
      <Row2 className="calendarContainer">
        {!horseTack && (
          <div
            className="filterwrapper"
            style={{
              display: "flex",
              flex: "1 1 100%",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <SelectCalendarFilter
              options={mystate.options_instructor}
              isMulti
              label={"instructor"}
              list={listState.filter_labelscal.instructor || null}
              defaultValue={
                listState.filtercal.instructor
                  ? mystate.options_instructor.filter((staff) => listState.filtercal.instructor.includes(staff.value))
                  : null
              }
            />
            <SelectCalendarFilter
              options={mystate.options_program_type}
              isMulti
              label={"program_type"}
              list={listState.filter_labelscal.program_type || null}
              extraLabel={"program type"}
              defaultValue={
                listState.filtercal.program_type
                  ? mystate.options_program_type.filter((pgm) => listState.filtercal.program_type.includes(pgm.value))
                  : null
              }
            />

            <SelectCalendarFilter
              options={mystate.options_program}
              isMulti
              label={"program"}
              list={listState.filter_labelscal.program || null}
              maxWidth={300}
              defaultValue={
                listState.filtercal.program
                  ? mystate.options_program.filter((pgm) => listState.filtercal.program.includes(pgm.value))
                  : null
              }
            />

            <SelectCalendarFilter
              options={mystate.options_section}
              isMulti
              label={"section"}
              list={listState.filter_labelscal.section || null}
              defaultValue={
                listState.filtercal.section
                  ? mystate.options_section.filter((sec) => listState.filtercal.section.includes(sec.value))
                  : null
              }
            />
            <SelectCalendarFilter
              options={mystate.options_location}
              isMulti
              label={"location"}
              list={listState.filter_labelscal.location || null}
              defaultValue={
                listState.filtercal.location
                  ? mystate.options_location.filter((loc) => listState.filtercal.location.includes(loc.value))
                  : null
              }
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            flex: "1 1 100%",
            flexWrap: "wrap",
            marginBottom: 30,
            justifyContent: "space-between",
          }}
        >
          {Auth.getUserType() == "ADM" && (
            <div>
              <Link to="/admin/bulk">
                <ButtonOrange label={"bulk lesson actions"} />
              </Link>
              <ButtonOrange
                onClick={() => onToggleChange()}
                label={mystate.showByStudentName ? "show by event name" : "show by client name"}
              />
            </div>
          )}
          {mystate.view === "day" && (
            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
              <div
                style={{ display: "flex", alignItems: "center", color: COLORS.blue, fontSize: 16, fontWeight: "bold" }}
              >
                {"View By: "}
              </div>

              {resourceViews &&
                resourceViews.map((item) => (
                  <div key={item}>
                    <ButtonBlue onClick={() => onClickResource(item)} key={item} label={`${item}`} />
                  </div>
                ))}
            </div>
          )}
        </div>

        {Auth.getUserType() == "ADM" && (
          <div style={{ display: "flex", flex: "1 1 100%" }}>
            {"To add lesson, click on empty time slot. For touch devices, touch and briefly hold on empty time slot."}
          </div>
        )}
        <div className="startcalendar" style={{ display: "flex", flex: "1 1 auto" }}>
          <div style={{ display: "flex", flex: "1 1 auto" }}>
            <div style={{ minHeight: 700, display: "flex", flex: "1 1 auto" }}>
              {mystate.filteredEvents && 2 == 2 && (
                <Calendar
                  allDayAccessor={"allDay"}
                  components={{ event: isAdmin ? CustomEvent : null }}
                  events={horseTack && mystate.view == "day" ? mystate.lessonHorses : mystate.filteredEvents}
                  localizer={localizer}
                  step={mystate.farmDefaults.calendar_interval || 60}
                  date={mystate.calendarDate}
                  views={["month", "week", "day", "agenda"]}
                  defaultView={isMobile ? "day" : mystate.view}
                  onSelectSlot={(event) => handleNewEvent(event)}
                  onSelectEvent={(event) => handleExistingEvent(event)}
                  selectable
                  onNavigate={handleNavigate}
                  onView={handleView}
                  onRangeChange={handleRangeChange}
                  min={mystate.farmDefaults.min_time}
                  max={mystate.farmDefaults.max_time}
                  resources={
                    mystate.resource_map == undefined || mystate.resource_map.length === 0 || mystate.view == "week"
                      ? null
                      : mystate.resource_map
                  }
                  resourceAccessor={mystate.resourceAccessor}
                  resourceIdAccessor={"value"}
                  resourceTitleAccessor={"label"}
                  eventPropGetter={eventStyleGetter2}
                  dayPropGetter={dayPropGetterbkg}
                  slotPropGetter={slotPropGetter1}
                  popup
                  tooltipAccessor={null}
                  style={{ flex: "1 1 auto" }}
                  length={7}
                />
              )}
            </div>
          </div>
        </div>
      </Row2>
    </>
  );
};

export default CalendarHome;
