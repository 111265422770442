import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import COLORS from "./styleConstants";
import { Formik, Form, useField, Field, useFormikContext, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CheckSquare, Square } from "./icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import User from "./UserSettings";
import { Link, NavLink } from "react-router-dom";
/*
export const Container = styled.div`
  margin: 0 50px 200px 50px;
`;
*/

const activeClassName = "nav-item-active";

const activeStyle = { color: "red" };

export const LNavLink = styled(NavLink)`
  font-size: 18px;
  color: white;
  padding: 20px;
  text-decoration: none;
  &:focus,
  &:hover {
    color: ${COLORS.blue};
    text-decoration: none;
    background: white;
  }
`;

export const BlueNavLink = styled(LNavLink)`
  background: ${COLORS.blue};
  color: white;
  padding: 5px 20px;
  display: flex;
  border: solid 1pt ${COLORS.blue};
  &:focus,
  &:hover {
    color: ${COLORS.blue};
    background: white;
  }
`;

export const BlueNavLink12 = styled(BlueNavLink)`
  font-size: 12px;
  padding: 5px;
`;

export const BlueNavLinkSm = styled(BlueNavLink)`
  font-size: 14px;
`;

export const BlueInvLink = styled(BlueNavLink)`
  display: flex;
  flex-wrap: wrap;
  min-width: 150px;
  color: ${COLORS.blue};
  font-size: 16px;
  background: white;
  border: none;
  &:focus,
  &:hover {
    color: white;
    background: ${COLORS.blue};
  }
`;

export const LeftNavLink = styled(LNavLink).attrs({ activeClassName })`
  &.${activeClassName} {
    background: white;
    color: ${process.env.NODE_ENV == "production" ? COLORS.orange : COLORS.blue};
  }
`;

export const IndexNavLink = styled(LNavLink).attrs({ activeClassName })`
  &.${activeClassName} {
    background: white;
    color: ${COLORS.blue};
  }
`;

export const Container = styled.div`
  display: flex;
`;
export const ContainerH = styled(Container)`
  flex-direction: column;
`;
export const ContainerV = styled(Container)`
  flex-direction: row;
`;
export const Row1 = styled.div`
  flex-wrap: wrap;
  flex: 1 1;
  display: flex;
  min-width: 100px;
  min-height: 100px;
`;
export const Row2 = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex-basis: 100%;
`;
export const Row100 = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

export const Ul = styled.ul`
  text-decoration: none;
  text-align: left;
  margin: 0;
  padding: 0;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

//******************* BUTTONS **************************//
export const SaveButton = styled.button`
  background-color: ${COLORS.orange};
  color: white;
  min-height: 30px;

  margin: 10px;
`;
export const NavButton = styled(SaveButton)`
  margin: 5px;
`;
export const NavButtonBlue = styled(SaveButton)`
  margin: 5px;
  background-color: ${COLORS.blue};
  border: solid 1;
`;
export const NavButtonLg = styled(SaveButton)`
  margin: 5px;
  background-color: ${COLORS.blue};
`;
export const AddButton = styled(SaveButton)`
  width: 150px;
`;
export const SaveButtonSm = styled(SaveButton)`
  width: 100px;
`;

export const CancelButtonSm = styled(SaveButtonSm)`
  background-color: ${COLORS.blue};
`;

export const CancelButton = styled(SaveButton)`
  background-color: ${COLORS.blue};
`;

export const BlueButtonSm = styled(SaveButton)`
  background-color: ${COLORS.blue};
  border-color: ${COLORS.blue};
`;

export const OrangeButtonSm = styled(SaveButton)`
  background-color: ${COLORS.orange};
  border-color: ${COLORS.orange};
`;

export const BlueInverseButtonSm = styled(SaveButton)`
  color: ${COLORS.blue};
  border: solid 1pt ${COLORS.blue};
  background-color: white;
`;
export const BlueButton = styled(SaveButton)`
  background-color: ${COLORS.blue};
  width: 150px;
`;

export const OrangeButton = styled(SaveButton)`
  background-color: ${COLORS.orange};
  width: 150px;
`;

//******************* LABELS **************************//

export const BlockLabel = styled.label`
  padding: 5px;
  font-size: 12px;
  background-color: ${COLORS.blue};
  color: white;
  margin-top: 30px;
  margin-bottom: 0;
  text-align: left;
  width: ${(props) => (props.short ? "30%" : "100%")};
  display: flex;
  flexdirection: column;
`;

export const BlockLabelHorizontal = styled.label`
  padding: 5px;
  font-size: 12px;
  background-color: ${COLORS.blue};
  color: white;

  text-align: left;

  display: flex;
  alignitems: center;
  max-height: 40px;
  margin-top: 15px;
  margin-right: 10px;
`;

export const BlockLabelGrow = styled.label`
  padding: 5px;
  font-size: 12px;
  background-color: ${COLORS.blue};
  color: white;
  margin-top: 30px;
  margin-bottom: 0;
  text-align: left;
  display: flex;
  flex: 1 1 100%;
`;

export const BlockLabelNoMargin = styled(BlockLabel)`
  margin: 0;
`;

export const BlockLabelDiv = styled.div`
  padding: 5px 20px;
  font-size: 12px;
  background-color: ${COLORS.blue};
  color: white;
  margin-top: 30px;
  margin-bottom: 0;
  text-align: center;
`;

export const BlockInput = styled.input`
  width: ${(props) => (props.short ? "30%" : "50%")};
`;

export const BlockInputLg = styled.input`
  width: 100%;
`;
// for listpages add e
const BoxDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  width: 200px;
`;

export const BoxWithLabel200 = (props) => {
  return (
    <BoxDiv className="BoxWithLabel200" style={{ minWidth: 200, maxWidth: 200, margin: 50 }}>
      <BlockLabel>{props.label}</BlockLabel>
      {props.children}
    </BoxDiv>
  );
};
export const BoxWithLabel = (props) => {
  return (
    <BoxDiv>
      <BlockLabel>{props.label}</BlockLabel>
      {props.children}
    </BoxDiv>
  );
};

export const BoxWithLabelW = (props) => {
  return (
    <BoxDiv style={{ minWidth: "100%" }}>
      <BlockLabel>{props.label}</BlockLabel>
      {props.children}
    </BoxDiv>
  );
};

export const BoxWithLabelNoM = (props) => {
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", ...(props.width && { width: props.width }) }}>
        <BlockLabel style={{ marginTop: 5 }}>{props.label}</BlockLabel>
        {props.children}
      </div>
    </>
  );
};

export const BoxWithLabel100 = (props) => {
  return (
    <div
      className="wrapperColumn"
      style={{
        display: "flex",
        flexDirection: "column",
        width: 100,
        flex: "1 1 auto",
      }}
    >
      <div
        className="label"
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: COLORS.lightblue,
          color: "white",
        }}
      >
        {props.label}
      </div>
      <div
        className="boxChildren"
        style={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

//******************* TABLES **************************//

export const StyledTableHeader = styled.div`
  min-height: 30px;
  background: ${COLORS.blue};
  color: white;
  width: 90%;
  fontsize: 24pt;
`;
export const StyledTh = styled.th`
  border: solid lightgrey 1pt;
  color: ${COLORS.blue};
`;

export const StyleTd = styled.td`
  border: solid lightgrey 1pt;
`;
export const TdNoBorder = styled.td`
  border: none;
  padding: 10px;
  border-bottom: solid lightgrey 1pt;
  font-size: 12px;
`;
export const Th = styled.th`
  border: none;
  border-bottom: solid lightgrey 1pt;
  color: ${COLORS.blue};
  padding: 10px;
  font-size: 12px;
`;

export const Td = styled.td`
  border: none;
  padding: 10px;
  border-bottom: solid lightgrey 1pt;
  color: #4d4d4d;
  font-size: 12px;
`;

export const StyledTr = styled.tr`
  background-color: ${(props) => (props.recent ? "#ffffb3" : "white")};
`;

//******************* TEXT **************************//
export const BlueText = styled.span`
  color: ${COLORS.blue};
`;

export const GreyHeaderText = styled.span`
  color: ${COLORS.textgrey};
  margin-top: 5px;
`;
export const LinkTextBlue = styled.span`
  color: #007bff;
`;

export const OrangeText = styled.span`
  color: ${COLORS.orange};
`;

export const GreyText = styled.span`
  color: darkgrey;
`;

export const PrimaryText = styled.div`
  font-family: Arial;
  font-weight: bold;
  font-size: 14px;
  color: ${COLORS.orange};
`;

export const PrimaryText18 = styled(PrimaryText)`
  font-size: 20px;
`;

export const PrimaryText30 = styled(PrimaryText)`
  font-size: 30px;
`;

export const PrimaryTextBlue = styled.div`
  font-family: Arial;
  font-weight: bold;
  font-size: 14px;
  color: ${COLORS.blue};
`;

export const PrimaryTextBlue20 = styled.div`
  font-family: Arial;
  font-weight: bold;
  font-size: 20px;
  color: ${COLORS.blue};
  margin-top: 20px;
`;

export const PrimaryTextBlue5m = styled(PrimaryTextBlue)`
  margin: 5px;
`;

export const PrimaryTextList = styled.div`
  font-family: Arial;

  font-size: 12px;
  color: #4d4d4d;
`;

export const TableText1 = styled.span`
  font-size: 10px;
  font-weight: bold;
`;

export const TableText2 = styled.div`
  font-size: 12px;
`;

export const SecondText = styled(PrimaryText)`
  font-size: 12px;
  line-height: 1.2;
  color: #535050;
`;
export const AccountHolderText = styled(SecondText)`
  color: ${COLORS.orange};
  padding-right: 10px;
`;

export const StudentText = styled(SecondText)`
  color: ${COLORS.blue};
  padding-right: 10px;
`;

export const StudentTextSpan = styled.span`
  color: ${COLORS.blue};
  padding-right: 10px;
`;

export const OrangeTextSpan = styled.span`
  color: ${COLORS.orange};
  padding-right: 10px;
`;

export const InstructorText = styled(SecondText)`
  color: #64c46c;
  padding-right: 10px;
`;

export const NameStringText = styled(PrimaryText)`
  color: ${COLORS.blue};
  font-size: 12px;
`;

export const HelpText = styled.span`
  font-size: 10px;
  font-style: italic;
  color: ${COLORS.orange};
`;

export const LinkText = styled.div`
  font-size: 10px;
  font-style: italic;
  color: ${COLORS.orange};
  margin: 5px;
  cursor: pointer;
`;

//******************* HEADERS **************************//

export const MediumPageHeader = styled.div`
  font-size: 18px;
  color: ${COLORS.blue};
  margin: 10px;
  font-weight: bold;
`;

export const PageTitle = styled.div`
  font-weight: bold;
  font-size: 30px;
  display: flex;
  flex: 1 1 100%;

  color: ${COLORS.orange};
`;

//******************* LISTS **************************//

export const StyledList = styled.li`
  list-style-type: none;
  padding-left: 5px;
  font-size: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
`;

export const StyledListNB = styled(StyledList)`
  list-style-type: none;
  padding-left: 5px;
  font-size: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  background: ${COLORS.blue};
  color: white;
`;

export const StyledListGroup = styled.ul`
  border: solid lightgrey 1pt;
  padding-left: 0;
`;

export const StyledListGroupHoriz = styled.ul`
  border: solid lightgrey 1pt;
  padding-left: 0;
  display: inline;
`;

export const StyledListGroupNB = styled.ul`
  border: none;
  padding-left: 0;
`;

export const StyledErrorMessage = styled.div`
  font-size: 12px;
  color: #ff0033;
  width: 400px;
  margin-top: 0.25rem;
`;

const dot = (isSelected) => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: isSelected ? COLORS.orange : "white",
    borderRadius: 2,
    content: '""',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const me = {
  minWidth: 150,
  maxWidthx: 150,
  maxHeight: 40,
  overflow: "visible",
};

const me2 = {
  minWidth: 100,
  maxWidthx: 100,
  maxHeight: 40,
  overflow: "visible",
};

export const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    ...me,
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      ...dot(isSelected),
      background: "white",
      color: isSelected ? COLORS.orange : COLORS.tablegrey,

      padding: 2,
      fontSize: 12,
      minWidth: 150,
      maxWidth: 150,
      minHeight: 20,
    };
  },
  singleValue: (styles) => ({ ...styles }),
  multiValue: (styles, { data }) => ({
    ...styles,
    order: 2,
    border: "none",
    background: "white",
    color: COLORS.orange,

    overflow: "visible",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: COLORS.orange,
    flex: "1 0 auto",
    overflow: "visible",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: COLORS.orange,
  }),

  placeholder: (styles, state) => ({
    ...styles,
  }),
  menuList: (styles) => ({
    ...styles,
    display: "flex",
    flexWrap: "wrap",
  }),
  menu: (styles) => ({
    ...styles,
    xleft: -100,
    top: "",
    width: 300,
    display: "flex",
    flexWrap: "wrap",
    zIndex: 9999,
  }),
  container: (provided, state) => ({
    ...provided,
    border: "none",
    minWidth: 150,
  }),
  valueContainer: (styles) => ({
    ...styles,
    overflowx: "visible",
  }),

  input: (styles) => ({
    ...styles,
    order: 1,
  }),
  indicatorsContainer: (styles) => ({
    ...styles,

    maxHeight: 40,
  }),
};

export const selectSkinny = {
  control: (styles, state) => ({
    ...styles,
    ...me2,
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      ...dot(isSelected),
      background: "white",
      color: isSelected ? COLORS.orange : COLORS.tablegrey,

      padding: 2,
      fontSize: 12,
      minWidth: 100,
      maxWidth: 100,
      minHeight: 20,
    };
  },
  singleValue: (styles) => ({ ...styles }),
  multiValue: (styles, { data }) => ({
    ...styles,
    order: 2,
    border: "none",
    background: "white",
    color: COLORS.orange,

    overflow: "visible",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: COLORS.orange,
    flex: "1 0 auto",
    overflow: "visible",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: COLORS.orange,
  }),

  placeholder: (styles, state) => ({
    ...styles,
  }),
  menuList: (styles) => ({
    ...styles,
    display: "flex",
    flexWrap: "wrap",
  }),
  menu: (styles) => ({
    ...styles,
    xleft: -100,
    top: "",
    width: 300,
    display: "flex",
    flexWrap: "wrap",
    zIndex: 9999,
  }),
  container: (provided, state) => ({
    ...provided,
    border: "none",
    minWidth: 100,
  }),
  valueContainer: (styles) => ({
    ...styles,
    overflowx: "visible",
  }),

  input: (styles) => ({
    ...styles,
    order: 1,
  }),
  indicatorsContainer: (styles) => ({
    ...styles,

    maxHeight: 40,
  }),
};
export const colourStyles2 = {
  ...colourStyles,
  control: (styles, state) => ({
    ...colourStyles2.control,
    border: "solid 5px red",
  }),
};

const whystyle = {
  maxWidth: 800,
  margin: "0 auto",
  columnWidth: 300,
  columnGap: 50,
  columnRule: "1px solid grey",
};

//**************  ASSIGNSTYLES ***************/

export const assignStyles = {
  control: (styles, state) => ({
    ...styles,
    maxWidth: 400,
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...dot(isSelected),
      background: "white",
      color: isSelected ? COLORS.orange : COLORS.tablegrey,

      padding: 2,
      marginBottom: 5,
      fontSize: 16,
      flex: "0 1",
      minHeight: 20,
      widthx: 150,
    };
  },
  multiValue: (styles, { data }) => ({
    ...styles,
    order: 2,
    border: "none",
    background: "white",
    color: COLORS.orange,

    overflow: "visible",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: COLORS.orange,
    flex: "1 0 auto",
    overflow: "visible",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: COLORS.orange,
  }),

  placeholder: (styles, state) => ({
    ...styles,
  }),
  menuList: (styles) => whystyle,
  menu: (styles) => ({}),
  container: (provided, state) => ({
    ...provided,
    border: "none",
    minWidth: 150,
  }),
  valueContainer: (styles) => ({
    ...styles,
  }),

  input: (styles) => ({
    ...styles,
    order: 1,
  }),
  indicatorsContainer: (styles) => ({
    display: "none",
  }),
};

export const addModaStyles = {
  control: (styles) => ({
    display: "none",
  }),
  menu: () => ({
    minHeight: "100%",
  }),
  menuList: () => ({
    minHeight: "100%",
  }),
};
