import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthHelperMethods from "./AuthHelperMethods";

const Auth = new AuthHelperMethods();

function PrivateRoute({ component: Component, ...rest }) {
  const checkV = async () => {
    let result = await Auth.api("vusers/checkUserVersion", "get");
    console.log("checkV");
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        Auth.loggedIn() ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { referer: props.location } }} />
        )
      }
    />
  );
}

export default PrivateRoute;
