//export const systemVersion = "3.12";

export const options_attendance = [
  //??not being used
  //current options
  { value: "SCH", label: "scheduled" },
  { value: "COM", label: "completed" },
  { value: "CWM", label: "cancelled with makeup" },
  { value: "CWP", label: "cancelled and added back to package" },
  { value: "CLC", label: "late cancel" },
  { value: "CNS", label: "no show" },
  { value: "CNA", label: "cancelled no action" },
  { value: "CWC", label: "cancel with invoice credit" },
];

export const cancel_student_options = [
  {
    value: "CLC",
    label: "mark as late cancel",
    help: "The client cancelled without the required notice. No credit issued for lesson.",
  },
  {
    value: "CNS",
    label: "mark as no show",
    help: "The client did not attend the lesson and did not give any notification.  No credit issued for lesson.",
  },
  {
    value: "CWP",
    label: "add lesson back to package",
    help: "The client gave sufficient notice and the lesson is credited by adding it back to the package.",
  },
  {
    value: "CNA",
    label: "no action",
    help: "Mark client as cancelled. Do not apply a credit.",
  },
  {
    value: "CWR",
    label: "refund payment",
    help: "Client has given sufficient notice and the lesson is credited by providing a payment refund.",
  },
  {
    value: "CWA",
    label: "issue single package lesson",
    help: "Similar to adding lesson back to package except it creates a new package of one lesson with a new expiry date. Use when client's current package expires soon, so they have a chance to book a lesson within the full number of days allowed.",
  },
  {
    value: "CWM",
    label: "issue makeup",
    help: "Client has given sufficient notice and a 'makeup' lesson credit will be issued. This credit is not automatically used to cover future lessons, but must be booked specifically.",
  },
  {
    value: "CWC",
    label: "issue credit",
    help: "Client has given sufficient notice.",
  },
];

export const cancel_by_barn_options = [
  { value: "BCWM", label: "cancelled with makeup" },
  { value: "BCAP", label: "add back to package" },
  { value: "BCWR", label: "issue refund" },
  { value: "BCNA", label: "no action" },
  { value: "BCWA", label: "issue single lesson package" },
];

export const lesson_cancel_resason_options = [
  { value: "WEA", label: "weather" },
  { value: "INS", label: "instructor illness" },
  { value: "PAN", label: "pandemic restrictions" },
  { value: "HOL", label: "holiday" },
  { value: "OTH", label: "other" },
];

export const available_options = [
  { value: "2", label: "Show All" },
  { value: "available", label: "Available" },
  { value: "full", label: "Full" },
];

export const show_cancel_options = [
  { value: "2", label: "Show All" },

  { value: "0", label: "Not Cancelled" },
  { value: "1", label: "Cancelled Only" },
];

export const active_options = [
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
  { value: "2", label: "All" },
];

export const errormsg = "Sorry, there has been an error sending the data.  Please try again.";

export function map_options_instructor(options) {
  return options.map((item) => ({
    value: item.id,
    label: item.name,
    color: item.color_code,
  }));
}

export const helpBooking = [
  "Click on the Orange Button that says 'Book Lesson' located under the student's name.",
  "To see availability, click on the date you are interested on the calendar.  You will see a list of lessons for that day with the available ones saying 'Click here to join'. ",
  "Scroll down the screen to find a time and instructor you are interested in.  You can also use the dropdown box at the top that says 'Instructor' to show only the instructor you are interested in.",
  "Once you click the lesson, the student will be added and you will see a message at the top of the screen confirming that.  You will also receive an email confirmation.  Please check your spam folder if you don't see the email in your inbox.",
  "To book another lesson for the same student, just repeat the steps above.",
  "To book a lesson for another student in your account, click the name of the student on the left hand side of the screen where all the students in the account are listed. (You do this from the same Book Lesson screen - you do not have to exit).",
];

const eventStyleGetter = (event) => {
  let newStyle = {
    color: "black",
    borderRadius: "0px",
    border: "none",
  };
  event.color = event.lesson_is_cancelled ? "lightgrey" : event.color;
  if (!event.id) {
    event.color = "yellow";
  }
  if (!event.instructor_id) {
    event.color = "white";
  }
  if (event.color) {
    newStyle.backgroundColor = event.color;
    newStyle.border = "solid 1px";
    newStyle.borderLeft = "solid 5px lightgrey";
    //newStyle.borderColor = event.color;
  }

  return {
    className: "",
    style: newStyle,
  };
};
