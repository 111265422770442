import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Link, useParams, useHistory, withRouter, useLocation } from "react-router-dom";
import { Row, Col, Modal, Accordion, Dropdown } from "react-bootstrap";
import { ListContext, MyContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import {
  BlueNavLink12,
  PrimaryText,
  PrimaryText18,
  PrimaryTextBlue,
  SaveButtonSm,
  CancelButtonSm,
  PageTitle,
  BlueText,
  HelpText,
} from "./styleCustom";
import { currentDayToDB, timeISOtoString, dateISOtoStringMed, is_expired, calculate_expiry_date } from "./momentUtils";
import { LessonTable } from "./lessonTable";
import { MemberLinks } from "./clientHomePage";
import { Limit } from "./formComponents";
import SearchFilterDates from "./searchFilterDates";

const Auth = new AuthHelperMethods();

export const History = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [makeups, setMakeups] = useState([]);
  const limit = 100;
  const [offset, setOffset] = useState(100);
  const [total, setTotal] = useState();
  // let changevar = listState.currentClient.id;
  let location = useLocation();
  const profileOnly = location.pathname.startsWith("/admin/calendarGroup/edit");

  //params = { ...params };
  useEffect(
    () => {
      let params = {
        student_id: listState.currentClient.student_id,
        today: currentDayToDB(),
        person_id: listState.currentClient.id,
        limit: profileOnly ? 10 : offset,
      };
      console.log("useEffect History");
      const fetchData = async () => {
        dispatch({ type: "FETCH_INIT" });

        try {
          const result = await Auth.api("students/lessonHistory", "get", params);
          dispatch2(["CLIENT_DATA", result.data, "history"]);
          dispatch({ type: "FETCH_SUCCESS" });
          setTotal(result.data.size);
          setMakeups(result.data.makeups);
        } catch (error) {
          dispatch({ type: "FETCH_ERROR", msg: error });
        }
      };
      if (params.student_id) fetchData();

      return () => {
        dispatch2(["CLIENT_DATA", [], "history"]);
      };
    },
    //eslint-disable-next-line
    [listState.currentClient.id, offset]
  );
  //  useFetch("students/lessonHistory", "CLIENT_DATA", "dispatchlist", params);
  //if (!listState.clientdata) return null;

  return (
    <>
      <div style={{ display: "flex", flex: "1 1 100%", flexWrap: "wrap" }}>
        <MemberLinks verb={"View"} page={"History"} link={"history"} />
      </div>
      <div style={{ display: "flex", flex: "1 1 100%" }}></div>
      {listState.clientdata && (
        <>
          <LessonTable {...props} lessons={listState.clientdata} makeups={makeups} canCancel />
        </>
      )}
      {!profileOnly && (
        <Limit total={total} setOffset={setOffset} data={listState.clientdata} offset={offset} limit={limit} />
      )}
    </>
  );
};

const ScheduledLessons = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const limit = 100;
  const [offset, setOffset] = useState(100);
  const [total, setTotal] = useState();
  const location = useLocation();
  const profileOnly = location.pathname.startsWith("/admin/calendarGroup/edit");
  const currentclientid = useParams().id;
  const [currentParams, setCurrentParams] = useState({});
  const [makeups, setMakeups] = useState([]);

  useEffect(
    () => {
      console.log("useeffect");

      let params = {
        student_id: listState.currentClient.student_id,
        today: currentDayToDB(),
        person_id: listState.currentClient.id,
        limit: profileOnly ? 10 : offset,
        //  ...(listState.filter.date1 && { date1: listState.filter.date1 }),
        //  ...(listState.filter.date2 && { date1: listState.filter.date2 }),
      };
      console.log(params);
      const fetchData = async () => {
        dispatch({ type: "FETCH_INIT" });

        try {
          const result = await Auth.api("students/lessonSchedule", "get", params);
          setTotal(result.data.size);
          dispatch2(["CLIENT_DATA", result.data, "schedule"]);
          dispatch({ type: "FETCH_SUCCESS" });
          setMakeups(result.data.makeups);
        } catch (error) {
          dispatch({ type: "FETCH_ERROR", msg: error });
        }
      };
      //   if (listState.filter.date1 && listState.filter.date2 && params.student_id) fetchData();
      ////   if (listState.filter.date1 && listState.filter.date2) console.log("dates ready");
      //  if (listState.filter.date1 && listState.filter.date2 && params.student_id)
      //  console.log("dates & student_id ready");
      // if (listState.filter.date1 && listState.filter.date2 && params.student_id) fetchData();

      if (params.student_id) fetchData();

      return () => {
        dispatch2(["CLIENT_DATA", []]);
      };
    },
    //eslint-disable-next-line
    [listState.currentClient.id, offset]
  );

  //if (!listState.clientdata) return null;
  return (
    <div style={{ display: "flex", flex: "1 1 100%", flexWrap: "wrap" }}>
      <MemberLinks verb={"View"} page={"Schedule"} link={"lessons"} />
      <div style={{ display: "flex", flex: "1 1 100%" }}></div>
      {listState.clientdata && (
        <>
          {" "}
          {<LessonTable lessons={listState.clientdata} makeups={makeups} {...props} />}
          {!profileOnly && (
            <Limit total={total} setOffset={setOffset} data={listState.clientdata} offset={offset} limit={limit} />
          )}
        </>
      )}
    </div>
  );
};

export default ScheduledLessons;
