import React, { useState, useContext, useEffect, lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Route,
  Switch,
  Link,
  useRouteMatch,
  useParams,
  useHistory,
  withRouter,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import { Inv } from "./client/inv";
import Horses from "./horses";
import Admin from "./admin";
import COLORS from "./styleConstants";
import { Container, LeftNavLink, PrimaryText, PrimaryTextBlue, HelpText } from "./styleCustom";
import { DaySheetView } from "./daySheetTack2";
import Url from "./url";
import Setup from "./setup";
import PrivateRoute from "./privateRoute";
import LeftNavBar from "./leftNavBar";
import Logout from "./logout";
import { MyContext } from "./App";
import { ListContext } from "./App";
import DefaultOptions from "./defaultOptions";
import Staff from "./staff";
import HorseHomePage from "./horseHomePage";
import StaffHomePage from "./staffHomePage";
import { Menu, MenuRight, MenuLeft, BackPage } from "./icons";
import Reports from "./reports";
import AuthHelperMethods from "./AuthHelperMethods";
import SuperUser from "./superUser";
import { UserCan } from "./formComponents";
import Payment from "./payment";
import Waiver from "./waiver";
import WaiverRetrieve, { WaiverRetrieveList, TemplateListRetrieve, DynamicTemplate } from "./waiverRetrieve";
import Toast from "react-bootstrap/Toast";
import PaymentSubscription from "./paymentSubscription";
import SectionAssignHorse from "./sectionAssignHorse";
import SectionAssignStudent from "./sectionAssignStudent";
import { QuestionIconNp } from "./icons";
import Help from "./help";
import moment from "moment";
import { todayDisplayDateTimeSec } from "./momentUtils";
import { Updates } from "./updates";
import { ClientNotesReview, HorseNotesReview, StaffNotesReview, NotesReview } from "./notesAdmin";
import Faq from "./faq";

const Auth = new AuthHelperMethods();
const CalendarGroup = lazy(() => import("./calendarGroup"));
const ClientHomePage = lazy(() => import("./clientHomePage"));
const ClientTable = lazy(() => import("./clientTable"));
const Whiteboard = lazy(() => import("./whiteboardMess"));
const EditEvent = lazy(() => import("./EditEvent"));
const AddModal = lazy(() => import("./addModal"));
const AddModalStudent = lazy(() => import("./addModalStudent"));

const AdmRoute = () => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);
  const [showLeftNav, setShowLeftNav] = useState(isMobile ? false : Auth.getUserType() == "STU" ? false : true);
  const [version, setVersion] = useState(2);
  const [update, setUpdate] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState("live");
  const tempversion = 1;
  const currentVersion = 2.23;
  let history = useHistory();

  if (Auth.getUserType() == "SPR") return <PrivateRoute path="/admin/superuser" component={SuperUser} />;

  const CheckSubcriptionStatus = () => {
    useEffect(
      () => {
        const fetchData = async () => {
          const result = await Auth.api("farms/getSubscriptionStatus", "get", {});
          if (result && result.data.subscriptionStatus == "over") setSubscriptionStatus("over");
          console.log(result.data);
        };
        fetchData();
      },
      //eslint-disable-next-line
      []
    );
    return subscriptionStatus == "live" ? (
      <div>{"OK"}</div>
    ) : (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          border: "solid 2pt orange",
          margin: 75,
          justifyContent: "center",
          flex: "1 1 100%",
          padding: 60,
        }}
      >
        <div style={{ fontSize: 20, color: COLORS.orange }}> {"Thank-you for trying Equine Office!"}</div>

        <div style={{ fontSize: 20, color: COLORS.orange }}> {"Your free trial has expired."}</div>

        <Link to={`/admin/setup/preferences/paymentSubscriptions`}>
          <div style={{ fontSize: 20 }}> {"To update to a paid subscription click here."}</div>
        </Link>
        <div style={{ fontSize: 14, fontStyle: "italic", color: COLORS.orange }}>
          {" "}
          {"Note: Only admins will see this message (and not clients)."}
        </div>
      </div>
    );
  };
  const checkLastUpdate = () => {
    return moment() > moment(mystate.farmDefaults.lastCheckM).add(4, "hours");
  };

  const Live = () => {
    if (
      !mystate.farmDefaults.eqo_subscription_id &&
      mystate.farmDefaults.eqo_stripe_customer_id &&
      mystate.farmDefaults.eqo_stripe_plan_id &&
      Auth.getUserType() == "ADM"
    ) {
      return (
        <Link to={`/admin/setup/preferences/paymentSubscriptions`}>
          You are currently using the 14 day free trial subscription. To update to a paid subscription click here
        </Link>
      );
    }
    return null;
  };

  const leftPadding = () => {
    if (isMobile) return 0;
    return 20;
  };

  const Unauthorized = () => {
    return <h1>{"Unauthorized"}</h1>;
  };
  return (
    <>
      {showLeftNav && (
        <>
          {" "}
          <div
            style={{
              flex: "0 0 10%",
              background: process.env.NODE_ENV == "production" ? COLORS.orange : COLORS.blue,
              paddingBottom: 200,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                color: "white",
                background: process.env.NODE_ENV == "production" ? COLORS.orange : COLORS.blue,
              }}
              onClick={() => setShowLeftNav(false)}
            >
              {" "}
              <MenuLeft />
              <Menu />
            </div>
            <LeftNavBar onClick={isMobile ? () => setShowLeftNav(false) : null} />
          </div>
        </>
      )}

      {
        <div
          className="85"
          style={{
            flex: isMobile ? "0 1 100%" : "0 1 85%",
            display: "flex",
            flexWrap: "wrap",
            alignContent: "flex-start",
            paddingBottom: 200,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: "1 1 100%",
              justifyContent: "flex-start",
            }}
          >
            {" "}
            {!mystate.defaultsLoaded && <DefaultOptions />}
            {!showLeftNav && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    color: "white",
                    background: COLORS.orange,
                    maxHeight: 40,
                    maxWidth: 30,
                    alignItems: "center",
                    flex: "1 1 100%",
                    flexWrap: "nowrap",
                  }}
                  onClick={() => setShowLeftNav(true)}
                >
                  {" "}
                  <Menu />
                  <MenuRight />
                </div>
              </>
            )}
            {Auth.getUserType() == "ADM" && (
              <div style={{ display: "flex", justifyContent: "flex-end", flex: "1 1 100%", alignItems: "flex-end" }}>
                <Link to={`/admin/updates`}>
                  <HelpText style={{ fontSize: 16, marginRight: 20 }}>{"NEW! Updates June 13"}</HelpText>
                </Link>

                <Link to={`/admin/help`}>
                  <HelpText style={{ fontSize: 16, marginRight: 5 }}>{"Help"}</HelpText>

                  <QuestionIconNp fontSize={30} />
                </Link>
              </div>
            )}
          </div>

          <Live />

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flex: "1 1 100%",
              paddingLeft: leftPadding(),
            }}
          >
            <div onClick={() => history.goBack()}>
              <BackPage />
            </div>{" "}
            <div
              className="centerThis"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                flexWrap: "wrap",
                flex: "1 1 100%",
                padding: 0,
              }}
            >
              {Auth.getUserType() == "ADM" && 1 == 2 && <CheckSubcriptionStatus />}
              <Suspense fallback={<div>Loading..</div>}>
                <Switch>
                  <PrivateRoute path="/admin/clienthome/:id" component={ClientHomePage} />
                  <PrivateRoute path={`/admin/add/:add`} component={AddModal} />
                  <PrivateRoute path={`/admin/calendarGroup/edit/:hid`} component={EditEvent} />

                  <PrivateRoute path="/admin/horsehome/:id" component={HorseHomePage} />

                  {Auth.getUserType() !== "STU" && (
                    <PrivateRoute path="/admin/staffhome/:id" component={StaffHomePage} />
                  )}

                  {Auth.getUserType() == "INS" && (
                    <>
                      <UserCan doThis={"STAFF_CAN_VIEW_CALENDAR"}>
                        <PrivateRoute path="/admin/calendarGroup" component={CalendarGroup} permission={"yes"} />
                      </UserCan>
                      <UserCan doThis={"STAFF_CAN_ACCESS_DAYSHEET"}>
                        <PrivateRoute path="/admin/daysheet" component={DaySheetView} permission={"yes"} />
                      </UserCan>
                      <UserCan doThis={"STAFF_CAN_ACCESS_WHITEBOARD"}>
                        <PrivateRoute path="/admin/whiteboard" component={Whiteboard} />
                      </UserCan>
                      <UserCan doThis={"STAFF_CAN_ACCESS_HORSE_LIST"}>
                        <PrivateRoute path="/admin/horses" component={Horses} permission={"yes"} />
                      </UserCan>
                      <UserCan doThis={"STAFF_CAN_ACCESS_STUDENT_LIST"}>
                        <PrivateRoute path="/admin/students" component={ClientTable} />
                      </UserCan>
                      <UserCan doThis={"STAFF_CAN_ACCESS_ACCOUNT_HOLDER_LIST"}>
                        <PrivateRoute path="/admin/accounts" component={ClientTable} />
                      </UserCan>
                      <UserCan doThis={"STAFF_CAN_VIEW_GENERAL_REPORTS"}>
                        <PrivateRoute path="/admin/reports" component={Reports} />
                      </UserCan>
                      <UserCan doThis={"STAFF_CAN_VIEW_HORSE_AND_CLIENT_NOTES_PAGE"}>
                        <PrivateRoute path="/admin/notesReview" component={NotesReview} />
                      </UserCan>
                    </>
                  )}

                  {Auth.getUserType() == "ADM" && (
                    <>
                      <PrivateRoute path="/admin/calendarGroup" component={CalendarGroup} permission={"yes"} />

                      <PrivateRoute path="/admin/daysheet" component={DaySheetView} permission={"yes"} />
                      <PrivateRoute path="/admin/staff" component={Staff} />
                      <PrivateRoute path="/admin/bulk" component={DaySheetView} />

                      <PrivateRoute path="/admin/students" component={ClientTable} />
                      <PrivateRoute path="/admin/accounts" component={ClientTable} />

                      <PrivateRoute path={`/admin/addStudent/:add`} component={AddModalStudent} />

                      <PrivateRoute path="/admin/whiteboard" component={Whiteboard} />
                      <PrivateRoute path="/admin/horses" component={Horses} />
                      <PrivateRoute path="/admin/setup" component={Setup} />
                      <PrivateRoute path="/admin/admin" component={Admin} />
                      <PrivateRoute path="/admin/reports" component={Reports} />

                      <PrivateRoute path="/admin/url" component={Url} />
                      <PrivateRoute
                        path="/admin/setup/preferences/paymentSubscriptions"
                        component={PaymentSubscription}
                      />

                      <PrivateRoute path="/admin/sectionHorses/:id" component={SectionAssignHorse} />
                      <PrivateRoute path="/admin/sectionStudents/:id" component={SectionAssignStudent} />
                      <PrivateRoute path={`/admin/help`} component={Help} />
                      <PrivateRoute path={`/admin/updates`} component={Updates} />
                      <PrivateRoute path="/admin/faq" component={Faq} />
                    </>
                  )}
                  <Route path="/public/unauthorized/" component={Unauthorized} />
                </Switch>
              </Suspense>
            </div>
          </div>
        </div>
      }
    </>
  );
};
export default AdmRoute;
