import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { ListContext } from "./App";
import { MyContext } from "./App";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Row, Col } from "react-bootstrap";
import {
  BlueButtonSm,
  ContainerV,
  ContainerH,
  HelpText,
  BoxWithLabel,
  BlockLabel,
  colourStyles,
  faArrowLeft,
  BoxWithLabelNoM,
} from "./styleCustom";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  currentMonthStart,
  currentMonthEnd,
  nextMonthStart,
  nextMonthEnd,
  previousMonthStart,
  previousMonthEnd,
  dateObjToDBDate,
  currentDayToDB,
  dateObjToDisplayDate,
  currentMonthLabel,
  nextMonthLabel,
  previousMonthLabel,
  DBDATEFORMAT,
} from "./momentUtils";
import { DATEPICKER_DATE_DISPLAY } from "./UserSettings";
import {
  DISPLAY_DATETIME,
  DISPLAY_DATE,
  DISPLAY_TIME,
  DISPLAY_DATE_SHORT,
  DISPLAY_DATE_MED,
  DISPLAY_DATE_NO_YEAR,
  DISPLAY_TIME_NO_MINUTES,
} from "./UserSettings";
import { enrollment_options } from "./Constants2";
import COLORS, { reportStyles, square } from "./styleConstants";
import "./style.css";
import { ButtonOrange, ButtonOrangeInverse } from "./formComponents";
import { Circle, CircleSolid, MenuRight, MenuLeft } from "./icons";

export const SelectMonth = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  var datefield1;
  var datefield2;
  var defaultValue;
  const selectonlyoptions = [
    { value: "all", label: "select" },
    { value: "current", label: "Current Month" },
    { value: "next", label: "Next Month" },
    { value: "previous", label: "Previous Month" },
  ];

  const getMonthValues = (whatMonth) => {
    if (whatMonth === "next") {
      props.first(nextMonthStart);
      props.second(nextMonthEnd);
    }
    if (whatMonth === "previous") {
      props.first(previousMonthStart);
      props.second(previousMonthEnd);
    }
    if (whatMonth === "current") {
      props.first(currentMonthStart);
      props.second(currentMonthEnd);
    }
  };
  return (
    <>
      <Select
        onChange={(selectedOption) => getMonthValues(selectedOption.value)}
        options={selectonlyoptions}
        defaultValue={defaultValue}
        styles={colourStyles}
      />
      {1 == 2 && !props.hidedate && (
        <div
          className="textdate"
          style={{
            display: "flex",
            flex: "1 1 100%",
            fontSize: "20",
            color: COLORS.orange,
          }}
        >
          <div>
            {`${props.first && dateObjToDisplayDate(props.first)}
to 
      ${props.second && dateObjToDisplayDate(props.second)}
          `}
          </div>
        </div>
      )}
    </>
  );
};

const SearchFilterDate = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);

  const [term, setTerm] = useState("");
  const [date1, setDate1] = useState();
  const [date2, setDate2] = useState();
  const [singleDate, setSingleDate] = useState(props.initial);
  const [showCustomDates, setShowCustomDates] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [highlighted, setHighlighted] = useState([]);
  const widthVal = 100;
  const [reset, setRest] = useState();
  const [programValue, setProgramValue] = useState();
  const datefield1 = props.datefield1 || "date1";
  const datefield2 = props.datefield2 || "date2";
  const defaultV = props.default || "current";
  const [circleValue, setCircleValue] = useState(props.circleValue || "current");
  const [weekArray, setWeekArray] = useState([]);

  const options = [
    { value: "all", label: "select" },
    { value: "current", label: currentMonthLabel },
    { value: "next", label: "Next Month" },
    { value: "previous", label: "Previous Month" },
    { value: "custom", label: "Custom Date Range" },
  ];

  const shortOptions = [
    { value: "previous", label: previousMonthLabel },

    { value: "current", label: currentMonthLabel },
    { value: "next", label: nextMonthLabel },
    { value: "weekly", label: "Weekly" },
    { value: "today", label: "Today" },
    { value: "custom", label: "Custom" },
  ];

  const selectonlyoptions = [
    { value: "all", label: "select" },
    { value: "current", label: "Current Month" },
    { value: "next", label: "Next Month" },
    { value: "previous", label: "Previous Month" },
  ];

  //const [defaultValue, setDefaultValue] = useState({});
  const defaultValue = shortOptions.find((item) => item.value == defaultV);

  useEffect(
    () => {
      console.log(" useeffect searchfilterdates begin");

      if (!props.single) getMonthValues(defaultV || null);
      if (props.single) dispatch2(["SET_SEARCH_FILTER_DATES", dateObjToDBDate(moment().toDate()), "dbday"]);
      if (props.singleboth)
        dispatch2([
          "SET_BOTH_SEARCH_FILTER_DATES",
          dateObjToDBDate(moment().toDate()),
          dateObjToDBDate(moment().toDate()),
        ]);

      if (props.weekonly) changeWeek();

      // dispatch2(["SET_SEARCH_FILTER_DATES", dateObjToDBDate(moment().toDate()), "dbday"]);
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      return () => {
        console.log(" @@@@@@@@@@@@@@@@  return RESET_SEARCH_FILTER_EMPTY useeffect @@@@@@@@@@@@@");
        console.log(datefield1);
        console.log(datefield2);

        dispatch2(["DELETE_FILTER_KEY", datefield1]);
        dispatch2(["DELETE_FILTER_KEY", datefield2]);

        // dispatch2(["RESET_SEARCH_FILTER_EMPTY"]);
      };
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      let date = moment().toDate();
      setWeekArray([
        moment(date).startOf("week").toDate(),
        moment(date).startOf("week").add(1, "days").toDate(),
        moment(date).startOf("week").add(2, "days").toDate(),
        moment(date).startOf("week").add(3, "days").toDate(),
        moment(date).startOf("week").add(4, "days").toDate(),
        moment(date).startOf("week").add(5, "days").toDate(),
        moment(date).startOf("week").add(6, "days").toDate(),
      ]);
    },
    //eslint-disable-next-line
    []
  );

  const CancelRow = (props) => {
    const { mystate, dispatch } = useContext(MyContext);
    const chooseSelection = (id) => {
      console.log(id);

      props.handleClick(id);
    };
    return (
      <div
        className="rowCancel"
        style={{
          minHeight: 10,
          minWidth: 50,

          display: "flex",
          marginBottom: 10,
          marginRight: 15,
        }}
        onClick={() => chooseSelection(props.id)}
      >
        <div
          className="circle"
          style={{
            display: "flex",
            alignItems: "flex-start",
            paddingRight: 5,
            paddingTop: 4,
          }}
        >
          {props.selected == props.id ? <CircleSolid color={COLORS.orange} /> : <Circle />}
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
          <div
            style={{
              fontSize: 16,
              color: props.selected == props.id ? COLORS.orange : COLORS.textDark,
              display: "flex",
            }}
          >
            {props.title}
          </div>
        </div>
      </div>
    );
  };

  const updateSelection = (selectedOption, filterType) => {
    console.log(selectedOption);
    var selectedValue;

    dispatch2(["SET_SEARCH_FILTER_DATES", selectedOption, filterType]);
  };
  const [dopen, setDOpen] = useState(true);

  const changeSingle = (date) => {
    setDOpen(true);
    setSingleDate(date);
    dispatch2(["SET_SEARCH_FILTER_DATES", dateObjToDBDate(date), "dbday"]);
    dispatch2(["SET_BOTH_SEARCH_FILTER_DATES", dateObjToDBDate(date), dateObjToDBDate(date)]);
  };
  const changeWeek = (date) => {
    if (!date) date = moment().toDate();
    let currentWeekStart = moment(date).startOf("week").format(DBDATEFORMAT);
    let currentWeekEnd = moment(date).endOf("week").format(DBDATEFORMAT);
    setWeekArray([
      moment(date).startOf("week").toDate(),
      moment(date).startOf("week").add(1, "days").toDate(),
      moment(date).startOf("week").add(2, "days").toDate(),
      moment(date).startOf("week").add(3, "days").toDate(),
      moment(date).startOf("week").add(4, "days").toDate(),
      moment(date).startOf("week").add(5, "days").toDate(),
      moment(date).startOf("week").add(6, "days").toDate(),
    ]);

    console.log(weekArray);

    // setDOpen(true);
    setSingleDate(date);
    dispatch2(["SET_SEARCH_FILTER_DATES_NEW", dateObjToDBDate(currentWeekStart), datefield1]);
    dispatch2(["SET_SEARCH_FILTER_DATES_NEW", dateObjToDBDate(currentWeekEnd), datefield2]);
    setDate1(currentWeekStart);
    setDate2(currentWeekEnd);
  };

  const changeDate1 = (date) => {
    setDate1(date);
    if (moment(date).isSameOrBefore(moment(listState.filter[datefield2]))) {
      console.log("before");
      console.log(date2);
      dispatch2(["SET_SEARCH_FILTER_DATES_NEW", dateObjToDBDate(date), datefield1]);
    } else alert("start date must be before end date");
  };
  const changeDate2 = (date) => {
    console.log(listState.filter[datefield1]);
    console.log(date);

    if (moment(date).isBefore(moment(listState.filter[datefield1]))) {
      alert("end date must be after start date");
    } else {
      // setDate2(date);
      // dispatch2(["SET_SEARCH_FILTER_DATES_NEW", dateObjToDBDate(date1), datefield1]);
      dispatch2(["SET_SEARCH_FILTER_DATES_NEW", dateObjToDBDate(date), datefield2]);
      //  dispatch2(["SET_SEARCH_FILTER_ON"]);
    }
  };

  const handleClick = (w) => {
    console.log(w);
    setCircleValue(w);
    getMonthValues(w);
  };
  const getMonthValues = (whatMonth) => {
    console.log("whatMonth");
    console.log(whatMonth);

    if (whatMonth === "next") {
      console.log(nextMonthStart);
      console.log(nextMonthEnd);
      console.log(datefield1);
      console.log(datefield2);

      dispatch2(["SET_SEARCH_FILTER_DATES", nextMonthStart, datefield1]);
      dispatch2(["SET_SEARCH_FILTER_DATES", nextMonthEnd, datefield2]);
    }
    if (whatMonth === "previous") {
      dispatch2(["SET_SEARCH_FILTER_DATES", previousMonthStart, datefield1]);
      dispatch2(["SET_SEARCH_FILTER_DATES", previousMonthEnd, datefield2]);
    }
    if (whatMonth === "current") {
      dispatch2(["SET_SEARCH_FILTER_DATES", currentMonthStart, datefield1]);
      dispatch2(["SET_SEARCH_FILTER_DATES", currentMonthEnd, datefield2]);
    }
    if (whatMonth === "all") {
      dispatch2(["SET_SEARCH_FILTER_DATES", null, datefield1]);
      dispatch2(["SET_SEARCH_FILTER_DATES", null, datefield2]);
    }
    if (whatMonth === "today") {
      dispatch2(["SET_SEARCH_FILTER_DATES", currentDayToDB(), datefield1]);
      dispatch2(["SET_SEARCH_FILTER_DATES", currentDayToDB(), datefield2]);
    }
    if (whatMonth === "custom") {
      setShowCustomDates(true);
    } else {
      setShowCustomDates(false);
    }
    //  dispatch2(["SET_SEARCH_FILTER_ON"]);

    //  dispatch2(["SET_SEARCH_FILTER_DATES", currentMonthStart, "date1"]);
    //  dispatch2(["SET_SEARCH_FILTER_DATES", currentMonthEnd, "date2"]);
  };
  const doNothing = () => {};
  const NoInput = () => <div />;
  const clearFilter = () => {
    dispatch2(["RESET_SEARCH_FILTER"]);
    setProgramValue([]);
  };
  const DayNavigation = ({ value, onClick }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div onClick={navigateDayPrevious} style={{ cursor: "pointer" }}>
        <MenuLeft color={COLORS.orange} />
      </div>
      <ButtonOrangeInverse onClick={onClick} label={"Select Day"} />
      <div onClick={navigateDayNext} style={{ cursor: "pointer" }}>
        <MenuRight color={COLORS.orange} />
      </div>
    </div>
  );

  const navigateDayNext = () => {
    console.log("navigateDayNext");
    let date = moment(singleDate).add(1, "days").toDate();
    changeSingle(date);
  };

  const navigateDayPrevious = () => {
    let date = moment(singleDate).subtract(1, "days").toDate();
    changeSingle(date);
  };

  if (props.single || props.singleboth) {
    return (
      <div
        style={{
          display: "flex",

          justifyContent: "flex-start",
          maxWidth: 200,
        }}
      >
        <HelpText>{props.help}</HelpText>
        <div
          style={{
            display: "flex",
            flexDirection: "column",

            width: 200,
          }}
        >
          {!props.hidelabel && <BlockLabel style={{ marginTop: 5 }}> {props.label || "day"}</BlockLabel>}
          <DatePicker
            autoFocus={props.autoFocus || false}
            selected={singleDate || moment().toDate()}
            onChange={changeSingle}
            placeholderText={"Choose Day"}
            dateFormat={DATEPICKER_DATE_DISPLAY}
            shouldCloseOnSelect={props.shouldCloseOnSelect}
            open={props.stayOpen}
            customInput={<DayNavigation />}
            showMonthDropdown
            showYearDropdown
          />
        </div>
      </div>
    );
  } //todo i don't think above is used - double check //default value hard coded to current

  if (props.weekonly) {
    return (
      <div
        className="weeklyCalendar"
        style={{
          display: "flex",
          alignItems: "flex-end",
          marginLeft: 10,
          maxHeight: 200,
        }}
      >
        <DatePicker
          autoFocus={props.autoFocus || true}
          selected={singleDate}
          onChange={changeWeek}
          highlightDates={weekArray}
          placeholderText={"Choose Day"}
          dateFormat={DATEPICKER_DATE_DISPLAY}
          onFocus={(e) => (e.target.readOnly = true)}
          shouldCloseOnSelect={false}
          startOpen
          customInput={<NoInput />}
          showPopperArrow={false}
        />
      </div>
    );
  }

  /* if (props.selectonly) {
    return (
      <Select
        onChange={(selectedOption) => getMonthValues(selectedOption.value)}
        options={selectonlyoptions}
        defaultValue={defaultValue}
        styles={colourStyles}
      />
    );
  }
*/ const clearDateField = () => {
    props.closeThis(false);
  };

  return (
    <>
      <div
        className="datehere"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          minWidth: 160,
        }}
      >
        <>
          {!props.plain && (
            <div style={{ display: "flex", flexWrap: "wrap", flex: "1 1 100%" }}>
              <div style={{ display: "flex", flexDirection: "column" }} className="notsingle">
                <HelpText>{props.help}</HelpText>
                <BlockLabel style={{ marginTop: 5 }}>
                  <div style={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
                    <span>{props.label || "day"} </span>
                    {props.clear && (
                      <span onClick={() => clearDateField()} style={{ fontWeight: "bold", fontSize: 20 }}>
                        {"X"}
                      </span>
                    )}
                  </div>
                </BlockLabel>
                {props.select && (
                  <Select
                    onChange={(selectedOption) => getMonthValues(selectedOption.value)}
                    options={options}
                    defaultValue={defaultValue}
                    styles={reportStyles}
                  />
                )}
                <div style={{ display: "flex", flex: "1 1 100%" }}>
                  {shortOptions.map((option) => (
                    <CancelRow
                      key={option.value}
                      id={option.value}
                      title={option.label}
                      selected={circleValue}
                      handleClick={handleClick}
                    />
                  ))}
                </div>
              </div>
              <div className="below" style={{ display: "flex", flexDirection: "column" }}>
                {circleValue == "weekly" && (
                  <div
                    className="weeklyCalendar"
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      marginLeft: 10,
                      maxHeight: 250,
                    }}
                  >
                    <DatePicker
                      autoFocus={props.autoFocus || true}
                      selected={singleDate}
                      onChange={changeWeek}
                      highlightDates={weekArray}
                      placeholderText={"Choose Day"}
                      dateFormat={DATEPICKER_DATE_DISPLAY}
                      onFocus={(e) => (e.target.readOnly = true)}
                      shouldCloseOnSelect={false}
                      startOpen
                      customInput={<NoInput />}
                      showPopperArrow={false}
                    />
                  </div>
                )}
                {showCustomDates && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      marginLeft: 10,
                    }}
                  >
                    <DatePicker
                      selected={moment(listState.filter[datefield1]).toDate()}
                      onChange={changeDate1}
                      placeholderText={"Choose Start Date"}
                      dateFormat={DATEPICKER_DATE_DISPLAY}
                      onFocus={(e) => (e.target.readOnly = true)}
                      showYearDropdown
                      showMonthDropdown
                    />

                    <DatePicker
                      selected={moment(listState.filter[datefield2]).toDate()}
                      onChange={changeDate2}
                      placeholderText={"Choose End Date"}
                      dateFormat={DATEPICKER_DATE_DISPLAY}
                      onFocus={(e) => (e.target.readOnly = true)}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      </div>
      {listState.filter[datefield1] && !props.hidedate && (
        <div
          className="textdate"
          style={{
            display: "flex",
            flex: "1 1 100%",
            fontSize: "20",
            color: COLORS.orange,
          }}
        >
          <div>
            {`${listState.filter[datefield1] && dateObjToDisplayDate(listState.filter[datefield1])}
 to 
        ${listState.filter[datefield2] && dateObjToDisplayDate(listState.filter[datefield2])}
            `}
          </div>
        </div>
      )}{" "}
    </>
  );
};

export default SearchFilterDate;
