import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, Link, useParams, Switch, useLocation } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import AuthHelperMethods from "./AuthHelperMethods";
import SearchFilter, { ModalFilter } from "./searchFilter";
import { ListContext, MyContext } from "./App";
import { PrimaryTextBlue, PageTitle, BlueInverseButtonSm, PrimaryText30, Th, Td } from "./styleCustom";
import SearchFilterDates from "./searchFilterDates";
import moment from "moment";
import Pagination from "react-js-pagination";
import { CSVLink, CSVDownload } from "react-csv";
import { Dropdown } from "react-bootstrap";
import Select from "react-select";
import { setNestedObjectValues } from "formik";
import { Desc, Asc } from "./icons";
import COLORS from "./styleConstants";
import {
  dateObjToDisplayDate,
  dateISOtoString,
  dateObjToDBDate,
  DBDATEFORMAT,
  timeISOtoString,
  timeISOtoStringN,
  timeISOtoStringUtc,
} from "./momentUtils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { result } from "lodash";
import { Limit } from "./formComponents";
import { HorseGreen, HorseSleep } from "./images";

const Auth = new AuthHelperMethods();

export const WorkloadGraphic = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const report = useParams();

  const [data, setData] = useState([]);
  const [dataw, setDataw] = useState([]);

  const [headers, setHeaders] = useState();
  let wholewordarray = [];
  //const [wholearray, setWholearray] = useState([]);
  let wholearray = [];
  const [go, setGo] = useState([]);
  const [goWord, setGoWord] = useState([]);

  useEffect(
    () => {
      let date = moment().toDate();
      let currentWeekStart = moment(date).startOf("week").format(DBDATEFORMAT);
      let currentWeekEnd = moment(date).endOf("week").format(DBDATEFORMAT);
      dispatch2(["SET_SEARCH_FILTER_DATES_NEW", dateObjToDBDate(currentWeekStart), "date1"]);
      dispatch2(["SET_SEARCH_FILTER_DATES_NEW", dateObjToDBDate(currentWeekEnd), "date2"]);
    }, //eslint-disable-next-line
    []
  );
  useEffect(
    () => {
      const fetchData = async () => {
        dispatch({ type: "FETCH_INIT" });

        let params = { ...listState.filter };
        const result = await Auth.api("reports/workloadgraphic", "get", params);
        if (result) {
          let horsestoshow = [];
          setData(result.data.a);
          setDataw(result.data.graphicword);
          setHeaders(result.data.dateheaders);
          //console.log(result.data.graphicword);
          if (listState.filter.horse)
            horsestoshow = mystate.options_horse.filter((horses) => listState.filter.horse.includes(horses.value));
          else horsestoshow = mystate.options_horse;
          horsestoshow &&
            horsestoshow.map((horse) => {
              //   console.log(horse.label);
              let horsearray = [horse.label];
              let wordarray = [horse.label];
              result.data.a.map((weekday) => {
                let obj = weekday.find((el) => el.horse_id == horse.value);
                if (obj) horsearray = [...horsearray, obj.totla];
                else horsearray = [...horsearray, 0];
              });
              if (props.word) {
                result.data.graphicword.map((day, index) => {
                  let obj = day.filter((el) => el.horse_id == horse.value);
                  let all = [];
                  if (obj) {
                    obj.map((el) => {
                      all = [...all, el.name];
                    });

                    wordarray = [...wordarray, all];
                  }
                });
              }

              wholearray = [...wholearray, horsearray];
              wholewordarray = [...wholewordarray, wordarray];
            });
          console.log(wholearray);
          console.log(wholewordarray);

          setGo(wholearray);
          setGoWord(wholewordarray);

          // console.log(go);
        }
        dispatch({ type: "FETCH_SUCCESS" });
      };
      if (listState.filter.date1 && listState.filter.date2) fetchData();
    },
    //eslint-disable-next-line
    [listState.filter]
  );
  if (!mystate.options_horse) return null;
  //if (wholearray.length == 0) return null;
  return (
    <>
      <div style={{ minHeight: 250 }}>
        <SearchFilterDates label={"Reporting Period"} default={"weekly"} circleValue={"weekly"} />
      </div>
      <table style={{ borderCollapse: "collapse" }}>
        <thead>
          <th>{"HORSE"}</th>
          {headers &&
            go.length > 1 &&
            headers.map((day) => (
              <th style={{ textAlign: "center" }}>
                <div> {moment(day).format("ddd")}</div>
                <div> {moment(day).format("D")}</div>
              </th>
            ))}
        </thead>
        <tbody>
          {report.report == "graphic" &&
            go.map((horseweekarray) => (
              <tr>
                {horseweekarray.map((weekday, index) =>
                  index == 0 ? (
                    <td style={{ border: "none", borderBottom: "1px solid lightgrey" }}>{weekday}</td>
                  ) : (
                    <td style={{ border: "none", borderBottom: "1px solid lightgrey", textAlign: "center" }}>
                      {weekday > 0 ? (
                        weekday > 1 ? (
                          weekday > 2 ? (
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                              <HorseGreen />
                              <HorseGreen />
                              <span style={{ color: COLORS.orange, fontWeight: "bold", fontSize: 12 }}>{`+ ${
                                weekday - 2
                              }`}</span>
                            </div>
                          ) : (
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <HorseGreen /> <HorseGreen />
                            </div>
                          )
                        ) : (
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            {" "}
                            <HorseGreen />
                          </div>
                        )
                      ) : (
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                          <HorseSleep />
                        </div>
                      )}
                    </td>
                  )
                )}
              </tr>
            ))}
          {report.report == "numbers" &&
            go.map((horseweekarray) => (
              <tr>
                {horseweekarray.map((weekday, index) =>
                  index == 0 ? (
                    <td style={{ border: "none", borderBottom: "1px solid lightgrey" }}>{weekday}</td>
                  ) : (
                    <td style={{ border: "none", borderBottom: "1px solid lightgrey", textAlign: "center" }}>
                      {weekday}
                    </td>
                  )
                )}
              </tr>
            ))}

          {props.word &&
            goWord.map((horseweekarray) => (
              <tr>
                {horseweekarray.length > 0 &&
                  horseweekarray.map((weekday, index) =>
                    index == 0 ? (
                      <td style={{ border: "none", borderBottom: "1px solid lightgrey" }}>{weekday}</td>
                    ) : (
                      <td style={{ border: "none", borderBottom: "1px solid lightgrey", textAlign: "center" }}>
                        {weekday.map((lesson, index) => (
                          <div>{`${index + 1}. ${lesson}`}</div>
                        ))}
                      </td>
                    )
                  )}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

const ReportTable = ({ ...props }) => {
  const [data, setData] = useState([]);
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  const [total, setTotal] = useState();
  const [activePage, setActivePage] = useState(1);
  //const [offset, setOffset] = useState(0);
  const [offset, setOffset] = useState(100);
  // const [total, setTotal] = useState();
  const [itemsCountPerPage, setItemsCountPerPage] = useState(1000);
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [changeData, setChangeData] = useState(true);

  const [sortBy, setSortBy] = useState();
  const [direction, setDirection] = useState(true);

  let firsturl = "";
  let urlparams = useParams();
  let location = useLocation();
  if (location.pathname.includes("enrollment")) firsturl = "enrollment";
  if (location.pathname.includes("workload")) firsturl = "workload";
  if (location.pathname.includes("lessonStaff")) firsturl = "lessonStaff";
  if (location.pathname.includes("studentStaff")) firsturl = "studentStaff";

  if (location.pathname.includes("financial")) firsturl = "financial";
  if (location.pathname.includes("lessons")) firsturl = "lessons";

  //console.log(a.find((field) => field.label == "student cancel").value);
  /*
  const findHeader = (fieldIn) => {
    let title = a.find((field) => field.value == fieldIn);
    if (title) return title.label;
    return fieldIn;
  };

  const findFieldName = (headerIn) => {
    let fieldName = a.find((field) => field.label == headerIn);
    if (fieldName) return fieldName.value;
    return headerIn;
  };
*/
  const changeSort = (sortIn) => {
    console.log(sortIn);
    // console.log(findFieldName(sortIn));
    // setSortBy(findFieldName(sortIn));
    setSortBy(sortIn);
    setDirection(!direction);
    //  fetchData(sortIn, direction);
  };

  const SortImg = (props) => {
    if (sortBy == props.sortIn) return <> {direction ? <Desc /> : <Asc />}</>;
    return null;
  };

  /* useEffect(() => {
    // setSortBy();
    console.log(sortBy);
  }, [urlparams.report, sortBy]);
*/

  const fetchData = async (sortIn) => {
    let params = { ...listState.filter, ...urlparams };
    params.billing_person_id = listState.accountHolder.billing_person_id;
    params.sortBy = sortIn;
    console.log(sortIn);
    params.direction = direction ? "desc" : "asc";
    params.limit = offset;

    try {
      dispatch({ type: "FETCH_INIT" });

      const result = await Auth.api(`reports/${firsturl}/`, "get", params);
      console.log(result.data.reports);

      // result.data.reports.map((obj) => (Object.keys(obj).includes("title") ? { ...obj, extra: obj.title } : obj)); //? this doesn't do anything as it is not assigned to new variable ?
      let x = result.data.reports.map(({ id, ...obj }) => obj);
      // if firsturl="enrollment" and params.report=="detail" //! student detail//

      // arr.map(({ password, ...rest }) => rest);

      //console.log(result.data.reports);

      //   Object.keys(row).map((key) => key == "scheduled_date" && row[key]
      //  console.log(newdata);
      // console.log(x);

      //  x = x.map((obj) => (Object.keys(obj).includes("time") ? { ...obj, time: timeISOtoString(obj.date) } : obj));

      setData(x);
      //  console.log(data);
      // result.data.reports.forEach((obj) => console.log(Object.keys(obj)));
      // setHeaders(eval(`${firsturl}_headers`)[`${urlparams.report}`]);
      //setRows(eval(firsturl)[`${urlparams.report}`]);

      if (result.data.totals) setTotal(result.data.totals);

      if (result)
        dispatch({
          type: "FETCH_SUCCESS",
        });
    } catch (error) {
      console.log(error);

      dispatch({
        type: "FETCH_ERROR",
      });
    }
  };

  useEffect(
    () => {
      console.log("UE plain");
      if (listState.filter.date1 && listState.filter.date2) fetchData();
    },

    //eslint-disable-next-line
    [listState.filter, urlparams.report, offset, sortBy, direction]
  );

  const dateText = `${listState.filter.date1 && dateObjToDisplayDate(listState.filter.date1)}
  to 
  ${listState.filter.date2 && dateObjToDisplayDate(listState.filter.date2)}
`;
  if (1 == 2) return null;
  const StudentDetail = (props) => {
    let data = props.data;
    const headers = ["student", "date", "time", "lesson", "cancellation status", "action", "code"];
    return (
      <>
        <thead>
          <tr>
            {headers.map((header) => (
              <Th>{header}</Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((row) => (
              <tr>
                <Td>{row.person_name}</Td>
                <Td>{dateISOtoString(row.scheduled_date)}</Td>
                <Td>{timeISOtoStringUtc(row.scheduled_starttime)}</Td>

                <Td>{row.name}</Td>
                <Td>
                  {row.lesson_is_cancelled == 1 && "Cancelled by Barn"}
                  {row.lesson_is_cancelled != 1 && row.student_is_cancelled == 1 && "Cancelled by Student"}{" "}
                </Td>
                <Td>{row.enrollment_status_name}</Td>

                <Td>{row.code}</Td>
              </tr>
            ))}
        </tbody>
      </>
    );
  };
  const HorseDetail = (props) => {
    let data = props.data;
    const headers = ["horse", "date", "time", "lesson", "canceled"];
    return (
      <>
        <thead>
          <tr>
            {headers.map((header) => (
              <Th>{header}</Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((row) => (
              <tr>
                <Td>{row.horse_name}</Td>
                <Td>{dateISOtoString(row.scheduled_date)}</Td>
                <Td>{timeISOtoString(row.scheduled_starttime)}</Td>

                <Td>{row.name}</Td>
                <Td>{row.lesson_is_cancelled == 1 && "Cancelled by Barn"}</Td>
              </tr>
            ))}
        </tbody>
      </>
    );
  };
  const StaffLessonDetail = (props) => {
    let data = props.data;
    const headers = ["staff", "date", "time", "lesson", "canceled"];
    return (
      <>
        <thead>
          <tr>
            {headers.map((header) => (
              <Th>{header}</Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((row) => (
              <tr>
                <Td>{row.staff}</Td>
                <Td>{dateISOtoString(row.scheduled_date)}</Td>
                <Td>{timeISOtoString(row.scheduled_starttime)}</Td>

                <Td>{row.name}</Td>
                <Td>{row.lesson_is_cancelled == 1 && "Cancelled by Barn"}</Td>
              </tr>
            ))}
        </tbody>
      </>
    );
  };

  const StaffStudentDetail = (props) => {
    let data = props.data;
    const headers = ["staff", "date", "time", "lesson", "student", "canceled"];
    return (
      <>
        <thead>
          <tr>
            {headers.map((header) => (
              <Th>{header}</Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((row) => (
              <tr>
                <Td>{row.person_name}</Td>
                <Td>{dateISOtoString(row.scheduled_date)}</Td>
                <Td>{timeISOtoString(row.scheduled_starttime)}</Td>

                <Td>{row.name}</Td>
                <Td>{row.student}</Td>

                <Td>{row.lesson_is_cancelled == 1 && "Cancelled by Barn"}</Td>
              </tr>
            ))}
        </tbody>
      </>
    );
  };
  return (
    <div
      className="reportTable"
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      <div
        className="reportTitle"
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "space-between",
          flex: "1 1 100%",
        }}
      >
        <PrimaryText30> {`${firsturl} report`}</PrimaryText30>

        <BlueInverseButtonSm>
          <CSVLink data={(listState.filter_labels, data)} filename={`${firsturl} ${urlparams.report} ${dateText}.csv`}>
            Download CSV
          </CSVLink>
        </BlueInverseButtonSm>
      </div>
      <div style={{ display: "flex", flex: "1 1 100%" }}>
        <SearchFilterDates label={"Reporting Period"} hidedate />
      </div>
      <div style={{ display: "flex", flex: "1 1 100%" }}></div>

      <div style={{ display: "flex", flex: "1 1 100%" }}>
        {listState.filter.date1 && (
          <div
            className="textdate"
            style={{
              display: "flex",
              flex: "1 1 100%",
              fontSize: "20",
              color: COLORS.orange,
            }}
          >
            <div>{dateText}</div>
          </div>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div></div>
        <div className="filter desc">
          {Object.entries(listState.filter_labels).map(([key, value]) => {
            return (
              <div>
                <span style={{ color: COLORS.orange }}>{`${key}: `}</span>
                <span style={{ color: COLORS.textDark }}>{`${value.join(", ")}  `}</span>
              </div>
            );
          })}
        </div>
        {Object.entries(listState.filter_labels).length > 0 && (
          <div
            onClick={() => dispatch2(["RESET_SEARCH_FILTER_EXCEPT_DATES"])}
            style={{ color: COLORS.blue, cursor: "pointer" }}
          >
            clear all filters
          </div>
        )}
      </div>
      <div
        style={{
          marginTop: 20,
          display: "flex",
          flex: "1 1 100%",
          flexDirection: "column",
        }}
      >
        <table style={{ width: "100%" }}>
          {urlparams.report == "detail" && firsturl == "workload" && <HorseDetail data={data} />}
          {urlparams.report == "detail" && firsturl == "enrollment" && <StudentDetail data={data} />}
          {urlparams.report == "detail" && firsturl == "lessonStaff" && <StaffLessonDetail data={data} />}
          {urlparams.report == "detail" && firsturl == "studentStaff" && <StaffStudentDetail data={data} />}
        </table>
        {urlparams.report != "detail" && (
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                {data.length > 0 &&
                  Object.keys(data[0]).map((key) => (
                    <Th>
                      {" "}
                      {key} <SortImg sortIn={data[0][key]} />{" "}
                    </Th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {data.length > 0 &&
                data.map((row) => (
                  <tr>
                    {Object.keys(row).map((key) => (
                      <Td>
                        {key == "date"
                          ? dateISOtoString(row[key])
                          : key == "time"
                          ? timeISOtoString(row[key])
                          : row[key]}
                      </Td>
                    ))}{" "}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: 50,
          }}
        >
          {total && parseFloat(total) > 0 && <PrimaryTextBlue>{total && `Total: ${total}`}</PrimaryTextBlue>}
        </div>
        <Limit total={total} setOffset={setOffset} data={data} offset={offset} />
      </div>
    </div>
  );
};

export default ReportTable;
