import React, { useReducer, Fragment, useState, useEffect, useContext } from "react";
import ReactDom from "react-dom";
import moment from "moment";
import Settings from "./UserSettings";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import RepeatingDates from "./repeatingDates";
import { DISPLAY_DATETIME, DISPLAY_DATE, DISPLAY_TIME, DATEPICKER_DISPLAY } from "./UserSettings";
import { MyContext, ListContext } from "./App";
import { Row, Col, Container, Modal, ListGroup, Button } from "react-bootstrap";
import {
  dateObjToDBDate,
  dateObjToDBTime,
  dateObjToDBDateTime,
  formatForDb,
  formatDateTimeForDbEdit,
  calculateEndTime,
  dateObjToDisplayDatetime,
  dateObjToDisplayDate,
  dateISOtoString,
  timeISOtoString,
} from "./momentUtils";
import { blueCheck } from "./images";
import { CheckSquare, Square } from "./icons";
import messageMap from "./messageMap";

const noteOpenStyle = {
  maxHeight: 200,
  transition: "max-height .5s",
  display: "flex",
  flexDirection: "column",
  opacity: 1,
};
const noteClosedStyle = {
  maxHeight: 0,
  transition: "max-height .5s",
  display: "flex",
  flexDirection: "column",

  opacity: 0,
};

const Notifications = ({ setNotificationMessageOnBlur = () => {}, setSendNotification = () => {} }) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const [message, setMessage] = useState("");

  //const [check, setCheck] = useState(mystate.sendNotification);
  const [check, setCheck] = useState(false);

  const successmsgpart = () => {
    let lessonIn = mystate.currentLesson;
    return (
      `  for ${lessonIn.title}  on ${dateISOtoString(lessonIn.scheduled_date)}  at  ${timeISOtoString(
        lessonIn.scheduled_starttime
      )}` || ""
    );
  };

  const lessonInfoMessage = () => {
    let lessonIn = mystate.currentLesson;
    return `${lessonIn.title}  on ${dateISOtoString(lessonIn.scheduled_date)}  at  ${timeISOtoString(
      lessonIn.scheduled_starttime
    )}`;
  };

  const from = () => {
    let rescheduledFrom = mystate.dateTimeComponent.is_allday
      ? dateObjToDisplayDate(mystate.currentLesson.start)
      : dateObjToDisplayDatetime(mystate.currentLesson.start);
    return rescheduledFrom;
  };

  const to = () => {
    let rescheduledTo = mystate.dateTimeComponent.is_allday
      ? dateObjToDisplayDate(mystate.dateTimeComponent.start)
      : dateObjToDisplayDatetime(mystate.dateTimeComponent.start);
    return rescheduledTo;
  };

  const handleInputChange = (e) => {
    console.log(e.target.value);
    dispatch({ type: "UPDATE_NOTIFICATION_MESSAGE", message: e.target.value });
    setMessage(e.target.value);
    // setNotificationMessage(e.target.value);
  };

  const handleOnBlur = () => {
    setNotificationMessageOnBlur(message);
  };

  const notificationMessageMap = {
    reschedule: "Your lesson has been rescheduled from " + from() + " to " + to(),
    cancel: `${lessonInfoMessage()}  has been canceled`,
    editInstructor: `Instructor has been changed from ${mystate.currentLessonOld.instructor_name || ""} to ${
      mystate.currentLesson.instructor_name
    } for ${lessonInfoMessage()}`,
    editLocation: `Location has been changed from ${mystate.currentLessonOld.location_name || ""} to ${
      mystate.currentLesson.location_name
    } for ${lessonInfoMessage()}`,
    bulkCancel: `Lessons have been cancelled for ${dateISOtoString(listState.filter.date1)}.`,
    editDateandtime: "Your lesson has been changed from " + from() + " to " + to(),
    editSection: `Lesson has been changed from ${mystate.currentLessonOld.section_name} to ${mystate.currentLesson.section_name}`,
    editNotes: `Lesson notes from ${mystate.currentLesson.title} on ${dateObjToDisplayDatetime(
      mystate.currentLesson.start
    )}:  ${mystate.currentLesson.lesson_notes}`,
  };

  useEffect(() => {
    dispatch({
      type: "UPDATE_NOTIFICATION_MESSAGE",
      message: notificationMessageMap[mystate.editAction],
    });
    setMessage(notificationMessageMap[mystate.editAction]);
    dispatch({
      type: "SET_BULK_NOTIFICATION_MESSAGE",
      message: "Lesson has been cancelled",
    });
  }, [
    mystate.dateTimeComponent.start,
    mystate.currentLesson.section_name,
    mystate.currentLesson.location_name,
    mystate.currentLesson.instructor_name,
    mystate.currentLesson.lesson_notes,
  ]);

  const handleNotificationChange = () => {
    console.log("click  handleNotificationChange");
    setSendNotification(!check);
    dispatch({
      type: "SEND_NOTIFICATION",
      notify: !check,
    });
    setCheck(!check);
  };

  return (
    <>
      <div
        className="part1"
        style={{
          marginTop: "30px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "flex-start",
          flex: "1 1 100%",
        }}
      >
        <div xs={3} onClick={() => handleNotificationChange()}>
          {check ? <CheckSquare fontSize={34} /> : <Square fontSize={34} />}
        </div>
        <div style={{ marginLeft: 10, alignItems: "center", display: "flex", fontSize: 16 }}>
          {"Do you wish to notify clients and staff? "}
        </div>
      </div>

      <div className="part2" style={check ? noteOpenStyle : noteClosedStyle}>
        <div style={{ minWidth: 400, display: "flex", marginTop: 20 }}>{"Notification Message (click to edit) "}</div>

        <>
          <textarea
            autoFocus
            style={{ minWidth: "100%", minHeight: 100, maxHeight: 100, resize: "none" }}
            onChange={(e) => handleInputChange(e)}
            value={mystate.updated_notification_message}
            onBlur={() => handleOnBlur()}
          />
        </>
      </div>
    </>
  );
};

export default Notifications;
