import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { MediumPageHeader, OrangeButton, BoxWithLabelW, HelpText, PrimaryText18 } from "./styleCustom";
import {
  MyTextInput,
  MyTextAreaInput,
  FormRightSave,
  TextArea,
  TextInput,
  CheckBox,
  TextDisplay,
  ButtonOrange,
  ButtonBlue,
} from "./formComponents";
import { errormsg } from "./Constants2";
//import "./styleForm.css";
//import "./styleFormik.css";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col } from "react-bootstrap";
import { ListContext } from "./App";
import { MyContext } from "./App";
import { useAlert } from "react-alert";
import { useHistory, useParams } from "react-router-dom";
import { BrowserRouter, Route, Switch, Link, NavLink } from "react-router-dom";
import SelectColor from "./selectColor";
import { EditIconPlain } from "./icons";
import Select from "react-select";
import { customColors } from "./styleConstants";
import { AccountPage, Contact } from "./accountPage";
import ContactForm from "./contactForm";
import PrivateRoute from "./privateRoute";
import { XrefSelect } from "./xrefs";
import { StaffAvatar } from "./images";

const Auth = new AuthHelperMethods();

export const DetailDisplay = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  let staff = { ...listState.currentStaff };
  let cur = useParams();
  let currpersonoid = cur.person_id;

  //
  if (!staff) return null;
  return (
    <>
      <div
        style={{
          display: "flex",
          flex: "1 1 100%",
          justifyContent: "center",
          flexDirection: "row",
          borderx: "solid red",
          flexWrap: "wrap",
        }}
      >
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1 1 ",
            }}
          >
            <Link
              style={{ marginLeft: 10, marginTop: 3 }}
              to={`/admin/staffhome/${listState.currentStaff.person_id}/edit`}
            >
              <EditIconPlain fontSize={"20px"} />
            </Link>

            <TextDisplay label={"Availability"} data={staff.availability} />
            <TextDisplay label={"General Notes 1"} data={staff.general_notes} />
            <TextDisplay label={"General Notes 2"} data={staff.general_notes2} />
            <TextDisplay label={"General Notes 3"} data={staff.general_notes3} />
          </div>
        </>

        <PrivateRoute path={`${props.match.url}/contact/`} component={ContactForm} />
      </div>
    </>
  );
};

export const StaffColor = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [options_colors, set_options_colors] = useState([]);
  const [colorId, setColorId] = useState(listState.currentStaff.color_id || null);

  let staff = { ...listState.currentStaff };

  useEffect(() => {
    const fetchData = async () => {
      const result = await Auth.api("colors/index", "get");
      console.log(result.data.colors);

      set_options_colors(
        result.data.colors.map((color) => ({
          label: color.color_name,
          value: color.id,
          other: color.id,
          color: color.color_code,
        }))
      );
    };
    fetchData();
  }, []);

  const changeColor = async (selected) => {
    // let currentStaff = { ...listState.currentStaff, color_id: selected.value };
    //dispatch2(["UPDATE_STAFF_COLOR", currentStaff]);
    dispatch2(["UPDATE_STAFF_COLORS", selected]);
    let params = { id: listState.currentStaff.instructor_id, color: selected.value };
    const result = await Auth.api("instructors/updateColor", "post", params);
  };

  return (
    <Select
      onChange={(selected) => changeColor(selected)}
      className="form-control"
      options={options_colors}
      clearable={true}
      searchable={false}
      placeholder={"Select Color..."}
      styles={customColors}
      value={options_colors.filter((option) => option.color == listState.currentStaff.color_code)}
    />
  );
};

const StaffFormEdit = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [isEditing, setIsEditing] = useState(false);
  const [options_colors, set_options_colors] = useState([]);
  let history = useHistory();
  let staff = { ...listState.currentStaff };

  const [colorId, setColorId] = useState(listState.currentStaff.color_id || null);
  console.log(listState.currentStaff.id);
  console.log(options_colors);

  console.log(options_colors.filter((color) => color.value == listState.currentStaff.color_id));
  const alert = useAlert();
  //
  //console.log(addoredit);
  useEffect(
    () => {
      /*    let horsearray = Object.entries(listState.currentHorse);

      let formInitialValues = horsearray.map(
        horse => `${horse[0]}: ${horse[1]}`
      );
      dispatch2(["SET_FORM_INITIAL_VALUES", listState.currentHorse]);*/

      let formInitialValues = {
        id: staff.instructor_id,
        color: staff.color_id || "",
        availability: staff.availability || "",
        general_notes: staff.general_notes || "",
        general_notes2: staff.general_notes2 || "",
        general_notes3: staff.general_notes3 || "",
      };
      dispatch2(["SET_FORM_INITIAL_VALUES", formInitialValues, "edit"]);
      // dispatch2(["STUDENT_DETAIL_SET", result.data.student]);
    },
    //eslint-disable-next-line
    [listState.currentStaff]
  );

  useEffect(() => {
    const fetchData = async () => {
      const result = await Auth.api("colors/index", "get");
      console.log(result.data.colors);

      set_options_colors(
        result.data.colors.map((color) => ({
          label: color.color_name,
          value: color.id,
          other: color.id,
          color: color.color_code,
        }))
      );
    };
    fetchData();
  }, []);

  const changeColor = async (selected) => {
    let currentStaff = { ...listState.currentStaff, color_id: selected.value };
    dispatch2(["UPDATE_STAFF_COLOR", currentStaff]);
    let params = { id: listState.currentStaff.id, color: listState.currentStaff.selected.value };
    const result = await Auth.api("instructors/update", "post", params);
  };

  // if (!staff) return null;

  return (
    <>
      <PrimaryText18>{isEditing && "Editing "} Staff Details</PrimaryText18>
      <StaffColor />
      {!isEditing && (
        <div style={{ marginLeft: 10, marginTop: 3 }} onClick={() => setIsEditing(true)}>
          <EditIconPlain fontSize={"20px"} />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flex: "1 1 100%",
          marginBottom: 20,
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: "1 1 100%",
            justifyContent: "space-between",
          }}
        >
          <MediumPageHeader></MediumPageHeader>
        </div>

        <Formik
          initialValues={listState.formInitialValues}
          enableReinitialize
          validationSchema={Yup.object({
            availability: Yup.string(),
            general_notes: Yup.string(),
            general_notes2: Yup.string(),
            general_notes3: Yup.string(),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            //  e.preventDefault();
            //options_colors values.color = listState.selectedColor.value;

            dispatch({ type: "FETCH_INIT" });

            try {
              const result = await Auth.api("instructors/update", "post", values);
              dispatch2(["STAFF_EDIT", result.data]);
              dispatch({ type: "FETCH_SUCCESS" });
              setSubmitting(false);
              dispatch2(["STAFF_GET", result.data]);
              history.push("/admin/staff");
            } catch (error) {
              console.log(error);
              dispatch({ type: "FETCH_ERROR" });
              alert.show(errormsg, { timeout: 5000, type: "error" });
            }
            //  dispatch2(["CLOSE_ADD_EDIT_FORM"]);
          }}
        >
          <Form
            id="staffForm"
            style={{
              display: "flex",
              flex: "1 1 100%",
              justifyContent: "space-evenly",
              alignItems: "flexStart",
              flexWrap: "wrap",
              margin: 30,
            }}
          >
            <div
              style={{
                display: "flex",
                flex: "1 1 100%",
                flexDirection: "column",
              }}
            >
              <MediumPageHeader>{"Edit Staff Details"}</MediumPageHeader>
            </div>
            <div
              style={{
                display: "flex",
                flex: "1 1 100%",
              }}
            >
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 50,
                    width: 600,
                    border: "solid 15px lightgrey",
                    padding: 20,
                  }}
                >
                  <BoxWithLabelW label={"Color"}>
                    <Select
                      onChange={(selected) => changeColor(selected)}
                      className="form-control"
                      options={options_colors}
                      clearable={true}
                      searchable={false}
                      placeholder={"Select Color..."}
                      styles={customColors}
                      value={options_colors.filter((option) => option.value == listState.currentStaff.color_id)}
                    />
                  </BoxWithLabelW>
                  <TextInput name="availability" label="Availability" />

                  <TextArea label={"General Notes 1"} name="general_notes" />
                  <TextArea label={"General Notes 2"} name="general_notes2" />
                  <TextArea label={"General Notes 3"} name="general_notes3" />
                  <div style={{ display: "flex", justifyContent: "space-around", marginTop: 20 }}>
                    <ButtonBlue onClick={() => history.goBack()} label={"Cancel"} />

                    <ButtonOrange formId={"staffForm"} type="submit" label={"Save"} />
                  </div>
                </div>
              </>
            </div>
          </Form>
        </Formik>
      </div>{" "}
    </>
  );
};
export default StaffFormEdit;
