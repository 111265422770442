import React, { useState, useContext, Fragment } from "react";
import ReactDOM from "react-dom";

import { MyContext, ListContext } from "./App";
import moment from "moment";
import { ListGroup, Modal, Row, Col, Container, Button } from "react-bootstrap";
import {
  valueFromId,
  currentDayToDB,
  currentTimeToDB,
  dateISOtoStringMed,
  timeISOtoString,
  datetimeISOtoStringOnAt,
} from "./momentUtils";
import Select from "react-select";
import AuthHelperMethods from "./AuthHelperMethods";
import { CalendarStateButton } from "./perm.js";
import { BlueCheckFuture, BlueCheckBox, BlueCheckBoxFutureOne, CheckImg } from "./blueCheckFuture";
import {
  horseAvatar,
  clientAvatar,
  staffAvatar,
  locationAvatar,
  deleteImg,
  privateNoteImg,
  publicNoteImg,
} from "./images";
import { BlueText, HelpText, PrimaryTextBlue } from "./styleCustom";
import { TimesIcon, QuestionIconNp, Check, Next } from "./icons";
import { TitleRow } from "./blueCheckFuture";
import COLORS from "./styleConstants";
import { cancel_student_options } from "./Constants2";
import { dateObjToDBDate } from "./momentUtils";
import Settings from "./UserSettings";
const Auth = new AuthHelperMethods();
const buttonstyle = {
  backgroundColor: COLORS.orange,
  padding: " 2 2",
  textAlign: "middle",
  color: "white",
  minHeight: "35px",
  maxHeight: 35,
  minWidth: "100px",
  margin: 0,
};

const InfoModal2 = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);

  const [addNoteType, setAddNoteType] = useState(false);
  const [addingNote, setAddingNote] = useState(false);
  const [currentNoteInput, setCurrentNoteInput] = useState("");
  const [emailNote, setEmailNote] = useState(false);
  const [test, setTest] = useState("");

  //const [isStudent, setIsStudent] = useState(true);
  const [message, setMessage] = useState("");
  let params = {};

  const getNext = () => {
    if (mystate.currentEditIsStudentNotHorse) {
      if (currentNoteInput) saveNotes();
      setCurrentNoteInput("");

      setMessage("");
      let x = mystate.currentLesson.lessonStudents.findIndex(
        (student) => student.student_id == mystate.currentStudent.student_id
      );
      let nextStudent = mystate.currentLesson.lessonStudents[x + 1];
      if (!nextStudent) alert("That was the last student");
      else {
        dispatch({
          type: "EDIT_STUDENT",
          data: nextStudent,
        });
      }
    } else {
      if (currentNoteInput) saveNotes();
      setMessage("");

      let x = mystate.currentLesson.lessonHorses.findIndex((horse) => horse.horse_id == mystate.currentHorse.horse_id);

      //  .map((value, index) => console.log(index));

      //    console.log(x);
      //  console.log(x + 1);
      let nextHorse = mystate.currentLesson.lessonHorses[x + 1];
      if (!nextHorse) alert("That was the last horse");
      else {
        dispatch({
          type: "EDIT_HORSE",
          data: nextHorse,
        });
      }
    }
    //  console.log(x.next());
  };

  const handleClose = () => {
    console.log("handleClose");
    setMessage("");
    dispatch({ type: "HIDE_INFO_MODAL2" });
  };

  const handleFinishNote = () => {
    console.log("handleFinishNote");
    saveNotes();
    setCurrentNoteInput("");
    setMessage("");
    dispatch({ type: "HIDE_INFO_MODAL2" });
  };

  const cond = false;

  const getCommonParams = () => {
    let x = {
      student_id: mystate.currentStudent.student_id,
      id: mystate.currentStudent.id,
      lesson_date_time_id: mystate.currentLesson.id,
      lesson_date_time_series_id: mystate.currentLesson.lesson_date_time_series_id,
      applyToFuture: mystate.applyToFuture,
      scheduled_date: mystate.currentLesson.scheduled_date,
      msgHead: `Regarding ${mystate.currentLesson.lessonString}`,
    };
    return x;
  }; /*if( mystate.currentStudent.student_id) student_id: mystate.currentStudent.student_id ,
    id: mystate.currentStudent.id,
    applyToFuture: mystate.applyToFuture
  };*/

  const handleHorseChange = (e) => {
    let params = {
      ...getCommonParams(),
      horse_id: e.horse_id,
    };

    //  //debugger;
    const result = Auth.api("lesson_people/updateHorseInStudentLesson", "post", params);

    dispatch({
      type: "ASSIGN_HORSE_TO_STUDENT",
      studentId: mystate.currentStudent.student_id,
      horseId: e.horse_id,
      horseName: e.name,
    });
    setMessage(`New horse assignment has been saved ${endOfMessage}`);
  };

  const endOfMessage = mystate.applyToFuture ? " for this and future events." : " for this event.";

  const assignHorseDropdown = () => {
    let lhwithvalue = mystate.currentLesson.lessonHorses.map((horseitem) =>
      mystate.currentLesson.lessonStudents.map((item) => item.horse_id).includes(horseitem.horse_id)
        ? { ...horseitem, isDisabled: true }
        : { ...horseitem, isDisabled: false }
    );

    lhwithvalue = lhwithvalue.map((lessonhorse) => ({
      ...lessonhorse,
      value: lessonhorse.horse_id,
      label: lessonhorse.name,
    }));

    lhwithvalue.unshift({ label: "unassigned", value: 0 });
    return lhwithvalue;
  };

  let options_variable = assignHorseDropdown();

  //  const url = mystate.addevent.url;
  const id = mystate.addevent.id;
  const lessonStudentsString = "students ";

  const showCalendarState = () => {
    // //console.log(createNameString(mystate.currentLessonStudents));
    //console.log("current Calendar state is ");
    //console.log(mystate);
    //  //console.log(mystate.addevent);*/
  };

  const handleClickPaid = async () => {
    let paid = !mystate.currentStudent.paid;
    //  paid = paid ? "true" : "false";
    let params = {
      ...getCommonParams(),
      paid: paid,
    };

    const result = await Auth.api("lesson_people/updatePaid", "post", params);
    dispatch({ type: "MARK_STUDENT_PAID", paid: paid });
    setMessage(`Paid status has been changed ${endOfMessage}`);
  };

  const handleNoteInputChange = (e) => {
    setCurrentNoteInput(e.target.value);
    // setTest(e.target.value);
  };

  const AddNote = (ispublic) => {
    setAddingNote(true);
    setAddNoteType(ispublic);
  };

  const saveNotes = async () => {
    console.log(currentNoteInput);
    console.log(addNoteType);

    //  e.stopPropagation();
    console.log(currentNoteInput);
    // setAddingNote(false);

    let noteRegardingLesson = ` regarding ${mystate.currentLesson.lessonString} `;

    /*let noteRegardingLesson = ` regarding ${mystate.currentLesson.name} ${datetimeISOtoStringOnAt(
      mystate.currentLesson.scheduled_date,
      mystate.currentLesson.scheduled_starttime,
      mystate.currentLesson.is_allday
    )}`;
*/
    if (currentNoteInput) {
      if (mystate.currentEditIsStudentNotHorse && currentNoteInput) {
        let params = {
          ...getCommonParams(),
          notes: currentNoteInput,
          is_public: addNoteType ? true : false,
          date_created_at: currentDayToDB(),
          time_created_at: currentTimeToDB(),
          emailNote: emailNote,
          //noteRegardingLesson: noteRegardingLesson,
        };
        const result = await Auth.api("notes_clients/createNoteFromLesson", "post", params);
        dispatch({
          type: "UPDATE_AFTER_SAVING_STUDENT_NOTE",
          studentNotes: result.data.studentNotes,
        });
      }

      if (!mystate.currentEditIsStudentNotHorse) {
        console.log("in horse");
        let params = {
          ...getCommonParams(),
          notes: currentNoteInput,
          horse_id: mystate.currentHorse.horse_id,
          is_public: addNoteType ? true : false,
          date_created_at: currentDayToDB(),
          time_created_at: currentTimeToDB(),
        };
        const result = await Auth.api("notes_horses/create", "post", params);
        dispatch({
          type: "UPDATE_AFTER_SAVING_HORSE_NOTE",
          horseNotes: result.data.horseNotes,
        });
      }
      setCurrentNoteInput("");
      setMessage(`New note has been saved ${endOfMessage}`);
    }
  };

  const Note = (props) => {
    const { listState, dispatch2 } = useContext(ListContext);
    const { mystate, dispatch } = useContext(MyContext);
  };

  const removeNote = async (noteId) => {
    console.log(noteId);
    let params = {
      ...getCommonParams(),
      id: noteId,
    };
    let horseOrStudentNotes = mystate.currentEditIsStudentNotHorse ? "notes_clients" : "notes_horses";
    const result = await Auth.api(`${horseOrStudentNotes}/delete`, "post", params);
    dispatch({
      type: mystate.currentEditIsStudentNotHorse ? "DELETE_STUDENT_LESSON_NOTE" : "DELETE_HORSE_LESSON_NOTE",
      id: noteId,
    });
    setMessage(`Note has been deleted ${endOfMessage}`);
  };

  if (!mystate.currentStudent) {
    return null;
  }

  const ListNotes = (props) => {
    /*console.log(props.note);
    console.log("note.id");
    console.log(props.note.id);
    console.log("note.notes");
    console.log(props.note.notes);*/
    let note = props.note;

    return (
      <ListGroup.Item key={note.id}>
        <Row>
          <Col xs={2}>
            <img
              alt={"public or private"}
              style={{ maxHeight: "15px" }}
              src={note.is_public ? publicNoteImg : privateNoteImg}
            />
          </Col>
          <Col xs={9}>{note.notes}</Col>
          <Col xs={1} style={{ padding: "5px" }} onClick={(e) => removeNote(note.id)}>
            <img alt={"delete"} src={deleteImg} />
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  const assignTackToHorse = async (e) => {
    let params = {
      lesson_date_time_horse_id: mystate.currentHorse.id,
      tack_id: e.value,
      horse_id: mystate.currentHorse.horse_id,
      lesson_date_time_id: mystate.currentLesson.id,
    };
    const result = await Auth.api("lesson_date_time_horses/addTackToHorse", "post", params);
    console.log(result.data);
    dispatch({
      type: "ASSIGN_TACK_TO_HORSE",
      lessondatetimehorseid: mystate.currentHorse.id,
      tackId: e.value,
      tackName: e.label,
    });
  };

  const assignTackDropdown = () => {
    let lhwithvalue = mystate.options_tack.map((tackitem) =>
      mystate.currentLesson.lessonHorses.map((item) => item.tack_id).includes(tackitem.value)
        ? { ...tackitem, isDisabled: true }
        : { ...tackitem, isDisabled: false }
    );

    /* if (lhwithvalue.length == 0)
      return <div>"There are no horses assigned to the lesson"</div>;*/

    lhwithvalue.unshift({ label: "unassigned", value: 0 });
    return lhwithvalue;
  };

  let options_tack_v = assignTackDropdown();

  return (
    <>
      <Modal show={mystate.showInfoModal2} size="md" onHide={handleClose}>
        <Modal.Header style={{ backgroundColor: COLORS.blue }}>
          <TitleRow
            title={mystate.currentEditIsStudentNotHorse ? mystate.currentStudent.name : mystate.currentHorse.name}
            clickevent={() => handleClose()}
          />
        </Modal.Header>

        <Modal.Body>
          {mystate.currentLesson.is_recuring && (
            <div
              style={{
                fontWeight: "bold",
                color: COLORS.orange,
                textAlign: "center",
                width: "100%",
              }}
            >
              {" "}
              Changes will be applied to {mystate.applyToFuture ? "this and all future events." : "this event only."}
            </div>
          )}
          <div
            style={{
              fontWeight: "bold",
              color: COLORS.orange,
              textAlign: "center",
              width: "100%",
            }}
          >
            {"Changes on this  page are saved immediately. You don't have to press a Save button to finish"}
          </div>
          <div
            style={{
              color: "blue",
              minHeight: "20px",
              textAlign: "center",
              width: "100%",
            }}
          >
            {" "}
            {message}{" "}
          </div>
          {mystate.currentEditIsStudentNotHorse && (
            <>
              {1 == 2 && (
                <ListGroup.Item>
                  <div className="blocklabel" style={{ marginTop: 10 }}>
                    PAID
                  </div>
                  <Row noGutters style={{ border: `solid 1pt ${COLORS.grey} ` }}>
                    <Col
                      xs={1}
                      onClick={() => handleClickPaid()}
                      style={{
                        width: 30,
                        height: 30,
                        border: `solid 2pt grey`,
                        marginRight: 10,
                      }}
                    >
                      {mystate.currentStudent.paid ? <CheckImg /> : " "}
                    </Col>
                    <Col>
                      <label style={{ padding: 5 }}> {"Check to change paid status."}</label>
                    </Col>
                    <Col></Col>
                  </Row>
                </ListGroup.Item>
              )}
              {mystate.infoModal2what == "assign" && (
                <ListGroup.Item>
                  <div className="blocklabel" style={{ marginTop: 10 }}>
                    ASSIGNED TO HORSE
                  </div>
                  {assignHorseDropdown().length > 1 ? (
                    <Select
                      onChange={(e) => handleHorseChange(e)}
                      className="form-control"
                      value={options_variable && valueFromId(options_variable, mystate.currentStudent.horse_id || 0)}
                      options={assignHorseDropdown()}
                      clearable={true}
                      searchable={true}
                      isOptionDisabled={options_variable.isDisabled}
                    />
                  ) : (
                    <div>There are no horses assigned to this lesson.</div>
                  )}

                  {isMobile && 1 == 2 && (
                    <div style={{ marginBottom: 10, textAlign: "center" }}>
                      <BlueText style={{ fontWight: "bold", fontSize: "20px" }}>
                        {" "}
                        {mystate.currentStudent.assigned_to || "unassigned"}
                      </BlueText>
                    </div>
                  )}

                  {isMobile && 1 == 2 && (
                    <div>
                      <HelpText>{"available to be assigned: (click on horse name to assign)"}</HelpText>
                    </div>
                  )}
                  {isMobile &&
                    1 == 2 &&
                    mystate.currentLesson.lessonHorses &&
                    mystate.currentLesson.lessonHorses.length > 0 &&
                    mystate.currentLesson.lessonStudents &&
                    mystate.currentLesson.lessonStudents.length > 0 &&
                    mystate.currentLesson.lessonHorses.map((horseitem) =>
                      mystate.currentLesson.lessonStudents.map((item) => item.horse_id).includes(horseitem.horse_id) ? (
                        <button key={horseitem.horse_id} style={{ backgroundColor: "lightgrey", fontSize: 14 }}>
                          {` ${horseitem.horse_name} `}
                        </button>
                      ) : (
                        <button
                          key={horseitem.horse_id}
                          style={{
                            backgroundColor: "orange",
                            color: "white",
                            fontSize: 14,
                          }}
                          onClick={() => handleHorseChange(horseitem)}
                        >
                          {`  ${horseitem.horse_name} `}
                        </button>
                      )
                    )}
                </ListGroup.Item>
              )}
              <div style={{ display: "flex", justifyContent: "flex-end" }}></div>
              {mystate.infoModal2what == "notes" && (
                <ListGroup>
                  <ListGroup.Item>
                    <div style={{ minHeight: "30px", marginTop: 10 }} className="blocklabel">
                      NOTES
                    </div>
                  </ListGroup.Item>
                  {addNoteType && (
                    <ListGroup.Item>
                      <div style={{ display: "flex", justifyContent: "flex-start" }}>
                        <div
                          style={{
                            marginLeft: 40,
                            height: 30,
                            width: 30,
                            border: "solid 3pt",
                            borderColor: COLORS.blue,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => setEmailNote(!emailNote)}
                        >
                          {emailNote ? <Check fontSize={20} /> : <Check fontSize={26} color={"white"} />}
                        </div>
                        <div style={{ marginLeft: 10 }}>
                          {" "}
                          <PrimaryTextBlue>{"Check to Email Note to Client"} </PrimaryTextBlue>
                        </div>
                      </div>
                    </ListGroup.Item>
                  )}

                  {mystate.currentStudent.studentLessonNotes &&
                    mystate.currentStudent.studentLessonNotes.map((note) => <ListNotes note={note} key={note.id} />)}
                  <ListGroup.Item>
                    <>
                      <Row style={{ display: "flex", justifyContent: "center" }}>
                        <PrimaryTextBlue>{`Adding ${addNoteType ? "Public" : "Private"} Note for ${
                          mystate.currentStudent.name
                        }`}</PrimaryTextBlue>
                        <HelpText>click eye to change toggle between private and public note</HelpText>
                      </Row>
                      <Row>
                        <Col xs={1}>
                          <img
                            alt={addNoteType ? "public" : "private"}
                            style={{ maxHeight: "20px", resize: "none" }}
                            src={addNoteType ? publicNoteImg : privateNoteImg}
                            onClick={() => setAddNoteType(!addNoteType)}
                          />
                        </Col>
                        <Col>
                          <textarea
                            style={{ minWidth: "100%", marginBottom: 20 }}
                            rows={6}
                            onChange={(e) => handleNoteInputChange(e)}
                            autoFocus
                            value={currentNoteInput}
                          />
                        </Col>
                      </Row>
                    </>
                  </ListGroup.Item>
                </ListGroup>
              )}
            </>
          )}
          {!mystate.currentEditIsStudentNotHorse && (
            <>
              {mystate.infoModal2what == "notes" && (
                <ListGroup>
                  <ListGroup.Item>
                    <div className="blocklabel">NOTES</div>
                  </ListGroup.Item>
                  <div>
                    {mystate.currentHorse.horseLessonNotes &&
                      mystate.currentHorse.horseLessonNotes.map((note) => <ListNotes note={note} key={note.id} />)}
                  </div>
                  <ListGroup.Item>
                    <>
                      <Row style={{ display: "flex", justifyContent: "center" }}>
                        <PrimaryTextBlue>{`Adding ${addNoteType ? "Public" : "Private"} Note for ${
                          mystate.currentHorse.horse_name
                        }`}</PrimaryTextBlue>
                        <HelpText>click eye to change toggle between private and public note</HelpText>
                      </Row>
                      <Row>
                        <Col xs={1}>
                          <img
                            alt={addNoteType ? "public" : "private"}
                            style={{ maxHeight: "20px", resize: "none" }}
                            src={addNoteType ? publicNoteImg : privateNoteImg}
                            onClick={() => setAddNoteType(!addNoteType)}
                          />
                        </Col>
                        <Col>
                          <textarea
                            style={{ minWidth: "100%", marginBottom: 20 }}
                            rows={6}
                            onChange={(e) => handleNoteInputChange(e)}
                            autoFocus
                            value={currentNoteInput}
                          />
                        </Col>
                      </Row>
                    </>
                  </ListGroup.Item>
                </ListGroup>
              )}
              {mystate.infoModal2what == "tack" && (
                <div>
                  <Select
                    onChange={(e) => assignTackToHorse(e)}
                    className="form-control"
                    options={options_tack_v}
                    value={options_tack_v.filter((tack) => tack.value == mystate.currentHorse.tack_id || 0)}
                    clearable={true}
                    searchable={true}
                    isOptionDisabled={options_tack_v.isDisabled}
                  />
                </div>
              )}
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            color="primary"
            onClick={() => {
              handleFinishNote();
            }}
          >
            Finished
          </Button>
          <Button
            color="primary"
            onClick={() => {
              getNext();
            }}
          >
            {mystate.currentEditIsStudentNotHorse ? "Next Student" : "Next Horse"}
          </Button>
          <CalendarStateButton />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InfoModal2;
