import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter, Route, Switch, Link, NavLink } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AuthHelperMethods from "./AuthHelperMethods";
import Url from "./url";
import CheckoutForm from "./paymentForm";
import { ListContext } from "./App";
const Auth = new AuthHelperMethods();

//import api from "./api";

const Payment = ({ isInvoicePayment = false, paymentId = "", currentPage }) => {
  // loadStripe.setLoadParameters({ advancedFraudSignals: false });

  const getPublicStripeKey = async () => {
    const result = await Auth.api("payments/getPublicStripeKey", "get");
    console.log("gettingi public stripe key");
    return result.data.publicKey;
  };

  const getFarmAccountId = async () => {
    const result = await Auth.api("payments/getFarmAccountId", "get");
    //console.log(result.data);
    return result.data.stripe_account_id;
  };

  const stripePromise = getPublicStripeKey().then((key) =>
    getFarmAccountId().then((farm) =>
      loadStripe(key, {
        stripeAccount: farm,
      })
    )
  );

  // const stripePromise = loadStripe(getPublicStripeKey());

  //const stripePromise = getPublicStripeKey().then((key) => loadStripe(key));

  //const v = getFarmAccountId();
  //console.log(v);

  return (
    <>
      <Elements stripe={stripePromise}>
        <CheckoutForm isInvoicePayment={isInvoicePayment} paymentId={paymentId} currentPage={currentPage} />
      </Elements>
    </>
  );
};

export default Payment;
