import React, { useReducer, useState, useEffect, useRef, useCallback } from "react";
import { Route, Switch, Redirect, useHistory, Link } from "react-router-dom";
import listPageReducer from "./listPageReducer";
import calendarReducer from "./calendarReducer";
import initialState from "./initialState";
import initialStateList from "./initialStateList";
import axios from "axios";
import Login from "./login";
import Logout from "./logout";
import AuthHelperMethods from "./AuthHelperMethods";
import { positions, Provider as AlertProvider } from "react-alert";
//import CalendarHome from "./Calendar";
import COLORS from "./styleConstants";

import { MenuIcon } from "./images";
//import { css } from "@emotion/core";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";

import PrivateRoute from "./privateRoute";
import LeftNavBar from "./leftNavBar";
import AlertTemplate from "./alert_template";
import AdmRoute from "./admRoute";
import ClientHome from "./clientHomePage";
import Public from "./public";
import PublicAvailability from "./publicAvailability";
import { ChangePassword, ValidatePasswordToken, CreatePasswordFromToken, WaiverNewAccountRedirect } from "./login";
//import setupLogRocketReact from "logrocket-react";
//import LogRocket from "logrocket";//
import posthog from "posthog-js";

import useSWR, { mutate, trigger, SWRConfig, useSWRInfinite } from "swr";

//import LogoutConfirm from "./logoutConfirm";

export const MyContext = React.createContext(null);
export const ListContext = React.createContext(null);
axios.defaults.baseURL =
  process.env.NODE_ENV == "production" ? "https://server.equineoffice.io/" : "http://localhost:3001"; // "https://meonlyapi.ngrok.io"; // /* switch to ngrok //when testing mobile device on localhost */

//axios.defaults.baseURL =
// process.env.NODE_ENV == "production" ? "https://server.equineoffice.io/" : "https://meonlyapi.ngrok.io"; // /* switch to ngrok when testing mobile device on localhost */

//

const Auth = new AuthHelperMethods();
function laggy(useSWRNext) {
  return (key, fetcher, config) => {
    // Use a ref to store previous returned data.
    const laggyDataRef = useRef();
    // Actual SWR hook.
    const swr = useSWRNext(key, fetcher, config);
    useEffect(() => {
      // Update ref if data is not undefined.
      if (swr.data !== undefined) {
        laggyDataRef.current = swr.data;
      }
    }, [swr.data]);

    // Expose a method to clear the laggy data, if any.
    const resetLaggy = useCallback(() => {
      laggyDataRef.current = undefined;
    }, []);

    // Fallback to previous data if the current data is undefined.
    const dataOrLaggyData = swr.data === undefined ? laggyDataRef.current : swr.data;
    // Is it showing previous data?
    const isLagging = swr.data === undefined && laggyDataRef.current !== undefined;

    // Also add a `isLagging` field to SWR.
    return Object.assign({}, swr, {
      data: dataOrLaggyData,
      isLagging,
      resetLaggy,
    });
  };
}
const App = () => {
  /* if (process.env.NODE_ENV == "production") {
    LogRocket.init("nv1fgx/twopointzero");
  }
*/ useEffect(
    () => {
      if (process.env.NODE_ENV == "production")
        posthog.init("phc_hCJrV09XYRL698Cbx749IWRNA9I0zSlCLlvWxVwZqrZ", { api_host: "https://app.posthog.com" });
    },
    //eslint-disable-next-line
    []
  );
  const [mystate, dispatch] = useReducer(calendarReducer, initialState);
  const [listState, dispatch2] = useReducer(listPageReducer, initialStateList);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);
  if (process.env.NODE_ENV == "production") {
    console.log = function () {};
  }

  const alertOptions = {
    position: positions.TOP_CENTER,
    timeout: 5000,
    offset: "0",
  };

  const fetcher = (url, params) =>
    axios.get(url, { params: params, headers: Auth.getHeaders() }).then((res) => res.data);

  return (
    <>
      <MyContext.Provider value={{ mystate, dispatch }}>
        <ListContext.Provider value={{ listState, dispatch2 }}>
          <SWRConfig
            value={{
              fetcher: (url, params) =>
                axios({ headers: Auth.getHeaders(), url: url, params: params, method: "get" }).then((res) => res.data),
              use: [laggy],
              revalidateOnFocus: process.env.NODE_ENV == "production" ? true : false,
            }}
          >
            <LoadingOverlay
              active={mystate.isFetching}
              fadeSpeed={1}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(245, 238, 237, 0.5)",
                }),
              }}
            >
              <div
                className="sweet-loading"
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "60%",
                  transform: "translate(-50%, -50%)",
                  zIndex: "50000",
                  textAlign: "center",
                }}
              >
                <RingLoader css={{}} sizeUnit={"px"} size={300} color={"#ff7900"} loading={mystate.isFetching} />
              </div>

              <>
                <AlertProvider template={AlertTemplate} {...alertOptions}>
                  <div
                    className="app"
                    style={{
                      display: "flex",
                      maxWidth: "100vw",
                      xjustifyContent: "center",
                      flexWrap: "nowrap",
                      background: "white",
                    }}
                  >
                    {" "}
                    <Switch>
                      <Route path="/login" component={Login} />
                      <PrivateRoute path="/clienthome/:id" component={ClientHome} />
                      <Route path="/logout" component={Logout} />
                      <PrivateRoute path="/admin" component={AdmRoute} />
                      <Route path="/password" component={ChangePassword} />
                      <Route path="/updatepassword" component={ValidatePasswordToken} />
                      <Route path="/createpassword" component={CreatePasswordFromToken} />
                      <Route path="/waivercompletion" component={WaiverNewAccountRedirect} />

                      <Route exact path="/" component={Login} />
                      <Route path="/public/:public_id" component={Public} />
                      <Route path="/publicBook/:public_id" component={PublicAvailability} />
                    </Switch>
                  </div>
                </AlertProvider>
              </>
            </LoadingOverlay>
          </SWRConfig>
        </ListContext.Provider>
      </MyContext.Provider>
    </>
  );
};

export default App;
