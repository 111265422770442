import React, { useEffect, useState, useContext } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Route, Switch, useHistory, useLocation, Link } from "react-router-dom";

import AuthHelperMethods from "./AuthHelperMethods";
import { ListContext, MyContext } from "./App";

import { PrimaryTextBlue, BlueButton } from "./styleCustom";
import { Modal } from "react-bootstrap";
import { todayDisplay } from "./momentUtils";
import COLORS from "./styleConstants";
import "./elementsStyle.css";
import { BlueButtonReg } from "./formComponents";
import OnlinePurchase from "./onlinePurchase";
import { CreatePaymentAndItems } from "./createPaymentAndItems";
import { Square, CheckSquare } from "./icons";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Payment from "./payment";

const Auth = new AuthHelperMethods();

export const Succeeded = ({ currentPage = "purchases" }) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  let history = useHistory();
  const finishPay = () => {
    //  dispatch2(["CLEAR_CART"]);
    history.push(`/admin/clienthome/${listState.currentClient.id}/${currentPage}`);
  };

  return (
    <div
      style={{
        margin: 50,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
      className={"paymentSuccess"}
    >
      <div>
        {" "}
        <PrimaryTextBlue>Your payment was successful.</PrimaryTextBlue>
      </div>
      <div>
        <PrimaryTextBlue>A detailed receipt has been sent to your email.</PrimaryTextBlue>
      </div>
      <BlueButton style={{ marginTop: 30 }} onClick={() => finishPay()}>
        close
      </BlueButton>
    </div>
  );
};

export const PaymentButtons = ({ processing, currentPage }) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  let history = useHistory();
  const cancelPaymentIntent = async () => {
    let params = { intent: listState.paymentIntent.id };
    // let result = Auth.api("payments/cancelPaymentIntent/", "post", params);
    //  console.log(result);
  };
  return (
    <>
      <button style={{ width: "100%", background: COLORS.orange }} className="payment">
        {processing
          ? "Processing…"
          : `pay  ${mystate.farmDefaults.currency_code.toLocaleUpperCase()}
            ${mystate.farmDefaults.currency_symbol}${listState.cartTotal.toLocaleString(navigator.language, {
              minimumFractionDigits: 2,
            })}`}
      </button>
      <button style={{ width: "100%", background: COLORS.blue }} className="payment">
        <Link
          onClick={() => cancelPaymentIntent()}
          to={`/admin/clienthome/${listState.absoluteCurrentClientId}/online`}
        >
          cancel
        </Link>
      </button>
    </>
  );
};

export const CheckoutSavedPaymentForm = ({ currentPage = "purchases" }) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [error, setError] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const [paymentMethodsList, setpaymentMethodsList] = useState([]);
  const [paymentMethodId, setPaymentMethodId] = useState();
  const [ready, setReady] = useState(false);
  const [change, setChange] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(
    () => {
      dispatch({
        type: "FETCH_INIT",
      });
      console.log("USE EFFECT");

      setClientSecret(listState.paymentIntent.client_secret);
      setPaymentIntentId(listState.paymentIntent.id);
      setpaymentMethodsList(listState.paymentMethodList);
      setPaymentMethodId(listState.paymentMethodList[0].id);
      dispatch({
        type: "FETCH_SUCCESS",
      });
    },
    //eslint-disable-next-line
    []
  );

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    dispatch({
      type: "FETCH_INIT",
    });
    if (paymentMethodsList.length > 0) {
      const params = { payment_intent_id: paymentIntentId, payment_method_id: paymentMethodId };
      try {
        const result = await Auth.api("payments/confirmPaymentIntent", "post", params);
        if (result.data.confirmstatus.status === "requires_source_action") {
          alert("Your card requires further authentication.  Please enter your card number again");
          setpaymentMethodsList([]);
          setProcessing(false);
          dispatch({
            type: "FETCH_SUCCESS",
          });
        } else {
          console.log(result.data.confirmstatus);

          setError(null);
          //setSucceeded(true);
          setProcessing(false);
          dispatch2([
            "SET_CLIENT_PAYMENT",
            { ...listState.clientPayment, transaction_type: "online", reference: result.data.confirmstatus.id },
          ]);
        }
      } catch (error) {
        dispatch({ type: "FETCH_ERROR" });
        // alert.show(error.message, { timeout: 5000, type: "error" });
        setError(`Payment failed: ${error.message}`);
        setProcessing(false);
      }
    }
  };
  let front = location.pathname.substring(0, location.pathname.lastIndexOf("/"));
  let paymentlink = `${front}/payments`;
  console.log(paymentlink);
  const finishPay = () => {
    dispatch({ type: "FETCH_SUCCESS" });

    setSucceeded(true);
  };
  if (change) return <Payment />;
  return (
    <>
      <div className="sr-form-row" />
      <Modal show={true} centered backdrop="static">
        {!succeeded && (
          <form
            onSubmit={handleSubmit}
            className="payment"
            style={{
              maxWidth: 500,

              display: "flex",
              flex: "1 1 100%",
              justifyContent: "center",
              flexDirection: "column",
              margin: 50,
            }}
          >
            <div className="sr-combo-inputs" style={{ border: "solid 1 grey", width: "100%", marginBottom: 20 }}>
              {paymentMethodsList.length > 0 && (
                <div style={{ display: "flex", flex: "1 1 100%", flexDirection: "column" }}>
                  {paymentMethodsList.map((pm) => (
                    <div
                      style={{
                        margin: 0,
                        padding: 10,

                        fontSize: 14,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div onClick={() => setPaymentMethodId(pm.id)} style={{ marginRight: 10, fontSize: 20 }}>
                        {paymentMethodId == pm.id ? <CheckSquare fontSize={30} /> : <Square fontSize={30} />}
                      </div>
                      <div style={{ fontSize: 14, color: COLORS.textDark }}>{`Use card ending in ${pm.last4}`}</div>
                    </div>
                  ))}
                </div>
              )}
              <div
                style={{
                  margin: 0,
                  padding: 10,
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                  flex: "1 1 100%",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: 20,
                    marginBottom: 5,
                  }}
                  onClick={() => setChange(true)}
                >
                  {"OR"}
                </div>
                <div
                  style={{
                    fontSize: 20,
                  }}
                >
                  <Link to={paymentlink}>{"Enter a New Card"}</Link>
                </div>
              </div>
            </div>

            {error && <div className="message sr-field-error">{error}</div>}

            <PaymentButtons processing={processing} />
          </form>
        )}
        {succeeded && <Succeeded currentPage={currentPage} />}
      </Modal>

      {Object.keys(listState.clientPayment).length > 0 && <CreatePaymentAndItems onFinish={finishPay} />}
    </>
  );
};

export default CheckoutSavedPaymentForm;
