import React, { useReducer, Fragment, useState, useEffect, useContext } from "react";
import axios from "axios";
import { MyContext } from "./App";
import Select from "react-select";
import { valueFromId, calculateEndTime } from "./momentUtils";
import TextInputTitle from "./textInputTitle";
import { colourStyles } from "./styleCustom";

const SelectSection = (props) => {
  const { mystate, dispatch } = useContext(MyContext);

  const handleSelectChangeAdd = async (event) => {
    dispatch({
      type: "SET_NEW_EVENT",
      data: { section_id: event.value },
    });
  };

  const handleSelectChangeEdit = (event) => {
    console.log(event);

    dispatch({
      type: "EDIT_CURRENT_SECTION",
      data: event,
    });
    dispatch({
      type: "EDIT_LESSON_TITLE",
      data: event.label,
    });
  };
  if (!mystate.options_section) return null;
  return (
    <div>
      {mystate.options_section && (
        <Select
          onChange={handleSelectChangeEdit}
          className="form-control"
          options={mystate.options_section}
          defaultValue={mystate.options_section.filter((section) => section.value == mystate.currentLesson.section_id)}
          clearable={true}
          searchable={true}
          placeholder={"Select Section..."}
          styles={props.styles || colourStyles}
        />
      )}
    </div>
  );
};

export default SelectSection;
