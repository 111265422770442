import moment from "moment";
import Settings from "./UserSettings";

import { DISPLAY_DATETIME, DISPLAY_DATE, DISPLAY_TIME, DATEPICKER_DISPLAY } from "./UserSettings";
import {
  dateObjToDBDate,
  dateObjToDBTime,
  dateObjToDBDateTime,
  formatForDb,
  formatDateTimeForDbEdit,
  calculateEndTime,
  dateObjToDisplayDatetime,
  dateObjToDisplayDate,
  dateISOtoString,
  timeISOtoString,
} from "./momentUtils";

export const messageMap = (editAction, currentLesson, currentLessonOld, dateTimeComponent) => {
  //  const [check, setCheck] = useState(false);

  const successmsgpart = () => {
    let lessonIn = currentLesson;
    return (
      `  for ${lessonIn.title}  on ${dateISOtoString(lessonIn.scheduled_date)}  at  ${timeISOtoString(
        lessonIn.scheduled_starttime
      )}` || ""
    );
  };

  const lessonInfoMessage = () => {
    let lessonIn = currentLesson;
    return `${lessonIn.title}  on ${dateISOtoString(lessonIn.scheduled_date)}  at  ${timeISOtoString(
      lessonIn.scheduled_starttime
    )}`;
  };

  const from = () => {
    let rescheduledFrom = dateTimeComponent.is_allday
      ? dateObjToDisplayDate(currentLesson.start)
      : dateObjToDisplayDatetime(currentLesson.start);
    return rescheduledFrom;
  };

  const to = () => {
    let rescheduledTo = dateTimeComponent.is_allday
      ? dateObjToDisplayDate(dateTimeComponent.start)
      : dateObjToDisplayDatetime(dateTimeComponent.start);
    return rescheduledTo;
  };

  const notificationMessageMap = {
    reschedule: "Your lesson has been rescheduled from " + from() + " to " + to(),
    cancel: `${lessonInfoMessage()}  has been canceled`,
    editInstructor: `Instructor has been changed from ${currentLessonOld.instructor_name} to ${
      currentLesson.instructor_name
    } for ${lessonInfoMessage()}`,
    editLocation: `Location has been changed from ${currentLessonOld.location_name} to ${
      currentLesson.location_name
    } for ${lessonInfoMessage()}`,
  };

  return notificationMessageMap[editAction];
};

export default messageMap;
