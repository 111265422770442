import React, { Fragment, useState, useContext, useEffect } from "react";
import { MyContext } from "./App";

const TextInputTitle = (props) => {
  const { mystate, dispatch } = useContext(MyContext);

  /* useEffect(
    () => {
    StudentAddSections();

      }
    },
    //eslint-disable-next-line
    [mystate.addEvent.title]
  );
*/

  const handleInputChange = (e) => {
    props.addOrEdit === "add"
      ? dispatch({
          type: "SET_NEW_EVENT",
          data: {
            title: e.target.value,
          },
        })
      : dispatch({
          type: "EDIT_LESSON_TITLE",
          title: e.target.value,
        });

    console.log("handleLTitileChangeEdit");
    //console.log(e);
  };

  return (
    <input
      type="text"
      className="form-control"
      onChange={(e) => handleInputChange(e)}
      value={props.addOrEdit == "add" ? mystate.addevent.title : mystate.currentLesson.title}
      style={{ fontSize: "10pt" }}
    />
  );
};

export default TextInputTitle;
