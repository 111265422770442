import COLORS from "./styleConstants";
import moment from "moment";
import {
  dateObjToDBDate,
  combineDateAndTimeStrToDateObj,
  timeISOtoString,
  dateTimeISOtoStringMed,
  dateISOtoString,
  combineDateAndTimeStrToMomentObj,
  dateISOtoStringUtc,
  createNameString,
} from "./momentUtils";

export function getRepeatDesc(currentLesson) {
  console.log(currentLesson);
  console.log(currentLesson.repeat_end);

  let frequency = currentLesson.frequency;
  let period = currentLesson.period;
  let periodShort = "";
  if ((period == "weeks" || period == "week") && frequency === 1) {
    period = "week";
    periodShort = "repeats weekly";
  }
  if (period == "days" && frequency === 1) {
    period = "day";
    periodShort = "repeats daily";
  }
  if (frequency > 1 && (period == "weeks" || period == "week")) {
    console.log("frequency > 1 && period == weeks");
    periodShort = "repeats every 2nd week";
  }

  if (frequency > 1 && period == "days") {
    periodShort = "repeats every 2nd day";
  }

  let desc = `This event repeats every ${frequency > 1 ? frequency : ""} ${period} until ${dateISOtoStringUtc(
    currentLesson.repeatEnd
  )}.`;

  return desc;
}

export function calulateInitialCalendarDateRange(view) {
  var currentStart, currentEnd;
  if (view == "day") {
    currentStart = dateObjToDBDate(moment().toDate());
    currentEnd = dateObjToDBDate(moment().toDate());
  }
  if (view == "week" || view == "agenda") {
    currentStart = dateObjToDBDate(moment().startOf("week"));
    currentEnd = dateObjToDBDate(moment().endOf("week"));
  }
  if (view == "month") {
    currentStart = dateObjToDBDate(moment().startOf("month").add(-2, "week").toDate());
    currentEnd = dateObjToDBDate(moment().endOf("month").add(2, "week").toDate());
  }

  return [currentStart, currentEnd];
}

export function calulateInitialAgendaDates() {
  let currentStart = dateObjToDBDate(moment().toDate());
  let currentEnd = dateObjToDBDate(moment().add(30, "days"));
  return [currentStart, currentEnd];
}

export function calculateDateRange(e) {
  console.log(e.start);
  console.log(e.end);

  var currentStart, currentEnd, view;
  if (e.length) {
    if (e.length == 1) {
      console.log(e[0]);
      currentStart = dateObjToDBDate(e[0]);
      currentEnd = dateObjToDBDate(e[0]);
      view = "day";
    } else if (e.length == 7) {
      currentStart = dateObjToDBDate(e[0]);
      currentEnd = dateObjToDBDate(e[6]);
      view = "week";
    }
  } else {
    currentStart = dateObjToDBDate(moment(e.start));
    currentEnd = dateObjToDBDate(moment(e.end));
    view = "month";
  }
  console.log(currentStart, currentEnd, view);
  return [currentStart, currentEnd];
}

export function mapAvailability(lessons) {
  let updated = lessons.map((lesson) => ({
    ...lesson,
    available: parseInt(lesson.max_enrollment || 0) - parseInt(lesson.cnt),
    dt: combineDateAndTimeStrToMomentObj(lesson.scheduled_date, lesson.scheduled_starttime),
  }));
  return updated;
}

export const formatEventsFromDB = (response) => {
  //console.log(moment());
  const events = [];
  // console.log(response);
  var time;
  if (response?.lessons) {
    response.lessons.forEach((obj) =>
      events.push({
        key: obj.id,
        id: obj.id,
        start: combineDateAndTimeStrToDateObj(obj.scheduled_date, obj.scheduled_starttime),
        end: combineDateAndTimeStrToDateObj(obj.scheduled_end_date, obj.scheduled_endtime),
        scheduled_date: obj.scheduled_date,
        scheduled_starttime: obj.scheduled_starttime,
        scheduled_end_date: obj.scheduled_end_date,
        scheduled_endtime: obj.scheduled_endtime,
        startdate_string: dateISOtoString(obj.scheduled_date),
        starttime_string: timeISOtoString(obj.scheduled_starttime),
        endtime_string: timeISOtoString(obj.scheduled_endtime),

        time: timeISOtoString(obj.scheduled_starttime), //changed to starttime_string - delete this
        endtime: timeISOtoString(obj.scheduled_endtime), //changed to endtime_string - delete this
        title: obj.title || obj.name,
        title_with_time: `${timeISOtoString(obj.scheduled_starttime)} ${obj.title || obj.name}`,
        title_with_date_time: `${obj.title || obj.name} on ${dateISOtoString(obj.scheduled_date)} at ${timeISOtoString(
          obj.scheduled_starttime
        )} `,

        url: `http://localhost:3000/calendar/?id:${obj.id}`,
        section_id: obj.section_id,
        product_id: obj.product_id,
        section_name: obj.section_name,
        instructor_id: obj.instructor_id,
        location_id: obj.location_id,
        is_allday: obj.is_allday,
        allDay: obj.is_allday,
        instructor_name: obj.instructor_name || "",
        location_name: obj.location_name || "",
        color: obj.color_code,
        lesson_is_cancelled: obj.lesson_is_cancelled,
        lesson_status_code: obj.lesson_status_code,
        lesson_notes: obj.lesson_notes,
        is_recuring: obj.is_recuring || obj.lesson_date_time_series_id,
        lesson_date_time_series_id: obj.lesson_date_time_series_id,
        frequency: obj.frequency,
        period: obj.period,
        repeat_end: dateISOtoStringUtc(obj.repeatEnd),
        refresh: false,
        program_id: obj.program_id,
        program_type_id: obj.program_type_id,
        students:
          response.LessonStudents?.filter(
            (students) => students.lesson_date_time_id == obj.id && students.student_is_cancelled == 0
          ) || [],
        horses: response.LessonHorses?.filter((horses) => horses.lesson_date_time_id == obj.id) || [],
        studentString:
          createNameString(
            response.LessonStudents?.filter(
              (students) => students.lesson_date_time_id == obj.id && students.student_is_cancelled == 0
            )
          ) || "",
        horseString:
          createNameString(response.LessonHorses?.filter((horses) => horses.lesson_date_time_id == obj.id)) || "",
        assignedHorses:
          response.LessonStudents?.filter((students) => students.lesson_date_time_id == obj.id)
            .filter((student) => student.horse_id !== null)
            .map((horse) => horse.horse_id) || [],
        max: obj.max,
        cnt: obj.cnt,
        available: obj.available || parseInt(obj.max_enrollment || 0) - parseInt(obj.cnt),

        horse_id: obj.horse_id,
        horse_name: obj.horse_name,
        tack_id: obj.tack_id,
        tack_name: obj.tack_name,
      })
    );
  }

  console.log(events);

  return events;
};
