import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, ListGroup, Modal, Accordion } from "react-bootstrap";
import { ListContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import { BlueText } from "./styleCustom";
import SearchFilter from "./searchFilter";
import { timeISOtoString, currentDayToDB } from "./momentUtils";
import { EditIcon } from "./icons";
import { SelectTemplateFilter } from "./searchFilterReports";
import OnlinePurchase from "./onlinePurchase";
import COLORS, { helpStyle } from "./styleConstants";
import { deleteImg, privateNoteImg, publicNoteImg } from "./images";
import { MyContext } from "./App";
import { dateObjToDisplayDate, dateObjToDisplayTime, DBDATEFORMAT, DBTIMEFORMAT } from "./momentUtils";
import moment from "moment";
import {
  dateObjToDBDate,
  dateISOtoStringMed,
  combineDateAndTimeStrToMomentObj,
  combineDateAndTimeStrToMomentObjNoUtc,
} from "./momentUtils";
import { ContainerH, ContainerV, Row2, Ul, PrimaryText18, PrimaryText, HelpText } from "./styleCustom";
import { useAlert } from "react-alert";
import SearchFilterDate from "./searchFilterDates";
import { DISPLAY_DATE, DATEPICKER_DATE_DISPLAY } from "./UserSettings";
import { MemberLinks } from "./clientHomePage";
import { UserCan, Dialog } from "./formComponents";
import { ArrowLeft, ArrowDown } from "./icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Auth = new AuthHelperMethods();

const Booking = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [localFilter, setLocalFilter] = useState({
    dbday: dateObjToDBDate(moment().toDate()),
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const [lessonToBook, setLessonToBook] = useState();
  const [heading, setHeading] = useState();
  const [refresh, setRefresh] = useState(false);
  const [confirm, setConfirm] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

  //const [isBookingMakeup, setIsBookingMakeup] = useState(false);
  let history = useHistory();
  const handleModalClose = () => {
    dispatch2(["SET_CONFIRM_SHOW", false]);
  };
  const [showHelp, setShowHelp] = useState(false);
  const alert = useAlert();
  console.log(props.match.url);
  let incoming = useParams();
  console.log(incoming);
  if (incoming.item_id) console.log("yes");
  if (!incoming.item_id) console.log("no");
  console.log(incoming.start);
  const start = incoming.start || "";
  const end = incoming.end || "";
  const item = incoming.item_id || "";
  const isBookingMakeup = incoming.item_id ? true : false;
  console.log(isBookingMakeup);

  /*useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    window.addEventListener("unload", checkEmails);
    return () => {
      checkEmails();

      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("unload", checkEmails);
    };
  }, []);
*/

  useEffect(
    () => {
      const fetchData = async () => {
        console.log("**************** INSTRUCTOR FETCH *****************");
        try {
          const params = { active: true };

          const result = await Auth.api("instructors/index", "get", params);
          let options = result.data.options.map((option) => ({ ...option, color: option.color_code }));

          dispatch({
            type: "GET_INSTRUCTOR_OPTIONS",
            options: options,
          });
        } catch (error) {
          console.log("error");
        }
      };
      if (!mystate.options_instructor.length > 0) fetchData();
    },
    //eslint-disable-next-line
    []
  );
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const checkEmails = async () => {
    console.log("checkEmails");

    const result = await Auth.api("lesson_people/sendBookingEmail", "post");
  };

  const checkIfInLesson = () => {
    const lesson = lessonToBook;
    lesson.student_id = listState.currentClient.student_id;
  };

  const handleClickLesson = (lesson) => {
    if (incoming.item_id) {
      if (moment(lesson.scheduled_date).isBetween(moment(start), moment(end))) {
        console.log("valid");
        addStudentToLesson(lesson);
      } else {
        console.log("invalid");
        alert.show("package isn't valid for this date");
      }
    } else {
      addStudentToLesson(lesson);
    }
  };

  const lessonInWords = (lesson) => {
    let msg = `${listState.currentClient.person_name} has been booked for ${lesson.title} on ${dateISOtoStringMed(
      lesson.scheduled_date
    )} ${!lesson.is_allday ? " at " + timeISOtoString(lesson.scheduled_starttime) : ""}  with ${
      lesson.instructor_name
    }.`;
    return msg;
  };

  const addStudentToLesson = async (lessonToBook) => {
    dispatch({ type: "FETCH_INIT" });

    console.log(lessonToBook);
    let lesson = lessonToBook;
    lesson.student_id = listState.currentClient.student_id;
    lesson.student_email = listState.accountHolder.contact_email;
    lesson.person_id = listState.currentClient.id;
    lesson.account_name = listState.accountHolder.first_name;
    lesson.makeup_lesson_id = listState.currentClient.makeup_lesson_id || null; //?this doesn't make sense -> doesn't exist

    console.log(lesson);
    let msg = lessonInWords(lesson);
    lesson.msg = msg;

    //let packageurl = isMakeup ? "items/everything" : "items/makeup";
    // let item_id = incoming.is_makeup || 0;

    if (incoming.item_id) lesson = { ...lesson, item_id: incoming.item_id, is_makeup: true }; //rule - if booking from package ie has item_id then it is a makeup
    // console.log(incoming.is_makeup);
    console.log(lesson);
    try {
      const result = await Auth.api("lesson_people/addStudentToLesson", "post", lesson);

      if (result) {
        if (result.data.lessonPerson == "already in lesson") {
          // dispatch2(["SET_DIALOG_SHOW", true]);
          alert.show("Student is already booked for this lesson spot");
        } else if (result.data.lessonPerson == "none available") {
          alert.show("Sorry this lesson has been booked since the last refresh");
        } else {
          setRefresh(!refresh);

          alert.show(msg, {
            timeout: 50000,
            type: "success",
          });

          setConfirm(confirm.concat(msg));
          console.log(confirm);

          let lessonPersonId = result.data.lessonPerson.id;

          let params = {
            student_id: lesson.student_id,
            scheduled_date: lesson.scheduled_date,
            lesson_person_id: lessonPersonId,
          };
          if (!incoming.item_id) Auth.api(`items/everything`, "get", params);
          dispatch2(["SET_CONFIRM_SHOW", true]);
          /*history.push(
            `/admin/clienthome/${listState.currentClient.id}/lessons`
          );*/
        }
        dispatch({ type: "FETCH_SUCCESS" });
      }
    } catch (error) {
      dispatch({ type: "FETCH_ERROR" });

      console.error(error.message);
    }
  };

  const updateLocalFilter = (filter) => {
    console.log(filter);
    setLocalFilter({ ...localFilter, ...filter });
  };

  useEffect(() => {
    //  if (listState.filter.current == "daysheet") {
    console.log("run");
    const fetchData = async () => {
      let params = { ...listState.filter };

      //params.dbday = listState.filter.dbday || currentDayToDB();
      params.date1 = "2021-11-20";
      params.date2 = "2021-11-27";
      params.student_id = listState.currentClient.student_id;
      console.log(params.student_id);
      params.booking_buffer = mystate.farmDefaults.booking_hours_buffer || 0;
      try {
        dispatch({ type: "FETCH_INIT" });

        const result = await Auth.api("lessons/index", "get", params);

        // if (!result.data) dispatch({ type: "FETCH_ERROR" });
        let updated = result.data.lessons.map((lesson) => ({
          ...lesson,
          // available: parseInt(lesson.max_enrollment || 0) - parseInt(lesson.cnt),
          dt: combineDateAndTimeStrToMomentObj(lesson.scheduled_date, lesson.scheduled_starttime),
        }));
        console.log(updated);
        let bufferdatenew = moment().add(mystate.farmDefaults.booking_hours_buffer, "hours");
        console.log(bufferdatenew);
        updated = updated.filter((lesson) => lesson.dt > bufferdatenew);

        console.log(updated);
        // }
        if (Auth.getUserType() == "ADM") dispatch2(["GET_DAYSHEET_CLIENTS", updated]);
        if (Auth.getUserType() == "STU" && (mystate.farmDefaults.clients_can_book_lessons || isBookingMakeup))
          dispatch2(["GET_DAYSHEET_CLIENTS", updated]);
        dispatch({ type: "FETCH_SUCCESS" });
      } catch (e) {
        dispatch({ type: "FETCH_ERROR" });

        alert.show("Sorry, there has been an error sending the data.", {
          timeout: 50000,
          type: "error",
        });
        console.log(e.message);
      }
    };

    fetchData();

    setHeading(moment(listState.filter.dbday).format(DISPLAY_DATE));

    //eslint-disable-next-line
  }, [listState.filter, refresh]);

  const changeSingle = (date) => {
    // setOpen(true);
    //setClose(false);
    console.log(date);
  };
  const [open, setOpen] = useState(true);
  const [close, setClose] = useState(false);

  const NoLessons = () => {
    if (listState.daysheetClients && listState.daysheetClients.length == 0)
      return (
        <div style={{ color: COLORS.blue, fontSize: 16, margin: 10 }}>{"There are no available lessons today."}</div>
      );
    else return null;
  };
  return (
    <>
      <Dialog title={"dialogtielf"}>
        <div> the rest</div>
      </Dialog>
      <Modal show={false} size="md" centered>
        <Modal.Header style={{ background: COLORS.blue, color: "white" }} closeButton>
          <Modal.Title>{props.modalTitle} </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary">Close</Button>
        </Modal.Footer>
      </Modal>

      <div className={"startBooking"}>
        <div
          style={{
            display: "flex",
            flex: "1 1 100%",
            flexWrap: "wrap",
          }}
        >
          <MemberLinks verb={""} page={"Book Lesson"} link={"booking"} />
        </div>
      </div>
      {confirm.length > 0 && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <PrimaryText18>{`Bookings Added`} </PrimaryText18>
          {confirm.map((msg) => (
            <div style={{ fontSize: 16 }}>{msg}</div>
          ))}

          <HelpText>{"To book another lesson for the same student, find a new available lesson and repeat."}</HelpText>
          <HelpText>
            {"To book a lesson for another student on the same account, click on their name above and repeat."}
          </HelpText>
        </div>
      )}
      <div style={{ display: "flex", flex: "1 1 100%", flexDirection: "row", alignItems: "flex-end" }}>
        {" "}
        <SelectTemplateFilter
          options={mystate.options_instructor}
          isMulti
          label={"instructor"}
          list={listState.filter_labels.instructor || null}
          value={listState.filter.instructor || null}
        />
        {!isMobile && (
          <div className="help" style={helpStyle}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {" "}
              <ArrowLeft />
            </div>
            <div>{"Click Dropdown to show only lessons from a particular instructor"}</div>
          </div>
        )}
      </div>
      <div style={{ display: "flex", flex: "1 1 100%", flexDirection: "row", alignItems: "flex-end", marginTop: 30 }}>
        {" "}
        <SearchFilterDate single initial={moment().toDate()} shouldCloseOnSelect={true} autoFocus hidelabel />
        {!isMobile && (
          <div className="help" style={helpStyle}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {" "}
              <ArrowLeft />
            </div>
            <div>
              {"Click the button to choose a day from the calendar, or use the arrows to go forward and back a day "}
            </div>
          </div>
        )}
      </div>

      <div
        className={"data"}
        style={{
          margin: 30,
          marginTop: 60,
          display: "flex",
          flex: "1 1 100%",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: "1 1 100%",
            fontSize: 20,
            color: COLORS.blue,
            paddingRight: 30,
            marginLeft: 20,
          }}
        >
          {heading}{" "}
        </div>

        <NoLessons />
        {listState.daysheetClients &&
          listState.daysheetClients.map((lesson) => (
            <div
              className="BookingRow"
              style={{
                display: "flex",
                flex: "1 1 100%",
                border: "solid 1pt grey",
                marginBottom: 10,
                background: "white",
              }}
            >
              {" "}
              <Row2
                style={{
                  background: lesson.color_code || "lightgrey",
                  maxWidth: 30,
                  minWidth: 30,
                  marginRight: 10,
                }}
              />
              <Row2 className="titleAndTime">
                <Ul style={{ listStyleType: "none" }}>
                  <li> {lesson.title}</li>

                  <li>
                    {lesson.is_allday
                      ? "all day"
                      : `${timeISOtoString(lesson.scheduled_starttime)} -  ${timeISOtoString(
                          lesson.scheduled_endtime
                        )}`}
                  </li>
                </Ul>
              </Row2>
              <Row2>
                <Ul style={{ listStyleType: "none" }}>
                  <li>{lesson.instructor_name || "Staff unassigned"}</li>
                  <li>{lesson.location_name}</li>
                </Ul>
              </Row2>
              <Row2>
                <Ul style={{ listStyleType: "none" }}>
                  <li>
                    {lesson.available > 1 && `${lesson.available} available spots `}
                    {lesson.available == 1 && `${lesson.available} available spot `}

                    {lesson.available < 1 && <span style={{ color: "red" }}>{"full"} </span>}
                  </li>
                  {lesson.available >= 1 && (
                    <>
                      <UserCan isThis={"ADM"}>
                        <li onClick={() => handleClickLesson(lesson)} style={{ cursor: "pointer" }}>
                          <BlueText> Click here to join </BlueText>
                        </li>
                      </UserCan>
                      {Auth.getUserType() == "STU" &&
                        (mystate.farmDefaults.clients_can_book_lessons || isBookingMakeup) && (
                          <li onClick={() => handleClickLesson(lesson)} style={{ cursor: "pointer" }}>
                            <BlueText> Click here to join </BlueText>
                          </li>
                        )}
                    </>
                  )}
                </Ul>
              </Row2>
            </div>
          ))}
      </div>
    </>
  );
};

export const DisplayBooking = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  const NoLessons = () => {
    if (listState.daysheetClients && listState.daysheetClients.length == 0)
      return (
        <div style={{ color: COLORS.blue, fontSize: 16, margin: 10 }}>{"There are no available lessons today."}</div>
      );
    else return null;
  };
  return (
    <>
      <Dialog title={"dialogtielf"}>
        <div> the rest</div>
      </Dialog>
      <Modal show={false} size="md" centered>
        <Modal.Header style={{ background: COLORS.blue, color: "white" }} closeButton>
          <Modal.Title>{props.modalTitle} </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary">Close</Button>
        </Modal.Footer>
      </Modal>

      <div className={"startBooking"}>
        <div
          style={{
            display: "flex",
            flex: "1 1 100%",
            flexWrap: "wrap",
          }}
        >
          <MemberLinks verb={""} page={"Book Lesson"} link={"booking"} />
        </div>
      </div>
      {confirm.length > 0 && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <PrimaryText18>{`Bookings Added`} </PrimaryText18>
          {confirm.map((msg) => (
            <div style={{ fontSize: 16 }}>{msg}</div>
          ))}

          <HelpText>{"To book another lesson for the same student, find a new available lesson and repeat."}</HelpText>
          <HelpText>
            {"To book a lesson for another student on the same account, click on their name above and repeat."}
          </HelpText>
        </div>
      )}
      <div style={{ display: "flex", flex: "1 1 100%", flexDirection: "row", alignItems: "flex-end" }}>
        {" "}
        <SelectTemplateFilter
          options={mystate.options_instructor}
          isMulti
          label={"instructor"}
          list={listState.filter_labels.instructor || null}
          value={listState.filter.instructor || null}
        />
        {!isMobile && (
          <div className="help" style={helpStyle}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {" "}
              <ArrowLeft />
            </div>
            <div>{"Click Dropdown to show only lessons from a particular instructor"}</div>
          </div>
        )}
      </div>
      <div style={{ display: "flex", flex: "1 1 100%", flexDirection: "row", alignItems: "flex-end", marginTop: 30 }}>
        {" "}
        <SearchFilterDate single initial={moment().toDate()} shouldCloseOnSelect={true} autoFocus hidelabel />
        {!isMobile && (
          <div className="help" style={helpStyle}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {" "}
              <ArrowLeft />
            </div>
            <div>
              {"Click the button to choose a day from the calendar, or use the arrows to go forward and back a day "}
            </div>
          </div>
        )}
      </div>

      <div
        className={"data"}
        style={{
          margin: 30,
          marginTop: 60,
          display: "flex",
          flex: "1 1 100%",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: "1 1 100%",
            fontSize: 20,
            color: COLORS.blue,
            paddingRight: 30,
            marginLeft: 20,
          }}
        >
          {heading}{" "}
        </div>

        <NoLessons />
        {listState.daysheetClients &&
          listState.daysheetClients.map((lesson) => (
            <div
              className="BookingRow"
              style={{
                display: "flex",
                flex: "1 1 100%",
                border: "solid 1pt grey",
                marginBottom: 10,
                background: "white",
              }}
            >
              {" "}
              <Row2
                style={{
                  background: lesson.color_code || "lightgrey",
                  maxWidth: 30,
                  minWidth: 30,
                  marginRight: 10,
                }}
              />
              <Row2 className="titleAndTime">
                <Ul style={{ listStyleType: "none" }}>
                  <li> {lesson.title}</li>

                  <li>
                    {lesson.is_allday
                      ? "all day"
                      : `${timeISOtoString(lesson.scheduled_starttime)} -  ${timeISOtoString(
                          lesson.scheduled_endtime
                        )}`}
                  </li>
                </Ul>
              </Row2>
              <Row2>
                <Ul style={{ listStyleType: "none" }}>
                  <li>{lesson.instructor_name || "Staff unassigned"}</li>
                  <li>{lesson.location_name}</li>
                </Ul>
              </Row2>
              <Row2>
                <Ul style={{ listStyleType: "none" }}>
                  <li>
                    {lesson.available > 1 && `${lesson.available} available spots `}
                    {lesson.available == 1 && `${lesson.available} available spot `}

                    {lesson.available < 1 && <span style={{ color: "red" }}>{"full"} </span>}
                  </li>
                  {lesson.available >= 1 && (
                    <>
                      <UserCan isThis={"ADM"}>
                        <li onClick={() => handleClickLesson(lesson)} style={{ cursor: "pointer" }}>
                          <BlueText> Click here to join </BlueText>
                        </li>
                      </UserCan>
                      {Auth.getUserType() == "STU" &&
                        (mystate.farmDefaults.clients_can_book_lessons || isBookingMakeup) && (
                          <li onClick={() => handleClickLesson(lesson)} style={{ cursor: "pointer" }}>
                            <BlueText> Click here to join </BlueText>
                          </li>
                        )}
                    </>
                  )}
                </Ul>
              </Row2>
            </div>
          ))}
      </div>
    </>
  );
};

export default Booking;
