import React, { useContext, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Modal, Button, Row, Col } from "react-bootstrap";
import TimeAndDate from "./timeanddate";
import DateOnly from "./dateonly";
import moment from "moment";

import { MyContext } from "./App";
import SelectInstructor from "./selectInstructor";
import SelectLocation from "./selectLocation";
import SelectSection from "./selectSection";
import LessonNotes from "./lessonNotes";
import RepeatingDates from "./repeatingDates";
import {
  dateObjToDBDate,
  formatDateTimeForDbEdit,
  dateObjToDisplayDatetime,
  dateISOtoString,
  timeISOtoString,
  datetimeInfo,
  formatDateTimeForDB,
  calculate_expiry_date,
  calculate_makeup_start_date,
  currentDayToDB,
} from "./momentUtils";
import {
  ModalHeaderBlue,
  ButtonBlue,
  ButtonOrange,
  ButtonOrangeDisable,
  ButtonOrangeMed,
  ButtonBlueMed,
} from "./formComponents";
import AuthHelperMethods from "./AuthHelperMethods";
import TextInputTitle from "./textInputTitle";
import Notifications from "./notification";
import { CalendarStateButton } from "./perm.js";
import { useAlert } from "react-alert";
import getRepeatFirstLast from "./getLastDate";
import Select from "react-select";
import { HelpText } from "./styleCustom";
import COLORS from "./styleConstants";
import SelectCancelMakeupAction from "./selectCancelMakeupAction";
const Auth = new AuthHelperMethods();

const Filler = () => {
  return <div />;
};

let components = {
  reschedule: TimeAndDate,
  editTitle: TextInputTitle,
  editDateandtime: TimeAndDate,
  editInstructor: SelectInstructor,
  editLocation: SelectLocation,
  editSection: SelectSection,
  duplicate: TimeAndDate,
  cancel: SelectCancelMakeupAction,
  editNotes: Filler,
  delete: Filler,
  changeExistingToRecurring: RepeatingDates,
  extendRecurring: DateOnly,
};

const successmsgpart = (lessonIn) => {
  return (
    ` ${lessonIn.title}  on ${dateISOtoString(lessonIn.scheduled_date)}  at  ${timeISOtoString(
      lessonIn.scheduled_starttime
    )}` || "note yet"
  );
};
let x = " this event here ";
const returnMsgMap = (editAction, x, applyToFuture) => {
  //  let x = successmsgpart(eventnameanddatetime) || " no date time yet ";
  let firstpart = ` for ${x} has been successfully updated ${applyToFuture ? "for this and all future events" : ""}.`;

  switch (editAction) {
    case "reschedule":
      return `Event ${x} has been successfully rescheduled`;

    case "duplicate":
      return `Event ${x} has been successfully duplicated`;

    case "cancel":
      return `Event ${x} has been successfully canceled`;

    case "delete":
      return `Event ${x} has been successfully deleted`;

    case "changeExistingToRecurring":
      return `Event ${x} has been changed to a recurring event`;

    case "extendRecurring":
      return `Event ${x} has been sucessfully extended.`;

    case "editTitle":
      return `Title ${firstpart}`;

    case "editDateandtime":
      return `Date and Time  ${firstpart}`;
    case "editInstructor":
      return `Instructor ${firstpart}`;
    case "editLocation":
      return `Location ${firstpart}`;
    case "editSection":
      return `Section `;
    case "editNotes":
      return `Note ${firstpart}`;
    default:
      return "";
  }
};

const urlDispatch = (x) => {
  switch (x) {
    case "delete":
      return "delete";
    case "changeExistingToRecurring":
      return "changeExistingToRecurring";
    case "extendRecurring":
      return "extendRecurring";
    case "duplicate":
      return "duplicate";
    case "cancel":
    case "reschedule":
      return "reschedule";

    case "editTitle":
    case "editDateandtime":
    case "editInstructor":
    case "editLocation":
    case "editSection":
    case "editNotes":
      return "update";
    default:
      return "";
  }
};

const ModalGeneral = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  let history = useHistory();
  const { start, end, is_allday } = mystate.dateTimeComponent;
  //  const [applyToFuture, setApplyToFuture] = useState(false);
  const applyToFuture = mystate.applyToFuture;
  const [showEditMessage, setShowEditMessage] = useState(false);

  const {
    lesson_notes,
    location_id,
    instructor_id,
    id,
    title,
    lesson_date_time_series_id,
    scheduled_date,
    section_id,
    section_name,
    lessonString,
  } = mystate.currentLesson;
  const { lesson_notes_old } = mystate.currentLessonOld;
  const alert = useAlert();

  const TagName = components[mystate.editAction];

  const handleModalClose = () => {
    dispatch({ type: "HIDE_DEFAULT_MODAL" });
    setShowEditMessage(false);
  };

  const saveevent = () => {
    let editParams = {};
    editParams.editAction = mystate.editAction;
    editParams.id = id;
    editParams.sendNotification = mystate.sendNotification;
    editParams.calendarDateRangeStart = mystate.calendarDateRangeStart;
    editParams.calendarDateRangeEnd = mystate.calendarDateRangeEnd;
    editParams.lesson_string = lessonString;
    editParams.msgHead = lessonString;

    if (mystate.sendNotification) editParams.updated_notification_message = mystate.updated_notification_message;
    if (lesson_notes) editParams.lesson_notes = lesson_notes;
    if (applyToFuture) {
      editParams.applyToFuture = "true";
    }
    if (lesson_date_time_series_id) {
      editParams.lesson_date_time_series_id = lesson_date_time_series_id;
      editParams.scheduled_date = scheduled_date;
    }

    editParams.expiry_date = dateObjToDBDate(
      calculate_expiry_date(scheduled_date, mystate.farmDefaults.makeup_expiry_days || 30)
    );
    editParams.makeup_start_date = dateObjToDBDate(
      calculate_makeup_start_date(scheduled_date, mystate.farmDefaults.makeup_start_before_days || 14)
    );
    editParams.purchase_date = currentDayToDB();

    const extendRecurring = (editParams) => {
      let lastDate = dateObjToDBDate(mystate.dateOnly);
      let dbParams = { ...editParams, lastDate };
      sendToDb(dbParams);
    };
    switch (mystate.editAction) {
      case "changeExistingToRecurring":
        changeExistingToRecurring(editParams);
        return;
      case "extendRecurring":
        extendRecurring(editParams);
        return;
      case "reschedule":
      case "duplicate":
      case "cancel":
        rescheduleEvent(editParams);
        return;
      case "delete":
        //deleteEvent(editParams);
        sendToDb(editParams);
        return;
      default:
        saveAndPost(editParams);
    }
  };

  /*function updateLessonMessage() {
    let updatedcurmsg = mystate.updated_lesson_message;
    let prevNotes = lesson_notes
      ? `    ### previous note: ${lesson_notes_old}`
      : " ";
    return updatedcurmsg + prevNotes;
  }*/

  const getDateTimeEditParams = () => {
    //  return formatDateTimeForDbEdit({ start, end, is_allday });
    return formatDateTimeForDbEdit({ start, end, is_allday });
  };

  const editParametersMap = {
    editSection: { section_id: section_id, title: section_name },
    editLocation: { location_id: location_id },
    editInstructor: {
      instructor_id: instructor_id,
      lesson_date_time_series_id: lesson_date_time_series_id,
    },
    editTitle: { title: title },
    editNotes: { lesson_notes: lesson_notes },
    editDateandtime: getDateTimeEditParams(),
  };

  const saveAndPost = async (editParams) => {
    editParams = { ...editParams, ...editParametersMap[mystate.editAction] };

    sendToDb(editParams);
  };

  const changeExistingToRecurring = async (editParams) => {
    if (mystate.currentLesson.is_recuring) {
      alert.show("This event is already a recurring event.", {
        timeout: 2000,
        type: "error",
      });
      return;
    }

    //  editParams = { ...editParams, ...getDateTimeEditParams() };
    console.log(editParams);
    const repeatObj = getRepeatFirstLast(mystate, editParams);
    console.log(repeatObj);
    const datetimeInfo = formatDateTimeForDB(mystate.dateTimeComponent);
    console.log(datetimeInfo);

    const dbParams = { ...repeatObj, ...datetimeInfo };
    console.log(dbParams);

    sendToDb(dbParams);
  };

  const getRescheduledMessage = (from, to) => {
    let rescheduledFrom = dateObjToDisplayDatetime(from);
    let rescheduledTo = dateObjToDisplayDatetime(to);
    let notesfrom = `Rescheduled from ${rescheduledFrom}`;
    let notesto = `Rescheduled to ${rescheduledTo}`;
    return [notesfrom, notesto];
  };

  const sendToDb = async (editParams) => {
    let urlvar = urlDispatch(mystate.editAction);
    console.log(urlvar);
    console.log(mystate.editAction);

    //  let refresh = calendarRefresh(mystate.editAction, applyToFuture);
    //  console.log(refresh);
    let eventnamedate = successmsgpart(mystate.currentLesson);
    dispatch({ type: "FETCH_INIT" });
    try {
      const result = await Auth.api(`lessons/${urlvar}`, "post", editParams);
      setShowEditMessage(true);

      dispatch({ type: "DELETE_AND_REFRESH", payload: result.data }); //??ignore other //??  don't need this anymore since n                 ot closing edit event automatically after api so now just need to update currentevent

      dispatch({ type: "FETCH_SUCCESS" });
      dispatch({ type: "HIDE_DEFAULT_MODAL" });

      alert.show(returnMsgMap(mystate.editAction, eventnamedate, mystate.applyToFuture), {
        timeout: 3000,
        type: "success",
      });
      if (mystate.editAction == "editDateandtime") history.push("/admin/calendarGroup/calendar");
    } catch (error) {
      dispatch({ type: "FETCH_ERROR" });
      alert.show(error.message, { timeout: 3000, type: "error" });
    }
  };
  const setMakeupAction = (lessonId) => {
    console.log(lessonId);
  };

  const rescheduleEvent = async (editParams) => {
    dispatch({ type: "FETCH_INIT" });
    if (editParams.editAction === "cancel") {
      editParams.cancelAction = mystate.currentLesson.barn_cancel_makeup_action;
      editParams.cancelReason = mystate.currentLesson.barn_cancel_reason;
      editParams.notesto = mystate.updated_lesson_message;
    }

    editParams = { ...editParams, ...getDateTimeEditParams() };
    if (mystate.editAction == "reschedule") {
      let rsmg = getRescheduledMessage(mystate.currentLesson.start, start);
      editParams.notesfrom = rsmg[0];
      editParams.notesto = rsmg[1];
    }
    sendToDb(editParams);
  };

  return (
    <Modal show={mystate.showDefaultModal} onHide={handleModalClose} size="md" centered>
      <Modal.Header
        closeButton
        style={{
          backgroundColor: `${mystate.applyToFuture ? COLORS.orange : "#66c2d7"}`,
          color: "#FFFFFF",
          borderRadius: "2px",
        }}
      >
        <Modal.Title>{mystate.modalTitle} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {mystate.currentLesson.is_recuring && mystate.showRecurringChoice && (
          <p>
            {" "}
            Changes will be applied to {mystate.applyToFuture ? "this and all future events." : "this event only."}
          </p>
        )}
        {mystate.editMessage && <Row style={{ fontStyle: "italic", paddingBottom: "10px" }}>{mystate.editMessage}</Row>}

        {(mystate.editAction && <TagName addOrEdit="edit" />) || <div />}
        {mystate.editAction == "editNotes" && (
          <Row>
            {" "}
            <LessonNotes />
          </Row>
        )}

        {mystate.showNotify && <Notifications />}
        <Row />
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: "flex", justifyContent: "space-evenly", flex: "1 1 100%", zIndex: 1000 }}>
          <ButtonBlueMed label={"Cancel"} onClick={handleModalClose} />

          <ButtonOrangeDisable label={"Save Changes"} onClick={saveevent} />
        </div>

        <CalendarStateButton />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalGeneral;
