import React, { useReducer, Fragment, useState, useEffect, useContext } from "react";
import ReactDom from "react-dom";
import moment from "moment";
import Settings from "./UserSettings";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import RepeatingDates from "./repeatingDates";
import { DISPLAY_DATETIME, DISPLAY_DATE, DISPLAY_TIME, DATEPICKER_DISPLAY } from "./UserSettings";

import {
  getdateinfo,
  calculateEndTime,
  DBTIMEFORMAT,
  DBDATEFORMAT,
  DBDATETIMEFORMAT,
  dateObjToDisplayDate,
  dateObjToDisplayDatetime,
  valueFromId,
  repeatOptions,
  currentTimeToDB,
  currentDayToDB,
} from "./momentUtils";
import { MyContext } from "./App";
import messageMap from "./messageMap";

const LessonNotes = () => {
  const { mystate, dispatch } = useContext(MyContext);
  //const [editNotes, setEditNotes] = useState(false);
  const handleInputChange = (e) => {
    dispatch({
      type: "UPDATE_LESSON_MESSAGE",
      message: e.target.value,
    });
  };

  const handleFinishNoteEdit = () => {
    dispatch({ type: "SAVE_LESSON_MESSAGE_EDIT" });
  };

  const handleInputChangeEditNotes = (e) => {
    dispatch({
      type: "HANDLE_LESSON_NOTES_INPUT",
      message: e.target.value,
    });
  };

  const from = () => {
    let rescheduledFrom = mystate.dateTimeComponent.is_allday
      ? dateObjToDisplayDate(mystate.currentLesson.start)
      : dateObjToDisplayDatetime(mystate.currentLesson.start);
    return rescheduledFrom;
  };

  const to = () => {
    let rescheduledTo = mystate.dateTimeComponent.is_allday
      ? dateObjToDisplayDate(mystate.dateTimeComponent.start)
      : dateObjToDisplayDatetime(mystate.dateTimeComponent.start);
    return rescheduledTo;
  };

  /*const updateRescheduleNotificationMessage = editactionin => {
    return lessonMessageMap[editactionin];
  };*/

  const lessonMessageMap = {
    reschedule: "Lesson has been rescheduled from " + from() + " to " + to(),
    cancel: "Lesson has been canceled",
    editInstructor: `Instructor has been changed from ${mystate.currentLessonOld.instructor_name} to ${
      mystate.currentLesson.instructor_name == mystate.currentLessonOld.instructor_name
        ? ""
        : mystate.currentLesson.instructor_name
    }`,
    editNotes: mystate.currentLesson.lesson_notes || "",
    editLocation: `Location has been changed from ${mystate.currentLessonOld.location_name}
to ${mystate.currentLesson.location_name}`,
  };

  useEffect(
    () => {
      console.log("useEffect Lesson Notes");
      console.log(lessonMessageMap[mystate.editAction]);
      console.log(mystate.editAction);
      dispatch({
        type: "UPDATE_LESSON_MESSAGE",
        message: lessonMessageMap[mystate.editAction],
      });
    },
    //eslint-disable-next-line
    [
      mystate.currentLesson.instructor_id,
      mystate.currentLesson.location_id,
      mystate.dateTimeComponent.is_allday,
      mystate.dateTimeComponent.start,
      mystate.currentLesson.lesson_notes,
    ]
  );

  return (
    <>
      <>
        {mystate.editAction !== "editNotes" && (
          <div
            style={{
              margin: "5px",
              minWidth: "400px",
              minHeight: "50px",
              fontStyle: "italic",
            }}
          >
            {messageMap(mystate.editAction, mystate.currentLesson, mystate.currentLessonOld, mystate.dateTimeComponent)}
          </div>
        )}
      </>

      {mystate.editActionx == "editNotes" && (
        <>
          <textarea
            autoFocus
            style={{ minWidth: "400px", minHeight: "100px", whiteSpace: "pre-line" }}
            onChange={(e) => handleInputChange(e)}
            defaultValue={mystate.currentLessonOld.lesson_notes}
            onBlur={() => handleFinishNoteEdit()}
          />
        </>
      )}

      {mystate.editAction == "editNotes" && (
        <>
          <textarea
            autoFocus
            style={{ minWidth: "100%", minHeight: "100px", whiteSpace: "pre-line" }}
            onChange={(e) => handleInputChangeEditNotes(e)}
            onBlur={() => handleFinishNoteEdit()}
            defaultValue={mystate.currentLessonOld.lesson_notes}
            rows={5}
          />
        </>
      )}
    </>
  );
};

export default LessonNotes;
