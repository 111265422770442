import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, NavLink, useParams } from "react-router-dom";

import { ListContext } from "./App";
import { MyContext } from "./App";
import {
  PrimaryText,
  Td,
  StyledTr,
  TableText1,
  TableText2,
  Th,
  HelpText,
  StyledLink,
  PrimaryTextBlue,
  OrangeButton,
  BlueText,
  GreyHeaderText,
} from "./styleCustom";
import { TableHeader2 } from "./formComponents";
import axios from "axios";
import AuthHelperMethods from "./AuthHelperMethods";
import COLORS from "./styleConstants";
import { CheckSquare, Check } from "./icons";
import SelectTemplateFilter from "./searchFilterReports";
import Select from "react-select";
import PrivateRoute from "./privateRoute";
import styled from "styled-components";
import { dateISOtoStringMed, dateISOtoStringShortWithYear, calculateAge } from "./momentUtils";
const Auth = new AuthHelperMethods();

export const DynamicTemplate = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [waiver, setWaiver] = useState();

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const result = await Auth.api("waiver/dynamic", "post");
          setWaiver(result.data);
        } catch (e) {
          console.log(e);
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );
  if (!waiver) return null;
  return <div>dyanmic template</div>;
};

export const WaiverRetrieve = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [waiver, setWaiver] = useState();
  const [image, setImage] = useState();
  const params = useParams();
  const [guardian, setGuardian] = useState();
  const [custom, setCustom] = useState();
  const [customWaiverValues, setCustomWaiverValues] = useState();
  let [riders, setRiders] = useState([]);
  console.log(props.waiver);
  console.log(props.waiver.participants);

  //var participants;
  console.log(params);
  useEffect(
    () => {
      dispatch({ type: "FETCH_INIT" });

      const fetchData = async () => {
        try {
          //  const result = await Auth.api("waiver/waiverRetrieve", "get", params);

          setWaiver(props.waiver);
          //  setImage(props.waiver.pdf);
          //  console.log(result.data.waiver.waiver.customWaiverFields);

          const { participants, guardian, customWaiverFields } = props.waiver;

          setCustomWaiverValues(Object.values(customWaiverFields));
          const { customParticipantFields } = participants;

          // console.log(Object.values(customParticipantFields));
          if (participants) setRiders(participants);
          if (guardian) setGuardian(guardian);
          if (customParticipantFields) setCustom(customParticipantFields);
          for (const [key, value] of Object.entries(props.waiver)) {
            console.log(`${key}: ${value}`);
          }
          dispatch({ type: "FETCH_SUCCESS" });
        } catch (e) {
          console.log(e);
          dispatch({ type: "FETCH_ERROR", msg: e });
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    [props.waiver]
  );
  const getWaiver = async (waiverId) => {
    let params = { id: waiverId };
    const result = await Auth.api("waiver/waiverRetrievePdf", "get", params);
    if (result.data.waiver) downloadPDF(result.data.waiver);
  };

  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = waiver ? `${waiver.title}_${waiver.firstName}${waiver.lastName}.pdf` : "waiver.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    setImage(downloadLink);
    downloadLink.click();
  }
  //<a href={`data:application/pdf;base64,${waiver.PDF}`}>dunno</a>

  const ParticpantsNotUsed = () => {
    //  console.log(Object.keys(participants).map((key) => <div> {key}</div>));

    const { customParticipantFields } = riders;
    console.log(riders);
    console.log("customParticipantFields");
    console.log(customParticipantFields);

    if (!riders && !riders.length > 0) return null;

    return (
      <div>
        <h1>participants</h1>
        {Object.keys(riders).map((key, i) => (
          <>
            <div>{"customParticipantFields"}</div>
            <div>{`key : ${key}`}</div>
            <div>{`value : ${riders[key]}`}</div>
          </>
        ))}
      </div>
    );
  };

  const OtherMainFields = (props) => {
    const GreyHeaderText = styled.span`
      color: ${COLORS.textgrey};
    `;

    const {
      driversLicenseNumber,
      driversLicenseState,
      emergencyContactName,
      emergencyContactPhone,
      expirationDate,
      insuranceCarrier,
      insurancePolicyNumber,
      verified,
    } = props.waiver;

    const Row = (props) => {
      if (!props.value) return null;
      return (
        <>
          <GreyHeaderText>{`${props.desc}:`}</GreyHeaderText>
          <div>{props.value}</div>
        </>
      );
    };

    return (
      <>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Row desc={`Drivers License Number`} value={driversLicenseNumber} />
          <Row desc={`Drivers License State`} value={driversLicenseState} />
          <Row desc={`Emergency Contact Name`} value={emergencyContactName} />
          <Row desc={`Emergency Contact Phone`} value={emergencyContactPhone} />
          <Row desc={`Insurance Carrier`} value={insuranceCarrier} />
          <Row desc={`Insurance Policy Number`} value={insurancePolicyNumber} />
          <Row desc={`Waiver Expiration Date`} value={expirationDate} />
          <Row desc={`Waiver Verified`} value={verified ? "yes" : "no"} />
        </div>
      </>
    );
  };
  const GetPeopleNames = () => {
    if (!riders) return null;
    return (
      <div style={{ display: "flex", minWidth: 400, flexDirection: "column" }}>
        <GreyHeaderText>{"This waiver includes the following people:"}</GreyHeaderText>
        {riders.map((item) => (
          <div style={{ display: "flex", maxWidth: 400, flexGrow: 1 }}>
            <div style={{ display: "flex", flex: "1 1 40%" }}>{`${item.firstName} ${item.lastName}`}</div>
            <div style={{ display: "flex", flex: "1 1 15%" }}>{item.isMinor && "minor"}</div>
            {item.dob != "1800-01-01" && (
              <>
                <div style={{ display: "flex", flex: "1 1 35%" }}>{`${dateISOtoStringShortWithYear(item.dob)}`}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>{`age ${calculateAge(item.dob)} `}</div>
              </>
            )}{" "}
          </div>
        ))}
        {guardian && (
          <div>
            <GreyHeaderText>{`Guardian Specified On Waiver:`}</GreyHeaderText>
            <div>{`${guardian["firstName"]} ${guardian["lastName"]}`}</div>
            <div>{`${guardian["relationship"]} `}</div>
            <div>{`${guardian["phone"]} `}</div>
          </div>
        )}
        <GreyHeaderText>{`Main Email:`}</GreyHeaderText>
        <div>{waiver.email}</div>
        <GreyHeaderText>{`Address:`}</GreyHeaderText>
        <div>{`${waiver.addressLineOne} ${waiver.addressLineTwo}`}</div>
        <div>{`${waiver.addressCity} ${waiver.addressState} ${waiver.addressZip}`}</div>
        <div>{`${waiver.addressCountry} `}</div>
      </div>
    );
  };

  const Participants = () => {
    //  console.log(Object.keys(riders).map((key) => <div> {key}</div>));
    //if (!guardian) return null;
    return (
      <div>
        {riders.map(
          (participant) =>
            participant["customParticipantFields"] && (
              <div>
                <PrimaryText>{`Additional Participant Information For ${participant.firstName}`}</PrimaryText>

                {Object.values(participant["customParticipantFields"]).map((item) => (
                  <>
                    <PrimaryText> {item.displayText} </PrimaryText>
                    <div>{item.value}</div>
                  </>
                ))}
              </div>
            )
        )}
      </div>
    );
  };

  const CustomWaiverFields = () => {
    if (!customWaiverValues) return null;
    if (!Object.entries(customWaiverValues).length > 0) return null;
    return (
      <>
        <PrimaryText> {"Other"} </PrimaryText>
        {customWaiverValues.map((item) => (
          <>
            <PrimaryText>{`${item.displayText}: `}</PrimaryText>
            <div>{`${item.value}`}</div>
          </>
        ))}
      </>
    );
  };

  if (!waiver) return null;
  if (!riders && !riders.length > 0) return <div>"noparit"</div>;
  return (
    <div
      style={{
        display: "flex",
        flex: "1 1 100%",
        flexWrap: "wrap",
        flexDirection: "column",
        marginBottom: 10,
      }}
    >
      <PrimaryText style={{ marginTop: 10 }}>{`${waiver.title}`} </PrimaryText>
      <div style={{ cursor: "pointer", flexGrow: 1 }} onClick={() => getWaiver(waiver.waiverId)}>
        <span style={{ cursor: "pointer", color: COLORS.orange }}>{"download pdf"}</span>
      </div>
      <div>{`Waiver created on ${dateISOtoStringMed(waiver.createdOn)}`}</div>

      <GetPeopleNames />
      <OtherMainFields waiver={props.waiver} />
      <Participants />
      <CustomWaiverFields />
    </div>
  );
};

export const TemplateListRetrieve = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [waiver, setWaiver] = useState();
  const [currentTemplate, setCurrentTemplate] = useState();
  const [currentWaiverList, setCurrentWaiverList] = useState([]);
  const [templateList, setTemplateList] = useState();
  const [list, setList] = useState();

  useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        if (props.person_id) params.autoTag = props.person_id;
        try {
          const result = await Auth.api("waiver/templateListRetrieve", "get", params);
          //  const result = await Auth.api("waiver/getAccountWaiverList", "get");

          setTemplateList(result.data.templates);
          setList(result.data.waiverList);
          dispatch2(["SET_TEMPLATE_LIST", result.data.templates]);

          console.log(result.data.templates);
        } catch (e) {
          console.log(e);
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  const getWaiverList = async (templateId) => {
    setCurrentTemplate(templateId);

    try {
      let params = { id: templateId };
      const result = await Auth.api("waiver/waiverList", "get", params);
      setCurrentWaiverList(result.data.waiver.waivers);
      console.log(result.data.waiver.waivers);
    } catch (e) {
      console.log(e);
    }
  };

  if (!templateList) return null;
  return (
    <>
      <div
        style={{
          display: "flex",
          flex: "1 1 100%",
          flexWrap: "wrap",
          marginBottom: 30,
        }}
      >
        <TableHeader2 title={"Waiver List"} />

        {templateList.map((template) => (
          <>
            <div
              className={"mapWaiver"}
              key={template.templateId}
              style={{
                display: "flex",
                flexDirection: "row",
                flex: "1 1 100%",
              }}
            >
              <div style={{ marginRight: 30, display: "flex", flexDirection: "row", xflex: "1 1 30%" }}>
                {template.templateId}
              </div>
              <div>{template.title}</div>
              <button onClick={() => getWaiverList(template.templateId)}>{"waiverlist"}</button>

              <div>
                {" "}
                <a style={{ cursor: "pointer" }} href={template.webUrl}>
                  {"click to sign waiver"}
                </a>
              </div>
            </div>
            {currentTemplate && currentTemplate == template.templateId && (
              <div
                style={{
                  display: "flex",
                  flex: "1 1 100%",
                  flexWrap: "wrap",
                  marginBottom: 30,
                }}
              >
                <div style={{ display: "flex", minWidth: 400, flexDirection: "column" }}>
                  <div> {`Waiver list for Template ${template.templateId}`}</div>
                  {currentWaiverList &&
                    currentWaiverList.map((waiver) => (
                      <div key={waiver.waiverId} style={{ display: "flex" }}>
                        <div
                          style={{ display: "flex", flex: "1 1 25%" }}
                        >{`${waiver.firstName}   ${waiver.lastName}`}</div>
                        <div style={{ display: "flex", flex: "1 1 50%" }}></div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </>
        ))}
        {list &&
          list.map((item) => (
            <>
              <div style={{ display: "flex", flex: "1 1 100%", flexDirection: "column" }}>
                <div>{`Template ${item.templateId}`}</div>
                {item.waiverlist.waivers &&
                  item.waiverlist.waivers.map((waiver) => (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div>{`waiverid ${waiver.waiverId} name ${waiver.firstName} client ${waiver.autoTag} ${waiver.tags[0]}`}</div>
                    </div>
                  ))}
              </div>
            </>
          ))}
      </div>{" "}
    </>
  );
};

export const WaiverRetrieveList = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [waiver, setWaiver] = useState();
  let ids = useParams();
  let template_id = ids.id;
  console.log("WaiverRetrieveList");

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          let params = { id: template_id };
          const result = await Auth.api("waiver/waiverList", "get", params);
          setWaiver(result.data.waiver.waivers);
          console.log(result.data.waiver.waivers);
        } catch (e) {
          console.log(e);
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    [ids.id]
  );

  //<a href={`data:application/pdf;base64,${waiver.PDF}`}>dunno</a>

  if (!waiver) return null;
  if (!waiver.length > 0) return <div>{"No signed waivers"}</div>;

  return (
    <>
      <div
        style={{
          display: "flex",
          flex: "1 1 100%",
          flexWrap: "wrap",
          marginBottom: 30,
        }}
      >
        <TableHeader2 title={`Completed ${waiver[0].title} List`} />
        <div style={{ display: "flex", minWidth: 400, flexDirection: "column" }}>
          {waiver.map((waiver) => (
            <div key={waiver.waiverId} style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: "1 1 25%" }}>{`${waiver.firstName}   ${waiver.lastName}`}</div>
              <div style={{ display: "flex", flex: "1 1 50%" }}>
                <Link to={`/admin/admin/waivers/WaiverRetrieve/${waiver.waiverId}`}>{waiver.title}</Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default WaiverRetrieve;
