import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { ListContext, MyContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import { currentDayToDB } from "./momentUtils";
import { useAlert } from "react-alert";
import { useParams, useHistory } from "react-router-dom";

const Auth = new AuthHelperMethods();

export const CreatePaymentAndItems = ({ onFinish = () => {}, isInvoicePayment = false, paymentId = undefined }) => {
  //! After Stripe payment is confirmed (& manual) , create a payment record and an item record in db and email receipt
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [payment, setPayment] = useState();
  let history = useHistory();

  const createReceiptDetails = () => {
    let receipt_details = listState.cartProducts.map((detail) => ({
      ...detail,
      description: detail.product_name,
      amount: Number(detail.product_price).toFixed(2),
      student_id: detail.student_id,
      student_name: detail.student_name,
    }));

    let params = {};
    params.billing_person_id = listState.accountHolder.billing_person_id || listState.accountHolder.account_billing_id;
    params.accountHolderPeopleId = listState.accountHolder.id;
    params.student_id = listState.quickpaystudentid || null;
    params.person_id = mystate.currentStudent.person_id || null;
    params.name = listState.accountHolder.person_name;
    params.email = listState.accountHolder.contact_email;
    params.subtotal = listState.cartSubtotal;
    params.total = listState.cartTotal;
    params.amount = listState.cartTotal;
    if (listState.cartFee > 0) params.fee = listState.cartFee || 0;
    return receipt_details;
  };

  console.log(isInvoicePayment);
  console.log(paymentId);

  useEffect(
    () => {
      return () => {
        //! had to not do this as it would clear payment intent and cart when switching from saved payment method to entering card method
        //! had to put back iin - not clearing manual payments
        console.log("CLERARING CARTTTT");
        dispatch2(["CLEAR_CART"]);
        dispatch2(["SET_CLIENT_PAYMENT", {}]);
        dispatch2(["SET_INVOICE_PAYMENT", {}]);
      };
    },
    //eslint-disable-next-line
    []
  );

  /*async function checkForClientPayment(student_id) {
    const params = { student_id: student_id };
    const result = await Auth.api("payments/checkIfClientHasAPaymentMethodOnFile", "get", params);
    console.dir(result.data);
    return await result.data.stripe_customer_id;
  }
*/
  //useEffect(() => {}, [listState.clientPayment]);

  useEffect(() => {
    // dispatch({ type: "FETCH_INIT" });

    console.log(listState.clientPayment ? "TRUE" : "FALSE");
    console.log("IS THIS BEING RENDERED?");
    //if (listState.clientPayment.transaction_type == "online")
    const fetchData = async () => {
      let receipt_details = listState.cartProducts.map((detail) => ({
        ...detail,
        description: detail.product_name,
        amount: Number(detail.product_price).toFixed(2),
        student_id: detail.student_id,
        student_name: detail.student_name,
      }));
      let params = {};
      params.billing_person_id =
        listState.accountHolder.billing_person_id || listState.accountHolder.account_billing_id;
      params.accountHolderPeopleId = listState.accountHolder.id;
      params.account_holder_id = listState.accountHolder.id;
      params.student_id = listState.quickpaystudentid || null;
      params.is_quickpay = listState.quickpaystudentid ? true : false;
      params.name = listState.accountHolder.person_name;
      params.person_id = mystate.currentStudent.person_id;
      params.email = listState.accountHolder.contact_email;
      params.subtotal = listState.cartSubtotal;
      params.total = listState.cartTotal;
      params.amount = listState.cartTotal;
      params.fee = listState.cartFee;
      params.tax1 = listState.cartTax;
      if (listState.currentInvoicePayment) {
        params.is_invoice_payment = listState.currentInvoicePayment.is_invoice || "no";
        params.payment_id = listState.currentInvoicePayment.payment_id || 0;
      }

      params.date = listState.clientPayment.purchase_date || currentDayToDB();
      params.transaction_type = listState.clientPayment.transaction_type;
      params.payment_notes = listState.clientPayment.payment_notes;
      params.reference = listState.clientPayment.reference;
      params.online_purchase = 0;
      params.receipt_details = JSON.stringify(createReceiptDetails());
      params.is_quickpay = listState.quickpaystudentid;
      let item_details = [];
      console.log(params);
      let result = await Auth.api("payments/createPaymentFromStripe", "post", params);
      console.log(result.data);
      setPayment(result.data);
      console.log(payment);

      dispatch({ type: "FETCH_SUCCESS" });

      // console.log(result.data);
    };
    // Object.entries(objectToCheck).length === 0;
    // if (Object.entries(listState.clientPayment) > 0) fetchData();

    if (listState.clientPayment.transaction_type) fetchData();
  }, [listState.clientPayment]);

  useEffect(() => {
    console.log("DOSE THEIS UE EFFECT RUN?");
    console.log(payment);

    const fetchData = async () => {
      let itemarray = [];
      listState.cartProducts.forEach(function async(arrayItem) {
        console.log(arrayItem);
        arrayItem = {
          ...arrayItem,
          payment_id: payment.newpayment.id,
          price: arrayItem.product_price,
        };
        if (listState.quickpaystudentid) {
          arrayItem = { ...arrayItem, quickpay: 1 };
        }

        itemarray = [...itemarray, arrayItem];
        console.log(arrayItem);
        //createitems(arrayItem);
      });

      console.log(itemarray);
      let params = { ...payment.newpayment };
      params.item_details = JSON.stringify(itemarray);
      let result = await Auth.api("items/createMultiple", "post", params);
      console.log(result.data);
      if (result.data && onFinish) onFinish(result.data);
    };

    if (payment) fetchData();
  }, [payment]);

  return null;
};
