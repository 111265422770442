import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Link, useParams, useHistory, withRouter, useLocation } from "react-router-dom";
import { Row, Col, Modal, Accordion, Dropdown, Button } from "react-bootstrap";
import { ListContext, MyContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import {
  BlueNavLink12,
  PrimaryText,
  PrimaryText18,
  PrimaryTextBlue,
  SaveButtonSm,
  CancelButtonSm,
  PageTitle,
  BlueText,
  LinkTextBlue,
  HelpText,
  BlockLabelGrow,
  SaveButton,
} from "./styleCustom";

import {
  currentDayToDB,
  timeISOtoString,
  dateISOtoStringMed,
  is_expired,
  calculate_expiry_date,
  calculate_makeup_start_date,
  calculateCancellationNotice,
  expiredColor,
  in_future,
  dateObjToDBDate,
} from "./momentUtils";
import {
  ModalHeaderBlue,
  UserCan,
  Dialog,
  OrangeButton,
  BlueButton,
  BlueButtonReg,
  OrangeButtonReg,
  orangebuttonstyle,
  bluebuttonstyle,
  DialogNoFooter,
} from "./formComponents";
import { useAlert } from "react-alert";
import { cancel_student_options } from "./Constants2";
import Select from "react-select";
import COLORS from "./styleConstants";
import PrivateRoute from "./privateRoute";
import CancelStudentModal from "./cancelStudentModal";

const Auth = new AuthHelperMethods();

const paidfunction = (lesson) => {
  let textpaid = "";
  textpaid = lesson.paid ? "paid" : "not paid";
  if (lesson.makeup_lesson_id > 0) textpaid = "makeup";
  return { ...lesson, paid: textpaid };
};

export const ItemModal = () => {
  let ItemId = useParams().id;
  let history = useHistory();
  let params = { item_id: ItemId };

  const [item, setItem] = useState();
  const [showModal, setShowModal] = useState(false);
  console.log("ITEMMODAL");
  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const result = await Auth.api("items/studentPurchase", "get", params);
          setItem(result.data.data);
          setShowModal(true);
          // console.log(result.data);
        } catch (e) {}
      };
      fetchData();

      return () => {
        setItem();
      };
      //  if (result) {
      // console.log(result.data);
    },

    //eslint-disable-next-line
    []
  );
  if (!item) return null;
  return (
    <>
      {item && (
        <Modal show={showModal} onHide={() => history.goBack()}>
          <ModalHeaderBlue>
            <Modal.Title>{"Package Details"}</Modal.Title>
          </ModalHeaderBlue>
          <Modal.Body>
            <div style={{ color: COLORS.blue }}>{"package"}</div>

            <div>{item.product_name}</div>
            <div style={{ color: COLORS.blue }}>{"purchase date"}</div>

            <div>{item.purchase_date}</div>
            <div style={{ color: COLORS.blue }}>{"package start date"}</div>

            <div>{item.package_start_date}</div>
            <div style={{ color: COLORS.blue }}>{"expiry date"}</div>

            <div style={{ color: COLORS.blue }}> {is_expired(item.package_expiry_date) ? "expired " : "expires "} </div>
            <div
              style={{
                color: is_expired(item.package_expiry_date) ? "red" : null,
              }}
            >
              {" "}
              {item.package_expiry_date}
            </div>
            <div style={{ color: COLORS.blue }}>{"start count"}</div>
            <div>{item.start_count}</div>

            <div style={{ color: COLORS.blue }}>{"remaining"}</div>

            <div>{item.remain}</div>
            <div style={{ color: COLORS.blue }}>{"notes"}</div>

            <div> {item.item_notes}</div>
          </Modal.Body>
          <Modal.Footer>
            <CancelButtonSm onClick={() => history.goBack()}>Close</CancelButtonSm>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export const LessonTable = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [makeups, setMakeups] = useState(props.makeups || []);

  const [currentLessonPersonObj, setCurrentLessonPersonObj] = useState();
  let history = useHistory();
  const location = useLocation();
  const alert = useAlert();

  const onPurchasesPage = location.pathname.endsWith("/purchases");
  console.log(onPurchasesPage);
  const onHistoryPage = location.pathname.endsWith("/history");

  const profileOnly = location.pathname.startsWith("/admin/calendarGroup/edit");

  const [lessons, setLessons] = useState([]);
  const [showAdminCancel, setShowAdminCancel] = useState(false);
  const [cancelReason, setCancelReason] = useState();
  const [currentLessonPersonId, setCurrentLessonPersonId] = useState();
  const [lessonToCancel, setLessonToCancel] = useState();

  const PackageInfoLink = (props) => {
    //  if (onPurchasesPage) return <div>purchase</div>;
    const { item } = props;
    if (onPurchasesPage) return null;
    return (
      <>
        <div
          style={{
            display: "flex",
            flex: "1 1 ",

            minWidth: 102,
          }}
        >
          <Link to={`${props.match.url}/package/${item}`}>{item && "package"}</Link>
        </div>
      </>
    );
  };

  const DialogBody = () => {
    const [noteInput, setNoteInput] = useState("");
    const [isEligible, setIsEligible] = useState(false);

    useEffect(
      () => {
        if (lessonToCancel) {
          setIsEligible(calculateCancellationNotice(lessonToCancel, mystate.farmDefaults.cancel_notice_hours));
        }
        return () => {
          console.log("setLessonToCancel");
          setLessonToCancel();
        };
      },
      //eslint-disable-next-line
      []
    );

    console.log(lessonToCancel);
    /* const isEligible = () => {
      calculateCancellationNotice(
        lessonToCancel,
        mystate.farmDefaults.cancel_notice_hours
      );
    };*/

    const handleCancelCancel = () => {
      dispatch2(["SET_DIALOG_SHOW", false]);
      // setLessonToCancel();
    };

    const cancelCurrentLesson = async () => {
      if (!lessonToCancel) return null;
      console.log(lessonToCancel);
      dispatch({ type: "FETCH_INIT" });

      var lessonName, studentName, params, lessonPerson, personId, contactEmail, instructorId;

      lessonName = lessonToCancel.lesson_name;
      studentName = listState.currentClient.person_name;
      personId = listState.currentClient.id;

      contactEmail = listState.currentClient.contact_email;

      let enrollment_status_code = isEligible ? mystate.farmDefaults.client_cancel_default_action : "CNM";

      /* ? lessonToCancel.item_id  //! changing this Dec 1/21 so that it doesn't have to be paid to be awarded a makeup.
          ? mystate.farmDefaults.client_cancel_default_action
          : "CNA"
        : "CNM";*/
      const emailmsg = `${studentName} has cancelled ${lessonName || "event"}  on ${dateISOtoStringMed(
        lessonToCancel.scheduled_date
      )} at ${timeISOtoString(lessonToCancel.scheduled_starttime)} with ${lessonToCancel.instructor_name}.
        `;
      params = {
        id: lessonToCancel.id,
        cancellation_note: noteInput,
        cancel_note: noteInput,
        enrollment_status_code: enrollment_status_code,
        newEnrollStatus: enrollment_status_code,
        scheduled_date: lessonToCancel.scheduled_date,
        purchase_date: currentDayToDB(),
        student_name: studentName,
        msg: emailmsg,
        person_id: personId,
        instructor_id: lessonToCancel.instructor_id,
        contactEmail: contactEmail,
      };

      if (cancelReason == "CWM" || "CWA") {
        console.log("getMakeupLessonExpiry");
        params.expiry_date = dateObjToDBDate(
          calculate_expiry_date(lessonToCancel.scheduled_date, mystate.farmDefaults.makeup_expiry_days || 30)
        );
        params.makeup_start_date = dateObjToDBDate(
          calculate_makeup_start_date(
            lessonToCancel.scheduled_date,
            mystate.farmDefaults.makeup_start_before_days || 14
          )
        );
      }
      try {
        //  const result = await Auth.api("lesson_people/studentCancelOwnLesson", "post", params);
        const result = await Auth.api("lesson_people/cancelStudent", "post", params);

        dispatch2(["SET_DIALOG_SHOW", false]);
        if (result) {
          alert.show(
            ` ${listState.currentClient.person_name} has cancelled their lesson on ${dateISOtoStringMed(
              lessonToCancel.scheduled_date
            )}. `,
            {
              timeout: 5000,
              type: "success",
            }
          );
          dispatch2(["UPDATE_LESSON_CANCEL", result.data.lessonpeopletoreturn[0]]);
        }
        dispatch({ type: "FETCH_SUCCESS" });

        //  history.push(`/admin/clienthome/${listState.currentClient.id}/lessons`);
      } catch (error) {
        dispatch({ type: "FETCH_ERROR" });

        console.log(error);
      }
    };
    if (!lessonToCancel) return null;
    return (
      <>
        <div style={{ fontSize: 14, color: "#4d4d4d" }}>
          <div>
            {`Cancel ${lessonToCancel.lesson_name} for ${listState.currentClient.person_name} on ${dateISOtoStringMed(
              lessonToCancel.scheduled_date
            )} ${timeISOtoString(lessonToCancel.scheduled_starttime)}`}
          </div>
        </div>
        <div style={{ color: "red", fontWeight: "bold", marginTop: 10 }}>
          {!isEligible &&
            `Please note that you are cancelling with less than the required ${mystate.farmDefaults.cancel_notice_hours} hours notice. `}
        </div>

        <div
          style={{
            minHeight: 100,
            minWidth: 300,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <BlockLabelGrow>{"add cancellation note (optional)"}</BlockLabelGrow>
          <textarea
            style={{
              flex: "1 1 100%",
              flexWrap: "wrap",
              alignContent: "flex-start",
            }}
            rows="6"
            onChange={(e) => setNoteInput(e.target.value)}
          />
        </div>

        <div
          style={{
            fontSize: 16,
            margin: 15,
            display: "flex",
            justifyContent: "center",
          }}
        >
          Confirm Lesson Cancellation
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            paddingBottom: 30,
          }}
        >
          <button style={bluebuttonstyle} onClick={() => handleCancelCancel()}>
            {" "}
            Don't Cancel
          </button>
          <button
            className="shadow-none"
            style={orangebuttonstyle}
            onClick={() =>
              cancelCurrentLesson(
                lessonToCancel,
                mystate.farmDefaults.cancel_notice_hours,
                mystate.farmDefaults.client_cancel_default_action
              )
            }
          >
            Yes Cancel Lesson
          </button>
        </div>
      </>
    );
  };

  const handleLessonClick = (lesson) => {
    //? student user
    console.log(lesson);
    dispatch2(["SET_DIALOG_SHOW", true]);
    setLessonToCancel(lesson);
  };
  const CancelLink = (props) => {
    let { lesson } = props;

    return (
      <div
        style={{
          display: "flex",
          flex: "1 1",
          justifyContentx: "flex-start",
          minWidth: 100,
          alignItems: "flexStart",
        }}
      >
        {!profileOnly && !lesson.student_is_cancelled && (
          <UserCan isThis="ADM">
            <div style={{ cursor: "pointer" }} className="adminCancel">
              <Link to={`${props.match.url}/modalCancel/${lesson.id}`}>
                {" "}
                <LinkTextBlue>cancel</LinkTextBlue>
              </Link>
            </div>
          </UserCan>
        )}
        {mystate.farmDefaults.clients_can_cancel && (
          <UserCan isThis="STU" {...props}>
            {!onHistoryPage && !profileOnly && !onPurchasesPage && !lesson.student_is_cancelled && (
              <div>
                {" "}
                <div className="cancelLesson" style={{ cursor: "pointer" }} onClick={() => handleLessonClick(lesson)}>
                  <LinkTextBlue>cancel lesson</LinkTextBlue>
                </div>
              </div>
            )}
          </UserCan>
        )}
      </div>
    );
  };

  function getMakeup(makeup_id) {
    const help = props.makeups.filter((makeup) => makeup.id == makeup_id);
    const makeup = help[0] || {};

    return `from ${makeup.lesson_name} - ${dateISOtoStringMed(makeup.scheduled_date)} ${timeISOtoString(
      makeup.scheduled_starttime
    )}`;
  }

  const blockStyle = {
    padding: 5,

    paddingLeft: 0,

    width: 100,
    xminWidth: 150,
    xmaxWdith: 150,
  };
  const shortblockStyle = {
    ...blockStyle,
    width: 75,
  };

  if (props.lessons.length == 0 && !props.hideText) return <div>no records recorded</div>;
  if (!props.lessons.length > 0) return null;

  return (
    <>
      {" "}
      <DialogNoFooter modalTitle={"Cancel Lesson"}>
        <DialogBody />
      </DialogNoFooter>
      {props.lessons.map((lesson) => (
        <div
          style={{
            display: "flex",
            color: COLORS.tablegrey,
            borderBottom: "solid 1pt lightgrey",
            padding: 10,
            flexWrap: "wrap",
            background: lesson.color && lesson.color,
            maxWidthx: 800, //was making it two columns on wide screens
          }}
          key={lesson.id}
        >
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: "1 1  100%",
                fontWeight: "bold",
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}
            >
              <div style={{ paddingRight: 5 }}> {dateISOtoStringMed(lesson.scheduled_date)}</div>
              <div stylex={blockStyle}>{timeISOtoString(lesson.scheduled_starttime)}</div>
            </div>

            <div
              className="row2"
              style={{
                display: "flex",

                flex: "1 1 100%",
                flexWrap: "wrap",
              }}
            >
              <div style={{ width: 200 }}>{lesson.lesson_name} </div>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: 200 }}>
                <div stylex={{ padding: 5, paddingBottom: 1 }}>{lesson.instructor_name} </div>
                <div stylex={{ paddingTop: 0 }}>{lesson.horse_name ? `${lesson.horse_name}` : ""}</div>
              </div>
              <div style={{ width: 100 }}>{lesson.item_id && <PackageInfoLink {...props} item={lesson.item_id} />}</div>

              <div style={{ width: 200 }}>
                <div
                  style={{
                    color: lesson.student_is_cancelled && "red",
                  }}
                >
                  {lesson.student_is_cancelled ? (-lesson.lesson_is_cancelled ? "barn cancel" : "student cancel") : ""}
                </div>

                {lesson.student_is_cancelled && <div>{lesson.enrollment_status_name}</div>}
                <div style={{ width: 100 }}> {!onPurchasesPage && <CancelLink {...props} lesson={lesson} />}</div>
              </div>
            </div>
            <div
              className={"cancellationNotes"}
              style={{
                paddingTop: 1,
                display: "flex",
                flex: "1 1 100%",
                flexWrap: "wrap",
              }}
            >
              {props.makeups && lesson.makeup_lesson_id && (
                <>
                  <div>
                    {lesson.makeup_lesson_id ? `This is a Makeup Lesson  ${getMakeup(lesson.makeup_lesson_id)}` : ""}
                  </div>
                </>
              )}

              {lesson.cancellation_note && <div>{`Cancellation Note: ${lesson.cancellation_note}`}</div>}
            </div>
            {lesson.lesson_notes && mystate.farmDefaults.show_general_lesson_notes_on_client_page && (
              <div
                className={"lessonNotes"}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flex: "1 1 100%",
                  fontStyle: "italic",
                  whiteSpace: "pre-wrap",
                }}
              >
                {`Lesson Notes:  ${lesson.lesson_notes}`}
              </div>
            )}
          </>
        </div>
      ))}
      <Switch>
        <PrivateRoute path={`${props.match.path}/modalCancel/:lpid`} component={CancelStudentModal} />
        <PrivateRoute path={`${props.match.url}/package/:id`} component={ItemModal} />
      </Switch>
    </>
  );
};
