import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, NavLink } from "react-router-dom";
import { TableHeaderNoAdd, OrangeLinkButton } from "./formComponents";
import { Row, Col } from "react-bootstrap";
import RoleForm from "./roleForm";
import RoleTable from "./roleTable";
import RoleAssignPermission from "./roleAssignPermission";
import { Container, Row1, Row2, PageTitle } from "./styleCustom";
import PrivateRoute from "./privateRoute";

const Roles = (props) => {
  return (
    <Container style={{ flexWrap: "wrap", flex: "0 0 100%" }}>
      <Route path={`/admin/setup/roles/rolepermission/:id`} component={RoleAssignPermission} />
      <div style={{ flex: "1 1 100%" }}>
        <TableHeaderNoAdd title="Roles">
          <Link to={`${props.match.url}/roleform/add`}>
            <OrangeLinkButton>{"Add New Role"} </OrangeLinkButton>{" "}
          </Link>
        </TableHeaderNoAdd>
        <Switch>
          <Route path={`/admin/setup/roles/`} component={RoleTable} />
        </Switch>
      </div>

      <div style={{ order: 1, flex: "0 0 auto" }}>
        <Row>
          <Route path={`${props.match.url}/roleform/:addoredit`} component={RoleForm} />
        </Row>
      </div>
    </Container>
  );
};

export default Roles;
