import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, NavLink } from "react-router-dom";

import { ListContext } from "./App";
import { MyContext } from "./App";
import { PrimaryText, Td, StyledTr, TableText1, TableText2, Th, HelpText, StyledLink } from "./styleCustom";
import axios from "axios";
import AuthHelperMethods from "./AuthHelperMethods";
import COLORS from "./styleConstants";
import { CheckSquare, Check } from "./icons";
import SelectTemplateFilter from "./searchFilterReports";
import Select from "react-select";
import PrivateRoute from "./privateRoute";

const Auth = new AuthHelperMethods();

const RoleTable = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [currentRoleId, setCurrentRoleId] = useState(999);

  //  useFetch("roles/index", "ROLES_GET", "dispatchlist");
  //  useFetch("role_permissions/index", "ROLE_PERMISSIONS_GET", "dispatchlist");
  const isAdmin = true;

  const dot = (isSelected) => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: isSelected ? COLORS.orange : "white",
      borderRadius: 2,
      content: '""',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const colourStyles = {
    control: (styles, isFocused) => ({
      ...styles,
      minWidth: 150,
      maxWidth: 150,
      maxHeight: 40,
      overflow: "visible",
      border: isFocused ? "none" : "none",
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        ...dot(isSelected),
        background: "white",
        color: isSelected ? COLORS.orange : "darkgrey",

        padding: 2,
        fontSize: 12,
        minWidth: 150,
        maxWidth: 150,
        minHeight: 20,
      };
    },
    singleValue: (styles) => ({ ...styles }),
    multiValue: (styles, { data }) => ({
      ...styles,
      order: 2,
      border: "none",
      background: "white",
      color: COLORS.orange,

      overflow: "visible",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: COLORS.orange,
      flex: "1 0 auto",
      overflow: "visible",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: COLORS.orange,
    }),

    placeholder: (styles, state) => ({
      ...styles,
    }),
    menuList: (styles) => ({
      ...styles,
      display: "flex",
      flexWrap: "wrap",
    }),
    menu: (styles) => ({
      ...styles,
      left: -100,
      top: "",
      width: 300,
      display: "flex",
      flexWrap: "wrap",
    }),
    container: (provided, isFocused) => ({
      ...provided,
      border: isFocused ? "none" : "none",
      minWidth: 150,
      maxWidth: 150,
    }),
    valueContainer: (styles, isFocused) => ({
      ...styles,
      overflowx: "visible",
      border: isFocused ? "none" : "none",
    }),

    input: (styles) => ({
      ...styles,
      order: 1,
    }),
    indicatorsContainer: (styles) => ({
      ...styles,

      maxHeight: 40,
    }),
  };

  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("roles/index", "get");
        dispatch2(["ROLES_GET", result.data]);
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("role_permissions/index", "get");
        if (result) dispatch2(["ROLE_PERMISSIONS_GET", result.data]);
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      console.log("useeffet");
      const fetchData = async () => {
        let params = {};
        const result = await Auth.api("permissions/index", "get", params);
        dispatch2(["PERMISSIONS_GET", result.data]);
      };
      fetchData();

      //  }
    },
    //eslint-disable-next-line
    []
  );

  const handleClickEditRole = (role) => {
    dispatch2(["SET_CURRENT_LIST_DETAIL", role]);
    let formInitialValues = {
      id: role.id,
      name: role.role_name || "",
      desc: role.description || "",
      user_code: role.user_code || "",
    };

    dispatch2(["SET_FORM_INITIAL_VALUES", formInitialValues]);
  };

  const getSingleRolePermissions = (roleId) => {
    console.log("getSingleRolePermissions");
    return (
      listState.rolePermissions &&
      listState.rolePermissions
        //.filter(item => item.role_id === roleId)
        //.map(item2 => item2.name)
        .reduce((acc, { role_id, name }) => {
          if (role_id === roleId) {
            acc.push(name);
          }
          return acc;
        }, [])
        .join(", ")
    );
  };

  const getArrayOfOptions = (roleId) => {
    return (
      listState.rolePermissions &&
      listState.rolePermissions
        .filter((item) => item.role_id == roleId)
        .map((permis) => ({ value: permis.permission_id, label: permis.name }))
    );
  };
  console.log(listState.options_permission);

  const handleSelectChange = (selectedOption, filterType, props) => {
    console.log(selectedOption);
    console.log(selectedOption.pop());
    let latest = selectedOption.pop();
    if (latest) {
      // addPermissionToRole(latest);
    } else {
      /* let currentStatePermissionArray = listState.rolePermissions
      .filter((rp) => rp.role_id == currentRoleId)
      .map((item) => item.permission_id);
    /*let currentStatePermissionArray = currentRolePermissionsFilter.map(
      (item) => item.permission_id
    );
*/
      /*  let selectedvalues = selectedOption.map((item) => item.value);

    let deletedPermissionId = currentStatePermissionArray.filter(
      (x) => !selectedvalues.includes(x)
    );

    if (deletedPermissionId.length) {
      unassign(deletedPermissionId);
    }
    if (deletedPermissionId.length == 0) {
      let addedPermissionId = selectedvalues.filter(
        (x) => !currentStatePermissionArray.includes(x)
      );

      addPermissionToRole(addedPermissionId);
    }*/
    }
  };
  const addPermissionToRole = async (permission) => {
    console.log(permission);
    let params = {};
    params.role_id = currentRoleId;
    params.permission_id = permission.value;

    params.name = permission.label;
    dispatch2(["ROLE_PERMISSION_CREATE", params]);
    // cheating doing dispatch first
    let result = await Auth.api("role_permissions/create", "post", params);
  };

  const unassign = async (deletedPermissionId) => {
    let params = {};
    params.role_id = currentRoleId;
    params.permission_id = deletedPermissionId[0];
    // cheating doing dispatch first

    dispatch2(["ROLE_PERMISSION_DELETE", params]);
    let result = await Auth.api("role_permissions/delete", "post", params);
  };

  return (
    <>
      {listState.roles && (
        <table style={{ width: "100%" }}>
          <thead>
            <tr style={{ color: `${COLORS.blue}` }}>
              <Th>
                {" "}
                <div>{"Name"}</div>
                <HelpText>{"Click on Role Name to edit"}</HelpText>
              </Th>
              <Th>Description</Th>

              <Th>
                <div> {"Valid Permissions"}</div>
              </Th>
            </tr>
          </thead>
          <tbody>
            {listState.roles.length > 0 &&
              listState.roles.map((role) => (
                <StyledTr key={role.id} recent={role.recent}>
                  <Td>
                    <StyledLink to={`${props.match.url}/roleform/edit`} onClick={() => handleClickEditRole(role)}>
                      <TableText2>{role.role_name} </TableText2>
                    </StyledLink>
                  </Td>
                  <Td>
                    <TableText2>
                      {`${role.description}
                    `}
                    </TableText2>
                  </Td>

                  <Td>
                    <Link to={`/admin/setup/roles/rolepermission/${role.id}`}>
                      <TableText2>
                        {(listState.rolePermissions &&
                          listState.rolePermissions
                            .filter((item) => item.role_id == role.id)
                            .map((permission) => permission.name)
                            .join(", ")) ||
                          "no permissions assigned"}
                      </TableText2>
                    </Link>
                  </Td>
                </StyledTr>
              ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default RoleTable;
