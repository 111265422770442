import React, { useReducer, Fragment, useState, useEffect, useContext } from "react";
import axios from "axios";
import Select from "react-select";
import { MyContext } from "./App";
import { valueFromId } from "./momentUtils";
import { customColors } from "./styleConstants";
import AuthHelperMethods from "./AuthHelperMethods";
import { map_options_instructor } from "./Constants2";
import { ActiveCheck } from "./formComponents";
const Auth = new AuthHelperMethods();

const SelectInstructor = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const [active, setActive] = useState(true);
  //const [inactiveOptions, setInactiveOptions] = useState({});
  const [options, setOptions] = useState();

  useEffect(
    () => {
      const fetchData = async () => {
        //  let params = { is_active: active ? 1 : 0 };

        console.log("**************** INSTRUCTOR FETCH *****************");
        try {
          const result = await Auth.api("instructors/index", "get");
          let options = result.data.options.map((option) => ({ ...option, color: option.color_code }));
          console.log(options);
          dispatch({
            type: "GET_INSTRUCTOR_OPTIONS",
            options: options,
          });

          //  setOptions(map_options_instructor(result.data.instructors));
        } catch (error) {
          console.log("error");
        }
      };

      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  const handleInstructorChangeAdd = (event) => {
    dispatch({
      type: "SET_NEW_EVENT",
      data: { instructor_id: event.value },
    });
  };

  const handleInstructorChangeEdit = (event) => {
    //console.log("handleInstructorChangeEdit");
    //console.log(event);

    let d = valueFromId(mystate.options_instructor, event.value);
    console.log(d);
    dispatch({
      type: "EDIT_CURRENT_INSTRUCTOR",
      data: d,
    });
  };
  function onClickActive() {
    setActive(!active);
  }
  //  const instructor_id = props.instructor_id || mystate.addevent.instructor_id;

  return (
    <>
      {mystate.options_instructor && (
        <div className="selectInstructor">
          <Select
            onChange={props.addOrEdit == "add" ? handleInstructorChangeAdd : handleInstructorChangeEdit}
            value={mystate.options_instructor.filter(
              (option) =>
                option.value ==
                (props.addOrEdit == "add" ? mystate.addevent.instructor_id : mystate.currentLesson.instructor_id)
            )}
            options={mystate.options_instructor}
            clearable={true}
            searchable={false}
            placeholder={"Select Staff..."}
            styles={customColors}
          />
        </div>
      )}
    </>
  );
};

export default SelectInstructor;
