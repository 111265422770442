//import { useContext } from "react";
//import { MyContext } from "./App";
import moment from "moment";
import { dateObjToDBDate } from "./momentUtils";

const getRepeatFirstLast = (mystate, editParamsIn) => {
  //  const { mystate, dispatch } = useContext(MyContext);

  //console.log("use Section handleSectionChange");
  //console.log(event);
  let firstDate = mystate.addevent.start || mystate.currentLesson.start;
  let measure = mystate.repeat.measure;
  let frequency = mystate.repeat.frequency;
  let units = mystate.repeat.units;
  let endtype = mystate.repeat.endtype;
  let freqencyMultiplyUnits = units * frequency;
  //  console.log(freqencyMultiplyUnits);
  let nextDate = moment(firstDate)
    .add(measure, units)
    .toDate();
  console.log(nextDate);

  let lastdate = endtype => {
    console.log(endtype);
    switch (endtype) {
      case "dates":
        return mystate.repeat.repeatChooseEndDate;

      case "times":
        return moment(firstDate)
          .add(measure, freqencyMultiplyUnits - 1)
          .toDate();

      case "never":
        return moment(firstDate)
          .add("years", 1)
          .toDate();

      default:
        return "";
    }
  };

  let lastDate = dateObjToDBDate(lastdate(endtype));
  firstDate = dateObjToDBDate(firstDate);
  nextDate = dateObjToDBDate(nextDate);
  let editParamsOut = {
    ...editParamsIn,
    frequency,
    measure,
    endtype,
    units,
    lastDate,
    firstDate,
    nextDate,
    type: endtype
  };
  console.log("firstDate");
  console.log(firstDate);
  console.log(nextDate);
  console.log(lastDate);
  console.log(editParamsOut);
  return editParamsOut;
};

export default getRepeatFirstLast;
