import React, { useState, useEffect } from "react";
import "./toggle.css";

function ToggleSwitch(props) {
  console.log(props);
  const [checked, setChecked] = useState();
  const [Text] = useState(props.Text);

  useEffect(
    () => {
      setChecked(props.defaultChecked);
    },
    //eslint-disable-next-line
    []
  );

  function onChange(e) {
    setChecked(e.target.value);
    if (props.onToggleChange) props.onToggleChange(e);
  }

  return (
    <div className={"toggle toggle-switch"}>
      <input
        type="checkbox"
        name={props.Name}
        className="toggle-switch-checkbox"
        id={props.id}
        defaultChecked={props.defaultChecked}
        onChange={onChange}
      />
      {props.id ? (
        <label className="toggle-switch-label" htmlFor={props.id}>
          <span
            className={props.disabled ? "toggle-switch-inner toggle-switch-disabled" : "toggle-switch-inner"}
            data-yes={"yes"}
            data-no={"no"}
          />
          <span className={props.disabled ? "toggle-switch-switch toggle-switch-disabled" : "toggle-switch-switch"} />
        </label>
      ) : null}
    </div>
  );
}

export default ToggleSwitch;
