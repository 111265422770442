import React, { useContext, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { formatPhoneNumber } from "react-phone-number-input";
import { Link, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import AccountPage from "./accountPage";
import AddModal from "./addModal.js";
import AddNote from "./addNote1"; //from ***addNote1****
import { ListContext, MyContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import { CalendarHomePage } from "./CalendarHomePage";
import { CalendarHomePageBooking } from "./CalendarHomePageBooking";
import { CalendarHomePageMakeupBooking } from "./CalendarHomePageMakeupBooking";
import { StudentPayments } from "./client/payments";
import { BackTo, BackToClose, ButtonOrangeSm, Limit, UserCan } from "./formComponents";
import { ClientAvatar } from "./images";
import { onPage } from "./momentUtils";
import NotesTable from "./notesTable";
import OnlinePurchase from "./onlinePurchase";
import PrivateRoute from "./privateRoute";
import { RegistrationClient } from "./registrationClient";
import ScheduledLessons, { History } from "./scheduledLessons";
import SearchFilterDatesLocal from "./searchFilterDatesLocal";
import StudentInfo from "./studentInfo";
import Purchases from "./studentPurchases";
import COLORS from "./styleConstants";
import { BlueNavLink12, HelpText, PageTitle } from "./styleCustom";
//import ContactForm from "./contactForm";
//import Waiver from "./waiver";
import WaiverClient from "./waiverClient";

const Auth = new AuthHelperMethods();

const labelStyle = { color: "orange" };
const textStyle = { color: "grey" };
/*const StudentInfo = (props) => {
  console.log(props.match.url);
  return <div>{"Student details"}</div>;
};
*/

export const MemberLinks = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);

  let location = useLocation();
  const profileOnly = location.pathname.startsWith("/admin/calendarGroup/edit");

  const Title = () => {
    if (!listState.currentClient.student_id) return null;
    return (
      <PageTitle
        style={{
          display: "flex",
          flexWrap: "wrap",
          flex: " 1 1 100%",
          fontSize: 20,
        }}
      >
        {`${props.page} for ${listState.currentClient.person_name}`}
      </PageTitle>
    );
  };

  return (
    <>
      <Title />
    </>
  );
};
export const onPageWhat = (x) => {
  if (!x) return;
  let last = x.lastIndexOf("/");
  return x.substring(last + 1);
};

export const Notes = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const usertype = Auth.getUserType();
  let location = useLocation();
  const [offset, setOffset] = useState(50);
  const [total, setTotal] = useState();

  const profileOnly = location.pathname.startsWith("/admin/calendarGroup/edit");

  let params = {
    person_id: listState.currentClient.id,
    limit: profileOnly ? 10 : offset,
  };
  if (props.searchDates) params = { ...params, ...props.searchDates };

  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("notes_clients/clientNotes", "get", params);
        dispatch2(["CLIENT_DATA", result.data, "notes"]);
        setTotal(result.data.size);
      };
      fetchData();

      return () => {
        console.log("Clear Notes");
        dispatch2(["CLIENT_DATA", []]);
      };
    },
    //eslint-disable-next-line
    [listState.currentClient.id, offset]
  );
  console.log(props.match.url);

  return (
    <>
      <MemberLinks verb={"View"} page={"Notes"} link={"notes"} />

      <NotesTable {...props} />
      {!profileOnly && <Limit total={total} setOffset={setOffset} data={listState.clientdata || []} offset={offset} />}
    </>
  );
};

const ClientHomePage = (props) => {
  console.log("ClientHomePage");
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);
  const [isLaptop, setIsLaptop] = useState(window.innerWidth > 1000);
  const [showAddClientToLesson, setShowAddClientToLesson] = useState(false);
  const [searchDates, setSearchDates] = useState({});
  const [showDateFilter, setShowDateFilter] = useState(false);

  const alert = useAlert();

  let paramsurl = useParams();

  const [currentclientid, setCurrentclientid] = useState(null);

  const location = useLocation();
  const history = useHistory();

  const profileOnly = location.pathname.startsWith("/admin/calendarGroup/edit");
  //||
  // location.pathname.startsWith("/profile");
  const onAccountPage =
    location.pathname.endsWith("/account") ||
    location.pathname.endsWith("/waivers") ||
    location.pathname.endsWith("/availableWaivers") ||
    console.log(location.pathname);
  let user = Auth.getUserType();

  const clientCanAdd = () => {
    if (user == "ADM") return true;
    if (user == "STU" && mystate.farmDefaults.clients_can_add_lesson) return true;
    if (user == "INS" && listState.userPermissions.includes("STAFF_CAN_ADD_EVENTS")) return true;
    return false;
  };

  useEffect(
    () => {
      console.log("Change Location");
      //  setSearchDates(undefined);
    },
    //eslint-disable-next-line
    [location]
  );

  const clientCanAddToExisting = () => {
    if (user == "ADM") return true;
    // if (user == "STU" && mystate.farmDefaults.clients_can_add_lesson) return true;
    if (user == "STU") return false;
    // if (user == "INS") return true;

    if (user == "INS" && listState.userPermissions.includes("STAFF_CAN_ADD_STUDENTS")) return true;
    return false;
  };

  const clientCanViewCalendar = () => {
    if (user == "ADM") return true;
    if (user == "INS") return true;
    if (user == "STU" && mystate.farmDefaults.clients_can_view_calendar) return true;
    return false;
    // mystate.farmDefaults.
  };

  useEffect(() => {
    if (!profileOnly) window.scrollTo(0, 0);
  }, []);

  useEffect(
    () => {
      // console.log(Auth.getUserType() !== "STU");

      return () => {
        console.log("Clear ClientHome");
        //console.log(Auth.getUserType() !== "STU");
        dispatch2(["CLIENT_DATA", []]);

        dispatch2(["CLEAR_CART"]);
        dispatch2(["CLIENT_CHECKOUT", 0, 0, 0]);
        // if (Auth.getUserType() !== "STU") {
        dispatch2(["CLIENT_UPDATE_ACCOUNT_HOLDER", {}]);
        dispatch2(["CLIENT_GET", {}]);
        dispatch2(["CLIENT_UPDATE_MEMBERS", []]);
        dispatch2(["RESET_SEARCH_FILTER_EXCEPT_DATES"]);

        //}
      };
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      console.log("useeffect clienthomepage");
      dispatch2(["SET_CURRENT_CLIENT_ID", paramsurl.id]); //? absolutecurrentclientid ? needed
      //console.log(currentclientid);

      // i think this else is here for page refresh or something where state.clients is wiped out
      // is so shoule upate account info also
      const fetchData = async () => {
        let params = { person_id: paramsurl.id };
        try {
          dispatch({ type: "FETCH_INIT" });

          const result = await Auth.api("people/getUserClients", "get", params);
          if (result.data) {
            dispatch2(["CLIENT_GET", result.data.currentClient]);
            dispatch2(["CLIENT_GET_ACCOUNT", result.data]);
          }
          if (result.data.members.length == 0) history.push(`${props.match.url}/account`);
          else {
            history.push(`${props.match.url}/details`);
          }
          dispatch({ type: "FETCH_SUCCESS" });
        } catch (e) {
          dispatch({ type: "FETCH_ERROR" });

          alert.show(e.message, {
            timeout: 5000,
            type: "error",
          });
          console.log(e);
        }
      };

      console.log(location.pathname.substring(0, location.pathname.lastIndexOf("/")));
      let last = location.pathname.lastIndexOf("/");

      console.log(location.pathname.substring(last + 1));

      console.log(onPage(location.pathname));

      //if ((listState.clients.length = 0)) fetchData();
      fetchData();

      // if currentClient[0].billing_person_people_id !==listState.accountHolder.id
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      console.log("useeffect clienthomepage just for profile id change");
      dispatch2(["SET_CURRENT_CLIENT_ID", paramsurl.id]);

      const fetchData = async () => {
        let params = { person_id: paramsurl.id };
        try {
          dispatch({ type: "FETCH_INIT" });

          const result = await Auth.api("people/getUserClients", "get", params);
          if (result.data) {
            console.log(result.data.currentClient.student_id);
            dispatch2(["CLIENT_GET", result.data.currentClient]);
            dispatch2(["CLIENT_GET_ACCOUNT", result.data]);
            //dispatch2(["SET_SEARCH_FILTER", result.data.currentClient.student_id], "student");
          }
          if (result.data.members.length == 0) history.push(`${props.match.url}/account`);
          else {
            history.push(`${props.match.url}/details`);
          }
          dispatch({ type: "FETCH_SUCCESS" });
        } catch (e) {
          dispatch({ type: "FETCH_ERROR" });

          alert.show(e.message, {
            timeout: 5000,
            type: "error",
          });
          console.log(e);
        }
      };

      if (profileOnly) fetchData();
    },
    //eslint-disable-next-line
    [paramsurl.id]
  );

  useEffect(
    () => {
      console.log("Change Member");
      if (!profileOnly) dispatch2(["CHANGE_CURRENT_MEMBER", paramsurl.id]);
    },
    //eslint-disable-next-line
    [paramsurl.id]
  );

  const Actions = () => {
    return (
      <>
        {Auth.getUserType() == "STU" && (
          <>
            <UserCan ifFarm={"clients_can_buy_online"} isThis={"STU"}>
              <Link to={`${props.match.url}/online`}>
                <ButtonOrangeSm label={"Buy Package or Lessons"} />
              </Link>
            </UserCan>
            <UserCan ifFarm={"clients_can_view_calendar"} isThis={"STU"}>
              <Link to={`${props.match.url}/calendarview`}>
                <ButtonOrangeSm label={"View Calendar"} />
              </Link>
            </UserCan>
            <UserCan ifFarm={"clients_can_book_lessons"} isThis={"STU"}>
              <Link to={`${props.match.url}/calendarbook`}>
                <ButtonOrangeSm label={"Book Lesson"} />
              </Link>
            </UserCan>

            <UserCan ifFarm={"clients_can_book_makeups"} isThis={"STU"}>
              <Link to={`${props.match.url}/calendarmakeup`}>
                <ButtonOrangeSm label={"View Makeups"} />
              </Link>
            </UserCan>
          </>
        )}
        {Auth.getUserType() == "ADM" && (
          <>
            <Link to={`${props.match.url}/online`}>
              <ButtonOrangeSm label={"Purchase Items / Create Invoice"} />
            </Link>
            {1 == 2 && (
              <Link to={`${props.match.url}/online/invoicecreate`}>
                <ButtonOrangeSm label={"Create Invoice"} />
              </Link>
            )}
            <Link to={`${props.match.url}/calendarview`}>
              <ButtonOrangeSm label={"View Calendar"} />
            </Link>
            <Link to={`${props.match.url}/calendarbook`}>
              <ButtonOrangeSm label={"Book Lesson"} />
            </Link>
            <Link to={`${props.match.url}/calendarmakeup`}>
              <ButtonOrangeSm label={"View Makeups"} />
            </Link>
          </>
        )}
        {mystate.farmDefaults.clients_can_register && 1 == 2 && (
          <Link to={`${props.match.url}/registration`}>
            <ButtonOrangeSm label={"Registration"} />
          </Link>
        )}

        <Link to={`${props.match.url}/addNote/1/client`}>
          <ButtonOrangeSm label={Auth.getUserType() == "STU" ? "Add/Email Note" : "Add Public Note"} />
        </Link>
        <UserCan doThis={"ADD_PRIVATE_NOTE"}>
          <Link to={`${props.match.url}/addNote/0/client`}>
            <ButtonOrangeSm label={"Add Private Note"} />
          </Link>
        </UserCan>
      </>
    );
  };

  const Summary = () => {
    if (!listState.accountHolder) return null;
    if (onAccountPage) return null;
    return (
      <div className={"SummaryLeft"}>
        {Auth.getUserType() == "STU" && (
          <div style={{ color: COLORS.blue, fontSize: 16 }}>{`${mystate.farmDefaults.farm_name} `}</div>
        )}

        <div
          className="currenClient"
          style={{ marginBottom: 30, display: "flex", justifyContent: "center", marginTop: 50 }}
        >
          <span style={{ fontSize: 30, fontWeight: "bold" }}>{listState.currentClient.person_name}</span>
        </div>
        <div style={{ display: "flex", flex: "1 1 100%", justifyContent: "center" }}>
          <ClientAvatar width={"70px"} height={"70px"} />
        </div>
        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <Actions />
        </div>
        <div
          className="contactDisplay"
          style={{ display: "flex", flexDirection: "column", color: COLORS.textgrey, fontSize: 20, marginTop: 20 }}
        >
          <div style={{ display: "flex", fontSize: 16, flexWrap: "wrap" }}>
            {" "}
            {`Account Holder ${listState.accountHolder.person_name}`}
          </div>
          <div style={{ fontSize: 16 }}>
            <div style={{ fontSize: 16, color: COLORS.textgrey }}>
              <a href={`mailto:${listState.accountHolder.contact_email}`}>{listState.accountHolder.contact_email}</a>
            </div>
            <div style={{ fontSize: 16, color: COLORS.textgrey }}>
              <a href={`tel: ${listState.accountHolder.contact_mobile}`}>
                {listState.accountHolder.contact_mobile && formatPhoneNumber(listState.accountHolder.contact_mobile)}
              </a>
            </div>
            <div style={{ fontSize: 16, color: COLORS.textgrey }}>
              <a href={`tel: ${listState.accountHolder.contact_homephone}`}>
                {listState.accountHolder.contact_homephone &&
                  formatPhoneNumber(listState.accountHolder.contact_homephone)}
              </a>
            </div>
          </div>
        </div>

        {listState.members.filter((member) => member.is_active).length > 0 && (
          <>
            <div className="accountMembers">
              <div style={{ display: "flex", fontSize: 16, color: COLORS.textgrey, marginTop: 20 }}>
                {"Account Members"}
              </div>
              <div style={{ display: "flex", fontSize: 12, fontStyle: "italic", color: COLORS.textgrey }}>
                {"Click name to see profile"}
              </div>

              <div>
                {listState.members
                  .filter((member) => member.is_active)
                  .map((member) => (
                    <div>
                      {" "}
                      <Link
                        to={`/admin/clienthome/${member.id}/${onPage(location.pathname)}`}
                        style={{
                          color: member.id == listState.currentClient.id ? COLORS.textgrey : COLORS.blue,
                          fontStyle: "bold",
                          fontSize: 16,
                        }}
                      >
                        {" "}
                        {member.person_name}
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const leftPadding = () => {
    if (profileOnly) return 0;
    if (isMobile) return 0;
    return 20;
  };

  // if (!currentclientid) return null;
  if (!listState.userPermissions) return <div> Permissions Not Yet Loaded</div>;
  if (!listState.currentClient) return <div>loading</div>;
  if (!listState.accountHolder) return null;

  //let params = { h: "h" };

  return (
    <>
      {listState.currentClient && (
        <>
          {!profileOnly && Auth.getUserType() !== "STU" && (
            <div style={{ display: "flex", flex: "1 1 100%" }}>
              {" "}
              {1 == 2 && Auth.getUserType() == "ADM" && (
                <BackTo url={`/admin/${onPage(location.pathname)}`} pagename={"list"} />
              )}
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "flex-start",
              flex: "1 1 100%",
              justifyContent: "center",
              maxWidth: "100vw",
            }}
          >
            {!profileOnly && listState.members.filter((member) => member.is_active).length > 0 && (
              <div
                style={{
                  display: "flex",
                  maxWidth: "30%",
                  minWidth: 250,
                  flexDirection: "column",
                  flex: "1 1 auto",
                  marginBottom: 60,
                }}
              >
                <Summary />
              </div>
            )}
            <div style={{ display: "flex", flex: "1 1 500px" }}>
              <div style={{ display: "flex", flex: "1" }}>
                <div
                  style={{
                    display: "flex",
                    flex: isMobile ? " 1 1 100% " : " 1 1 75%",
                    flexWrap: "wrap",
                    alignContent: "flex-start",
                    paddingLeft: leftPadding(),
                  }}
                  className={"student"}
                >
                  <div
                    className="BlueNav"
                    style={{
                      display: "flex",
                      background: COLORS.blue,
                      justifyContent: "space-evenly",
                      flexWrap: "wrap",
                      flex: " 1 1 100%",
                      alignItems: "center",
                    }}
                  >
                    {listState.currentClient.is_student && (
                      <>
                        {profileOnly && (
                          <BackToClose url={`/admin/calendarGroup/edit/${mystate.currentLesson.id}`} color={"white"} />
                        )}
                        {1 == 2 && clientCanViewCalendar() && !profileOnly && (
                          <>
                            <div style={{ background: "white", color: COLORS.orange, fontWeight: "bold" }}>
                              {"new! =>"}
                            </div>{" "}
                            <BlueNavLink12 to={`${props.match.url}/calendar`}> Calendar</BlueNavLink12>
                          </>
                        )}
                        {1 == 2 && clientCanViewCalendar() && !profileOnly && (
                          <>
                            <div style={{ background: "white", color: COLORS.orange, fontWeight: "bold" }}>
                              {"new! =>"}
                            </div>{" "}
                            <BlueNavLink12 to={`${props.match.url}/calendarview`}> Calendar</BlueNavLink12>
                          </>
                        )}

                        <BlueNavLink12 to={`${props.match.url}/lessons`}> Schedule</BlueNavLink12>
                        <BlueNavLink12 to={`${props.match.url}/history`}> History</BlueNavLink12>
                        <BlueNavLink12 to={`${props.match.url}/notes`}> Notes</BlueNavLink12>
                        <BlueNavLink12 to={`${props.match.url}/purchases`}>Packages</BlueNavLink12>
                        {mystate.farmDefaults.can_use_invoices && !profileOnly && (
                          <>
                            {" "}
                            <BlueNavLink12 to={`${props.match.url}/invoices`}>Invoices</BlueNavLink12>
                            <BlueNavLink12 to={`${props.match.url}/payments`}>Payments</BlueNavLink12>
                          </>
                        )}
                        <BlueNavLink12 to={`${props.match.url}/details`}> Student Details</BlueNavLink12>

                        <UserCan ifFarm={"can_sign_waivers"}>
                          <BlueNavLink12 to={`${props.match.url}/waivers`}> Waivers</BlueNavLink12>
                        </UserCan>

                        {1 == 1 && profileOnly && (
                          <Link to={`${props.match.url}/online`}>
                            <ButtonOrangeSm label={"Buy Package"} />
                          </Link>
                        )}
                      </>
                    )}
                    {!profileOnly && (
                      <>
                        <BlueNavLink12 activeClassName="is-active" to={`${props.match.url}/account`}>
                          Account
                        </BlueNavLink12>
                      </>
                    )}
                  </div>

                  <Switch>
                    <PrivateRoute path={`${props.match.path}/lessons`} component={ScheduledLessons} />
                    <PrivateRoute path={`${props.match.url}/addNote/:isPublic/:who`} component={AddNote} />
                    <PrivateRoute path={`${props.match.path}/history`} component={History} />
                    <PrivateRoute path={`${props.match.url}/purchases`} component={Purchases} />
                    <PrivateRoute path={`${props.match.url}/account`} component={AccountPage} />
                    <PrivateRoute path={`${props.match.url}/waivers`} component={WaiverClient} />
                    <PrivateRoute path={`${props.match.url}/online`} component={OnlinePurchase} />
                    <PrivateRoute
                      path={`${props.match.url}/online/invoicecreate`}
                      component={OnlinePurchase}
                      invoice={"true"}
                    />
                    <PrivateRoute
                      path={`${props.match.url}/notes`}
                      searchDates={searchDates}
                      setShowDateFilter={setShowDateFilter}
                      component={Notes}
                    />
                    <PrivateRoute path={`${props.match.url}/registration`} component={RegistrationClient} />
                    <PrivateRoute path={`${props.match.url}/details`} component={StudentInfo} />{" "}
                    <PrivateRoute
                      exact
                      path={`${props.match.url}/payments`}
                      component={StudentPayments}
                      searchDates={searchDates}
                      setShowDateFilter={setShowDateFilter}
                      currentPage={"payments"}
                    />
                    <PrivateRoute
                      path={`${props.match.url}/invoices/`}
                      component={StudentPayments}
                      searchDates={searchDates}
                      setShowDateFilter={setShowDateFilter}
                      currentPage={"invoices"}
                    />
                    <PrivateRoute
                      path={`${props.match.url}/calendarview`}
                      component={CalendarHomePage}
                      currentStudentId={[listState.currentClient.student_id]}
                    />
                    <PrivateRoute
                      path={`${props.match.url}/calendarbook`}
                      component={CalendarHomePageBooking}
                      currentStudentId={[listState.currentClient.student_id]}
                    />
                    <PrivateRoute
                      path={`${props.match.url}/calendarmakeup`}
                      component={CalendarHomePageMakeupBooking}
                    />
                    <PrivateRoute path={`${props.match.url}/add/:add`} component={AddModal} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {profileOnly && <HelpText>Showing top ten results. To see more, go to client page.</HelpText>}
    </>
  );
};

export default ClientHomePage;
