import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, ListGroup, Modal, Accordion } from "react-bootstrap";
import { ListContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import { BlueText, CancelButtonSm } from "./styleCustom";
import SearchFilter from "./searchFilter";
import { timeISOtoString, currentDayToDB } from "./momentUtils";
import { EditIcon } from "./icons";
import { SelectTemplateFilter } from "./searchFilterReports";
import OnlinePurchase from "./onlinePurchase";
import COLORS, { helpStyle } from "./styleConstants";
import { deleteImg, privateNoteImg, publicNoteImg } from "./images";
import { MyContext } from "./App";
import { dateObjToDisplayDate, dateObjToDisplayTime, DBDATEFORMAT, DBTIMEFORMAT } from "./momentUtils";
import moment from "moment";
import {
  dateObjToDBDate,
  dateISOtoStringMed,
  combineDateAndTimeStrToMomentObj,
  combineDateAndTimeStrToMomentObjNoUtc,
  currentTimeToDB,
} from "./momentUtils";
import { ContainerH, ContainerV, Row2, Ul, PrimaryText18, PrimaryText, HelpText } from "./styleCustom";
import { useAlert } from "react-alert";
import SearchFilterDate from "./searchFilterDates";
import { DISPLAY_DATE, DATEPICKER_DATE_DISPLAY } from "./UserSettings";
import { MemberLinks } from "./clientHomePage";
import { UserCan, Dialog, ButtonBlue, ButtonOrange, CheckBoxSquare } from "./formComponents";
import { ArrowLeft, ArrowDown } from "./icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Info } from "./Calendar";

const Auth = new AuthHelperMethods();

const BookingCalendar = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const alert = useAlert();
  const history = useHistory();
  //const [makeups, setMakeups] = useState([]);
  const [useMakeup, setUseMakeup] = useState(false); //todo create farmDefaultSetting for this

  useEffect(
    () => {
      return () => {
        dispatch2(["SET_CURRENT_BOOKING_LESSON", {}]);
      };
    },
    //eslint-disable-next-line
    []
  );

  const lessonInWords = (lesson) => {
    let msg = `${listState.currentClient.person_name} has been booked for ${lesson.title} on ${dateISOtoStringMed(
      lesson.scheduled_date
    )} ${!lesson.is_allday ? " at " + timeISOtoString(lesson.scheduled_starttime) : ""}  with ${
      lesson.instructor_name
    }.`;
    return msg;
  };
  const addStudentToCalendarLesson = async () => {
    //  setStudentBooking(true);
    let lessonToBook = listState.currentBookingLesson;
    console.log(lessonToBook);
    let params = {};
    params.id = lessonToBook.id;
    params.instructor_id = lessonToBook.instructor_id;
    params.lesson_date_time_id = lessonToBook.id;
    params.student_id = listState.currentClient.student_id;
    params.student_email = listState.accountHolder.contact_email;
    params.person_id = listState.currentClient.id;
    params.account_name = listState.accountHolder.first_name;
    params.makeup_lesson_id = listState.currentClient.makeup_lesson_id || null; //?this doesn't make sense -> doesn't exist
    params.dont_check_availability = true; //? need this
    params.scheduled_date = lessonToBook.scheduled_date;
    params.use_makeup = useMakeup;
    params.msg = lessonInWords(lessonToBook);
    console.log(params);

    try {
      const result = await Auth.api("lesson_people/addStudentToLesson", "post", params);

      if (result) {
        if (result.data.lessonPerson == "already in lesson") {
          // dispatch2(["SET_DIALOG_SHOW", true]);
          alert.show("Student is already booked for this lesson spot");
          //  setStudentBooking(false);
          history.goBack();
        } else if (result.data.lessonPerson == "none available") {
          // setStudentBooking(false);
          history.goBack();
          alert.show("No spots available for this lesson");
        } else {
          dispatch2(["SET_SEARCH_FILTER", moment().format(), "timetorefresh"]);
          // setRefresh(!refresh);
          //  setStudentBooking(false);

          history.goBack();
          alert.show(lessonInWords(lessonToBook), {
            timeout: 5000,
            type: "success",
          });
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  // if (!listState.currentBookingLesson) return null;
  return (
    <>
      <h1>Booking Calendar</h1>
      <Modal show={true} size="md" centered>
        <Modal.Header style={{ background: COLORS.blue, color: "white" }}>
          <Modal.Title>{`Add ${listState.currentClient.person_name} To This Lesson?`} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ color: COLORS.blue, fontSize: 16 }}>
            {dateISOtoStringMed(listState.currentBookingLesson.scheduled_date)}
          </div>
          <Info currentevent={listState.currentBookingLesson} />

          <CheckBoxSquare
            check={useMakeup}
            onClick={() => setUseMakeup(!useMakeup)}
            label={"Use makeup if available?"}
          />
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flex: "1 1 100%",
            }}
          >
            <ButtonOrange onClick={() => addStudentToCalendarLesson()} label={"Add"} />
            <ButtonBlue onClick={() => history.goBack()} label={"Cancel"} />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BookingCalendar;
