import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, useParams } from "react-router-dom";
import { Row, Col, Button, ListGroup, Modal, Accordion } from "react-bootstrap";
import { ListContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import { BlueText } from "./styleCustom";
import SearchFilter from "./searchFilter";
import { timeISOtoString } from "./momentUtils";
import { EditIcon } from "./icons";

import OnlinePurchase from "./onlinePurchase";
import COLORS from "./styleConstants";
import { deleteImg, privateNoteImg, publicNoteImg } from "./images";
import { MyContext } from "./App";
import { dateObjToDisplayDate, dateObjToDisplayTime } from "./momentUtils";
import moment from "moment";
import { dateObjToDBDate } from "./momentUtils";
import { ContainerH, ContainerV, Row1, Ul } from "./styleCustom";
import { useAlert } from "react-alert";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
const Auth = new AuthHelperMethods();

const PublicAvailability = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  const [localFilter, setLocalFilter] = useState({
    dbday: dateObjToDBDate(moment().toDate()),
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const [lessonToBook, setLessonToBook] = useState();

  useEffect(
    () => {
      console.log("ORIGINAL []");

      const fetchData = async () => {
        console.log("********** ORIGINAL LESSONS GET **************");
        let params = {
          //scheduled_date: mystate.calendarDateRangeStart,
          //scheduled_end_date: mystate.calendarDateRangeEnd,

          scheduled_date: dateObjToDBDate(moment().startOf("month").toDate()),
          scheduled_end_date: dateObjToDBDate(moment().endOf("month").toDate()),
        };
        // setResourceView(mystate.farmDefaults.default_day_view || "default");

        try {
          console.log("time to run");
          let result = await Auth.api("lessons/index", "get", params);
          if (result) {
            dispatch({ type: "GET_ALL_EVENTS", payload: result.data });

            //    dispatch({ type: "FETCH_SUCCESS" });
            //  setRefresh(false);
          }
        } catch (error) {
          alert.show("Sorry, there has been an error sending the data.  Please try again.", {
            timeout: 50000,
            type: "error",
          });

          dispatch({ type: "FETCH_ERROR" });
        }
      };

      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  const handleModalClose = () => {
    dispatch2(["SET_CONFIRM_SHOW", false]);
  };
  const alert = useAlert();

  let incoming = useParams();
  let public_id = incoming.public_id;
  console.log(public_id);

  const checkIfInLesson = () => {
    const lesson = lessonToBook;
    lesson.student_id = listState.currentClient.student_id;
  };

  const handleClickLesson = (lesson) => {
    //  dispatch2(["SET_DIALOG_SHOW", true]);
    //  setShowConfirm(true);
    //setLessonToBook(lesson);
    addStudentToLesson(lesson);
  };

  const addStudentToLesson = async (lessonToBook, isMakeup) => {
    console.log(lessonToBook);
    let lesson = lessonToBook;
    lesson.student_id = listState.currentClient.student_id;
    let packageurl = isMakeup ? "items/everything" : "items/makeup";
    // let item_id = incoming.is_makeup || 0;
    if (incoming.item_id) lesson = { ...lesson, item_id: incoming.item_id };
    console.log(incoming.is_makeup);
    try {
      const result = await Auth.api("lesson_people/addStudentToLesson", "post", lesson);
      if (result) {
        if (result.data.lessonPerson == "already in lesson") {
          dispatch2(["SET_DIALOG_SHOW", true]);
          alert("Student is already booked for this lesson spot");
        } else {
          alert.show("added to lesson", { timeout: 5000, type: "success" });

          let lessonPersonId = result.data.lessonPerson.id;

          let params = {
            student_id: lesson.student_id,

            lesson_person_id: lessonPersonId,
          };
          if (!incoming.item_id) Auth.api(`items/everything`, "get", params);
          dispatch2(["SET_CONFIRM_SHOW", true]);
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const updateLocalFilter = (filter) => {
    console.log(filter);
    setLocalFilter({ ...localFilter, ...filter });
  };

  useEffect(() => {
    //  if (listState.filter.current == "daysheet") {
    console.log("run");
    const fetchData = async () => {
      let params = localFilter;
      params = { ...params, public_id: public_id };
      try {
        const result = await Auth.api("lessons/public", "get", params);

        let updated = result.data.lessons.map((lesson) => ({
          ...lesson,
          available: parseInt(lesson.max_enrollment) - parseInt(lesson.cnt),
        }));

        dispatch2(["GET_DAYSHEET_CLIENTS", updated]);
      } catch {
        return "error";
      }
    };
    fetchData();
    //  }//
    //esnt-disable-next-line
  }, [dispatch2, localFilter]);

  const birthdayStyle = `.DayPicker-Day--x {
    background-color: orange;
    color: white;
  }`;
  const before = `.DayPicker-Day--y {
    background-color: white;
    color: orange;
  }`;

  const modifiers = {
    x: { after: new Date(2021, 1, 19) },
    y: { before: new Date(2021, 1, 19) },
  };
  const handleDayClick = (day) => {
    console.log(day);
  };

  return (
    <>
      <div style={{ minWidth: 300, minHeight: 300 }}>
        <style>{birthdayStyle}</style>
        <style>{before}</style>

        <DayPicker
          modifiers={modifiers}
          selectedDays={[new Date(2021, 1, 21), new Date(2021, 1, 23)]}
          onDayClick={handleDayClick}
        />
      </div>

      {listState.daysheetClients &&
        listState.daysheetClients.map(
          (lesson) =>
            parseInt(lesson.available) > 0 && (
              <div
                className="BookingRow"
                style={{
                  display: "flex",
                  flex: "1 1 100%",
                  border: "solid 1pt grey",
                  marginBottom: 10,
                }}
              >
                {" "}
                <Row1
                  style={{
                    background: lesson.color_code || "lightgrey",
                    maxWidth: 30,
                    minWidth: 30,
                    marginRight: 10,
                  }}
                />
                <Row1>
                  <Ul style={{ listStyleType: "none" }}>
                    <li> {lesson.title}</li>
                    <li>{`${timeISOtoString(lesson.scheduled_starttime)} -  ${timeISOtoString(
                      lesson.scheduled_endtime
                    )}`}</li>
                  </Ul>
                </Row1>
                <Row1>
                  <Ul style={{ listStyleType: "none" }}>
                    <li>{lesson.instructor_name || "Staff unassigned"}</li>
                    <li>{lesson.location_name}</li>
                  </Ul>
                </Row1>
                <Row1>
                  <Ul style={{ listStyleType: "none" }}>
                    <li>
                      {lesson.available > 1
                        ? `${lesson.available} available spots `
                        : `${lesson.available} available spot `}
                    </li>
                    <li onClick={() => handleClickLesson(lesson)} style={{ cursor: "pointer" }}>
                      <BlueText> Click here to join lesson</BlueText>
                    </li>
                  </Ul>
                </Row1>
              </div>
            )
        )}
    </>
  );
};

export default PublicAvailability;
