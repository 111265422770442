import React, { useState, useContext, useEffect } from "react";
import { SelectTemplateFilter } from "./searchFilterReports";
import AuthHelperMethods from "./AuthHelperMethods";
import { ListContext, MyContext } from "./App";

const Auth = new AuthHelperMethods();

export default function SelectAccountHolder({}) {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  useEffect(
    () => {
      // console.log("USEEFFECT API");
      const fetchData = async () => {
        dispatch({ type: "FETCH_INIT" });

        let params = { is_active: 1 };

        try {
          const result = await Auth.api("people/accounts", "get", params);
          dispatch({
            type: "FETCH_SUCCESS",
          });

          dispatch2(["ACCOUNT_CLIENTS_GET", result.data]);
          //  setInitComplete(true);
        } catch (error) {
          dispatch({ type: "FETCH_ERROR" });
          //  alert.show(error.message, { timeout: 3000, type: "error" });
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
    //[active, refresh, filter]
  );

  useEffect(
    () => {
      // console.log("USEEFFECT API");
      const fetchData = async () => {
        dispatch({ type: "FETCH_INIT" });

        let params = { is_active: 1 };

        try {
          const result = await Auth.api("people/studentClient", "get", params);
          dispatch({
            type: "FETCH_SUCCESS",
          });

          dispatch2(["ACCOUNT_STUDENTS_GET", result.data]);
          // setInitComplete(true);
        } catch (error) {
          dispatch({ type: "FETCH_ERROR" });
          //  alert.show(error.message, { timeout: 3000, type: "error" });
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
    //[active, refresh, filter]
  );

  return (
    <>
      <SelectTemplateFilter
        options={listState.accountClients}
        isMulti={true}
        label={"billing_person_id"}
        list={listState.filter_labels.billing_person_id || null}
        extraLabel={"account search by account holder name"}
        maxWidth={300}
        value={listState.filter.billing_person_id || null}
      />
      <div style={{ padding: 10 }}>OR</div>
      <SelectTemplateFilter
        options={listState.accountStudents}
        isMulti={true}
        label={"billing_person_id"}
        list={listState.filter_labels.person__name || null}
        extraLabel={"account search by student name"}
        maxWidth={300}
        value={listState.filter.billing_person_id || null}
      />
    </>
  );
}
