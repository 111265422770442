import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, NavLink, useParams } from "react-router-dom";
import { TableHeaderNoAdd, OrangeLinkButton } from "./formComponents";
import { Row, Col } from "react-bootstrap";
import ProductForm from "./productForm";
import ProductTable from "./productTable";
import ProductAssignProgram from "./productAssignProgram";
import SectionForm from "./sectionForm";
import { ListContext } from "./App";
import {
  PrimaryText,
  TableText1,
  TableText2,
  Th,
  Td,
  HelpText,
  StyledLink,
  Container,
  Row1,
  Row2,
  ContainerH,
  ContainerV,
} from "./styleCustom";
import COLORS from "./styleConstants";
import AuthHelperMethods from "./AuthHelperMethods";
import { MyContext } from "./App";
import { AddIconNP } from "./icons";
import SectionAssignStudent from "./sectionAssignStudent";
import ProgramForm from "./programForm";
import FilterForm from "./filterForm";
import PrivateRoute from "./privateRoute";
import { errormsg } from "./Constants2";
import { Active } from "./searchFilterReports";
import { active_options } from "./Constants2";

const Auth = new AuthHelperMethods();

const Programs = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  useEffect(
    () => {
      const fetchData = async () => {
        let params = { ...listState.filter };
        try {
          const result = await Auth.api("programs/programList", "get", params);
          dispatch2(["GET_PROGRAM_LIST", result.data]);
        } catch (e) {
          console.log(e);
          dispatch({ type: "FETCH_ERROR" });

          alert.show(errormsg, { timeout: 5000, type: "error" });
        }
      };

      fetchData();

      //  }
    },
    //eslint-disable-next-line
    [listState.filter]
  );

  useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        const result = await Auth.api("options/index", "get", params);
        dispatch({
          type: "UPDATE_OPTIONS",
          options: result.data,
        });
      };
      fetchData();
    },
    //eslint-disable-next-line
    [listState.programList]
  );

  const updateIsActive = async (id) => {
    let params = { ...listState.filter };
    params.id = id;
    const result = await Auth.api("programs/updateActivationStatus", "post", params);
    dispatch2(["GET_PROGRAM_LIST", result.data]);

    console.log(result.data);
  };

  const addInitialValues = {
    name: "",
    duration: "",
    program_type_id: "",
    location_id: "",
    instructor_id: "",
    duration: "",
  };

  const handleClickEditProgram = (program) => {
    console.log(program);
    let formInitialValues = {
      id: program.id,
      name: program.program_name || "",
      duration: program.duration || "",
      desc: program.program_desc || "",
      program_type_id: program.program_type_id || "",
      location_id: program.location_id || "",
      instructor_id: program.instructor_id || "",
    };

    dispatch2(["SET_FORM_INITIAL_VALUES", formInitialValues]);
  };
  return (
    <>
      <Container style={{ flexWrap: "wrap", flex: "1 1 100%" }}>
        <div
          style={{
            display: "flex",
            flex: "1 1 100%",
            justifyContent: "center",
          }}
        >
          <PrivateRoute path={`${props.match.url}/programform/:id`} component={ProgramForm} />
        </div>
        <div
          style={{
            display: "flex",
            flex: "1 1 100%",
            marginRight: 100,
            flexWrap: "wrap",
          }}
        >
          <TableHeaderNoAdd title={"Programs"}>
            <Link
              to={`${props.match.url}/programform/add`}
              onClick={() => dispatch2(["SET_FORM_INITIAL_VALUES", addInitialValues])}
            >
              <OrangeLinkButton>{"Add New Program"} </OrangeLinkButton>{" "}
            </Link>
          </TableHeaderNoAdd>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
            <span style={{ marginRight: 10 }}>{"Showing:"}</span>
            <Active field={"is_active"} default={"1"} options={active_options} />
          </div>

          {listState.programList && (
            <table style={{ width: "100%" }}>
              <thead>
                <tr style={{ color: `${COLORS.blue}` }}>
                  <Th>
                    {" "}
                    <div>{"Name"}</div>
                    <HelpText>{"Click on Program Name to edit"}</HelpText>
                  </Th>
                  <Th>{"program type"}</Th>

                  <Th>{"description"}</Th>
                </tr>
              </thead>
              <tbody>
                {listState.programList &&
                  listState.programList.length > 0 &&
                  listState.programList.map((program) => (
                    <tr key={program.id}>
                      <Td>
                        <StyledLink
                          to={`${props.match.url}/programform/${program.id}`}
                          onClick={() => handleClickEditProgram(program)}
                        >
                          <TableText2>{program.program_name} </TableText2>
                        </StyledLink>
                      </Td>
                      <Td>{program.program_type_name || ""}</Td>

                      <Td>{program.program_desc || ""}</Td>

                      <Td onClick={() => updateIsActive(program.id)} style={{ color: "red", cursor: "pointer" }}>
                        {`${program.is_active ? "inactivate" : "activate"}`}
                      </Td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </Container>
    </>
  );
};

export default Programs;
