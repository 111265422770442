import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Route,
  Switch,
  Link,
  useRouteMatch,
  useParams,
  useHistory,
  withRouter,
  useLocation,
} from "react-router-dom";
import { Row, Col, Button, ListGroup, Modal, Accordion, Dropdown } from "react-bootstrap";
import { ListContext, MyContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import {
  BlueNavLink,
  BlueText,
  StyledListGroupHoriz,
  Row1,
  Row2,
  ContainerH,
  ContainerV,
  GreyText,
  OrangeTextSpan,
  HelpText,
  AddButton,
  OrangeButton,
  PrimaryText,
} from "./styleCustom";
import { AddIconNP, EditIconPlain } from "./icons";
import {
  ModalHeaderBlue,
  UserCan,
  HelpDiv,
  ButtonOrange,
  ButtonOrangeMed,
  ModalWarning,
  Limit,
} from "./formComponents";
import PaymentMethodElements from "./addPaymentMethodElements";

import COLORS, { DetailContainer, RowBottomBorder, Separator } from "./styleConstants";
import ContactForm from "./contactForm";
import AddDependantForm, { EditDependantName } from "./addDependantForm";
import PrivateRoute from "./privateRoute";
import Select from "react-select";
////import WaiverRetrieve, { WaiverRetrieveList } from "./waiverRetrieve";
import Waiver from "./waiver";
import WaiverRetrieve from "./waiverRetrieve";
import { AccountPaymentMethodX } from "./capturePaymentMethodForFutureUseX";
//import WaiverRetrieve, { WaiverRetrieveList } from "./waiverRetrieve";
import { helpBooking } from "./Constants2";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input";
import NotesTable from "./notesTable";

const Auth = new AuthHelperMethods();

const Waivers = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  let params = { person_id: listState.accountHolder.id };
  const [waivers, setWaivers] = useState([]);
  const [currentWaiverId, setCurrentWaiverId] = useState();
  const [currentWaiver, setCurrentWaiver] = useState();
  const [customWaiverValues, setCustomWaiverValues] = useState();

  const [image, setImage] = useState();
  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const result = await Auth.api("waiver/getAccountWaiverList", "get", params);
          setWaivers(result.data.waivers);
        } catch (e) {
          console.log(e);
        }
        // setSections(result.data.studentSections);
        // dispatch2(["HORSE_DATA", result.data]);
      };
      if (listState.accountHolder.id) fetchData();
    },
    //eslint-disable-next-line
    []
  );

  /* useEffect(
    () => {
      const fetchData = async () => {
        try {
          params.id = currentWaiverId;
          const result = await Auth.api("waiver/waiverRetrieve", "get", params);
          setCurrentWaiver(result.data.waiver.waiver);
          setImage(result.data.waiver.waiver.pdf);
          const { participants, guardian, customWaiverFields } = result.data.waiver.waiver;
          setCustomWaiverValues(Object.values(customWaiverFields));
          const { customParticipantFields } = participants;
          //   setRiders(participants);
          //   setGuardian(guardian);
          //  setCustom(customParticipantFields);
          const linkSource = `data:application/pdf;base64,${result.data.waiver.waiver.pdf}`;
          const downloadLink = document.createElement("a");
          const fileName = `${result.data.waiver.waiver.title}.pdf`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          setImage(downloadLink);
          downloadLink.click();
        } catch (e) {
          console.log(e);
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    [currentWaiverId]
  );*/

  /*function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${currentWaiver.image}`;
    const downloadLink = document.createElement("a");
    const fileName = "vct_illustration.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    setImage(downloadLink);
    downloadLink.click();
  }*/

  if (!waivers) return null;
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <DetailContainer>
          <GreyText style={{ display: "flex", flex: "1 1 100%" }}>
            {`Completed Waivers for ${listState.accountHolder.person_name} Account`}
          </GreyText>
        </DetailContainer>
        {waivers.map((item) => (
          <>
            <div style={{ display: "flex", flex: "1 1 100%", marginBottom: 20 }}>
              {" "}
              <div key={item.waiverId} style={{ display: "flex", width: 300, alignItems: "center" }}>
                <BlueText> {item.title}</BlueText>
                <Link to={`${props.match.url}/WaiverRetrieve/${item.waiverId}`}>{item.title}</Link>
              </div>
              <div style={{ display: "flex", width: 300 }} onClick={() => setCurrentWaiverId(item.waiverId)}>
                <OrangeButton>download pdf</OrangeButton>
              </div>
            </div>
          </>
        ))}
      </div>
      <PrivateRoute path={`${props.match.url}/WaiverRetrieve/:id`} component={WaiverRetrieve} {...props} />
    </div>
  );
};

export const TemplateListRetrieve = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  const [waiver, setWaiver] = useState();

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const result = await Auth.api("waiver/templateListRetrieve", "get");
          //  const result = await Auth.api("waiver/getAccountWaiverList", "get");

          setWaiver(result.data.templates);
          console.log(result.data.templates);
        } catch (e) {
          console.log(e);
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  if (!waiver) return null;
  return (
    <>
      <div
        style={{
          display: "flex",
          flex: "1 1 100%",
          flexWrap: "wrap",
          marginBottom: 30,
        }}
      >
        <DetailContainer>
          <GreyText style={{ display: "flex", flex: "1 1 100%" }}>
            {` Available Waivers for ${mystate.farmDefaults.farm_name}`}
          </GreyText>
        </DetailContainer>
        {waiver.map((item) => (
          <div
            className={"mapWaiver"}
            key={item.templateId}
            style={{
              display: "flex",
              flexDirection: "row",
              flex: "1 1 100%",
            }}
          >
            <div style={{ display: "flex" }}>
              <a
                href={`https://waiver.smartwaiver.com/auto/?auto_waiverid=${item.templateId}&auto_tag=${listState.accountHolder.id}`}
              >
                {item.title}{" "}
              </a>{" "}
            </div>
          </div>
        ))}
      </div>{" "}
    </>
  );
};

export const AccountPage = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [showStudentsForm, setShowStudentsForm] = useState(false);
  const [waiverUrl, setWaiverUrl] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [activeWord, setActiveWord] = useState("active");

  var client = listState.currentClient;
  let v = useParams();
  let h = useHistory();
  let j = useRouteMatch();
  let location = useLocation();

  const onAccountPage = location.pathname.endsWith("/account");
  const onStaffPage = location.pathname.endsWith("/details");

  //  let is_billing_person=client.is_billing_person.
  let params = {
    person_id: client.id,
    // is_billing_person: client.is_billing_person,
    // billing_person_id: client.billing_person_id,
    //billing_person_people_id: client.billing_person_people_id,
  };

  useEffect(
    () => {
      setActiveWord(listState.accountHolder.is_active ? "Inactivate" : "Activate");
    },
    //eslint-disable-next-line
    []
  );

  /*const onClickInactivate = () => {
    setShowWarning(true);
  };
*/
  /*
  const InactivateAccount = async () => {
    let params = {};
    setShowWarning(false);

    params.studentsIds = listState.members.map((student) => student.student_id);
    params.accountHolderId = listState.accountHolder.id;
    params.studentPeopleIds = listState.members
      .map((person) => person.id)
      .filter((id) => id != listState.accountHolder.id);
    params.whattodo = listState.accountHolder.is_active ? "inactivate" : "activate";
    if (listState.is_instructor) params.is_staff = true;
    console.log(params);
    try {
      const result = await Auth.api("people/updateIsActiveAccount", "post", params);
      dispatch2(["CLIENT_GET", result.data.currentClient]);

      dispatch2(["CLIENT_GET_ACCOUNT", result.data]);
    } catch {
      console.log("error");
    }
  };
*/
  /* useEffect(
    () => {
      const templateUrl = listState.templates[0];
      console.log(templateUrl);
    },

    //eslint-disable-next-line
    []
  );*/

  /* useEffect(
    () => {
      const fetchData = async () => {
        try {
          const result = await Auth.api("waiver/templateList", "get");
          //  setTemplateList(result.data.templates);
          dispatch2([
            "SET_SINGLE_TEMPLATE",
            result.data.templates[0].templateId,
          ]);
          // console.log(result.data.templates[0].webUrl);
          //  setWaiverUrl(
          //  `${result.data.templates[0].webUrl}${listState.accountHolder.id}`
          //  );

          setWaiverUrl(
            `https://waiver.smartwaiver.com/auto/?auto_waiverid=${result.data.templates[0].templateId}&auto_tag=${listState.accountHolder.id}`
            //
          );
        } catch (e) {
          console.log(e);
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );
  */

  //   `${listState.templates[0].webUrl}/${listState.accountHolder.id}`;

  const removerUserAccount = async (client) => {
    let params = { person_id: client.id };
    try {
      const result = await Auth.api("vusers/removerUserAccount", "post", params);
      params = { ...params, user_id: "" };
      dispatch2(["CLIENT_NEW_ACCOUNT", params]);
      alert("Account removed successfully");

      console.log(result.data.success);
      console.log(result.exeption);
    } catch (error) {
      console.log(error);
      //  dispatch({ type: "FETCH_ERROR" });
      alert("Account deletion failed. Please contact administrator.");
    }
  };

  const createUserAccount = async (client) => {
    console.log(client);
    let params = {
      person_name: client.person_name,
      person_id: client.id,
      email: client.contact_email,
      type: client.is_instructor ? "INS" : "STU",
    };
    if (!client.contact_email) {
      alert(
        "Client does not yet have a email address associated with this account.  Please create one then try again."
      );
    } else {
      try {
        const result = await Auth.api("vusers/addClientUserAccount", "post", params);
        params = { ...params, user_id: result.data.user_id };
        dispatch2(["CLIENT_NEW_ACCOUNT", params]);

        alert("User Succesfully Created");
      } catch (error) {
        console.log(error);
        //  dispatch({ type: "FETCH_ERROR" });
        alert(
          "Account creation failed. Check that this email address has not already been used to create another account"
        );
      }
    }
  };

  const createStripeCustomer = async () => {
    Auth.api("payments/createCustomer", "post");
  };

  const Holder = (props) => {
    if (!listState.clientdata) return null;
    return (
      <>
        <RowBottomBorder>
          <Row2
            className="holder"
            style={{
              fontSize: "10pt",
              justifyContent: "space-between",
            }}
          >
            {" "}
            <GreyText>Account Holder</GreyText>
          </Row2>
          <Row2>
            <OrangeTextSpan style={{ fontSize: "14pt" }}>
              {listState.accountHolder.person_name || listState.accountHolder.instructor_name}
            </OrangeTextSpan>

            {onAccountPage && (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginLeft: 20,
                }}
              >
                <Link to={`${props.match.url}/editMemberName/${listState.accountHolder.id}`}>
                  <EditIconPlain />
                </Link>
              </div>
            )}
          </Row2>
        </RowBottomBorder>
      </>
    );
  };

  const Horses = () => {
    return (
      <RowBottomBorder>
        <Row2
          className="horses"
          style={{
            fontSize: "10pt",
            justifyContent: "space-between",
          }}
        >
          {" "}
          <GreyText>Horses</GreyText> {onAccountPage && <AddIconNP />}
        </Row2>
      </RowBottomBorder>
    );
  };

  //  dispatch2(["SET_NEW_CLIENT_IDS", accountHolder]);
  console.log(props);

  const updateUserRole = async (selectedOption) => {
    let params = {
      role_id: selectedOption.value,
      user_id: listState.accountHolder.user_id,
    };
    const result = await Auth.api("vusers/updateUserRole", "post", params);
    if (result.data.role) dispatch2(["UPDATE_ACCOUNT_HOLDER_ROLE", selectedOption.value]);
  };
  if (!listState.accountHolder) return <div>No Account Information</div>;
  //if (!listState.absoluteCurrentClientId) return null;
  //if (!listState.currentClient) return null;

  const [showPaymentMethod, setShowPaymentMethod] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flex: "1 1 100%",
        justifyContent: "center",
        flexWrap: "wrap-reverse",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", flex: "1 1 100%", justifyContent: "center" }}>
        <ContainerH
          className="account"
          style={{
            flex: "1 1 60%",
            maxWidth: 600,
            border: "solid 5px lightgrey",
            padding: 10,
          }}
        >
          {1 == 2 && <button onClick={() => setShowPaymentMethod(true)}>addPaymentMethod</button>}
          {1 == 2 && showPaymentMethod && <AccountPaymentMethod />}
          <RowBottomBorder>
            <BlueText>
              {" "}
              Account Information. To view student specific information and actions, click on the student name in
              Account Members section below.
            </BlueText>
            <Holder {...props} />
            {!listState.accountHolder.is_instructor && <Members {...props} />}
            {1 == 2 && <Horses />}
          </RowBottomBorder>

          <ContainerH className="details">
            <Contact {...props} />

            <UserCan isThis={"ADM"}>
              <Separator>
                <div>{"LOGIN USER ACCOUNT"}</div>
              </Separator>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                {!listState.accountHolder.user_id && (
                  <ButtonOrange
                    onClick={() => createUserAccount(listState.accountHolder)}
                    label={"create user login account"}
                  />
                )}
                {listState.accountHolder.user_id && (
                  <ButtonOrange
                    onClick={() => removerUserAccount(listState.accountHolder)}
                    label={"remove user login account"}
                  />
                )}
              </div>
            </UserCan>
            <div>
              {1 == 1 &&
                listState.accountHolder.user_id &&
                listState.currentStaff &&
                listState.currentStaff.instructor_id && (
                  <>
                    <UserCan ifFarm={"use_roles"} isThis={"ADM"}>
                      <BlueText>User Role</BlueText>

                      <Select
                        options={mystate.options_role}
                        onChange={(selectedOption) => updateUserRole(selectedOption)}
                        defaultValue={mystate.options_role.filter(
                          (role) => role.value == listState.accountHolder.role_id
                        )}
                      />
                    </UserCan>
                  </>
                )}
            </div>

            {1 == 2 && (
              <>
                <Separator>
                  <div>{"ACCOUNT NOTES"}</div>
                </Separator>

                <AccountNotes {...props} />
              </>
            )}
          </ContainerH>
        </ContainerH>
      </div>
      <div
        className="helpinfo"
        style={{
          display: "flex",
          flex: "1 1 40%",
          padding: 20,
          flexDirection: "column",
        }}
      >
        <UserCan ifFarm={"clients_can_book_lessons"} isThis={"STU"}>
          <HelpDiv title={"How to book a lesson"}>
            <ul
              style={{
                fontSize: 16,
                color: COLORS.textDark,
                listStyleType: "numbers",
              }}
            >
              {helpBooking.map((line) => (
                <li style={{ paddingBottom: 5 }}>{line}</li>
              ))}
            </ul>
          </HelpDiv>{" "}
        </UserCan>{" "}
      </div>
      <Switch>
        <PrivateRoute path={`${props.match.url}/addMemberName`} component={AddDependantForm} />
        <PrivateRoute path={`${props.match.url}/editMemberName/:student_person_id`} component={EditDependantName} />
        <PrivateRoute path={`${props.match.url}/contact/`} component={ContactForm} />
      </Switch>
      {showWarning && (
        <ModalWarning
          title={activeWord}
          proceedlabel={`Yes, ${activeWord} account holder and members`}
          cancellabel={`No, I do not wish to ${activeWord} account holder`}
          warningMessage={`Warning! ${activeWord}  the Account Holder will also ${activeWord} all the Students on the account as well.`}
          cancel={() => setShowWarning(false)}
          proceed={"yes"}
        ></ModalWarning>
      )}
    </div>
  );
};

export const Contact = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  let location = useLocation();
  return (
    <>
      <Separator style={{ display: "flex", justifyContent: "space-between" }}>
        <div>{"CONTACT DETAILS"}</div>
        <div>
          <Link to={`${props.match.url}/contact`}>
            <EditIconPlain />
          </Link>
        </div>
      </Separator>
      <RowBottomBorder>
        <DetailContainer>
          <GreyText>{"Email"}</GreyText>{" "}
        </DetailContainer>
        <DetailContainer>
          <BlueText>{listState.accountHolder.contact_email}</BlueText>
        </DetailContainer>
      </RowBottomBorder>
      <RowBottomBorder>
        <DetailContainer>
          <GreyText>{"Phone Primary"}</GreyText>
        </DetailContainer>
        <DetailContainer>
          <BlueText>
            {" "}
            {listState.accountHolder.contact_mobile && formatPhoneNumber(listState.accountHolder.contact_mobile)}
          </BlueText>
        </DetailContainer>
      </RowBottomBorder>
      <RowBottomBorder>
        <DetailContainer>
          <GreyText>{"Phone Secondary"}</GreyText>
        </DetailContainer>{" "}
        <DetailContainer>
          <BlueText>
            {listState.accountHolder.contact_homephone && formatPhoneNumber(listState.accountHolder.contact_homephone)}
          </BlueText>
        </DetailContainer>
      </RowBottomBorder>
    </>
  );
};

const Members = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const [showInactiveMembers, setShowInactiveMembers] = useState(false);
  let location = useLocation();

  const onAccountPage = location.pathname.endsWith("/account");

  console.log(location.pathname);
  console.log("location.pathname");
  let last = location.pathname.lastIndexOf("/");
  console.log(location.pathname.substring(last + 1));
  let place = location.pathname.substring(last + 1);
  console.log(location.pathname.substring(0, location.pathname.lastIndexOf("/")));
  //if (!listState.members) return null;
  return (
    <RowBottomBorder>
      <Row2
        className="members"
        style={{
          fontSize: "10pt",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <GreyText>Account Members</GreyText>
          <HelpText>{"Click on name to view student's page"}</HelpText>
          <HelpText>{"Click on plus sign to add members "}</HelpText>
        </div>
        {onAccountPage && (
          <Link to={`${props.match.url}/addMemberName`}>
            <div style={{ fontSize: "20pt", marginRight: 50 }}>
              <AddIconNP style={{ zIndex: 1000 }} />
            </div>
          </Link>
        )}
      </Row2>
      {listState.members &&
        listState.members.filter((member) => member.is_active) &&
        listState.members.map((student) => (
          <Row2 key={student.id} onClick={() => dispatch2(["CLIENT_GET", student])}>
            <Link to={`/admin/clienthome/${student.id}/lessons`}>
              <BlueText style={{ fontSize: "14pt", cursor: "pointer" }}>{student.person_name}</BlueText>{" "}
            </Link>
            {onAccountPage && (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginLeft: 20,
                }}
              >
                <Link to={`${props.match.url}/editMemberName/${student.id}`}>
                  <EditIconPlain />
                </Link>
              </div>
            )}
          </Row2>
        ))}

      {1 == 2 && listState.members.filter((member) => !member.is_active).length > 0 && (
        <>
          {" "}
          <div onClick={() => setShowInactiveMembers(true)}>{"click to see inactive members"}</div>
          {showInactiveMembers &&
            listState.members
              .filter((member) => !member.is_active)
              .map((student) => <div key={student.id}>{student.person_name}</div>)}
        </>
      )}
    </RowBottomBorder>
  );
};

export const AccountNotes = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const usertype = Auth.getUserType();
  let location = useLocation();
  const [offset, setOffset] = useState(5);
  const [total, setTotal] = useState();

  const profileOnly = location.pathname.startsWith("/admin/calendarGroup/edit");

  let params = {
    person_id: listState.accountHolder.id,
    is_account_note: 1,
    limit: profileOnly ? 10 : offset,
  };
  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("notes_clients/clientNotes", "get", params);
        dispatch2(["CLIENT_DATA", result.data, "notes"]);
        setTotal(result.data.size);
      };
      fetchData();

      return () => {
        console.log("Clear Notes");
        dispatch2(["CLIENT_DATA", []]);
      };
    },
    //eslint-disable-next-line
    [listState.currentClient.id, offset]
  );
  // console.log(props.match.url);

  //  useFetch("notes_clients/clientNotes", "CLIENT_DATA", "dispatchlist", params);
  //if (!listState.clientdata) return null;
  return (
    <>
      <NotesTable {...props} />
      <Limit total={total} setOffset={setOffset} data={listState.clientdata || []} offset={offset} />
    </>
  );
};

export default AccountPage;
