import React, { useContext, useState } from "react";
import { LeftNavLink } from "./styleCustom";
import COLORS from "./styleConstants";
import { MyContext, ListContext } from "./App";
import Logout from "./logout";
import AuthHelperMethods from "./AuthHelperMethods";
import { UserCan } from "./formComponents";
import StaffHomePage from "./staffHomePage";

const Auth = new AuthHelperMethods();

const LeftNavBar = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  if (!mystate.farmDefaults) return null;
  return (
    <>
      <div
        className="left"
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "100%",
          alignContent: "flex-start",
          minHeight: "100vh",
          background: process.env.NODE_ENV == "production" ? COLORS.orange : COLORS.blue,
        }}
      >
        {" "}
        <div
          style={{
            minHeight: 200,
            fontSize: "14px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            background: process.env.NODE_ENV == "production" ? COLORS.orange : COLORS.blue,
            paddingLeft: 10,
          }}
        >
          <div style={{ fontSize: "16pt" }}>{mystate.farmDefaults.farm_name}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }} onClick={props.onClick}>
          {Auth.getUserType() == "ADM" && (
            <>
              {" "}
              <LeftNavLink className={({ isActive }) => (isActive ? "active" : "inactive")} to="/admin/admin">
                Admin
              </LeftNavLink>
              <LeftNavLink activeClassName="nav-item-active" to="/admin/reports">
                Reports
              </LeftNavLink>
              <LeftNavLink to="/admin/calendarGroup/calendar">Calendar</LeftNavLink>
              <LeftNavLink to="/admin/daysheet">Day Sheet</LeftNavLink>
              <LeftNavLink to="/admin/whiteboard">Whiteboard</LeftNavLink>
              <LeftNavLink to="/admin/students">Students</LeftNavLink>
              <LeftNavLink to="/admin/accounts">Account Holders</LeftNavLink>
              <LeftNavLink to="/admin/horses">Horses</LeftNavLink>
              <LeftNavLink to="/admin/staff">Staff</LeftNavLink>
              <LeftNavLink to="/admin/setup">Setup</LeftNavLink>
              <LeftNavLink to="/admin/faq">FAQ</LeftNavLink>
            </>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "column" }} onClick={props.onClick}>
          {Auth.getUserType() == "INS" && (
            <>
              <UserCan doThis={"STAFF_CAN_VIEW_HORSE_AND_CLIENT_NOTES_PAGE"}>
                <LeftNavLink to="/admin/notesReview">Notes</LeftNavLink>{" "}
              </UserCan>
              <UserCan doThis={"STAFF_CAN_VIEW_GENERAL_REPORTS"}>
                <LeftNavLink to="/admin/reports">Reports</LeftNavLink>{" "}
              </UserCan>
              <UserCan doThis={"STAFF_CAN_VIEW_CALENDAR"}>
                <LeftNavLink to="/admin/calendarGroup/calendar">Calendar</LeftNavLink>{" "}
              </UserCan>
              <UserCan doThis={"STAFF_CAN_ACCESS_DAYSHEET"}>
                <LeftNavLink to="/admin/daysheet">Day Sheet</LeftNavLink>{" "}
              </UserCan>
              <UserCan doThis={"STAFF_CAN_ACCESS_WHITEBOARD"}>
                <LeftNavLink to="/admin/whiteboard">Whiteboard</LeftNavLink>{" "}
              </UserCan>
              <UserCan doThis={"STAFF_CAN_ACCESS_STUDENT_LIST"}>
                <LeftNavLink to="/admin/students">Students</LeftNavLink>{" "}
              </UserCan>
              <UserCan doThis={"STAFF_CAN_ACCESS_ACCOUNT_HOLDER_LIST"}>
                <LeftNavLink to="/admin/accounts">Account Holders</LeftNavLink>{" "}
              </UserCan>
              <UserCan doThis={"STAFF_CAN_ACCESS_HORSE_LIST"}>
                <LeftNavLink to="/admin/horses">Horses</LeftNavLink>{" "}
              </UserCan>
              <LeftNavLink to={`/admin/staffhome/${listState.loggedInStaff || listState.currentStaff.user_id}`}>
                Home Page{" "}
              </LeftNavLink>{" "}
              {1 == 2 && (
                <>
                  <LeftNavLink to="/admin/admin">Admin</LeftNavLink>
                  <LeftNavLink to="/admin/reports">Reports</LeftNavLink>
                  <LeftNavLink to="/admin/calendarGroup/calendar">Calendar</LeftNavLink>
                  <LeftNavLink to="/admin/daysheet">Day Sheet</LeftNavLink>
                  <LeftNavLink to="/admin/whiteboard">Whiteboard</LeftNavLink>
                  <LeftNavLink to="/admin/students">Students</LeftNavLink>
                  <LeftNavLink to="/admin/accounts">Account Holders</LeftNavLink>
                  <LeftNavLink to="/admin/horses">Horses</LeftNavLink>
                  <LeftNavLink to="/admin/staff">Staff</LeftNavLink>
                  <LeftNavLink to="/admin/setup">Setup</LeftNavLink>
                </>
              )}
            </>
          )}
        </div>
        <div style={{ marginTop: Auth.getUserType() == "STU" ? 20 : 100, display: "flex", justifyContent: "center" }}>
          {" "}
          <Logout />
        </div>
      </div>
    </>
  );
};

export default LeftNavBar;
