import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import lodash from "lodash";
import { BrowserRouter, Route, Switch, Link, useRouteMatch, Redirect, useHistory } from "react-router-dom";
import {
  NavButton,
  BlockLabel,
  NameStringText,
  MediumPageHeader,
  BlueButton,
  SaveButtonSm,
  CancelButtonSm,
  HelpText,
  LinkText,
  SaveButton,
} from "./styleCustom";
import {
  MyTextInput,
  MyCheckbox,
  OrangeButton,
  TableHeader2,
  BlueButtonFinish,
  ModalHeaderBlue,
} from "./formComponents";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col, Button, Accordion, Modal } from "react-bootstrap";
import { ListContext } from "./App";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import "react-google-places-autocomplete/dist/assets/index.css";
import "react-phone-number-input/style.css";
import PhoneInput, { formatPhoneNumber } from "react-phone-number-input/input";
import User from "./UserSettings";
import { MyContext } from "./App";
import { DropdownArrow } from "./icons";
const Auth = new AuthHelperMethods();

export const ContactForm = (props) => {
  //  const { values, submitForm } = useFormikContext();

  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const history = useHistory();
  const [phone1, setPhone1] = useState(listState.accountHolder.contact_mobile || "");
  const [phone2, setPhone2] = useState(listState.accountHolder.contact_homephone || "");

  const [showSaveButtons, setShowSaveButtons] = useState(true);
  const [showSuccess, setShowSuccess] = useState(true);

  // console.log(User.countryCode);
  console.log("country");

  const params = {};
  /*  if (lodash.isEmpty(listState.newClientIds)) {
    return <div>you have to add an account holder first</div>;
  }*/
  return (
    <>
      <Modal show={true} onHide={() => history.goBack()}>
        <ModalHeaderBlue closeButton>
          <Modal.Title>{"Contact Details"}</Modal.Title>
        </ModalHeaderBlue>
        <Modal.Body>
          {" "}
          <Formik
            initialValues={{
              email: listState.accountHolder.contact_email || "",
              phone1: listState.accountHolder.contact_mobile || "",
              phone2: listState.accountHolder.contact_homephone || "",
            }}
            enableReinitialize
            validationSchema={Yup.object({
              phone1: Yup.string(), //.required("Last Name Required"),
              phone2: Yup.string(),
              email: Yup.string().email(),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              console.log(props);
              values.phone1 = phone1;
              values.phone2 = phone2;
              console.log(values);
              values.person_id = listState.accountHolder.id;

              /* if (value) params.phone1 = value;
          if (value2) params.phone2 = value2;
          if (values.email) params.email = values.email;
          console.log(values);
          //    params.person_id = listState.newClientIds.id;
          params.person_id = props.accountHolder.id;
*/
              const result = await Auth.api("people/createNewContact", "post", values);

              // dispatch2(["UPDATE_CLIENT_INFO", result.data.client]);
              dispatch2(["CLIENT_UPDATE_SINGLE_MEMBER", result.data.client]);

              history.goBack();
              // setShowSaveButtons(false);
              resetForm();
              // dispatch2(["SET_CONTACT_MODAL_SHOW", false]);
            }}
          >
            <Form id="contactForm">
              <>
                {" "}
                <Row>
                  <Col>
                    <BlockLabel>{"Primary phone number"}</BlockLabel>
                    <PhoneInput
                      style={{ width: "100%" }}
                      placeholder="Enter phone number"
                      value={phone1}
                      onChange={setPhone1}
                      country={mystate.farmDefaults.country_code}
                      name="phone1"
                    />
                  </Col>
                  <Col>
                    <BlockLabel>{"Secondary phone number"}</BlockLabel>
                    <PhoneInput
                      style={{ width: "100%" }}
                      placeholder="Enter phone number"
                      value={phone2}
                      onChange={setPhone2}
                      country={mystate.farmDefaults.country_code}
                      name="phone2"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <MyTextInput name="email" type="text" label="Primary Email" />
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 30,
                  }}
                >
                  <OrangeButton type="submit">{"Save"}</OrangeButton>
                </Row>
              </>
            </Form>
          </Formik>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </>
  );
};

export default ContactForm;
