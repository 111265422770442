import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, NavLink } from "react-router-dom";
import { useParams, useHistory } from "react-router";
import { Formik, Form, useField, useFormikContext } from "formik";
import * as Yup from "yup";
import { MediumPageHeader } from "./styleCustom";
import { MyTextInput, MyCheckbox, AddEditForm, OrangeButton, BlueLinkButton } from "./formComponents";

import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col } from "react-bootstrap";
import { ListContext } from "./App";
import { AddIconNP } from "./icons";
const Auth = new AuthHelperMethods();

const RoleForm = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  let { addoredit } = useParams();
  const iv = {
    role_name: "",
    description: "",
  };

  let initialValues = addoredit === "add" ? iv : listState.formInitialValues;
  let history = useHistory();

  function goBackToList() {
    history.goBack();
  }

  return (
    <>
      <AddEditForm>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={Yup.object({
            role_name: Yup.string(),
            description: Yup.string(),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            if (addoredit === "add") {
              values = { ...values, farm_id: 8, user_code: "INS" };
              const result = await Auth.api("roles/create", "post", values);
              dispatch2(["ROLE_CREATE", result.data]);
              setSubmitting(false);
              goBackToList();
            }

            if (addoredit === "edit") {
              console.log(addoredit);

              const result = await Auth.api("roles/update", "post", values);
              console.log(result.data.role);
              dispatch2(["ROLE_EDIT", result.data]);
              goBackToList();
            }
          }}
        >
          <Form id="roleForm">
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <MediumPageHeader>{addoredit === "add" ? "Create a New Role" : "Edit Current Role"}</MediumPageHeader>
            </Row>
            <div style={{ marginBottom: 50 }}>
              <Row noGutters style={{ flexDirection: "column" }}>
                <MyTextInput name="role_name" type="text" placeholder="name" label="Role Name" />{" "}
                <MyTextInput label="Role Description" name="description" type="text" placeholder="Description" />
                {1 == 2 && <MyTextInput label="User Type" name="user_code" type="text" placeholder="User type" />}
              </Row>
            </div>
            <Row style={{ display: "flex", justifyContent: "spread-evenly" }}>
              <Col>
                <Link to="/roles/">
                  <BlueLinkButton>{"Cancel"}</BlueLinkButton>
                </Link>
              </Col>
              <Col>
                {" "}
                <OrangeButton type="submit">{"Save"}</OrangeButton>
              </Col>
            </Row>
          </Form>
        </Formik>
      </AddEditForm>
    </>
  );
};
export default RoleForm;
