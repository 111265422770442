import React, { useState, useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import getRepeatFirstLast from "./getLastDate";
import SelectSectionDefaults from "./selectSectionDefaults";
import SelectInstructor from "./selectInstructor";
import SelectStudent from "./selectStudent";
import SelectHorse from "./selectHorse";
import SelectLocation from "./selectLocation";
import TextArea from "./textInputgeneric";
import TextInputTitle from "./textInputTitle";
import TimeAndDate from "./timeanddate";
import RepeatingDates from "./repeatingDates";
import { Button, Row, Col, Container } from "react-bootstrap";
import { MyContext } from "./App";
import axios from "axios";
import { formatDateTimeForDB, dateObjToDisplayDate, dateObjToDisplayTime, dateObjToDBDate } from "./momentUtils";
import AuthHelperMethods from "./AuthHelperMethods";
import "./style.css";
import ReactTooltip from "react-tooltip";
import { useAlert } from "react-alert";
import "./styleAdd.css";
import { TitleRow } from "./blueCheckFuture";
import { ClientAvatar, HorseAvatar, StaffAvatar, LocationAvatar } from "./images";
import { NoteIcon, LocationIcon, RepeatIcon, ClockIcon, TitleIcon } from "./icons";

const Auth = new AuthHelperMethods();

const orangelabel = {};

//render(<AlertDismissibleExample />);

function AddModalStu(props) {
  const { mystate, dispatch } = useContext(MyContext);
  const alert = useAlert();
  let history = useHistory();

  useEffect(
    () => {
      return () => {
        dispatch({ type: "SET_CURRENTLESSON_TO_NULL" });
      };
    },
    //eslint-disable-next-line
    []
  );
  //  const alertb = useAlert();
  //  const alerta = alert.show("Some message");
  //const alert = alert.info("You are adding a lesson!", { timeout: 0 });
  const successmsg = () => {
    return `${mystate.addevent.is_recuring ? "Recurring " : ""}  Event ${
      mystate.addevent.title
    }  on ${dateObjToDisplayDate(mystate.dateTimeComponent.start)}
       at  ${dateObjToDisplayTime(mystate.dateTimeComponent.start)} has been saved successfully.`;
  };

  const handleCancel = () => {
    history.goBack();
    console.log("click handleCancel");
  };

  const saveNewEvent = async () => {
    dispatch({
      type: "FETCH_INIT",
    });
    if (mystate.addevent.section_id == undefined) {
      alert.show("section is required", { timeout: 15000, type: "warning" });
      dispatch({
        type: "FETCH_SUCCESS",
      });
      return null;
    }
    const { horses, students, start, end } = mystate.addevent;
    let studentsArray = [],
      horsesArray = [];

    if (students) {
      studentsArray = students.map((student) => {
        return student.value;
      });
      console.log(studentsArray);
    }
    if (horses) {
      horsesArray = horses.map((horses) => {
        return horses.value;
      });
      console.log(horsesArray);
    }

    const repeatObj = getRepeatFirstLast(mystate, {});
    const datetimeInfo = formatDateTimeForDB(mystate.dateTimeComponent);
    const dbParams = {
      ...mystate.addevent,
      ...repeatObj,
      ...datetimeInfo,
      students: studentsArray,
      horses: horsesArray,
      calendarDateRangeStart: dateObjToDBDate(mystate.calendarDateRangeStart),
      calendarDateRangeEnd: dateObjToDBDate(mystate.calendarDateRangeEnd),
    };
    console.log(dbParams.date1);

    let route = mystate.addevent.is_recuring ? "lessons/createRecurringLesson" : "lessons/createSingleLesson";

    try {
      const result = await Auth.api(route, "post", dbParams);
      dispatch({ type: "ADD_NEW_LESSON", payload: result.data });
      dispatch({
        type: "FETCH_SUCCESS",
      });
      history.goBack();

      alert.show(successmsg(), { timeout: 15000, type: "success" });

      Auth.getConfirm();
      return result;
    } catch (error) {
      dispatch({ type: "FETCH_ERROR" });
      alert.show(error.message, { timeout: 5000, type: "error" });
    }
  };

  let location_id = Number(mystate.addevent.location_id);

  return (
    <>
      {1 === 1 && (
        <Container className="add" style={{ margin: 0 }}>
          <TitleRow clickevent={() => handleCancel()} title={"Add Event"} />

          <Row>
            <Col xs="12" md="6" lg="4">
              <Col xs="12">
                <div>{"SECTION "}</div>

                <SelectSectionDefaults />
              </Col>
            </Col>

            <Col xs="12" md="6" lg="4">
              <div>
                <ClockIcon />
                {"DATE AND TIME "}
              </div>
              <TimeAndDate />
            </Col>
            <Col xs="12" md="6" lg="4">
              <div>
                <RepeatIcon /> {"RECURRING "}
              </div>
              <RepeatingDates />
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="6" lg="4">
              <div style={orangelabel}>{"You are adding a lesson for the following student(s)"} </div>

              <SelectStudent perm={"CAN_ADD_STUDENT"} addOrEdit={"add"} />
            </Col>

            <Col xs="12" md="6" lg="4">
              <div>
                <HorseAvatar /> {"HORSES"}
              </div>
              {mystate.options_horse && <SelectHorse perm={"CAN_ADD_HORSE"} add horse />}
            </Col>

            <Col xs="12" md="6" lg="4">
              <div>
                <StaffAvatar /> {"STAFF"}
              </div>
              <SelectInstructor addOrEdit={"add"} />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6" lg="4">
              <div>
                <LocationIcon /> {"LOCATION "}
              </div>
              <SelectLocation />
            </Col>

            <Col xs="12" md="6" lg="4">
              <div>
                <NoteIcon /> {"NOTES"}
              </div>

              <TextArea style={{ border: "solid blue" }} addOrEdit={"add"} />
            </Col>
          </Row>

          <Row style={{ marginLeft: 50, marginRight: 50 }} />

          <Row style={{ marginTop: "50px", marginBottom: "50px" }}>
            <div style={{ margin: "auto" }}>
              {" "}
              <Button
                className="btn-close"
                size="md"
                onClick={() => {
                  handleCancel();
                }}
              >
                Cancel
              </Button>
              <Button className="btn-save" size="md" onClick={() => saveNewEvent()}>
                Save Event
              </Button>
            </div>
          </Row>
        </Container>
      )}
    </>
  );
}
export default AddModalStu;
