import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Route, Link, useRouteMatch, useParams, useHistory, useLocation } from "react-router-dom";
import { MyContext } from "./App";
import { ListContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import { map_options_instructor } from "./Constants2";
//import LogRocket from "logrocket";
import posthog from "posthog-js";

import { useAlert } from "react-alert";
import useSWR, { mutate, trigger } from "swr";
import axios from "axios";
import { calulateInitialCalendarDateRange } from "./calendarFunctions";
const Auth = new AuthHelperMethods();
const fetcher = (url, params) => axios.get(url, { params: params, headers: Auth.getHeaders() }).then((res) => res.data);

const DefaultOptions = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const params = { active: true };
  const alert = useAlert();

  const { data: sections, error } = useSWR("sections/index");
  const { data: options, error1 } = useSWR("options/index");

  console.log("GETTING_DEFALT_OPTIONS");
  useEffect(
    () => {
      const fetchData = async () => {
        console.log("***************** FARM FETCH *****************");
        try {
          const defaults = await Auth.api("farms/farmDefaults", "get");
          dispatch({
            type: "SET_FARM_DEFAULTS",
            data: defaults.data.farmDefaults,
            // farmPermissions: defaults.data.farmPermissions,
            currentUser: defaults.data.currentUser || "",
          });
          console.log(defaults.data.farmDefaults.show_lesson_by_client_name);
          //*setting this here instead of Calendar re false/undefined **//
          dispatch({
            type: "SET_SHOW_BY_STUDENT_NAME",
            data: Auth.getUserType() == "STU" ? false : defaults.data.farmDefaults.show_lesson_by_client_name,
          });
          dispatch({
            type: "SET_VIEW",
            data: defaults.data.farmDefaults.default_calendar_view,
          });

          var [currentStart, currentEnd] = calulateInitialCalendarDateRange(
            defaults.data.farmDefaults.default_calendar_view
          );

          dispatch({
            type: "SET_CALENDAR_DATE_RANGE",
            start: currentStart,
            end: currentEnd,
          });
          dispatch({
            type: "SET_RESOURCE",
            data: defaults.data.farmDefaults.default_day_view,
          });
          dispatch({
            type: "SET_RESOURCE_ACCESSOR",
            resourceAccessor: `${defaults.data.farmDefaults.default_day_view}_id`,
          });

          posthog.identify(`${defaults.data.currentUser} ${defaults.data.farmDefaults.farm_name}`);
          console.log(`${defaults.data.currentUser} ${defaults.data.farmDefaults.farm_name}`);
        } catch (error) {
          console.log(error);
          dispatch({ type: "FETCH_ERROR" });
        }
      };

      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        const result = await Auth.api("options/index", "get", params);
        dispatch({
          type: "UPDATE_OPTIONS",
          options: result.data,
        });
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const fetchData = async () => {
        console.log("**************** INSTRUCTOR FETCH *****************");
        try {
          const result = await Auth.api("instructors/index", "get", params);
          let options = result.data.options.map((option) => ({ ...option, color: option.color_code }));

          dispatch({
            type: "GET_INSTRUCTOR_OPTIONS",
            options: options,
          });
        } catch (error) {
          console.log("error");
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const fetchData = async () => {
        console.log("***************** DEFAULT SECTION FETCH *****************");
        try {
          // ? could get this from farmDefaults?  timing??
          const result = await Auth.api("sections/getDefaultSection", "get");
          dispatch({
            type: "SET_INITAL_SECTION_DEFAULTS",
            data: result.data.defaultSection,
          });
        } catch (error) {
          console.log("erroR");
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const fetchData = async () => {
        let params = { person_id: Auth.getUserPersonId() };
        try {
          const result = await Auth.api("people/getUserClients", "get", params);
          //  dispatch2(["CLIENT_GET", result.data.currentClient]);
          dispatch({ type: "CLIENT_GET_ACCOUNT", data: result.data });
        } catch (e) {
          alert.show(e.message, {
            timeout: 5000,
            type: "error",
          });
          console.log(e);
        }
      };

      if (Auth.getUserType() == "STU") fetchData();
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("vusers/getUserPermissions", "get");
        dispatch2(["SET_USER_PERMISSIONS", result.data.permissions]);
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  dispatch({ type: "SET_DEFAULTS_LOADED" });

  return null;
};

export default DefaultOptions;
