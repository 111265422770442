import React, { useState, useEffect, useContext } from "react";
import { MyContext, ListContext } from "./App";
import { Th, Td } from "./styleCustom";
import { ButtonOrange, ButtonOrangeInverse, ButtonOrangeMed, ButtonBlueMed, ModalWarning } from "./formComponents";
import { Check } from "./icons";
import { timeISOtoString } from "./momentUtils";
import { useAlert } from "react-alert";
import AuthHelperMethods from "./AuthHelperMethods";
import SelectCancelMakeupAction from "./selectCancelMakeupAction";
import Notifications from "./notification";
import { currentDayToDB, calculate_expiry_date, calculate_makeup_start_date, dateObjToDBDate } from "./momentUtils";
const Auth = new AuthHelperMethods();

const LessonMiddle = ({ lesson, lessonStudents }) => {
  return (
    <>
      <Td>
        {" "}
        {!lesson.is_allday &&
          `${timeISOtoString(lesson.scheduled_starttime)} -  ${timeISOtoString(lesson.scheduled_endtime)}`}
      </Td>
      <Td>{lesson.title}</Td>
      <Td>{lesson.instructor_name}</Td>
      <Td>
        {lessonStudents &&
          lessonStudents
            .filter((students) => students.lesson_date_time_id == lesson.id)
            .map((student) => <span>{`${student.name} `}</span>)}
      </Td>
    </>
  );
};

const SelectAllNone = ({ lessonStudents, list, listArray, setListArray }) => {
  const [selectAll, setSelectAll] = useState(false);
  const alert = useAlert();

  useEffect(
    () => {
      // if (action=="cancel")
      selectAll ? setListArray(list.map((item) => item.id)) : setListArray([]);
    },
    //eslint-disable-next-line
    [selectAll]
  );

  const onClickCheck = (lesson) => {
    console.log(lesson.id);
    console.log(listArray?.includes(lesson.id));
    if (lesson.lesson_is_cancelled && action == "cancel") alert.show("This lesson is already cancelled");

    if (listArray?.length == 0) setListArray([lesson.id]);
    else if (listArray.includes(lesson.id)) setListArray(listArray.filter((id) => id != lesson.id));
    else setListArray([...listArray, lesson.id]);
    console.log(listArray);
  };

  return (
    <table style={{ marginTop: 30 }}>
      <tr>
        <Th style={{ border: "solid" }} onClick={() => setSelectAll(!selectAll)}>
          {selectAll ? <Check fontSize={16} /> : <Check fontSize={16} color={"white"} />}
        </Th>
        <th>{"select all/none"}</th>
      </tr>
      {list.map((item) => (
        <tr style={{ background: item.lesson_is_cancelled ? "lightgrey" : item.color }}>
          <Td
            key={item.id}
            onClick={() => onClickCheck(item)}
            style={{ minWidth: 20, background: "white", border: "solid 1pt grey" }}
          >
            {listArray.includes(item.id) ? <Check fontSize={16} /> : <Check fontSize={16} color={"white"} />}
          </Td>

          <LessonMiddle lesson={item} lessonStudents={lessonStudents} />
        </tr>
      ))}
    </table>
  );
};

export const Bulk = ({ events, lessonStudents }) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const [selectAll, setSelectAll] = useState(false);
  const [action, setAction] = useState();
  const [showWarning, setShowWarning] = useState(false);
  const [listArray, setListArray] = useState([]);
  const [cancelReason, setCancelReasonX] = useState("Lesson Cancelled");
  const [cancelAction, setCancelActionX] = useState(mystate.farmDefaults.lesson_cancel_default_action || "BCNA");
  const [notificationMessageOnBlur, setNotificationMessageOnBlur] = useState("");
  const [sendNotification, setSendNotification] = useState(false);
  const [paramsx, setParamsx] = useState({});
  const alert = useAlert();

  useEffect(
    () => {
      setListArray([]);
      setSelectAll(false);
      setAction("");
      console.log("date changed");
    },
    //eslint-disable-next-line
    [listState.filter.dbday]
  );

  const finishBulkAction = () => {
    setListArray([]);
    setSelectAll(false);
    setAction("");
  };
  /*const onClickCheck = (lesson) => {
    if (lesson.lesson_is_cancelled && action == "cancel") alert.show("This lesson is already cancelled");
    else dispatch({ type: "SET_BULK_IDS", id: lesson.id });
  };
*/
  const onClickClose = () => {
    setAction("");
  };

  const onClickCancelEvents = () => {
    if (!checkChecked()) return null;
    dispatch({ type: "SET_EDIT_ACTION", edit_action: "bulkCancel" });
    dispatch({ type: "SET_BULK_FLAG", edit_action: true });

    console.log(listArray);
    /* const events = events
      .filter((lesson) => listArray.includes(lesson.id))
      .filter((eachLesson) => eachLesson.lesson_is_cancelled);
    if (events.length == 0) alert("There are not uncancelled lessons today."); */ //todo put back check
    //else
    setAction("cancel");
  };

  const checkChecked = () => {
    if (listArray == 0) {
      alert.show(`There is nothing selected ${action ? `to ${action}` : ""}`);
      return false;
    }
    return true;
  };

  const getMakeupLessonExpiry = calculate_expiry_date(listState.dbday, mystate.farmDefaults.makeup_expiry_days || 30);
  const getMakeupLessonStart = calculate_makeup_start_date(
    listState.dbday,
    mystate.farmDefaults.makeup_start_before_days || 14
  );

  const onSaveCancelEvents = async () => {
    console.log("onSaveCancel");
    console.log(checkChecked);
    if (!checkChecked()) return null;

    const params = {
      cancelAction: cancelAction,
      cancelReason: cancelReason.cancel_code,
      sendNotification: sendNotification,
      editAction: "cancel",
      updated_notification_message: notificationMessageOnBlur,
      scheduled_date: listState.filter.dbday,
      bulkIds: listArray,
    };

    if (mystate.bulk_cancel_action == "BCWM" || mystate.bulk_cancel_action == "BCWA") {
      params.expiry_date = dateObjToDBDate(getMakeupLessonExpiry);
      params.makeup_start_date = dateObjToDBDate(getMakeupLessonStart);
      params.purchase_date = currentDayToDB();
    }

    console.log(params);
    dispatch({ type: "FETCH_INIT" });

    try {
      const result = await Auth.api("lessons/bulkCancel", "post", params);
      dispatch({
        type: "FETCH_SUCCESS",
      });
      if (result) {
        alert.show("Lessons Cancelled", {
          timeout: 5000,
          type: "success",
        });
        finishBulkAction();

        //    dispatch2(["SET_SEARCH_FILTER_RADIO", "0", "bulk"]);
      }
    } catch (error) {
      dispatch({ type: "FETCH_ERROR" });
    }
  };

  const onClickDelete = () => {
    if (!checkChecked()) return null;
    setShowWarning(true);
  };

  const deleteEvent = async () => {
    console.log(listArray);

    console.log("deleteEvent");

    setAction("delete");

    setShowWarning(false);

    dispatch({ type: "SET_EDIT_ACTION", edit_action: "bulkDelete" });

    //  if (!checkChecked()) return null;

    let params = { idarray: listArray };
    console.log(params);
    dispatch({ type: "FETCH_INIT" });

    try {
      const result = await Auth.api("lessons/deleteAll", "post", params);
      dispatch({
        type: "FETCH_SUCCESS",
      });
      if (result) {
        alert.show("Items Deleted", {
          timeout: 5000,
          type: "success",
        });
        finishBulkAction();
      }
    } catch (error) {
      dispatch({ type: "FETCH_ERROR" });

      console.log(error);
    }
  };
  const BulkButtons = () => {
    return (
      <div>
        <ButtonOrange onClick={onClickDelete} label={"Delete Selected Event(s)"} />
        <ButtonOrange onClick={onClickCancelEvents} label={"Cancel Selected Event(s)"} />
      </div>
    );
  };

  if (!mystate.daysheet) return <BulkButtons />;
  if (!events) return <BulkButtons />;
  if (events.length === 0) return <BulkButtons />;

  return (
    <div style={{ marginTop: 30 }}>
      <BulkButtons />
      <SelectAllNone list={events} lessonStudents={lessonStudents} listArray={listArray} setListArray={setListArray} />

      {action == "cancel" && (
        <>
          <div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <SelectCancelMakeupAction setCancelActionX={setCancelActionX} setCancelReasonX={setCancelReasonX} />
              <Notifications
                setNotificationMessageOnBlur={setNotificationMessageOnBlur}
                setSendNotification={setSendNotification}
              />
            </div>
          </div>
        </>
      )}
      {showWarning && (
        <ModalWarning
          title={"Delete Event(s)?"}
          warningMessage={"Warning!  Events and all their associated data will be delete forever."}
          cancel={() => setShowWarning(false)}
          proceed={deleteEvent}
          cancellabel={"No Do Not Delete"}
          proceedlabel={"Yes Delete Event(s) Forever"}
        />
      )}
      {action && (
        <div
          style={{
            marginTop: 100,
            display: "flex",
            justifyContent: "space-evenly",
            flex: "1 1 ",
            maxWidth: 400,
            minHeight: 50,
            position: "relative",
            zIndex: 3000,
          }}
        >
          <ButtonBlueMed label={"Cancel"} onClick={onClickClose} />

          <ButtonOrangeMed label={"Save Changes"} onClick={onSaveCancelEvents} />
        </div>
      )}
    </div>
  );
};
