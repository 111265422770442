import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, Link, useParams, Switch, useLocation } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import AuthHelperMethods from "./AuthHelperMethods";
import SearchFilter, { ModalFilter } from "./searchFilter";
import { ListContext, MyContext } from "./App";
import { PrimaryTextBlue, PageTitle, BlueInverseButtonSm, PrimaryText30, Th, Td, OrangeButtonSm } from "./styleCustom";
import SearchFilterDates from "./searchFilterDates";
import moment from "moment";
import Pagination from "react-js-pagination";
import { CSVLink, CSVDownload } from "react-csv";
import { Dropdown } from "react-bootstrap";
import Select from "react-select";
import { setNestedObjectValues } from "formik";
import { Desc, Asc } from "./icons";
import COLORS from "./styleConstants";
import {
  dateObjToDisplayDate,
  dateISOtoString,
  dateObjToDBDate,
  DBDATEFORMAT,
  timeISOtoString,
  timeISOtoStringN,
  timeISOtoStringUtc,
  dateObjToDisplayDateShort,
  dateTimeISOtoStringMedLocal,
} from "./momentUtils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { result } from "lodash";
import { Limit, ButtonOrangeMed } from "./formComponents";
import { HorseGreen, HorseSleep } from "./images";
//import fetcher from "./App";
import useSWR from "swr";
import axios from "axios";
import useSWRInfinite from "swr/infinite";
import { SelectTemplateFilter, SelectNoLabel, CancelRow, Active } from "./searchFilterReports";
import { options_attendance, available_options } from "./Constants2";
import { OpenClose, ReportChoice } from "./formComponents";

//const { data, error } = useSWR([url, params], (url, params) => fetcher(url, { params }), {
//  revalidateOnFocus: false,
//});
const Auth = new AuthHelperMethods();
//const headers = Auth.getHeaders();

export const WorkloadGraphic = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const report = useParams();

  const [data, setData] = useState([]);
  const [dataw, setDataw] = useState([]);

  const [headers, setHeaders] = useState();
  let wholewordarray = [];
  //const [wholearray, setWholearray] = useState([]);
  let wholearray = [];
  const [go, setGo] = useState([]);
  const [goWord, setGoWord] = useState([]);

  useEffect(
    () => {
      let date = moment().toDate();
      let currentWeekStart = moment(date).startOf("week").format(DBDATEFORMAT);
      let currentWeekEnd = moment(date).endOf("week").format(DBDATEFORMAT);
      dispatch2(["SET_SEARCH_FILTER_DATES_NEW", dateObjToDBDate(currentWeekStart), "date1"]);
      dispatch2(["SET_SEARCH_FILTER_DATES_NEW", dateObjToDBDate(currentWeekEnd), "date2"]);
    }, //eslint-disable-next-line
    []
  );
  useEffect(
    () => {
      const fetchData = async () => {
        dispatch({ type: "FETCH_INIT" });

        let params = { ...listState.filter };
        const result = await Auth.api("reports/workloadgraphic", "get", params);
        if (result) {
          let horsestoshow = [];
          setData(result.data.a);
          setDataw(result.data.graphicword);
          setHeaders(result.data.dateheaders);
          //console.log(result.data.graphicword);
          if (listState.filter.horse)
            horsestoshow = mystate.options_horse.filter((horses) => listState.filter.horse.includes(horses.value));
          else horsestoshow = mystate.options_horse;
          horsestoshow &&
            horsestoshow.map((horse) => {
              //   console.log(horse.label);
              let horsearray = [horse.label];
              let wordarray = [horse.label];
              result.data.a.map((weekday) => {
                let obj = weekday.find((el) => el.horse_id == horse.value);
                if (obj) horsearray = [...horsearray, obj.totla];
                else horsearray = [...horsearray, 0];
              });
              if (props.word) {
                result.data.graphicword.map((day, index) => {
                  let obj = day.filter((el) => el.horse_id == horse.value);
                  let all = [];
                  if (obj) {
                    obj.map((el) => {
                      all = [...all, el.name];
                    });

                    wordarray = [...wordarray, all];
                  }
                });
              }

              wholearray = [...wholearray, horsearray];
              wholewordarray = [...wholewordarray, wordarray];
            });
          console.log(wholearray);
          console.log(wholewordarray);

          setGo(wholearray);
          setGoWord(wholewordarray);

          // console.log(go);
        }
        dispatch({ type: "FETCH_SUCCESS" });
      };
      if (listState.filter.date1 && listState.filter.date2) fetchData();
    },
    //eslint-disable-next-line
    [listState.filter]
  );
  if (!mystate.options_horse) return null;
  //if (wholearray.length == 0) return null;
  return (
    <>
      <div style={{ minHeight: 250 }}>
        <SearchFilterDates label={"Reporting Period"} default={"weekly"} circleValue={"weekly"} />
      </div>
      <table style={{ borderCollapse: "collapse" }}>
        <thead>
          <th>{"HORSE"}</th>
          {headers &&
            go.length > 1 &&
            headers.map((day) => (
              <th style={{ textAlign: "center" }}>
                <div> {moment(day).format("ddd")}</div>
                <div> {moment(day).format("D")}</div>
              </th>
            ))}
        </thead>
        <tbody>
          {report.report == "graphic" &&
            go.map((horseweekarray) => (
              <tr>
                {horseweekarray.map((weekday, index) =>
                  index == 0 ? (
                    <td style={{ border: "none", borderBottom: "1px solid lightgrey" }}>{weekday}</td>
                  ) : (
                    <td style={{ border: "none", borderBottom: "1px solid lightgrey", textAlign: "center" }}>
                      {weekday > 0 ? (
                        weekday > 1 ? (
                          weekday > 2 ? (
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                              <HorseGreen />
                              <HorseGreen />
                              <span style={{ color: COLORS.orange, fontWeight: "bold", fontSize: 12 }}>{`+ ${
                                weekday - 2
                              }`}</span>
                            </div>
                          ) : (
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <HorseGreen /> <HorseGreen />
                            </div>
                          )
                        ) : (
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            {" "}
                            <HorseGreen />
                          </div>
                        )
                      ) : (
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                          <HorseSleep />
                        </div>
                      )}
                    </td>
                  )
                )}
              </tr>
            ))}
          {report.report == "numbers" &&
            go.map((horseweekarray) => (
              <tr>
                {horseweekarray.map((weekday, index) =>
                  index == 0 ? (
                    <td style={{ border: "none", borderBottom: "1px solid lightgrey" }}>{weekday}</td>
                  ) : (
                    <td style={{ border: "none", borderBottom: "1px solid lightgrey", textAlign: "center" }}>
                      {weekday}
                    </td>
                  )
                )}
              </tr>
            ))}

          {props.word &&
            goWord.map((horseweekarray) => (
              <tr>
                {horseweekarray.length > 0 &&
                  horseweekarray.map((weekday, index) =>
                    index == 0 ? (
                      <td style={{ border: "none", borderBottom: "1px solid lightgrey" }}>{weekday}</td>
                    ) : (
                      <td style={{ border: "none", borderBottom: "1px solid lightgrey", textAlign: "center" }}>
                        {weekday.map((lesson, index) => (
                          <div>{`${index + 1}. ${lesson}`}</div>
                        ))}
                      </td>
                    )
                  )}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};
const Filter = () => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const [showFilter, setShowFilter] = useState(true);

  return (
    <div className="filterside" style={{ display: "flex", flex: "1 1 30%", flexDirection: "column", marginRight: 50 }}>
      <OpenClose onClick={() => setShowFilter(!showFilter)} show={showFilter} />
      <div
        className="filter"
        style={{
          display: "flex",
          flex: "1 1 100%",
          flexWrap: "wrap",
          border: "solid 1pt lightgrey",
          paddingBottom: 20,
          alignContent: "center",
          flexDirection: "column",
        }}
      >
        {showFilter && (
          <>
            <SelectTemplateFilter
              options={mystate.options_section}
              isMulti
              label={"section"}
              list={listState.filter_labels.section || null}
              placeholder="section"
              value={listState.filter.section}
              maxWidth={200}
            />
            <SelectTemplateFilter
              options={mystate.options_instructor}
              isMulti
              label={"instructor"}
              list={listState.filter_labels.instructor || null}
              value={listState.filter.instructor || null}
              maxWidth={200}
            />
            <SelectTemplateFilter
              options={mystate.options_program}
              isMulti
              label={"program"}
              list={listState.filter_labels.program || null}
              value={listState.filter.program || null}
              maxWidth={200}
            />
            <SelectTemplateFilter
              options={mystate.options_program_type}
              isMulti
              label={"program_type"}
              extraLabel={"program type"}
              list={listState.filter_labels.program_type || null}
              value={listState.filter.program_type || null}
              maxWidth={200}
            />
            <SelectTemplateFilter
              options={mystate.options_location}
              isMulti
              label={"location"}
              list={listState.filter_labels.location || null}
              value={listState.filter.location || null}
              maxWidth={200}
            />
            <SelectTemplateFilter
              options={options_attendance}
              isMulti
              label={"attendance"}
              list={listState.filter_labels.attendance || null}
              value={listState.filter.attendance || null}
              maxWidth={200}
            />
            <SelectTemplateFilter
              options={mystate.options_student}
              isMulti
              label={"student"}
              list={listState.filter_labels.student || null}
              value={listState.filter.student || null}
              maxWidth={200}
            />
            <SelectTemplateFilter
              options={mystate.options_horse}
              isMulti
              label={"horse"}
              list={listState.filter_labels.horse || null}
              value={listState.filter.horse || null}
              maxWidth={200}
            />
            <Active
              biglabel
              direction={"column"}
              label={"lesson cancelled by barn"}
              field={"lesson_is_cancelled"}
              default={"0"}
              options={[
                { value: 0, label: "don't include barn cancelled lessons" },
                { value: 1, label: "show barn cancelled lessons only" },
              ]}
            />
          </>
        )}
      </div>
    </div>
  );
};
const ORANGEBUTTON = {
  backgroundColor: COLORS.orange,
  padding: 8,
  color: "white",
  fontSize: 12,
  minWidth: 100,
  margin: 5,
  borderRadius: 5,
  border: "medium none,",
  borderColor: COLORS.orange,
};
const GREYBUTTON = {
  backgroundColor: COLORS.lightgrey,
  padding: 8,
  color: "black",
  fontSize: 12,
  minWidth: 100,
  margin: 5,
  borderRadius: 5,
  border: "medium none,",
  borderColor: COLORS.lightgrey,
};

const Csv = ({ data, currentParams, dateText }) => {
  console.log(dateText);
  const filename = `${currentParams.firsturl}-${currentParams.report}-${dateText}.csv`;
  // const filename = "filenamehere.csv";
  console.log(filename);

  return (
    <button style={ORANGEBUTTON}>
      <CSVLink data={data} filename={filename} style={{ color: "white" }}>
        Download CSV
      </CSVLink>
    </button>
  );
};

function manipDetail(objtom, currentParams) {
  console.log(objtom);
  console.log(currentParams);
  console.log(currentParams.report);
  console.log(currentParams.firsturl);
  console.log(currentParams.report == "logs" && currentParams.firsturl == "email");
  if (currentParams.report == "detail") {
    let a = objtom.map((obj) => ({
      ...obj,
      date: dateISOtoString(obj.scheduled_date),
      time: obj.is_allday ? "all day" : timeISOtoStringUtc(obj.scheduled_starttime),
      event: obj.title,
    }));

    if (currentParams.report == "detail" && currentParams.firsturl == "enrollment")
      return a.map((obj) => ({
        student: obj.person_name,
        date: obj.date,
        time: obj.time,
        event: obj.event,
        cancellation:
          obj.lesson_is_cancelled == 1
            ? "Cancelled by Barn"
            : obj.lesson_is_cancelled != 1 && obj.student_is_cancelled == 1
            ? "Cancelled by Student"
            : null,
        action: obj.enrollment_status_name,
        code: obj.code,
        ...(currentParams.contact && { email: obj.email }),
        ...(currentParams.contact && { phone: obj.phone }),
      }));
    else if (currentParams.report == "detail" && currentParams.firsturl == "workload")
      return a.map((obj) => ({
        horse: obj.horse_name,
        date: obj.date,
        time: obj.time,
        event: obj.event,
        client: obj.client,
        cancellation: obj.lesson_is_cancelled == 1 ? "Cancelled by Barn" : null,
      }));
    else if (currentParams.report == "detail" && currentParams.firsturl == "lessonStaff")
      return a.map((obj) => ({
        staff: obj.staff,
        date: obj.date,
        time: obj.time,
        event: obj.event,
        cancellation: obj.lesson_is_cancelled == 1 ? "Cancelled by Barn" : null,
      }));
    else if (currentParams.report == "detail" && currentParams.firsturl == "studentStaff")
      return a.map((obj) => ({
        staff: obj.person_name,
        date: obj.date,
        time: obj.time,
        event: obj.event,
        student: obj.student,
        status: obj.status,
        cancelled:
          obj.lesson_is_cancelled == 1 ? "Cancelled by Barn" : obj.student_is_cancelled ? "student cancel" : null,
      }));
    else if (currentParams.report == "detail" && currentParams.firsturl == "extraStaff")
      return a.map((obj) => ({
        staff: obj.person_name,
        date: obj.date,
        time: obj.time,
        event: obj.event,
        task: obj.task_name,
      }));
  }

  if (currentParams.report == "logs" && currentParams.firsturl == "email") {
    console.log("EMAIL REPORT");
    return objtom.map((obj) => ({
      to: obj.email_to,
      type: obj.template_desc,
      status: obj.message,
      sent: dateTimeISOtoStringMedLocal(obj.created_at),
    }));
  }

  if (currentParams.report == "error" && currentParams.firsturl == "email") {
    console.log("EMAIL REPORT");
    return objtom.map((obj) => ({
      error: obj.error_type,
      desc: obj.error_message,
      sent: dateTimeISOtoStringMedLocal(obj.created_at),
    }));
  }

  objtom.map((row) => delete row.id);
  return objtom;
}

const ReportTable = () => {
  //const [data, setData] = useState([]);
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [total, setTotal] = useState();
  const [headers, setHeaders] = useState([]);
  const [sortBy, setSortBy] = useState();
  const [direction, setDirection] = useState(true);
  const [paramsAreReady, setParamsAreReady] = useState(false);
  const [currentParams, setCurrentParams] = useState({});
  const INITIAL_PAGE_SIZE = 200;
  const [showContacts, setShowContacts] = useState(false);
  const [pagesize, setPagesize] = useState(INITIAL_PAGE_SIZE);

  let firsturl = "";
  let urlparams = useParams();
  let location = useLocation();

  const changeSort = (sortIn) => {
    console.log(sortIn);
    // console.log(findFieldName(sortIn));
    // setSortBy(findFieldName(sortIn));
    setSortBy(sortIn);
    setDirection(!direction);
    //  fetchData(sortIn, direction);
  };

  useEffect(() => {
    console.log("**********INITIAL******************");
    if (location.pathname.includes("enrollment")) firsturl = "enrollment";
    if (location.pathname.includes("workload")) firsturl = "workload";
    if (location.pathname.includes("lessonStaff")) firsturl = "lessonStaff";
    if (location.pathname.includes("studentStaff")) firsturl = "studentStaff";
    if (location.pathname.includes("financial")) firsturl = "financial";
    if (location.pathname.includes("lessons")) firsturl = "lessons";
    if (location.pathname.includes("email")) firsturl = "email";
    if (location.pathname.includes("extraStaff")) firsturl = "extraStaff";

    // console.log(data);
    setPagesize(INITIAL_PAGE_SIZE);
    console.log(listState.filter);
    setCurrentParams({
      ...listState.filter,
      report: urlparams.report,
      url: `reports/${firsturl}/`,
      firsturl: firsturl,
      ...(showContacts && { contact: 1 }),
    });
    /* console.log(
      mystate.options_instructor
        .filter((option) => listState.filter.instructor?.includes(option.value))
        .map((x) => x.value)
    );*/
    if (listState.filter.date1 && listState.filter.date2) setParamsAreReady(true);
  }, [location.pathname, listState.filter, showContacts]);

  useEffect(
    () => {
      const fetchData = async () => {
        let params = {
          ...listState.filter,
          report: urlparams.report,
          url: `reports/${firsturl}/`,
          firsturl: firsturl,
          totalonly: 1,
        };
        const result = await Auth.api(currentParams.url, "get", params);
        if (result && result.data.totals) setTotal(result.data.totals);
      };
      if (paramsAreReady) fetchData();
    },
    //eslint-disable-next-line
    [currentParams]
  );

  useEffect(
    () => {
      console.log(location.pathname);
      console.log("location.pathname");

      dispatch2(["RESET_SEARCH_FILTER_EXCEPT_DATES"]);
    },
    //eslint-disable-next-line
    [firsturl]
  );

  const fetcher = (url, params) =>
    axios({ headers: Auth.getHeaders(), url: url, params: params, method: "get" }).then((res) => res.data.reports);

  const { data, error, mutate, size, setSize, isValidating } = useSWRInfinite(
    (index) => (paramsAreReady ? [`${currentParams.url}?page=${index + 1}&per_page=${pagesize}`, currentParams] : null),
    fetcher
  );

  const reportdatax = data ? [].concat(...data) : [];

  let reportdata = manipDetail(reportdatax, currentParams);

  console.log(reportdata);
  //eportdata = reportdata.map((x) => (x.id ? null : x));
  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd = isEmpty || (reportdata && reportdata?.length == total);
  const isRefreshing = isValidating && data && data.length === size;
  console.log(data ? data.length : "NOTYET");
  console.log(isEmpty);
  console.log(isReachingEnd);

  console.log(reportdata ? reportdata.length : "NO REPORT DATA");

  const dateText = `${listState.filter.date1 && dateObjToDisplayDate(listState.filter.date1)}
  - 
  ${listState.filter.date2 && dateObjToDisplayDate(listState.filter.date2)}
`;
  const dateTextShort = `${listState.filter.date1 && dateObjToDisplayDateShort(listState.filter.date1)}-${
    listState.filter.date2 && dateObjToDisplayDateShort(listState.filter.date2)
  }`;

  function onClear() {
    setSize(size + 1);
    setTotal(0);
  }
  if (firsturl == "extraStaff") return <div>extrasrff</div>;
  return (
    <div
      className="reportWrapper"
      style={{ display: "flex", flex: "1 1 100%", flexWrap: "nowrap", marginTop: 50, alignItems: "flex-start" }}
    >
      <Filter />
      <div
        className="reportTable"
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          flex: "1 1 70%",
        }}
      >
        <div
          className="reportTitle"
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "space-between",
            flex: "1 1 100%",
          }}
        >
          <PrimaryText30> {`${currentParams.firsturl} by ${currentParams.report}   `}</PrimaryText30>
        </div>

        <div style={{ display: "flex", flex: "1 1 100%" }}>
          <SearchFilterDates label={"Reporting Period"} hidedate />
        </div>

        <div className={"buttonsTop"} style={{ display: "flex", flex: "1 1 100%", flexDirection: "column" }}>
          <p>
            <button style={ORANGEBUTTON} disabled={isLoadingMore || isReachingEnd} onClick={() => setSize(size + 1)}>
              {isLoadingMore ? "loading..." : isReachingEnd ? "no more records" : `load next ${INITIAL_PAGE_SIZE}  `}
            </button>
            {!isReachingEnd && (
              <button style={ORANGEBUTTON} Disabled={isLoadingMore || isReachingEnd} onClick={() => setPagesize(total)}>
                {isLoadingMore ? "loading..." : `load all  `}
              </button>
            )}

            {reportdata?.length > 0 && (
              <Csv
                data={(listState.filter_labels, reportdata)}
                currentParams={currentParams}
                dateText={dateTextShort}
              />
            )}
            {currentParams.firsturl == "enrollment" && (
              <button style={showContacts ? ORANGEBUTTON : GREYBUTTON} onClick={() => setShowContacts(!showContacts)}>
                show contact detail
              </button>
            )}
          </p>
          <p>
            {" "}
            showing {isLoadingMore ? "..." : reportdata.length} record(s) of {total}
          </p>
          {listState.filter.date1 && (
            <div
              className="textdate"
              style={{
                display: "flex",
                flex: "1 1 100%",

                color: COLORS.orange,
              }}
            >
              <div style={{ fontSize: 20 }}>{dateText}</div>
            </div>
          )}
        </div>
        {Object.entries(listState.filter_labels).length > 0 && (
          <>
            {" "}
            <div className={"filters"} style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ marginTop: 3, fontSize: 16, color: COLORS.darkgrey }}>{"Data is being filtered by: "}</div>
              <div
                onClick={() => dispatch2(["RESET_SEARCH_FILTER_EXCEPT_DATES"])}
                style={{ color: COLORS.blue, cursor: "pointer" }}
              >
                clear all filters
              </div>

              {Object.entries(listState.filter_labels).map(([key, value]) => {
                return (
                  <div>
                    <span style={{ color: COLORS.orange }}>{`${key}: `}</span>
                    <span style={{ color: COLORS.textDark }}>{`${value.join(", ")}  `}</span>
                  </div>
                );
              })}
            </div>
          </>
        )}

        <div
          className={"table"}
          style={{
            marginTop: 20,
            display: "flex",
            flex: "1 1 100%",
            flexDirection: "column",
          }}
        >
          <table style={{ width: "100%" }}>
            <thead>
              <tr>{reportdata && reportdata.length > 0 && Object.keys(reportdata[0]).map((key) => <Th> {key}</Th>)}</tr>
            </thead>
            <tbody>
              {reportdata &&
                reportdata.length > 0 &&
                reportdata.map((row) => (
                  <tr>
                    {Object.keys(row).map((key) => (
                      <Td>{row[key]}</Td>
                    ))}{" "}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className={"buttonsBottom"} style={{ display: "flex", flex: "1 1 100%", flexDirection: "column" }}>
          <p>
            <button style={ORANGEBUTTON} disabled={isLoadingMore || isReachingEnd} onClick={() => setSize(size + 1)}>
              {isLoadingMore ? "loading..." : isReachingEnd ? "no more records" : `load next ${INITIAL_PAGE_SIZE}  `}
            </button>
            {!isReachingEnd && (
              <button style={ORANGEBUTTON} Disabled={isLoadingMore || isReachingEnd} onClick={() => setPagesize(total)}>
                {isLoadingMore ? "loading..." : `load all  `}
              </button>
            )}

            {reportdata?.length > 0 && (
              <Csv
                data={(listState.filter_labels, reportdata)}
                currentParams={currentParams}
                dateText={dateTextShort}
              />
            )}
          </p>
          <p>
            {" "}
            showing {isLoadingMore ? "..." : reportdata.length} record(s) of {total}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReportTable;
