import React, { useState, useContext, useEffect, useRef } from "react";
import { Route, Switch } from "react-router-dom";
import { ListContext, MyContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import { BlueNavLink, BlueNavLinkSm, Row2, PageTitle } from "./styleCustom";
import Sections from "./sections";
import COLORS from "./styleConstants";
import { AdminPaymentReport } from "./adminPaymentReport";
import Products from "./products";
import AssignPackage from "./assignPackage";
import { ClientNotesReview, HorseNotesReview, StaffNotesReview } from "./notesAdmin";
import Programs from "./programs";
import Waiver from "./waiver";
import PrivateRoute from "./privateRoute";
import ClientHomePage from "./clientHomePage";
import ReportTable from "./reportTable";
import { UserCan } from "./formComponents";
import { AdminPackages } from "./adminPackages";
import axios from "axios";

const Auth = new AuthHelperMethods();

const Admin = (props) => {
  const { mystate, dispatch } = useContext(MyContext);

  useEffect(() => {
    //window.scrollTo(0, 0);
  }, []);

  if (Auth.getUserType() != "ADM") return null;
  return (
    <>
      <PageTitle>{"Admin"}</PageTitle>

      <div
        className="admin"
        style={{
          background: COLORS.blue,

          fontSize: "10px",
          marginBottom: 30,
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "wrap",

          flex: "1 1 100%",
        }}
      >
        <>
          <BlueNavLinkSm to={`${props.match.url}/clientnotes`}> Client Notes</BlueNavLinkSm>
          <BlueNavLinkSm to={`${props.match.url}/staffnotes`}> Staff Notes</BlueNavLinkSm>
          <BlueNavLinkSm to={`${props.match.url}/horsenotes`}> Horse Notes</BlueNavLinkSm>
          <BlueNavLinkSm to={`${props.match.url}/assignPackages`}> Assign Packages</BlueNavLinkSm>
          <BlueNavLinkSm to={`${props.match.url}/packages`}>Package Reports</BlueNavLinkSm>
          <BlueNavLinkSm to={`${props.match.url}/paymentReport`}>Payment Report</BlueNavLinkSm>
          {mystate.farmDefaults.can_use_invoices && (
            <BlueNavLinkSm to={`${props.match.url}/invoices`}>Invoices</BlueNavLinkSm>
          )}

          {1 == 2 && (
            <BlueNavLinkSm to={`${props.match.url}/financial/paymentLesson/`}>Lesson Payment Report</BlueNavLinkSm>
          )}
          {1 == 2 && <BlueNavLinkSm to={`${props.match.url}/purchases/`}>Today Purchases</BlueNavLinkSm>}
          <UserCan ifFarm={"can_sign_waivers"}>
            <BlueNavLinkSm to={`${props.match.url}/waivers`}> Waivers</BlueNavLinkSm>
          </UserCan>
        </>
      </div>

      <div className="switch" style={{ display: "flex", flex: "1", flexWrap: "wrap" }}>
        <Switch>
          <PrivateRoute path={`${props.match.url}/clientnotes`} component={ClientNotesReview} />
          <PrivateRoute path={`${props.match.url}/staffnotes`} component={StaffNotesReview} />
          <PrivateRoute path={`${props.match.url}/horsenotes`} component={HorseNotesReview} />
          <PrivateRoute path={`${props.match.url}/paymentReport`} component={AdminPaymentReport} />
          <PrivateRoute path={`${props.match.url}/invoices`} component={AdminPaymentReport} />

          <PrivateRoute path={`${props.match.url}/financial/:report/`} component={ReportTable} />
          <PrivateRoute path={`/admin/admin/xpurchaseReport/financial/:report/`} component={ReportTable} />
          <PrivateRoute path={`${props.match.url}/packages`} component={AdminPackages} />
          <PrivateRoute path="/admin/admin/assignPackages" component={AssignPackage} />
          <PrivateRoute path="/admin/admin/waivers" component={Waiver} />
        </Switch>
      </div>
    </>
  );
};

export default Admin;
