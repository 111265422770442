import React, { useState, useContext, useEffect } from "react";
import ReactDom from "react-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { MyContext } from "./App";
import { DBDATEFORMAT, repeatOptions } from "./momentUtils";
import { DATEPICKER_DATE_DISPLAY } from "./UserSettings";
import { Row, Col, InputGroup, Form, ListGroup } from "react-bootstrap";
import Select from "react-select";
//import Checkbox from "./Checkbox";
import { CalendarStateButton } from "./perm.js";
//import { blueCheck } from "./images";
import { CheckImg, boxStyle } from "./blueCheckFuture";

//import moment-recur from "moment-recur";
require("moment-recur");

const RepeatingDates = () => {
  const { mystate, dispatch } = useContext(MyContext);
  const [show, setShow] = useState(false);
  const defaultNumberTimesValue = 4;
  let labelStylex = {
    marginTop: 0,
    textAlign: "left",
    border: "solid 1px #ced4da ",
    borderLeft: "none",
    minHeight: "30px",
    maxHeight: "30px",
    backgroundColor: "#e9ecef",
    paddingLeft: "5px",
    borderRadius: "3px",
    paddingRight: 10,
  };

  const checkRowStyle = {
    marginBottom: "15px",
    maxHeight: "30px",
    flex: "1 1 100%",
    flexWrap: "nowrap",
    display: "flex",
    background: "#e9ecef",
  };

  const labelStyle = {
    margin: "auto",
    padding: 5,
    flex: "1 1 auto",
  };

  const checkSquareStyle = {
    maxWidth: 30,
    minWidth: 30,
    height: 30,
    border: `solid 2pt grey`,
    flex: "0 0 auto",
    display: "flex",
    background: "white",
  };
  const handleCancel = () => {
    setShow(false);

    dispatch({
      type: "SET_REPEAT_VALUES",
      data: {
        selectRepeatValue: repeatOptions[0],
      },
    });
    dispatch({
      type: "SET_NEW_EVENT",
      data: {
        is_recuring: false,
      },
    });
  };

  useEffect(
    () => {
      dispatch({
        type: "SET_REPEAT_VALUES",
        data: {
          selectRepeatValue: repeatOptions[0],
        },
      });
    },
    //eslint-disable-next-line
    []
  );

  const handleShow = () => setShow(true);

  const handleRepeatEndDateChange = (datein) => {
    dispatch({
      type: "SET_REPEAT_VALUES",
      data: { repeatChooseEndDate: datein, endtype: "dates" },
    });
  };

  const handleRepeatEndNumberChange = (e) => {
    dispatch({
      type: "SET_REPEAT_VALUES",
      data: {
        frequency: e.target.value,
        endtype: "times",
      },
    });
  };

  const repeatEndTypeChange = (e) => {
    dispatch({
      type: "SET_REPEAT_VALUES",
      data: {
        endtype: e,
      },
    });
  };

  const handleCreateRepeatDates = () => {
    let start = mystate.addevent.start;
    let second = moment(mystate.addevent.start);

    let recurrence = mystate.repeat.recurrence;
    let repeat_end_type = mystate.repeat.repeat_end_type;
    let allRepeatDates = [];

    if (repeat_end_type == "times") {
      //console.log("repeat_end_type == times");
      allRepeatDates = recurrence.next(mystate.repeat.repeat_number_times, DBDATEFORMAT);
    } else if (repeat_end_type == "dates") {
      let end = moment(mystate.repeat.repeatChooseEndDate);
      if (end.diff(start, "days") > 0) {
        //console.log("repeat_end_type == dates");
        allRepeatDates = recurrence.endDate(moment(mystate.repeat.repeatChooseEndDate)).all(DBDATEFORMAT);
      } else {
        //console.log("end date must be greater than start date");
        //don't close modal here
      }
    } else {
      allRepeatDates = recurrence.endDate(moment(mystate.addevent.start).add(1, "year")).all(DBDATEFORMAT);
    }
    console.log(allRepeatDates);
    dispatch({
      type: "SET_NEW_EVENT",
      data: { allRepeatDates: allRepeatDates },
    });
    setShow(false);
    //console.log(allRepeatDates);
  };

  const handleRepeatSelectionChange = (e) => {
    let selectRepeatValue = e;
    let is_recuring = e.value.measure === "none" ? false : true;
    if (is_recuring) {
      handleShow();
      let measure = e.value.measure;
      let units = e.value.units;
      let start = mystate.addevent.start || mystate.currentLesson.start;
      let frequency = defaultNumberTimesValue;
      /*  let recurrence = moment
        .recur(moment(start))
        .every(repeat_units, repeat_measure);
      //console.log(recurrence);
      //console.log(start);*/

      dispatch({
        type: "SET_REPEAT_VALUES",
        data: {
          selectRepeatValue: selectRepeatValue,
          measure: measure,
          units: units,
          repeatStart: start,
          repeatChooseEndDate: start,
          frequency: frequency,

          endtype: "times",
          repeat_text: "default - add functionality for this ",
        },
      });
      dispatch({
        type: "SET_NEW_EVENT",
        data: { is_recuring: is_recuring },
      });
    } else {
      dispatch({
        type: "SET_NEW_EVENT",
        data: { is_recuring: is_recuring },
      });
      dispatch({
        type: "SET_REPEAT_VALUES",
        data: { selectRepeatValue: selectRepeatValue },
      });
    }
  };

  return (
    <>
      <Row noGutters>
        <div className="repeat" style={{ width: "100%" }}>
          <Select
            value={[mystate.repeat.selectRepeatValue]}
            onChange={(e) => handleRepeatSelectionChange(e)}
            options={repeatOptions}
          />
        </div>
      </Row>

      {mystate.repeat.selectRepeatValue != repeatOptions[0] && (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="blocklabel" style={{ flex: "1 1 100%" }}>
            END REPEATS{" "}
          </div>

          <div className="times" style={checkRowStyle} onClick={(e) => repeatEndTypeChange("times")}>
            <div style={checkSquareStyle} onClick={(e) => repeatEndTypeChange("times")}>
              {mystate.repeat.endtype == "times" ? <CheckImg /> : " "}
            </div>
            <label style={labelStyle}>{"after"}</label>
            <input
              className="times"
              defaultValue={defaultNumberTimesValue}
              type="number"
              onChange={(e) => handleRepeatEndNumberChange(e)}
              size="10"
              onFocus={(e) => (e.target.value = "")}
            />
            <label style={labelStyle}>{"times"}</label>
          </div>

          <div className="never" style={checkRowStyle}>
            <div style={checkSquareStyle} onClick={(e) => repeatEndTypeChange("never")}>
              {mystate.repeat.endtype == "never" ? <CheckImg /> : " "}
            </div>
            <label onClick={(e) => repeatEndTypeChange("never")} style={labelStyle}>
              {"ongoing"}
            </label>
          </div>

          <div className="dates" style={checkRowStyle}>
            <div style={checkSquareStyle} onClick={(e) => repeatEndTypeChange("dates")}>
              {mystate.repeat.endtype == "dates" ? <CheckImg /> : " "}
            </div>
            <label style={labelStyle}>{"on"}</label>
            <DatePicker
              dateFormat={DATEPICKER_DATE_DISPLAY}
              onChange={(date) => handleRepeatEndDateChange(date)}
              selected={mystate.repeat.repeatChooseEndDate}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default RepeatingDates;
