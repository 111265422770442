import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Link, useParams, useHistory, withRouter, useLocation } from "react-router-dom";
import { Row, Col, Modal, Accordion, Dropdown } from "react-bootstrap";
import { ListContext, MyContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import {
  BlueNavLink12,
  PrimaryText,
  PrimaryText18,
  PrimaryTextBlue,
  SaveButtonSm,
  CancelButtonSm,
  PageTitle,
  BlueText,
  HelpText,
  BlockLabelGrow,
  BlockLabelNoMargin,
} from "./styleCustom";

import {
  currentDayToDB,
  timeISOtoString,
  dateISOtoStringMed,
  is_expired,
  calculate_expiry_date,
  calculate_makeup_start_date,
  calculateCancellationNotice,
  dateObjToDisplayDate,
  dateObjToDBDate,
  dateObjToDisplayDatetime,
} from "./momentUtils";
import { ModalHeaderBlue, UserCan } from "./formComponents";
import { useAlert } from "react-alert";
import { cancel_student_options } from "./Constants2";
import Select from "react-select";
import COLORS from "./styleConstants";
import PrivateRoute from "./privateRoute";
import { Circle, DotCircle, Expand, QuestionIconNp, CircleSolid } from "./icons";
import "./style.css";
const Auth = new AuthHelperMethods();

const CancelStudentModal = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const [cancelReason, setCancelReason] = useState(mystate.farmDefaults.client_cancel_default_action || "CWP");
  const [options, setOptions] = useState();
  const [cancelOption, setCancelOption] = useState(mystate.farmDefaults.client_cancel_default_action || "CWP");
  const [noteInput, setNoteInput] = useState("");
  const [showHelpText, setShowHelpText] = useState(false);
  let history = useHistory();
  const alert = useAlert();

  //console.log(mystate.currentStudent);
  //console.log(a);

  // console.log(cancel_student_options);

  let lesson_person_id = useParams().lpid;
  console.log(lesson_person_id);
  let where = useParams().where;
  var lessonToCancel, lessonName, studentName, params, lessonPerson, personId, contactEmail, instructorId;

  if (where == "cal") {
    lessonToCancel = mystate.currentLesson;
    lessonName = lessonToCancel.title;
    lessonPerson = lessonToCancel.lessonStudents.find((lessonPerson) => lessonPerson.id == lesson_person_id);
    studentName = lessonPerson.name;
    personId = mystate.currentStudent.person_id;
    contactEmail = null;
  } else {
    lessonToCancel = listState.clientdata.find((lesson) => lesson.id == lesson_person_id);
    lessonName = lessonToCancel.lesson_name;
    studentName = listState.currentClient.person_name;
    personId = listState.currentClient.id;

    contactEmail = listState.currentClient.contact_email;
  }
  instructorId = lessonToCancel.instructor_id;
  useEffect(() => {
    //console.log(lessonPerson);
    var item;
    if (where == "cal") {
      console.log("calendar");
      item = mystate.currentLesson.lessonStudents.find((student) => student.id == lesson_person_id).item_id;
    } else {
      console.log("profile");

      item = listState.clientdata.find((lesson) => lesson.id == lesson_person_id).item_id;
    }

    /* if (!item) setOptions(cancel_student_options.filter((option) => ["CNA", "CLC", "CNS"].includes(option.value)));
    else {
      setOptions(cancel_student_options);
      // setCancelReason(mystate.farmDefaults.lesson_cancel_default_action || "CWP");
    }*/ /* changing this so not paid non package tracking can have makeups  */
    setOptions(cancel_student_options);
  }, []);

  //const [showwarning, setShowwarning] = useState(false);

  console.log(listState.currentClient.student_id);

  /*const handleCancelOptionsChange = (e) => {
    setCancelReason(e.value);
    setCancelOption(e);
    //setShowwarning(false);
  };*/

  //const lessontext = ` ${lessonName} on   ${dateObjToDisplayDatetime(mystate.currentLesson.start)}`;

  // console.log(lessontext);
  const getMakeupLessonExpiry = calculate_expiry_date(
    lessonToCancel.scheduled_date,
    mystate.farmDefaults.makeup_expiry_days || 30
  );
  const getMakeupLessonStart = calculate_makeup_start_date(
    lessonToCancel.scheduled_date,
    mystate.farmDefaults.makeup_start_before_days || 14
  );

  const handleClick = (code) => {
    console.log(code);
    setCancelReason(code);
    //  setActiveCode(code);
  };

  const saveCancelLesson = async () => {
    /*  if (!cancelReason) {
      setCancelReason("CWP");
      //console.log("must choose reason");
      // setShowwarning(true);
    }
*/
    dispatch({ type: "FETCH_INIT" });

    params = {
      id: lesson_person_id,
      //   student_id: listState.currentClient.student_id,  ? don't need
      newEnrollStatus: cancelReason,
      cancel_note: noteInput || "",
      scheduled_date: lessonToCancel.scheduled_date,
      lesson_date_time_id: lessonToCancel.lesson_date_time_id,
      lesson_string: lessonToCancel.lessonString,
      student_name: studentName,
      msg: emailmsg,
      purchase_date: currentDayToDB(),
      person_id: personId,
      instructor_id: instructorId,
    };
    if (contactEmail) params.contact_email = contactEmail;
    if (cancelReason == "CWM" || "CWA" || "CWC") {
      console.log("getMakeupLessonExpiry");
      params.expiry_date = dateObjToDBDate(getMakeupLessonExpiry);
      params.makeup_start_date = dateObjToDBDate(getMakeupLessonStart);
      params.purchase_date = currentDayToDB();
    }
    /*  if (cancelReason == "CWA") {
      params.expiry_date = getMakeupLessonExpiry();
    }*/
    console.log(params);
    try {
      const result = await Auth.api("lesson_people/cancelStudent", "post", params);
      console.log(result.data);

      // todo handle dispatch below based on cancel from homepage or edit lesson
      /* dispatch2(["UPDATE_LESSON_CANCEL", result.data]);
    alert.show(
      ` ${result.data.lessonPerson.lesson_name} on ${result.data.lessonPerson.scheduled_date} been cancelled  `,
      {
        timeout: 5000,
        type: "info",
      }
    );
    */

      if (where == "cal")
        dispatch({
          type: "ASSIGN_STUDENT_TO_LESSON_WITH_ID",
          data: result.data.toreturnForCalendar,
          lessonIds: result.data.lessonIds,
        });
      // dispatch({ type: "SET_STUDENT_ATTENDANCE", data: cancelOption });
      else {
        dispatch2(["UPDATE_LESSON_CANCEL", result.data.lessonpeopletoreturn[0]]);
        alert.show(` ${lessonName} on ${dateISOtoStringMed(lessonToCancel.scheduled_date)} been cancelled  `, {
          timeout: 5000,
          type: "info",
        });
      }
      dispatch({ type: "FETCH_SUCCESS" });

      history.goBack();
    } catch (e) {
      dispatch({ type: "FETCH_ERROR" });

      console.log(e);
    }
  };

  const CancelRow = (props) => {
    const { mystate, dispatch } = useContext(MyContext);

    return (
      <div
        className="rowCancel"
        style={{
          minHeight: 10,
          minWidth: 100,

          display: "flex",
          marginBottom: 10,
        }}
        onClick={() => handleClick(props.id)}
      >
        <div
          className="circle"
          style={{
            display: "flex",
            alignItems: "flex-start",
            paddingRight: 20,
            paddingTop: 4,
          }}
        >
          {cancelReason == props.id ? <CircleSolid color={COLORS.orange} /> : <Circle />}
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
          <div
            style={{
              fontSize: 16,
              color: cancelReason == props.id ? COLORS.orange : COLORS.textDark,
              display: "flex",
            }}
          >
            {props.title}
          </div>
          {props.showHelpText && <div style={{ fontSize: 10, color: COLORS.textMuted }}>{props.help || ""}</div>}
        </div>
      </div>
    );
  };

  const cancelBodyStyle = {
    padding: 10,

    xbackground: "#F4F6F8",
    background: "white",

    margin: 5,
  };

  const blueHeading = {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
  };

  const msg = `${lessonName || "event"} for ${studentName} on ${dateISOtoStringMed(
    lessonToCancel.scheduled_date
  )} at ${timeISOtoString(lessonToCancel.scheduled_starttime)} with ${lessonToCancel.instructor_name}
  `;
  const emailmsg = `${studentName} has cancelled ${lessonName || "event"}  on ${dateISOtoStringMed(
    lessonToCancel.scheduled_date
  )} at ${timeISOtoString(lessonToCancel.scheduled_starttime)} with ${lessonToCancel.instructor_name}.
  `;

  return (
    <Modal show scrollable style={{ height: "100vh" }} onHide={() => history.goBack()} size="md">
      <ModalHeaderBlue closeButton>
        <Modal.Title>{"Cancel Student"}</Modal.Title>
      </ModalHeaderBlue>
      <Modal.Body
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {lessonToCancel && <BlueText style={{ fontSize: 14 }}>{`You are cancelling ${msg}`}</BlueText>}
        {options && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={cancelBodyStyle}>
                <div style={blueHeading} style={{ display: "flex", justifyContent: "space-between" }}>
                  {" "}
                  <BlueText
                    style={{
                      fontWeight: "bold",
                      marginBottom: 5,
                      fontSize: 14,
                    }}
                  >
                    {"Choose Cancel Action:"}
                  </BlueText>
                  <div onClick={() => setShowHelpText(true)}>
                    <QuestionIconNp datatip={"show definitions"} />
                  </div>
                </div>
                {options.map((option) => (
                  <CancelRow
                    key={option.value}
                    id={option.value}
                    title={option.label}
                    help={option.help}
                    showHelpText={showHelpText}
                    default={cancelOption}
                  />
                ))}
              </div>
            </div>

            <div
              className="note"
              style={{
                display: "flex",
                width: 300,
                flexWrap: "wrap",
                alignContentx: "flex-start",
                marginBottomx: 50,
                margin: "auto",
              }}
            >
              <BlockLabelNoMargin>{"add cancellation note (optional)"}</BlockLabelNoMargin>
              <textarea
                style={{
                  display: "flex",
                  flex: "1 1 60%",
                  resize: "none",
                }}
                rows="3"
                onChange={(e) => setNoteInput(e.target.value)}
              />
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <CancelButtonSm onClick={() => history.goBack()}>Cancel</CancelButtonSm>
        <SaveButtonSm onClick={() => saveCancelLesson()}>Save</SaveButtonSm>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelStudentModal;
