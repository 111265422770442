import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, NavLink, useParams } from "react-router-dom";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";

import AuthHelperMethods from "./AuthHelperMethods";
import { MyContext } from "./App";
import { ListContext } from "./App";
import {
  TableHeaderNoAdd,
  OrangeLinkButton,
  OrangeButton,
  ModalHeaderBlue,
  MyTextInput,
  MyTextAreaInput,
  ButtonOrange,
} from "./formComponents";
import { BoxWithLabelW, SaveButton, StyledTr, Td, HelpText } from "./styleCustom";
import { Modal } from "react-bootstrap";
import { useAlert } from "react-alert";
import { errormsg } from "./Constants2";
import COLORS from "./styleConstants";
const Auth = new AuthHelperMethods();

const Tack = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [showModal, setShowModal] = useState(false);
  const [addoredit, setAddorEdit] = useState("add");
  const [initialValues, setInitialValues] = useState({
    tack_name: "",
  });
  const [tackList, setTackList] = useState([]);
  const alert = useAlert();

  const editTack = (tack) => {
    console.log("editLocatiion");
    setAddorEdit("edit");
    setShowModal(true);
    setInitialValues({
      id: tack.id,
      tack_name: tack.tack_name,
      tack_description: tack.tack_description,
    });
  };

  useEffect(
    () => {
      const fetchData = async () => {
        console.log("**************** TACK LIST*****************");
        try {
          const result = await Auth.api("tacks/index", "get");

          setTackList(result.data.tack);
        } catch (error) {
          console.log("error");
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  /* useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        const result = await Auth.api("options/index", "get", params);
        dispatch({
          type: "UPDATE_OPTIONS",
          options: result.data,
        });
      };
      fetchData();
    },
    //eslint-disable-next-line
    [listState.locations]
  );
  */

  const updateActivationStatus = async (is_active, tack_id) => {
    try {
      let params = { is_active: is_active, id: tack_id };
      const result = await Auth.api("tacks/updateActivationStatus", "post", params);
      setTackList(tackList.map((tack) => (tack.id == result.data.tack.id ? result.data.tack : tack)));
    } catch (error) {
      console.log("error");
    }
  };
  return (
    <>
      <Modal show={showModal} size="md" onHide={() => setShowModal(false)}>
        <ModalHeaderBlue closeButton>
          <Modal.Title>{`${addoredit == "edit" ? "Edit" : "Add New"}  Tack`}</Modal.Title>
        </ModalHeaderBlue>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              tack_name: Yup.string().required("required"),
              tack_description: Yup.string(),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              dispatch({ type: "FETCH_INIT" });
              console.log(values);
              let url = addoredit == "edit" ? "tacks/update" : "tacks/create";
              console.log(url);
              try {
                const result = await Auth.api(url, "post", values);
                if (addoredit == "edit") {
                  setTackList(tackList.map((tack) => (tack.id == result.data.tack.id ? result.data.tack : tack)));
                  //   dispatch2(["EDIT_LOCATION", result.data.tack]);
                  alert.show(`Changes to tack ${values.tack_name} saved`, {
                    timeout: 5000,
                    type: "success",
                  });
                } else {
                  setTackList([result.data.tack, ...tackList]);
                  alert.show(`Tack ${values.tack_name} added`, {
                    timeout: 5000,
                    type: "success",
                  });
                }

                dispatch({ type: "FETCH_SUCCESS" });

                setShowModal(false);
                setAddorEdit("add");
                setInitialValues({
                  tack_name: "",
                });
              } catch (e) {
                console.log(e);
                dispatch({ type: "FETCH_ERROR" });

                alert.show(errormsg, { timeout: 5000, type: "error" });
              }
            }}
          >
            <Form>
              <MyTextInput name="tack_name" type="text" placeholder=" " label="Tack Name" />
              <HelpText>required</HelpText>
              <MyTextAreaInput name="tack_description" type="text" placeholder=" " label="Description" />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 40,
                }}
              >
                <ButtonOrange type="submit" label={"Save Tack"} />
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
      <div style={{ display: "flex", flexWrap: "nowrap", flex: "1 0 100%" }}>
        <div style={{ flex: "1 0 100%" }}>
          <TableHeaderNoAdd title={"Tack"}>
            <OrangeButton onClick={() => setShowModal(true)}>{"Add New Tack"} </OrangeButton>
          </TableHeaderNoAdd>
          <table style={{ width: "100%" }}>
            <tbody>
              {tackList &&
                tackList.length > 0 &&
                tackList.map((tack) => (
                  <StyledTr key={tack.id}>
                    <Td>
                      <div style={{ cursor: "pointer" }} onClick={() => editTack(tack)}>
                        {tack.tack_name}
                      </div>
                    </Td>
                    <Td>
                      <div>{tack.tack_description}</div>
                    </Td>

                    <Td>
                      {tack.is_active && (
                        <div
                          style={{ cursor: "pointer", color: COLORS.orange }}
                          onClick={() => updateActivationStatus("0", tack.id)}
                        >
                          {"inactivate"}
                        </div>
                      )}
                    </Td>
                    <Td>
                      {!tack.is_active && (
                        <div
                          style={{ cursor: "pointer", color: COLORS.orange }}
                          onClick={() => updateActivationStatus("1", tack.id)}
                        >
                          {"activate"}
                        </div>
                      )}
                    </Td>
                  </StyledTr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Tack;
