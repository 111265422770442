import React, { Fragment, useState, useContext } from "react";
import ReactDom from "react-dom";
import moment from "moment";
import Settings from "./UserSettings";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import RepeatingDates from "./repeatingDates";

import { MyContext } from "./App";

import { DATEPICKER_DATE_DISPLAY } from "./UserSettings";
import { Row } from "react-bootstrap";

const DateOnly = props => {
  const { mystate, dispatch } = useContext(MyContext);

  const start = mystate.currentLesson.start;

  const handleDateChange = e => {
    dispatch({ type: "SET_DATE_COMPONENT", dateOnly: e });
  };

  return (
    <>
      <Row noGutters>
        <DatePicker
          selected={mystate.dateOnly}
          dateFormat={DATEPICKER_DATE_DISPLAY}
          onChange={e => handleDateChange(e)}
        />
      </Row>
    </>
  );
};

export default DateOnly;
