import React, { useReducer, Fragment, useState, useEffect, useContext } from "react";
import axios from "axios";
import Select from "react-select";
import { MyContext } from "./App";
import { valueFromId } from "./momentUtils";
import { colourStyles } from "./styleCustom";
import AuthHelperMethods from "./AuthHelperMethods";
const Auth = new AuthHelperMethods();

const SelectProduct = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  //console.log(props);
  //console.log("SelectLocation props");
  useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        const result = await Auth.api("products/product_options", "get", params);
        dispatch({
          type: "UPDATE_PRODUCT_OPTIONS",
          options: result.data.product_options,
        });
      };
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  const handleLocationChangeAdd = (event) => {
    dispatch({
      type: "SET_NEW_EVENT",
      data: { product_id: event.value },
    });
  };

  const handleLocationChangeEdit = (event) => {
    let d = valueFromId(mystate.options_product, event.value);
    dispatch({
      type: "EDIT_CURRENT_LOCATION",
      data: d,
    });
  };

  return (
    <div>
      {mystate.options_product && (
        <Select
          onChange={props.addOrEdit == "edit" ? handleLocationChangeEdit : handleLocationChangeAdd}
          className="form-control"
          value={mystate.options_product.filter(
            (option) =>
              option.value ==
              (props.addOrEdit == "edit" ? mystate.currentLesson.location_id : mystate.addevent.product_id)
          )}
          options={mystate.options_product}
          clearable={true}
          searchable={false}
          placeholder={"Select Package..."}
          styles={props.styles || colourStyles}
        />
      )}
    </div>
  );
};

export default SelectProduct;
