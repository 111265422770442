import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { MyContext, ListContext } from "./App";
import { ButtonBlue, ButtonOrange } from "./formComponents";
import { assignStyles } from "./styleCustom";
import { EditIconPlain } from "./icons";
import Select from "react-select";
import AuthHelperMethods from "./AuthHelperMethods";
import { Modal } from "react-bootstrap";
import { HorseAvatar, StaffAvatar, ClientAvatar } from "./images";
import COLORS from "./styleConstants";
const Auth = new AuthHelperMethods();

export const XrefSelect = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [currentValue, setCurrentValue] = useState([]);
  const [currentValueArray, setCurrentValueArray] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);
  const [showSelect, setShowSelect] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [newNames, setNewNames] = useState([]);

  const whystyle = {
    maxWidth: 800,
    margin: "0 auto",
    columnWidth: 300,
    columnGap: 50,
    columnRule: "1px solid grey",
  };

  useEffect(
    () => {
      console.log("tell me when xref runs");
      let isMounted = true;
      const fetchData = async () => {
        let params = { single_id: props.single };
        const result2 = await Auth.api(props.get, "get", params);
        if (result2.data && isMounted) setCurrentValue(result2.data.currentValue);
        setCurrentValueArray(result2.data.currentValue.map((me) => me.value));
        console.log(currentValue);
      };
      fetchData();
      return () => {
        isMounted = false;
      };
    },
    //eslint-disable-next-line
    [showSelect, listState.currentClient.id] //todo this is causing problems - instructor_id undefined - took currentStaff.instructor_id out
  );

  const onFinish = async () => {
    console.log(props.single);
    let params = {
      // array: currentValue.map((value) => value.value),
      array: currentValueArray,
      single_id: props.single,
    };
    let result2 = await Auth.api(props.post, "post", params);
    setShowSelect(false);
  };

  /*const handleOnclick = (option, on) => {
    console.log(option);
    console.log(on);
    if (on) setCurrentValueArray(...currentValue, option.value);
    if (!on) setCurrentValueArray(currentValue.filter((me) => me != option.value));

    // setCurrentValueArray(option.value);
  };
  */

  const handleClickEach = (option) => {
    let id = option.value;
    currentValueArray.includes(id)
      ? setCurrentValueArray(currentValueArray.filter((me) => me != id))
      : setCurrentValueArray([...currentValueArray, id]);
    currentValueArray.includes(option.label)
      ? setNewNames(currentValueArray.filter((me) => me != option.label))
      : setNewNames([...currentValueArray, option.label]);

    // setSearchTerm("");

    // setNewNames(`${newNames} ${option.label}`);
  };
  const searchForName = (e) => {
    setSearchTerm(e.target.value);
    // .filter((horse) => horse.person_name.toLowerCase().includes(search.toLowerCase()))

    //props.options.filter((option) => option.label.toLowerCase().includes(e.target.value));
  };
  return (
    <>
      {showSelect && (
        <>
          <Modal show={true} size={"lg"} scrollable onHide={() => setShowSelect(false)}>
            <Modal.Header>
              <Modal.Title>{props.label}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ display: "flex", flexDirection: "column", flex: "1 1 100" }}>
                {1 == 2 && <div>{newNames.map((name) => name)}</div>}
                <input placeholder="search by name" value={searchTerm} onChange={(e) => searchForName(e)} />
                {props.options
                  .filter((option) => option.label.toLowerCase().includes(searchTerm))
                  .map((option) => (
                    <div
                      key={option.value}
                      onClick={() => handleClickEach(option)}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                    >
                      <div style={dot(currentValueArray.includes(option.value))} />{" "}
                      <div style={{ fontSize: 16, color: currentValueArray.includes(option.value) && COLORS.orange }}>
                        {option.label}
                      </div>
                    </div>
                  ))}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div style={{ display: "flex", flex: "1 1 100%", justifyContent: "space-around" }}>
                <ButtonBlue onClick={() => setShowSelect(false)} label={"Cancel"} />
                <ButtonOrange onClick={() => onFinish()} label={"Finished"} />
              </div>
            </Modal.Footer>
          </Modal>
        </>
      )}
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <div
          style={{
            display: "flex",
            flex: "1 1 100%",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          {" "}
          {Auth.getUserType() == "STU" && currentValue.length > 0 && (
            <div style={{ fontSize: 20, marginTop: 10, marginRight: 20 }}>{props.label || ""}</div>
          )}
          {Auth.getUserType() == "ADM" && (
            <div style={{ fontSize: 20, marginTop: 10, marginRight: 20 }}>{props.label || ""}</div>
          )}
          {Auth.getUserType() == "ADM" && (
            <div
              onClick={() => setShowSelect(true)}
              style={{ color: COLORS.orange, paddingBottom: 3, cursor: "pointer" }}
            >
              {"click to edit"}
            </div>
          )}
        </div>
      </div>
      {currentValue.map((each) => (
        <>
          {!props.nametag && <div key={each.value}>{each.label}</div>}
          {props.nametag && (
            <NameTag
              currentobj={each}
              name={each.label}
              value={each.value}
              horse={props.horse}
              staff={props.staff}
              student={props.student}
              section={props.section}
              sectionadd={props.sectionadd}
            />
          )}
        </>
      ))}
    </>
  );
};

const NameTag = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);

  return (
    <div
      style={{
        border: "solid 2pt lightgrey",
        borderRadius: 10,
        padding: 5,
        maxWidth: isMobile ? 300 : 400,
        maxHeight: 60,
        fontSize: 20,
        fontStyle: "bold",
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "row",
        flexx: "1 1 auto",
        alignItems: "center",
        marginBottom: 5,
        background: "white",
      }}
    >
      <div>
        {props.horse && <HorseAvatar width={"50px"} />}
        {props.staff && <StaffAvatar width={"50px"} />}
        {props.student && <ClientAvatar width={"50px"} />}
        {props.section && <ClientAvatar width={"50px"} />}
        {props.sectionadd && <ClientAvatar width={"50px"} />}
      </div>
      <div>
        {props.horse && <Link to={`/admin/horsehome/${props.value}/details`}>{props.name}</Link>}
        {props.staff && <div>{props.name}</div>}
        {props.student && <Link to={`/admin/clienthome/${props.currentobj.person_id}/details`}>{props.name}</Link>}
        {props.section && <div>{props.name}</div>}
        {props.sectionadd && <div>{props.name}</div>}
      </div>
    </div>
  );
};

const dot = (isSelected) => ({
  alignItems: "center",
  display: "flex",

  backgroundColor: isSelected ? COLORS.orange : "white",
  content: '""',
  marginRight: 8,
  height: 10,
  width: 10,
});
