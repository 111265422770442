import React, { useReducer, useState, useEffect } from "react";
import { formatFromDB } from "./momentUtils";
import initialStateList from "./initialStateList";
import { filter, stubTrue } from "lodash";

const listPageReducer = (state, action) => {
  let data = action[1];
  var dateheading;
  var subtotal, fee, grandtotal, tax, subtotalwithtax;
  switch (action[0]) {
    //********************* GENERIC ***********************//

    case "SET_MAIN_LIST":
      return { ...state, mainList: data };

    case "SET_CURRENT_LIST_DETAIL":
      return { ...state, currentListDetail: data };
    //************************ SELECT *******************************//
    case "SELECT_STUDENT":
      return { ...state, selectStudent: data, selectStudentName: action[2] };
    //  return state;
    //************************ ADMIN *******************************//

    case "GET_ADMIN_CLIENT_NOTES":
      return { ...state, adminClientNotes: data.data };

    case "GET_ADMIN_HORSE_NOTES":
      return { ...state, adminHorseNotes: data.data };

    case "GET_INSTRUCTOR_OPTIONS":
      return { ...state, options_instructor: data.instructors };

    //************************ FORMS *******************************//

    case "SET_FORM_INITIAL_VALUES":
      console.log(data);
      console.log(action);
      return { ...state, formInitialValues: data, formAction: action[2] };

    case "UPDATE_FORM_INITIAL_VALUES":
      let formheading = action[2];
      return {
        ...state,
        formInitialValues: {
          ...state.formInitialValues,
          [formheading]: data,
        },
      };

    case "SET_FORM_ACTION":
      return { ...state, formAction: data }; //leaving until have its replaced

    case "SHOW_ADD_EDIT_FORM":
      return { ...state, showAddEditForm: true, formAction: data };

    case "CLOSE_ADD_EDIT_FORM":
      return { ...state, showAddEditForm: false };

    case "SHOW_FORM_ASSIGN_TO_LISTS":
      return { ...state, showAssignToLists: data };

    //************************ FILTERS *******************************//

    case "INITIALIZE_SEARCH_FILTER":
      console.log("INITIALIZE_SEARCH_FILTER");

      return { ...state, filter: { current: data, dbday: action[2] } };

    case "SET_SEARCH_FILTER":
      console.log(action);
      console.log("SET_SEARCH_FILTER");
      console.log(data);
      console.log(state.filter);

      let heading = action[2];

      return {
        ...state,
        filter: { ...state.filter, [heading]: data },
      };

    case "SET_SEARCH_FILTER_OBJECT":
      console.log(data);
      let label = {};
      if (data.student) label.student = [data.studentlabel];
      return {
        ...state,
        filter: data,
        filter_labels: label,
      };

    case "SET_SEARCH_FILTER_AND_LABELS":
      console.log(action);
      console.log("SET_SEARCH_FILTER_LABELS");
      console.log(data);
      heading = action[2];

      let labels = state.options_section.map((option) => (data.includes(option.value) ? option.value : null));

      return {
        ...state,
        filter: { ...state.filter, [heading]: data },
        filter_labels: { ...state.filter_labels, [heading]: labels },
      };

    case "SET_SEARCH_FILTER_BUTTON":
      console.log("SET_SEARCH_FILTER_BUTTON");

      // heading = action[2];
      console.log(action);
      let currentValueArray = state.filter[action[2]] || [];
      currentValueArray.includes(data)
        ? (currentValueArray = currentValueArray.filter((me) => me != data))
        : (currentValueArray = [...currentValueArray, data]);

      return {
        ...state,
        filter: { ...state.filter, [action[2]]: currentValueArray },
      };

    case "TOGGLE_SHOW_HIDE_FILTER":
      return { ...state, showfilter: !state.showfilter };
    case "SET_SHOW_FILTER":
      return { ...state, showfilter: data };

    case "TOGGLE_SHOW_CLIENT_OR_ALL":
      return { ...state, showClientOrAll: !state.showClientOrAll };
    case "SET_SHOW_CLIENT_OR_ALL":
      return { ...state, showClientOrAll: data };

    case "TOGGLE_SHOW_STUDENT_EVENT_NAME":
      return { ...state, showByStudentName: !state.showByStudentName };

    case "SET_SEARCH_FILTER_TOGGLE":
      //slet heading = action[2];
      console.log("SET_SEARCH_FILTER_TOGGLE");

      var newlrables;
      console.log(action);
      console.log(action[2]);
      let newfilter = { ...state.filter };
      console.log(data);
      let head = action[2];
      if (data == false) {
        console.log("this is false");

        delete state.filter[head];
        delete state.filter_labels[head];
        console.log(state.filter_labels);
      } else {
        state.filter = { ...state.filter, [action[2]]: 1 };
        let newlrables = { ...state.filter_labels, [action[2]]: [action[2]] };
        console.log(newlrables);
      }
      return { ...state, filter: state.filter, labels: newlrables };

    case "SET_SEARCH_FILTER_HORSE":
      return { ...state, filter: { ...state.filter, horse: data } };

    case "SET_SEARCH_FILTER_REPORTS":
      console.log(action);
      console.log(data);
      console.log("SET_SEARCH_FILTER_REPORTS");
      let newobj = { ...state.filter };
      let newobjlabel = { ...state.filter_labels };
      console.log(newobjlabel);

      let headingr = action[2];
      let filterLabel = action[2];
      if (data.length == 0) {
        delete newobj[headingr];
        delete newobjlabel[headingr];

        console.log(newobj);
        console.log(newobjlabel);
      } else {
        data.map((item) => item.value);
        newobj = { ...newobj, [headingr]: data.map((item) => item.value) };
        newobjlabel = {
          ...newobjlabel,
          [filterLabel]: data.map((item) => (item ? item.label : "")),
        };
        console.log(newobj);
      }

      return {
        ...state,
        filter: { ...newobj },
        filter_labels: {
          ...newobjlabel,
        },
      };

    case "SET_COPY_TO_SEARCH_FILTER":
      return {
        ...state,
        filter: data,
        filter_labels: action[2],
      };

    case "SET_COPY_FILTER_TO_CALENDAR":
      return {
        ...state,
        filtercal: state.filter,
        filter_labelscal: state.filter_labels,
      };

    case "SET_SEARCH_FILTER_CALENDAR":
      /* console.log(action);
      console.log("SET_SEARCH_FILTER_REPORTS_CALENDAR");
      let newobjcal = { ...state.filtercal };
      let newobjlabelcal = { ...state.filter_labelscal };
      console.log(newobjlabelcal);

      let headingcal = action[2];
      let filterLabelcal = action[2];
      if (!data || data.length == 0) {
        delete newobjcal[headingcal];
        delete newobjlabelcal[headingcal];

        console.log(newobjcal);
        console.log(newobjlabelcal);
      } else {
        data.map((item) => item.value);
        newobjcal = { ...newobjcal, [headingcal]: data.map((item) => item.value) };
        newobjlabelcal = {
          ...newobjlabelcal,
          [filterLabelcal]: data.map((item) => (item ? item.label : "")),
        };
        console.log(newobjcal);
      }

      return {
        ...state,
        filtercal: { ...newobjcal },
        filter_labelscal: {
          ...newobjlabelcal,
        },
      };*/

      return {
        ...state,
        filtercal: state.filter,
        filter_labelscal: state.filter_labels,
      };

    case "SET_SEARCH_FILTER_RADIO":
      if (data == 2) {
        let filterhere = state.filter;
        delete filterhere[action[2]];
        return { ...state, filter: { ...filterhere, changeFilter: !state.filter.chageFilter } };
      }

      return { ...state, filter: { ...state.filter, [action[2]]: data } };

    case "MERGE_FILTERS": //merge regular filter and advanced filter on save in advanced filter - for useEffect[listState.filter]
      return {
        ...state,
        filter: { ...state.filter, ...state.advanced_filter },
      };

    case "SET_SEARCH_FILTER_DATES": //useing separate reducer to avoid setting filter_optione N/A with dates
      console.log("SET_SEARCH_FILTER_DATES");
      console.log(action);
      console.log(state.filter);

      let newFilter = {};
      if (state.filter) {
        newFilter = {
          ...state.filter,
          [action[2]]: data,
          current: action[3] || state.filter.current,
        };
      } else {
        newFilter = {
          [action[2]]: data,
          current: action[3] || state.filter.current,
        };
      }

      return {
        ...state,
        filter: newFilter,
      };
    case "SET_SEARCH_FILTER_DATES_NEW": //useing separate reducer to avoid setting filter_optione N/A with dates
      console.log("SET_SEARCH_FILTER_DATES_NEW REDUCER");
      console.log(action);
      let dateheading2 = action[2];
      return {
        ...state,
        filter: { ...state.filter, [dateheading2]: data },
      };

    case "SET_BOTH_SEARCH_FILTER_DATES":
      console.log("SET_BOTH_SEARCH_FILTER_DATES");
      console.log(action);
      console.log(state, filter);
      return {
        ...state,
        filter: { ...state.filter, date1: data, date2: action[2], page: action[3] },
      };

    case "DELETE_FILTER_KEY":
      console.log(data);
      // console.log(state.filter);

      // delete state.filter["expiry1"];
      // delete state.filter.data;
      console.log(state.filter);
      let myfilter = state.filter;
      delete myfilter[data];
      //delete myfilter.date1;
      // delete myfilter.date2;
      console.log(myfilter);

      return { ...state, filter: myfilter };

    case "SET_MOUNTED":
      return {
        ...state,
        mounted: data,
      };

    case "RESET_SEARCH_FILTER":
      console.log("SETTING RESET &&&&&&&&&&&&&& in dispatch RESET_SEARCH_FILTER FROM");
      console.log(data);
      /* let newseststate = {
        ...state,
        filter: { old: data },
      };*/
      return {
        ...state,
        filter: {},
        filter_labels: {},
      };

    case "RESET_SEARCH_FILTER_EMPTY":
      console.log("RESET_SEARCH_FILTER_EMPTY");

      return {
        ...state,
        filter: {},
        filter_labels: {},
      };

    case "RESET_SEARCH_FILTER_CALENDAR_EMPTY":
      return {
        ...state,
        filtercal: {},
        filter_labelscal: {},
      };

    case "RESET_SEARCH_FILTER_EXCEPT_DATES":
      //* ADDED PAGE
      return {
        ...state,
        filter: {
          date1: state.filter.date1,
          date2: state.filter.date2,
          page: state.filter.page,
          lesson_is_cancelled: 0,
          view: state.filter.view,
        },
        filter_labels: {},
      };

    case "RESET_SEARCH_FILTER_FOR_CLIENT_BOOKING_KEEP_AVAILABLE":
      //* normal reset was taking out available and currentStudentId
      return {
        ...state,
        filter: {
          date1: state.filter.date1,
          date2: state.filter.date2,
          page: state.filter.page,
          lesson_is_cancelled: 0,
          view: state.filter.view,
          available: "available",
          currentStudentId: state.filter.currentStudentId,
        },
        filter_labels: {},
      };

    case "SET_SEARCH_BY_TEXT_INPUT":
      return {
        ...state,
        searchByText: data,
      };

    //********************* USER TYPE CLIENTS ***********************//
    case "USER_CLIENTS_GET":
      console.log(action);
      return {
        ...state,
        clients: data.clients,
        currentClient: data.clients.filter((client) => (client.is_billing_person = 1)),
        //    filteredclients: data.clients
      };

    case "CLIENT_UPDATE_SINGLE_MEMBER":
      return {
        ...state,
        members: state.members.map((member) => (member.id == data.id ? data : member)),
        accountHolder: state.accountHolder.id == data.id ? data : state.accountHolder,
        clients: state.clients.map((client) => (client.id == data.id ? data : client)),
      };

    case "CLIENT_UPDATE_MEMBERS":
      return {
        ...state,
        members: data,
      };

    case "CLIENT_UPDATE_ACCOUNT_HOLDER":
      return {
        ...state,
        accountHolder: data,
      };

    case "UPDATE_ACCOUNT_HOLDER_ROLE":
      return {
        ...state,
        accountHolder: { ...state.accountHolder, role_id: data },
      };

    case "CLIENT_GET_ACCOUNT":
      return {
        ...state,
        members: data.members,
        accountHolder: data.accountHolder,
      };

    //********************* CLIENT HOMEPAGE ***********************//

    case "SET_CURRENT_CLIENT_ID":
      return {
        ...state,
        absoluteCurrentClientId: data,
      };
    case "CLIENT_DATA":
      console.log("SETTTING  CLIENT_DATA");
      // console.log(data);

      console.log(action);

      return {
        ...state,

        clientdata: data.data,
      };

    case "SET_CLIENT_HOMEPAGE_CURRENT_LESSON":
      return {
        ...state,
        clientHomepageLesson: data,
      };

    case "UPDATE_LESSON_CANCEL":
      console.log("UPDATE_LESSON_CANCEL");

      console.log(data);
      console.log(state.clientdata[0]);
      //  let newinfo = data.lessonPerson;
      return {
        ...state,
        clientdata: state.clientdata.map((lesson) =>
          lesson.id == data.id
            ? {
                ...data,
                scheduled_date: lesson.scheduled_date,
                scheduled_starttime: lesson.scheduled_starttime,
                instructor_name: lesson.instructor_name,
              }
            : lesson
        ),
      };

    case "STUDENT_DETAIL_SET":
      return {
        ...state,
        currentStudentDetail: data,
      };

    //********************* CLIENTS ***********************//
    case "CLIENTS_GET":
      //  console.log(data.clients);
      return {
        ...state,
        clients: data.clients,
        //    filteredclients: data.clients
      };

    case "ACCOUNT_CLIENTS_GET":
      //  console.log(data.clients);
      return {
        ...state,
        accountClients: data.clients,
        //    filteredclients: data.clients
      };

    case "ACCOUNT_STUDENTS_GET":
      //  console.log(data.clients);
      return {
        ...state,
        accountStudents: data.clients,
        //    filteredclients: data.clients
      };

    //**************** ADD DEPENDENTS FORM ****************//

    case "ADD_NEW_DEPENDANT":
      return {
        ...state,
        clients: [data, ...state.clients],
        members: [data, ...state.members],
      };

    case "CLIENTS_UPDATE_NEW_DEPENDENT":
      return {
        ...state,
        clients: [data, ...state.clients],
      };

    case "GET_DAYSHEET_CLIENTS":
      return {
        ...state,
        daysheetClients: data,
        //    filteredclients: data.clients
      };
    case "CLIENT_GET":
      console.log(action);
      return {
        ...state,

        currentClient: data,
      };

    case "CHANGE_CURRENT_MEMBER":
      console.log(data);
      let newcurrentClientObject = state.members.find((member) => member.id == data);
      console.log(newcurrentClientObject);

      return {
        ...state,
        filter: {
          ...state.filter,
          currentStudentId: [newcurrentClientObject?.student_id],
        },
        currentClient: newcurrentClientObject,
      };

    case "CLIENT_NEW_ACCOUNT":
      return {
        ...state,

        accountHolder: { ...state.accountHolder, user_id: data.user_id },
      };

    //****************  ONLINE PURCHASE ****************//

    case "SET_PAYMENT_INTENT":
      return {
        ...state,
        paymentIntent: data,
      };
    case "SET_PAYMENT_METHODS":
      return {
        ...state,
        paymentMethodList: data,
      };

    case "SET_CLIENT_PAYMENT":
      console.log("SET_CLIENT_PAYMENT");
      console.log(data);

      return {
        ...state,
        clientPayment: data,
      };

    case "SET_INVOICE_PAYMENT":
      return {
        ...state,
        currentInvoicePayment: data,
      };

    case "SET_CURRENT_PRODUCT":
      // console.log(action);
      //  console.log(state.currentProduct);
      return { ...state, currentProduct: data };

    case "EDIT_CART":
      let stateObject = state.cartProducts.filter((item) => item.counter == data.counter)[0];

      subtotal =
        parseFloat(state.cartSubtotal) - parseFloat(stateObject.product_price) + parseFloat(data.product_price);
      tax = parseFloat(subtotal * parseFloat(data.tax_percent));
      subtotalwithtax = parseFloat(subtotal) + parseFloat(tax);

      let newCartProducts = state.cartProducts.map((item) => (item.counter == data.counter ? data : item));

      fee = parseFloat(subtotal * parseFloat(data.fee));
      grandtotal = parseFloat(subtotal) + parseFloat(fee);

      return {
        ...state,
        cartProducts: newCartProducts,
        cartSubtotal: subtotal.toFixed(2),
        cartTax: tax.toFixed(2),
        cartSubtotalWithTax: subtotalwithtax.toFixed(2),
        cartFee: fee.toFixed(2),
        cartTotal: grandtotal.toFixed(2),
      };

    case "ADD_TO_CART":
      delete data.name;
      // data.uuid = state.cartProducts && state.cartProducts.length > 0 ? state.cartProducts.slice(-1).uuid + 1 : 1;
      data.counter = Date.now();
      console.log("DATA COMING IN TO CART");
      subtotal = parseFloat(state.cartSubtotal) + parseFloat(data.product_price);
      tax = parseFloat(subtotal * parseFloat(data.tax_percent));
      subtotalwithtax = parseFloat(subtotal) + parseFloat(tax);
      fee = parseFloat(subtotalwithtax * parseFloat(data.fee));
      grandtotal = parseFloat(subtotalwithtax) + parseFloat(fee);

      return {
        ...state,
        cartProducts: [...state.cartProducts, data],
        cartSubtotal: subtotal.toFixed(2),
        cartTax: tax.toFixed(2),
        cartSubtotalWithTax: subtotalwithtax.toFixed(2),
        cartFee: fee.toFixed(2),
        cartTotal: grandtotal.toFixed(2),
      };

    case "ADD_CART_FEE_TO_INVOICE_PAYMENT":
      var subtotal = action[1];

      var fee = action[2];
      var total = action[3];
      return {
        ...state,
        cartSubtotal: subtotal,
        cartSubtotalWithTax: subtotal, //todo handle invoice payment w tax
        cartFee: fee.toFixed(2),
        cartTotal: total.toFixed(2),
      };

    case "ADD_CART_FEE":
      console.log(data);

      fee = parseFloat(state.cartSubtotal) * parseFloat(data);
      console.log(fee);

      grandtotal = parseFloat(state.cartSubtotal) + fee;
      console.log(grandtotal);
      console.log(grandtotal.toFixed(2));

      return {
        ...state,
        cartFee: fee.toFixed(2),
        cartTotal: grandtotal.toFixed(2),
      };

    case "REMOVE_CART_FEE":
      return {
        ...state,
        cartTotal: state.cartSubtotalWithTax,
        cartFee: 0,
        fee: 0,
      };

    case "REMOVE_FROM_CART":
      let stripe_convenience_fee = action[2];
      subtotal = parseFloat(state.cartSubtotal) - parseFloat(data.product_price);
      tax = parseFloat(subtotal * parseFloat(data.tax_percent));
      subtotalwithtax = parseFloat(subtotal) + parseFloat(tax);
      fee = parseFloat(subtotalwithtax * parseFloat(data.fee));
      grandtotal = parseFloat(subtotalwithtax) + parseFloat(fee);
      console.log(subtotal);
      console.log(fee);
      console.log(grandtotal);

      return {
        ...state,
        cartProducts: state.cartProducts.filter((product) => product.counter != data.counter),
        cartSubtotal: subtotal.toFixed(2),
        cartFee: fee.toFixed(2),
        cartTotal: grandtotal.toFixed(2),
      };

    case "SET_CART_TOTAL":
      return {
        ...state,
        cartTotal: data,
      };

    case "CLEAR_CART":
      return {
        ...state,
        cartProducts: [],
        cartSubtotal: 0,
        cartFee: 0,
        cartTotal: 0,
        currentProduct: {},
        cartSubtotalWithTax: 0,
      };

    case "CLIENT_CHECKOUT":
      return {
        ...state,
        checkout: data,
        subtotal: action[3],
        fee: action[2],
      };

    case "SET_QUICKPAY_STUDENT_ID":
      console.log("SET_QUICKPAY_STUDENT_ID");
      return {
        ...state,
        quickpaystudentid: data,
      };

    //****************  NEW CLIENT FORM ****************//
    case "UPDATE_CLIENT_NOTE_PUBLIC":
      return {
        ...state,
        clientdata: state.clientdata.map((note) => (note.id == data ? { ...note, is_public: !note.is_public } : note)),
      };

    case "UPDATE_CLIENT_NOTE_PIN":
      return {
        ...state,
        clientdata: state.clientdata.map((note) => (note.id == data ? { ...note, pin: !note.pin } : note)),
      };

    case "UPDATE_CLIENT_INFO":
      return {
        ...state,
        clients: state.clients.map((client) => (client.id == data.id ? data : client)),
      };

    case "RETURN_NEW_CLIENT":
      return {
        ...state,
        clients: [data, ...state.clients],
      };

    case "SET_NEW_CLIENT_IDS":
      console.log(action);
      //  let newClientObj = data.newClient[0];
      return {
        ...state,
        newClientIds: data.newClient,
      };

    case "SET_CLIENT_PROFILE":
      return { ...state, clientProfile: data };

    case "SET_CLIENT_QUICKLOOK":
      return { ...state, quicklook: data };

    //************* STAFF PAGE *******************//

    case "STAFF_GET":
      return {
        ...state,
        staff: data.staff,
      };

    case "STAFF_NEW":
      return {
        ...state,
        staff: [data.staff, ...state.staff],
        //options_instructor: data.options,
      };

    case "STAFF_GET_ONE":
      return {
        ...state,
        currentStaff: data,
      };

    case "LOGGED_IN_STAFF":
      return {
        ...state,
        loggedInStaff: data,
      };

    case "STAFF_EDIT":
      if (state.staff) {
        let f = state.staff.map((el) => (el.id === data.staff.id ? data.staff : el));
        return { ...state, staff: f, currentStaff: data.staff };
      } else return state;

    case "UPDATE_STAFF_COLOR":
      if (state.staff) {
        let f = state.staff.map((el) => (el.id === data.id ? data : el));
        return { ...state, staff: f, currentStaff: data };
      } else return state;

    case "UPDATE_STAFF_COLORS":
      console.log(data);
      return { ...state, currentStaff: { ...state.currentStaff, color_code: data.color, color_id: data.color_id } };

    //************* HORSES PAGE *******************//
    case "HORSES_GET":
      return {
        ...state,
        horses: data.horses,
        horseList: data.horses.map((horse) => ({
          id: horse.id,
          name: horse.horse_name,
        })),
      };

    case "HORSE_GET":
      console.log(action);
      console.log(data);

      return {
        ...state,
        currentHorse: data,
      };

    case "HORSE_DATA":
      console.log(action);

      return {
        ...state,

        horsedata: data,
      };

    case "HORSE_CREATE":
      return {
        ...state,
        horses: [data.horse, ...state.horses],
        currentHorse: data.horse,
      };

    case "HORSE_EDIT":
      return {
        ...state,
        horses: state.horses.map((el) => (el.id === data.horse.id ? data.horse : el)),
        currentHorse: data.horse,
      };

    //********************* PROGRAMS ***********************//

    case "GET_PROGRAM_LIST":
      console.log("GET_PROGRAM_LIST");
      console.log(action);
      return {
        ...state,
        programList: data.programList,
      };

    case "GET_PROGRAMS":
      return {
        ...state,
        programs: data.programs,
      };

    case "ADD_PROGRAM":
      return {
        ...state,
        programList: data.concat(state.programList),
      };

    case "EDIT_PROGRAM":
      return {
        ...state,
        programList: state.programList.map((item) => (item.id == data.id ? data : item)),
      };

    /*case "PROGRAM_CREATE":
      return {
        ...state,
        programs: [data.programs, ...state.programs],
      };
      */

    /*case "PROGRAM_EDIT":
      console.log(action);
      console.log(data.program.id);

      return {
        ...state,
        programs: state.programs.map((item) =>
          item.id === data.program.id ? data.program : item
        ),
      };*/

    //********************* SECTIONS ***********************//

    case "GET_SECTION_LIST":
      let sectionList = data.sectionList.map((section) => ({ ...section, location_id: parseInt(section.location_id) }));
      return {
        ...state,
        sectionList: sectionList,
      };

    case "SECTION_CREATE":
      return {
        ...state,
        sectionList: [data.section, ...state.sectionList],
      };

    case "SECTION_EDIT":
      return {
        ...state,
        sectionList: state.sectionList.map((item) => (item.id == data.section.id ? data.section : item)),
      };
    //********************* SECTION HORSES ***********************//

    case "SECTION_HORSES_GET":
      console.log(data);
      console.log(data.map((horse) => ({ ...horse, id: horse.horse_id })));

      return {
        ...state,
        sectionHorses: data.map((horse) => ({ ...horse, id: horse.horse_id })),
      };

    case "CLEAR_SECTION_HORSES":
      return { ...state, sectionHorses: [] };

    case "SECTION_HORSES_CREATE":
      console.log(data);
      return {
        ...state,
        sectionHorses: [data, ...state.sectionHorses],
      };

    case "SECTION_HORSES_DELETE":
      console.log(data);
      console.log(state.sectionHorses);
      return {
        ...state,
        sectionHorses: state.sectionHorses.filter((item) => item.id !== data),
      };

    //********************* SECTION STUDENTS***********************//
    case "SECTION_STUDENTS_GET":
      return {
        ...state,
        sectionStudents: data.map((student) => ({
          ...student,
          id: student.student_id,
          name: student.person_name,
        })),
      };

    case "SECTION_STUDENTS_CREATE":
      console.log(data);
      return {
        ...state,
        sectionStudents: [data, ...state.sectionStudents],
      };

    case "SECTION_STUDENTS_DELETE":
      console.log(data);
      console.log(state.sectionStudents);
      return {
        ...state,
        sectionStudents: state.sectionStudents.filter((item) => item.id !== data),
      };

    case "STUDENTS_GET":
      return {
        ...state,
        studentList: data.students.map((student) => ({
          id: student.id,
          name: student.person_name,
        })),
      };

    //********************* LOCATIONS ***********************//
    case "GET_LOCATION_LIST":
      return { ...state, locations: data };

    case "ADD_LOCATION":
      console.log(action);
      return {
        ...state,
        locations: data.concat(state.locations),
      };

    case "EDIT_LOCATION":
      console.log(action);
      return {
        ...state,
        locations: state.locations.map((item) => (item.id == data.id ? data : item)),
      };

    //********************* PRODUCT ***********************//
    case "PRODUCTS_GET":
      return {
        ...state,
        products: data.products,
      };
    case "PRODUCT_CREATE":
      return { ...state, products: [data.products, ...state.products] };
    case "PRODUCT_EDIT":
      // after update product item from api,  replace selected item in list.  Stirng of product program names is in state.product -> when mapping it, when current edit item is found, add pp stringname (since that isn't returned from api.   Also add recent flag to highlight saved item that has jsut changed in list.)
      return {
        ...state,
        products: state.products.map((product) => (product.id === data.product.id ? data.product : product)),
      };

    case "PRODUCT_DELETE":
      return state;

    //********************* PRODUCT_PROGRAMS ***********************//

    case "PRODUCT_PROGRAMS_GET":
      return {
        ...state,
        productPrograms: data.productPrograms,
      };

    case "PRODUCT_PROGRAM_GET":
      return { ...state, currentProgramsForProduct: data.productPrograms };

    case "PRODUCT_PROGRAM_CREATE":
      console.log(state.productPrograms);
      return {
        ...state,
        productPrograms: data.newProductProgram.concat(state.productPrograms),
      };

    case "PRODUCT_PROGRAM_DELETE":
      return {
        ...state,
        productPrograms: state.productPrograms.filter((item) => item.id !== data),
      };

    //********************* ROLES ***********************//
    case "ROLES_GET":
      console.log(action);
      console.log(data.roles);
      return {
        ...state,
        roles: data.roles,
        options_roles: data.roles.map((role) => ({
          value: role.id,
          label: role.role_name,
        })),
      };
    case "ROLE_CREATE":
      return { ...state, roles: [data.roles, ...state.roles] };
    case "ROLE_EDIT":
      // after update role item from api,  replace selected item in list.  Stirng of role program names is in state.role -> when mapping it, when current edit item is found, add pp stringname (since that isn't returned from api.   Also add recent flag to highlight saved item that has jsut changed in list.)
      return {
        ...state,
        roles: state.roles.map((role) => (role.id === data.role.id ? data.role : role)),
      };

    case "ROLE_DELETE":
      return state;
    //********************* USER PERMISSIONS ***********************//
    case "PERMISSIONS_GET":
      console.log(action);
      console.log(data.permissions);

      return {
        ...state,
        permissionList: data.permissions,
        options_permission: data.permissions.map((item) => ({
          value: item.id,
          label: item.permission_name,
        })),
      };
    //********************* ROLE_PERMISSIONS ***********************//
    case "ROLE_PERMISSIONS_GET":
      return {
        ...state,
        rolePermissions: data.rolePermissions,
      };

    case "ROLE_PERMISSION_CREATE":
      return {
        ...state,
        rolePermissions: [...state.rolePermissions, data.newRolePermission[0]],
      };

    case "ROLE_PERMISSION_DELETE":
      let f = state.rolePermissions.find(
        (item) => item.role_id == data.role_id && item.permission_id == data.permission_id
      );

      return {
        ...state,
        rolePermissions: state.rolePermissions.filter((item) => item.id != f.id),
      };

    //****************** MODALS **********************//

    case "SET_DIALOG_SHOW":
      return {
        ...state,
        dialogShow: data,
      };

    case "SET_CONFIRM_SHOW":
      return {
        ...state,
        confirmShow: data,
      };

    case "SET_DISABLED":
      return {
        ...state,
        disabled: data,
      };

    case "SET_CONTACT_MODAL_SHOW":
      console.log(data);
      return {
        ...state,
        showContactModal: data,
      };

    //********************NOTES *********************/
    case "SET_GENERIC_NOTES":
      return { ...state, generic_note: data };

    //************************* WAIVERS ***********************/
    case "SET_TEMPLATE_LIST":
      return { ...state, templates: data };
    case "SET_SINGLE_TEMPLATE":
      return { ...state, templates: data };

    //****************** DEFAULT **********************//
    case "SET_SELECTED_COLOR":
      return { ...state, selectedColor: data.id };

    case "SET_STAFF_PERMISSIONS":
      return { ...state, staffPermissions: data, userPermissions: data };

    case "SET_USER_PERMISSIONS":
      console.log("SET_USER_PERMISSIONS");
      console.log(data);

      return { ...state, userPermissions: data };

    case "CLEAR_STATE":
      return { state: {} };

    case "RESET_STATE":
      return initialStateList;

    case "SET_FARM_OPTIONS":
      return { ...state, options_farm: data };

    case "SET_QUICKPAY_REFRESH":
      return { ...state, quickPayRefresh: data };

    case "SET_CURRENT_BOOKING_LESSON":
      console.log("SET_CURRENT_BOOKING_LESSON");
      console.log(data);
      return { ...state, currentBookingLesson: data };

    case "SET_CURRENT_ADD_LESSON":
      return { ...state, setcurrentAddLesson: data };
    case "SET_USE_MAKEUP":
      return { ...state, use_makeup: data };
    case "SET_IS_MAKEUP":
      return { ...state, is_makeup: data };

    default:
      throw new Error(`Unexpected  ${action} action-could not find dispatch type ${action[0]}`);
      console.log(action);
  }
};

export default listPageReducer;
