import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MediumPageHeader, HelpText } from "./styleCustom";
import { MyTextInput, FormRightSave } from "./formComponents";
import { errormsg } from "./Constants2";
//import "./styleForm.css";
//import "./styleFormik.css";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col } from "react-bootstrap";
import { ListContext } from "./App";
import { MyContext } from "./App";
import { useAlert } from "react-alert";
const Auth = new AuthHelperMethods();

const StaffForm = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const alert = useAlert();
  return (
    <Formik
      initialValues={listState.formInitialValues}
      enableReinitialize
      validationSchema={Yup.object({
        firstName: "",
        lastName: "",
      })}
      onSubmit={async (values, { setSubmitting }) => {
        let params = {};
        params.firstName = values.firstName;
        params.lastName = values.lastName;
        params.isStaff = 1;
        dispatch({ type: "FETCH_INIT" });
        let action =
          listState.formAction === "add" ? "STAFF_CREATE" : "STAFF_EDIT";
        let url =
          listState.formAction === "add"
            ? "instructors/create"
            : "instructors/update";

        try {
          const result = await Auth.api(url, "post", params);
          //    dispatch2([action, result.data]);
          dispatch({ type: "FETCH_SUCCESS" });
          setSubmitting(false);
        } catch (error) {
          dispatch({ type: "FETCH_ERROR" });
          alert.show(errormsg, { timeout: 5000, type: "error" });
        }
        dispatch2(["CLOSE_ADD_EDIT_FORM"]);
      }}
    >
      <Form id="staffForm">
        <Row>
          <MediumPageHeader>
            {listState.formAction === "add"
              ? "Create a new Staff Member "
              : "Edit Staff Member"}
          </MediumPageHeader>
          <HelpText>
            {
              "If the new staff member is also an account holder or a student, edit their client profile to add them as a staff member as well."
            }
          </HelpText>
        </Row>
        <Row noGutters style={{ flexDirection: "column" }}>
          <Row>
            <Col>
              <MyTextInput
                name="firstName"
                type="text"
                placeholder="First Name"
                label="First Name"
              />
            </Col>
            <Col>
              <MyTextInput
                label="Last Name"
                name="lastName"
                type="text"
                placeholder="Last Name"
              />
            </Col>
          </Row>
        </Row>
        <FormRightSave formId={"staffForm"} />
      </Form>
    </Formik>
  );
};
export default StaffForm;
