import React, { useReducer, Fragment, useState, useEffect, useContext } from "react";
import axios from "axios";
import { MyContext } from "./App";
import Select from "react-select";
import { valueFromId, calculateEndTime } from "./momentUtils";
import { ListContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
const Auth = new AuthHelperMethods();

const SelectSectionDefaults = (props) => {
  // THIS IS TO HANDLE CHANGES TO SECTION ON ADDLESSON - USED INSTEAD OF OPTIONS_SECTION
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const [section_id, setSection_id] = useState(mystate.sectionDefaults.section_id || null);
  const restrictSection = true;
  const [options, setOptions] = useState(mystate.options_section);
  // * dont think getStudentSections is used */
  /* const getStudentSections = async () => {
    console.log(mystate.options_section);
    let params = { student_id: listState.currentClient.student_id };
    const result = await Auth.api("section_students/getStudentSections", "get", params);
    let sectionArray = result.data.sectionStudentsArray;
    console.log(sectionArray);

    // let o = options.filter((section) => sectionArray.includes(section.value));
    // console.log(o);
    setOptions(options.filter((section) => sectionArray.includes(section.value)));
    dispatch({
      type: "SET_SECTION_DEFAULTS",
      id: sectionArray[0],
    });
    if (sectionArray.length == 0) alert("There are no sections this student is eligible for");
  };
  */

  //keep this until create student_add sections
  // jkl
  //?
  const getStudentAddSections = async () => {
    let params = { student_id: listState.currentClient.student_id };
    const result = await Auth.api("students/getStudentAddSections", "get", params);
    let sectionArray = result.data.sectionStudentAddArray;
    console.log(sectionArray);
    console.log(options);
    if (sectionArray?.length > 1) setOptions(options.filter((section) => sectionArray.includes(section.value)));
    //todo - change it so that if not sections, student can't add.  Need to add easy way for users to assign bulk sections. Need to inform clients.
    /* dispatch({
      type: "SET_SECTION_DEFAULTS",
      id: sectionArray[0],
    });*/
    //if (sectionArray.length == 0) alert("There are no sections this student is eligible to add");
    //history.goBack();
  };

  useEffect(
    () => {
      if (Auth.getUserType() == "STU") {
        console.log("STU");
        getStudentAddSections();
      }
    },
    //eslint-disable-next-line
    []
  );

  const handleSelectChange = async (event) => {
    let params = {};
    params.id = event.value;
    try {
      const result = await Auth.api("sections/getSection", "get", params);
      setSection_id(event.value);
      dispatch({
        type: "SET_SECTION_DEFAULTS",
        id: event.value,
        section: result.data.section,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="sectionSelect">
      <Select
        onChange={(e) => handleSelectChange(e)}
        className="form-control"
        value={options.filter((section) => section.value == mystate.addevent.section_id)}
        options={options}
        clearable={true}
        searchable={true}
      />
    </div>
  );
};

export default SelectSectionDefaults;
