import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link, useParams, useHistory, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import AuthHelperMethods from "./AuthHelperMethods";
import { ListContext, MyContext } from "./App";
import moment from "moment";
import COLORS from "./styleConstants";
import { BlueText } from "./styleCustom";
import useSWR from "swr";
import { Calendar, momentLocalizer } from "react-big-calendar";
import AddModal from "./addModal.js";
import { SelectTemplateFilter } from "./searchFilterReports";

import { Filter, FilterLabels, SelectFilter, FilterActive } from "./calendarFilter";
import { resourceAccessor, eventStyleGetter, dayPropGetter } from "./calendarConstants";
import {
  CustomEvent,
  BlueResourceButtons,
  BulkButtons,
  ToggleStudentEventName,
  ToggleShowClientShowAll,
  ToggleShowFilter,
} from "./calendarComponents";
import {
  formatEventsFromDB,
  calculateDateRange,
  calulateInitialCalendarDateRange,
  calulateInitialAgendaDates,
} from "./calendarFunctions";
import { useAlert } from "react-alert";
import BookingCalendar from "./bookingCalendar";
import { InfoIconCirc } from "./icons";
const localizer = momentLocalizer(moment);
const Auth = new AuthHelperMethods();

export const CalendarHomePage = ({ match }) => {
  //console.log(props);
  //const [data, setData] = useState([]);
  const alert = useAlert();

  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);
  const [showFilter, setShowFilter] = useState(isMobile ? false : false);
  const [showBookLesson, setShowBookLesson] = useState(false);
  //let incoming = useParams();
  //console.log(incoming);

  //const isBookingMakeup = incoming.is_makeup ? true : false;

  //console.log(isBookingMakeup);

  let history = useHistory();
  let location = useLocation();

  moment.updateLocale("en", {
    week: {
      dow: mystate.farmDefaults.monday_start ? 1 : 0, // First day of week is Monday
      doy: mystate.farmDefaults.monday_start ? 7 : 6, // First week of year must contain 1 January (7 + 1 - 1)
    },
  });

  useEffect(() => {
    console.log("INITAL USEEFFECT");
    dispatch({
      type: "SET_CALENDAR_DATE",
      data: moment().toDate(),
    });
    let [date1, date2] = calulateInitialCalendarDateRange("month");
    console.log(date1, date2);

    let initialfilter = {
      date1: date1,
      date2: date2,
      page: "calendar",
      view: "month",
    };
    console.log(initialfilter);

    dispatch2(["SET_SEARCH_FILTER_OBJECT", initialfilter]);
    dispatch2(["SET_SHOW_CLIENT_OR_ALL", true]);
    dispatch2(["SET_SHOW_FILTER", isMobile ? false : true]);

    // if (listState.filter.page != "calendar") dispatch2(["SET_SEARCH_FILTER", "calendar", "page"]);
  }, []);

  const { data, error, isLagging, resetLaggy, isValidating } = useSWR(
    listState.filter.date1 &&
      listState.filter.date2 &&
      listState.filter.page == "calendar" &&
      listState.currentClient.student_id && [`lessons/index`, listState.filter]
  );
  console.log(data);
  useEffect(() => {
    if (isValidating) dispatch({ type: "FETCH_INIT" });
    else dispatch({ type: "FETCH_SUCCESS" });
  }, [isValidating]);

  function handleRangeChange(e) {
    var [currentStart, currentEnd, view] = calculateDateRange(e);
    // dispatch2(["SET_SEARCH_FILTER", view, "view"]);
    dispatch2(["SET_BOTH_SEARCH_FILTER_DATES", currentStart, currentEnd, "calendar"]);
    dispatch({
      type: "SET_CALENDAR_DATE_RANGE",
      start: currentStart,
      end: currentEnd,
    });
    console.log("set view from range");
    /* dispatch({
      type: "SET_VIEW",
      data: view,
    });
    */
  }

  function handleViewChange(e) {
    console.log(e);
    dispatch({
      type: "SET_VIEW",
      data: e,
    });
    dispatch2(["SET_SEARCH_FILTER", e, "view"]);
  }

  function handleNavigate(date) {
    dispatch({
      type: "SET_CALENDAR_DATE",
      data: date,
    });
  }

  const handleNewEvent = async (event) => {
    if (Auth.getUserType() == "STU" && !mystate.farmDefaults.clients_can_add_lesson) return null;
    if (Auth.getUserType() == "STU" && mystate.farmDefaults.restrict_client_add_sections) {
      let params = { student_id: listState.currentClient.student_id };
      const result = await Auth.api("students/getStudentAddSections", "get", params);
      if (result.data?.sectionStudentAddArray?.length == 0) {
        //alert("no sections");
        return null;
      }
    }

    dispatch({
      type: "SET_EVENT",
      event: event,
    });
    history.push(`/admin/clienthome/${listState.currentClient.id}/add/:add`);
  };

  let formats = {
    agendaHeaderFormat: ({ start, end }) => {
      return moment(start).format("dddd MMMM Do") + " - " + moment(end).format("dddd MMMM Do");
    },
  };

  const handleExistingEvent = (event) => {
    let url = `${match.url}/bookingCalendar`;
    dispatch({
      type: "SET_EVENT",
      event: event,
    });
    dispatch2(["SET_CURRENT_BOOKING_LESSON", event]);
    history.push(url);
  };

  const Instructions = () => {
    const canBookEventbyClick = Auth.getUserType() == "ADM" ? true : false;
    const mustBookByButton =
      Auth.getUserType() == "STU" && mystate.farmDefaults.clients_can_book_lessons ? true : false;
    const canAddEvent = Auth.getUserType() == "ADM" || mystate.farmDefaults.clients_can_add_lesson ? true : false;
    console.log(canBookEventbyClick);
    console.log(mustBookByButton);
    console.log(canAddEvent);

    const eventbyClickInstr =
      "You are currently viewing the calendar. To add client to existing event, click the orange `Book Lesson` button.";
    const addInstr = "To add an event for the client, click on desired date and/or time in the calendar.";
    const clickOrBookInstr =
      "You are currently viewing the calendar. To add client to existing event, click on the event. To view events client is eligible for, click orange 'Book Lesson' button.";

    const instructionStyle = {
      display: "flex",
      fontSize: 14,
      color: COLORS.tablegrey,
      justifyContent: "flex-start",
      alignItems: "flex-start",
    };
    return (
      <div style={{ marginBottom: 20 }}>
        {mustBookByButton && (
          <div style={instructionStyle}>
            {" "}
            <InfoIconCirc /> {eventbyClickInstr}
          </div>
        )}
        {canBookEventbyClick && (
          <div style={instructionStyle}>
            {" "}
            <InfoIconCirc />
            {clickOrBookInstr}
          </div>
        )}
        {canAddEvent && (
          <div style={instructionStyle}>
            {" "}
            <InfoIconCirc /> {addInstr}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {" "}
      <div
        className="reportTable"
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          flex: "1 1 ",
        }}
      >
        <div
          className={"table"}
          style={{
            marginTop: 20,
            display: "flex",
            flex: "1 1 100%",
            flexDirection: "column",
          }}
        >
          <div className="filtertop">
            <div style={{ marginBottom: 10 }}>
              <ToggleStudentEventName />
            </div>

            <div style={{ xmaxWidth: 700, marginBottom: 20, maxWidthx: 800 }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {listState.showfilter && (
                  <>
                    <SelectFilter staff program_type program location section student horse />
                    <FilterActive lessonCancel available studentCancel />
                  </>
                )}
              </div>
              <FilterLabels />
            </div>
          </div>
          <Instructions />
          <div style={{ minHeight: 700 }}>
            <Calendar
              events={formatEventsFromDB(data)}
              localizer={localizer}
              defaultView={"month"}
              selectable
              min={mystate.farmDefaults.min_time}
              max={mystate.farmDefaults.max_time}
              date={mystate.calendarDate}
              eventPropGetter={eventStyleGetter}
              dayPropGetter={dayPropGetter}
              components={{ event: CustomEvent }}
              step={mystate.farmDefaults.calendar_interval || 60}
              popup
              views={["month", "week", "day"]}
              formats={formats}
              length={30}
              onView={handleViewChange}
              onRangeChange={handleRangeChange}
              onNavigate={handleNavigate}
              onSelectSlot={(event) => handleNewEvent(event)}
              onSelectEvent={Auth.getUserType() == "ADM" ? (event) => handleExistingEvent(event) : null}
            />
          </div>

          <PrivateRoute path={`${match.url}/bookingCalendar`} component={BookingCalendar} />
        </div>
      </div>
    </>
  );
};
