import React, { useState, useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { Formik, Form, useField, useFormikContext, FieldProps, Field } from "formik";
import * as Yup from "yup";

import Select, { Option, ReactSelectProps } from "react-select";
import { ListContext } from "./App";
import { MyContext } from "./App";
import {
  MediumPageHeader,
  BoxWithLabel,
  Row1,
  ContainerH,
  ContainerV,
  Row2,
  BoxWithLabelW,
  HelpText,
} from "./styleCustom";
import { TextInput, MyCheckbox, AddEditForm, OrangeButton, BlueLinkButton, OrangeBlueButton } from "./formComponents";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col } from "react-bootstrap";
import COLORS from "./styleConstants";
import { errormsg } from "./Constants2";

/**/
const Auth = new AuthHelperMethods();

const ProgramForm = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  let addoredit = useParams().id;
  let history = useHistory();
  let currentProgramId = useParams().id;

  useEffect(
    () => {
      window.scrollTo(0, 0);
      return () => {
        dispatch2(["SET_FORM_INITIAL_VALUES", {}]);
      };

      //  }
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      let currentProgram = listState.programList.find((program) => program.id == currentProgramId || null);
      let program = currentProgram;
      let formInitialValues = {};
      if (program)
        formInitialValues = {
          id: program.id || null,
          program_name: program.program_name || "",
          program_desc: program.program_desc || "",
          program_type_id: program.program_type_id || "",
          instructor_id: program.instructor_id || "",
          location_id: program.location_id || "",
          duration: program.duration || "",
        };

      dispatch2(["SET_FORM_INITIAL_VALUES", formInitialValues, "edit"]);
    },
    //eslint-disable-next-line
    [currentProgramId]
  );

  useEffect(
    () => {
      const fetchData = async () => {
        let params = {};
        const result = await Auth.api("options/index", "get", params);
        dispatch({
          type: "UPDATE_OPTIONS",
          options: result.data,
        });
      };
      fetchData();
    },
    //eslint-disable-next-line
    [listState.programList]
  );

  const SelectField: React.SFC<ReactSelectProps & FieldProps> = ({ options, field, form }) => (
    <Select
      options={options}
      name={field.name}
      value={options ? options.find((option) => option.value === field.value) : ""}
      onChange={(option: Option) => form.setFieldValue(field.name, option.value)}
      onBlur={field.onBlur}
    />
  );

  return (
    <>
      <AddEditForm>
        {listState.formInitialValues && (
          <Formik
            initialValues={listState.formInitialValues}
            enableReinitialize
            validationSchema={Yup.object({
              program_name: Yup.string().required("name is required"),
              program_desc: Yup.string(),
              program_type_id: Yup.number().required("program_type is required"),
              instructor_id: Yup.number(),
              location_id: Yup.number(),
              duration: Yup.number(),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              if (addoredit === "add") {
                try {
                  const result = await Auth.api("programs/create", "post", values);
                  dispatch2(["ADD_PROGRAM", result.data.program]);

                  setSubmitting(false);
                  history.goBack();
                } catch (e) {
                  console.log(e);
                  dispatch({ type: "FETCH_ERROR" });

                  alert.show(errormsg, { timeout: 5000, type: "error" });
                }
              }

              if (addoredit !== "add") {
                values.id = currentProgramId;
                try {
                  const result2 = await Auth.api("programs/update", "post", values);
                  dispatch2(["EDIT_PROGRAM", result2.data.program]);

                  history.push("/admin/setup/programs");
                } catch (e) {
                  console.log(e);
                  dispatch({ type: "FETCH_ERROR" });

                  alert.show(errormsg, { timeout: 5000, type: "error" });
                }
              }
              //    }
            }}
          >
            <Form id="programForm" style={{ width: 400 }}>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <MediumPageHeader>
                  {addoredit === "add" ? "Create a new Program" : "Edit Current Program"}
                </MediumPageHeader>
              </Row>
              <div style={{ marginBottom: 50 }}>
                <Row noGutters>
                  <Col xs={12}>
                    {mystate.options_program_type && (
                      <BoxWithLabelW label="program type">
                        <Field
                          name={"program_type_id"}
                          component={SelectField}
                          options={mystate.options_program_type}
                        />
                        <HelpText>required</HelpText>
                      </BoxWithLabelW>
                    )}
                  </Col>
                </Row>
                <Row noGutters>
                  <Col xs={12}>
                    <TextInput name="program_name" label="Name" /> <HelpText>required</HelpText>
                  </Col>
                </Row>
                {1 == 2 && (
                  <>
                    <Row noGutters>
                      <Col xs={12}>
                        <TextInput name="duration" type="number" placeholder="duration" label="Duration" />{" "}
                      </Col>
                    </Row>

                    <Row noGutters>
                      <Col xs={12}>
                        {mystate.options_instructor && (
                          <BoxWithLabelW label="instructor">
                            <Field
                              name={"instructor_id"}
                              component={SelectField}
                              options={mystate.options_instructor}
                            />
                          </BoxWithLabelW>
                        )}
                      </Col>
                    </Row>
                    <Row noGutters>
                      <Col xs={12}>
                        {mystate.options_location && (
                          <BoxWithLabelW label="location">
                            <Field name={"location_id"} component={SelectField} options={mystate.options_location} />
                          </BoxWithLabelW>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
                <Row noGutters>
                  <Col xs={12}>
                    <TextInput name="program_desc" placeholder="description" label="Description" />{" "}
                  </Col>
                </Row>
              </div>

              <Row style={{ display: "flex", justifyContent: "spread-evenly" }}>
                <Col>
                  <OrangeBlueButton onClick={() => history.push("/admin/setup/programs")}>{"Cancel"}</OrangeBlueButton>
                </Col>
                <Col>
                  {" "}
                  <OrangeButton
                    className="shadow-none"
                    style={{
                      color: "white",
                      background: COLORS.orange,
                      width: "100%",
                    }}
                    size={"md"}
                    type="submit"
                  >
                    {"Save"}
                  </OrangeButton>
                </Col>
              </Row>
            </Form>
          </Formik>
        )}
      </AddEditForm>
    </>
  );
};
export default ProgramForm;
