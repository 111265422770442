import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, ListGroup, Modal, Accordion } from "react-bootstrap";
import { ListContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import { BlueText } from "./styleCustom";
import SearchFilter from "./searchFilter";
import { timeISOtoString } from "./momentUtils";
import { EditIcon } from "./icons";

import OnlinePurchase from "./onlinePurchase";
import COLORS from "./styleConstants";
import { deleteImg, privateNoteImg, publicNoteImg } from "./images";
import { MyContext } from "./App";
import { dateObjToDisplayDate, dateObjToDisplayTime } from "./momentUtils";
import moment from "moment";
import { dateObjToDBDate } from "./momentUtils";
import { ContainerH, ContainerV, Row1, Ul } from "./styleCustom";
import { useAlert } from "react-alert";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { MyTextInput, MyCheckbox, OrangeButton, TableHeader2, BlueButtonFinish } from "./formComponents";
import { MediumPageHeader, HelpText, SaveButton } from "./styleCustom";
import axios from "axios";
import { RegistrationClient } from "./registration";
const Auth = new AuthHelperMethods();

const Public = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [accountCreated, setAccountCreated] = useState(false);
  const [farm, setFarm] = useState("");
  const [data, setData] = useState([]);
  const alert = useAlert();

  let incoming = useParams();
  let public_id = incoming.public_id;
  console.log(public_id);

  /*useEffect(() => {
    //  if (listState.filter.current == "daysheet") {
    console.log("run");
    const fetchData = async () => {
      let params = { ...params, public_id: public_id };
      try {
        // const result = await Auth.api("public/public", "get", params);
        const result = await axios.get(`http://localhost:3001/public/public`, params);
        setFarm(result.data.farm);
      } catch {
        return "error";
      }
    };
    //fetchData();
    //esnt-disable-next-line
  }, [public_id]);
*/
  const getFarm = async () => {
    let params = { public_id: public_id };
    console.log(params);

    try {
      // const result = await Auth.api("public/public", "get", params);
      const result = await axios.get(`http://localhost:3001/public/public`, { params });
      // setFarm(result.data.farm);
      setData(result.data.list);
    } catch {
      return "error";
    }
  };

  return (
    <>
      <div>
        {1 == 2 && <AddPublicAccount public_url={public_id} />}
        <div>{`Welcome To ${farm}`}</div>
        <button onClick={() => getFarm()}>click</button>
        <div>
          {data &&
            data.map((item) => (
              <div
                style={{ border: "solid .5pt", padding: 15, minWidth: 600, marginBottom: 5, color: COLORS.textgrey }}
              >
                <div className="title" style={{ marginBottom: 5, color: COLORS.textDark, fontWeight: "bold" }}>
                  {item.title}
                </div>
                <div className="more">{item.description}</div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

const AddPublicAccount = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  // const [accountCreated, setAccountCreated] = useState(false);
  const [billingPersonId, setBillingPersonId] = useState();
  const [contactId, setContactId] = useState();
  const [accountPersonId, setAccountPersonId] = useState();

  const [accountName, setAccountName] = useState();
  const [showAccountForm, setShowAccountForm] = useState(true);

  const history = useHistory();
  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "false",
        }}
        enableReinitialize
        validationSchema={Yup.object({
          firstName: Yup.string().required("First Name Required"),
          lastName: Yup.string(),
          email: Yup.string().email().required("Email Required"),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log("onSubmit");
          //  console.log(dependantValues);
          if (values) {
            console.log(values);
            dispatch({ type: "FETCH_INIT" });

            //    setBillingPersonValues(`${values.firstName} ${values.lastName}`);
            let params = {};

            params.firstName = values.firstName.trim();
            params.lastName = values.lastName.trim();
            params.email = values.email;
            params.isPublic = true;
            params.public_url = props.public_url;

            setAccountName(`${params.firstName} ${params.lastName}`);

            //params = { name: values.firstName };
            try {
              const result = await Auth.api("people/createPublicBillingPerson", "post", params);
              console.log(result.data);
              setShowAccountForm(false);

              setBillingPersonId(result.data.billingPersonId);
              setContactId(result.data.contactId);
              accountPersonId(result.data.newPersonId);

              setShowAccountForm(false);
              dispatch({ type: "FETCH_SUCCESS" });
            } catch {
              dispatch({ type: "FETCH_ERROR" });
            }
          }
        }}
      >
        {showAccountForm && (
          <Form>
            <Row>
              <MediumPageHeader> {`Account Holder`}</MediumPageHeader>
            </Row>
            <Row>
              <HelpText>
                {
                  "To create a new client, enter their name here if they will have their own account, or enter the name of the guardian/account holder.  Check the box if the Account Holder is also a student. Once saved, you will be prompted for the remaining information."
                }
              </HelpText>
            </Row>
            <Row>
              <Col>
                <MyTextInput name="firstName" type="text" placeholder="First Name" label="First Name" />
              </Col>
              <Col>
                <MyTextInput label="Last Name" name="lastName" type="text" placeholder="Last Name" />
              </Col>
            </Row>
            <Row>
              <Col>
                <MyTextInput name="email" type="text" label="Primary Email" />
              </Col>
            </Row>

            <div style={{ display: "flex", justifyContent: "center", marginTop: 40 }}>
              <SaveButton type="submit">{"Save Account Holder"}</SaveButton>
            </div>
          </Form>
        )}
      </Formik>
      {!showAccountForm && (
        <>
          <MediumPageHeader
            style={{ textAlign: "center" }}
          >{`Create Additional Account Member for ${accountName}`}</MediumPageHeader>

          <AddDependantForm
            public_url={props.public_url}
            billingPersonId={billingPersonId}
            contactId={contactId}
            accountPersonId={accountPersonId}
          />
        </>
      )}
    </>
  );
};

export const AddDependantForm = (props) => {
  //  const { values, submitForm } = useFormikContext();
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);

  const [params, setParams] = useState({});
  const [first, setFirst] = useState({});
  const [second, setSecond] = useState({});
  let history = useHistory();
  console.log(props);

  useEffect(
    () => {
      console.log("useEffect");
      window.searchinputRef = searchinputRef;
      if (searchinputRef.current) searchinputRef.current.focus();
    },
    //eslint-disable-next-line
    []
  );
  if (!listState.accountHolder) {
    return <div>you have to add an account holder first</div>;
  }
  const searchinputRef = React.createRef();
  return (
    <>
      {" "}
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
        }}
        enableReinitialize
        validationSchema={Yup.object({
          firstName: Yup.string().required("First Name Required"),
          lastName: Yup.string(),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          let params = {};

          //console.log(params);

          params.firstName = values.firstName.trim();
          params.lastName = values.lastName.trim();
          //console.log(`x${values.firstName}`);

          params.billing_person_id = props.billingPersonId;
          params.contact_id = props.contactId;
          params.public_url = props.public_url;

          let result = await Auth.api("people/createPublicBillingStudents", "post", params);
          console.log(result.data);
          // resetForm();
        }}
      >
        <Form id="dependentForm">
          <Row>
            <Col>
              <MyTextInput name="firstName" type="text" placeholder="First Name" label="First Name" />
            </Col>{" "}
            <Col>
              <MyTextInput label="Last Name" name="lastName" type="text" placeholder="Last Name" />
            </Col>
          </Row>

          <>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 30,
              }}
            >
              <OrangeButton type="submit">{"Save"}</OrangeButton>
            </Row>
          </>
        </Form>
      </Formik>
    </>
  );
};

export default Public;
