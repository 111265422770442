import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, NavLink, useParams } from "react-router-dom";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";

import AuthHelperMethods from "./AuthHelperMethods";
import { MyContext } from "./App";
import { ListContext } from "./App";
import {
  TableHeaderNoAdd,
  OrangeLinkButton,
  OrangeButton,
  ModalHeaderBlue,
  MyTextInput,
  MyTextAreaInput,
  ButtonOrange,
} from "./formComponents";
import { BoxWithLabelW, SaveButton, StyledTr, Td, Th, HelpText, BlueText } from "./styleCustom";
import { Modal } from "react-bootstrap";
import { useAlert } from "react-alert";
import { errormsg } from "./Constants2";
import { active_options } from "./Constants2";
import { Active } from "./searchFilterReports";
import COLORS from "./styleConstants";
import useSWR from "swr";
import moment from "moment";

const Auth = new AuthHelperMethods();

const LocationList = ({ updateIsActive, setShowModal, editLocation }) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const { data, error, isLagging, resetLaggy, isValidating } = useSWR([`locations/index`, listState.filter]);
  //  if (data?.locations) dispatch2(["GET_LOCATION_LIST", data.locations]);
  //console.log(data);
  return (
    <div style={{ display: "flex", flexWrap: "nowrap", flex: "1 0 100%" }}>
      <div style={{ flex: "1 0 100%" }}>
        <TableHeaderNoAdd title={"Locations"}>
          <OrangeButton onClick={() => setShowModal(true)}>{"Add New Location"} </OrangeButton>{" "}
        </TableHeaderNoAdd>
        <Active field={"is_active"} default={"1"} options={active_options} />

        <table style={{ width: "100%" }}>
          <thead>
            <tr style={{ color: `${COLORS.blue}` }}>
              <Th>
                {" "}
                <div>{"Name"}</div>
                <HelpText>{"Click on Name to edit"}</HelpText>
              </Th>
              <Th>{"description"}</Th>

              <Th>{"status"}</Th>
            </tr>
          </thead>
          <tbody>
            {data?.locations.map((location) => (
              <StyledTr key={location.id}>
                <Td style={{ maxWidth: 200 }}>
                  <div style={{ cursor: "pointer", maxWidth: 100 }} onClick={() => editLocation(location)}>
                    <BlueText> {location.location_name}</BlueText>
                  </div>
                </Td>
                <Td>
                  <div>{location.location_description}</div>
                </Td>
                <Td onClick={() => updateIsActive(location.id)} style={{ color: "red", cursor: "pointer" }}>
                  {`${location.is_active ? "inactivate" : "activate"}`}
                </Td>
              </StyledTr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Locations = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [showModal, setShowModal] = useState(false);
  const [addoredit, setAddorEdit] = useState("add");
  const [initialValues, setInitialValues] = useState({
    location_name: "",
    location_description: "",
  });
  const alert = useAlert();

  const editLocation = (location) => {
    console.log("editLocatiion");
    setAddorEdit("edit");
    setShowModal(true);
    setInitialValues({
      id: location.id,
      location_name: location.location_name,
      location_description: location.location_description,
    });
  };

  const updateIsActive = async (id) => {
    let params = { ...listState.filter };
    params.id = id;
    const result = await Auth.api("locations/updateActivationStatus", "post", params);
    // dispatch2(["GET_LOCATION_LIST", result.data.locations]);
    dispatch2(["SET_SEARCH_FILTER", moment().format(), "timetorefresh"]);

    console.log(result.data);
  };

  return (
    <>
      <LocationList updateIsActive={updateIsActive} setShowModal={setShowModal} editLocation={editLocation} />
      <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
        <ModalHeaderBlue closeButton>
          <Modal.Title>{`${addoredit == "edit" ? "Edit" : "Add New"}  Location`}</Modal.Title>
        </ModalHeaderBlue>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              location_name: Yup.string().required("required"),
              location_description: Yup.string().nullable(true),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              dispatch({ type: "FETCH_INIT" });

              let url = addoredit == "edit" ? "locations/update" : "locations/create";
              console.log(url);
              try {
                const result = await Auth.api(url, "post", values);
                if (addoredit == "edit") {
                  dispatch2(["EDIT_LOCATION", result.data.location]);
                  alert.show(`Changes to location ${values.location_name} saved`, {
                    timeout: 5000,
                    type: "success",
                  });
                } else {
                  dispatch2(["ADD_LOCATION", result.data.location]);

                  alert.show(`Location ${values.location_name} added`, {
                    timeout: 5000,
                    type: "success",
                  });
                }

                dispatch({ type: "FETCH_SUCCESS" });

                setShowModal(false);
                setAddorEdit("add");
                setInitialValues({
                  location_name: "",
                });
                dispatch2(["SET_SEARCH_FILTER", moment().format(), "timetorefresh"]);
              } catch (e) {
                console.log(e);
                dispatch({ type: "FETCH_ERROR" });

                alert.show(errormsg, { timeout: 5000, type: "error" });
              }
            }}
          >
            <Form>
              <MyTextInput name="location_name" type="text" placeholder=" " label="Location Name" />
              <HelpText>required</HelpText>
              <MyTextAreaInput name="location_description" type="text" placeholder=" " label="Description" />

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 40,
                }}
              >
                <ButtonOrange type="submit" label={"Save Location"} />
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Locations;
