const APIURL = "https://server.equineoffice.io/";



export default APIURL;

export const note_types = [
  "PERM",
  "ACCOUNT",
  "PROGRESS",
  "NOTIFICATION",
  "REMINDER",
];

