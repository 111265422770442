import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, Link, useParams, useHistory, Switch, useLocation } from "react-router-dom";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
//import "./styleFormik.css";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col, Modal } from "react-bootstrap";
import SelectList from "./selectList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  DateWithLabel,
  BoxWithLabel,
  MediumPageHeader,
  PageTitle,
  OrangeButtonSm,
  PrimaryText,
  StyledTr,
  TableText1,
  TableText2,
  BoxWithLabelNoM,
  StyledListGroup,
  BlockLabel,
  StyledList,
  Td,
  Th,
  AddButton,
  HelpText,
  ContainerH,
  ContainerV,
  PrimaryTextBlue,
} from "./styleCustom";
import { TableHeader2, ModalWarning, ButtonOrangeSm } from "./formComponents";
import { DATEPICKER_DATE_DISPLAY } from "./UserSettings";
import PrivateRoute from "./privateRoute";
import ReportTable from "./reportTable";

import moment from "moment";
import {
  dateObjToDBDate,
  dateISOtoStringShort,
  dateISOtoStringShortWithYear,
  currentMonthStart,
  currentMonthEnd,
  dateObjToDisplayDate,
  dateTimeISOtoStringMed,
  dateISOtoDateObj,
  expiredColor,
  currentDayToDB,
  dateTimeISOtoStringShort,
  onPage,
} from "./momentUtils";
import SearchFilter, { ModalFilter } from "./searchFilter";
import SearchFilterDate from "./searchFilterDates";
import { ListContext, MyContext } from "./App";
import { RemoveIconNoPosition, EditIcon, Desc, Asc, Expand } from "./icons";
import PackageDetailsEdit from "./packageDetailsEdit";

import COLORS from "./styleConstants";
import { useAlert } from "react-alert";
import ToggleSwitch from "./ToggleSwitch";
import { SelectTemplateFilter, SelectNoLabel, CancelRow, Active } from "./searchFilterReports";
import { CSVLink, CSVDownload } from "react-csv";

const Auth = new AuthHelperMethods();

const ORANGEBUTTON = {
  backgroundColor: COLORS.orange,
  padding: 8,
  color: "white",
  fontSize: 12,
  minWidth: 100,
  margin: 5,
  borderRadius: 5,
  border: "medium none,",
  borderColor: COLORS.orange,
};

const Csv = ({ data, filename }) => {
  return (
    <button style={ORANGEBUTTON}>
      <CSVLink data={data} filename={`${filename}.csv`} style={{ color: "white" }}>
        Download CSV
      </CSVLink>
    </button>
  );
};

//****************************************************///

export const AdminPackageReport = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const alert = useAlert();
  let history = useHistory();
  let location = useLocation();
  const [studentProductList, setStudentProductList] = useState([]);
  const [purchaseList, setPurchaseList] = useState([]);
  const [summary, setSummary] = useState();

  const [search, setSearch] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [day, setDay] = useState(currentDayToDB());
  const [sortBy, setSortBy] = useState("purchase_date");
  const [direction, setDirection] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const [packageToDelete, setPackageToDelete] = useState();
  const [nextUnpaidLesson, setNextUnpaidLesson] = useState([]);
  const [lastPaidLesson, setLastPaidLesson] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [showExpiryFilter, setShowExpiryFilter] = useState(false);
  const [showPurchaseFilter, setShowPurchaseFilter] = useState(true);
  const [showPurchased, setShowPurchased] = useState(false);
  const [reportData, setReportData] = useState([]);

  let showToday = onPage(location.pathname) == "today";
  console.log(onPage(location.pathname));

  useEffect(
    () => {
      let params = { ...listState.filter };
      console.log("SORTY");

      console.log(listState.filter);
      params.sortBy = sortBy;
      params.direction = direction ? "desc" : "asc";

      delete params.dbday; // double check in case it was still set - fixed from whiteboard but might be elsewhere?

      console.log(params);
      const fetchData = async () => {
        try {
          dispatch({ type: "FETCH_INIT" });

          const result = await Auth.api("items/studentPurchases", "get", params);
          result && setStudentProductList(result.data.data);

          result &&
            setReportData(
              result.data.data.map((item) => ({
                client: item.person_name,
                package: item.product_name,
                purchased: dateISOtoStringShortWithYear(item.purchase_date),
                starts: dateISOtoStringShortWithYear(item.package_start_date),
                expires: dateISOtoStringShortWithYear(item.package_expiry_date),
                created: dateTimeISOtoStringShort(item.created_at),
                start: item.start_count,
                used: showToday ? item.usedtilltoday : item.used,
                remain: showToday ? item.start_count - item.usedtilltoday : item.remain,
                notes: item.notes,
              }))
            );

          dispatch({ type: "FETCH_SUCCESS" });
        } catch {
          dispatch({ type: "FETCH_ERROR" });
        }
      };
      // if (listState.filter.page == "packages") {
      //  console.log("FILTER PAG IS PACKAGS");
      if (listState.filter.purchase1 && listState.filter.purchase2) fetchData();
      // }
    },
    //eslint-disable-next-line
    [listState.filter, sortBy, direction, update]
  );

  const prepareDeletePackage = (itemId) => {
    setShowWarning(true);
    setPackageToDelete(itemId);
  };

  const deletePackage = async () => {
    console.log("click");
    let params = { item_id: packageToDelete };
    try {
      dispatch({ type: "FETCH_INIT" });
      const result = await Auth.api("items/delete", "post", params);
      setPackageToDelete();
      setShowWarning(false);
      let packageIdsToDelete = result.data.itemIds;
      if (result.data.itemIds.length > 0)
        setStudentProductList(studentProductList.filter((product) => !packageIdsToDelete.includes(product.id)));

      dispatch({ type: "FETCH_SUCCESS" });

      console.log(result);
    } catch {
      dispatch({ type: "FETCH_ERROR" });
    }
  };

  const handleClickEditPackage = (packageIn) => {
    setShowEditModal(true);
    dispatch2(["SET_CURRENT_PRODUCT", { ...packageIn, product_count: packageIn.start_count }]);
    console.log("edip");
  };

  const handleCloseEditModal = async () => {
    setShowEditModal(false);
    console.log(listState.currentProduct);
    let params = { ...listState.filter };
    console.log(listState.filter);
    params.sortBy = sortBy;
    params.direction = direction ? "desc" : "asc";
    console.log(params);
    params = { ...params, ...listState.currentProduct };
    console.log(params);
    const result = await Auth.api("items/update", "post", params);
    setUpdate(!update);

    if (result)
      alert.show("Package had been changed and recalculated", {
        timeout: 3000,
        type: "success",
      });
    //console.log(result.data.data[0].id);
    //console.log(studentProductList[0].id);
    //let editedItem = result.data.data;

    // console.log(studentProductList.filter((item) => item.item_id == result.data.data[0].id));

    // //console.log(studentProductList.filter((item) => item.id == result.data.data[0].id));
    //  result && setStudentProductList([result.data, ...studentProductList.filter((item) => item.id != editedItem.id)]);
  };

  const changeSort = (sortIn) => {
    console.log(sortIn);
    setSortBy(sortIn);
    setDirection(!direction);
  };

  const SortImg = (props) => {
    if (sortBy == props.sortIn) return <> {direction ? <Desc /> : <Asc />}</>;
    return null;
  };
  const sm = { width: 50 };
  const md = { width: 100 };
  const lg = { width: 200 };

  const Head = (props) => {
    const sm = { width: 50 };
    const md = { width: 100 };
    const lg = { width: 200 };

    return (
      <div style={props.lg ? lg : props.md ? md : sm} onClick={() => changeSort(props.sort)}>
        {props.label}
        <SortImg sortIn={props.sort} />
      </div>
    );
  };

  const Purchased = () => {
    return (
      <div className={"packagesPurchased"}>
        <div>
          {listState.filter.purchase2 && (
            <div>
              {`You are viewing packages that were purchased between${dateISOtoStringShortWithYear(
                listState.filter.purchase2
              )} and ${dateISOtoStringShortWithYear(listState.filter.purchase2)}`}
            </div>
          )}
          {listState.filter.expiry2 &&
            (listState.filter.purchase2 ? (
              <div>
                {`and have an expirty date between${dateISOtoStringShortWithYear(
                  listState.filter.expiry1
                )} and ${dateISOtoStringShortWithYear(listState.filter.expiry2)}`}
              </div>
            ) : (
              <div>
                {`You are viewing packages that have an expiry date  between ${dateISOtoStringShortWithYear(
                  listState.filter.expiry1
                )} and ${dateISOtoStringShortWithYear(listState.filter.expiry2)}`}
              </div>
            ))}
        </div>

        <TableHeader2
          title={
            showToday
              ? "Package List by Student -  used counts as of today"
              : "Package List by Student -  used counts based on future schedule"
          }
        />

        <div style={{ padding: 10, fontWeight: "bold" }}>
          {" "}
          {showToday
            ? "Used and Remaining counts are calculated up until today and do not include any future lessons."
            : "Used and Remaining counts are calculated to include lessons up to today *plus* any scheduled lessons after today."}
        </div>

        {1 == 2 && (
          <div style={{ display: "flex", alignItems: "center", margin: 10, justifyContent: "flex-end" }}>
            <label style={{ marginRight: 10, fontSize: "10pt" }} htmlFor="today">
              Show Used and Remaining that Include Scheduled Lessons
            </label>

            <ToggleSwitch
              id="today"
              defaultChecked={false}
              disabled={false}
              Text={["Yes", "No"]}
              onToggleChange={onToggleChange}
              name={"showToday"}
            />
          </div>
        )}
        <div
          className={"headers"}
          style={{
            display: "flex",
            flex: "1 1 100%",
            flexWrap: "nowrap",
            color: COLORS.blue,
            fontWeight: "bold",
            marginTop: 15,
            marginBottom: 15,
          }}
        >
          <Head lg sort={"person_name"} label={"client"} />

          <div style={lg} onClick={() => changeSort("product_name")}>
            package
            <SortImg sortIn={"product_name"} />{" "}
          </div>

          <div style={md} onClick={() => changeSort("purchase_date")}>
            {" purchased"}
          </div>
          <div style={md} onClick={() => changeSort("package_start_date")}>
            start
          </div>
          <div style={md} onClick={() => changeSort("package_expiry_date")}>
            expiry
          </div>

          <div style={lg} onClick={() => changeSort("created_at")}>
            entered on
          </div>

          <div style={sm} onClick={() => changeSort("start_count")}>
            start
          </div>

          <div style={sm} onClick={() => changeSort("used")}>
            used
          </div>

          <div style={sm} onClick={() => changeSort("used")}>
            remain
          </div>
        </div>
        <div data-cy="packageTable">
          {studentProductList.map((item) => (
            <>
              <div className={"row1"} style={{ display: "flex", paddingTop: 7, paddingBottom: 7 }}>
                <div
                  style={{ ...lg, cursor: "pointer", color: COLORS.blue }}
                  onClick={() => handleClickEditPackage(item)}
                >
                  {item.person_name}
                </div>
                <div onClick={() => handleClickEditPackage(item)} style={{ ...lg }}>
                  {item.product_name}
                </div>

                <div style={md}>{dateISOtoStringShortWithYear(item.purchase_date)}</div>

                <div style={md}>{dateISOtoStringShortWithYear(item.package_start_date)}</div>
                <div style={{ ...md, color: expiredColor(item.package_expiry_date) }}>
                  {dateISOtoStringShortWithYear(item.package_expiry_date)}
                </div>
                <div style={lg}>{dateTimeISOtoStringShort(item.created_at)}</div>
                <div style={sm}>{item.start_count}</div>

                <div style={sm}>{showToday ? item.usedtilltoday : item.used}</div>
                <div style={sm}>{showToday ? item.start_count - item.usedtilltoday : item.remain}</div>

                <div style={sm}>
                  <div
                    onClick={() => prepareDeletePackage(item.id)}
                    style={{ color: COLORS.orange, cursor: "pointer" }}
                  >
                    {} {"delete "}{" "}
                  </div>
                </div>
              </div>
              <div className={"row2notes"} style={{ borderBottom: "solid 1pt lightgrey" }}>
                {item.item_notes && <div style={{ fontStyle: "italic", marginLeft: 10 }}>{item.item_notes}</div>}
              </div>
            </>
          ))}
        </div>
      </div>
    );
  };
  function onToggleChange(event) {
    console.log(event.target.checked);
    // setShowToday(!showToday);
    /* dispatch({
      type: "SET_APPLY_TO_FUTURE",
      applyToFuture: event.target.checked,
    });*/
  }
  return (
    <>
      {showWarning && (
        <ModalWarning
          title={"Whoa there!"}
          proceedlabel={`Yes I want to delete this package`}
          cancellabel={`No, I do not wish to delete this package`}
          warningMessage={`Warning!  You are about to delete this client's package.`}
          warning2={"Package counts will be automatically recalculated when deleted."}
          cancel={() => setShowWarning(false)}
          proceed={deletePackage}
        ></ModalWarning>
      )}
      <PackageDetailsEdit
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        onCloseModal={handleCloseEditModal}
        hidePrice
      />
      <div className={"reportArea"}>
        <div className={"filter"} style={{ display: "flex", flex: "1 1 100%", marginBottom: 5 }}>
          <div style={{ display: "flex", flexDirection: "column", fontSize: 20 }}>
            <HelpText style={{ fontSize: 16 }}>{"** new - click on Package to edit package details **"}</HelpText>
            {showPurchaseFilter && (
              <SearchFilterDate
                datefield1={"purchase1"}
                datefield2={"purchase2"}
                default={"current"}
                label={"purchase date"}
                clear
                closeThis={() => setShowPurchaseFilter()}
              />
            )}
            {!showPurchaseFilter && (
              <div style={{ maxWdith: 150 }}>
                <ButtonOrangeSm
                  onClick={() => setShowPurchaseFilter(!showPurchaseFilter)}
                  label={"show select purchase dates"}
                />
              </div>
            )}

            {!showExpiryFilter && (
              <div style={{ maxWdith: 150 }}>
                <ButtonOrangeSm
                  onClick={() => setShowExpiryFilter(!showExpiryFilter)}
                  label={"show select expiry dates"}
                />
              </div>
            )}
            {showExpiryFilter && (
              <SearchFilterDate
                datefield1={"expiry1"}
                datefield2={"expiry2"}
                label={"expiry date"}
                default={"current"}
                clear
                closeThis={() => setShowExpiryFilter()}
              />
            )}
          </div>
        </div>
        <div style={{ maxWdith: 150 }}>
          <Csv data={reportData} filename={showToday ? "counts_today" : "counts_future"} />
        </div>
        <Purchased />
      </div>
    </>
  );
};
